import { Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userTokenAtom } from 'src/lib/recoil/userCoil';

interface Props {
	url: string;
	url2:string;
	height: any;
}

const AuthenticatedImage: React.FC<Props> = ({ url ,url2, height }) => {
	const [blob, setBlob] = useState<string>('');
	const [blob2, setBlob2] = useState<string>('');
	const [imageIn, setImageIn] = useState<any>(false);

	const userToken = useRecoilValue(userTokenAtom);

	useEffect(() => {
		const src = url;
		const options = {
			headers: {
				authorization: userToken ? userToken : '',
			},
		};

		fetch(src, options)
			.then((res) => res.blob())
			.then((blob) => {
				setBlob(URL.createObjectURL(blob));
			});

			const src2 = url2;
			const options2 = {
				headers: {
					authorization: userToken ? userToken : '',
				},
			};
	
			fetch(src2, options2)
				.then((res) => res.blob())
				.then((blob) => {
					setBlob2(URL.createObjectURL(blob));
				});
	}, []);

	if (!blob) {
		return <p>Loading...</p>;
	}

	return (
		<>
	 <Image.PreviewGroup>
		<Image width={200} height={400} src={blob} style={height ? {height, position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)'} : {}}/> 
		
		<Image  src={blob2} style={{display:"none"}} />
	
		</Image.PreviewGroup>
		{/* <Image.PreviewGroup >
		
	
		</Image.PreviewGroup> */}
		</>
	);
	
	
};

export default AuthenticatedImage;
