import React ,{useEffect,useState}from 'react';
import CircularProgress from '../CircularProgress';
import LineIndicatorDash from './LineIndicatorDash';
import noData from "./noDataa.png"
interface Props {
	title: string;
	input:any;
}

const SiteAudienceDash: React.FC<Props> = ({ title ,input}) => {
	const [dataValue,SetDataValue] =useState<any>()
	const [dataFinal,SetDataFinal] =useState<any>()

    // console.log("innnn",input);
    useEffect(()=>{
    const highestValue =input?.reduce((maxObj, currentObj) => {
        return currentObj.value > maxObj.value ? currentObj : maxObj;
      }, input[0]);
    SetDataValue(highestValue)
    },[input])

     useEffect(()=>{
         const dataaa =input?.filter(value => value.value !==0);
        //  console.log("daaaaaaaaaaaaaaaaaaaaaaaaaa",dataaa);
         SetDataFinal(dataaa)
       },[input])

    //    useEffect(()=>{
    //     console.log("dataFinal",dataFinal);
    //   },[input])

	return (
		<div className="gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2">
			<h6 className="gx-text-uppercase gx-mb-2 gx-mb-sm-4">{title}</h6>
            {dataFinal&&dataFinal?.length!==0 ?(
            <>
            {dataFinal?.map((entry, index) => (					
                <ul className="gx-line-indicator" key={index}>
				<li>
       
					<LineIndicatorDash width={entry?.value} title={entry?.name} color="cyan" value={entry?.value}  maximum={dataValue?.value}/>
				</li>
			</ul>
			))}
            </>
            ):dataFinal ==undefined ?(
                <>
{/* <CircularProgress className="gx-loader-5" /> */}
<div className="gx-loader-5" style={{textAlign:"center",paddingTop: "1rem" ,paddingLeft:"200px"}}>
		<img src="/assets/images/loader.svg" alt="loader" style={{width:"80px"}}/>
	</div>
    </>
):(

                <div style={{ textAlign: "center", paddingTop: "1rem" ,paddingLeft:"200px"}}>
                <div>
                <img src={noData} height={110} width={110} />
                </div><div ><span style={{ fontStyle: "italic" }}>No Data</span>
                </div></div>
            )}
		</div>
	);
};
export default SiteAudienceDash;
