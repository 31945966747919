import { useMutation } from '@apollo/client';
import { Alert, Button, Col, Form, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import {
	CMSCreateServiceRequirement,
	CMSCreateServiceRequirementVariables,
} from 'src/lib/gql/generated/CMSCreateServiceRequirement';
import {
	CMSDeleteServiceRequirement,
	CMSDeleteServiceRequirementVariables,
} from 'src/lib/gql/generated/CMSDeleteServiceRequirement';
import { CMSGetService_getService_requirements } from 'src/lib/gql/generated/CMSGetService';
import {
	CMSUpdateServiceRequirement,
	CMSUpdateServiceRequirementVariables,
} from 'src/lib/gql/generated/CMSUpdateServiceRequirement';
import {
	CREATE_SERVICE_REQUIREMENT,
	DELETE_SERVICE_REQUIREMENT,
	UPDATE_SERVICE_REQUIREMENT,
} from 'src/lib/gql/mutations/mutations';
import { EditOutlined,DeleteOutlined } from '@ant-design/icons';

interface Props {
	serviceID: string;
	input?: CMSGetService_getService_requirements;
	onFinishAdd?: () => void;
}

const AddServiceRequirement = ({ serviceID, input, onFinishAdd }: Props) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [updateAlert, setUpdateAlert] = useState(false)
	const [createAlert, setCreateAlert] = useState(false)
	const [
		createServiceRequirement,
		{ data: dataMt, loading: loadingMt, error: errorMt },
	] = useMutation<
		CMSCreateServiceRequirement,
		CMSCreateServiceRequirementVariables
	>(CREATE_SERVICE_REQUIREMENT,{
		onCompleted: () => {
			onFinishAdd
			form.resetFields();
		},
	});

	const [
		updateServiceRequirement,
		{ data: dataMtu, loading: loadingMtu, error: errorMtu },
	] = useMutation<
		CMSUpdateServiceRequirement,
		CMSUpdateServiceRequirementVariables
	>(UPDATE_SERVICE_REQUIREMENT,{
		onCompleted: () => {
			onFinishAdd
		},
	});

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
		setIsModalVisible(false);
	};

	const [form] = Form.useForm();

	const onFinish = (values) => {
		let data: CMSCreateServiceRequirementVariables['data'];
		if (!input) {
			data = {
				serviceId: serviceID,
				title: values.title,
				description: values.description,
			};
		} else {
			data = {
				serviceId: serviceID,
				title: values.title,
				description: values.description,
			};
		}

		if (!input) {
			createServiceRequirement({
				variables: {
					data: data,
				},
			}).then(() => {
				form.resetFields();
				setCreateAlert(true)
				setTimeout(() => {
				setCreateAlert(false)
				}, 2000)
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
					}
					setIsModalVisible(false);
				}, 1500);
			});

		} else {
			updateServiceRequirement({
				variables: {
					data: {
						title: values.title,
						description: values.description,
					},
					id: input.id,
				},
			}).then(() => {

				form.resetFields();
				setUpdateAlert(true)
				setTimeout(() => {
				setUpdateAlert(false)
				}, 2000)
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
					}
					setIsModalVisible(false);
				}, 1500);
			});
		}
	};

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	// Delete
	const [
		deleteServiceRequirement,
		{ data: dataMtD, loading: loadingMtD, error: errorMtD },
	] = useMutation<
		CMSDeleteServiceRequirement,
		CMSDeleteServiceRequirementVariables
	>(DELETE_SERVICE_REQUIREMENT);
	const onDelete = () => {
		if (!input?.id) return;
		deleteServiceRequirement({
			variables: {
				id: input?.id,
			},
		}).then(() => {
			setTimeout(() => {
				if (typeof onFinishAdd === 'function') {
					onFinishAdd();
				}
				setIsDeleteModalVisible(false);
			}, 1500);
		});
	};

	return (
		<>
			{/* <Button type="primary" onClick={showModal}>
				{!input ? 'Add Service Requirement' : <EditOutlined/>}
			</Button> */}
			<Button
				type="primary"
				onClick={showModal}
				style={
					input
						? {
								background: 'transparent',
								color: '#5a20ff',
								border: 'none',
								boxShadow: 'none',
						  }
						: {}
				}
			>
				{!input ? 'Add Service Requirement' : <EditOutlined />}
			</Button>
			<Modal
				title={!input ? 'Add Service Requirement' : 'Edit'}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
			>
				{createAlert && !loadingMt && <Alert message="Created" type="success" showIcon />}
				{loadingMt && <Alert message="Submitting..." type="info" showIcon />}
				{errorMt && (
					<Alert
						message={`Submission error! ${errorMt.message}`}
						type="error"
						showIcon
					/>
				)}

				{/* Updating */}
				{updateAlert &&  !loadingMtu && <Alert message="Updated" type="success" showIcon />}
				{loadingMtu && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtu && (
					<Alert
						message={`Submission error! ${errorMtu.message}`}
						type="error"
						showIcon
					/>
				)}
				<Form
					form={form}
					name="addServiceInput"
					onFinish={onFinish}
					scrollToFirstError
					initialValues={
						!input
							? {}
							: {
									serviceId: serviceID,
									title: input.title,
									description: input.description,
							  }
					}
				>
					<Row>
						<Col span={8}>
							<Form.Item
								name={`title`}
								label={<span>Title</span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={22}>
							<Form.Item name={`description`} label={<span>Description</span>}>
								<Input.TextArea />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			{input && (
				<Button
					type="text"
					onClick={() => setIsDeleteModalVisible(true)}
					danger
				>
					<DeleteOutlined/>
				</Button>
			)}
			<Modal
				title={'Delete'}
				visible={isDeleteModalVisible}
				onOk={onDelete}
				onCancel={() => setIsDeleteModalVisible(false)}
				width={300}
			>
				Delete?
				{/* Delete */}
				{dataMtD && <Alert message="Deleted" type="success" showIcon />}
				{loadingMtD && <Alert message="Deleting..." type="info" showIcon />}
				{errorMtD && (
					<Alert
						message={`Submission error! ${errorMtD.message}`}
						type="error"
						showIcon
					/>
				)}
			</Modal>
		</>
	);
};
export default AddServiceRequirement;
