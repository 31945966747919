import React from 'react';
import { Card, Row, Col, Button } from 'antd';
import { RouteList } from 'src/config/constants/routes';
import { Link } from 'react-router-dom';

import { Form, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
};

const formItemLayoutWithOutLabel = {
	wrapperCol: {
		xs: { span: 24, offset: 0 },
		sm: { span: 24, offset: 4 },
	},
};

const AddServiceTimeSlots: React.FC = () => {
	const [form] = Form.useForm();

	return (
		<Card title="Services">
			<div className="gx-flex gx-flex-nowrap gx-mb-2">
				<div className="gx-card-profile">
					<div className="ant-card-head ">
						<Link to={RouteList.services.path} className="gx-mr-4">
							Back
						</Link>

						{/* <span className="ant-card-head-title gx-mb-1">Bookings</span> */}
						{/* <p className="gx-text-grey gx-fs-sm gx-mb-0">Booking List</p> */}
					</div>

					<div className="gx-pt-md-3"></div>
				</div>

				<Row>
					<Col span={24} className="gx-mb-4">
						<h1>Manage time slots</h1>
						<hr />
					</Col>
					<Col span={24} className="gx-mb-4">
						<Form
							{...formItemLayout}
							form={form}
							name="register"
							initialValues={{
								slots: ['2 Hour', '4 Hour', '8 Hour'],
							}}
							scrollToFirstError
						>
							{/* Fields */}
							<Form.List name="slots">
								{(fields, { add, remove }) => {
									return (
										<div>
											{fields.map((field) => (
												<Form.Item
													{...formItemLayoutWithOutLabel}
													label={''}
													required={false}
													key={field.key}
												>
													<Form.Item
														{...field}
														validateTrigger={['onChange', 'onBlur']}
														rules={[
															{
																required: true,
																whitespace: true,
																message: 'Please input Option.',
															},
														]}
														noStyle
													>
														<Input
															placeholder="Option"
															style={{ width: '40%' }}
														/>
													</Form.Item>
													{fields.length > 1 ? (
														<MinusCircleOutlined
															className="dynamic-delete-button"
															style={{ margin: '0 8px' }}
															onClick={() => {
																remove(field.name);
															}}
														/>
													) : null}
												</Form.Item>
											))}
											<Form.Item>
												<Button
													type="dashed"
													onClick={() => {
														add();
													}}
													style={{ width: '100%' }}
												>
													<PlusOutlined /> Add field
												</Button>
											</Form.Item>
										</div>
									);
								}}
							</Form.List>

							<Form.Item {...tailFormItemLayout}>
								<Button type="primary" htmlType="submit">
									Save
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</div>
		</Card>
	);
};
export default AddServiceTimeSlots;
