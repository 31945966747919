import { useQuery, useMutation } from '@apollo/client';
import { Button, Card, Col, Row, Select, message } from 'antd';
import React, { useState, useEffect } from 'react';
import {
	PayoutIntervalEnum,
	UserTypeEnum,
} from 'src/lib/gql/generated/globalTypes';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'src/lib/recoil/userCoil';
import { UPDATE_CHKSETTINGS, UPDATE_PAYOUT_SETTINGS } from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetPayoutSettings } from 'src/lib/gql/queries/payouts';

const { Option } = Select;

const PayoutSettings: React.FC = () => {
	const userAuth = useRecoilValue(userAtom);
	const [payoutSchedule, setPayoutSchedule] = useState<any>();

	const { data: qryDataChk, refetch: refetchQryDataChk } = useQuery(
		CMSQueryGetPayoutSettings,
		{
			fetchPolicy: 'network-only',
		},
	);

	useEffect(() => {
		setPayoutSchedule(qryDataChk?.getPayoutSettings?.payoutSchedule);
	}, [qryDataChk]);

	const [
		updatePayoutSettings,
		{ data: dataEditMtU, loading: loadingEditMtU, error: errorEditMtU },
	] = useMutation(UPDATE_PAYOUT_SETTINGS);

	const handleSave = () => {
		updatePayoutSettings({
			variables: {
				data: {
					payoutSchedule: payoutSchedule,
				},
			},
		})
			.then((result) => {
				refetchQryDataChk();
				message.success('Payout Settings Updated Successfully ');
			})
			.catch((error) => {
				message.error('Error please try again');
			});
	};

	return (
		<Card title={<div>Payout Settings</div>}>
			{/* {loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)} */}
			{/* {userAuth?.userType === UserTypeEnum.ADMIN ||
				userAuth?.rights?.find(
					(x) => x.type === 'sub2' && x.name === 'export-csv',
				) ? ( */}
			<Row
				justify="end"
				style={{
					marginBottom: 16,
					paddingTop: '1rem',
					backgroundColor: '#f6f6f6',
					paddingBottom: '5px',
				}}
			>
				<Col span={20}>Payout Settings</Col>
				<Col span={4}>
					<Button onClick={handleSave}>Save</Button>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<div style={{ display: 'flex' }}>
						Payout Interval :
						<div style={{ paddingLeft: '0.5rem' }}>
							<Select
								value={payoutSchedule}
								style={{ width: 120 }}
								onChange={(value) => setPayoutSchedule(value)}
								getPopupContainer={(trigger) => trigger.parentElement}
							>
								<Option value={PayoutIntervalEnum.ON_JOB_FINISH}>
									{PayoutIntervalEnum.ON_JOB_FINISH}
								</Option>
								<Option value={PayoutIntervalEnum.DAILY}>
									{PayoutIntervalEnum.DAILY}
								</Option>
								<Option value={PayoutIntervalEnum.WEEKLY}>
									{PayoutIntervalEnum.WEEKLY}
								</Option>
							</Select>
						</div>
					</div>{' '}
					<br />
				</Col>
			</Row>
			{/* // ) : (
			// 	<></>
			// )} */}
		</Card>
	);
};
export default PayoutSettings;
