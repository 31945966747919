import React ,{useState}from 'react';
import { Col, Row,Tabs,Card,Select,Space,Modal,DatePicker,Radio} from 'antd';
import CustomerImg from "./images/Customers.png"
import SerPartImg from "./images/ServicePartner.png"
import ServiceImg from "./images/Services.png"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Bar,BarChart,Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CMSQueryGetDirectorsDashboardCounts } from 'src/lib/gql/queries/dashboard';
import { useQuery } from '@apollo/client';
import { CMSQueryGetDirectorsDashboardRevenue,CMSQueryGetCustomerRetentionRateGraph } from 'src/lib/gql/queries/dashboard';
import { RevenueDatePeriodEnum   } from 'src/lib/gql/generated/globalTypes';
import { Link } from 'react-router-dom';
import moment from 'moment';

interface Props {}
const DirectorsDashboard: React.FC = (props: Props) => {
	const Option = Select.Option;
	const[datePeriod,setDatePeriod]=useState<any>()
    const[datePeriodCustomerRate,setDatePeriodRateCustomerRate]=useState<any>()
	// const defaultDate = moment().startOf('year');
    const { data: qryDataDirectDashCounts } = useQuery(
		CMSQueryGetDirectorsDashboardCounts,
	);
    const disabledDate = (current) => {
        // Disable dates that are in the future (including future years)
        return current && current > moment().endOf('year');
      };
 
    const { data: qryDataDirectDashRevenue } = useQuery(
		CMSQueryGetDirectorsDashboardRevenue,
        {
            variables: {
                filters: {
                    datePeriod: datePeriod,
                }
            },
            fetchPolicy: 'network-only',
        }
	);
    const currentYear = new Date().getFullYear();
    const { data: qryCustomerRetensionRate } = useQuery(
		CMSQueryGetCustomerRetentionRateGraph,
        {
            variables: {
                filters: {
                    datePeriod:datePeriodCustomerRate ? datePeriodCustomerRate : String(currentYear),
                }
            },
            fetchPolicy: 'network-only',
        }
        
	);

    const handleDatePeriod=(date)=>{
        setDatePeriod(date)
    }
    const handleDatePeriodCustomerRate=(date,dateString)=>{
        // console.log("dateString",dateString);
        // console.log("dateeeee",date);
        setDatePeriodRateCustomerRate(dateString)
    }
    // console.log("daaa",qryCustomerRetensionRate);
    const data: any = [];
    qryDataDirectDashRevenue?.getDirectorsDashboardRevenue?.revenueGraph?.forEach((revenue) => {
        data.push({
			...revenue,
			name: revenue.name,
            amt: revenue.revenue,
			revenue: revenue.revenue,
		});
    });

    const dataaa : any = [];
    qryCustomerRetensionRate?.getCustomerRetentionRateGraph?.customerRetentionGraph?.forEach((revenue) => {
        dataaa.push({
			...revenue,
			day: revenue.name,
            rate: revenue.value,
		});
    });
  

// const CustomizedLabel = ({ x, y, stroke, value }) => (
//   <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
//     {value}
//   </text>
// );

// const CustomizedAxisTick = ({ x, y, stroke, payload }) => (
//   <g transform={`translate(${x},${y})`}>
//     <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
//       {payload.value}
//     </text>
//   </g>
// );

    return (
     <Card>
        <Row style={{marginRight:"-100PX"}}>
            <Col span={6}>
            <Card style={{borderRadius:"1rem",marginTop:"2rem",paddingTop:"1rem"}}>
                <Row>
                <Col span={10}>   
                <img src={CustomerImg}  alt="customer" style={{width:"50px",height:"50px"}}/>
                 </Col>
                
                <Col span={14}> <b>{qryDataDirectDashCounts?.getDirectorsDashboardCounts?.totalCustomers}</b> <br/>
                <Link to={"/users"}> <span>Total Customers </span></Link> </Col>
                </Row>
                <hr/>
                <Row> 
                <Col span={10}> 
                <img src={SerPartImg} alt="service partner" style={{width:"50px",height:"50px"}}/>
                 </Col> 
                <Col span={14}> <b>{qryDataDirectDashCounts?.getDirectorsDashboardCounts?.totalPartners}</b> <br/>
                <Link to={"/partners"}><span> Total Partners</span> </Link> </Col>
                </Row>
                <hr/>
                <Row>
                <Col span={10}>
                <img src={ServiceImg} alt="service" style={{width:"50px",height:"50px"}}/>
                 </Col> 
                <Col span={14}> <b>{qryDataDirectDashCounts?.getDirectorsDashboardCounts?.totalServices}</b> <br/>
                <Link to={"/services"}><span>Total Services</span></Link> </Col>
                </Row>
            </Card>
            </Col>
            <Col span={16}>
                <Row>
            <Col span={14} style={{paddingTop:"2rem",marginLeft:"-25px"}}>
            <ResponsiveContainer width="140%" height={250}>
          <LineChart
            width={500}
            height={200}
            data={data}
            syncId="anyId"
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="revenue" stroke="#8884d8" fill="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
            {/* <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" height={60} tick={CustomizedAxisTick} />
          <YAxis />
          <Tooltip />
          <Legend />
           <Line type="monotone" dataKey="pv" stroke="#8884d8" label={<Label content={<CustomizedAxisTick />} position="top" />} />
          <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer> */}
      </Col>
      	{/* <div style={{height: "244px",marginTop: "27px", borderLeft: "2px solid #ebebeb",marginLeft:"100px"}}/> */}
        <Col span={10}>
      <div style={{float:"right", paddingTop:"3rem",textAlign:"center"}} >
                  <Select
					style={{
					width: '150px',
					}}
					size="small"
					placeholder="Select Date Period"
					onChange={handleDatePeriod} getPopupContainer={(trigger) => trigger.parentElement}
					optionLabelProp="label"
					value={datePeriod}
					defaultValue={"Life Time"}
				>
					<Option value={null}label="Life Time">
					<Space>
						Life Time
					</Space>
					</Option>
                    <Option value={"THIS_YEAR"}label="THIS_YEAR">
					<Space>
					{RevenueDatePeriodEnum .THIS_YEAR}
					</Space>
					</Option>
                    <Option value={"THIS_MONTH"}label="THIS_MONTH">
					<Space>
					{RevenueDatePeriodEnum .THIS_MONTH}
					</Space>
					</Option>
                    <Option value={"THIS_WEEK"}label="THIS_WEEK">
					<Space>
                    {RevenueDatePeriodEnum .THIS_WEEK}
					</Space>
					</Option>
                    <Option value={"LAST_3_MONTHS"}label="LAST_3_MONTHS">
					<Space>
                    {RevenueDatePeriodEnum .LAST_3_MONTHS}
					</Space>
					</Option>
                    <Option value={"LAST_YEAR"}label="LAST_YEAR">
					<Space>
                    {RevenueDatePeriodEnum .LAST_YEAR}
					</Space>
					</Option>
                    <Option value={"LAST_MONTH"}label="LAST_MONTH">
					<Space>
                    {RevenueDatePeriodEnum .LAST_MONTH}
					</Space>
					</Option>
                    <Option value={"LAST_WEEK"}label="LAST_WEEK">
					<Space>
					{RevenueDatePeriodEnum .LAST_WEEK}
					</Space>
					</Option>
							
				</Select>	<br/><br/><br/>
                <span style={{paddingTop:"2rem",  fontSize:"15px"  }}>Total Revenue</span><br/>
                <h2>₹ {qryDataDirectDashRevenue?.getDirectorsDashboardRevenue?.totalRevenue}</h2>

                </div></Col>
                </Row>

            </Col>
        </Row>
        <hr/>
        <Row>
            <Col span={20}>
            <span style={{paddingTop:"2rem",  fontSize:"15px"  }}>Customer Retention Rate</span><br/><br/>
            <BarChart width={730} height={250} data={dataaa} margin={{top: 20, right: 20, bottom: 20, left: 20}} >
          <XAxis dataKey="day" />
             <YAxis />
             <Tooltip />
             <Bar dataKey="rate" fill="#8884d8" />
            </BarChart>
            </Col>
            <Col span={4}>
            <div style={{float:"right", paddingTop:"3rem",textAlign:"center"}} >
            <DatePicker onChange={handleDatePeriodCustomerRate} picker="year"   disabledDate={disabledDate} defaultValue={moment(`${currentYear}-01-01`)}/>
                </div>
            </Col>
        </Row>
     </Card>
    )}
 export default DirectorsDashboard;