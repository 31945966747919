import { useQuery } from '@apollo/client';
import {
	Button,
	Alert,
	Input,
	Modal,
	Table,
	DatePicker,
	Card,
	Col,
	Row,
	Pagination,
	Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import Meta from 'antd/lib/card/Meta';
import React from 'react';
import AddCouponCode from 'src/components/coupons/add';
import {
	CMSGetCoupons,
	CMSGetCouponsVariables,
} from 'src/lib/gql/generated/CMSGetCoupons';
import { CMSQueryGetCoupons, CMSQueryExportCoupons } from 'src/lib/gql/queries';
import moment from 'moment';
import { start } from 'repl';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { userAtom } from 'src/lib/recoil/userCoil';
import { useRecoilValue } from 'recoil';
import { DeleteOutlined } from '@ant-design/icons';
import { DELETE_COUPON } from 'src/lib/gql/mutations/mutations';
import { useMutation } from '@apollo/client';
import { CMSGetCoupons_getCoupons } from 'src/lib/gql/generated/CMSGetCoupons';
import { log } from 'console';
import { CSVLink } from 'react-csv';
import { DownloadOutlined } from '@ant-design/icons';
import CircularProgress from 'src/components/CircularProgress';

const DupManageCouponCodes: React.FC = () => {
	const [isModalVisible, setIsModalVisible] = useState<any>(false);
	const [startDate, setStartDate] = useState<any>(null);
	const [endDate, setEndDate] = useState<any>(null);
	const [dateRange, setDateRange] = useState<any>([]);
	const [filterName, setFilterName] = useState('');
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const userAuth = useRecoilValue(userAtom);
	const [deleteConfirm, setDeleteConfirm] = useState<any>(false);
	const [deleteAlert, setDeleteAlert] = useState(false);
	const [dataInfo, setDataInfo] = useState<any>();
	const [deleteId, setDeleteId] = useState();
	const {
		loading,
		data: qryData,
		called,
		error,
		refetch: refetchQryData,
	} = useQuery<CMSGetCoupons, CMSGetCouponsVariables>(CMSQueryGetCoupons, {
		variables: {
			showAll: true,
			pagination: pagination,
			filters: {
				name: filterName,
				fromDate: startDate ? startDate.toISOString() : null,
				toDate: endDate ? endDate.toISOString() : null,
			},
		},
		notifyOnNetworkStatusChange: true
	});

	const { data: qryDataa, refetch: refetchQryDataa } = useQuery(
		CMSQueryExportCoupons,
		{
			variables: {
				filters: {
					name: filterName,
					fromDate: startDate ? startDate.toISOString() : null,
					toDate: endDate ? endDate.toISOString() : null,
				},
			},
		},
	);
	// Delete
	const [
		deleteServiceInputOption,
		{ data: dataMtD, loading: loadingMtD, error: errorMtD },
	] = useMutation(DELETE_COUPON);

	useEffect(() => {
		if (qryData) {
			setDataInfo(qryData?.getCoupons);
		}
		
	}, [filterName, dateRange, qryData]);

	const { RangePicker } = DatePicker;

	const pageSizeOptions = ['10', '20', '50'];
	const handleDate = (dates: any, dateStrings: [string, string]) => {
		if (dates && dates.length === 2) {
			const startDates = moment(dates[0]).startOf('day').toDate();
			const endDates = moment(dates[1]).endOf('day').toDate();
			setStartDate(startDates);
			setEndDate(endDates);
			setDateRange([dates[0].startOf('day'), dates[1].endOf('day')]);
		} else if (dates && dates.length === 1) {
			const startDates = moment(dates[0]).startOf('day').toDate();
			setStartDate(startDates);
			setEndDate(null);
		} else {
			setStartDate(null);
			setEndDate(null);
		}
	};

	const ClearAll = () => {
		setFilterName('');
		setDateRange([]);
		setPagination((prevState) => ({
			...prevState,
			pageNumber: 1,
		}));
		setStartDate(null);
		setEndDate(null);
	};
	const [dataVal, setDataVal] = useState<any>(false);
	const [dataValss, setDataValss] = useState<any>(false);

	const onEdit = (data: any) => {
		//    setDataValss(data)
		//    setDataVal(true)
		//    {dataVal && dataValss&&

		<AddCouponCode input={data} onFinishAdd={refetchQryData} />;
		// }
		{
			/* <AddCouponCode input={data} onFinishAdd={refetchQryData} /> */
		}
	};
	const onDelete = (data: any) => {
		setDeleteConfirm(true);
		setDeleteId(data?.id);
	};
	const onDeleteOk = () => {
		deleteServiceInputOption({
			variables: {
				id: deleteId,
			},
		}).then(() => {
			setDeleteAlert(true);
			setTimeout(() => {
				// if (typeof onFinishAdd === 'function') {
				refetchQryData();
				// }
				setDeleteConfirm(false);
				setDeleteAlert(false);
			}, 4000);
		});
	};
	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: any) => (
				<Tooltip title={<span><strong>Terms:</strong> {record.terms.replace(/<[^>]*>/g, '')}</span>}>
  			<span>{text}</span>
			</Tooltip>
			),
		},
		{
			title: 'Benefit',
			dataIndex: '',
			key: 'value',
			align: 'center' as const,
			render: (text: string, record: any) => (
				<span>
					{record?.discountType === 'FIXED_AMOUNT' ? (
						<>₹{record?.value}</>
					) : (
						<>{record?.value}%</>
					)}
				</span>
			),
		},

		{
			title: 'Max Use Per Customer',
			dataIndex: 'maxUsePerCustomer',
			key: 'maxUsePerCustomer',
			align: 'center' as const,
		},
		{
			title: 'Start Date',
			dataIndex: 'startDate',
			key: 'startdate',
			width: 180,
			align: 'center' as const,
			render: (text: string, record: any) => (
				<span>
					{record?.startDate ? moment(record?.startDate).format('lll') : '-'}
				</span>
			),
		},
		{
			title: 'End Date',
			dataIndex: 'endDate',
			key: 'enddate',
			width: 180,
			align: 'center' as const,
			render: (text: string, record: any) => (
				<span>
					{record?.endDate ? moment(record?.endDate).format('lll') : '-'}
				</span>
			),
		},
		{
			title: 'Usage Limit',
			dataIndex: 'usageLimit',
			key: 'value',
			align: 'center' as const,
		},

		{
			title: 'Min Order Val',
			dataIndex: 'minOrder',
			key: 'minvalue',
			align: 'center' as const,
			render: (text: string, record: any) => <span>₹{record?.minOrder}</span>,
		},
		{
			title: 'Status',
			dataIndex: '',
			key: 'minvalue',
			align: 'center' as const,
			render: (text, record: any) => {
				let color = 'white';
				if (record?.enabled === false) {
					color = 'red';
				} else {
					color = 'green';
				}
				return (
					<div
						style={{
							height: '20px',
							width: '20px',
							background: color,
							borderRadius: '50%',
							border: '1px solid rgba(0,0,0,0.05)',
							marginLeft: '20px',
							textAlign: 'center',
						}}
					></div>
				);
			},
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			width: 80,
			render: (text: string, record: any) => (
				<div style={{ float: 'right' }}>
					{userAuth?.userType === UserTypeEnum.ADMIN ||
					userAuth?.rights?.find(
						(x) => x.type === 'sub2' && x.name === 'edit-coupon',
					) ? (
						<span onClick={(e) => onEdit(record)}>
							<AddCouponCode
								key={record.id}
								input={record}
								onFinishAdd={refetchQryData}
							/>
						</span>
					) : (
						// <Button style={{paddingTop:"1rem"}} type="link" onClick={()=>onEdit(record)} >
						//       <AddCouponCode
						//          key={record.id}
						//          input={record}
						//         onFinishAdd={refetchQryData}
						//      />
						// </Button>

						''
					)}
				</div>
			),
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			width: 80,
			render: (text: string, record: any) => (
				<div style={{ float: 'right' }}>
					{userAuth?.userType === UserTypeEnum.ADMIN ||
					userAuth?.rights?.find(
						(x) => x.type === 'sub2' && x.name === 'delete-coupon',
					) ? (
						<Button
							style={{ paddingTop: '1rem' }}
							type="link"
							onClick={() => onDelete(record)}
							icon={<DeleteOutlined />}
						/>
					) : (
						''
					)}
				</div>
			),
		},
	];

	const csvData =
		qryDataa?.exportCoupons?.map((record) => {
			// Map the data source to match the structure of the CSV
			return {
				Name: record.name,
				Benefit:
					record?.discountType === 'FIXED_AMOUNT'
						? `₹${record?.value}`
						: `${record?.value}%`,
				'Max Use Per Customer': record.maxUsePerCustomer,
				'Start Date': new Date(record.startDate).toLocaleString('en-US', {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					second: 'numeric',
					hour12: true,
				}),
				'End Date': new Date(record.endDate).toLocaleString('en-US', {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					second: 'numeric',
					hour12: true,
				}),

				'Usage Limit': record.usageLimit,
				'Min Order Val': `₹${record.minOrder}`,
				Status: record?.enabled === false ? 'No' : 'Yes',
			};
		}) || [];

	const currentDate = new Date();
	const formattedDate = currentDate.toISOString().split('T')[0];
	const formattedTime = currentDate
		.toTimeString()
		.split(' ')[0]
		.replace(/:/g, '');
	const hour = parseInt(formattedTime.substring(0, 2), 10);
	const minute = parseInt(formattedTime.substring(2, 4), 10);
	const second = parseInt(formattedTime.substring(4, 6), 10);

	const filename = `Coupon Code-${formattedDate}_${hour}:${minute}:${second}.csv`;

	return (
		<>
			<Card
				title="Manage Coupon Codes"
				extra={
					<>
						{userAuth?.userType === UserTypeEnum.ADMIN ||
						userAuth?.rights?.find(
							(x) => x.type === 'sub2' && x.name === 'add-coupon',
						) ? (
							<AddCouponCode key={'add'} onFinishAdd={refetchQryData} />
						) : (
							''
						)}
					</>
				}
			>
				<Row
					style={{
						marginBottom: '16px',
						paddingTop: '0.2rem',
						paddingBottom: '5px',
					}}
				>
					<Col span={24}>
						<div
							style={{
								justifyContent: 'center',
								display: 'flex',
							}}
						>
							<div>
								<Input
									placeholder="Search by Name"
									value={filterName}
									style={{ width: '250px' }}
									allowClear
									onChange={(e) => {
										setFilterName(e.target.value);
									}}
								/>
							</div>
							<div style={{ paddingLeft: '0.5rem' }}>
								<RangePicker
									onChange={handleDate}
									allowClear
									value={dateRange}
								/>
							</div>
							<br />

							<div style={{ paddingLeft: '0.5rem' }}>
								<Button onClick={ClearAll} className="primary">
									Clear All
								</Button>
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<div style={{ textAlign: 'center' }}>
							{startDate ? moment(startDate).format('lll') : ''}
							{startDate ? '-' : ''}{' '}
							{endDate ? moment(endDate).format('lll') : ''}
						</div>
					</Col>
				</Row>
				<Row justify="end" style={{ marginBottom: 16 }}>
					{userAuth?.userType === UserTypeEnum.ADMIN ||
					userAuth?.rights?.find(
						(x) => x.type === 'sub2' && x.name === 'coupon-export-csv',
					) ? (
						<CSVLink
							data={csvData}
							filename={filename}
							className="ant-btn ant-btn-primary"
							target="_blank"
						>
							<DownloadOutlined /> Export CSV
						</CSVLink>
					) : (
						<></>
					)}
				</Row>
				<Row
					style={{
						marginBottom: 16,
						paddingTop: '1rem',
						backgroundColor: '#f6f6f6',
					}}
				>
					<Col span={24} style={{ textAlign: 'center' }}>
						<Pagination
							key="pagination"
							current={pagination.pageNumber}
							pageSize={pagination.pageSize}
							total={dataInfo?.pageInfo?.totalCount}
							showTotal={(total) => `Total ${total} items`}
							onChange={(page, pageSize) => {
								setPagination({
									pageNumber: page,
									pageSize: pageSize ? +pageSize : pagination.pageSize,
								});
							}}
							pageSizeOptions={pageSizeOptions}
							showSizeChanger
						/>
					</Col>
				</Row>

				{loading ? (
					// <div>Loading...</div>
					<CircularProgress className="gx-loader-400" />
				) : dataInfo?.data.length === 0 ? (
					<div>No data in applied filter.</div>
				) : (
					<Table
						className="gx-table-responsive"
						dataSource={dataInfo?.data}
						columns={columns}
						pagination={false}
					/>
				)}
			</Card>

			<Modal
				title={'Delete'}
				visible={deleteConfirm}
				onOk={onDeleteOk}
				onCancel={() => setDeleteConfirm(false)}
				width={300}
			>
				Delete?
				{/* Delete */}
				{deleteAlert && !loadingMtD && !errorMtD && (
					<Alert message="Deleted" type="success" showIcon />
				)}
				{loadingMtD && <Alert message="Deleting..." type="info" showIcon />}
				{errorMtD && (
					<Alert
						message={`Submission error! ${errorMtD.message}`}
						type="error"
						showIcon
					/>
				)}
			</Modal>
		</>
	);
};

export default DupManageCouponCodes;
