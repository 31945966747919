import React from 'react';

interface Props {
	className?: string;
}

const CircularProgress = ({ className }: Props) => (
	<div className={`loader ${className}`} style={{textAlign:"center"}}>
		<img src="/assets/images/loader.svg" alt="loader" style={{width:"150px"}}/>
	</div>
);
export default CircularProgress;
