import { useMutation } from '@apollo/client';
import { Alert, Button, Col, Form, Modal, Row, Select } from 'antd';
import React, { useState,useEffect } from 'react';
import { CMSGetPartner_getUser } from 'src/lib/gql/generated/CMSGetPartner';
import {
	CMSUpdatePartnerDocuments,
	CMSUpdatePartnerDocumentsVariables,
} from 'src/lib/gql/generated/CMSUpdatePartnerDocuments';
import { DocumentTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { UPDATE_PARTNER_DOCUMENTS } from 'src/lib/gql/mutations/mutations';
import { UploadPublicImage } from '../shared/UploadPublicImage';
import { log } from 'console';

interface Props {
	input?: CMSGetPartner_getUser | null;
	onFinishAdd?: () => void;
}

const UpdatePartnerDocuments: React.FC<Props> = ({ input, onFinishAdd }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [uploadWarning, setUploadWarning] = useState(false);

	const [documentType, setDocumentType] = useState(DocumentTypeEnum.AADHAR);
	const [documentURL, setDocumentURL] = useState<any>('');
	const [documentURLBack, setDocumentURLBack] = useState<any>('');

	// Update
	const [
		updateDetails,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation<
		CMSUpdatePartnerDocuments,
		CMSUpdatePartnerDocumentsVariables
	>(UPDATE_PARTNER_DOCUMENTS);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		form.submit();
	};
	useEffect(() => {
		setTimeout(() => {
			setUploadWarning(false);
		}, 3000);

	},[uploadWarning])

	const handleCancel = () => {
		if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
		setIsModalVisible(false);
	};

	const [form] = Form.useForm();

	const onFinish = (values) => {
		if (input ) {
			if(documentURL.length>0 &&documentURLBack.length>0 ){
			setUploadWarning(false)
			updateDetails({
				variables: {
					partnerId: input?.id,
					document: {
						type: documentType,
						medias: [documentURL, documentURLBack],
					},
				},
			}).then(() => {
				form.resetFields();
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
						setDocumentType(DocumentTypeEnum.AADHAR);
						setDocumentURL('');
					}
					setIsModalVisible(false);
				}, 1500);
			});
	}else{
		setUploadWarning(true)
	}}
	};
	return (
		<>
		<div style={{paddingRight:"1rem"}}> 
			<Button type="primary" onClick={showModal}>
				Add
			</Button></div>
			<Modal
				title={!input ? 'Add' : 'Edit'}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
			>
				{/* Updating */}
				{dataMtU && <Alert message="Updated" type="success" showIcon />}
				{loadingMtU && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtU && (
					<Alert
						message={`Submission error! ${errorMtU.message}`}
						type="error"
						showIcon
					/>
				)}
				{uploadWarning && (
						<Alert
			        message="Please upload front and back side "
			     type="warning"
			      showIcon
		         />
				 )}
		       <br/>
				<Form
					form={form}
					name="addCoupon"
					onFinish={onFinish}
					scrollToFirstError
					initialValues={
						!input
							? {}
							: {
									type: documentType,
							  }
					}
				>
					<Row>
						<Col span={24}>
							<Form.Item
								label="Type"
								name="type"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Select defaultValue={documentType} onChange={setDocumentType}>
									{(
										Object.keys(
											DocumentTypeEnum,
										) as (keyof typeof DocumentTypeEnum)[]
									).map((key) => {
										return (
											<Select.Option key={key} value={DocumentTypeEnum[key]}>
												{DocumentTypeEnum[key]}
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label="Upload Front">
								<Form.Item name="icon" valuePropName="icon" noStyle 	
							>
									<UploadPublicImage
										onFinishAdd={(info) =>
											setDocumentURL(info?.file.response.data.id)
										}
									/>
								</Form.Item>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label="Upload Back">
								<Form.Item name="icon" valuePropName="icon" noStyle
								>
									<UploadPublicImage
										onFinishAdd={(info) =>
											setDocumentURLBack(info?.file.response.data.id)
										}
									/>
								</Form.Item>
							</Form.Item>
						</Col>

						<Col span={2}></Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};
export default UpdatePartnerDocuments;
