import { gql } from '@apollo/client';

export const CMSQueryGetPersonnel = gql`
	query CMSGetPersonnel(
		$pagination: PaginationGqlInput!
		$filters: PersonnelQueryGqlInput!
	) {
		getPersonnel(pagination: $pagination, filters: $filters) {
			data {
                id
                name
                uid
                phone
                rank
                isActive
				createDateTime
				totalBookings
				openBookings
            }
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;

export const CMSQueryGetTeams = gql`
	query CMSGetTeams(
		$pagination: PaginationGqlInput!
		$filters: TeamQueryGqlInput!
	) {
		getTeams(pagination: $pagination, filters: $filters) {
			data {
				id
				uid
				name
				partnerId
				strength
				members {
					id
					name
					uid
					phone
					rank
					isActive
				}
				isActive
				partner {
					id
					name
					email
					phone
					dob
					gender
					uid
				}
			}
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;

export const CMSQueryGetChkSettings= gql`
	query CMSgetChkSettings{
	getChkSettings {
		bufferTimeValue
		bufferTimeType
		cutOffTimeValue
		cutOffTimeType
	}
}
`;