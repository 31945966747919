import { useMutation, useQuery } from '@apollo/client';
import {
	Card,
	Col,
	Input,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Table,
	Button,
	Modal,
	Alert,
	Form,
	Switch,
	Radio,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import {
	CMSGetUsers,
	CMSGetUsersVariables,
} from 'src/lib/gql/generated/CMSGetUsers';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import {
	DELETE_SUB_ADMIN,
	UPDATE_SUB_ADMIN,
} from 'src/lib/gql/mutations/mutations';
import {
	CMSQueryGetRights,
	CMSQueryGetRoles,
} from 'src/lib/gql/queries/rights';
import {
	CMSQueryGetSubAdmins,
	CMSQueryGetUsers,
} from 'src/lib/gql/queries/users';
import { CaretLeftOutlined } from '@ant-design/icons';
import './admins.css';

const { Option } = Select;

function countCommonElements(arr1, arr2) {
	// Initialize a counter variable
	let count = 0;

	// Loop through each element in arr1
	for (let i = 0; i < arr1.length; i++) {
		// Check if the element exists in arr2
		if (arr2.includes(arr1[i])) {
			// Increment the counter if it does
			count++;
		}
	}

	// Return the count of common elements
	return count;
}

const Admins: React.FC = () => {


	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [filterName, SetFilterName] = useState('');
	const [filterPhone, SetFilterPhone] = useState('');
	const [accountType, SetAccountType] = useState(UserTypeEnum.CUSTOMER);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const [deletingAdmin, setDeletingAdmin] = useState('');
	const [deletingAdminData, setDeletingAdminData] = useState<any>();
	const [selectedAdmin, setSelectedAdmin] = useState<any>('');
	const [deleteAlert, setDeleteAlert] = useState(false)

	// const { data: qryData, loading } = useQuery<
	// 	CMSGetUsers,
	// 	CMSGetUsersVariables
	// >(CMSQueryGetUsers, {
	// 	variables: {
	// 		pagination: pagination,
	// 		filters: {
	// 			type: accountType,
	// 			name: filterName,
	// 		},
	// 	},
	// });

	const {
		data: qryData,
		loading,
		refetch: refetchQryData,
	} = useQuery(CMSQueryGetSubAdmins, {
		variables: {
			pagination: pagination,
			filters: {
				// type: accountType,
				name: filterName,
			},
		},
		fetchPolicy: 'network-only',
	});

	// Get All Rights
	const { data: rightsData } = useQuery(CMSQueryGetRights);

	const [mainPagesRights, setMainPagesRights] = useState<any>([]);
	const [subPagesRights, setSubPagesRights] = useState<any>([]);
	const [rightsOfPageOrSubpage, setRightsOfPageOrSubpage] = useState<any>([]);
	const [
		subPagesRightsOfSelectedMainPage,
		setSubPagesRightsOfSelectedMainPage,
	] = useState<any>([]);
	const [selectedMainPageRight, setSelectedMainPageRight] = useState<any>();

	useEffect(() => {
		//   console.log('rightsData---', rightsData.getRights)

		const mainPages = rightsData?.getRights.filter((x) => x.type === 'main');
		setMainPagesRights(mainPages);

		const subPages = rightsData?.getRights.filter((x) => x.type === 'sub');
		setSubPagesRights(subPages);

		const rightsUnderPageOrSubpage = rightsData?.getRights.filter(
			(x) => x.type === 'sub2',
		);
		setRightsOfPageOrSubpage(rightsUnderPageOrSubpage);
	}, [rightsData]);

	const [
		deleteSubAdmin,
		{ data: dataMtD, loading: loadingMtD, error: errorMtD },
	] = useMutation(DELETE_SUB_ADMIN);

	const navigate = useNavigate();

	const handleOpenDeleteModal = (id, data) => {
		setDeletingAdmin(id);
		setDeletingAdminData(data);
		setIsDeleteModalVisible(true);
	};

	const handleDeleteSubAdmin = () => {
		deleteSubAdmin({
			variables: {
				id: deletingAdmin,
			},
		}).then((res) => {
			setIsDeleteModalVisible(false);
			setDeletingAdminData(null);
			refetchQryData();
		});
		setDeleteAlert(true)
		setTimeout(() => {
		setDeleteAlert(false)
		}, 4000)
	};


	const [isModalVisible, setIsModalVisible] = useState(false);
	const showRightsModal = (adminData) => {
		setSelectedAdmin(adminData);
		setShowRightsSaveSuccessAlert(false);

		const rightsIdsOfSelectedAdmin = adminData?.rights?.map((x) => x.id);
		setRights(rightsIdsOfSelectedAdmin);
		setIsModalVisible(true);
	};

	const [updateSubAdmin, { data: dataMt, loading: loadingMt, error: errorMt }] =
		useMutation(UPDATE_SUB_ADMIN);

	const [showRightsSaveSuccessAlert, setShowRightsSaveSuccessAlert] =
		useState(false);

	const [form2] = Form.useForm();

	const [rights, setRights] = useState<string[]>([]);

	const onUpdateAdminFinish = (value) => {

		updateSubAdmin({
			variables: {
				id: selectedAdmin?.id,
				data: {
					// name: adminValues.name,
					// email: adminValues.email,
					// phone: adminValues.phone,
					// password: adminValues.password,
					// role: adminValues.role,
					rights: rights,
				},
			},
		}).then((res) => {
			// navigate('/partners/' + res?.data?.registerPartnerByAdmin?.id);

			setShowRightsSaveSuccessAlert(true);
			form2.resetFields();
			setIsModalVisible(false);
			refetchQryData();
			// dataMt = null
		});
	};

	// old
	// const handleRightsSelection = (value, checked) => {
	// 	if (checked) {
	// 		setRights([...rights, value]);
	// 	} else {
	// 		const updatedArray = rights.filter((item) => item !== value);
	// 		setRights(updatedArray);
	// 	}
	// };
	const columns = [
		{
			title: 'Name',
			dataIndex: 'user',
			key: 'user',
			width: 200,
			render: (user) => <span className="">{user.name} </span>,
		},
		{
			title: 'Email',
			dataIndex: 'user',
			key: 'email',
			width: 200,
			align: 'center' as const,
			render: (user) => <span>{user.email} </span>,
		},
		{
			title: 'Mobile Number',
			dataIndex: 'user',
			key: 'phone',
			width: 200,
			align: 'center' as const,
			render: (
				text,
				record,  
			) =>  {
				const formatPhoneNumber = (phoneNumber) => {
					phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
					if (phoneNumber?.startsWith('91') || phoneNumber?.length >= 10) {
					  return ` ${phoneNumber?.slice(0,5)} ${phoneNumber?.slice(5)}`;
					} else {
					  return phoneNumber;
					}
				  };
				const inputPhoneNumber = record?.user?.phone
				const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);
				return(
				<>
				{formattedPhoneNumber} 
				</>
			);
		},
		},
		{
			title: 'Account Status',
			dataIndex: 'user',
			width: 200,
			align: 'center' as const,
			render: (user) => (
				<span>{user?.userType === 'SUB_ADMIN' ? 'Active' : 'Pending'}</span>
			),
		},

		{
			title: 'Action',
			dataIndex: 'key',
			key: 'id',
			width: 200,
			align: 'center' as const,
			render: (text, record) => (
				<>
					<span>
						<Link to={RouteList?.admins?.path + '/' + record?.id}>
							<i className="icon icon-edit" style={{ marginRight: '20px',color:"#5143dc" }} />
						</Link>
					</span>
					{record?.cannotDelete ?(
						""
					):(		
					<span>
						<a onClick={() => showRightsModal(record)}>
							<i
								className="icon icon-ckeditor"
								style={{ marginRight: '20px',color:"#232222" }}
							/>
						</a>
					</span>
					)}
					{record?.cannotDelete ?(
						""
					):(
					<span>
						<a onClick={() => handleOpenDeleteModal(record?.id, record)}>
							<i className="icon icon-trash" style={{ marginRight: '20px' }} />
						</a>
					</span>
					)}

				</>
			),
		},
	];

	const handleRightsSelection = (value, checked, parentId: any) => {
		
		let count = 0;

		const subPageData = subPagesRights.find((x) => x.id == parentId);

		const mainParentData = mainPagesRights.find(
			(x) => x.id == subPageData?.parentId,
		);
		
		if (parentId) {
			const subPagesWithThisParentId = subPagesRights.filter(
				(x) => x.parentId == parentId,
			);
			const innerRightsWithThisParentId = rightsOfPageOrSubpage.filter(
				(x) => x.parentId == parentId,
			);

			if (subPagesWithThisParentId.length) {
				const subPagesRightsIdsWithThisParentId = subPagesWithThisParentId.map(
					(i) => i.id,
				);
				count = countCommonElements(rights, subPagesRightsIdsWithThisParentId);
			} else if (innerRightsWithThisParentId.length) {
				const innerRightsIdsWithThisParentId = innerRightsWithThisParentId.map(
					(i) => i.id,
				);
				count = countCommonElements(rights, innerRightsIdsWithThisParentId);
			}
		}
		const isSubPageRight = subPagesRights.find((x) => x.id == value);

		if (checked) {
			if (parentId && count == 0) {
				const isMainPageAdded = rights.filter((x) => x == mainParentData?.id);
				if (isMainPageAdded?.length) {
					setRights([...rights, parentId, value]);
					
				} else {
					
					setRights([...rights, parentId, value, mainParentData?.id]);
				}
			} else {
				setRights([...rights, value]);
			}
		} else {
			let updatedArray;
			if (parentId && count == 1) {
				updatedArray = rights.filter((item) => item !== value);
				updatedArray = updatedArray.filter((item) => item !== parentId);
				updatedArray = updatedArray.filter(
					(item) => item !== mainParentData?.id,
				);
			} else {
				updatedArray = rights.filter((item) => item !== value);
			}
			if(updatedArray){
				setRights(updatedArray);
			}
		}
	};
	const handleMainPageRadio = (e) => {
		const subPagesOfSelectedMainPage = subPagesRights.filter(
			(x) => x.parentId == e?.target?.value,
		);
		const selectedMainPage = mainPagesRights.find(
			(x) => x.id == e?.target?.value,
		);
		setSelectedMainPageRight(selectedMainPage);

		setSubPagesRightsOfSelectedMainPage(subPagesOfSelectedMainPage);
	};

	useEffect(() => {
		if (
			Array.isArray(mainPagesRights) &&
			mainPagesRights.length &&
			!selectedMainPageRight
		) {
			const subPagesOfSelectedMainPage = subPagesRights.filter(
				(x) => x.parentId == mainPagesRights[0]?.id,
			);
			const selectedMainPage = mainPagesRights.find(
				(x) => x.id == mainPagesRights[0]?.id,
			);
			setSelectedMainPageRight(selectedMainPage);

			setSubPagesRightsOfSelectedMainPage(subPagesOfSelectedMainPage);
		}
	}, [mainPagesRights]);

	return (
		// <Card title="Admins">
		<Card
			title={<div>Admins</div>}
			extra={[
				// <Link to={RouteList.addAdmin.path} key={1}>
				<>
					<Button
						type="primary"
						className="text-align-right"
						style={{ marginRight: '10px' }}
						onClick={() => navigate(RouteList.adminRoles.path)}
					>
						Roles
					</Button>
					<Button
						type="primary"
						className="text-align-right"
						onClick={() => navigate(RouteList.addAdmin.path)}
						key={1}
					>
						Add New Admin
					</Button>
				</>,
				// </Link>,
			]}
		>
			{/* </Card> */}
			{/* <Row justify="end">
				<Link to={RouteList.adminRoles.path}>
					<Button
						type="primary"
						className="text-align-right"
						style={{ marginRight: '10px' }}
					>
						Roles
					</Button>
				</Link>
				<Link to={RouteList.addAdmin.path}>
					<Button type="primary" className="text-align-right">
						Add New Admin
					</Button>
				</Link>
			</Row> */}

			<Row style={{marginBottom: "16px",
   							 paddingTop: "0.2rem",
   							 paddingBottom: "5px"}}>
				<Col span={24}>
				<div style={{textAlign:"center"}}>
					<Input  
						style={{width:"250px"}}
						placeholder="Search by Name"
						value={filterName}
						allowClear
						onChange={(e) => SetFilterName(e.target.value)}
					/>
					</div>
				</Col>
				</Row>
				<Row style={{ marginBottom: 16 ,paddingTop:"1rem",
							backgroundColor: "#f6f6f6",
							paddingBottom: "5px"}}>					
				<Col span={24} style={{textAlign:"center"}}>
					<Pagination
						key="pagination"
						defaultCurrent={qryData?.getSubAdmins?.pageInfo.currentPage}
						current={qryData?.getSubAdmins?.pageInfo.currentPage}
						pageSize={pagination.pageSize}
						total={qryData?.getSubAdmins?.pageInfo.totalCount}
						showTotal={(total) => `Total ${total} items`}
						onChange={(page, pageSize) => {
							setPagination({
								pageNumber: page,
								pageSize: pageSize ? +pageSize : pagination.pageSize,
							});
						}}
						showSizeChanger
					/>
				</Col>
			</Row>

			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}

			<Table
				className="gx-table-responsive"
				columns={columns}
				dataSource={qryData?.getSubAdmins?.data}
				pagination={false}
			/>

			<Modal
				title={'Delete'}
				visible={isDeleteModalVisible}
				onOk={handleDeleteSubAdmin}
				onCancel={() => setIsDeleteModalVisible(false)}
				width={300}
			>
				Delete {deletingAdminData?.user?.name}?{/* Delete */}
				{deleteAlert &&  !loadingMtD && !errorMtD&&<Alert message="Deleted" type="success" showIcon />}
				{loadingMtD && <Alert message="Deleting..." type="info" showIcon />}
				{errorMtD && (
					<Alert
						message={`Submission error! ${errorMtD.message}`}
						type="error"
						showIcon
					/>
				)}
			</Modal>

			{/* Rights Modal */}
			<Modal
				// title={!input ? 'Add Coupon' : 'Edit'}
				title={'Manage Rights'}
				visible={isModalVisible}
				onOk={onUpdateAdminFinish}
				onCancel={() => setIsModalVisible(false)}
				width={800}
			>
				{/* Creating */}
				{showRightsSaveSuccessAlert && (
					<Alert message="Created" type="success" showIcon />
				)}
				{loadingMt && <Alert message="Submitting..." type="info" showIcon />}
				{errorMt && (
					<Alert
						message={`Submission error! ${errorMt.message}`}
						type="error"
						showIcon
					/>
				)}

				{/* Updating */}
				{/* {dataMtU && <Alert message="Updated" type="success" showIcon />}
				{loadingMtU && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtU && ( */}
				{/* <Alert
						message={`Submission error! `}
						type="error"
						showIcon
					/> */}
				{/* // )} */}

				<Form
					form={form2}
					name="adminRights"
					// onFinish={onFinish}
					// scrollToFirstError
					// initialValues={
					// 	!input
					// 		? {}
					// 		: {
					// 				...input,
					// 				startDate: moment(input.startDate),
					// 				endDate: moment(input.endDate),
					// 		  }
					// }
				>
					{/* NEW LAYOUT STARTS  */}
					<Radio.Group
						className="gx-radio-group-link gx-radio-group-link-news"
						// defaultValue={news}
						defaultValue={
							Array.isArray(mainPagesRights) &&
							mainPagesRights.length &&
							mainPagesRights[0]?.id
						}
						onChange={handleMainPageRadio}
					>
						{mainPagesRights?.map((x, i) => (
							<Radio.Button key={i} value={x.id} className="gx-mb-1">
								{x.name.charAt(0).toUpperCase() + x.name.slice(1)}
							</Radio.Button>
						))}
					</Radio.Group>

					<Row justify="center">
						<Col span={14}>
							<Form.Item
								// validateStatus={editRightsValidation ? 'error' : ''}
								// help={
								// 	editRightsValidation
								// 		? 'Please select at least one right'
								// 		: ''
								// }
								style={{ marginBottom: 0 }}
							>
								<br />
								{selectedMainPageRight &&
									!subPagesRightsOfSelectedMainPage.length && (
										<Row
											key={selectedMainPageRight?.name}
											justify="center"
											align="middle"
										>
											<Col span={18}>
												{/* <Form.Item
												name={`right1`}
												valuePropName="checked"
												label={
													<h4
														style={{ display: 'inline', marginRight: '1rem' }}
													>
														{selectedMainPageRight?.name} -
													</h4>
												}
												style={{ marginBottom: 0 }}
											> */}
												{/* <h6>{right.name} -</h6> */}
												<Form.Item
													labelAlign="right"
													name={`right${selectedMainPageRight.id}`}
													valuePropName="checked"
													label={
														<h4
															style={{
																display: 'inline',
																marginRight: '0.5rem',
																marginTop: '6px',
															}}
														>
															{selectedMainPageRight.name
																.charAt(0)
																.toUpperCase() +
																selectedMainPageRight.name.slice(1)}
														</h4>
													}
													style={{ marginBottom: 0 }}
												>
													{/* <span style={{ marginLeft: '1rem' }}>
														{`${selectedMainPageRight.name}:      `}
													</span> */}
													{''}
													<Switch
														onChange={(checked) =>
															handleRightsSelection(
																selectedMainPageRight?.id,
																checked,
																null,
															)
														}
														checked={rights.includes(selectedMainPageRight?.id)}
														style={{
															transform: 'scale(0.8)',
														}}
													/>
												</Form.Item>
												{/* </Form.Item> */}
											</Col>
										</Row>
									)}

								{subPagesRightsOfSelectedMainPage.length != 0 &&
									subPagesRightsOfSelectedMainPage.map((right, index) => (
										<Row
											key={right.name}
											justify="center"
											align="middle"
											style={{ display: 'flex' }}
										>
											<Col span={20}>
												<Form.Item
													name={`right${index}`}
													valuePropName="checked"
													label={
														<h4
															style={{
																display: 'inline',
																marginRight: '0.5rem',
																marginTop: '6px',
															}}
														>
															{right.name.charAt(0).toUpperCase() +
																right.name.slice(1)}
														</h4>
													}
													style={{ marginBottom: 0, flexBasis: '30%' }}
												>
													{/* <h6>{right.name} -</h6> */}

													{rightsOfPageOrSubpage?.map(
														(x, i) =>
															x.parentId == right.id && (
																<div style={{ flexBasis: '70%' }} key={i}>
																	<Form.Item
																		labelAlign="right"
																		name={`right${index}`}
																		valuePropName="checked"
																		// label={right.name}
																		style={{ marginBottom: 0 }}
																		key={i}
																	>
																		<Switch
																			onChange={(checked) =>
																				handleRightsSelection(
																					x.id,
																					checked,
																					right.id,
																				)
																			}
																			checked={rights.includes(x.id)}
																			style={{
																				transform: 'scale(0.8)',
																				marginLeft: '1rem',
																			}}
																		/>
																		<span style={{ marginLeft: '4px' }}>
																			{x.name.charAt(0).toUpperCase() +
																				x.name.slice(1)}
																		</span>
																	</Form.Item>
																</div>
															),
													)}

													{!rightsOfPageOrSubpage.find(
														(k) => k.parentId == right.id,
													) && (
														<Switch
															onChange={(checked) =>
																handleRightsSelection(
																	right.id,
																	checked,
																	selectedMainPageRight.id,
																)
															}
															checked={rights.includes(right.id)}
															style={{
																transform: 'scale(0.8)',
															}}
														/>
													)}
												</Form.Item>
											</Col>
										</Row>
									))}
							</Form.Item>
						</Col>
					</Row>
					{/* NEW LAYOUT ENDS */}

					{/* <>OLD LAYOUT STARTS</>
					<Row>
						{rightsData?.getRights?.map((right, index) => (
							<React.Fragment key={right.name}>
								<Col span={3}></Col>
								<Col span={21}>
									<Form.Item
										name={`right${index}`}
										valuePropName="checked"
										label={right.name}
									>
										{' '}
										<Switch
											onChange={(checked) =>
												handleRightsSelection(right.id, checked)
											}
											checked={rights?.includes(right.id)}
										/>
									</Form.Item>
								</Col>
							</React.Fragment>
						))}
					</Row>
						<>OLD LAYOUT ENDS</> */}
				</Form>
			</Modal>
		</Card>
	);
};
export default Admins;
