import { useQuery } from '@apollo/client';
import { Badge, Card, Col, Row } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React from 'react';
import AddCouponCode from 'src/components/coupons/add';
import {
	CMSGetCoupons,
	CMSGetCouponsVariables,
} from 'src/lib/gql/generated/CMSGetCoupons';
import { CMSQueryGetCoupons } from 'src/lib/gql/queries';

const ManageCouponCodes: React.FC = () => {
	const {
		loading,
		data: qryData,
		called,
		error,
		refetch: refetchQryData,
	} = useQuery<CMSGetCoupons, CMSGetCouponsVariables>(CMSQueryGetCoupons, {
		variables: {
			showAll: true,
		},
	});

	return (
		<>
			{/* Coupon list */}
			<Card
				title="Manage coupon codes"
				extra={[<AddCouponCode key={'add'} onFinishAdd={refetchQryData} />]}
			>
				<div className="gx-flex gx-flex-nowrap gx-mb-2">
					<Row>
						{qryData &&
							qryData?.getCoupons?.map((cp) => {
								return (
									<>
										<Col span={8} className="gx-mb-4" key={cp.id}>
											<Badge.Ribbon
												text={cp.enabled ? 'Enabled' : 'Disabled'}
												color={cp.enabled ? 'green' : 'red'}
											>
												<Card
													type="inner"
													title={`Code: ${cp.code}`}
													actions={[
														<AddCouponCode
															key={cp.id}
															input={cp}
															onFinishAdd={refetchQryData}
														/>,
													]}
												>
													<Meta
														title={`Name: ${cp.name}`}
														description={`Description: ${cp.description}`}
													/>
													<br />
													<Meta title={`Terms:`} description={cp.terms} />
													<br />
													<Meta
														title={`Services:`}
														description={
															cp.forAllServices.toString()
																? 'Applicable for all Services'
																: ''
														}
													/>
													<br />
													<Meta
														title={`Users:`}
														description={
															cp.forAllServices.toString()
																? 'Applicable for all Users'
																: ''
														}
													/>
													<br />
													<Row>
														<Col span={12}>
															<Meta title={`Value:`} description={cp.value} />
														</Col>
														<Col span={12}>
															<Meta
																title={`Max Value:`}
																// description={cp.maxValue}
															/>
														</Col>
													</Row>
													<br />
													<Row>
														<Col span={12}>
															<Meta
																title={`maxUsePerCustomer:`}
																description={cp.maxUsePerCustomer}
															/>
														</Col>
														<Col span={12}>
															<Meta
																title={`minOrder:`}
																description={cp.minOrder}
															/>
														</Col>
													</Row>
													<br />

													<Row>
														<Col span={12}>
															<Meta
																title={`Start Date:`}
																description={new Date(
																	cp.startDate,
																).toDateString()}
															/>
														</Col>
														<Col span={12}>
															<Meta
																title={`End Date:`}
																description={new Date(
																	cp.endDate,
																).toDateString()}
															/>
														</Col>
													</Row>
												</Card>
											</Badge.Ribbon>
										</Col>
									</>
								);
							})}
					</Row>
				</div>
			</Card>
		</>
	);
};
export default ManageCouponCodes;
