import React from 'react';

import LineIndicator from './LineIndicator';
import BubbleMap from './BubbleMap/index';

interface Props {
	title?;
	total?;
	cities?;
	countries?;
	disableMap?;
}

const Overview = (props: Props) => {
	return (
		<div className="gx-card-overview">
			<div className="gx-overview-row">
				<div className="gx-line-indicator-col">
					<h2 className="h4 gx-card-title">{props.title}</h2>
					<ul className="gx-line-indicator">
						{props.cities.values.map((e) => (
							<li key={e.name}>
								<LineIndicator
									width={`${(e.value / props.cities.total) * 100}%`}
									title={e.name}
									color="purple"
									value={e.value}
								/>
							</li>
						))}
					</ul>
				</div>
				<div className="gx-overview-description">
					<div className="gx-revenu gx-revenu-total">
						<h1>{props.total}</h1>
						{/* <span className="gx-fs-md">Total Revenu</span> */}
					</div>

					<div className="gx-revenu">
						<div className="gx-revenu-row">
							<div className="gx-revenu-col">
								<h3>{props.cities.values.length}</h3>
								<span className="gx-fs-sm">Cities</span>
							</div>

							<div className="gx-revenu-col">
								<h3>1</h3>
								<span className="gx-fs-sm">Countries</span>
							</div>
						</div>
					</div>
				</div>
				<div className="gx-map-col">{!props.disableMap && <BubbleMap />}</div>
			</div>
		</div>
	);
};

export default Overview;
