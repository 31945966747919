import { useQuery } from '@apollo/client';
import {
	Card,
	Col,
	Input,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Table,
} from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import {
	CMSGetUsers,
	CMSGetUsersVariables,
} from 'src/lib/gql/generated/CMSGetUsers';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { CMSQueryGetUsers } from 'src/lib/gql/queries/users';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'src/lib/recoil/userCoil';

const { Option } = Select;

const Users: React.FC = () => {
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [filterName, setFilterName] = useState('');
	const [filterPhone, setFilterPhone] = useState('');
	const [accountType, setAccountType] = useState(UserTypeEnum.CUSTOMER);
	const userAuth = useRecoilValue(userAtom);
	const { data: qryData, loading } = useQuery<
		CMSGetUsers,
		CMSGetUsersVariables
	>(CMSQueryGetUsers, {
		variables: {
			pagination: pagination,
			filters: {
				type: accountType,
				name: filterName,
				phone: filterPhone,
			},
		},
		fetchPolicy: 'network-only'
	}
	);
	const handlePhoneFilter = (value: string | undefined) => {
		setFilterPhone(value || '');
	};
	const handleInputChange = (e) => {
		console.log("e",e);
		let value = e.target.value;
		// Allow only numbers
		value = value.replace(/\D/g, '');
		setFilterPhone(value);
	  };
	  const handleInputChangeName = (e) => {
		let value = e.target.value;
		// Allow only strings (no numbers)
		value = value.replace(/[0-9]/g, '');
		setFilterName(value);
	  };

	const pageSizeOptions = ['10', '20', '50']; // Add '5' to the list of options

	const columns = [
		...(accountType === UserTypeEnum.CUSTOMER
			? [{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
				width: 150,
				render: (name: string, record: any) => (
					<span className="gx-link">
						<Link to={RouteList.users.path + '/' + record.id}>{name}</Link>
					</span>
				),
			},
			{
				title: 'Open Bookings',
				dataIndex: 'bookings',
				key: 'bookings',
				align: 'center' as const,
				render: (bookings: any[]) => (
					<span>{bookings.filter((b) => b.bookingStatus === 'OPEN').length}</span>
				),

			},
			{
				title: 'Total Bookings',
				dataIndex: 'bookings',
				key: 'bookings',
				align: 'center' as const,
				render: (bookings: any[]) => <span>{bookings.length}</span>,
			},
			{
				title: 'Account Status',
				dataIndex: 'userType',
				align: 'center' as const,
				render: (status: string, record: any) => {
					return (record?.customerDetails?.disableAccount === true ? <span >🔴</span> : <span >🟢</span>)
				}
			},

			]
			: []),
		{
			title: 'Mobile Number',
			dataIndex: 'phone',
			key: 'phone',
			width: 250,
			render: (
				text,
				record,  
			) =>  {
                const formatPhoneNumber = (phoneNumber) => {
                    phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
                    if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
                      return `+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`;
                    } else {
                      return phoneNumber;
                    }
                  };
                const inputPhoneNumber = record?.phone
                const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);
                return(
				<>
                {formattedPhoneNumber} 
				</>
			);
        },
		}
		,
		// 	title: 'Area',
		// 	dataIndex: 'area',
		// 	render: (area: string, record: any) => (
		// 		<span>{record.customerDetails?.defaultAddress?.area?.name}</span>
		// 	),
		// },
		// {
		// 	title: 'City',
		// 	dataIndex: 'city',
		// 	key: 'city',
		// },
		...(accountType === UserTypeEnum.PENDING
			? [
				{
					title: 'Device',
					dataIndex: 'os',
					key: 'os',
					render: (value: string | number | boolean, record: any) => (
						<span style={{ textAlign: 'center' }}>
							{record.os === 'android' ? (
								<img src="android-icon.png" alt="Android Icon" />
							) : record.os === 'ios' ? (
								<img src="ios-icon.png" alt="iOS Icon" />
							) : (
								record.os
							)}
						</span>
					),
				},
				{
					title: 'Last Tried On',
					dataIndex: 'lastChangedDateTime',
					key: 'lastChangedDateTime',
					render: (lastChangedDateTime, record) => {
						const convertedDatel = new Date(parseInt(lastChangedDateTime));
						const month = convertedDatel.toLocaleString('default', { month: 'long' });
						const time = convertedDatel.toLocaleTimeString();
						const timeCrt = moment(time).format('LT');
						return (
							<span style={{ textAlign: 'center' }}>
								{/* {convertedDatel.getDate()} {month}, {convertedDatel.getFullYear()}
								<br /> */}
								{/* {timeCrt} */}
								{moment(convertedDatel).fromNow()}
							</span>
						);
					},
				},
				{
					title: 'Created On',
					dataIndex: 'createDateTime',
					key: 'createDateTime',
					render: (createDateTime, record) => {
						const convertedDate = new Date(parseInt(createDateTime));
						const month = convertedDate.toLocaleString('default', { month: 'long' });
						const time = convertedDate.toLocaleTimeString();
						const timeCrt = moment(time).format('LT');
						return (
							<span style={{ textAlign: 'center' }}>
								{/* {/* {convertedDate.getDate()} {month}, {convertedDate.getFullYear()}
								<br /> */}
								{/* {timeCrt} */}
								{moment(convertedDate).fromNow()}
							</span>
						);
					},
				},
			]
			: []),
		// {
		// 	title: 'Action',
		// 	dataIndex: 'key',
		// 	key: 'id',
		// 	width: 'auto',
		// 	align: 'center' as const,
		// 	render: (text: string, record: any) => (
		// 		<span>
		// 			<Link to={RouteList.users.path + '/' + record.id} >
		// 				<i className="icon-view-o" style={{ marginRight: '20px' }} />
		// 			</Link>
		// 		</span>
		// 		),
		// },
	];

	useEffect(() => {
		setPagination({
			...pagination,
			pageNumber: 1, // Reset pagination when filters change
		});
	}, [filterName, filterPhone, accountType]);

	const exportData = qryData?.getUsers?.data || []; // Extract the data to be exported

	// console.log("dataa==>",qryData?.getUsers?.data);

	return (
		<Card title={<div>Customers</div>}
		// extra={[
		// <>
		// 	<CSVLink
		// 			data={exportData}
		// 			filename="users.csv"
		// 			className="ant-btn ant-btn-primary"
		// 			target="_blank"
		// 		>
		// 			<DownloadOutlined /> Export CSV
		// 		</CSVLink>
		// </>
		// ]}
		>
			<Row style={{
				marginBottom: "16px",
				paddingTop: "0.2rem",
				paddingBottom: "5px"
			}}>
				<Col span={24} >
					<div style={{
						justifyContent: "center",
						display: "flex"
					}}>
						<div>
							<Input type='string'
								style={{ width: "250px" }}
								placeholder="Search by Name"
								value={filterName}
								allowClear
								onChange={(e) => handleInputChangeName(e)}
							/>
						</div>

						{/* </Col> */}
						{/* <Col span={4}> */}
						<div style={{ paddingLeft: "0.5rem" }}>
							<Input style={{ width: "250px", paddingLeft: "1rem" }}
								placeholder="Search by Phone"
								value={filterPhone}
								allowClear 
								onChange={(e) => handleInputChange(e)}
								maxLength={10}
							/>
						</div>
						{/* </Col> */}
						{/* <Col> */}
						<div style={{ paddingLeft: "0.5rem" }}>
							<Select
								defaultValue={UserTypeEnum.CUSTOMER}
								style={{ width: 120 }}
								onChange={(value) => setAccountType(value)} getPopupContainer={(trigger) => trigger.parentElement}
							>
								<Option value={UserTypeEnum.CUSTOMER}>{UserTypeEnum.CUSTOMER}</Option>
								<Option value={UserTypeEnum.PENDING}>{UserTypeEnum.PENDING}</Option>
							</Select>
						</div>
					</div>
				</Col>
				{/* <Col span={12}>
				
				</Col> */}
			</Row>

			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}

			{/* Data export button */}

			{userAuth?.userType === UserTypeEnum.ADMIN ||
				userAuth?.rights?.find(
					(x) => x.type === 'sub2' && x.name === 'export-csv',
				) ? (
				<Row justify="end" style={{
					marginBottom: 16, paddingTop: "1rem",
					backgroundColor: "#f6f6f6",
					paddingBottom: "5px"
				}}>
					<Col span={24} style={{ textAlign: "center" }}>
						<Pagination
							key="pagination"
							defaultCurrent={qryData?.getUsers?.pageInfo.currentPage}
							current={qryData?.getUsers?.pageInfo.currentPage}
							pageSize={pagination.pageSize}
							total={qryData?.getUsers?.pageInfo.totalCount}
							showTotal={(total) => `Total ${total} items`}
							onChange={(page, pageSize) => {
								setPagination({
									pageNumber: page,
									pageSize: pageSize ? +pageSize : pagination.pageSize,
								});
							}}
							showSizeChanger
							pageSizeOptions={pageSizeOptions} // Set the pageSizeOptions prop
						/>
					</Col>

				</Row>
			) : (
				<></>
			)}

			{/* Table */}
			<Table
				className="gx-table-responsive"
				columns={columns}
				dataSource={qryData?.getUsers?.data}
				pagination={false}
			/>
		</Card>
	);
};

export default Users;
