import { gql } from '@apollo/client';

export const CMSQueryGetUsers = gql`
	query CMSGetUsers(
		$pagination: PaginationGqlInput!
		$filters: UsersQueryGqlInput!
	) {
		getUsers(pagination: $pagination, filters: $filters) {
			data {
				id
				name
				email
				userType
				isActive
				phone
				dob
				createDateTime
				lastChangedDateTime
				os
				os_version
				bookings {
					id
					bookingStatus
				}
				customerDetails {
					disableAccount
					defaultAddress {
						address
						area {
							name
						}
						locality
						landmark
						addressType
						buildingName
						buildingNumber
					}
				}

			}
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;

export const CMSQueryGetPartners = gql`
	query CMSGetPartners(
		$pagination: PaginationGqlInput!
		$filters: UsersQueryGqlInput!
	) {
		getUsers(pagination: $pagination, filters: $filters) {
			data {
				id
				name
				email
				userType
				isActive
				uid
				phone
				partnerAnalytics {
					totalBookings
					openBookings
					withdrawableAmount
					totalEarnings
					}
				bookings {
					id
					bookingStatus
				}

				createDateTime
				partnerDetails {
					id
					approved
					disableAccount
					accountNumber
					pendingTasks
					services {
						id
					}
					serviceAreas {
						id
					}
					documents {
						id
						documentType
						medias {
							id
							url
						}
					}
				}
			}
			pageInfo {
				totalPage
				totalCount
				currentPage
			}
		}
	}
`;

export const CMSQueryGetMe = gql`
	query CMSGetMe {
		me {
			id
			name
			email
			phone
			dob
			userType
			rights {
				id
				name
				type
				parentId
			}
		}
	}
`;

export const CMSQueryGetPartnerCompanies = gql`
	query CMSGetPartnerCompanies(
		$pagination: PaginationGqlInput!
		$filters: PartnerCompaniesQueryGqlInput!
	) {
		getPartnerCompanies(pagination: $pagination, filters: $filters) {
			data {
				companyAddress
				companyName
				id
			}
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;

export const CMSQueryGetUser = gql`
	# Write your query or mutation here
	query CMSGetUser($id: String!) {
		getUser(id: $id) {
			id
			name
			email
			phone
			userType
			dob
			about
			uid
			createDateTime
			isActive
			bookings {
				id
				bookingStatus
				bookingDate
				userBookingNumber
			}
			analytics {
				openBookings
				totalPointsRedeemed
				totalPointsEarned
				openSubscriptions
				openOrders
				totalOrders
				pendingPaymentsCounts
				totalBookings
				completedBookings
				canceledBookings
				totalSpend
				favouriteServices
			}
			customerDetails {
				id
				disableAccount
				favoriteServices{
					id
					name
				}
				defaultAddress {
					id
					buildingName
					buildingNumber
					otherText
					addressType
					landmark
					locality
					isDefault
					postalCode
					
					area {
						id
						name
						city{
							name
						}
					}
				}
				addresses {
					id
					buildingName
					buildingNumber
					otherText
					addressType
					landmark
					
					locality
					postalCode
					isDefault
					area{
						name
						city{
							name
						}
					}
					
				}
				rewardPointBalance
			}
			partnerDetails {
				id
				approved
				accountNumber
				
				ifsc
				pendingTasks
				walletBalance
				# walletLogs {
				# 	id
				# 	points
				# 	transactionDate
				# 	logType
				# 	source
				# }
				photo {
					id
					type
					url
					name
					enabled
				}
				address {
					id
					buildingName
					buildingNumber
					landmark
					address
					postalCode
					area
				}
				serviceAreas {
					id
					name
					code
				}
				services {
					id
					code
					name
					icon {
						id
						url
					}
				}
				photo {
					id
					name
				}
				documents {
					id
					documentType
					medias {
						id
						type
						url
						name
						enabled
						thumbnail {
							id
							url
						}
					}
				}
			}
		}
	}
`;
export const CMSQueryGetPartner = gql`
	# Write your query or mutation here
	# Write your query or mutation here
	query CMSGetPartner($id: String!) {
		getUser(id: $id) {
			id
			name
			email
			createDateTime
			phone
			userType
			dob
			about
			uid
			isActive
			
			partnerAnalytics {
				totalBookings
				openBookings
				withdrawableAmount
				totalEarnings
			}
			bookings {
				id
				userBookingNumber
				bookingStatus
				bookingAmount {
					subTotal
					grandTotal
				}
				bookingDate
				pendingAmount {
					amount
				}
				bookingPayments {
					id
					orderId
					paymentId
					amount
					amountPaid
					amountDue
					currency
					status
					attempts
					invoiceNumber
					bookingId
				}
				bookingService {
					id
					serviceId
					service {
						id
						name
					}
				}
			}
			partnerDetails {
				id
				approved
				accountNumber
				ifsc
				isAvailable
				unavailableTill
				disableAccount
				pendingTasks
				walletBalance
				isBackgroundVerification
				backgroundVerificationNote
				backgroundVerificationDateTime
				isZimkeyPartner
				company{
					companyName
				}
				# walletLogs {
				# 	id
				# 	points
				# 	transactionDate
				# 	logType
				# 	source
				# }
				photo {
					id
					type
					url
					name
					enabled
				}
				address {
					id
					buildingName
					buildingNumber
					landmark
					address
					postalCode
					area
					city
					locality
				}
				serviceAreas {
					id
					name
					code
				}
				services {
					id
					code
					name
					icon {
						id
						url
					}
				}
				photo {
					id
					name
				}
				documents {
					id
					documentType
					medias {
						id
						type
						url
						name
						enabled
						thumbnail {
							id
							url
						}
					}
				}
			}
		}
	}
`;
export const CMSQueryGetSubAdmins = gql`
	query CMSGetSubAdmins(
		$pagination: PaginationGqlInput!
		$filters: UsersQueryGqlInput!
	) {
		getSubAdmins(pagination: $pagination, filters: $filters) {
			data {
				id
				cannotDelete
				user {
					name
					email
					phone
					userType
				}
				rights {
					id
					name
				}
				roleId
			}
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;

export const CMSQueryGetSubAdmin = gql`
	query CMSGetSubAdmin($id: String!) {
		getSubAdmin(id: $id) {
			id
			user {
				name
				email
				phone
			}
			rights {
				id
				name
			}
			role {
				id
				name
			}
		}
	}
`;