import { useMutation, useQuery } from '@apollo/client';
import { Spin, Alert, Row, Col, Card, Pagination, Input, Space, Modal, Table, Button } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { useState, useEffect } from 'react';
import UpdatePartnerCompanies from 'src/components/users/updatePartnerCompanies';
import {
	CMSDeletePartnerCompany,
	CMSDeletePartnerCompanyVariables,
} from 'src/lib/gql/generated/CMSDeletePartnerCompany';
import { CMSGetPartnerCompanies } from 'src/lib/gql/generated/CMSGetPartnerCompanies';
import { DELETE_PARTNER_COMPANY, UPDATE_PARTNER_COMPANY } from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetPartnerCompanies } from 'src/lib/gql/queries/users';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { CaretLeftOutlined } from '@ant-design/icons';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { userAtom } from 'src/lib/recoil/userCoil';
import { useRecoilValue } from 'recoil';

const ManagePartnerCompanies: React.FC = () => {
	const navigate = useNavigate();
	const userAuth = useRecoilValue(userAtom);
	const [updateAlert, setUpdateAlert] = useState(false)
	const [deleteAlert, setDeleteAlert] = useState(false)


	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [filterName, setFilterName] = useState("");

	const {
		loading,
		data: qryData,
		called,
		error,
		refetch: refetchQryData,
	} = useQuery<CMSGetPartnerCompanies>(CMSQueryGetPartnerCompanies,
		{
			variables: {
				pagination: pagination,
				filters: {
					companyName: filterName
				},
				// useId: '',
				// partnerAssigned: true,
			},
		},

	);

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [deleteId, setDeleteId] = useState<any>("");


	// const [
	// 	deleteServiceInputOption,
	// 	{ data: dataMtD, loading: loadingMtD, error: errorMtD },
	// ] = useMutation<CMSDeletePartnerCompany, CMSDeletePartnerCompanyVariables>(
	// 	DELETE_PARTNER_COMPANY,
	// 	{
	// 		onCompleted: () => {
	// 			refetchQryData();
	// 		},
	// 	},
	// );

	const [
		deleteServiceInputOption,
		{ data: dataMtD, loading: loadingMtD, error: errorMtD },
	] = useMutation<CMSDeletePartnerCompany, CMSDeletePartnerCompanyVariables>(
		DELETE_PARTNER_COMPANY,
		{
			onCompleted: () => {
				refetchQryData();
				setIsDeleting(false);
			},
		},
	);



	// Update
	const [
		updateServiceInputOption,
		{ data: dataMtDs, loading: loadingMtDs, error: errorMtDs },
	] = useMutation(UPDATE_PARTNER_COMPANY);


	const [dataInfo, setDataInfo] = useState<any>(qryData?.getPartnerCompanies)

	// useEffect(() => {
	// 	setPagination({
	// 		...pagination,
	// 		pageNumber: 1, // Reset pagination when filters change
	// 	});
	// }, [filterName]);

	// const pageSizeOptions = ['10', '20', '50']; // Add '5' to the list of options

	useEffect(() => {
		setDataInfo(qryData?.getPartnerCompanies)

	}, [qryData?.getPartnerCompanies])

	// console.log("data",deleteServiceInputOption);


	const delteData = (record) => {
		setIsDeleteModalVisible(true)
		// console.log("e",record.id);
		setDeleteId(record?.id)

	}



	const onDelete = (id) => {
		setIsDeleteModalVisible(false);
		setIsDeleting(true);
		deleteServiceInputOption({
			variables: {
				id: deleteId,
			},
		});
				setDeleteAlert(true)
				setTimeout(() => {
				setDeleteAlert(false)
				}, 2000)
	};
	// const onDelete = (id) => {
	// 	setIsDeleteModalVisible(false)
	// 	deleteServiceInputOption({
	// 		variables: {
	// 			id: deleteId,
	// 		},
	// 	}).then(() => {
	// 		setTimeout(() => {
	// 			setIsDeleteModalVisible(false);
	// 		}, 1500);
	// 	});
	// };
	const [updateData, setUpdateData] = useState<any>()
	const [isUpdateModalVisible, setisUpdateModalVisible] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);

	const onEdit = (data) => {
		// console.log("123");
		setUpdateData(data)
		setisUpdateModalVisible(true)
	}
	const [name, setName] = useState<any>()
	const [address, setAddress] = useState<any>()


	useEffect(() => {
		setName(updateData?.companyName)
		setAddress(updateData?.companyAddress)
		// console.log("123",updateData);
	}, [updateData])

	const onUpdate = () => {
		// setIsDeleteModalVisible(true)
		updateServiceInputOption({
			variables: {
				id: updateData?.id,
				name: name,
				address: address
			},
		}).then(() => {
			setTimeout(() => {
				setisUpdateModalVisible(false);
			}, 1500);
		});
		setUpdateAlert(true)
		setTimeout(() => {
		setUpdateAlert(false)
		}, 2000)
	};


	const columns = [
		// {
		// 	title: 'ID',
		// 	dataIndex: 'id',
		// 	key:"id"
		// },
		{
			title: 'Name',
			dataIndex: 'companyName',
			key: "companyName",
			width: 300,

		},
		{
			title: 'Address',
			dataIndex: 'companyAddress',
			key: "companyAddress",
			width: 300,

		},
		{
			title: 'Operations',
			dataIndex: '',
			key: "delete",
			width: 80,

			render: (text: string, record: any) => (
				<>

					{userAuth?.userType === UserTypeEnum.ADMIN ||
						userAuth?.rights?.find(
							(x) => x.type === 'sub2' && x.name === 'partner-companies-delete"',
						) ? (
						<span onClick={(e) => delteData(record)}>
							<DeleteOutlined style={{ color: "red" }} />
						</span>
					) : (
						<span >
							<DeleteOutlined style={{ color: "red" }} />
						</span>
					)}

					{userAuth?.userType === UserTypeEnum.ADMIN ||
						userAuth?.rights?.find(
							(x) => x.type === 'sub2' && x.name === 'partner-companies-edit"',
						) ? (
						<span onClick={(e) => onEdit(record)} style={{ paddingLeft: "3rem", color: "#5143dc" }}>
							<EditOutlined /></span>
					) : (
						<span style={{ paddingLeft: "3rem" }}>
							<EditOutlined /></span>
					)}


				</>
			),
		},
		// {
		// 	title: 'Edit',
		// 	dataIndex: '',
		// 	key:"edit",
		// 	width: 80,
		// 	render: (text: string, record: any) => (
		// 		<span onClick={(e) =>onEdit(record)}>
		// 			<EditOutlined />
		// 		</span>
		// 		),

		// },
	];

	return (
		<>
			{/* Coupon list */}

			<Card
				title={
					<div>
						<Button className="gx-mr-4" onClick={() => navigate(-1)} style={{ margin: "0" }}>
							<CaretLeftOutlined />
						</Button>
						Manage Partner Companies
					</div>
				}
				extra={[
					{
						...userAuth?.userType === UserTypeEnum.ADMIN ||
							userAuth?.rights?.find(
								(x) => x.type === 'sub2' && x.name === 'partner-companies-add',
							) ? (
							<UpdatePartnerCompanies key={'add'} onFinishAdd={refetchQryData} />
						) : null
					}
				]}
			>
				<div className="gx-flex gx-flex-nowrap gx-mb-2">
					<Row style={{
						marginBottom: "16px",
						paddingTop: "0.2rem",
						paddingBottom: "5px"
					}}>
						<Col span={24}>
							<div style={{ textAlign: "center" }}>
								<Input
									placeholder="Search by Name"
									value={filterName}
									style={{ width: "250px" }}
									allowClear
									onChange={(e) => {
										setFilterName(e.target.value);
									}}
								/>
							</div>
						</Col>
					</Row>
					<Row style={{
						marginBottom: 16,
						paddingTop: "1rem",
						backgroundColor: "#f6f6f6",
						paddingBottom: "5px"
					}}>
						<Col span={24} style={{ textAlign: "center" }}>
							<Pagination
								pageSize={pagination.pageSize}
								total={dataInfo?.pageInfo.totalCount}
								showTotal={(total) => `Total ${total} items`}
								current={
									dataInfo?.pageInfo.currentPage}
								onChange={(page, pageSize) => {
									setPagination({
										pageNumber: page,
										pageSize: pageSize ? +pageSize : pagination.pageSize,
									});
								}}
								showSizeChanger

							/>

						</Col>

					</Row>

					<div className="gx-table-responsive">
						<Table
							className="gx-table-responsive"
							columns={columns}
							dataSource={dataInfo?.data}
							pagination={false}
						// bordered={false}
						// size="small"
						/>
					</div>
				</div>


				{/* Delete and Deleted Message */}
				{isDeleting && (
					<Alert message="Deleting..." type="info" showIcon />
				)}
				{deleteAlert &&  !isDeleting && (
					<Alert message="Deleted" type="success" showIcon />
				)}
				{errorMtD && (
					<Alert
						message={`Submission error! ${errorMtD.message}`}
						type="error"
						showIcon
					/>
				)}



				{isDeleteModalVisible &&
					<Modal
						title={'Delete'}
						visible={isDeleteModalVisible}
						onOk={() => onDelete(deleteId)}
						onCancel={() => setIsDeleteModalVisible(false)}
						width={300}
					>
						Delete Company?
						<br />
						{/* {cp.companyName} */}
					</Modal>
				}


				{isUpdating && (
					<Alert message="Updating..." type="info" showIcon />
				)}
				{updateAlert &&  !isUpdating && (
					<Alert message="Updated" type="success" showIcon />
				)}
				{errorMtDs && (
					<Alert
						message={`Submission error! ${errorMtDs.message}`}
						type="error"
						showIcon
					/>
				)}

				{isUpdateModalVisible &&
					<Modal
						title={'Update'}
						visible={isUpdateModalVisible}
						onOk={onUpdate}
						onCancel={() => setisUpdateModalVisible(false)}
						width={300}
					>
						<label>Name
							<Input value={name} onChange={(e) => setName(e.target.value)} />
						</label>
						<br />	<br />
						<label>Address
							<Input value={address} onChange={(e) => setAddress(e.target.value)} />
						</label>

						{/* {dataMtDs && (
							<Alert message="Updated" type="success" showIcon />
						)}
						{loadingMtDs && (
							<Alert message="Updating..." type="info" showIcon />
						)}
						{errorMtDs && (
							<Alert
								message={`Submission error! ${errorMtDs.message}`}
								type="error"
								showIcon
							/>
						)} */}
					</Modal>
				}
			</Card>
			{/* {loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}
			{!loading && (
				<Table
					className="gx-table-responsive"
					columns={columns}
					dataSource={qryData}
					pagination={false}
					bordered
				/>
			)} */}



		</>
	);
};
export default ManagePartnerCompanies;
