import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
	Card,
	Col,
	Input,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Table,
	Button,
	Radio,
	Checkbox,
	Modal,
	message,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import { CMSGetBookingServiceItems_getBookingServiceItems_data } from 'src/lib/gql/generated/CMSGetBookingServiceItems';
import {
	CMSGetUsers,
	CMSGetUsersVariables,
} from 'src/lib/gql/generated/CMSGetUsers';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { GENERATE_PARTNER_PAYOUTS } from 'src/lib/gql/mutations/mutations';
import {
	CMSQueryGetPendingPayoutsBooking,
	CMSQueryGetPendingPayoutsPartner,
} from 'src/lib/gql/queries/payouts';
import { CMSQueryGetPartners } from 'src/lib/gql/queries/users';

// Name, Bookings, Address, Account status, action

const { Option } = Select;

const Payouts: React.FC = () => {
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 20,
	});
	const [filterName, SetFilterName] = useState('');
	const [filterPhone, SetFilterPhone] = useState('');

	const [accountType, SetAccountType] = useState(UserTypeEnum.PARTNER);
	const [radioValue, setRadioValue] = useState('user');
	const [startDate, setStartDate] = useState<any>('');
	const [endDate, setEndDate] = useState<any>('');
	const [searchId, setSearchId] = useState<any>();
	const [selectedPartners, setSelectedPartners] = useState<any>([]);
	const [isReadyToPayout, setIsReadyToPayout] = useState(false);
	const [totalAmount, setTotalAmount] = useState(0);

	const togglePartnerSelection = (newValue: string) => {
		// Check if the new value already exists in the array
		const index = selectedPartners.indexOf(newValue);

		if (index !== -1) {
			// If the value exists, remove it from the array
			setSelectedPartners((prevState) =>
				prevState.filter((id) => id !== newValue),
			);
		} else {
			// If the value doesn't exist, push it into the array
			setSelectedPartners((prevState) => [...prevState, newValue]);
		}
	};

	const handleTitleCheckboxChange = (e) => {
		const isChecked = e.target.checked;

		// Extracting the IDs of all rows
		const ids = qryData?.getPendingPayoutsPartner.map((record) => record.id);

		if (isChecked) {
			// If title checkbox is checked, add all IDs to selectedPartners
			setSelectedPartners(ids);
		} else {
			// If title checkbox is unchecked, remove all IDs from selectedPartners
			setSelectedPartners([]);
		}
	};

	const columns = [
		{
			title: (
				<Checkbox
					// checked={checked}
					onChange={handleTitleCheckboxChange}
					// value={checked}
					style={{ marginTop: '6px', marginBottom: '12px' }}
				></Checkbox>
			),
			dataIndex: '',
			render: (text, record, index) => (
				<Checkbox
					checked={selectedPartners.find((p: any) => p === record.id)}
					onChange={(e) => togglePartnerSelection(e.target.value)}
					value={record.id}
					style={{ marginTop: '6px', marginBottom: '12px' }}
				></Checkbox>
			),
		},
		{
			title: 'SL',
			dataIndex: 'SL',
			render: (text, record, index) => index + 1,
		},
		{
			title: 'Provider',
			dataIndex: 'name',
			key: 'name',
			width: 150,
			render: (name, record) => (
				<Link
					className="gx-link"
					to={RouteList.partners.path + '/' + record.id}
				>
					{name}{' '}
				</Link>
			),
		},

		// {
		// 	title: 'Phone',
		// 	dataIndex: 'phone',
		// 	key: 'phone',
		// 	width: 150,
		// 	render: (phone, record) => <span>{phone} </span>,
		// },
		{
			title: 'Registered on',
			dataIndex: 'createDateTime',
			key: 'createDateTime',
			width: 150,
			render: (createDateTime, record) => {
				const convertedDate = new Date(parseInt(createDateTime));
				const month = convertedDate.toLocaleString('default', {
					month: 'long',
				});
				const time = convertedDate.toLocaleTimeString();
				return (
					<span>
						{time}
						<br />
						{convertedDate.getDate()} {month}, {convertedDate.getFullYear()}
					</span>
				);
			},
		},
		{
			title: 'Total Revenue',
			dataIndex: 'partnerAnalytics',
			key: 'partnerAnalytics',
			align: 'center' as const,
			render: (partnerAnalytics) => (
				<span>{partnerAnalytics?.totalEarnings}</span>
			),
		},
		{
			title: 'Commission Given',
			dataIndex: 'Commission',
			key: 'Commission',
			align: 'center' as const,
			render: (bookings) => <span>{0}</span>,
		},
		{
			title: 'Pending Amount',
			dataIndex: 'partnerDetails',
			key: 'partnerDetails',
			align: 'center' as const,
			render: (partnerDetails) => (
				<span style={{ fontWeight: 'bold' }}>
					₹{formattedAmount(partnerDetails?.walletBalance)}
				</span>
			),
		},

		// {
		// 	title: 'Pending Amount From Zimkey',
		// 	dataIndex: 'Pending Amount From Zimkey',
		// 	key: 'Pending Amount From Zimkey',
		// },
		// {
		// 	title: 'Pending Amount to Zimkey',
		// 	dataIndex: 'Pending Amount From Zimkey',
		// 	key: 'Pending Amount From Zimkey',
		// },
		// {
		// 	title: 'No. of Cancellation',
		// 	dataIndex: 'cancelations',
		// 	key: 'cancelations',
		// },
		// {
		// 	title: 'Total Earned',
		// 	dataIndex: 'totalearned',
		// 	key: 'totalearned',
		// },
		// {
		// 	title: 'Total paid',
		// 	dataIndex: 'totalreceived',
		// 	key: 'totalreceived',
		// },
		// {
		// 	title: 'Balance to be paid',
		// 	dataIndex: 'balancetobereceived',
		// 	key: 'balancetobereceived',
		// },
		// {
		// 	title: 'Ratings',
		// 	dataIndex: 'totalratings',
		// 	key: 'totalratings',
		// 	render: (text, record) => (
		// 		<span>
		// 			<span style={{ color: 'green' }}>
		// 				<strong>4.5/722</strong>
		// 			</span>
		// 			<br />
		// 			<span>⭐⭐⭐⭐</span>
		// 		</span>
		// 	),
		// },
		// {
		// 	title: 'Account Status',
		// 	dataIndex: 'userType',
		// 	key: 'userType',
		// },

		// {
		// 	title: 'Action',
		// 	dataIndex: 'key',
		// 	key: 'id',
		// 	width: 'auto',
		// 	render: (text, record) => (
		// 		<span>
		// 			<Button className="gx-ml-2 gx-mb-0" type="primary">
		// 				Settle
		// 			</Button>
		// 		</span>
		// 	),
		// },
	];

	const bookingTableColumns = [
		{
			title: 'Booking ID',
			dataIndex: 'userBookingNumber',
			key: 'userBookingNumber',
			width: 150,
			align: 'center' as const,
			render: (
				text,
				record: CMSGetBookingServiceItems_getBookingServiceItems_data,
			) => (
				<>
					<Link to={RouteList.bookings.path + '/' + record?.id}>
						<span className="gx-link">
							{record?.bookingService?.booking?.userBookingNumber}
						</span>
					</Link>
				</>
			),
		},

		// {
		// 	title: 'Service',
		// 	dataIndex: 'service',
		// 	key: 'service',
		// 	// width: 250,
		// 	// align: 'center' as const,
		// 	render: (
		// 		text,
		// 		record: CMSGetBookingServiceItems_getBookingServiceItems_data,
		// 	) => {
		// 		return (
		// 			<div className="gx-ml-2 gx-d-none gx-d-sm-flex">
		// 				{/* <Avatar
		// 					size="large"
		// 					alt={'name'}
		// 					src={`https://staging.api.zimkey.in/media-upload/${record?.bookingService?.service?.icon?.url}`}
		// 					style={{ marginRight: '8px' }}
		// 				/> */}
		// 				<span>
		// 					{/* <Link to="/users/users/1">
		// 	  <span className="gx-link">View detail</span>
		// 	  </Link> */}
		// 					{/* <span className="">{record?.bookingService?.service?.code}</span>
		// 					<br /> */}
		// 					<span className="">{record?.bookingService?.service?.name}</span>
		// 				</span>
		// 			</div>
		// 		);
		// 	},
		// },

		{
			title: 'Booked By',
			dataIndex: 'name',
			key: 'name',
			// width: 150,
			align: 'center' as const,
			render: (
				text,
				record: CMSGetBookingServiceItems_getBookingServiceItems_data,
			) => {
				const formatPhoneNumber = (phoneNumber) => {
					phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
					if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
						return `+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`;
					} else {
						return phoneNumber;
					}
				};
				const inputPhoneNumber = record?.bookingService?.booking?.user?.phone;
				const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);
				return (
					<Link
						to={
							RouteList.users.path +
							'/' +
							record?.bookingService?.booking?.user?.id
						}
						// target="_blank"
					>
						<span className="gx-link">
							{record?.bookingService?.booking?.user?.name}
						</span>
						<br />
						<span>{formattedPhoneNumber}</span>
						<br />
						<span>
							{record?.bookingService?.booking?.bookingAddress?.area?.name}
						</span>
					</Link>
				);
			},
		},
		
		// {
		// 	title: 'Address',
		// 	dataIndex: 'areaName',
		// 	key: 'areaName',
		// children: [
		// {
		// 	title: 'Building Name',
		// 	dataIndex: 'buildingName',
		// 	key: 'buildingName',
		// },
		// {
		// 	title: 'Building Number',
		// 	dataIndex: 'buildingNumber',
		// 	key: 'buildingNumber',
		// },
		// {
		// 	title: 'Address',
		// 	dataIndex: 'address',
		// 	key: 'address',
		// },
		// {
		// 	title: 'Postal Code',
		// 	dataIndex: 'postalCode',
		// 	key: 'postalCode',
		// 	align: 'center' as const,
		// },
		// {
		// 	title: 'Address Type',
		// 	dataIndex: 'addressType',
		// 	key: 'addressType',
		// },
		// {
		// 	title: 'Landmark',
		// 	dataIndex: 'landmark',
		// 	key: 'landmark',
		// },
		// {
		// 	title: 'Area Name',
		// 	dataIndex: 'areaName',
		// 	key: 'areaName',
		// },
		// ],
		// },
		// {
		// 	title: 'Scheduled Time',
		// 	dataIndex: 'time',
		// 	key: 'time',
		// 	align: 'center' as const,
		// },
		{
			title: 'Scheduled On ',
			dataIndex: '',
			key: 'bookingDate',
			align: 'center' as const,
			render: (text: string, record: any) => {
				return (
					<span>
						{record?.bookingService?.unit === 'HOUR' ? (
							<>
								{moment(record?.startDateTime).format('lll')}{' '}
								{record?.endDateTime &&
									` - ${moment(record?.endDateTime).format('LT')}`}{' '}
								<br />
								{/* {moment(record?.bookingService?.booking?.createDateTime).format('lll')}  */}
								{/* {moment("2023-08-01T10:24:59.792Z").format('lll')} */}
							</>
						) : (
							<>
								{moment(record?.startDateTime).format('lll')}{' '}
								{record?.endDateTime &&
									` - ${moment(record?.endDateTime).format('lll')}`}{' '}
								<br />
								{/* {moment(record?.bookingService?.booking?.createDateTime).format('lll')} */}
							</>
						)}
					</span>
				);
			},
		},
		{
			title: 'Booked On',
			dataIndex: '',
			key: 'bookingDate',
			align: 'center' as const,
			render: (text: string, record: any) => {
				return (
					<span>
						{record?.bookingService?.unit === 'HOUR' ? (
							<>
								{/* {moment(record?.startDateTime).format('lll')} {record?.endDateTime && (` - ${moment(record?.endDateTime).format('LT')}`)} / <br /> */}
								{moment(record?.bookingService?.booking?.createDateTime).format(
									'lll',
								)}
								{/* {moment("2023-08-01T10:24:59.792Z").format('lll')} */}
							</>
						) : (
							<>
								{/* {moment(record?.startDateTime).format('lll')} {record?.endDateTime && (` - ${moment(record?.endDateTime).format('lll')}`)} / <br /> */}
								{moment(record?.bookingService?.booking?.createDateTime).format(
									'lll',
								)}
							</>
						)}
					</span>
				);
			},
		},

		{
			title: 'Assigned to',
			dataIndex: 'partner',
			key: 'partner',
			align: 'center' as const,
			render: (
				text,
				record: CMSGetBookingServiceItems_getBookingServiceItems_data,
			) => {
				const formatPhoneNumber = (phoneNumber) => {
					phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
					if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
						return `+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`;
					} else {
						return phoneNumber;
					}
				};
				const inputPhoneNumber = record?.servicePartner?.phone;
				const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);
				return (
					<Link
						to={RouteList.partners.path + '/' + record?.servicePartner?.id}
						// target="_blank"
					>
						<span className="gx-link">{record?.servicePartner?.name}</span>
						<br />
						<span>{formattedPhoneNumber}</span>
						<br />
					</Link>
				);
			},
		},

		{
			title: 'Total Amount',
			dataIndex: '',
			key: 'grandTotal',
			align: 'center' as const,
			render: (
				text,
				record: CMSGetBookingServiceItems_getBookingServiceItems_data,
			) => (
				<>
					<span className="">
						₹
						{/* <b> */}
							{record?.bookingService?.booking?.bookingAmount?.grandTotal?.toLocaleString()}
						{/* </b> */}
					</span>
				</>
			),
		},

		{
			title: 'Commission Amount',
			dataIndex: '',
			key: 'grandTotal',
			align: 'center' as const,
			render: (
				text,
				record: CMSGetBookingServiceItems_getBookingServiceItems_data,
			) => (
				<>
					<span className="">
						₹
						{/* <b> */}
							{record?.bookingService?.booking?.bookingAmount?.totalCommission?.toLocaleString()}
						{/* </b> */}
					</span>
				</>
			),
		},

		{
			title: 'Partner Amount',
			dataIndex: '',
			key: 'grandTotal',
			align: 'center' as const,
			render: (
				text,
				record: CMSGetBookingServiceItems_getBookingServiceItems_data,
			) => (
				<>
					<span className="">
						₹
						<b>
							{record?.bookingService?.booking?.bookingAmount?.partnerAmount?.toLocaleString()}
						</b>
					</span>
				</>
			),
		},

		// {
		// 	title: 'Action',
		// 	dataIndex: 'bookingID',
		// 	key: 'bookingID',
		// 	width: 'auto',
		// 	align: 'center' as const,
		// 	render: (
		// 		text,
		// 		record: CMSGetBookingServiceItems_getBookingServiceItems_data,
		// 	) => (
		// 		<span>
		// 			<Link to={RouteList.bookings.path + '/' + record?.id} target="_blank">
		// 			<i className="icon-view-o" style={{ marginRight: '20px' }} />
		// 			</Link>
		// 		</span>
		// 	),
		// },
		// {
		// 	title: 'Status',
		// 	dataIndex: '',
		// 	key: 'areaName',
		// 	align: 'center' as const,
		// 	render: (
		// 		text,
		// 		record: CMSGetBookingServiceItems_getBookingServiceItems_data,
		// 	) => (
		// 		<>
		// 			<span className="">{record?.bookingServiceItemStatus}</span>
		// 		</>
		// 	),
		// },
	];

	const {
		data: qryData,
		loading,
		called,
		error,
		refetch: refetchQryData,
	} = useQuery(CMSQueryGetPendingPayoutsPartner, {
		variables: {
			filters: {
				// name: filterName,
			},
		},
	});

	const [
		getPendingPayoutsBookingData,
		{
			data: qryPendingPayoutsBookingData,
			loading: loadingBooking,
			called: calledBooking,
			error: errorBooking
		},
	] = useLazyQuery(CMSQueryGetPendingPayoutsBooking);

	const handleDate = (dates) => {
		// console.log("datedate00.+++ ",dates);
		if (dates && dates?.length === 2) {
			const startDates = moment(dates[0]?._d).toISOString();
			const endDates = moment(dates[1]?._d).toISOString();
			// setStartDate(moment(startDates).format('MMMM Do YYYY'));
			// setEndDate(moment(endDates).format('MMMM Do YYYY'));
			setStartDate(startDates);
			setEndDate(endDates);
		}
		// setSaveDate(date)
	};

	const onRadioChange = (e) => {
		// console.log('radio checked', e.target.value);
		if (e?.target?.value === 'booking') return

		setRadioValue(e.target.value);
		if (e?.target?.value === 'booking') {
			getPendingPayoutsBookingData({
				variables: {
					filters: {}
				},
			});
		} 
	};

	const ClearAll = () => {
		setStartDate(null);
		setEndDate(null);
		// setRadioValue("scheduledOn")
	};

	const openPayoutModal = () => {
		if (!selectedPartners.length) {
			return message.error('No partners selected');
		} else {
			const selPartners = qryData?.getPendingPayoutsPartner?.filter((p) =>
				selectedPartners.includes(p.id),
			);
			const totalWalletBalance = selPartners.reduce((total, partner) => {
				const walletBalance = partner.partnerDetails
					? partner.partnerDetails.walletBalance
					: 0;
				return total + walletBalance;
			}, 0);

			setTotalAmount(totalWalletBalance);
			setIsReadyToPayout(true);
			return;
		}
	};

	// Get current date for payout date
	const payoutDate = new Date().toLocaleDateString('en-IN', {
		day: '2-digit',
		month: 'short',
		year: 'numeric',
	});

	// Format totalAmount with commas
	const formattedAmount = (amount) => {
		return amount.toLocaleString('en-IN', {
			maximumFractionDigits: 2,
		});
	};

	const [
		generateMultplePartnerPayouts,
		{ data: dataMt, loading: loadingMt, error: errorMt },
	] = useMutation(GENERATE_PARTNER_PAYOUTS, {
		onCompleted: () => {
			refetchQryData();
			// onFinishAdd
			// form.resetFields();
		},
	});

	const onConfirm = () => {
		console.log('on confirrm');

		generateMultplePartnerPayouts({
			variables: {
				partnerIds: selectedPartners,
			},
		})
			.then((resp) => {
				console.log('responseeee', resp);
				setIsReadyToPayout(false);
			})
			.catch((error) => {
				console.log('eeeerrrrrrrrrrrr', error);
			});
	};

	return (
		<Card
			title="Partner Payouts"
			extra={[
				<div style={{ float: 'right', paddingLeft: '1rem' }} key={null}>
					<Button
						className="gx-ml-2 gx-mb-0"
						type="primary"
						onClick={openPayoutModal}
					>
						Settle
					</Button>
				</div>,
			]}
		>
			<Row
				style={{
					marginBottom: '16px',
					paddingTop: '0.2rem',
					paddingBottom: '5px',
				}}
			>
				<Col span={18}>
					<div
						style={{
							justifyContent: 'center',

							display: 'flex',
						}}
					>
						{radioValue === 'booking' && (
							<Input
								placeholder="Search by ID"
								value={searchId}
								style={{ width: '200px' }}
								allowClear
								onChange={(e) => {
									setSearchId(e?.target?.value);
								}}
							/>
						)}

						{radioValue === 'user' && (
							<>
								<Input
									style={{ width: '250px' }}
									placeholder="Search by Name"
									value={filterName}
									onChange={(e) => {
										SetFilterName(e.target.value);
										refetchQryData();
									}}
								/>

								<div style={{ paddingLeft: '0.5rem' }}>
									<Input
										style={{ width: '250px' }}
										placeholder="Search by Phone"
										value={filterPhone}
										allowClear
										onChange={(e) => SetFilterPhone(e.target.value)}
									/>
								</div>
							</>
						)}
					</div>
				</Col>

				<Col span={6}>
					<Radio.Group onChange={onRadioChange} value={radioValue}>
						<Radio value={'user'}>Service Partner</Radio>
						<Radio value={'booking'}>Booking</Radio>
					</Radio.Group>
				</Col>
			</Row>

			<Row>
				<Col
					span={24}
					style={{
						textAlign: 'center',
						paddingTop: '1rem',
						marginBottom: 16,
						backgroundColor: '#f6f6f6',
						paddingBottom: '5px',
					}}
				>
					{/* <Pagination
						key="pagination"
						defaultCurrent={qryData?.getPendingPayoutsPartner?.pageInfo.currentPage}
						current={qryData?.getPendingPayoutsPartner?.pageInfo.currentPage}
						pageSize={pagination.pageSize}
						total={qryData?.getUsers?.pageInfo.totalCount}
						showTotal={(total) => `Total ${total} items`}
						onChange={(page, pageSize) => {
							console.log('onChange');
							setPagination({
								pageNumber: page,
								pageSize: pageSize ? +pageSize : pagination.pageSize,
							});
						}}
						showSizeChanger
					/> */}
				</Col>
			</Row>

			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}

			{radioValue === 'user' && qryData && (
				<Table
					className="gx-table-responsive"
					columns={columns}
					dataSource={qryData?.getPendingPayoutsPartner}
					pagination={false}
				/>
			)}

			{radioValue === 'booking' && qryPendingPayoutsBookingData && (
				<Table
					className="gx-table-responsive"
					columns={bookingTableColumns}
					dataSource={qryPendingPayoutsBookingData?.getPendingPayoutsBooking}
					pagination={false}
				/>
			)}

			{isReadyToPayout && (
				<Modal
					title="Date"
					visible={isReadyToPayout}
					onOk={onConfirm}
					onCancel={() => setIsReadyToPayout(false)}
					width={800}
					okButtonProps={{ loading: loadingMt }}
					confirmLoading={loadingMt}
				>
					<div style={{ textAlign: 'left' }}>
						<Space direction="vertical" size={6}>
							<div className="confirmation-popup">
								<h2>Confirm Payout Generation</h2>
								<div className="popup-content">
									<p>
										Are you sure you want to generate payouts for the selected
										service partners?
									</p>
									<p>
										This action will initiate the payout process for the
										selected partners. Please ensure that all details are
										accurate before proceeding.
									</p>

									<p>
										<strong>Selected Partners:</strong>
									</p>
									<ul>
										{qryData?.getPendingPayoutsPartner?.map(
											(partner, index) =>
												selectedPartners.includes(partner.id) && (
													<li key={index}>
														{partner.name} - ₹
														{partner.partnerDetails.walletBalance}
													</li>
												),
										)}
									</ul>

									<p>
										<strong>Total Amount to be Paid:</strong>{' '}
										<span style={{ fontWeight: 'bold', color: 'red' }}>
											₹{formattedAmount(totalAmount)}
										</span>
									</p>
									<p>
										<strong>Date of Payout:</strong> {payoutDate}
									</p>
									{/* Additional information or instructions if necessary */}
									<p>
										<strong>
											⚠️ This action cannot be undone. Once payouts are
											generated, they cannot be reversed.
										</strong>
									</p>
								</div>
							</div>
						</Space>
					</div>
				</Modal>
			)}
		</Card>
	);
};
export default Payouts;
