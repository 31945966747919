import { useMutation, useQuery } from '@apollo/client';
import {
	Card,
	Col,
	Input,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Table,
	Button,
	Modal,
	Switch,
	DatePicker,
} from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'src/lib/recoil/userCoil';
import {
	CREATE_PERSONNEL,
	UPDATE_PERSONNEL,
	DELETE_PERSONNEL,
} from 'src/lib/gql/mutations/mutations';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {
	CREATE_BULK_NOTIFICATION,
	DELETE_BULK_NOTIFICATION,
	UPDATE_BULK_NOTIFICATION,
} from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetPushNotifications } from 'src/lib/gql/queries';
const { Option } = Select;

const Notifications: React.FC = () => {
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [filterName, setFilterName] = useState('');
	const [name, setName] = useState<any>();
	const [description, setDescription] = useState<any>();
	const [dateAndTime, setDateAndTime] = useState<any>();
	const [statusVal, setStatusVal] = useState<any>([]);
	const [accountType, setAccountType] = useState(UserTypeEnum.CUSTOMER);
	const userAuth = useRecoilValue(userAtom);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
	const [isModalVisibleDelete, setIsModalVisibleDelete] = useState(false);

	const {
		data: qryData,
		loading,
		refetch: refetchQryData,
	} = useQuery(CMSQueryGetPushNotifications, {
		variables: {
			pagination: pagination,
		},
		fetchPolicy: 'network-only',
	});
	console.log('qryData push', qryData);

	// const handleInputChangeName = (e) => {
	// 	let value = e.target.value;
	// 	// Allow only strings (no numbers)
	// 	value = value.replace(/[0-9]/g, '');
	// 	setFilterName(value);
	// };

	const pageSizeOptions = ['10', '20', '50']; // Add '5' to the list of options

	const columns = [
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			width: 'auto',
			render: (title: string, record: any) => (
				<span className="">
					{/* <Link to={RouteList.users.path + '/' + record.id}> */}
					{title}
					{/* </Link> */}
				</span>
			),
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
			width: 'auto',
			render: (description: string, record: any) => (
				<span className="">
					{/* <Link to={RouteList.users.path + '/' + record.id}> */}
					{description}
					{/* </Link> */}
				</span>
			),
		},
		{
			title: 'User Type',
			dataIndex: 'userType',
			key: 'userType',
			width: 'auto',
			render: (userType: string, record: any) => (
				<span className="">
					{/* <Link to={RouteList.users.path + '/' + record.id}> */}
					{userType?.toString()}
					{/* </Link> */}
				</span>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: 'auto',
			render: (status: string, record: any) => (
				<span className="">
					{/* <Link to={RouteList.users.path + '/' + record.id}> */}
					{status}
					{/* </Link> */}
				</span>
			),
		},
		{
			title: 'Schedule On',
			dataIndex: 'scheduleDate',
			key: 'scheduleDate',
			width: 'auto',
			render: (scheduleDate, record) => {
				const convertedDate = scheduleDate ? new Date(scheduleDate) : null;
				// const time = convertedDate.toLocaleTimeString();
				return (
					<span style={{ textAlign: 'center' }}>
						{/* {/* {convertedDate.getDate()} {month}, {convertedDate.getFullYear()}
                            <br /> */}
						{/* {timeCrt} */}
						{convertedDate
							? moment(convertedDate).format('DD-MM-YYYY hh:mm a')
							: '-'}
					</span>
				);
			},
		},
		{
			title: 'Action',
			dataIndex: 'key',
			key: 'id',
			width: 'auto',
			align: 'center' as const,
			render: (text: string, record: any) => (
				<>
					{record?.status === 'PENDING' && (
						<>
							<span
								onClick={() => handleOpenModalEdit(record)}
								style={{ cursor: 'pointer' }}
							>
								<EditOutlined
									style={{ marginRight: '20px', color: '#5143dc' }}
								/>
							</span>
							<span
								onClick={() => handleOpenModalDelete(record)}
								style={{ cursor: 'pointer' }}
							>
								<DeleteOutlined style={{ color: 'red', marginRight: '20px' }} />
							</span>
						</>
					)}
				</>
			),
		},
	];

	const [
		createBulkNotification,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation(CREATE_BULK_NOTIFICATION);

	const [selectedNotification, setSelectedNotification] = useState<any>();
	const [deletedNotification, setdeletedNotification] = useState<any>();
	const [
		updateBulkNotification,
		{ data: dataEditMtU, loading: loadingEditMtU, error: errorEditMtU },
	] = useMutation(UPDATE_BULK_NOTIFICATION);

	const [
		deleteBulkNotification,
		{ data: dataEditMtUd, loading: loadingEditMtUd, error: errorEditMtUd },
	] = useMutation(DELETE_BULK_NOTIFICATION);

	const handleOpenModalEdit = (e) => {
		const selectedNotification = qryData.getPushNotifications.data.find(
			(x, i) => x.id === e.id,
		);
		setSelectedNotification(selectedNotification);
		// setEditPersonnelStatus(selectedNotification?.isActive);

		setIsModalVisible(true);
		setIsModalVisibleEdit(true);
	};
	const handleOpenModalDelete = (e) => {
		const selectedNotification = qryData.getPushNotifications.data.find(
			(x, i) => x.id === e.id,
		);
		setdeletedNotification(selectedNotification);
		setIsModalVisibleDelete(true);
	};

	const handleOpenModal = () => {
		setIsModalVisible(true);
		setIsModalVisibleEdit(false);
	};

	// handle edit changes
	const handleEditChanges = (field) => (e) => {
		setSelectedNotification({
			...selectedNotification,
			[field]: e.target.value,
		});
	};

	const handleOk = () => {
		if (isModalVisibleEdit) {
			updateBulkNotification({
				variables: {
					id: selectedNotification.id,
					data: {
						title: name,
						description: description,
						userType: statusVal,
						scheduleDate: dateAndTime,
					},
				},
				onCompleted: () => {
					refetchQryData();
				},
			});
		} else {
			createBulkNotification({
				variables: {
					data: {
						title: name,
						description: description,
						userType: statusVal,
						scheduleDate: dateAndTime,
					},
				},
				onCompleted: () => {
					refetchQryData();
				},
			});
		}
		setIsModalVisibleEdit(false);
		setIsModalVisible(false);
	};
	const handleCancel = () => {
		setIsModalVisible(false);
		setIsModalVisibleEdit(false);
	};
	const handleCancelDelete = () => {
		setIsModalVisibleDelete(false);
	};
	const handleOkDelete = () => {
		deleteBulkNotification({
			variables: {
				id: deletedNotification.id,
			},
			onCompleted: () => {
				refetchQryData();
				setIsModalVisibleDelete(false);
			},
		});
	};
	useEffect(() => {
		setPagination({
			...pagination,
			pageNumber: 1,
		});
	}, [filterName, accountType]);

	const onDateTime = (_, dateStr) => {
		setDateAndTime(dateStr);
	};
	const statusChange = (value) => {
		if (value === UserTypeEnum.PARTNER || value === UserTypeEnum.CUSTOMER) {
			setStatusVal([value]);
		} else if (value === 'BOTH') {
			setStatusVal([UserTypeEnum.CUSTOMER, UserTypeEnum.PARTNER]);
		}
	};

	return (
		<Card
			title={<div>Notifications</div>}
			extra={[
				<>
					<Button
						onClick={handleOpenModal}
						type="primary"
						style={{ float: 'right' }}
					>
						Add New Notification
					</Button>
				</>,
			]}
		>
			<Row
				style={{
					marginBottom: '16px',
					paddingTop: '0.2rem',
					paddingBottom: '5px',
				}}
			>
				{/* <Col span={24}>
					<div
						style={{
							justifyContent: 'center',
							display: 'flex',
						}}
					>
						<div>
							<Input
								type="string"
								style={{ width: '250px' }}
								placeholder="Search by Title"
								value={filterName}
								allowClear
								onChange={(e) => handleInputChangeName(e)}
							/>
						</div>

					</div>
				</Col> */}
				{/* <Col span={12}>
				
				</Col> */}
			</Row>

			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}

			{/* Data export button */}

			{userAuth?.userType === UserTypeEnum.ADMIN ||
			userAuth?.rights?.find(
				(x) => x.type === 'sub2' && x.name === 'export-csv',
			) ? (
				<Row
					justify="end"
					style={{
						marginBottom: 16,
						paddingTop: '1rem',
						backgroundColor: '#f6f6f6',
						paddingBottom: '5px',
					}}
				>
					<Col span={24} style={{ textAlign: 'center' }}>
						<Pagination
							key="pagination"
							defaultCurrent={
								qryData?.getPushNotifications?.pageInfo.currentPage
							}
							current={qryData?.getPushNotifications?.pageInfo.currentPage}
							pageSize={pagination.pageSize}
							total={qryData?.getPushNotifications?.pageInfo.totalCount}
							showTotal={(total) => `Total ${total} items`}
							onChange={(page, pageSize) => {
								setPagination({
									pageNumber: page,
									pageSize: pageSize ? +pageSize : pagination.pageSize,
								});
							}}
							showSizeChanger
							pageSizeOptions={pageSizeOptions} // Set the pageSizeOptions prop
						/>
					</Col>
				</Row>
			) : (
				<></>
			)}

			{/* Table */}
			<Table
				className="gx-table-responsive"
				columns={columns}
				dataSource={qryData?.getPushNotifications?.data}
				pagination={false}
			/>

			{/* modal edit */}
			<Modal
				title={
					isModalVisibleEdit ? 'Edit Notification' : 'Add New Notification'
				}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
				bodyStyle={{
					padding: '1rem 3rem',
				}}
			>
				<Row>
					<Col span={12} style={{ width: '100px' }}>
						<span>Titile </span>
						<Input
							onChange={
								isModalVisibleEdit
									? handleEditChanges('title')
									: (e) => setName(e.target.value)
							}
							value={isModalVisibleEdit ? selectedNotification?.title : name}
						/>
					</Col>

					<Col span={12} style={{ width: '100px' }}>
						<span>Description</span>
						<Input
							onChange={
								isModalVisibleEdit
									? handleEditChanges('description')
									: (e) => setDescription(e.target.value)
							}
							value={
								isModalVisibleEdit ? selectedNotification?.description : description
							}
						/>
					</Col>
					<Col span={12} style={{ width: '100px', marginTop: '10px' }}>
						<span>Schedule On </span>
						<DatePicker showTime onChange={onDateTime} />
					</Col>
					<Col span={12} style={{ width: '500px' }}>
						<span>User Type</span>
						<Select
							getPopupContainer={(trigger) => trigger.parentElement}
							onChange={(e) => statusChange(e)}
							value={isModalVisibleEdit && selectedNotification?.userType?.length > 1 ? 'BOTH' : isModalVisibleEdit && selectedNotification?.userType?.length == 1 ? selectedNotification?.userType[0] : statusVal} 
							style={{ width: '400px' }}
						>
							<Select.Option value="CUSTOMER">
								{UserTypeEnum.CUSTOMER}
								{/* {ServiceStatusTypeEnum.DRAFT} */}
							</Select.Option>
							<Select.Option value="PARTNER">
								{UserTypeEnum.PARTNER}
								{/* {ServiceStatusTypeEnum.PUBLISHED} */}
							</Select.Option>
							<Select.Option value="BOTH">
								BOTH
								{/* {ServiceStatusTypeEnum.PUBLISHED} */}
							</Select.Option>
						</Select>
					</Col>

					{/* {isModalVisibleEdit && (
						<Col
							span={12}
							style={{
								width: '100px',
								marginTop: '10px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<span style={{ marginRight: '1rem' }}>Status </span>
							<Switch
								onChange={(e) => setEditPersonnelStatus(e)}
								checked={editPersonnelStatus}
							/>
						</Col>
					)} */}
				</Row>
			</Modal>

			{/* modal delete */}
			<Modal
				title={'Delete Bulk Notification'}
				visible={isModalVisibleDelete}
				onOk={handleOkDelete}
				onCancel={handleCancelDelete}
				width={500}
				bodyStyle={{
					padding: '1rem 3rem',
				}}
			>
				<p>Are you sure want to delete?</p>
			</Modal>
		</Card>
	);
};

export default Notifications;
