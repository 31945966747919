// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getMessaging ,onMessage} from 'firebase/messaging';

export const firebaseConfig = {
	apiKey: 'AIzaSyCouCfPC8fdv3mqvrI-eUPFteuC-lXzZTA',
	authDomain: 'zimkey-admin-panel.firebaseapp.com',
	projectId: 'zimkey-admin-panel',
	storageBucket: 'zimkey-admin-panel.appspot.com',
	messagingSenderId: '57540737332',
	appId: '1:57540737332:web:a7da8331c2a5768c298d61',
	measurementId: 'G-CXP1GRQGYD',
};

export const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

// // Function to get the current token
// const getCurrentToken = async () => {
// 	try {
// 	  const currentToken = await messaging.getToken();
// 	  if (currentToken) {
// 		// Handle the current token (e.g., send it to your server)
// 		console.log('Current token:', currentToken);
// 	  } else {
// 		console.warn('No registration token available.');
// 	  }
// 	} catch (error) {
// 	  console.error('Error getting token:', error);
// 	}
//   };
  
//   // Listen for incoming messages (notifications)
  
  
//   // Request permission for notifications
//   const requestNotificationPermission = async () => {
// 	try {
// 	  const permission = await Notification.requestPermission();
// 	  if (permission === 'granted') {
// 		console.log('Notification permission granted.');
// 		getCurrentToken(); // Get the current token after permission is granted
// 	  } else {
// 		console.warn('Notification permission denied.');
// 	  }
// 	} catch (error) {
// 	  console.error('Error requesting permission:', error);
// 	}
//   };
  
//   // Check for notification permission and get token
//   requestNotificationPermission();
  


// export const subscribeToTopic = (topicName, handler = () => {}) =>
//   getToken().then((currentToken) => {
//     if (currentToken) {
//       const FIREBASE_API_KEY = `AAAA***:************************************************`;
//       // Subscribe to the topic
//       const topicURL = `https://iid.googleapis.com/iid/v1/${currentToken}/rel/topics/${topicName}`;
//       return fetch({
//         url: topicURL,
//         method: "POST",
//         headers: {
//           Authorization: `key=${firebaseConfig.apiKey}`,
//         },
//       })
//         .then((response) => {
//           fMessaging.onMessage(
//             (payload) => {
//               handler(payload);
//             },
//             (error) => {
//               console.log(error);
//             }
//           );
//         })
//         .catch(() => {
//           console.error(`Can't subscribe to ${topicName} topic`);
//         });
//     }
//   });