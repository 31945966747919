import { useMutation } from '@apollo/client';
import { Alert, Button, Col, Form, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { CMSGetPartner_getUser } from 'src/lib/gql/generated/CMSGetPartner';
import {
	CMSUpdatePartnerPayoutAccount,
	CMSUpdatePartnerPayoutAccountVariables,
} from 'src/lib/gql/generated/CMSUpdatePartnerPayoutAccount';
import { UPDATE_PARTNER_ACCOUNT_PAYOUT } from 'src/lib/gql/mutations/mutations';

interface Props {
	input?: CMSGetPartner_getUser | null;
	onFinishAdd?: () => void;
}

const UpdatePartnerPayoutAccount: React.FC<Props> = ({
	input,
	onFinishAdd,
}) => {
	const [isModalVisible, setIsModalVisible] = useState(false);

	// Update
	const [
		updateDetails,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation<
		CMSUpdatePartnerPayoutAccount,
		CMSUpdatePartnerPayoutAccountVariables
	>(UPDATE_PARTNER_ACCOUNT_PAYOUT);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
		setIsModalVisible(false);
	};

	const [form] = Form.useForm();

	const onFinish = (values) => {
		if (input) {
			updateDetails({
				variables: {
					partnerId: input?.id,
					data: {
						ifscCode: values?.ifsc,
						accountNumber: values?.accountNumber,
					},
				},
			}).then(() => {
				form.resetFields();
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
					}
					setIsModalVisible(false);
				}, 1500);
			});
		}
	};
	return (
		<>
		<div style={{paddingRight:"1rem"}}>
			<Button type="primary" onClick={showModal}>
				Edit
			</Button></div>
			<Modal
				title={!input ? 'Add Coupon' : 'Edit'}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
			>
				{/* Updating */}
				{dataMtU && <Alert message="Updated" type="success" showIcon />}
				{loadingMtU && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtU && (
					<Alert
						message={`Submission error! ${errorMtU.message}`}
						type="error"
						showIcon
					/>
				)}

				<Form
					form={form}
					name="addCoupon"
					onFinish={onFinish}
					scrollToFirstError
					initialValues={
						!input
							? {}
							: {
									...input,
									ifsc: input?.partnerDetails?.ifsc,
									accountNumber: input?.partnerDetails?.accountNumber,
							  }
					}
				>
					<Row>
						<Col span={12}>
							<Form.Item
								name={`ifsc`}
								label={<span>IFSC</span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name={`accountNumber`}
								label={<span>Account No</span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={2}></Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};
export default UpdatePartnerPayoutAccount;
