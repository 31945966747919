import { useMutation, useQuery } from '@apollo/client';
import {
	Alert,
	Avatar,
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Row,
	Space,
	Spin,
	Switch,
	Modal,
	Input,
	Pagination,
	Select,
} from 'antd';
import { MailOutlined, MobileOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Contact from 'src/components/profile/contact/index';
import ProfileHeader from 'src/components/profile/profileHeader/index';
import UpdatePartnerDetails from 'src/components/users/updatePartnerDetails';
import UpdatePartnerDocuments from 'src/components/users/updatePartnerDocuments';
import UpdatePartnerPayoutAccount from 'src/components/users/updatePartnerPayoutAccount';
import UpdatePartnerServiceAreas from 'src/components/users/updatePartnerServiceAreas';
import UpdatePartnerServices from 'src/components/users/updatePartnerServices';
import AuthenticatedImage from 'src/components/util/AuthenticatedImage';
import {
	CMSApprovePartner,
	CMSApprovePartnerVariables,
} from 'src/lib/gql/generated/CMSApprovePartner';
import {
	CMSGetPartner,
	CMSGetPartnerVariables,
} from 'src/lib/gql/generated/CMSGetPartner';
import {
	CMSUpdatePartnerPendingTasks,
	CMSUpdatePartnerPendingTasksVariables,
} from 'src/lib/gql/generated/CMSUpdatePartnerPendingTasks';
import {
	PartnerPendingTaskEnum,
	UserTypeEnum,
	PartnerStatusTypeEnum,
} from 'src/lib/gql/generated/globalTypes';
import {
	APPROVE_PARTNER,
	UPDATE_PARTNER_PENDING_TASKS,
} from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetPartner } from 'src/lib/gql/queries/users';
import { userTokenAtom, userAtom } from 'src/lib/recoil/userCoil';
import Widget from 'src/components/widget';
import { Radio, Table } from 'antd';
import { useState } from 'react';
import CircularProgress from 'src/components/CircularProgress';
import Counts from 'src/components/profile/counts';
import ServiceCounts from './serviceCounts';
import { RouteList } from 'src/config/constants/routes';
import atm from './atm-card.png';
import {
	UPDATE_DISABLE_PARTNER_ACCOUNT,
	DELETE_PARTNER_DOCUMENT,
} from 'src/lib/gql/mutations/mutations';
import moment from 'moment';
import {
	CMSGetUserBookings,
	CMSGetUserBookingsVariables,
} from 'src/lib/gql/generated/CMSGetUserBookings';
import { CMSQueryGetUserBookingServiceItems, CMSQueryGetUserBookings } from 'src/lib/gql/queries/bookings';
import { REJECT_PARTNER } from 'src/lib/gql/mutations/mutations';
import { useNavigate } from 'react-router-dom';
import { CaretLeftOutlined } from '@ant-design/icons';
import ImageAuth from './imageAuth';
import {
	CMSUpdatePartnerDetails,
	CMSUpdatePartnerDetailsVariables,
} from 'src/lib/gql/generated/CMSUpdatePartnerDetails';
import { UPDATE_PARTNER_DETAILS } from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetServiceAreas } from 'src/lib/gql/queries/services';
import { log } from 'console';

const { Meta } = Card;
//Dummy data
export const friendList = [
	{
		id: 1,
		image: 'https://via.placeholder.com/150',
		name: 'Electrician',
		status: 'online',
	},
	{
		id: 2,
		image: 'https://via.placeholder.com/150',
		name: 'Plumber',
		status: 'offline',
	},
	{
		id: 3,
		image: 'https://via.placeholder.com/150',
		name: 'Cleaning',
		status: 'away',
	},
	{
		id: 4,
		image: 'https://via.placeholder.com/150',
		name: 'Cleaning',
		status: 'away',
	},
];

//Dummy data end

const PartnerDetail: React.FC = () => {
	const userToken = useRecoilValue(userTokenAtom);
	const userAuth = useRecoilValue(userAtom);
	const [updateAlert, setUpdateAlert] = useState(false)
	const [errorAlert, setErrorAlert] = useState(false)
	const [isPreviewVisible, setIsPreviewVisible] = useState(false);
	const [loader, setLoader] = useState(false);
	const [news, setNews] = useState(0);
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [filterName, setFilterName] = useState('');
	const [delteCnfrm, setDelteCnfrm] = useState(false);
	const [proActivate, setProActivate] = useState(false);
	const [proDeactivate, setProDeactivate] = useState(false);
	const [verficationStatus, setVerficationStatus] = useState<any>();
	const [verficationStatusMsg, setVerficationStatusMsg] = useState(false);
	const [statusVal, setStatusVal] = useState<any>();
	const [backgroundNote, setBackgroundNote] = useState<any>();
	const [accountType, setAccountType] = useState(UserTypeEnum.PARTNER);
	const navigate = useNavigate();
	const [blob, setBlob] = useState<any>('');
	const [backgroundDate, setBackgroundDate] = useState<any>();
	const [activateOpen,setActivateOpen]= useState<any>(false);
	const [activateOpenZimkeyPartner,setActivateOpenZimkeyPartner]= useState<any>(false);
	const[partnerArea,setPartnerArea]=useState<any>();
	const { id } = useParams<{ id: string }>();
	const [updateAlertReq, setUpdateAlertReq] = useState(false)
	const [errorAlertReq, setErrorAlertReq] = useState(false)

	const {
		loading,
		data: qryData,
		refetch: refetchQryData,
	} = useQuery(CMSQueryGetPartner, {
		variables: {
			id: id || '',
			pagination: pagination,
			filters: {
				type: accountType,
				name: filterName,
				// phone: filterPhone,
			},
		},
		errorPolicy: 'all',
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'network-only'
	});
	const { data: qryDataArea, refetch: refetchQryDatas } =
	useQuery(CMSQueryGetServiceAreas, {
		errorPolicy: 'all',
	});
	// console.log('completetetetetetettetet', qryData?.getUser)
	// Update background
	const [
		updateDetail,
		{ data: dataMtUs, loading: loadingMtUs, error: errorMtUs },
	] = useMutation(
		UPDATE_PARTNER_DETAILS,{
			onCompleted: () => {
				refetchQryData()
			},
		}
	);


	useEffect(()=>{
		let dataaa =qryDataArea?.getAreas?.filter(value => value.id==qryData?.getUser?.partnerDetails?.address?.area);
		dataaa =dataaa?.map(
			(value) => value?.name
			)
			setPartnerArea(dataaa)
	},[qryData,qryDataArea])	

	const imageUrl = qryData?.getUser?.partnerDetails?.photo?.url;
	const userName = qryData?.getUser?.name;

	const { data: qryDatas } = useQuery<
		CMSGetUserBookings,
		CMSGetUserBookingsVariables
	>(CMSQueryGetUserBookings, {
		variables: {
			pagination: pagination,
			useId: id!,
		},
	});


	const { data: qryDataBooking, loading: bookingsLoading, refetch: refetchGetUserBookingServiceItems } = useQuery
	(CMSQueryGetUserBookingServiceItems, {
		variables: {
			pagination: pagination,
			userId: id,
		},
	});

	// Update
	const [
		updateDetails,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation(UPDATE_DISABLE_PARTNER_ACCOUNT, {
		onCompleted: () => {
			refetchQryData();
		},
	});

	

	//  Delete
	const [
		deleteDocument,
		{ data: dataMtUe, loading: loadingMtUe, error: errorMtUe },
	] = useMutation(DELETE_PARTNER_DOCUMENT, {
		onCompleted: () => {
			refetchQryData();
		},
	});

	const [statusCheck, setStatusCheck] = useState<any>(false);
	const [statusZimkeyPartner, setStatusZimkeyPartner] = useState<any>(false);

	useEffect(() => {
		if (qryData?.getUser?.partnerDetails?.disableAccount === true || qryData?.getUser?.partnerDetails?.disableAccount === false) {
			setStatusCheck(qryData?.getUser?.partnerDetails?.disableAccount);
		}
		if (qryData?.getUser?.partnerDetails?.isZimkeyPartner === true || qryData?.getUser?.partnerDetails?.isZimkeyPartner === false) {
			setStatusZimkeyPartner(qryData?.getUser?.partnerDetails?.isZimkeyPartner);
		}
		// console.log('statusCheck api',qryData?.getUser?.disableAccount);
		// console.log('statusCheck--', statusCheck);
		setBackgroundNote(
			qryData?.getUser?.partnerDetails?.backgroundVerificationNote,
		);
		setVerficationStatus(
			qryData?.getUser?.partnerDetails?.isBackgroundVerification,
		);
		setBackgroundDate(
			qryData?.getUser?.partnerDetails?.backgroundVerificationDateTime,
		);
	}, [qryData]);

	const handleChange = (e) => {
		const value = e.target.value;
		setNews(e.target.value);
		// if (value == 0){
		// 	<Counts  />
		// }else{
		// 	<Counts  />
		// }
		setLoader(true);
		setTimeout(() => {
			setLoader(false);
		}, 1500);
	};

	const handleClick = (e) => {
		setStatusCheck(e);
		setActivateOpen(true)
	};
	const handleClickZimkeyPartner= (e) => {
		setStatusZimkeyPartner(e);
		setActivateOpenZimkeyPartner(true)
	};
	const activeOk=()=>{
		updateDetails({
			variables: {
				partnerId: qryData?.getUser?.id,
				status: statusCheck,
			},
		});
		// setResult(true)
		// console.log("val",e);
		setProDeactivate(true);
		// if (e===true){
		// 	setProActivate(true)
		// 	setProDeactivate(false)
		// 	setTimeout(() => {
		// 		setProActivate(false)
		// 	}, 2000);
		// }else{
		// 	setProDeactivate(true)
		// 	setProActivate(false)
		setTimeout(() => {
			setProDeactivate(false);
		}, 2000);
		// }
		setActivateOpen(false)
	}
	const activeCancel=()=>{
		setActivateOpen(false)
		// refetchQryData()
		setStatusCheck(qryData?.getUser?.partnerDetails?.disableAccount);
		console.log("stat innn",statusCheck);

	}

	const activeOkZimkeyPartner=()=>{
				updateDetail({
					variables: {
						partnerId: qryData?.getUser?.id,
						data: {
							isZimkeyPartner: statusZimkeyPartner,
						},
					},
				});
		setProDeactivate(true);
		setTimeout(() => {
			setProDeactivate(false);
		}, 2000);
		setActivateOpenZimkeyPartner(false)
	}
	const activeCancelZimkeyPartner=()=>{
		setActivateOpenZimkeyPartner(false)
		setStatusZimkeyPartner(qryData?.getUser?.partnerDetails?.isZimkeyPartner);
		// refetchQryData()
	}
	const columns = [
		{
			title: 'Booking ID',
			dataIndex: '',
			key: 'userBookingNumber',
			render: (record: any) => (
				<>
					<Link to={RouteList.bookings.path + '/' +record?.id}>
						{/* <span> */}
						{/* {bookingService?.userBookingNumber} */}

						{record?.bookingService?.booking?.userBookingNumber}
						{/* </span> */}
					</Link>
				</>
			),
		},
		{
			title: 'Unit',
			key: 'units',
			dataIndex: '',
			render: (record: any) => <span>{record?.units}</span>,
		},
		{
			title: 'Booking Amount',
			key: 'grandTotal',
			dataIndex: '',
			render: (record: any) => <span>{record?.bookingService?.booking?.bookingAmount?.grandTotal}</span>,
		},
		{
			title: 'Service',
			dataIndex: '',
			key: 'service',
			render: (record: any) => <span>{record?.bookingService?.service?.name}</span>,
		},
		{
			title: 'Booked On',
			dataIndex: 'bookingDate',
			key: 'bookingDate',
			align: 'center' as const,
			render: (text: string, record: any) => (
				<span>{moment(record?.bookingService?.booking?.book).format('lll')}</span>
			),
		},
		{
			title: 'Scheduled On',
			dataIndex: 'startDateTime',
			key: 'bookingDate',
			align: 'center' as const,
			render: (text: string, record: any) => (
				<span>{moment(record?.startDateTime).format('lll')}</span>
			),
		},

		// {
		// 	title: 'Area',
		// 	dataIndex: 'area',
		// 	render: (area: string, record: any) => (
		// 		<span>{record.customerDetails?.defaultAddress?.area?.name}</span>
		// 	),
		// },
		// {
		// 	title: 'City',
		// 	dataIndex: 'city',
		// 	key: 'city',
		// },
		// {
		// 	title: 'Status',
		// 	dataIndex: 'bookingStatus',
		// 	key: 'bookingStatus',
		// },
		// {
		// 	title: 'Action',
		// 	dataIndex: 'key',
		// 	key: 'id',
		// 	width: 'auto',
		// 	align: 'center' as const,
		// 	render: (text: string, record: any) => (
		// 		<span>
		// 			<Link to={RouteList.users.path + '/' + record.id} target="_blank">
		// 				<i className="icon-view-o" style={{ marginRight: '20px' }} />
		// 			</Link>
		// 		</span>
		// 	),
		// },
	];


	const statusChange = (e) => {
		// console.log("12",e);
		if (e === 'ACTION') {
			acceptModalOpen();
		} else {
			rejectModalOpen();
		}
	};

	const delteOk = () => {
		setDelteCnfrm(true);
	};
	const handleOk = (id) => {
		setDelteCnfrm(false);
		setProActivate(true);
		deleteDocument({
			variables: {
				id: id,
			},
		});
	};

	const handleCancel = () => {
		setDelteCnfrm(false);
		setProActivate(false);
	};
	//  reject Modal

	const [reject, setReject] = useState(false);

	const rejectModalOpen = () => {
		setReject(true);
	};
	const handleRejectOk = () => {
		rejectPartner({
			variables: {
				partnerId: id ? id : 'id',
			},
		}).then(() => {
			console.log('done');
		});
		setReject(false);
	};

	const handleRejectCancel = () => {
		setReject(false);
		setStatusVal('Select Status');
	};

	// accept modal
	const [accept, setAccept] = useState(false);

	const acceptModalOpen = () => {
		setAccept(true);
	};

	const handleAcceptOk = () => {
		approvePartner({
			variables: {
				partnerId: id ? id : 'id',
			},
		}).then(() => {
			console.log('done');
			refetchQryData()
			refetchGetUserBookingServiceItems()
		});
		setAccept(false);
	};

	const handleAcceptCancel = () => {
		setAccept(false);
		setStatusVal('Select Status');
	};

	const contactList = [
		{
			id: 1,
			title: 'Email',
			icon: 'email',
			desc: [
				<span className="gx-link" key={1}>
					{qryData?.getUser?.email}
				</span>,
			],
		},
		{
			id: 3,
			title: 'Phone',
			icon: 'phone',
			desc: [`${qryData?.getUser?.phone}`],
		},
	];

	const [dateTime, setDateTime] = useState('');

	useEffect(() => {
		if (qryData?.getUser?.createDateTime) {
			const datetime = moment(+qryData?.getUser?.createDateTime).format(
				'DD-MM-YYYY, h:mm:ss a',
			);
			// console.log("date",datetime);
			setDateTime(datetime);
		}
	}, [qryData?.getUser?.createDateTime]);

	const [approvePartner, { data: dataMp, loading: loadingMp, error: errorMp }] =
		useMutation<CMSApprovePartner, CMSApprovePartnerVariables>(APPROVE_PARTNER);


	const [
		rejectPartner,
		{ data: dataMps, loading: loadingMps, error: errorMpS },
	] = useMutation(REJECT_PARTNER);
	

	const [
		updatePartnerPendingTasks,
		{ data: dataMt, loading: loadingMt, error: errorMt },
	] = useMutation<
		CMSUpdatePartnerPendingTasks,
		CMSUpdatePartnerPendingTasksVariables
	>(UPDATE_PARTNER_PENDING_TASKS);

	const onFinish = (values: any) => {
		if(values[PartnerPendingTaskEnum.UPLOAD_DOCUMENT] ||
			values[PartnerPendingTaskEnum.SELECT_AREA] ||
			values[PartnerPendingTaskEnum.SELECT_SERVICE] 
			){
				const requests: PartnerPendingTaskEnum[] = [];
		if (values[PartnerPendingTaskEnum.UPLOAD_DOCUMENT]) {
			requests.push(PartnerPendingTaskEnum.UPLOAD_DOCUMENT);
		}
		if (values[PartnerPendingTaskEnum.SELECT_AREA]) {
			requests.push(PartnerPendingTaskEnum.SELECT_AREA);
		}
		if (values[PartnerPendingTaskEnum.SELECT_SERVICE]) {
			requests.push(PartnerPendingTaskEnum.SELECT_SERVICE);
		}
		if (id) {
			updatePartnerPendingTasks({
				variables: {
					partnerId: id,
					pendingItems: requests,
				},
			}).then(() => {
				setUpdateAlertReq(true)
				setTimeout(() => {
					setUpdateAlertReq(false)
				}, 2000)
			}).catch((error) => {
				setErrorAlertReq(true);
				setTimeout(() => {
					setErrorAlertReq(false);
				}, 3000);
			});
		}

		}
		
	};

	if (loading) {
		return (
			<Row justify="center">
				<Space size="middle">
					<Spin size="large" />
				</Space>
			</Row>
		);
	}
	const partnerStats = [
		{
			label: 'Wallet Balance',
			value: qryData?.getUser?.partnerDetails?.walletBalance ?? '...',
		},
	];
	const handlePageSizeChange = (current: number, size: number) => {
		setPagination((prevPagination) => ({
			pageNumber: 1, // Reset to the first page when changing page size
			pageSize: size,
		}));
	};

	// const userToken = useRecoilValue(userTokenAtom);
	const imageChange = () => {
		const src = imageUrl;
		const options = {
			headers: {
				authorization: userToken ? userToken : '',
			},
		};
		fetch(src, options)
			.then((res) => res.blob())
			.then((blob) => {
				setBlob(URL.createObjectURL(blob));
			});
	};
	const backVerification = (e) => {
		console.log('ee', e.target.checked);

		setVerficationStatus(e.target.checked);
		setVerficationStatusMsg(true);
		updateDetail({
			variables: {
				partnerId: qryData?.getUser?.id,
				data: {
					isBackgroundVerification: e.target.checked,
					backgroundVerificationNote: backgroundNote,
					// isZimkeyPartner:null
				},
			},
		});
	};
	const saveBackgroundNote = () => {
		if(backgroundNote){
			updateDetail({
				variables: {
					partnerId: qryData?.getUser?.id,
					data: {
						isBackgroundVerification: null,
						backgroundVerificationNote: backgroundNote,
						// isZimkeyPartner:null
					},
				},
			})
			.then(() => {
				setUpdateAlert(true)
				setTimeout(() => {
				setUpdateAlert(false)
				}, 2000)
			}).catch((error) => {
				setErrorAlert(true);
				setTimeout(() => {
					setErrorAlert(false);
				}, 3000);
			});
		}
	};
	
	const formatPhoneNumber = (phoneNumber) => {
		phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
		if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
		  return `+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`;
		} else {
		  return phoneNumber;
		}
	  };

	const inputPhoneNumber =qryData?.getUser?.phone
	const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);


	return (
		<>
			<Row>
				<Col span={24}>
					<div style={{ display: 'flex' }}>
						<h2 style={{ paddingBottom: '2px' }}>
							<Button
								className="gx-mr-4"
								onClick={() => navigate(-1)}
								style={{ margin: '0' }}
							>
								<CaretLeftOutlined />
							</Button>
							Provider Details -{' '}
							{imageUrl && (
							<ImageAuth
								url={`https://staging.api.zimkey.in/media-upload/` + imageUrl}
							/>
							)}
							{userName}
						</h2>
					</div>
				</Col>
			</Row>
			<Row>
				{qryData?.getUser?.email && (
					<>
						{/* <Col span={6} style={{marginRight:"-20px"}} > 	 */}
						<p style={{ paddingRight: '1rem', paddingLeft: '1rem' }}>
							<MailOutlined /> {qryData?.getUser?.email}{' '}
						</p>
						{/* </Col> */}|
					</>
				)}

				{qryData?.getUser?.phone && (
					<>
						{/* <Col span={5} style={{marginRight:"-50px"}} >  */}
						<p style={{ paddingRight: '1rem', paddingLeft: '1rem' }}>
							<MobileOutlined />
							{/* {qryData?.getUser?.phone}{' '} */}
							{formattedPhoneNumber}
						</p>
						{/* </Col> */}|
					</>
				)}
				{/* <Col span={6}  >  */}
				<p style={{ paddingLeft: '1rem' }}>Joined on {dateTime} </p>
				{/* </Col> */}
			</Row>

			<Widget>
				<div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-dash-search">
					<div className="gx-mx-sm-2">
						<Radio.Group
							className="gx-radio-group-link gx-radio-group-link-news"
							defaultValue={news}
							onChange={handleChange}
						>
							<Radio.Button value={0} className="gx-mb-1">
								Overview
							</Radio.Button>
							<Radio.Button value={1} className="gx-mb-1">
								Services
							</Radio.Button>
							{qryData?.getUser?.userType === UserTypeEnum.PENDING_PARTNER ? (
								''
							) : qryData?.getUser?.userType ===
							  UserTypeEnum.REJECTED_PARTNER ? (
								''
							) : (
								<Radio.Button value={2} className="gx-mb-1">
									Bookings
								</Radio.Button>
							)}

							<Radio.Button value={3} className="gx-mb-1">
								Area
							</Radio.Button>
							<Radio.Button value={5} className="gx-mb-1">
								Documents
							</Radio.Button>
							<Radio.Button value={6} className="gx-mb-1">
								Bank Information
							</Radio.Button>
							{/* <Radio.Button value={7} className="gx-mb-1">
								Availability
							</Radio.Button> */}
							{userAuth?.userType === UserTypeEnum.ADMIN ||
							userAuth?.rights?.find(
								(x) => x.type === 'sub2' && x.name === 'partner-settings',
							) ? (
								qryData?.getUser?.userType === UserTypeEnum.PENDING_PARTNER ? (
									''
								) : qryData?.getUser?.userType ===
								  UserTypeEnum.REJECTED_PARTNER ? (
									''
								) : (
									<Radio.Button value={4} className="gx-mb-1">
										Settings
									</Radio.Button>
								)
							) : (
								<></>
							)}
						</Radio.Group>
					</div>
				</div>

				{loader && <CircularProgress className="gx-loader-10" />}

				{news === 0 ? (
					<>
						{qryData?.getUser?.userType === UserTypeEnum.PENDING_PARTNER ? (
							''
						) : qryData?.getUser?.userType === UserTypeEnum.REJECTED_PARTNER ? (
							''
						) : (
							<ServiceCounts data={qryData?.getUser} />
						)}
					</>
				) : news === 1 ? (
					<>
						{/* // <BookingDetails bookingsData={qryData?.getUser?.bookings}/> */}
						{/* <span>Services {qryData?.getUser?.partnerDetails?.services?.length}</span> */}

						<Card
							style={{ paddingLeft: '1rem' }}
							// title="Services" + {qryData?.getUser?.partnerDetails?.services?.length}
							title={`Services (${qryData?.getUser?.partnerDetails?.services?.length})`}
							className={`gx-card-widget gx-card-tabs gx-card-profile`}
							extra={
								userAuth?.userType === UserTypeEnum.ADMIN ||
								userAuth?.rights?.find(
									(x) => x.type === 'sub2' && x.name === 'services-edit',
								) ||
								userAuth?.rights?.find(
									(x) =>
										x.type === 'sub2' && x.name === 'pending-services-edit',
								) ? (
									qryData?.getUser?.partnerDetails?.services && (
										<UpdatePartnerServices
											input={qryData?.getUser}
											onFinishAdd={refetchQryData}
										/>
									)
								) : (
									<></>
								)
							}
						>
							<br />
							<Row gutter={8}>
								{qryData?.getUser?.partnerDetails?.services?.map((el) => {
									return (
										<Col key={el.id} span={8} className="gx-mb-2">
											<Card>
												<br />
												<Meta
													avatar={
														<Avatar
															src={`https://staging.api.zimkey.in/media-upload/${el?.icon?.url}`}
														/>
													}
													title={el.name}
													description={el.code}
												/>
											</Card>
										</Col>
									);
								})}
							</Row>
						</Card>
					</>
				) : news === 2 ? (
					<>
						<Row
							justify="end"
							style={{
								marginBottom: 16,
								paddingTop: '1rem',
								backgroundColor: '#f6f6f6',
								paddingBottom: '5px',
							}}
						>
							<Col span={24} style={{ textAlign: 'center' }}>
								<Pagination
									key="pagination"
									defaultCurrent={
										qryDataBooking?.getUserBookingServiceItems?.pageInfo.currentPage
									}
									current={qryDataBooking?.getUserBookingServiceItems?.pageInfo.currentPage}
									pageSize={pagination.pageSize}
									total={qryDataBooking?.getUserBookingServiceItems?.pageInfo
										?.totalCount}
									showTotal={(total) => `Total ${total} items`}
									onChange={(page, pageSize) => {
										setPagination({
											pageNumber: page,
											pageSize: pageSize ? +pageSize : pagination.pageSize,
										});
									}}
									onShowSizeChange={handlePageSizeChange}
									showSizeChanger
									pageSizeOptions={['10', '20', '50', '100']}
								/>
							</Col>
						</Row>
						{bookingsLoading ? (
							<Row justify="center">
								<Space size="middle">
									<Spin size="large" />
								</Space>
							</Row>
						) : (
							<Table
								className="gx-table-responsive"
								columns={columns}
								dataSource={qryDataBooking?.getUserBookingServiceItems?.data}
								pagination={false}
							/>
			            )}
					</>
				) : news === 3 ? (
					<Card
						style={{ paddingLeft: '1rem' }}
						// title="Service Areas"
						title={`Service Areas (${qryData?.getUser?.partnerDetails?.serviceAreas?.length})`}
						className={`gx-card-widget gx-card-tabs gx-card-profile`}
						extra={
							userAuth?.userType === UserTypeEnum.ADMIN ||
							userAuth?.rights?.find(
								(x) => x.type === 'sub2' && x.name === 'area-edit',
							) ||
							userAuth?.rights?.find(
								(x) => x.type === 'sub2' && x.name === 'pending-areas-edit',
							) ? (
								qryData?.getUser?.partnerDetails?.serviceAreas && (
									<UpdatePartnerServiceAreas
										input={qryData?.getUser}
										onFinishAdd={refetchQryData}
									/>
								)
							) : (
								<></>
							)
						}
					>
						<br />
						<Row gutter={8}>
							{qryData?.getUser?.partnerDetails?.serviceAreas?.map((el) => {
								return (
									<Col key={el.id} span={8} className="gx-mb-2">
										<Card>
											<br />
											<Meta title={el.name} description={el.code} />
										</Card>
									</Col>
								);
							})}
						</Row>
					</Card>
				) : news === 4 ? (
					<div>
						<Card>
							{/* {proActivate && <Alert message="Profile Deactivated" type="success" showIcon />} */}
							{proDeactivate && (
								<Alert message="Updating..." type="success" showIcon />
							)}
							<br />
							<Row>
								<Col span={24}>
							<span style={{ paddingRight: '1rem' }}>
								Disable Partner
							</span>	
							<Switch onChange={(e) => handleClick(e)} checked={statusCheck} />
							</Col></Row><br/>
							<span  style={{ paddingRight: '1rem' }}>
							Zimkey Partner
							</span>	
							<Switch onChange={(e) => handleClickZimkeyPartner(e)} checked={statusZimkeyPartner} />
						</Card>
						{activateOpen && (
						<Modal
						title={'Confirm'}
						visible={activateOpen}
						onOk={ activeOk}
						onCancel={activeCancel}
						width={500}
					>
						{/* {proActivate && <Alert message="Profile updated successfully" type="success" showIcon />} */}
						<br />
						<span>Are you sure to change?</span>
					</Modal>
					)}

					{activateOpenZimkeyPartner && (
						<Modal
						title={'Confirm'}
						visible={activateOpenZimkeyPartner}
						onOk={ activeOkZimkeyPartner}
						onCancel={activeCancelZimkeyPartner}
						width={500}
					>
						{/* {proActivate && <Alert message="Profile updated successfully" type="success" showIcon />} */}
						<br />
						<span>Are you sure to change?</span>
					</Modal>
					)}
					</div>
				) : news === 5 ? (
					<Card
						style={{ paddingLeft: '1rem' }}
						// title="Documents"
						title={`Documents (${qryData?.getUser?.partnerDetails?.documents?.length})`}
						className={`gx-card-widget gx-card-profile`}
						extra={
							userAuth?.userType === UserTypeEnum.ADMIN ||
							userAuth?.rights?.find(
								(x) => x.type === 'sub2' && x.name === 'documents-add',
							) ||
							userAuth?.rights?.find(
								(x) => x.type === 'sub2' && x.name === 'pending-documents-add',
							) ? (
								<UpdatePartnerDocuments
									input={qryData?.getUser}
									onFinishAdd={refetchQryData}
								/>
							) : (
								<></>
							)
						}
					>
						<Row gutter={12}>
							{qryData?.getUser?.partnerDetails?.documents?.map((document) => {
								let url: any = '';
								let url2: any = '';

								if (document?.medias && document?.medias[0]?.url && userToken) {
									url =
										'https://staging.api.zimkey.in/media-upload/' +
										document?.medias[0]?.url;
									url2 =
										'https://staging.api.zimkey.in/media-upload/' +
										document?.medias[1]?.url;

									return (
										<Col
											key={document.medias[0].id}
											xl={6}
											lg={12}
											md={12}
											sm={12}
											xs={24}
											className="gx-mb-2"
										>
											<Card hoverable >
												<br />
												<AuthenticatedImage url={url} url2={url2} height={'100%'} />
												<Meta title={document.documentType} />
											</Card>
											<div
												className="ant-card-head"
												style={{ textAlign: 'center' }}
											>
												{userAuth?.userType === UserTypeEnum.ADMIN ||
												userAuth?.rights?.find(
													(x) =>
														x.type === 'sub2' && x.name === 'documents-delete',
												) ||
												userAuth?.rights?.find(
													(x) =>
														x.type === 'sub2' &&
														x.name === 'pending-documents-delete',
												) ? (
													<Link to={''} onClick={delteOk}>
														<Button className="gx-mb-0 gx-mr-4" type="primary">
															Delete
														</Button>
													</Link>
												) : (
													<></>
												)}
											</div>

											{delteCnfrm && (
												<Modal
													title={'Delete'}
													visible={delteCnfrm}
													onOk={() => handleOk(document?.id)}
													onCancel={handleCancel}
													width={500}
												>
													{/* {proActivate && <Alert message="Profile updated successfully" type="success" showIcon />} */}
													<br />
													<span>Are you sure delete the document?</span>
												</Modal>
											)}
										</Col>
									);
								}
								return '';
							})}
						</Row>
					</Card>
				) : news === 7 ? (
					<Card
						style={{
							textAlign: 'center',
							width: '300px',
							borderRadius: '1rem',
							backgroundColor: '#eaf7ea',
						}}
					>
						{qryData?.getUser?.partnerDetails?.isAvailable ? (
							<h1 style={{ color: 'green' }}>Available</h1>
						) : (
							<>
								<h1 style={{ color: 'red' }}>Un Available</h1>

								{qryData?.getUser?.partnerDetails?.unavailableTill &&
								qryData?.getUser?.partnerDetails?.unavailableTill !== null ? (
									<h3>{qryData?.getUser?.partnerDetails?.unavailableTill}</h3>
								) : (
									<h3>- -</h3>
								)}
							</>
						)}
					</Card>
				) : (
					<div>
						<Card
							style={{ paddingLeft: '1rem' }}
							// title="Bank Information"
							className={`gx-card-widget gx-card-tabs gx-card-profile`}
							extra={
								userAuth?.userType === UserTypeEnum.ADMIN ||
								userAuth?.rights?.find(
									(x) => x.type === 'sub2' && x.name === 'bank-edit',
								) ||
								userAuth?.rights?.find(
									(x) => x.type === 'sub2' && x.name === 'pending-bank-edit',
								) ? (
									<UpdatePartnerPayoutAccount
										input={qryData?.getUser}
										onFinishAdd={refetchQryData}
									/>
								) : (
									<></>
								)
							}
						>
							<br />
							<div>
								<Card
									style={{
										width: '45%',
										backgroundColor: '#E3F4F4',
										borderRadius: '5px',
										position: 'relative',
										overflow: 'hidden',
										boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									{/* <div style={{paddingTop:"1rem",display:"flex",borderBottom:"ridge"}}> */}
									<p className="gx-pt-3">
										Holder Name:{' '}
										<span style={{ paddingLeft: '0.5rem', fontWeight: 'bold' }}>
											{qryData?.getUser?.name}
										</span>{' '}
									</p>
									{/* </div> */}
									<hr style={{ width: '40rem', marginLeft: '-5rem' }} />
									<div className="card-content" style={{ display: 'flex' }}>
										<div>
											{/* <h5 className="gx-mb-1">Bank Name:</h5>
											<p style={{ fontSize: '11px' }}>HDFC</p> */}

											<h5 className="gx-mb-1">Branch IFSC Number:</h5>
											<p style={{ fontSize: '11px' }}>
												{qryData?.getUser?.partnerDetails?.ifsc}
											</p>

											<h5>Account Number:</h5>
											<p style={{ fontSize: '11px' }}>
												{qryData?.getUser?.partnerDetails?.accountNumber}
											</p>
										</div>
										<img
											src={atm}
											alt="atm-card"
											style={{
												width: '100px',
												height: '100px',
												position: 'absolute',
												marginLeft: '19rem',
												marginTop: '-2rem',
											}}
										/>
									</div>
								</Card>
							</div>
							{/* <p>
					<strong>IFSC: </strong>
					{qryData?.getUser?.partnerDetails?.ifsc}
				</p>
				<br />
				<p>
					<strong>Account No: </strong>
					{qryData?.getUser?.partnerDetails?.accountNumber}
				</p> */}
						</Card>
					</div>
				)}
			</Widget>

			{/* <ProfileHeader
				name={qryData?.getUser?.name}
				avatar={`https://staging.api.zimkey.in/media-upload/${qryData?.getUser?.partnerDetails?.photo?.url}`}
				stats={partnerStats}
			>
				<Row className="w-full">
					<Col span={12}>
						<UpdatePartnerDetails
							input={qryData?.getUser}
							onFinishAdd={refetchQryData}
						/>
					</Col>
				</Row>
			</ProfileHeader> */}
			{news === 0 && (
				<div className="gx-profile-content">
					<Row>
						{(qryData?.getUser?.userType === UserTypeEnum.PENDING_PARTNER ||
							qryData.getUser.userType === UserTypeEnum.REJECTED_PARTNER) && (
							<Col span={12}>
								<Card
									title="Request Verification Again"
									className={`gx-card-widget gx-card-tabs gx-card-profile`}
								>
									<br />
									{/* Commiting */}
									{updateAlertReq && !loadingMt && (
										<Alert message="Created" type="success" showIcon />
									)}
									{loadingMt && (
										<Alert message="Submitting..." type="info" showIcon />
									)}
									{errorMt && errorAlertReq && (
										<Alert
											message={`Submission error! ${errorMt?.message}`}
											type="error"
											showIcon
										/>
									)}
									<Form
										name="tasks"
										labelCol={{ span: 4 }}
										wrapperCol={{ span: 24 }}
										layout="horizontal"
										onFinish={onFinish}
									>
										<Form.Item
											name={PartnerPendingTaskEnum.UPLOAD_DOCUMENT}
											valuePropName="checked"
										>
											<Checkbox 
											// checked={qryData?.getUser?.partnerDetails.pendingTasks.PartnerPendingTaskEnum.UPLOAD_DOCUMENT}
											>Request Documents</Checkbox>
										</Form.Item>
										<Form.Item
											name={PartnerPendingTaskEnum.SELECT_SERVICE}
											valuePropName="checked"
										>
											<Checkbox 
											// checked={qryData?.getUser?.partnerDetails.pendingTasks.PartnerPendingTaskEnum.SELECT_SERVICE}
											>Request Services</Checkbox>
										</Form.Item>
										<Form.Item
											name={PartnerPendingTaskEnum.SELECT_AREA}
											valuePropName="checked"
										>
											<Checkbox 
											// checked={qryData?.getUser?.partnerDetails.pendingTasks.PartnerPendingTaskEnum.SELECT_AREA}
											>Request Areas</Checkbox>
										</Form.Item>

										<Form.Item>
											{userAuth?.userType === UserTypeEnum.ADMIN ||
											userAuth?.rights?.find(
												(x) =>
													x.type === 'sub2' &&
													x.name === 'request-verification-again',
											) ? (
												<Button type="primary" htmlType="submit">
													Submit
												</Button>
											) : (
												<Button type="primary" disabled htmlType="submit">
													Submit
												</Button>
											)}
										</Form.Item>
									</Form>
								</Card>
							</Col>
						)}
						{(qryData?.getUser?.userType === UserTypeEnum.PENDING_PARTNER ||
							qryData.getUser.userType === UserTypeEnum.REJECTED_PARTNER) && (
							<Col span={12}>
								<Card
									title="Action"
									className={`gx-card-widget gx-card-tabs gx-card-profile`}
								>
									{/* Commiting */}
									{dataMp && (
										<Alert message="Created" type="success" showIcon />
									)}
									{loadingMp && (
										<Alert message="Submitting..." type="info" showIcon />
									)}
									{errorMp && (
										<Alert
											message={`Submission error! ${errorMp.message}`}
											type="error"
											showIcon
										/>
									)}

									{/* Commiting */}
									{dataMps && (
										<Alert message="Rejected" type="success" showIcon />
									)}
									{loadingMps && (
										<Alert message="Rejecting..." type="info" showIcon />
									)}
									{errorMpS && errorAlert && (
										<Alert
											message={`Submission error! ${errorMpS.message}`}
											type="error"
											showIcon
										/>
									)}
										{/* updTE nOTE */}
									{updateAlert &&  !loadingMtUs   && (
										<Alert message="Updated" type="success" showIcon />
									)}
									{loadingMtUs && (
										<Alert message="Updating..." type="info" showIcon />
									)}
									{errorMtUs && (
										<Alert
											message={`Submission error! ${errorMtUs.message}`}
											type="error"
											showIcon
										/>
									)}
									
									<br />
									{(qryData?.getUser?.userType ===
										UserTypeEnum.PENDING_PARTNER ||
										qryData?.getUser?.userType ===
											UserTypeEnum.REJECTED_PARTNER) && (
										<div>
											<div>
												{verficationStatus &&(
													<Alert
														message={`Verification Status On Progress- ${moment(
															qryData?.getUser?.partnerDetails?.backgroundVerificationDateTime,
														).format('lll')}`}
														type="success"
														showIcon
													/>
												)}
												<br />
												{userAuth?.userType === UserTypeEnum.ADMIN ||
											userAuth?.rights?.find(
												(x) => x.type === 'sub2' && x.name === 'pending-background-verification',
											) ? (
												<Checkbox
												onChange={backVerification}
												checked={verficationStatus}
											>
												Background Verification
											</Checkbox>
											) : (
												<></>
											)}
											
											</div>
											<br />
											{verficationStatus && (
												<>
													<Button
														type="primary"
														style={{
															background: 'green',
															borderColor: 'green',
														}}
														disabled={!id}
														onClick={acceptModalOpen}
													>
														Approve
													</Button>
													<Button
														type="primary"
														style={{ background: 'red', borderColor: 'red' }}
														disabled={!id}
														onClick={rejectModalOpen}
													>
														Reject
													</Button>
												</>
											)}
											<br />
											<div>
												<span>Notes</span>
												{userAuth?.userType === UserTypeEnum.ADMIN ||
											userAuth?.rights?.find(
												(x) => x.type === 'sub2' && x.name === 'pending-notes-save',
											) ? (
												<Button
													type="primary"
													style={{ float: 'right' }}
													onClick={saveBackgroundNote}
												>
													Save
												</Button>
											) : (
												<></>
											)}
												
											</div>
											<textarea
												style={{ width: '100%' }}
												value={backgroundNote}
												onChange={(e) => setBackgroundNote(e.target.value)}
											/>

											{/* {userAuth?.userType === UserTypeEnum.ADMIN ||
													userAuth?.rights?.find(
														(x) =>
															x.type === 'sub2' && x.name === 'approve-partner',
													) ? (
													<Button
														type="primary"
														style={{ background: 'green', borderColor: 'green' }}
														disabled={!id}
														onClick={acceptModalOpen}
													>
														Action
													</Button>
												) : (
													<Button
														type="primary"
														style={{ background: 'green', borderColor: 'green' }}
														disabled={true}
														onClick={acceptModalOpen}
													>
														Action
													</Button>
												)} */}

											{/* {userAuth?.userType === UserTypeEnum.ADMIN ||
													userAuth?.rights?.find(
														(x) => x.type === 'sub2' && x.name === 'reject-partner',
													) ? (
													<Button
														type="primary"
														style={{ background: 'red', borderColor: 'red' }}
														disabled={!id}
														onClick={rejectModalOpen}
													>
														Reject Partner
													</Button>
												) : (
													<Button
														type="primary"
														style={{ background: 'red', borderColor: 'red' }}
														disabled={true}
														onClick={rejectModalOpen}
													>
														Reject Partner
													</Button>
												)} */}
										</div>
									)}
								</Card>
								{accept && (
									<Modal
										title={'Accept'}
										visible={accept}
										onOk={handleAcceptOk}
										onCancel={handleAcceptCancel}
										width={500}
									>
										{/* {proActivate && <Alert message="Profile updated successfully" type="success" showIcon />} */}
										<br />
										<span>Are you sure accept the partner?</span>
									</Modal>
								)}
								{reject && (
									<Modal
										title={'Reject'}
										visible={reject}
										onOk={handleRejectOk}
										onCancel={handleRejectCancel}
										width={500}
									>
										{/* {proActivate && <Alert message="Profile updated successfully" type="success" showIcon />} */}
										<br />
										<span>Are you sure reject the partner?</span>
									</Modal>
								)}
							</Col>
						)}

						<Col span={24}>
							<br />
						</Col>

						<Col xl={12} lg={14} md={14} sm={24} xs={24}>
							<Card
								title="Address"
								className={`gx-card-widget gx-card-tabs gx-card-profile`}
								extra={
									userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub2' && x.name === 'address-edit',
									) ||
									userAuth?.rights?.find(
										(x) =>
											x.type === 'sub2' && x.name === 'pending-address-edit',
									) ? (
										<UpdatePartnerDetails
											input={qryData?.getUser}
											onFinishAdd={refetchQryData}
										/>
									) : (
										<></>
									)
								}
							>
								<br />
								{qryData?.getUser?.partnerDetails?.address && (
									<Row>
										<Col span={12}>
											{/* <strong>Address : </strong>
											<br /> */}
											<strong>House/ Flat No :</strong>
											<br />  
											{/* <strong>Building Number : </strong>
											<br /> */}
												{qryData?.getUser?.partnerDetails?.address?.locality && (
												<>
												<strong>Locality : </strong>
												<br />
												</>
											)}
										
											{qryData?.getUser?.partnerDetails?.address?.landmark&&(
											<>
											<strong>Landmark : </strong>
											<br />
											</>
											)}
												{qryData?.getUser?.partnerDetails?.address?.city  && (
												<>
													<strong>City : </strong><br />
												</>
											) }
											<strong>Area : </strong>
											<br />
											<strong>Postal Code : </strong>
											<br />
											{/* <strong>Ph : </strong> */}
											{/* <br /> */}
										</Col>
										<Col span={12} style={{ textAlign: 'end' }}>
											{/* {qryData?.getUser?.partnerDetails?.address?.address}
											<br /> */}
											{qryData?.getUser?.partnerDetails?.address?.buildingName}
											<br />
											{/* {
												qryData?.getUser?.partnerDetails?.address
													?.buildingNumber
											}
											
											<br /> */}
											{qryData?.getUser?.partnerDetails?.address?.locality && (
												<>
												{qryData?.getUser?.partnerDetails?.address?.locality}<br />
												</>
											)}
											
											{qryData?.getUser?.partnerDetails?.address?.landmark&&(
											<>
												{qryData?.getUser?.partnerDetails?.address?.landmark}
												<br /></>
											)}

											{qryData?.getUser?.partnerDetails?.address?.city  && (
												<>
												{qryData?.getUser?.partnerDetails?.address?.city}<br />
												</>
											) }
											
											{/* {partnerArea ? partnerArea : qryData?.getUser?.partnerDetails?.address?.area } */}
											{partnerArea?.length ? (<>{partnerArea}</>) : (
												<>
												{qryData?.getUser?.partnerDetails?.address?.area}
												</>
											) }
											{/* {qryData?.getUser?.partnerDetails?.address?.area} */}
											<br />
											{qryData?.getUser?.partnerDetails?.address?.postalCode}
											<br />
											{/* {qryData?.getUser?.partnerDetails?.address?.ph} */}
											<br />
										</Col>
									</Row>

									// Object.keys(qryData?.getUser?.partnerDetails?.address).map(
									// 	(val) => {
									// 		if (val == '__typename' || val == 'id') {
									// 			return <></>;
									// 		}
									// 		return (
									// 			<p key={val}>
									// 				<strong>{val}: </strong>
									// 				{qryData?.getUser?.partnerDetails?.address?.[val]}
									// 			</p>
									// 		);
									// 	},
								)}
							</Card>
						</Col>

						<Col xl={12} lg={24} md={24} sm={12} xs={24}>
							<Card
								title="Availability"
								className={`gx-card-widget gx-card-tabs gx-card-profile`}
							>
								<div
									style={{
										justifyContent: 'center',
										display: 'flex',
										paddingTop: '2rem',
									}}
								>
									<Card
										style={{
											// textAlign: 'center',
											width: '200px',
											height: '90px',
											borderRadius: '0.5rem',
											backgroundColor: '#f7e3e8',
											display: 'flex',
											// justifyContent: 'center',
											alignItems: 'center',
											flexDirection: 'column-reverse',
											paddingTop:"2rem"
										}}
									>
										{qryData?.getUser?.partnerDetails?.isAvailable ? (
											<h1 style={{ color: 'green',textAlign: "center" }}>Available</h1>
										) : (
											<div style={{height: '5.3rem'}}> 
												<h1 style={{ color: 'red',textAlign: "center",paddingTop:"2rem"}}>Unavailable</h1>
												{qryData?.getUser?.partnerDetails?.unavailableTill &&
												qryData?.getUser?.partnerDetails?.unavailableTill !==
													null ? (
													<h3>
														{/* {qryData?.getUser?.partnerDetails?.unavailableTill} */}
														{moment(qryData?.getUser?.partnerDetails?.unavailableTill).format("LL")}
													</h3>
												) : (
													<h3>- -</h3>
												)}
											</div>
										)}
									</Card>
								</div>
							</Card><br/>
							{qryData?.getUser?.partnerDetails?.backgroundVerificationNote &&(

							<Card style={{marginTop:"1rem"}}
								title="Approval Note"
								className={`gx-card-widget gx-card-tabs gx-card-profile`}
							>
							{qryData?.getUser?.partnerDetails?.backgroundVerificationNote}
							</Card>
							)}<br/>
								{qryData?.getUser?.partnerDetails?.company?.length>=1 &&(

								<Card style={{marginTop:"1rem",marginBottom:"1rem"}}
									title="Company Name"
									className={`gx-card-widget gx-card-tabs gx-card-profile`}
								>
								{qryData?.getUser?.partnerDetails?.company[0]?.companyName}
								</Card>
								)}
						</Col>

						{/* <Col xl={12} lg={10} md={10} sm={24} xs={24}> */}

						{/* <Col xl={24} lg={10} md={10} sm={12} xs={24}>
								<br /> */}

						{/* <Friends friendList={friendList} /> */}
						{/* </Col> */}
						{/* <Col xl={24} lg={24} md={24} sm={12} xs={24}>
                <Photos photoList={photoList}/>
              </Col> */}

						{/* </Col> */}
					</Row>
				</div>
			)}
		</>
	);
};
export default PartnerDetail;
