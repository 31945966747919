import React from 'react';

interface Props {
	title;
	width;
	value;
	color;
}
const LineIndicator = ({ title, width, value, color }: Props) => {
	return (
		<>
			<p>{title}</p>
			<div className="gx-line-indi-info">
				<div
					className={`gx-line-indi gx-bg-${color}`}
					style={{
						width: Number.parseInt(width, 10) * 4,
					}}
				/>

				<span className="gx-line-indi-count gx-ml-2">{value}</span>
			</div>
		</>
	);
};

export default LineIndicator;
