import { gql } from '@apollo/client';

export const CMSQueryGetCmsContent = gql`
	query CMSGetCmsContent {
		getCmsContent {
			id
			aboutUs
			referPolicy
			termsConditionsCustomer
			termsConditionsPartner
			privacyPolicy
			safetyPolicy
			cancellationPolicyPartner
			cancellationPolicyCustomer
		}
	}
`;

export const CMSQueryGetBanners = gql`
	query CMSGetCmsBanners($getAll: Boolean,$resourceType:EnumBannerResourceType,$search:String,	$pagination: PaginationGqlInput!) {
		getBanners(getAll: $getAll,resourceType :$resourceType, search:$search,pagination: $pagination) {
			data{
				id
				title
				url
				description
				mediaId
				resourceData
				enabled
				resourceType
				media {
					id
					url
				}
			}
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;
