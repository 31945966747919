import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Card, Col, Form, Input, Row, Space, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, useLocation, useParams } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import {
	CMSCreateServiceCategory,
	CMSCreateServiceCategoryVariables,
} from 'src/lib/gql/generated/CMSCreateServiceCategory';
import {
	CMSGetServiceCategory,
	CMSGetServiceCategoryVariables,
} from 'src/lib/gql/generated/CMSGetServiceCategory';
import {
	CREATE_SERVICE_CATEGORY,
	UPDATE_SERVICE_CATEGORY,
	UPDATE_SERVICE_ORDER,
	UPDATE_SERVICE_CATEGORY_ORDER,
} from 'src/lib/gql/mutations/mutations';
import {
	CMSQueryGetServiceCategory,
	CMSQueryGetServices,
} from 'src/lib/gql/queries/services';
import { CaretLeftOutlined, DragOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
	CMSGetAllServiceCategories,
	CMSGetAllServiceCategories_getServiceCategories,
} from 'src/lib/gql/generated/CMSGetAllServiceCategories';
import { CMSQueryGetAllServiceCategories } from 'src/lib/gql/queries/services';
import { CMSGetServices } from 'src/lib/gql/generated/CMSGetServices';
import {
	CMSUpdateServiceCategory,
	CMSUpdateServiceCategoryVariables,
} from 'src/lib/gql/generated/CMSUpdateServiceCategory';
import { ServiceStatusTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { userAtom, userTokenAtom } from 'src/lib/recoil/userCoil';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';

function useQueryParam() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ChangeOrder: React.FC = () => {
	const mode = useQueryParam().get('mode');

	const {
		loading,
		data: qryData,
		refetch: refetchQryData,
	} = useQuery<CMSGetAllServiceCategories>(CMSQueryGetAllServiceCategories);

	const { data: qryServicesData, refetch: refetchQryServicesData } =
		useQuery(CMSQueryGetServices);

	const [items, setItems] = useState<any>([]);
	const [showInHomeServices, setShowInHomeServices] = useState<any>([]);
	const [popularServices, setPopularServices] = useState<any>([]);
	const [deleteAlert, setDeleteAlert] = useState(false);


	useEffect(() => {
		refetchQryData()
	}, [showInHomeServices, popularServices, items, refetchQryData]);

	useEffect(() => {
		if (qryData?.getServiceCategories) {
			const transformedItems = qryData?.getServiceCategories.filter(
				(item, index) => ({
					id: item.id,
					content: item.name,
					sortOrder: item.sortOrder,
					index, // Add index to maintain the sort order
					enabled: item.enabled,
				}),
			);

			setItems(transformedItems);
		}
	}, [loading, qryData]);

	useEffect(() => {
		if (qryServicesData?.getServices) {
			// const transformedItems = qryData?.getServiceCategories.map(
			// 	(item, index) => ({
			// 		id: item.id,
			// 		content: item.name,
			// 		index, // Add index to maintain the sort order
			// 	}),
			// );

			// setItems(transformedItems);

			// filter show in home services

			// const filteredShowInHomeServices = qryServicesData?.getServices?.serviceShortList?.includes('SHOW_IN_HOME')
			const filteredShowInHomeServices = qryServicesData?.getServices?.filter(
				(item) => item?.serviceShortList?.includes('SHOW_IN_HOME'),
			);
			filteredShowInHomeServices.sort((a, b) =>
				a.showInHomeSortOrder > b.showInHomeSortOrder ? 1 : -1,
			);

			const filteredPopularServices = qryServicesData?.getServices?.filter(
				(item) => item?.serviceShortList?.includes('POPULAR'),
			);
			filteredPopularServices.sort((a, b) =>
				a.popularSortOrder > b.popularSortOrder ? 1 : -1,
			);

			setShowInHomeServices(filteredShowInHomeServices);
			setPopularServices(filteredPopularServices);
			// setShowInHomeServices(filteredShowInHomeServices)
		}
	}, [qryServicesData]);

	// interface MyObject {
	// 	// Define the properties of your object
	// 	id: number;
	// 	content: string;
	// 	order: number;
	// 	index: number;
	// 	enabled: boolean;
	// 	// ...
	// }

	const handleCategoryDragEnd = (result) => {
		if (!result.destination) return;

		const newItems = Array.from(items);
		const [reorderedItem] = newItems.splice(result.source.index, 1);
		newItems.splice(result.destination.index, 0, reorderedItem);

		// Update the "order" field based on the new order
		// (newItems as MyObject[]).forEach((obj) => {
		// 	// Process each object in the array
		// 	obj.order = obj.index;
		// 	console.log('upppppp', obj.order);
		// });

		const updatedItems = newItems.map((item: any, index) => ({
			...item,
			sortOrder: index + 1,
		}));
		setItems(updatedItems);
	};

	const handleShowInHomeServicesDragEnd = (result) => {
		if (!result.destination) return; // Item dropped outside a droppable area

		const newItems = Array.from(showInHomeServices);
		const [reorderedItem] = newItems.splice(result.source.index, 1);
		newItems.splice(result.destination.index, 0, reorderedItem);
		// Update the showInHomeOrder based on the new positions
		const updatedItems = newItems.map((item: any, index) => ({
			...item,
			showInHomeSortOrder: index + 1,
		}));

		setShowInHomeServices(updatedItems);
	};

	const handlePopularServicesDragEnd = (result) => {
		if (!result.destination) return; // Item dropped outside a droppable area

		const newItems = Array.from(popularServices);
		const [reorderedItem] = newItems.splice(result.source.index, 1);
		newItems.splice(result.destination.index, 0, reorderedItem);
		// Update the showInHomeOrder based on the new positions
		const updatedItems = newItems.map((item: any, index) => ({
			...item,
			popularSortOrder: index + 1,
		}));

		setPopularServices(updatedItems);
	};

	const [form] = Form.useForm();

	const isEditMode = useQueryParam().get('mode');
	const { id } = useParams<{ id: string }>();

	const navigate = useNavigate();

	const [
		updateServiceCategorySortOrder,
		{
			data: dataServiceCategorySorderMtU,
			loading: loadingServiceCategorySorderMtU,
			error: errorServiceCategorySorderMtU,
		},
	] = useMutation(UPDATE_SERVICE_CATEGORY_ORDER, {
		onCompleted: () => {
			refetchQryServicesData();
		},
	});

	const [
		updateServiceSortOrder,
		{
			data: dataServiceSorderMtU,
			loading: loadingServiceSorderMtU,
			error: errorServiceSorderMtU,
		},
	] = useMutation(UPDATE_SERVICE_ORDER, {
		onCompleted: () => {
			refetchQryServicesData();
		},
	});

	const sortUpdate = () => {
		if (mode == 'serviceShowons') {
			updateServiceSortOrder({
				variables: {
					data: showInHomeServices.map((i, k) => ({
						id: i.id,
						showInHomeSortOrder: i.showInHomeSortOrder,
						// popularSortOrder: i.popularSortOrder
					})),
				},
			});

			updateServiceSortOrder({
				variables: {
					data: popularServices.map((i, k) => ({
						id: i.id,
						// showInHomeSortOrder: i.showInHomeSortOrder,
						popularSortOrder: i.popularSortOrder,
					})),
				},
			});
			setDeleteAlert(true);
			setTimeout(() => {
				setDeleteAlert(false);
			}, 2000);
			// updateServiceSortOrder(showInHomeServices)
		} else if (mode == 'serviceCategory') {
			updateServiceCategorySortOrder({
				variables: {
					data: items.map((i, k) => ({
						id: i.id,
						sortOrder: i.sortOrder,
					})),
				},
			});
			setDeleteAlert(true);
			setTimeout(() => {
				setDeleteAlert(false);
			}, 2000);
		}
	};

	const userAuth = useRecoilValue(userAtom);

	return (
		<Card title="Services">
			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}
			<div className="gx-flex gx-flex-nowrap gx-mb-2">
				{!loading && (
					<>
					<Row>
						<Col style={{ marginBottom: '1rem' }} span={24}>
							{deleteAlert &&
								!loadingServiceCategorySorderMtU &&
								!loadingServiceSorderMtU && (
									<Alert message="Updated" type="success" showIcon />
								)}
							{loadingServiceCategorySorderMtU && (
								<Alert message="Updating..." type="info" showIcon />
							)}
							{loadingServiceSorderMtU && (
								<Alert message="Updating..." type="info" showIcon />
							)}
							{errorServiceCategorySorderMtU && (
								<Alert
									message={`Submission error! ${errorServiceCategorySorderMtU.message}`}
									type="error"
									showIcon
								/>
							)}
						</Col>
					</Row>

					<Row>
						
						<Col span={24} className="gx-mb-4">
							<div style={{ display: 'flex' }}>
								<Button
									className="gx-mr-4"
									onClick={() => navigate(-1)}
									style={{ margin: '0' }}
								>
									<CaretLeftOutlined />
								</Button>
								{mode === 'serviceCategory' ? (
									<h1>Change Service Category Order</h1>
								) : mode === 'serviceShowons' ? (
									<h1>Change Service Show-On Order</h1>
								) : (
									<></>
								)}
							</div>
										<Button
											type="primary"
											color="blue"
											style={{ float: 'right', marginTop: '-2rem' }}
											onClick={sortUpdate}
										>
											Save
										</Button>

							<hr />
						</Col>

						{mode === 'serviceCategory' ? (
							<Col span={24} className="gx-mb-4">
								<Card
									className={`gx-card-widget`}
									style={{
										borderRadius: '1rem',
										display: 'flex',
										boxShadow:
											'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
										width: '100%',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<DragDropContext onDragEnd={handleCategoryDragEnd}>
										<Droppable droppableId="sortable-list">
											{(provided) => (
												<ul
													{...provided.droppableProps}
													ref={provided.innerRef}
												>
													{items.map((item, index) => (
														<Draggable
															key={item.id}
															draggableId={item.id}
															index={index}
														>
															{(provided) => (
																<div
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	ref={provided.innerRef}
																>
																	<Card
																		className={`gx-card-widget`}
																		style={{
																			borderRadius: '1rem',
																			boxShadow:
																				'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
																			marginBottom: '15px',
																			position: 'relative',
																			width: '25rem',
																			backgroundColor: `${
																				item.enabled ? '' : '#ffb8b8'
																			}`,
																		}}
																	>
																		<h3>{item?.name}</h3>
																		<DragOutlined
																			style={{
																				position: 'absolute',
																				marginLeft: '20rem',
																				marginTop: '-23px',
																				fontSize: '22px',
																				cursor: '-moz-grab',
																			}}
																		/>
																	</Card>
																</div>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</ul>
											)}
										</Droppable>
									</DragDropContext>
								</Card>
							</Col>
						) : mode === 'serviceShowons' ? (
							<>
								<Col span={12} className="gx-mb-4">
									<Col span={24} style={{ textAlign: 'center' }}>
										<h4>SHOW IN HOME</h4>
									</Col>
									<Card
										className={`gx-card-widget`}
										style={{
											borderRadius: '1rem',
											display: 'flex',
											boxShadow:
												'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
											width: '100%',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<DragDropContext
											onDragEnd={handleShowInHomeServicesDragEnd}
										>
											<Droppable droppableId="sortable-list">
												{(provided) => (
													<ul
														{...provided.droppableProps}
														ref={provided.innerRef}
													>
														{showInHomeServices.map((item, index) => (
															// item.serviceShortList.includes('SHOW_IN_HOME') &&
															<Draggable
																key={item.id}
																draggableId={item.id}
																index={index}
															>
																{(provided) => (
																	<div
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		ref={provided.innerRef}
																	>
																		<Card
																			className={`gx-card-widget`}
																			style={{
																				borderRadius: '1rem',
																				boxShadow:
																					'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
																				marginBottom: '15px',
																				position: 'relative',
																				width: '25rem',
																				backgroundColor: `${
																					item?.serviceStatus == 'DRAFT'
																						? '#ffb8b8'
																						: ''
																				}`,
																			}}
																		>
																			<h3>{item?.name}</h3>
																			<DragOutlined
																				style={{
																					position: 'absolute',
																					marginLeft: '20rem',
																					marginTop: '-23px',
																					fontSize: '22px',
																					cursor: '-moz-grab',
																				}}
																			/>
																		</Card>
																	</div>
																)}
															</Draggable>
														))}
														{provided.placeholder}
													</ul>
												)}
											</Droppable>
										</DragDropContext>
									</Card>
								</Col>

								<Col span={12} className="gx-mb-4">
									<Col span={24} style={{ textAlign: 'center' }}>
										<h4>POPULAR</h4>
									</Col>
									<Card
										className={`gx-card-widget`}
										style={{
											borderRadius: '1rem',
											display: 'flex',
											boxShadow:
												'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
											width: '100%',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<DragDropContext onDragEnd={handlePopularServicesDragEnd}>
											<Droppable droppableId="sortable-list">
												{(provided) => (
													<ul
														{...provided.droppableProps}
														ref={provided.innerRef}
													>
														{popularServices.map((item, index) => (
															// item?.serviceShortList?.includes('POPULAR') &&
															<Draggable
																key={item.id}
																draggableId={item.id}
																index={index}
															>
																{(provided) => (
																	<div
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		ref={provided.innerRef}
																	>
																		<Card
																			className={`gx-card-widget`}
																			style={{
																				borderRadius: '1rem',
																				boxShadow:
																					'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
																				marginBottom: '15px',
																				position: 'relative',
																				width: '25rem',
																				backgroundColor: `${
																					item?.serviceStatus == 'DRAFT'
																						? '#ffb8b8'
																						: ''
																				}`,
																			}}
																		>
																			<h3>{item?.name}</h3>
																			<DragOutlined
																				style={{
																					position: 'absolute',
																					marginLeft: '20rem',
																					marginTop: '-23px',
																					fontSize: '22px',
																					cursor: '-moz-grab',
																				}}
																			/>
																		</Card>
																	</div>
																)}
															</Draggable>
														))}
														{provided.placeholder}
													</ul>
												)}
											</Droppable>
										</DragDropContext>
									</Card>
								</Col>
							</>
						) : (
							<></>
						)}
					</Row>
					</>
				)}
			</div>
		</Card>
	);
};
export default ChangeOrder;
