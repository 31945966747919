import { gql } from '@apollo/client';

export const CREATE_SERVICE_CATEGORY = gql`
	mutation CMSCreateServiceCategory($data: ServiceCategoryCreateGqlInput!) {
		createServiceCategory(data: $data) {
			id
			name
			code
			description
		}
	}
`;

export const UPDATE_SERVICE_CATEGORY = gql`
	mutation CMSUpdateServiceCategory(
		$data: ServiceCategoryUpdateGqlInput!
		$id: String!
	) {
		updateServiceCategory(data: $data, id: $id) {
			id
		}
	}
`;

export const DELETE_SERVICE_CATEGORY = gql`
	mutation CMSDeleteServiceCategory($id: ID!) {
		deleteServiceCategory(id: $id)
	}
`;

export const CREATE_SERVICE = gql`
	mutation CMSCreateService($data: ServiceCreateGqlInput!) {
		createService(data: $data) {
			id
			code
			name
			description
		}
	}
`;

export const UPDATE_SERVICE = gql`
	mutation CMSUpdateService($data: ServiceUpdateGqlInput!, $id: ID!) {
		updateService(data: $data, id: $id) {
			id
			code
			name
			description
			serviceStatus
			
		}
	}
`;

export const DELETE_SERVICE = gql`
	mutation CMSDeleteService($id: ID!) {
		deleteService(id: $id)
	}
`;

export const CREATE_SERVICE_BILLING_OPTION = gql`
	mutation CMSCreateServiceBillingOption(
		$data: ServiceBillingOptionCreateGqlInput!
	) {
		createServiceBillingOption(data: $data) {
			id
			code
			name
			description
			recurring
			recurringPeriod
			autoAssignPartner
			unit
			minUnit
			maxUnit
			additionalMinUnit
				additionalMaxUnit
			unitPrice {
				partnerPrice
				commission
				commissionTax
				partnerTax
				commissionAmount
				commissionTotal
				partnerTotal
				grandTotal
				unitPrice
			}
			additionalUnitPrice {
				partnerPrice
				commission
				commissionTax
				partnerTax
				commissionAmount
				commissionTotal
				partnerTotal
				grandTotal
				unitPrice
				
			}
		}
	}
`;
export const UPDATE_SERVICE_BILLING_OPTION = gql`
	mutation CMSUpdateServiceBillingOption(
		$data: ServiceBillingOptionUpdateGqlInput!
		$id: ID!
	) {
		updateServiceBillingOption(data: $data, id: $id) {
			id
		}
	}
`;

export const DELETE_SERVICE_BILLING_OPTION = gql`
	mutation CMSDeleteServiceBillingOption($id: ID!) {
		deleteServiceBillingOption(id: $id)
	}
`;

export const CREATE_SERVICE_INPUT_OPTION = gql`
	mutation CMSCreateServiceInputOption($data: ServiceInputCreateGqlInput!) {
		createServiceInput(data: $data) {
			id
			name
			description
			key
			type
		}
	}
`;

export const UPDATE_SERVICE_INPUT_OPTION = gql`
	mutation CMSUpdateServiceInput($data: ServiceInputUpdateGqlInput!, $id: ID!) {
		updateServiceInput(data: $data, id: $id) {
			id
			name
			description
		}
	}
`;

export const DELETE_SERVICE_INPUT = gql`
	mutation CMSDeleteServiceInput($id: ID!) {
		deleteServiceInput(id: $id)
	}
`;

export const CREATE_SERVICE_REQUIREMENT = gql`
	mutation CMSCreateServiceRequirement(
		$data: ServiceRequirementInsertGqlInput!
	) {
		createServiceRequirement(data: $data) {
			id
			title
			description
		}
	}
`;

export const UPDATE_SERVICE_REQUIREMENT = gql`
	mutation CMSUpdateServiceRequirement(
		$data: ServiceRequirementUpdateGqlInput!
		$id: String!
	) {
		updateServiceRequirement(data: $data, id: $id) {
			id
			title
			description
		}
	}
`;

export const DELETE_SERVICE_REQUIREMENT = gql`
	mutation CMSDeleteServiceRequirement($id: String!) {
		deleteServiceRequirement(id: $id)
	}
`;

export const CREATE_SERVICE_ADDON = gql`
	mutation CMSCreateServiceAddon($data: ServiceAddonCreateGqlInput!) {
		createServiceAddon(data: $data) {
			id
			name
			description
		}
	}
`;

export const UPDATE_SERVICE_ADDON = gql`
	mutation CMSUpdateServiceAddon($id: ID!, $data: ServiceAddonUpdateGqlInput!) {
		updateServiceAddon(id: $id, data: $data) {
			id
			name
			description
		}
	}
`;

export const DELETE_SERVICE_ADDON = gql`
	mutation CMSDeleteServiceAddon($id: ID!) {
		deleteServiceAddon(id: $id)
	}
`;

export const CREATE_SERVICE_PROPERTY_AREA = gql`
	mutation CMSCreatesServicePropertyArea($data: ServicePropertyAreaInsertGqlInput!) {
		createServicePropertyArea(data: $data) {
			id
			title
			description
			hours
		}
	}
`;

export const UPDATE_SERVICE__PROPERTY_AREA  = gql`
	mutation CMSUpdateServicePropertyArea($data: ServicePropertyAreaUpdateGqlInput!) {
		updateServicePropertyArea(data: $data) {
			id
			title
			description
			hours
		}
	}
`;

export const DELETE_SERVICE__PROPERTY_AREA  = gql`
	mutation CMSDeleteServicePropertyArea($id: String!) {
		deleteServicePropertyArea(id: $id)
	}
`;

export const BOOKING_COMMITJOB = gql`
	mutation CMSCommitJob($partnerId: String!, $jobBoardId: String!) {
		commitJob(partnerId: $partnerId, jobBoardId: $jobBoardId) {
			id
		}
	}
`;

export const UPDATE_PARTNER_PENDING_TASKS = gql`
	mutation CMSUpdatePartnerPendingTasks(
		$pendingItems: [PartnerPendingTaskEnum!]!
		$partnerId: String!
	) {
		updatePartnerPendingTasks(
			pendingItems: $pendingItems
			partnerId: $partnerId
		) {
			id
		}
	}
`;

export const APPROVE_PARTNER = gql`
	mutation CMSApprovePartner($partnerId: String!) {
		approvePartner(partnerId: $partnerId) {
			id
		}
	}
`;
export const REJECT_PARTNER = gql`
	mutation CMSRejectPartner($partnerId: String!) {
		rejectPartner(partnerId: $partnerId) {
			id
		}
	}
`;

export const CREATE_COUPON = gql`
	mutation CMSCreateCoupon($data: CouponCreateGqlInput!) {
		createCoupon(data: $data) {
			id
			name
			description
		}
	}
`;
export const UPDATE_COUPON = gql`
	mutation CMSUpdateCoupon($id: ID!, $data: CouponUpdateGqlInput!) {
		updateCoupon(id: $id, data: $data) {
			id
			name
			description
		}
	}
`;
export const DELETE_COUPON = gql`
	mutation CMSDeleteCoupon($id: ID!) {
		deleteCoupon(id: $id)
	}
`;

export const UPDATE_CMS_CONTENT = gql`
	mutation CMSUpdateCmsContent($data: CmsContentUpdateGqlInput!) {
		updateCmsContent(data: $data) {
			id
			aboutUs
			referPolicy
			termsConditionsCustomer
			termsConditionsPartner
			privacyPolicy
			safetyPolicy
		}
	}
`;

export const BOOKING_RE_ASSIGN_PARTNER = gql`
	mutation CMSBookingReAssignPartner(
		$bookingServiceItemId: String!
		$partnerId: String!
		$skipBuffer: Boolean
	) {
		reassignPartner(
			bookingServiceItemId: $bookingServiceItemId
			partnerId: $partnerId
			skipBuffer: $skipBuffer
		) {
			id,partnerId
		}
	}
`;

export const UPDATE_PARTNER_DETAILS = gql`
	mutation CMSUpdatePartnerDetails(
		$data: PartnerUpdateGqlInput!
		$partnerId: ID!
	) {
		updatePartnerDetails(data: $data, partnerId: $partnerId) {
			id
		}
	}
`;

export const UPDATE_PARTNER_ACCOUNT_PAYOUT = gql`
	mutation CMSUpdatePartnerPayoutAccount(
		$data: PartnerUpdatePayoutGqlInput!
		$partnerId: ID
	) {
		updatePartnerPayoutAccount(data: $data, partnerId: $partnerId) {
			id
		}
	}
`;

export const UPDATE_PARTNER_SERVICE_AREAS = gql`
	mutation CMSUpdatePartnerServiceAreas(
		$areas: [String!]!
		$partnerId: String
	) {
		updatePartnerAreas(areas: $areas, partnerId: $partnerId) {
			id
		}
	}
`;

export const UPDATE_PARTNER_SERVICES = gql`
	mutation CMSUpdatePartnerServices($services: [String!]!, $partnerId: String) {
		updatePartnerServices(services: $services, partnerId: $partnerId) {
			id
		}
	}
`;

export const UPDATE_PARTNER_DOCUMENTS = gql`
	mutation CMSUpdatePartnerDocuments(
		$document: PartnerDocumentGqlInput!
		$partnerId: String
	) {
		updatePartnerDocument(document: $document, partnerId: $partnerId) {
			id
		}
	}
`;

export const REGISTER_PARTNER_BY_ADMIN = gql`
	mutation CMSRegisterPartnerByAdmin(
		$data: PartnerRegisterGqlInput!
		$phone: String!
	) {
		registerPartnerByAdmin(data: $data, phone: $phone) {
			id
		}
	}
`;

export const CREATE_PARTNER_COMPANY = gql`
	mutation CMSCreatePartnerCompany($name: String!, $address: String) {
		createPartnerCompany(name: $name, address: $address) {
			id
		}
	}
`;

export const DELETE_PARTNER_COMPANY = gql`
	mutation CMSDeletePartnerCompany($id: ID!) {
		deletePartnerCompany(id: $id) 
	}
`;

export const CREATE_BANNER = gql`
	mutation CMSCreateBanner($data: BannerCreateGqlInput!) {
		createBanner(data: $data) {
			id
		}
	}
`;

export const UPDATE_BANNER = gql`
	mutation CMSUpdateBanner($id: ID!, $data: BannerUpdateGqlInput!) {
		updateBanner(id: $id, data: $data) {
			id
		}
	}
`;

export const DELETE_BANNER = gql`
	mutation CMSDeleteBanner($id: ID!) {
		deleteBanner(id: $id)
	}
`;

export const ADD_FAQ = gql`
	mutation CMSAddFaq($data: FaqCreateGqlInput!) {
		createFaq(data: $data) {
			question
		}
	}
`;

export const UPDATE_FAQ = gql`
	mutation CMSUpdateFaq($id: String!, $data: FaqCreateGqlInput!) {
		updateFaq(id: $id, data: $data) {
			id
		}
	}
`;

export const DELETE_FAQ = gql`
	mutation CMSDeleteFaq($id: String!) {
		deleteFaq(id: $id) {
			question
		}
	}
`;

export const ADD_SUB_ADMIN = gql`
	mutation CMSADD_SUB_ADMINByAdmin(
		$data: SubAdminCreateGqlInput!
	) {
		createSubAdmin(data: $data) {
			id
			user {
				id
				name
				email
				phone
				userType
			}
			rights {
				id
				name
			}
		}
	}
`;

export const DELETE_SUB_ADMIN = gql`
	mutation CMSDELETE_SUB_ADMINByAdmin(
		$id: ID!
	) {
		deleteSubAdmin (id: $id) 
	}
`;

export const UPDATE_SUB_ADMIN = gql`
	mutation CMSUPDATE_SUB_ADMINByAdmin(
		$id: ID!
		$data: SubAdminUpdateGqlInput!
	) {
		updateSubAdmin(id: $id, data: $data) {
			id
			user {
				id
				name
				email
				phone
				userType
			}
			rights {
				id
				name
			}
		}
	}
`;

export const ADD_NEW_ROLE = gql`
	mutation CMSADD_ROLEByAdmin(
		$data: RolesCreateGqlInput!
	) {
		createRole(data: $data) {
			id
			name
			rights {
			id
			name
			}
		}
	}
`;

export const EDIT_NEW_ROLE = gql`
	mutation CMSEDIT_ROLEByAdmin(
		$id: ID!,
		$data: RolesEditGqlInput!
	) {
		editRole(id: $id, data: $data) {
			id
			name
		}
	}
`;

export const DELETE_ROLE = gql`
	mutation CMSDELETE_ROLEByAdmin(
		$id: ID!
	) {
		deleteRole (id: $id) 
	}
`;

export const UPDATE_DISABLE_CUSTOMER_ACCOUNT = gql`	
mutation CMSUpdateDisableCustomerAccount($status: Boolean!, $partnerId: ID!) {
	disableCustomerAccount(status: $status, partnerId: $partnerId) {
		name
	}
}
`;
export const UPDATE_DISABLE_PARTNER_ACCOUNT = gql`	
mutation CMSUpdateDisablePartnerAccount($status: Boolean!, $partnerId: ID!) {
	disablePartnerAccount(status: $status, partnerId: $partnerId) {
		name
	}
}
`;
export const DELETE_PARTNER_DOCUMENT = gql`
	mutation CMSDeletePartnersDocument($id: ID!) {
		deletePartnersDocument(id: $id)
	}
`;

export const UPDATE_APP_CONFIG = gql`	
mutation CMSUpdateAppConfig($data: AppConfigUpdateGqlInput!) {
	updateAppConfig(data: $data) {
		androidVersion 
		androidAppPath
		androidMMode 
		iosVersion
		iosAppPath 
		iosMMode 
   		appModule
	}
}
`;

export const UPDATE_PARTNER_COMPANY = gql`	
mutation CMSUpdatePartnerCompany($id: ID!,$name: String!,$address: String!) {
	updatePartnerCompany(id: $id,name : $name,address: $address) {
		id
		companyName
		companyAddress
	}
}
`;

export const ADMIN_LOGIN = gql`
	mutation CMSAdminLogin($data: AdminLoginGqlInput!) {
		adminLogin(data: $data) {
			status
			message
			data {
				token
			}
		}
	}
`;

export const UPDATE_SERVICE_ORDER = gql`
	mutation changeServiceOrder(
		$data: [ServicesSortOrderUpdateGqlInput!]!
	) {
		changeServiceOrder(data: $data) {
			id
			name
			showInHomeSortOrder
			popularSortOrder 
		}
	}
`;

export const UPDATE_SERVICE_CATEGORY_ORDER = gql`
	mutation changeServiceCategoryOrder(
		$data: [ServiceCategoryOrderUpdateGqlInput!]!
	) {
		changeServiceCategoryOrder(data: $data) {
			id
			name
			sortOrder
		}
	}
`;

export const UPDATE_HOME_CONTENT = gql`
	mutation updateHomeContent(
		$data: HomeContentUpdateGqlInput!
	) {
		updateHomeContent(data: $data) {
			
			status
			image
			description
		}
	}
`;

export const REGISTER_FCM_TOKEN = gql`
	mutation registerFcmToken(
		$data: UserFcmTokenGqlInput!
	) {
		registerFcmToken(data: $data) 
	}
`;
export const UNREGISTER_FCM_TOKEN = gql`
	mutation unregisterFcmToken(
		$token:  String
	) {
		unregisterFcmToken(token: $token) 
	}
`;
export const  RESCHEDULE_JOB = gql`
	mutation CMSRescheduleJob($bookingServiceItemId: ID!, $scheduleTime: DateTime!,
		 $scheduleEndDateTime: DateTime!, $modificationReason: String) {
		rescheduleJob(bookingServiceItemId: $bookingServiceItemId, scheduleTime: $scheduleTime, scheduleEndDateTime: $scheduleEndDateTime, 
			modificationReason: $modificationReason) {
				id
			}
	}
`;

export const CREATE_PERSONNEL = gql`
	mutation CMSCreatePersonnel($data: PersonnelCreateGqlInput!) {
		createPersonnel(data: $data) {
			id
		}
	}
`;

export const UPDATE_PERSONNEL = gql`
	mutation CMSUpdatePersonnel(
		$data: PersonnelUpdateGqlInput!
	) {
		updatePersonnel(data: $data) {
			id
		}
	}
`;

export const DELETE_PERSONNEL = gql`
	mutation CMSDeletePersonnel(
		$id: ID!
	) {
		deletePersonnel (id: $id) 
	}
`;

export const CREATE_TEAM = gql`
	mutation CMSCreateTeam($data: TeamCreateGqlInput!) {
		createTeam(data: $data) {
			id
		}
	}
`;

export const UPDATE_TEAM = gql`
	mutation CMSUpdateTeam(
		$data: TeamUpdateGqlInput!
	) {
		updateTeam(data: $data) {
			id
		}
	}
`;

export const DELETE_TEAM= gql`
	mutation CMSDeleteTeam(
		$id: ID!
	) {
		deleteTeam(id: $id) 
	}
`;


export const UPDATE_CHKSETTINGS = gql`
	mutation CMSUpdateChkSettings(
		$data: ChkSettingsUpdateGqlInput!
	) {
		updateChkSettings(data: $data) {
			bufferTimeValue
			bufferTimeType
			cutOffTimeValue
			cutOffTimeType
		}
	}
`;

export const UPDATE_PAYOUT_SETTINGS = gql`
	mutation CMSUpdatePayoutSettings(
		$data: PayoutSettingsUpdateGqlInput!
	) {
		updatePayoutSettings(data: $data) {
			payoutSchedule
		}
	}
`;

export const GENERATE_PARTNER_PAYOUTS = gql`
	mutation CMSGeneratePartnerPayouts($partnerIds: [String!]!) {
		multiplePartnerPayouts(partnerIds: $partnerIds) {
			id
		}
	}
`;
export const CREATE_BULK_NOTIFICATION = gql`
	mutation CMSSendBulkPushNotification($data: SendBulkPushNotificationGqlInput!) {
		sendBulkPushNotification(data: $data) 
	}
`;
export const DELETE_BULK_NOTIFICATION= gql`
	mutation CMSPushNotification(
		$id: ID!
	) {
		deletePushNotification(id: $id) 
	}
`;

export const UPDATE_BULK_NOTIFICATION = gql`
	mutation CMSUpdatePushNotification(
		$data: SendBulkPushNotificationGqlInput!
	) {
		updatePushNotification(id: $id,data: $data) {
			id
		}
	}
`;

