import { useMutation, useQuery } from '@apollo/client';
import {
	Alert,
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Input,
	Row,
	Select,
	Space,
	Spin,
	Modal,
	Switch,
	DatePicker,
	InputNumber,
	Radio,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UpdatePartnerCompanies from 'src/components/users/updatePartnerCompanies';
import { RouteList } from 'src/config/constants/routes';
import { CMSGetPartnerCompanies } from 'src/lib/gql/generated/CMSGetPartnerCompanies';
import { CMSGetServiceAreas } from 'src/lib/gql/generated/CMSGetServiceAreas';
import {
	CMSRegisterPartnerByAdmin,
	CMSRegisterPartnerByAdminVariables,
} from 'src/lib/gql/generated/CMSRegisterPartnerByAdmin';
import {
	ADD_SUB_ADMIN,
	REGISTER_PARTNER_BY_ADMIN,
} from 'src/lib/gql/mutations/mutations';
import {
	CMSQueryGetRights,
	CMSQueryGetRoles,
} from 'src/lib/gql/queries/rights';
import { CMSQueryGetServiceAreas } from 'src/lib/gql/queries/services';
import { CMSQueryGetPartnerCompanies } from 'src/lib/gql/queries/users';
import {  CaretLeftOutlined } from '@ant-design/icons';
import './admins.css';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 24,	
			offset: 8,
		},
	},
};

function countCommonElements(arr1, arr2) {
	// Initialize a counter variable
	let count = 0;
  
	// Loop through each element in arr1
	for (let i = 0; i < arr1.length; i++) {
	  // Check if the element exists in arr2
	  if (arr2.includes(arr1[i])) {
		// Increment the counter if it does
		count++;
	  }
	}
  
	// Return the count of common elements
	return count;
}

const AddAdmin: React.FC = (props: any) => {
	const [form] = Form.useForm();
	const [form2] = Form.useForm();

	const navigate = useNavigate();
	const [checked, setChecked] = useState(false);

	// Get All Roles
	const { loading, data: rolesData } = useQuery(CMSQueryGetRoles, {
		variables: {
			pagination: {
				pageNumber: 1,
				pageSize: 100
			},
			filters: {
				name: "",
			},
		},
		fetchPolicy: 'network-only',
	});

	const { data: rightsData } = useQuery(CMSQueryGetRights);

	const [mainPagesRights, setMainPagesRights] = useState<any>([]);
	const [subPagesRights, setSubPagesRights] = useState<any>([]);
	const [rightsOfPageOrSubpage, setRightsOfPageOrSubpage] = useState<any>([]);
	const [
		subPagesRightsOfSelectedMainPage,
		setSubPagesRightsOfSelectedMainPage,
	] = useState<any>([]);
	const [selectedMainPageRight, setSelectedMainPageRight] = useState<any>();

	useEffect(() => {
		//   console.log('rightsData---', rightsData.getRights)

		const mainPages = rightsData?.getRights.filter((x) => x.type === 'main');
		setMainPagesRights(mainPages);

		const subPages = rightsData?.getRights.filter((x) => x.type === 'sub');
		setSubPagesRights(subPages);

		const rightsUnderPageOrSubpage = rightsData?.getRights.filter(
			(x) => x.type === 'sub2',
		);
		setRightsOfPageOrSubpage(rightsUnderPageOrSubpage);
	}, [rightsData]);

	

	// Get all Areas
	// const {
	// 	loading,
	// 	data: qryData,
	// 	refetch: refetchQryData,
	// } = useQuery<CMSGetServiceAreas>(CMSQueryGetServiceAreas, {
	// 	errorPolicy: 'all',
	// });

	// const PartnerCompanies = useQuery<CMSGetPartnerCompanies>(
	// 	CMSQueryGetPartnerCompanies,
	// );

	const AdminRoles = {
		data: {
			getAdminRoles: [
				{
					id: 1,
					roleName: 'Test role 1',
				},
			],
		},
	};

	// // Add Partner
	// const [
	// 	addServiceCategory,
	// 	{ data: dataMt, loading: loadingMt, error: errorMt },
	// ] = useMutation<
	// 	CMSRegisterPartnerByAdmin,
	// 	CMSRegisterPartnerByAdminVariables
	// >(REGISTER_PARTNER_BY_ADMIN);

	// Add Partner
	// const [
	// 	addServiceCategory,
	// 	{ data: dataMt, loading: loadingMt, error: errorMt },
	// ] = useMutation<
	// 	CMSRegisterPartnerByAdmin,
	// 	CMSRegisterPartnerByAdminVariables
	// >(REGISTER_PARTNER_BY_ADMIN);

	const [addNewSubAdmin, { data: dataMt, loading: loadingMt, error: errorMt }] =
		useMutation(ADD_SUB_ADMIN);

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select style={{ width: 150 }} getPopupContainer={(trigger) => trigger.parentElement}>
				<Select.Option value="+91">+91</Select.Option>
				{/* <Select.Option value="971">+971</Select.Option> */}
			</Select>
		</Form.Item>
	);

	const [adminValues, setAdminValues] = useState({
		name: '',
		email: '',
		phone: '',
		password: '',
		role: '',
	});

	const [rightsOfRole, setRightsOfRole] = useState<any[]>([]);
	const [rights, setRights] = useState<string[]>([]);

	
	const onDetailsSubmit = (values) => {
		setAdminValues({
			...adminValues,
			name: values.name,
			email: values.email,
			phone: values.phone,
			password: values.password,
		});
		showModal();
		return;
	};

	const onCreateAdminFinish = (value) => {

		addNewSubAdmin({
			variables: {
				data: {
					name: adminValues.name,
					email: adminValues.email,
					phone: adminValues.phone,
					password: adminValues.password,
					role: adminValues.role,
					rights: rights,
				},
			},
		}).then((res) => {
			// navigate('/partners/' + res?.data?.registerPartnerByAdmin?.id);
			navigate('/users/admins');

			form.resetFields();
		});
	};

	const validatePhoneNumber = (_, value) => {
		const phoneNumberRegex = /^\d{10}$/; // Regular expression for 10-digit phone number
		if (!phoneNumberRegex.test(value)) {
			return Promise.reject('Please enter a valid 10-digit phone number');
		}
		return Promise.resolve();
	};

	const passwordValidator = (_, value) => {
		if (value && value.length < 6) {
			return Promise.reject('Password must be at least 6 characters long');
		}
		return Promise.resolve();
	};

	const confirmPasswordValidator = (_, value) => {
		// return Promise.resolve(value === form.getFieldValue('password') ? undefined : 'Passwords do not match');
		if (value && value !== form.getFieldValue('password')) {
			return Promise.reject('Passwords do not match');
		}
		return Promise.resolve();
	};

	const [isModalVisible, setIsModalVisible] = useState(false);
	const showModal = () => {
		setIsModalVisible(true);
	};

	const onFinish = (values) => {
		console.log('oaisjfd');
	};

	

	const handleRightsSelection = (value, checked, parentId: any) => {

		let count = 0

		const subPageData = subPagesRights.find((x) => x.id == parentId)
		const mainParentData = mainPagesRights.find((x) => x.id == subPageData?.parentId)
		if (parentId) {
			const subPagesWithThisParentId = subPagesRights.filter((x) => x.parentId == parentId)
			const innerRightsWithThisParentId = rightsOfPageOrSubpage.filter((x) => x.parentId == parentId)

			if (subPagesWithThisParentId.length) {
				const subPagesRightsIdsWithThisParentId = subPagesWithThisParentId.map((i) => i.id)
				count = countCommonElements(rights, subPagesRightsIdsWithThisParentId)
				
			} else if (innerRightsWithThisParentId.length) {
				const innerRightsIdsWithThisParentId = innerRightsWithThisParentId.map((i) => i.id)
				count = countCommonElements(rights, innerRightsIdsWithThisParentId)
			} 

		}
		const isSubPageRight = subPagesRights.find((x) => x.id == value)

		if (checked) {
			if (parentId && count == 0) {
				const isMainPageAdded = rights.filter((x) => x == mainParentData?.id)
				if (isMainPageAdded.length) {
					setRights([...rights, parentId, value]);
				} else {
					setRights([...rights, parentId, value, mainParentData?.id]);
				}
			} else {
				setRights([...rights, value]);
			}
		} else {
			let updatedArray;
			if (parentId && count == 1) {
				updatedArray = rights.filter((item) => item !== value);
				updatedArray = updatedArray.filter((item) => item !== parentId);
				updatedArray = updatedArray.filter((item) => item !== mainParentData?.id);		
			} else {
				updatedArray = rights.filter((item) => item !== value);
			}
			setRights(updatedArray);
		}
	};

	// useEffect(() => {
	// 	console.log('rights--->', rights);
	// 	console.log('rightsOfRole--->', rightsOfRole);
	// }, [rights, rightsOfRole]);

	const handleRightsOfRole = (selectedRoleId) => {
		// console.log('hhhh', selectedRoleId);
		const selectedRole = rolesData?.getRoles?.data?.find(
			(x: any) => x.id === selectedRoleId,
		);
		setAdminValues({ ...adminValues, role: selectedRoleId });

		const rightsIdsOfSelectedRole = selectedRole?.rights?.map((x) => x.id);
		setRights(rightsIdsOfSelectedRole);
		setRightsOfRole(selectedRole?.rights);
	};

	useEffect(() => {
		console.log('selectedMainPageRight***', selectedMainPageRight);
	}, [selectedMainPageRight]);

	const handleMainPageRadio = (e) => {
		console.log('mainPageRightId', e?.target?.value);
		console.log('aosifj', subPagesRights);



		const subPagesOfSelectedMainPage = subPagesRights.filter(
			(x) => x.parentId == e?.target?.value,
		);
		const selectedMainPage = mainPagesRights.find(
			(x) => x.id == e?.target?.value,
		);
		setSelectedMainPageRight(selectedMainPage);

		setSubPagesRightsOfSelectedMainPage(subPagesOfSelectedMainPage);
		console.log('subPagesOfSelectedMainPage===', subPagesOfSelectedMainPage);
	};

	useEffect(() => {
		if (Array.isArray(mainPagesRights) && mainPagesRights.length && !selectedMainPageRight) {
			const subPagesOfSelectedMainPage = subPagesRights.filter(
				(x) => x.parentId == mainPagesRights[0]?.id,
			);
			const selectedMainPage = mainPagesRights.find(
				(x) => x.id == mainPagesRights[0]?.id,
			);
			setSelectedMainPageRight(selectedMainPage);

			setSubPagesRightsOfSelectedMainPage(subPagesOfSelectedMainPage);
		}
	}, [mainPagesRights]);

	return (
		// <Card title="Add New Admin">
		<Card
				title={
					<div>
					  <Button className="gx-mr-4" onClick={() => navigate(-1)} style={{margin:"0"}}>
						<CaretLeftOutlined/>
					  </Button>
					 Add New Admin
					  
					</div>
				  }
				
			>
			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}
			<div className="gx-flex gx-flex-nowrap gx-mb-2">
				{/* <div className="gx-card-profile">
					<div className="ant-card-head " style={{ textAlign: 'center' }}>
						<Link to={RouteList.admins.path} className="gx-mr-4">
							[ Back ]
						</Link>
					</div>

					<div className="gx-pt-md-3"></div>
				</div> */}

				{!loading && (
					<Row>
						<Col span={24} className="gx-mb-4">
							{/* <h1>Add new Admin</h1> */}
							{/* <hr /> */}

							{dataMt && <Alert message="Created" type="success" showIcon />}
							{loadingMt && (
								<Alert message="Submitting..." type="info" showIcon />
							)}
							{errorMt && (
								<Alert
									message={`Submission error! ${errorMt.message}`}
									type="error"
									showIcon
								/>
							)}
						</Col>
						<Col span={24} className="gx-mb-4">
							{!loading && (
								<Form
									{...formItemLayout}
									form={form}
									name="addAdmin1"
									onFinish={onDetailsSubmit}
									initialValues={{
										partOfCompany: false,
										prefix: '+91',
									}}
									scrollToFirstError
								>
									{/* <h3>Fill Info</h3> */}

									<Form.Item
										name="name"
										label={<span>Name</span>}
										rules={[
											{
												required: true,
												message: 'Please input name',
												whitespace: true,
											},
										]}
									>
										<Input />
									</Form.Item>

									<Form.Item
										name={`email`}
										label={<span>Email</span>}
										rules={[
											{
												required: true,
												type: 'email',
												message: 'Please enter a Email!',

											},
										]}
									>
										<Input />
									</Form.Item>

									<Form.Item
										name="phone"
										label="Phone Number"
										rules={[
											{
												required: true,
												message: 'Please input your phone number!',
												// type: 'number'
											},
											{ validator: validatePhoneNumber },
										]}
									>
										<Input
											addonBefore={prefixSelector}
											style={{ width: '100%' }}
										/>
									</Form.Item>

									<Form.Item
										name="password"
										label={<span>Password</span>}
										rules={[
											{
												required: true,
												message: 'Please input password',
												whitespace: true,
											},
											{
												validator: passwordValidator,
											},
										]}
									>
										<Input.Password />
									</Form.Item>

									<Form.Item
										name="confirmPassword"
										label={<span>Confirm Password</span>}
										dependencies={['password']}
										rules={[
											{
												required: true,
												message: 'Please enter correct password',
												whitespace: true,
											},
											{ validator: confirmPasswordValidator },
										]}
									>
										<Input.Password />
									</Form.Item>

									<Form.Item
										name="role"
										label={<span>Roles</span>}
										rules={[
											{
												required: true,
												message: 'Please enter a Role!',

												// message: 'Please input name',
												// whitespace: true,
											},
										]}
									>
										<Select getPopupContainer={(trigger) => trigger.parentElement}
											showSearch
											placeholder="Select Role"
											allowClear
											optionFilterProp="children"
											filterOption={(input, option: any) => {
												return (
													option?.children?.toLowerCase().includes(input) ??
													true
												);
											}}
											style={{ marginTop: '6px', marginBottom: '12px' }}
											onChange={handleRightsOfRole}
										>
											{rolesData?.getRoles?.data?.map((role, index) => (
												<Select.Option key={role.id} value={role.id}>
													{role.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>

									<Form.Item
										{...tailFormItemLayout}
										style={{ textAlign: 'center' }}
									>
										<Button type="primary" htmlType="submit">
											Add Admin
										</Button>
									</Form.Item>
								</Form>
							)}
						</Col>
					</Row>
				)}
			</div>

			<Modal
				// title={!input ? 'Add Coupon' : 'Edit'}
				title={'Rights'}
				visible={isModalVisible}
				onOk={onCreateAdminFinish}
				onCancel={() => setIsModalVisible(false)}
				width={800}
			>
				{/* Creating */}
				{dataMt && <Alert message="Created" type="success" showIcon />}
				{loadingMt && <Alert message="Submitting..." type="info" showIcon />}
				{errorMt && (
					<Alert
						message={`Submission error! ${errorMt.message}`}
						type="error"
						showIcon
					/>
				)}

				{/* Updating */}
				{/* {dataMtU && <Alert message="Updated" type="success" showIcon />}
				{loadingMtU && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtU && ( */}
				{/* <Alert
						message={`Submission error! `}
						type="error"
						showIcon
					/> */}
				{/* // )} */}

				<Form
					form={form2}
					name="adminRights"
					onFinish={onFinish}
					// scrollToFirstError
					// initialValues={
					// 	!input
					// 		? {}
					// 		: {
					// 				...input,
					// 				startDate: moment(input.startDate),
					// 				endDate: moment(input.endDate),
					// 		  }
					// }
				>
					<Radio.Group
						className="gx-radio-group-link gx-radio-group-link-news"
						// defaultValue={news}
						onChange={handleMainPageRadio}
						defaultValue={
							Array.isArray(mainPagesRights) &&
							mainPagesRights.length &&
							mainPagesRights[0]?.id
						}
					>
						{mainPagesRights?.map((x, i) => (
							<Radio.Button key={i} value={x.id} className="gx-mb-1">
								{x.name.charAt(0).toUpperCase() + x.name.slice(1)}
							</Radio.Button>
						))}
					</Radio.Group>

					<Row justify="center">
						<Col span={14}>
							<Form.Item
								// validateStatus={editRightsValidation ? 'error' : ''}
								// help={
								// 	editRightsValidation
								// 		? 'Please select at least one right'
								// 		: ''
								// }
								style={{ marginBottom: 0 }}
							>
								<br/>
								{selectedMainPageRight && !subPagesRightsOfSelectedMainPage.length && (
									<Row key={selectedMainPageRight?.name} justify="center" align="middle">
										<Col span={18}>
											{/* <Form.Item
												name={`right1`}
												valuePropName="checked"
												label={
													<h4
														style={{ display: 'inline', marginRight: '1rem' }}
													>
														{selectedMainPageRight?.name} -
													</h4>
												}
												style={{ marginBottom: 0 }}
											> */}
												{/* <h6>{right.name} -</h6> */}
												<Form.Item
													labelAlign="right"
													name={`right${selectedMainPageRight.id}`}
													valuePropName="checked"
													label={<h4
														style={{ display: 'inline', marginRight: '0.5rem', marginTop: '6px' }}
													>
														{selectedMainPageRight.name}
													</h4>}
													style={{ marginBottom: 0 }}
												>
													{/* <span style={{ marginLeft: '1rem' }}>
														{`${selectedMainPageRight.name}:      `}
													</span> */}
													{''}
													<Switch
														onChange={(checked) =>
															handleRightsSelection(selectedMainPageRight?.id, checked, null)
														}
														checked={rights.includes(selectedMainPageRight?.id)}
														style={{
															transform: 'scale(0.8)',
														}}
													/>
												</Form.Item>
											{/* </Form.Item> */}
										</Col>
									</Row>
								)}

								{subPagesRightsOfSelectedMainPage.length != 0 && subPagesRightsOfSelectedMainPage.map((right, index) => (
									<Row key={right.name} justify="center" align="middle">
										<Col span={20}>
											<Form.Item
												name={`right${index}`}
												valuePropName="checked"
												label={
													<h4
														style={{ display: 'inline', marginRight: '0.5rem', marginTop: '6px' }}
													>
														{right.name}
													</h4>
												}
												style={{ marginBottom: 0 }}
											>
												{/* <h6>{right.name} -</h6> */}

												{rightsOfPageOrSubpage?.map((x,i) => (
													
													x.parentId == right.id && (
													<Form.Item
													labelAlign="right"
													name={`right${index}`}
													valuePropName="checked"
													// label={right.name}
													style={{ marginBottom: 0 }}
													key={i}
												>
													<Switch
														onChange={(checked) =>
															handleRightsSelection(x.id, checked, right.id)
														}
														checked={rights.includes(x.id)}
														style={{
															transform: 'scale(0.8)',
															marginLeft: '1rem',
														}}
													/>
													<span style={{ marginLeft: '4px' }}>
														{`${x.name}`}
													</span>
												</Form.Item>
													)
												))
												}

												{!rightsOfPageOrSubpage.find((k) => k.parentId == right.id) && (
													<Switch
													onChange={(checked) =>
														handleRightsSelection(right.id, checked, selectedMainPageRight.id)
													}
													checked={rights.includes(right.id)}
													style={{
														transform: 'scale(0.8)',
													}}
												/>
												)}
												
											</Form.Item>
										</Col>
									</Row>
								))}
							</Form.Item>
						</Col>
					</Row>

					<Row>
						{/* <Col span={12}>
							<Form.Item
								name={`maxUsePerCustomer`}
								label={<span>Max redemption per customer</span>}
								rules={[
									{
										type: 'number',
										required: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col> */}

						{/* <Radio.Group
						className="gx-radio-group-link gx-radio-group-link-news"
						// defaultValue={news}
						// onChange={handleChange}
					>

						{mainPagesRights?.map((x,i) => (
							<Radio.Button key={i} value={i} className="gx-mb-1">
								{x.name}
							</Radio.Button>
						))}
						
					</Radio.Group> */}

						{/* {rightsData?.getRights?.map((right, index) => (

							
							<React.Fragment key={right.name}>
								<Col span={3}></Col>
								<Col span={21} style={{ textAlign: 'center' }}>
								<Form.Item
									name={`right${index}`}
									valuePropName="checked"
									label={right.name}
								>
									{''}
									<Switch
									onChange={(checked) => handleRightsSelection(right.id, checked)}
									checked={rights.includes(right.id)}
									/>
								</Form.Item>
								</Col>
							</React.Fragment>
						))} */}

						{/* {rightsOfRole?.map((right, index) => (
							<React.Fragment key={right.name}>
							<Col span={3}></Col>
							<Col span={21}>
							  <Form.Item
								name={`right${index}`}
								valuePropName="checked"
								label={right.name}
							  >
								<Switch
								  onChange={(checked) => handleRightsSelection(right.id, checked)}
								  checked={rights.includes(right.id)}
								/>
							  </Form.Item>
							</Col>
						  </React.Fragment>
						))} */}
					</Row>
				</Form>
			</Modal>
		</Card>
	);
};
export default AddAdmin;
