import React ,{useState,useEffect }from 'react';
import { Col, Row,Tabs,Card,Select,Space,Modal,DatePicker,Radio} from 'antd';
import { Link,useLocation   } from 'react-router-dom';
import { useNavigate, Routes, Route } from 'react-router-dom';
//Components Import
import SiteVisit from 'src/components/dashboard/SiteVisit';
import WelComeCard from 'src/components/dashboard/welcomeCard';
import Overview from 'src/components/dashboard/Overview';
import TotalRevenueCard from 'src/components/dashboard/TotalRevenueCard';
// import NewCustomers from 'src/components/dashboard/NewCustomers';
import GrowthCard from 'src/components/dashboard/GrowthCard';
import { trafficData } from 'src/pages/reports/data';
import { CMSQueryGetDashboardData } from 'src/lib/gql/queries/dashboard';
import { useQuery } from '@apollo/client';
import Widget from 'src/components/widget';
import { DatePeriodEnum2,DatePeriodEnums ,FilterByEnum} from 'src/lib/gql/generated/globalTypes';
import { CMSQueryGetCities } from 'src/lib/gql/queries';
import moment from 'moment';
import { CMSQueryGetServices } from 'src/lib/gql/queries/services';
import { CMSQueryGetDashboardCounts } from 'src/lib/gql/queries/dashboard';
import { CMSQueryGetDashboardFilterCounts ,CMSQueryGetTopBookings,CMSQueryGetTopRatings} from 'src/lib/gql/queries/dashboard';
import SiteAudienceDash from 'src/components/dashboard/SiteAudienceDas';
import DirectorsDashboard from './directorsDashboard';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userAtom, userTokenAtom } from 'src/lib/recoil/userCoil';
import {
	UserTypeEnum,
} from 'src/lib/gql/generated/globalTypes';
import noOfBooking from "./images/no.of bookings.png"
import totalBooking from "./images/total bookings.png"
import newCustomer from "./images/new customers.png"
import newPartner from "./images/new partners.png"
import unassBooking from "./images/unassigned bokkings.png"
import pendingPartner from "./images/pending partners.png"
import pendingRework from "./images/pending reworks.png"
import workInProg from "./images/work in progress.png"

const TabPane = Tabs.TabPane;
interface Props {}

const Dashboard: React.FC = (props: Props) => {
	const Option = Select.Option;
	const { RangePicker } = DatePicker;
	const location = useLocation();
	const [startDate, setStartDate] = useState<any>("")
	const [startDateTop, setStartDateTop] = useState<any>("")
	const [startDateRating, setStartDateRating] = useState<any>("")
	const [endDate, setEndDate] = useState<any>("")
	const [endDateTop, setEndDateTop] = useState<any>("")
	const [endDateRating, setEndDateRating] = useState<any>("")
	const [dateValue,SetDateValue] =useState<any>()
	const [isOpenDate,SetIsOpenDate] =useState(false)
	const [isOpenDateTop,SetIsOpenDateTop] =useState(false)
	const [isOpenDateRating,SetIsOpenDateRating] =useState(false)
	const[saveDate,setSaveDate]=useState<any>("")
	const [citiesValue, SetCitiesValue] = useState<any>()
	const [topBtnValue, SetTopBtnValue] = useState<any>("TRADE")
	const [ratingBtnValue, SetRatingBtnValue] = useState<any>("PARTNER")
	const [serviceValue, SetServiceValue] = useState<any>()
	const [serviceValueRating, SetServiceValueRating] = useState<any>()
	const [citiesValueTop, SetCitiesValueTop] = useState<any>()
	const [citiesValueRating, SetCitiesValueRating] = useState<any>()
	const [dateValueTop,SetDateValueTop] =useState<any>()
	const [dateValueRating,SetDateValueRating] =useState<any>()
	const [size, setSize] = useState('small');
	const userAuth = useRecoilValue(userAtom);
	const { loading, data: qryData } = useQuery(
		CMSQueryGetDashboardData,
	);
	const { data: qryDataDashCounts ,refetch: refetchDashCounts,} = useQuery(
		CMSQueryGetDashboardCounts,
	);
	// Get GetDashboardFilterCounts
	const {
		data: qryDataDashboardFilterCounts,refetch: refetchDashboardFilterCounts,
	} = useQuery(CMSQueryGetDashboardFilterCounts, {
		variables: {
			filters: {
			city: citiesValue,
			datePeriod:dateValue,
			fromDate:startDate,
			toDate:endDate
			}
		},
		fetchPolicy: 'network-only',
	});
		// Get getTopBookings
		const {
			data: qryDataTopBookings,
		} = useQuery(CMSQueryGetTopBookings, {
			variables: {
				filters: {
				filterBy:topBtnValue,
				city: citiesValueTop,
				datePeriod:dateValueTop,
				fromDate:startDateTop,
				toDate:endDateTop,
				serviceId:serviceValue
				}
			},
			fetchPolicy: 'network-only',
		});
			// Get getTopBookings
		const {
			data: qryDataTopRatings,
		} = useQuery(CMSQueryGetTopRatings, {
			variables: {
				filters: {
				filterBy:ratingBtnValue,
				city: citiesValueRating,
				datePeriod:dateValueRating,
				fromDate:startDateRating,
				toDate:endDateRating,
				serviceId:serviceValueRating
				}
			},
			fetchPolicy: 'network-only',
		});
		// Get all cities
		const {
			data: qryDataCities,
			refetch: refetchQryDataCities,
		} = useQuery(CMSQueryGetCities);
			// Get all services
	const {
		data: qryDataService,
		refetch: refetchQryDataService,
	} = useQuery(CMSQueryGetServices, {
		variables: {
			showAll: true,
			search: '',
		},
		errorPolicy: 'all',
	});
	// console.log("qryData++",qryData);
	const handleChanges = (value) => {
        SetDateValue(value)
		setStartDate(null)
        setEndDate(null)
        if(value==="CUSTOM"){ 
            SetIsOpenDate(true)
        }
      };
	  const handleChangesTop= (value) => {
        SetDateValueTop(value)
		setStartDateTop(null)
        setEndDateTop(null)
        if(value==="CUSTOM"){ 
            SetIsOpenDateTop(true)
        }
      };
	  const handleChangesRating= (value) => {
        SetDateValueRating(value)
		setStartDateRating(null)
        setEndDateRating(null)
        if(value==="CUSTOM"){ 
            SetIsOpenDateRating(true)
        }
      };
	  const handleCities = (value) => {
		// console.log("123",value);
		SetCitiesValue(value)
	}
	const handleCitiesTop = (value) => {
		// console.log("123",value);
		SetCitiesValueTop(value)
	}
	const handleCitiesRating = (value) => {
		// console.log("123",value);
		SetCitiesValueRating(value)
	}
	const handleOk = () => {
        SetIsOpenDate(false)
    }
    const handleCancel = () => {
        SetIsOpenDate(false)  
    }
	const handleOkTop = () => {
        SetIsOpenDateTop(false)
    }
    const handleCancelTop = () => {
        SetIsOpenDateTop(false)  
    }
	const handleOkRating = () => {
        SetIsOpenDateRating(false)
    }
    const handleCancelRating = () => {
        SetIsOpenDateRating(false)  
    }
	const handleDate = (dates) => {
        setSaveDate(dates)  
		if (dates && dates?.length === 2) {
			const startDates = moment(dates[0]?._d).toISOString();
			const endDates = moment(dates[1]?._d).toISOString();
			// setStartDate(moment(startDates).format('MMMM Do YYYY'));
			// setEndDate(moment(endDates).format('MMMM Do YYYY'));
			setStartDate(startDates)
			setEndDate(endDates)
		}  
    }
	const handleDateTop = (dates) => {
        setStartDateTop(dates)  
		if (dates && dates?.length === 2) {
			const startDates = moment(dates[0]?._d).toISOString();
			const endDates = moment(dates[1]?._d).toISOString();
			// setStartDate(moment(startDates).format('MMMM Do YYYY'));
			// setEndDate(moment(endDates).format('MMMM Do YYYY'));
			setStartDateTop(startDates)
			setEndDateTop(endDates)
		}  
    }
	const handleDateRating = (dates) => {
        setStartDateRating(dates)  
		if (dates && dates?.length === 2) {
			const startDates = moment(dates[0]?._d).toISOString();
			const endDates = moment(dates[1]?._d).toISOString();
			// setStartDate(moment(startDates).format('MMMM Do YYYY'));
			// setEndDate(moment(endDates).format('MMMM Do YYYY'));
			setStartDateRating(startDates)
			setEndDateRating(endDates)
		}  
    }
	const handleTop=(e)=>{
		SetTopBtnValue(e.target.value)
		
	}
	const handleRating=(e)=>{
		SetRatingBtnValue(e.target.value)	
	}
	const handleService = (value) => {
		// console.log("123",value);
		SetServiceValue(value)
	}
	const handleServiceRating = (value) => {
		// console.log("123",value);
		SetServiceValueRating(value)
	}


  const [activeKey, setActiveKey] = useState<any>(localStorage.getItem('dashboradKey'));
  const handleTabChange = (key) => {
	// console.log("keyyyyyyyyyy",key);
    setActiveKey(key);
		localStorage.setItem('dashboradKey',key);
		refetchDashCounts()
		refetchDashboardFilterCounts()
  };


const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	return (
		<Row>
			<Col span={24}>
				<div className="">
					<div className="gx-card-body" >
					{/* <h1 className="gx-mb-3">Welcome Admin!</h1> */}
						{/* <Row>
							<Col span={24}> */}
							
						<Widget
						title=""
						styleName="gx-card-tabs" 
					>
						<Tabs defaultActiveKey={activeKey} style={{paddingTop:"0.5rem"}} onChange={handleTabChange}>
						{userAuth?.userType === UserTypeEnum.ADMIN ||
					userAuth?.rights?.find(
						(x) => x.type === 'sub2' && x.name === 'general-dashboard',
					) ? (
						<TabPane tab="General Dashboard" key="1"> 
						<div style={{textAlign:"end",paddingTop:"0.5rem"}}>
							
							<span>City</span>{" "}
				
				<Select  
					style={{
					width: '7%', textAlign:"center"
					}} 
					size="small"
					placeholder="Select One City"
					onChange={handleCities}
					optionLabelProp="label"
					value={citiesValue}
					defaultValue={"All"} getPopupContainer={(trigger) => trigger.parentElement}
				>
					<Option value={null}label="All Time">
					<Space>
						All
					</Space>
					</Option>
					{ qryDataCities && qryDataCities?.getCities.map((entry, index) => (
						<Option value={entry?.id} label={entry?.name} key={index}>
							<Space>
								<span role="img" aria-label={entry?.name}>
									{entry?.name}
								</span>

							</Space>
						</Option>
					))}
							
				</Select>	
				{" "}<span>Date Period</span>{" "}
				
				<Select
					style={{
					width: '13%', textAlign:"center"
					}} size="small"
					placeholder="Select One Date"
					defaultValue={saveDate ? saveDate:'Last 7 Days'}
					onChange={handleChanges}
					optionLabelProp="label"
					value={dateValue} getPopupContainer={(trigger) => trigger.parentElement}
				>
					<Option value={"LAST_7_DAYS"}label="Last 7 Days">
					<Space>
					Last 7 Days
					</Space>
					</Option>
					<Option value="TODAY" label="TODAY">
						<Space>
							{DatePeriodEnum2.TODAY}
						</Space>
					</Option>
					<Option value="YESTERDAY" label="YESTERDAY">
						<Space>
							{DatePeriodEnum2.YESTERDAY}
						</Space>
					</Option>
			
					<Option value="THIS_WEEK" label="THIS_WEEK">
						<Space>
							{DatePeriodEnum2.THIS_WEEK}
						</Space>
					</Option>
					<Option value="THIS_MONTH" label="THIS_MONTH">
						<Space>
							{DatePeriodEnum2.THIS_MONTH}
						</Space>
					</Option>
					<Option value="CUSTOM" label="CUSTOM">
						<Space>
							{DatePeriodEnum2.CUSTOM}
						</Space>
					</Option>						
				</Select>	
							
				</div><br/>
						<Row>
						<Col span={6}>
						<Link to={"/bookings"}>
						<Card style={{backgroundColor:"#fff",borderRadius:"10px",marginBottom:"1rem",height:"140px",textAlign:"center",
					boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",marginLeft:"1rem"}}>
						<Row style={{display:"flex"}}>
						<Col span={12}>
						<img src={noOfBooking} alt="..." style={{width:"60px",height:"60px"}} /></Col>
						<Col span={12}  style={{margin:"auto"}} >
						<span style={{fontSize:"34px"}}>{qryDataDashboardFilterCounts?.getDashboardFilterCounts?.upcomingBookings}</span>
						</Col></Row>
						<h5 style={{paddingTop:"1rem",color:"#858080"}}>NO: OF BOOKINGS FOR NEXT 7 DAYS</h5>
						</Card></Link>
						</Col>

						<div style={{height: "140px", borderLeft: "2px solid #ebebeb"}}/>
						<Col span={6}>
						<Link to={"/bookings"}>
						<Card style={{backgroundColor:"#fff",borderRadius:"10px",boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",marginBottom:"1rem",height:"140px",textAlign:"center"}}>
						<Row style={{display:"flex",paddingTop:"1rem"}}>
						<Col span={12}>
						<img src={totalBooking} alt="..." style={{width:"60px",height:"60px"}} /></Col>
						<Col span={12} style={{margin:"auto"}}>
						<span style={{fontSize:"34px"}}>
							{qryDataDashboardFilterCounts?.getDashboardFilterCounts?.totalBookings}
							</span>
						</Col></Row>
						<h5 style={{paddingTop:"1rem",color:"#858080"}}>TOTAL BOOKINGS</h5>
						</Card></Link></Col>
						<Col span={6}>
						<Link to={"/users"}>
						<Card style={{backgroundColor:"#fff",boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",borderRadius:"10px",marginBottom:"1rem",height:"140px",textAlign:"center"}}>
						<Row style={{display:"flex",paddingTop:"1rem"}}>
						<Col span={12}>
						<img src={newCustomer} alt="..." style={{width:"60px",height:"60px"}} /></Col>
						<Col span={12} style={{margin:"auto"}}>
						<span style={{fontSize: "34px"}}>
							{qryDataDashboardFilterCounts?.getDashboardFilterCounts?.newCustomers}
							</span>
						</Col></Row>
						<h5 style={{paddingTop:"1rem",color:"#858080"}}>NEW CUSTOMERS</h5>
						</Card></Link></Col>
						<Col span={6}><Link to={"/partners"}>
						<Card style={{backgroundColor:"#fff",boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",borderRadius:"10px",marginBottom:"1rem",height:"140px",textAlign:"center"}}>
						<Row style={{display:"flex",paddingTop:"1rem"}}>
						<Col span={12}>
						<img src={newPartner} alt="..." style={{width:"60px",height:"60px"}} /></Col>
						<Col span={12} style={{margin:"auto"}}>
						<span style={{fontSize:"34px"}}>{qryDataDashboardFilterCounts?.getDashboardFilterCounts?.newPartners}</span>
						</Col></Row>
						<h5 style={{paddingTop:"1rem",color:"#858080"}}>NEW PARTNERS</h5>
						</Card></Link></Col>
						</Row><hr/>
						<Row>
						<Col span={6}><Link to={"/job-board"}>
						<Card style={{backgroundColor:"#fff",marginLeft:"1rem",boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",borderRadius:"10px",marginBottom:"1rem",height:"140px",textAlign:"center"}}>
						<Row style={{display:"flex",paddingTop:"1rem"}}>
						<Col span={12}>
						<img src={unassBooking} alt="..." style={{width:"60px",height:"60px"}} /></Col>
						<Col span={12} style={{margin:"auto"}} >
						<span style={{fontSize:"34px"}}>{qryDataDashCounts?.getDashboardCounts?.unassignedBookings}</span>
						</Col></Row>
						<h5 style={{paddingTop:"1rem",color:"#858080"}}>UNASSIGNED  BOOKINGS</h5>
						</Card></Link></Col>
						<Col span={6}><Link to={"/partners-pending"}>
						<Card style={{backgroundColor:"#fff",boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",borderRadius:"10px",marginBottom:"1rem",height:"140px",textAlign:"center"}}>
						<Row style={{display:"flex",paddingTop:"1rem"}}>
						<Col span={12}>
						<img src={pendingPartner} alt="..." style={{width:"60px",height:"60px"}} /></Col>
						<Col span={12} style={{margin:"auto"}}>
						<span style={{fontSize:"34px"}}>{qryDataDashCounts?.getDashboardCounts?.pendingPartners}</span>
						</Col></Row>
						<h5 style={{paddingTop:"1rem",color:"#858080"}}>PENDING PARTNERS</h5>
						</Card></Link></Col>
						<Col span={6}><Link to={"/rework"}>
						<Card style={{backgroundColor:"#fff",boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",borderRadius:"10px",marginBottom:"1rem",height:"140px",textAlign:"center"}}>
						<Row style={{display:"flex",paddingTop:"1rem"}}>
						<Col span={12}>
						<img src={pendingRework} alt="..." style={{width:"60px",height:"60px"}} /></Col>
						<Col span={12} style={{margin:"auto"}}>
						<span style={{fontSize:"34px"}}>{qryDataDashCounts?.getDashboardCounts?.pendingReworks}</span>
						</Col></Row>
						<h5 style={{paddingTop:"1rem",color:"#858080"}}>PENDING REWORK</h5>
						</Card></Link></Col>
						<Col span={6}><Link to={"/bookings"}>
						<Card style={{backgroundColor:"#fff",boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",borderRadius:"10px",marginBottom:"1rem",height:"140px",textAlign:"center"}}>
						<Row style={{display:"flex",paddingTop:"1rem"}}>
						<Col span={12}>
						<img src={workInProg} alt="..." style={{width:"60px",height:"60px"}} /></Col>
						<Col span={12} style={{margin:"auto"}}>
						<span style={{fontSize:"34px"}}>{qryDataDashCounts?.getDashboardCounts?.workingInProgress}</span>
						</Col></Row>
						<h5 style={{paddingTop:"1rem",color:"#858080"}}>WORK IN PROGRESS</h5>
						</Card></Link></Col>
						</Row><hr/>
						<Row>
							<Col span={12} style={{paddingLeft:"17px"}}>
								<Card  style={{borderRadius:"10px",marginBottom:"1rem",height:"400px"}}>
								<div style={{paddingTop:"1rem"}}> 
								<span  ><b>Top 5 Bookings</b></span>
								<div style={{float:"right"}}>
								<Radio.Group onChange={handleTop} value={topBtnValue} >
     							 <Radio value="TRADE">{FilterByEnum.TRADE}</Radio>
     							 <Radio value="PARTNER">{FilterByEnum.PARTNER}</Radio>
   							 	</Radio.Group>
								</div></div><br/>
									{topBtnValue ==="TRADE" ? (

							<>
							<Row>
							<Col span={24} style={{textAlign:"center"}} >
							<span >City</span>
							<span style={{paddingLeft:"9.5rem"}}>Date Period</span>
								</Col>
								
								</Row>
								<Row>
					{/* <div  > */}
					<Col span={24} style={{textAlign:"center"}}>
					<Select
					style={{
					width: '40%',
					}}
					size="small"
					placeholder="Select One City"
					onChange={handleCitiesTop}
					optionLabelProp="label" getPopupContainer={(trigger) => trigger.parentElement}
					value={citiesValueTop}
					defaultValue={"All"}
				>
					<Option value={null}label="All">
					<Space>
						All
					</Space>
					</Option>
					{ qryDataCities?.getCities.map((entry, index) => (
						<Option value={entry?.id} label={entry?.name} key={index}>
							<Space>
								<span role="img" aria-label={entry?.name}>
									{entry?.name}
								</span>

							</Space>
						</Option>
					))}
							
				</Select>	
				{/* {" "}<span>Date Period</span>{" "} */}
				
				<Select
					style={{
					width: '30%',
					 paddingLeft:"0.5rem"
					}}
					size="small"
					placeholder="Select One Date"
					defaultValue={saveDate ? saveDate:'All Time'} getPopupContainer={(trigger) => trigger.parentElement}
					onChange={handleChangesTop}
					optionLabelProp="label"
					value={dateValueTop}
				>
					<Option value={null}label="All Time">
					<Space>
						All
					</Space>
					</Option>
					<Option value="TODAY" label="TODAY">
						<Space>
							{DatePeriodEnums.TODAY}
						</Space>
					</Option>
					<Option value="YESTERDAY" label="YESTERDAY">
						<Space>
							{DatePeriodEnums.YESTERDAY}
						</Space>
					</Option>
			
					<Option value="THIS_WEEK" label="THIS_WEEK">
						<Space>
							{DatePeriodEnums.THIS_WEEK}
						</Space>
					</Option>
					<Option value="THIS_MONTH" label="THIS_MONTH">
						<Space>
							{DatePeriodEnums.THIS_MONTH}
						</Space>
					</Option>
					<Option value="CUSTOM" label="CUSTOM">
						<Space>
							{DatePeriodEnums.CUSTOM}
						</Space>
					</Option>						
				</Select>	</Col> 
				{/* </div> */}
				
				</Row>
			</>
									):(
										<>
							<Row>
							<Col span={24} style={{textAlign:"center"}} >
							<span >Service</span>
							<span style={{paddingLeft:"9.5rem"}}>City</span>
							<span style={{paddingLeft:"6.5rem"}}>Date Period</span>
						</Col>
								
								</Row><Row>
								<Col span={24} style={{textAlign:"center"}}>
								
										<Select
									style={{
										width: '40%',	
									}} size="small"
									placeholder="select Service"
									defaultValue={'All'}
									onChange={handleService}
									showSearch filterOption={filterOption}
									optionLabelProp="label" getPopupContainer={(trigger) => trigger.parentElement}
									value={serviceValue}
	
								>
									<Option value={null} label={"All"} >
										<Space>
											<span role="img" aria-label={"All"}>
												All
											</span>
										</Space>
									</Option>
										<>
											{qryDataService?.getServices.map((entry, index) => (
												<Option value={entry?.id} label={entry?.name} key={index}>
													<Space>
														<span role="img" aria-label={entry?.name}>
															{entry?.name}
														</span>
													</Space>
												</Option>
											))}
										</>
									
								</Select>
								{/* {" "}<span>City</span>{" "} */}
					
					<Select
						style={{
						 paddingLeft:"0.5rem",
						 width:"40%"
						}} size="small"
						placeholder="Select One City"
						onChange={handleCitiesTop} getPopupContainer={(trigger) => trigger.parentElement}
						optionLabelProp="label"
						value={citiesValueTop}
						defaultValue={"All"}
					>
						<Option value={null}label="All">
						<Space>
							All
						</Space>
						</Option>
						{ qryDataCities?.getCities.map((entry, index) => (
							<Option value={entry?.id} label={entry?.name} key={index}>
								<Space>
									<span role="img" aria-label={entry?.name}>
										{entry?.name}
									</span>
	
								</Space>
							</Option>
						))}
								
					</Select>	
					{/* {" "}<span>Date Period</span>{" "} */}
					
					<Select
						style={{
						width: '40%', paddingLeft:"0.5rem"
						}} size="small"
						placeholder="Select One Date"
						defaultValue={saveDate ? saveDate:'All Time'} getPopupContainer={(trigger) => trigger.parentElement}
						onChange={handleChangesTop}
						optionLabelProp="label"
						value={dateValueTop}
					>
						<Option value={null}label="All Time">
						<Space>
							All
						</Space>
						</Option>
						<Option value="TODAY" label="TODAY">
							<Space>
								{DatePeriodEnums.TODAY}
							</Space>
						</Option>
						<Option value="YESTERDAY" label="YESTERDAY">
							<Space>
								{DatePeriodEnums.YESTERDAY}
							</Space>
						</Option>
				
						<Option value="THIS_WEEK" label="THIS_WEEK">
							<Space>
								{DatePeriodEnums.THIS_WEEK}
							</Space>
						</Option>
						<Option value="THIS_MONTH" label="THIS_MONTH">
							<Space>
								{DatePeriodEnums.THIS_MONTH}
							</Space>
						</Option>
						<Option value="CUSTOM" label="CUSTOM">
							<Space>
								{DatePeriodEnums.CUSTOM}
							</Space>
						</Option>						
					</Select>
					</Col></Row>
					</>
					)}

					<hr/>
				<Row style={{marginTop:"-1rem"}}>
				<SiteAudienceDash title="" input={qryDataTopBookings?.getTopBookings}/>
				</Row>
								</Card>
							</Col>
							<Col span={12}>
							<Card  style={{borderRadius:"10px",marginBottom:"1rem",height:"400px"}}>
								<div style={{paddingTop:"1rem"}}> 
								<span ><b>Top 5 Rating</b></span>
								<div style={{float:"right"}}>
								<Radio.Group onChange={handleRating} value={ratingBtnValue} >
     							 <Radio value={"PARTNER"}>{FilterByEnum.PARTNER}</Radio>
     							 <Radio value={"TRADE"}>{FilterByEnum.TRADE}</Radio>
   							 	</Radio.Group>
								</div></div><br/>
								{ratingBtnValue==="PARTNER"?(
								// <div style={{textAlign:"center"}}>
									<>
										<Row>
								<Col span={24} style={{textAlign:"center"}} >
							<span >Service</span>
							<span style={{paddingLeft:"9.5rem"}}>City</span>
							<span style={{paddingLeft:"6.5rem"}}>Date Period</span>
							</Col>
								
								</Row><Row>
								<Col span={24} style={{textAlign:"center"}}>
								
								<Select
								style={{
									width: '40%',	
								}} size="small"
								placeholder="select Service"
								defaultValue={'All'}
								onChange={handleServiceRating} showSearch filterOption={filterOption}
								optionLabelProp="label" getPopupContainer={(trigger) => trigger.parentElement}
								value={serviceValueRating}
							>
								<Option value={null} label={"All"} >
									<Space>
										<span role="img" aria-label={"All"}>
											All
										</span>
									</Space>
								</Option>
									<>
										{qryDataService?.getServices.map((entry, index) => (
											<Option value={entry?.id} label={entry?.name} key={index}>
												<Space>
													<span role="img" aria-label={entry?.name}>
														{entry?.name}
													</span>
												</Space>
											</Option>
										))}
									</>
								
							</Select>
						
				
				<Select
					style={{
						paddingLeft:"0.5rem",width:"30%"
					}} size="small"
					placeholder="Select One City"
					onChange={handleCitiesRating}
					optionLabelProp="label"
					value={citiesValueRating} getPopupContainer={(trigger) => trigger.parentElement}
					defaultValue={"All"}
				>
					<Option value={null}label="All Time">
					<Space>
						All
					</Space>
					</Option>
					{ qryDataCities?.getCities.map((entry, index) => (
						<Option value={entry?.id} label={entry?.name} key={index}>
							<Space>
								<span role="img" aria-label={entry?.name}>
									{entry?.name}
								</span>

							</Space>
						</Option>
					))}
							
				</Select>	
				
				
				<Select
					style={{
					width: '30%',paddingLeft:"0.5rem"
					}} size="small"
					placeholder="Select One Date"
					defaultValue={saveDate ? saveDate:'All Time'}
					onChange={handleChangesRating} getPopupContainer={(trigger) => trigger.parentElement}
					optionLabelProp="label"
					value={dateValueRating}
				>
					<Option value={null}label="All Time">
					<Space>
						All
					</Space>
					</Option>
					<Option value="TODAY" label="TODAY">
						<Space>
							{DatePeriodEnums.TODAY}
						</Space>
					</Option>
					<Option value="YESTERDAY" label="YESTERDAY">
						<Space>
							{DatePeriodEnums.YESTERDAY}
						</Space>
					</Option>
			
					<Option value="THIS_WEEK" label="THIS_WEEK">
						<Space>
							{DatePeriodEnums.THIS_WEEK}
						</Space>
					</Option>
					<Option value="THIS_MONTH" label="THIS_MONTH">
						<Space>
							{DatePeriodEnums.THIS_MONTH}
						</Space>
					</Option>
					<Option value="CUSTOM" label="CUSTOM">
						<Space>
							{DatePeriodEnums.CUSTOM}
						</Space>
					</Option>						
				</Select>		</Col></Row>
				{/* // </div> */}</>
								):(
									<>
							{/* // <div style={{textAlign:"center"}} > */}
								<Row>
							<Col span={24} style={{textAlign:"center"}} >
							<span >City</span>
							<span style={{paddingLeft:"10.5rem"}}>Date Period</span>
								</Col>
								
						</Row>
						<Row>
					<Col span={24} style={{textAlign:"center"}}>
							<Select
							style={{
							width: '40%',
							}} size="small"
							placeholder="Select One City"
							onChange={handleCitiesRating}
							optionLabelProp="label" getPopupContainer={(trigger) => trigger.parentElement}
							value={citiesValueRating}
							defaultValue={"All"}
						>
							<Option value={null}label="All">
							<Space>
								All
							</Space>
							</Option>
							{ qryDataCities?.getCities.map((entry, index) => (
								<Option value={entry?.id} label={entry?.name} key={index}>
									<Space>
										<span role="img" aria-label={entry?.name}>
											{entry?.name}
										</span>
		
									</Space>
								</Option>
							))}
									
						</Select>	
						
						<Select
							style={{
							width: '30%',
							 paddingLeft:"0.5rem"

							}} size="small"
							placeholder="Select One Date"
							defaultValue={saveDate ? saveDate:'All Time'}
							onChange={handleChangesRating} getPopupContainer={(trigger) => trigger.parentElement}
							optionLabelProp="label"
							value={dateValueRating}
						>
							<Option value={null}label="All Time">
							<Space>
								All
							</Space>
							</Option>
							<Option value="TODAY" label="TODAY">
								<Space>
									{DatePeriodEnums.TODAY}
								</Space>
							</Option>
							<Option value="YESTERDAY" label="YESTERDAY">
								<Space>
									{DatePeriodEnums.YESTERDAY}
								</Space>
							</Option>
					
							<Option value="THIS_WEEK" label="THIS_WEEK">
								<Space>
									{DatePeriodEnums.THIS_WEEK}
								</Space>
							</Option>
							<Option value="THIS_MONTH" label="THIS_MONTH">
								<Space>
									{DatePeriodEnums.THIS_MONTH}
								</Space>
							</Option>
							<Option value="CUSTOM" label="CUSTOM">
								<Space>
									{DatePeriodEnums.CUSTOM}
								</Space>
							</Option>						
						</Select></Col> </Row>
						</>
								)}
								<hr/>
								<Row style={{marginTop:"-1rem"}}>
								<SiteAudienceDash title="" input={qryDataTopRatings?.getTopRatings}/>
								</Row>
							</Card>
							</Col>	
						</Row>
						
						</TabPane>
							) : (
						<></>
					)}
					
						{userAuth?.userType === UserTypeEnum.ADMIN ||
					userAuth?.rights?.find(
						(x) => x.type === 'sub2' && x.name === 'directors-dashboard',
					) ? (
						<TabPane tab="Directors Dashboard" key="2">
									<DirectorsDashboard />
								</TabPane>
					) : (
						<></>
					)}
						</Tabs>
					</Widget>
					{/* </Col>
				
						</Row> */}
					
						{/* <Row>
							<Col xl={6} lg={12} md={12} sm={12} xs={24}>
								<WelComeCard />
							</Col>

							<Col
								xl={6}
								lg={12}
								md={12}
								sm={12}
								xs={24}
								className="gx-audi-col"
							>
								<SiteAudience title="Site Audience" />
							</Col>

							<Col
								xl={12}
								lg={24}
								md={24}
								sm={24}
								xs={24}
								className="gx-visit-col"
							>
								<SiteVisit title="Incoming bookings" />
							</Col>
						</Row> */}
					</div>
				</div>
			</Col>
			{/* <Col xl={8} lg={24} md={8} sm={24} xs={24}>
				<TotalRevenueCard
					title="TOTAL REVENUE"
					value={qryData?.getDashboardData?.directorsDashboard
						?.totalRevenue}
					width={'100%'}
				/>
			</Col> */}
{/* 
			<Col xl={8} lg={12} md={8} sm={24} xs={24}>
				<GrowthCard trafficData={trafficData} title="Growth" percentage="35%" />
			</Col> */}
			{/* <Col xl={8} lg={12} md={8} sm={24} xs={24}> */}
				{/* <NewCustomers /> */}
				{/* <GrowthCard
					trafficData={trafficData}
					title="Customer retention rate"
					percentage="20%"
				/>
			</Col> */}
{/* 
			<Col xl={8} lg={24} md={8} sm={24} xs={24}>
				<TotalRevenueCard
					title="Total number of customers"
					value={qryData?.getDashboardData?.directorsDashboard
						?.totalCustomers}
					width={'100%'}
				/>
			</Col> */}
			{/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
				<Overview
					title="Revenue Overview"
					total="₹ 200,167"
					cities={{
						total: 100,
						values: [
							{ name: 'Cochin', value: 60 },
							{ name: 'Others', value: 30 },
						],
					}}
				/>
			</Col> */}

			{/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
				<Overview
					title="Total Registered Customers by city"
					total="2000"
					cities={{
						total: 100,
						values: [
							{ name: 'Cochin', value: 70 },
							{ name: 'Others', value: 40 },
						],
					}}
				/>
			</Col> */}

			{/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
				<Overview
					title="Total Service Offering by Service"
					total="32 Services"
					disableMap
					cities={{
						total: 100,
						values: [
							{ name: 'Electric (Booked 52 times)', value: 70 },
							{ name: 'Handyman (Booked 33 times)', value: 40 },
						],
					}}
				/>
			</Col> */}
			{/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
				<Overview
					title="Total Service Offering by Area"
					total="32 Services"		
					cities={{
						total: 100,
						values: qryData?.getDashboardData?.directorsDashboard?.serviceOfferingsByArea ? qryData?.getDashboardData?.directorsDashboard?.serviceOfferingsByArea?.map((data, index) => (
							{ name: data.areaName, value: data.bookings }
						)) : []	
					}}
				/>			
			</Col> */}
{/* 
			<Col xl={24} lg={24} md={24} sm={24} xs={24}>
				<Overview
					title="Total Service Offering by Trade"
					total="32 Services"
					cities={{
						total: 100,
						values: qryData?.getDashboardData?.directorsDashboard?.serviceOfferingsByTrade ? qryData?.getDashboardData?.directorsDashboard?.serviceOfferingsByTrade?.map((data, index) => (
							{ name: data.serviceName, value: data.bookings }
						)) : []
					}}
				/>
			</Col> */}
			{/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
				<Overview
					title="Total Service Offering by City"
					total="32 Services"
					cities={{
						total: 100,
						values: qryData?.getDashboardData?.directorsDashboard?.serviceOfferingsByCity ? qryData?.getDashboardData?.directorsDashboard?.serviceOfferingsByCity?.map((data, index) => (
							{ name: data.areaName, value: data.bookings }
						)) : []
					}}
				/>
			</Col> */}

			{/* <Col xl={8} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-2">
				<Widget>
					<RecentActivity recentList={recentActivity} shape="circle" />
				</Widget>
				<CurrencyCalculator />
			</Col> */}

			<Col xl={16} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-1">
				<Row>
					{/* <Col xl={6} lg={6} md={6} sm={12} xs={12}>
						<IconWithTextCard
							cardColor="cyan"
							icon="diamond"
							title="09"
							subTitle="Projects"
						/>
					</Col>
					<Col xl={6} lg={6} md={6} sm={12} xs={12}>
						<IconWithTextCard
							cardColor="orange"
							icon="tasks"
							title="687"
							subTitle="Tasks"
						/>
					</Col>
					<Col xl={6} lg={6} md={6} sm={12} xs={12}>
						<IconWithTextCard
							cardColor="teal"
							icon="team"
							title="04"
							subTitle="Teams"
						/>
					</Col>
					<Col xl={6} lg={6} md={6} sm={12} xs={12}>
						<IconWithTextCard
							cardColor="red"
							icon="files"
							title="09"
							subTitle="Files"
						/>
					</Col> */}
					{/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<TaskList taskList={taskList} />
					</Col>
					<Col xl={16} lg={16} md={16} sm={24} xs={24}>
						<TicketList />
					</Col>
					<Col xl={8} lg={8} md={8} sm={24} xs={24}>
						<TaskByStatus />
					</Col> */}
				</Row>
			</Col>
								{ isOpenDate && 
                                    <Modal
                                    title='Date'
                                    visible={isOpenDate}
                                    onOk={handleOk}
                                    onCancel={handleCancel}
                                    // width={800}
                                >

                                <div style={{textAlign:"center"}}> 
                                <Space direction="vertical" size={6}>
                                <RangePicker onChange={handleDate}/>
                                </Space> 
                                </div>

                                </Modal>
                          	  }
							  	{ isOpenDateTop && 
                                    <Modal
                                    title='Date'
                                    visible={isOpenDateTop}
                                    onOk={handleOkTop}
                                    onCancel={handleCancelTop}
                                    // width={800}
                                >

                                <div style={{textAlign:"center"}}> 
                                <Space direction="vertical" size={6}>
                                <RangePicker onChange={handleDateTop}/>
                                </Space> 
                                </div>

                                </Modal>
                          	  }
							  	{ isOpenDateRating && 
                                    <Modal
                                    title='Date'
                                    visible={isOpenDateRating}
                                    onOk={handleOkRating}
                                    onCancel={handleCancelRating}
                                    // width={800}
                                >

                                <div style={{textAlign:"center"}}> 
                                <Space direction="vertical" size={6}>
                                <RangePicker onChange={handleDateRating}/>
                                </Space> 
                                </div>

                                </Modal>
                          	  }
		</Row>
	);
};

export default Dashboard;