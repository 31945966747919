import React, { useState } from 'react';
import { Modal, Button, Row, Col, Alert } from 'antd';
import { Form, Input, Select, Checkbox } from 'antd';
import { useMutation } from '@apollo/client';

import {
	CREATE_SERVICE_INPUT_OPTION,
	DELETE_SERVICE_INPUT,
	UPDATE_SERVICE_INPUT_OPTION,
} from 'src/lib/gql/mutations/mutations';

import { ServiceInputTypeEnum } from 'src/lib/gql/generated/globalTypes';
import {
	CMSCreateServiceInputOption,
	CMSCreateServiceInputOptionVariables,
} from 'src/lib/gql/generated/CMSCreateServiceInputOption';
import {
	CMSDeleteServiceInput,
	CMSDeleteServiceInputVariables,
} from 'src/lib/gql/generated/CMSDeleteServiceInput';
import { CMSGetService_getService_inputs } from 'src/lib/gql/generated/CMSGetService';
import {
	CMSUpdateServiceInput,
	CMSUpdateServiceInputVariables,
} from 'src/lib/gql/generated/CMSUpdateServiceInput';
import { EditOutlined,DeleteOutlined } from '@ant-design/icons';

interface Props {
	serviceID: string;
	input?: CMSGetService_getService_inputs;
	onFinishAdd?: () => void;
}

const AddServiceInput = ({ serviceID, input, onFinishAdd }: Props) => {
	const [isModalVisible, setIsModalVisible] = useState(false);

	const [
		createServiceInputOption,
		{ data: dataMt, loading: loadingMt, error: errorMt },
	] = useMutation<
		CMSCreateServiceInputOption,
		CMSCreateServiceInputOptionVariables
	>(CREATE_SERVICE_INPUT_OPTION);

	const [
		updateServiceInput,
		{ data: dataMtu, loading: loadingMtu, error: errorMtu },
	] = useMutation<CMSUpdateServiceInput, CMSUpdateServiceInputVariables>(
		UPDATE_SERVICE_INPUT_OPTION,{
			onCompleted: () => {
				onFinishAdd
			},
		}
	);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
		setIsModalVisible(false);
	};

	const [form] = Form.useForm();

	const onFinish = (values) => {
		let data: CMSCreateServiceInputOptionVariables['data'];
		if (!input) {
			data = {
				serviceId: serviceID,
				key: values.key,
				name: values.name,
				description: values.description,
				type: values.type.toUpperCase(),
				required: values.required === true,
			};
		} else {
			data = {
				serviceId: serviceID,
				key: values.key,
				name: values.name,
				description: values.description,
				type: values.type.toUpperCase(),
				required: values.required === true,
			};
		}

		if (!input) {
			createServiceInputOption({
				variables: {
					data: data,
				},
			}).then(() => {
				form.resetFields();
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
					}
					setIsModalVisible(false);
				}, 1500);
			});
		} else {
			updateServiceInput({
				variables: {
					data: {
						serviceId: serviceID,
						key: values.key,
						name: values.name,
						description: values.description,
						type: values.type.toUpperCase(),
						required: values.required === true,
					},
					id: input.id,
				},
			}).then(() => {
				form.resetFields();
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
					}
					setIsModalVisible(false);
				}, 1500);
			});
		}
	};

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	// Delete
	const [
		deleteServiceInput,
		{ data: dataMtD, loading: loadingMtD, error: errorMtD },
	] = useMutation<CMSDeleteServiceInput, CMSDeleteServiceInputVariables>(
		DELETE_SERVICE_INPUT,
	);
	const onDelete = () => {
		if (!input?.id) return;
		deleteServiceInput({
			variables: {
				id: input?.id,
			},
		}).then(() => {
			setTimeout(() => {
				if (typeof onFinishAdd === 'function') {
					onFinishAdd();
				}
				setIsDeleteModalVisible(false);
			}, 1500);
		});
	};

	return (
		<>
			<Button
				type="primary"
				onClick={showModal}
				style={
					input
						? {
								background: 'transparent',
								color: '#5a20ff',
								border: 'none',
								boxShadow: 'none',
						  }
						: {}
				}
			>	{!input ? 'Add Additional Input' : <EditOutlined />}
			</Button>
			{/* <Button type="primary" onClick={showModal}>
				{!input ? 'Add Additional Input' : <EditOutlined/>}
			</Button> */}
			<Modal
				title={!input ? 'Add Additional Input' : 'Edit'}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
			>
				{dataMt && <Alert message="Created" type="success" showIcon />}
				{loadingMt && <Alert message="Submitting..." type="info" showIcon />}
				{errorMt && (
					<Alert
						message={`Submission error! ${errorMt.message}`}
						type="error"
						showIcon
					/>
				)}

				{/* Updating */}
				{dataMtu && <Alert message="Updated" type="success" showIcon />}
				{loadingMtu && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtu && (
					<Alert
						message={`Submission error! ${errorMtu.message}`}
						type="error"
						showIcon
					/>
				)}
				<Form
					form={form}
					name="addServiceInput"
					onFinish={onFinish}
					scrollToFirstError
					initialValues={
						!input
							? {}
							: {
									serviceId: serviceID,
									name: input.name,
									key: input.key,
									description: input.description,
									type: input.type.toUpperCase(),
									required: input.required === true,
							  }
					}
				>
					<Row>
						<Col span={8}>
							<Form.Item
								name={`key`}
								label={<span>Key</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item
								name={`name`}
								label={<span>Name</span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={22}>
							<Form.Item
								name={`description`}
								label={<span>Description</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input.TextArea />
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item
								label="Type"
								name="type"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Select getPopupContainer={(trigger) => trigger.parentElement}>
									<Select.Option value={ServiceInputTypeEnum.DATE}>
										{ServiceInputTypeEnum.DATE}
									</Select.Option>
									<Select.Option value={ServiceInputTypeEnum.TEXT}>
										{ServiceInputTypeEnum.TEXT}
									</Select.Option>
									<Select.Option value={ServiceInputTypeEnum.LOCATION}>
										{ServiceInputTypeEnum.LOCATION}
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={2}></Col>

						<Col span={4}>
							<Form.Item name="required" valuePropName="checked">
								<Checkbox>Required</Checkbox>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			{input && (
				<Button
					type="text"
					onClick={() => setIsDeleteModalVisible(true)}
					danger
				>
					<DeleteOutlined/>
				</Button>
			)}
			<Modal
				title={'Delete'}
				visible={isDeleteModalVisible}
				onOk={onDelete}
				onCancel={() => setIsDeleteModalVisible(false)}
				width={300}
			>
				Delete?
				{/* Delete */}
				{dataMtD && <Alert message="Deleted" type="success" showIcon />}
				{loadingMtD && <Alert message="Deleting..." type="info" showIcon />}
				{errorMtD && (
					<Alert
						message={`Submission error! ${errorMtD.message}`}
						type="error"
						showIcon
					/>
				)}
			</Modal>
		</>
	);
};
export default AddServiceInput;
