import { useMutation, useQuery } from '@apollo/client';
import {
	Card,
	Col,
	Input,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Table,
	Button,
	Modal,
	Alert,
	Form,
	Switch,
	Radio,
} from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import {
	CMSGetUsers,
	CMSGetUsersVariables,
} from 'src/lib/gql/generated/CMSGetUsers';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import {
	DELETE_SUB_ADMIN,
	ADD_NEW_ROLE,
	EDIT_NEW_ROLE,
	DELETE_ROLE,
} from 'src/lib/gql/mutations/mutations';
import {
	CMSQueryGetRights,
	CMSQueryGetRoles,
} from 'src/lib/gql/queries/rights';
import {
	CMSQueryGetSubAdmins,
	CMSQueryGetUsers,
} from 'src/lib/gql/queries/users';
import { CaretLeftOutlined } from '@ant-design/icons';
import './admins.css';

const { Option } = Select;

function countCommonElements(arr1, arr2) {
	// Initialize a counter variable
	let count = 0;

	// Loop through each element in arr1
	for (let i = 0; i < arr1.length; i++) {
		// Check if the element exists in arr2
		if (arr2.includes(arr1[i])) {
			// Increment the counter if it does
			count++;
		}
	}

	// Return the count of common elements
	return count;
}

const Roles: React.FC = () => {
	const columns = [
		{
			title: 'Sl No',
			dataIndex: 'slNo',
			key: 'slNo',
			align: 'center' as const,
			width: 100,
			render: (slNo) => slNo,
		},
		{
			title: 'Title',
			dataIndex: 'name',
			key: 'name',
			width: 150,
			render: (name) => <span className="">{name} </span>,
		},
		// {
		// 	title: 'Email',
		// 	dataIndex: 'user',
		// 	key: 'email',
		// 	render: (user) => <span className="gx-link">{user.email} </span>,
		// },
		// {
		// 	title: 'Mobile number',
		// 	dataIndex: 'user',
		// 	key: 'phone',
		// 	render: (user) => <span className="gx-link">{user.phone} </span>,
		// },
		// {
		// 	title: 'Account Status',
		// 	dataIndex: 'user',
		// 	render: (user) => (
		// 		<span>{user?.userType === 'SUB_ADMIN' ? 'Active' : 'Pending'}</span>
		// 	),
		// },

		{
			title: 'Action',
			dataIndex: 'key',
			key: 'id',
			width: 'auto',
			align: 'right' as const,
			render: (text, record) => (
				<>
					<span>
						<a onClick={() => handleEditRoleButton(record)}>
							<i className="icon icon-edit" style={{ marginRight: '20px',color:"#5143dc" }} />
						</a>
					</span>
					<span>
						<a onClick={() => showRightsModal(record)}>
							<i
								className="icon icon-ckeditor"
								style={{ marginRight: '20px',color:"#232222" }}
							/>
						</a>
					</span>
					<span>
						<a onClick={() => handleOpenDeleteModal(record?.id)}>
							<i className="icon icon-trash" style={{ marginRight: '20px' }} />
						</a>
					</span>
				</>
			),
		},
	];

	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [filterName, SetFilterName] = useState('');
	const [filterPhone, SetFilterPhone] = useState('');
	const [accountType, SetAccountType] = useState(UserTypeEnum.CUSTOMER);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const [deletingRole, setDeletingRole] = useState('');
	const [rightsOfAdminRole, setRightsOfAdminRole] = useState<any[]>([]);
	const [selectedAdmin, setSelectedAdmin] = useState<any>('');
	const [isRoleModalVisible, setIsRoleModalVisible] = useState<boolean>(false);
	const [addValues, setAddValues] = useState({
		name: '',
	});
	const [addRights, setAddRights] = useState<any[]>([]);
	const [activePage, setActivePage] = useState(1);
	const [addRightsValidation, setAddRightsValidation] = useState(true);
	const [editValues, setEditValues] = useState({
		name: '',
	});
	const [isEditRoleModalVisible, setIsEditRoleModalVisible] = useState(false);
	const [selectedRole, setSelectedRole] = useState<any>();
	const [editRights, setEditRights] = useState<any[]>([]);
	const [editRightsValidation, setEditRightsValidation] = useState(false);
	const [isManageRightsModalVisible, setIsManageRightsModalVisible] =
		useState(false);
	const [testRole, settestRole] = useState('testt');
	const [showRolesAddSuccessAlert, setShowRolesAddSuccessAlert] =
		useState(false);
	const [showEditRole2Alert, setShowEditRole2Alert] = useState(false);

	const [form2] = Form.useForm();
	const [form3] = Form.useForm();
	const [form4] = Form.useForm();
	const [deleteAlert, setDeleteAlert] = useState(false)

	const handleNext = useCallback(async () => {
		try {
			await form2.validateFields(['name']);
			setActivePage(activePage + 1);
		} catch (error) {
			console.log('Validation error:', error);
		}
	}, [form2, activePage]);

	const handleBack = useCallback(() => {
		setActivePage(activePage - 1);
		if (
			mainPagesRights &&
			Array.isArray(mainPagesRights) &&
			mainPagesRights.length
		) {
			const event = {
				target: {
					value: mainPagesRights[0]?.id,
				},
			};
			handleMainPageRadio(event);
		}
	}, [activePage]);

	// const { data: qryData, loading } = useQuery<
	// 	CMSGetUsers,
	// 	CMSGetUsersVariables
	// >(CMSQueryGetUsers, {
	// 	variables: {
	// 		pagination: pagination,
	// 		filters: {
	// 			type: accountType,
	// 			name: filterName,
	// 		},
	// 	},
	// });

	// const {
	// 	data: qryData,
	// 	loading,
	// 	refetch: refetchQryData,
	// } = useQuery(CMSQueryGetSubAdmins, {
	// 	variables: {
	// 		pagination: pagination,
	// 		filters: {
	// 			name: filterName,
	// 		},
	// 	},
	// 	fetchPolicy: 'network-only',
	// });

	// Get All Roles
	const {
		data: rolesData,
		loading,
		refetch: refetchRoles,
	} = useQuery(CMSQueryGetRoles, {
		variables: {
			pagination: pagination,
			filters: {
				name: filterName,
			},
		},
		fetchPolicy: 'network-only',
	});

	// Get All Roles
	const { data: rightsData } = useQuery(CMSQueryGetRights);

	const [deleteRole, { data: dataMtD, loading: loadingMtD, error: errorMtD }] =
		useMutation(DELETE_ROLE);

	const navigate = useNavigate();

	const [mainPagesRights, setMainPagesRights] = useState<any>([]);
	const [subPagesRights, setSubPagesRights] = useState<any>([]);
	const [rightsOfPageOrSubpage, setRightsOfPageOrSubpage] = useState<any>([]);
	const [
		subPagesRightsOfSelectedMainPage,
		setSubPagesRightsOfSelectedMainPage,
	] = useState<any>([]);
	const [selectedMainPageRight, setSelectedMainPageRight] = useState<any>();

	useEffect(() => {
		//   console.log('rightsData---', rightsData.getRights)

		const mainPages = rightsData?.getRights.filter((x) => x.type === 'main');
		setMainPagesRights(mainPages);

		const subPages = rightsData?.getRights.filter((x) => x.type === 'sub');
		setSubPagesRights(subPages);

		const rightsUnderPageOrSubpage = rightsData?.getRights.filter(
			(x) => x.type === 'sub2',
		);
		setRightsOfPageOrSubpage(rightsUnderPageOrSubpage);
	}, [rightsData]);

	const handleOpenDeleteModal = (id) => {
		setDeletingRole(id);
		setIsDeleteModalVisible(true);
	};

	const handleDeleteRole = () => {
		deleteRole({
			variables: {
				id: deletingRole,
			},
		}).then((res) => {
			setIsDeleteModalVisible(false);
			refetchRoles();
		});
		setDeleteAlert(true)
		setTimeout(() => {
		setDeleteAlert(false)
		}, 2000)
	};

	const [isModalVisible, setIsModalVisible] = useState(false);
	const showRightsModal = (role) => {
		setSelectedRole(role);
		setEditRights(role?.rights);
		setShowEditRole2Alert(false);

		if (
			mainPagesRights &&
			Array.isArray(mainPagesRights) &&
			mainPagesRights.length
		) {
			const event = {
				target: {
					value: mainPagesRights[0]?.id,
				},
			};
			handleMainPageRadio(event);
		}
		setIsManageRightsModalVisible(true);

		// const roleDataOfSelectedAdmin = rolesData?.getRoles?.find(
		// 	(x: any) => x.id == adminData?.roleId,
		// );
		// console.log('roleDataOfSelectedAdmin-->', roleDataOfSelectedAdmin);
		// setRightsOfAdminRole(roleDataOfSelectedAdmin?.rights);
		// setIsModalVisible(true);
	};

	const [
		addNewRole,
		{ data: dataRoleMt, loading: loadingRoleMt, error: errorRoleMt },
	] = useMutation(ADD_NEW_ROLE);

	const [
		editRole,
		{
			data: dataEditRoleMt,
			loading: loadingEditRoleMt,
			error: errorEditRoleMt,
		},
	] = useMutation(EDIT_NEW_ROLE);

	const [rights, setRights] = useState<string[]>([]);

	const onCreateRoleFinish = (value) => {

		if (!addRights?.length || addRightsValidation) {
			return;
		}

		addNewRole({
			variables: {
				data: {
					name: addValues.name,
					rights: addRights,
					// email: adminValues.email,
					// phone: adminValues.phone,
					// password: adminValues.password,
					// role: adminValues.role,
				},
			},
		}).then((res) => {
			// navigate('/partners/' + res?.data?.registerPartnerByAdmin?.id);
			setShowRolesAddSuccessAlert(true);
			setAddValues({ name: '' });
			setAddRights([]);
			setActivePage(1);

			if (
				mainPagesRights &&
				Array.isArray(mainPagesRights) &&
				mainPagesRights.length
			) {
				const event = {
					target: {
						value: mainPagesRights[0]?.id,
					},
				};
				handleMainPageRadio(event);
			}

			form2.resetFields();
			setIsRoleModalVisible(false);
			refetchRoles();
		});
	};

	// old
	// const handleRightsSelection = (value, checked) => {
	// 	if (checked) {
	// 		setAddRightsValidation(false);
	// 		setAddRights([...addRights, value]);
	// 	} else {
	// 		setAddRightsValidation(addRights.length === 1);
	// 		const updatedArray = addRights.filter((item) => item !== value);
	// 		setAddRights(updatedArray);
	// 	}
	// };

	useEffect(() => {
		if (!addRights.length) {
			setAddRightsValidation(true);
		}
	}, [addRights]);

	// old
	// const handleEditRightsSelection = (value, checked) => {
	// 	console.log('rights is switching', value, checked);
	// 	if (checked) {
	// 		setEditRightsValidation(false);
	// 		setEditRights([...editRights, value]);
	// 	} else {
	// 		setEditRightsValidation(editRights.length === 1);
	// 		console.log('editRights--->', editRights);
	// 		const updatedArray = editRights.filter((item) => item.id !== value.id);
	// 		console.log('updatedArray--->', updatedArray);
	// 		setEditRights(updatedArray);
	// 	}
	// };

	// edit role name
	const onEditRoleFinish = (value) => {
		const editRightsIds = editRights.map((i) => i.id);
		editRole({
			variables: {
				id: selectedRole.id,
				data: {
					...(editValues?.name && { name: editValues.name }),
					...(editRights?.length && { rights: editRightsIds }),
				},
			},
		}).then((res) => {
			setShowEditRole2Alert(true);
			form3.resetFields();
			form4.resetFields();
			setIsManageRightsModalVisible(false);
			setIsEditRoleModalVisible(false);
			refetchRoles();
		});
	};

	const handleCloseEditRoleModal = () => {
		setSelectedRole({});
		form3.resetFields();
		setEditValues({ name: '' });
		setIsEditRoleModalVisible(false);
	};

	const handleEditRoleButton = (role) => {
		setSelectedRole(role);
		setShowEditRole2Alert(false);
		setIsEditRoleModalVisible(true);
	};

	const handleCloseManageRightsModal = () => {
		setEditRights([]);
		setIsManageRightsModalVisible(false);
	};

	useEffect(() => {
		form3.setFieldsValue({
			name: selectedRole?.name,
		});
	}, [selectedRole]);

	const handleRightsSelection = (value, checked, parentId: any) => {
		let count = 0;

		const subPageData = subPagesRights.find((x) => x.id == parentId);
		const mainParentData = mainPagesRights.find(
			(x) => x.id == subPageData?.parentId,
		);
		if (parentId) {
			const subPagesWithThisParentId = subPagesRights.filter(
				(x) => x.parentId == parentId,
			);
			const innerRightsWithThisParentId = rightsOfPageOrSubpage.filter(
				(x) => x.parentId == parentId,
			);

			if (subPagesWithThisParentId.length) {
				const subPagesRightsIdsWithThisParentId = subPagesWithThisParentId.map(
					(i) => i.id,
				);
				count = countCommonElements(
					addRights,
					subPagesRightsIdsWithThisParentId,
				);
			} else if (innerRightsWithThisParentId.length) {
				const innerRightsIdsWithThisParentId = innerRightsWithThisParentId.map(
					(i) => i.id,
				);
				count = countCommonElements(addRights, innerRightsIdsWithThisParentId);
			}
		}
		const isSubPageRight = subPagesRights.find((x) => x.id == value);

		if (checked) {
			if (parentId && count == 0) {
				const isMainPageAdded = addRights.filter(
					(x) => x == mainParentData?.id,
				);
				if (isMainPageAdded.length) {
					setAddRights([...addRights, parentId, value]);
				} else {
					setAddRights([...addRights, parentId, value, mainParentData?.id]);
				}
			} else {
				setAddRights([...addRights, value]);
			}
			setAddRightsValidation(false);
		} else {
			let updatedArray;
			if (parentId && count == 1) {
				updatedArray = addRights.filter((item) => item !== value);
				updatedArray = updatedArray.filter((item) => item !== parentId);
				updatedArray = updatedArray.filter(
					(item) => item !== mainParentData?.id,
				);
			} else {
				updatedArray = addRights.filter((item) => item !== value);
			}
			setAddRights(updatedArray);
		}
	};

	const handleEditRightsSelection = (value, checked, parentId: any) => {
		let count = 0;
		value = value?.id;
		const valueData = rightsData.getRights.find((x) => x.id == value);
		const parentIdData = rightsData.getRights.find((x) => x.id == parentId);
		const subPageData = subPagesRights.find((x) => x.id == parentId);
		const mainParentData = mainPagesRights.find(
			(x) => x.id == subPageData?.parentId,
		);
		if (parentId) {
			const subPagesWithThisParentId = subPagesRights.filter(
				(x) => x.parentId == parentId,
			);
			const innerRightsWithThisParentId = rightsOfPageOrSubpage.filter(
				(x) => x.parentId == parentId,
			);

			const editRightsIds = editRights.map((x) => x.id);
			if (subPagesWithThisParentId.length) {
				const subPagesRightsIdsWithThisParentId = subPagesWithThisParentId.map(
					(i) => i.id,
				);
				count = countCommonElements(
					editRightsIds,
					subPagesRightsIdsWithThisParentId,
				);
			} else if (innerRightsWithThisParentId.length) {
				const innerRightsIdsWithThisParentId = innerRightsWithThisParentId.map(
					(i) => i.id,
				);
				count = countCommonElements(
					editRightsIds,
					innerRightsIdsWithThisParentId,
				);
			}
		}
		const isSubPageRight = subPagesRights.find((x) => x.id == value);

		if (checked) {
			if (parentId && count == 0) {
				const isMainPageAdded = editRights.filter(
					(x) => x.id == mainParentData?.id,
				);
				if (isMainPageAdded.length) {
					setEditRights([...editRights, parentIdData, valueData]);
				} else {
					setEditRights([
						...editRights,
						parentIdData,
						valueData,
						mainParentData,
					]);
				}
			} else {
				setEditRights([...editRights, valueData]);
			}
			setEditRightsValidation(false);
		} else {
			let updatedArray;
			if (parentId && count == 1) {
				updatedArray = editRights.filter((item) => item.id !== value);
				updatedArray = updatedArray.filter((item) => item.id !== parentId);
				updatedArray = updatedArray.filter(
					(item) => item !== mainParentData?.id,
				);
			} else {
				updatedArray = editRights.filter((item) => item.id !== value);
			}
			setEditRights(updatedArray);
		}
	};

	const handleMainPageRadio = (e) => {

		const subPagesOfSelectedMainPage = subPagesRights.filter(
			(x) => x.parentId == e?.target?.value,
		);
		const selectedMainPage = mainPagesRights.find(
			(x) => x.id == e?.target?.value,
		);
		setSelectedMainPageRight(selectedMainPage);

		setSubPagesRightsOfSelectedMainPage(subPagesOfSelectedMainPage);
	};

	useEffect(() => {
		if (
			Array.isArray(mainPagesRights) &&
			mainPagesRights.length &&
			!selectedMainPageRight
		) {
			const subPagesOfSelectedMainPage = subPagesRights.filter(
				(x) => x.parentId == mainPagesRights[0]?.id,
			);
			const selectedMainPage = mainPagesRights.find(
				(x) => x.id == mainPagesRights[0]?.id,
			);
			setSelectedMainPageRight(selectedMainPage);

			setSubPagesRightsOfSelectedMainPage(subPagesOfSelectedMainPage);
		}
	}, [mainPagesRights]);

	const handleShowAddRoleModal = () => {
		setIsRoleModalVisible(true);
		setShowRolesAddSuccessAlert(false);
	};

	const handleCloseAddRoleModal = () => {
		setAddValues({ name: '' });
		setAddRights([]);
		setActivePage(1);
		if (
			mainPagesRights &&
			Array.isArray(mainPagesRights) &&
			mainPagesRights.length
		) {
			const event = {
				target: {
					value: mainPagesRights[0]?.id,
				},
			};
			handleMainPageRadio(event);
		}
		setIsRoleModalVisible(false);
	};

	return (
		// <Card title="Roles">
		<Card
			title={
				<div>
					<Button
						className="gx-mr-4"
						onClick={() => navigate(-1)}
						style={{ margin: '0' }}
					>
						<CaretLeftOutlined />
					</Button>
					Roles
				</div>
			}
			extra={[
				<Button
					type="primary"
					className="text-align-right"
					onClick={handleShowAddRoleModal}
					key={1}
				>
					Add New Role
				</Button>,
			]}
		>
		

			<Row style={{marginBottom: "16px",
   						paddingTop: "0.2rem",
   						paddingBottom: "5px"}}>
				<Col span={24}>
				<div style={{textAlign:"center"}}>
					<Input
						style={{width:"250px"}}
						placeholder="Search by Name"
						value={filterName}
						allowClear
						onChange={(e) => SetFilterName(e.target.value)}
					/></div>
				</Col>
				</Row>
				<Row style={{ marginBottom: 16 ,paddingTop:"1rem",
							backgroundColor: "#f6f6f6",
							paddingBottom: "5px"}}>
				<Col span={24} style={{textAlign:"center"}}>
					<Pagination
						key="pagination"
						defaultCurrent={rolesData?.getRoles?.pageInfo.currentPage}
						current={rolesData?.getRoles?.pageInfo.currentPage}
						pageSize={pagination.pageSize}
						total={rolesData?.getRoles?.pageInfo.totalCount}
						showTotal={(total) => `Total ${total} items`}
						onChange={(page, pageSize) => {
							setPagination({
								pageNumber: page,
								pageSize: pageSize ? +pageSize : pagination.pageSize,
							});
						}}
						showSizeChanger
					/>
				</Col>
			</Row>

			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}

			<Table
				className="gx-table-responsive"
				columns={columns}
				dataSource={rolesData?.getRoles?.data}
				pagination={false}
			/>

			<Modal
				title={'Delete'}
				visible={isDeleteModalVisible}
				onOk={handleDeleteRole}
				onCancel={() => setIsDeleteModalVisible(false)}
				width={300}
			>
				Delete Role?
				{/* Delete */}
				{deleteAlert &&  !loadingMtD && <Alert message="Deleted" type="success" showIcon />}
				{loadingMtD && <Alert message="Deleting..." type="info" showIcon />}
				{errorMtD && (
					<Alert
						message={`Submission error! ${errorMtD.message}`}
						type="error"
						showIcon
					/>
				)}
			</Modal>

			{/* Add Role Modal */}
			<Modal
				// title={!input ? 'Add Coupon' : 'Edit'}
				title={'Add Role'}
				visible={isRoleModalVisible}
				// onOk={form2.submit}
				onCancel={handleCloseAddRoleModal}
				width={activePage === 1 ? 600 : 800}
				footer={null}
			>
				{/* Creating */}
				{showRolesAddSuccessAlert && (
					<Alert message="Created" type="success" showIcon />
				)}
				{loadingRoleMt && (
					<Alert message="Submitting..." type="info" showIcon />
				)}
				{errorRoleMt && (
					<Alert
						message={`Submission error! ${errorRoleMt.message}`}
						type="error"
						showIcon
					/>
				)}

				{/* Updating */}
				{/* {dataMtU && <Alert message="Updated" type="success" showIcon />}
				{loadingMtU && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtU && ( */}
				{/* <Alert
						message={`Submission error! `}
						type="error"
						showIcon
					/> */}
				{/* // )} */}

				<Form
					form={form2}
					onFinish={onCreateRoleFinish}
					// scrollToFirstError
					// initialValues={
					// 	!input
					// 		? {}
					// 		: {
					// 				...input,
					// 				startDate: moment(input.startDate),
					// 				endDate: moment(input.endDate),
					// 		  }
					// }
				>
					{/* Page 1 */}
					{activePage === 1 && (
						<>
							<Row justify="center">
								{/* <Col span={12}>
							<Form.Item
								name={`maxUsePerCustomer`}
								label={<span>Max redemption per customer</span>}
								rules={[
									{
										type: 'number',
										required: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col> */}
								<Col span={12}>
									<Form.Item
										name="name"
										label={<span>Name</span>}
										rules={[
											{
												required: true,
												message: 'Please input name',
												whitespace: true,
											},
										]}
										style={{ textAlign: 'center', marginLeft: '-8rem' }}
									>
										<Input
											onChange={(e) =>
												setAddValues({ ...addValues, name: e.target.value })
											}
										/>
									</Form.Item>
								</Col>

								{/* {rightsOfAdminRole?.map((right, index) => (
							<React.Fragment key={right.name}>
							<Col span={3}></Col>
							<Col span={21}>
							  <Form.Item
								name={`right${index}`}
								valuePropName="checked"
								label={right.name}
							  >								
								<Switch
								  onChange={(checked) => handleRightsSelection(right.id, checked)}
								  defaultChecked={selectedAdmin?.rights?.find((i) => i.id+'' == right.id+'')}
								/>
							  </Form.Item>
							</Col>
						  </React.Fragment>
						))} */}
							</Row>

							{/* Next button */}
							<div style={{ textAlign: 'right' }}>
								<Button type="primary" onClick={handleNext}>
									Next
								</Button>
							</div>
						</>
					)}

					{/* Page 2 */}
					{activePage === 2 && (
						<>
							{/* ... Page 2 content ... */}

							{/* NEW LAYOUT STARTS */}
							<Radio.Group
								className="gx-radio-group-link gx-radio-group-link-news"
								// defaultValue={news}
								onChange={handleMainPageRadio}
								defaultValue={
									Array.isArray(mainPagesRights) &&
									mainPagesRights.length &&
									mainPagesRights[0]?.id
								}
							>
								{mainPagesRights?.map((x, i) => (
									<Radio.Button key={i} value={x.id} className="gx-mb-1">
										{x.name.charAt(0).toUpperCase() + x.name.slice(1)}
									</Radio.Button>
								))}
							</Radio.Group>

							<Row justify="center">
								<Col span={14}>
									<Form.Item
										// validateStatus={editRightsValidation ? 'error' : ''}
										// help={
										// 	editRightsValidation
										// 		? 'Please select at least one right'
										// 		: ''
										// }
										style={{ marginBottom: 0 }}
									>
										<br />
										{selectedMainPageRight &&
											!subPagesRightsOfSelectedMainPage.length && (
												<Row
													key={selectedMainPageRight?.name}
													justify="center"
													align="middle"
												>
													<Col span={18}>
														{/* <Form.Item
												name={`right1`}
												valuePropName="checked"
												label={
													<h4
														style={{ display: 'inline', marginRight: '1rem' }}
													>
														{selectedMainPageRight?.name} -
													</h4>
												}
												style={{ marginBottom: 0 }}
											> */}
														{/* <h6>{right.name} -</h6> */}
														<Form.Item
															labelAlign="right"
															name={`right${selectedMainPageRight.id}`}
															valuePropName="checked"
															label={
																<h4
																	style={{
																		display: 'inline',
																		marginRight: '0.5rem',
																		marginTop: '6px',
																	}}
																>
																	{selectedMainPageRight.name
																		.charAt(0)
																		.toUpperCase() +
																		selectedMainPageRight.name.slice(1)}
																</h4>
															}
															style={{ marginBottom: 0 }}
														>
															{/* <span style={{ marginLeft: '1rem' }}>
														{`${selectedMainPageRight.name}:      `}
													</span> */}
															{''}
															<Switch
																onChange={(checked) =>
																	handleRightsSelection(
																		selectedMainPageRight?.id,
																		checked,
																		null,
																	)
																}
																checked={addRights.includes(
																	selectedMainPageRight?.id,
																)}
															/>
														</Form.Item>
														{/* </Form.Item> */}
													</Col>
												</Row>
											)}

										{subPagesRightsOfSelectedMainPage.length != 0 &&
											subPagesRightsOfSelectedMainPage.map((right, index) => (
												<Row key={right.name} justify="center" align="middle">
													<Col span={20}>
														<Form.Item
															name={`right${index}`}
															valuePropName="checked"
															label={
																<h4
																	style={{
																		display: 'inline',
																		marginRight: '0.5rem',
																		marginTop: '6px',
																	}}
																>
																	{right.name.charAt(0).toUpperCase() +
																		right.name.slice(1)}
																</h4>
															}
															style={{ marginBottom: 0 }}
														>
															{/* <h6>{right.name} -</h6> */}

															{rightsOfPageOrSubpage?.map(
																(x, i) =>
																	x.parentId == right.id && (
																		<Form.Item
																			labelAlign="right"
																			name={`right${index}`}
																			valuePropName="checked"
																			// label={right.name}
																			style={{ marginBottom: 0 }}
																			key={i}
																		>
																			<div
																				key={i}
																				style={{
																					display: 'flex',
																					alignItems: 'center',
																				}}
																			>
																				<Switch
																					onChange={(checked) =>
																						handleRightsSelection(
																							x.id,
																							checked,
																							right.id,
																						)
																					}
																					checked={addRights.includes(x.id)}
																					style={{
																						transform: 'scale(0.8)',
																						marginLeft: '1rem',
																					}}
																				/>
																				<span style={{ marginLeft: '4px' }}>
																					{x.name.charAt(0).toUpperCase() +
																						x.name.slice(1)}
																				</span>
																			</div>
																		</Form.Item>
																	),
															)}

															{!rightsOfPageOrSubpage.find(
																(k) => k.parentId == right.id,
															) && (
																<Switch
																	onChange={(checked) =>
																		handleRightsSelection(
																			right.id,
																			checked,
																			selectedMainPageRight.id,
																		)
																	}
																	checked={addRights.includes(right.id)}
																	style={{
																		transform: 'scale(0.8)',
																	}}
																/>
															)}
														</Form.Item>
													</Col>
												</Row>
											))}
									</Form.Item>
								</Col>
							</Row>
							{/* NEW LAYOUT ENDS */}

							{/* OLD LAYOUT STARTS */}
							{/* <Form.Item
								// rules={[
								//     {
								//         required: true,
								//         message: 'Please input name',
								//         whitespace: true,
								//     },
								// ]}
								validateStatus={addRightsValidation ? 'error' : ''}
								help={
									addRightsValidation ? 'Please select at least one right' : ''
								}
								style={{ marginBottom: 0 }}
							>
								{rightsData?.getRights?.map((right, index) => (
									<Row key={right.name} justify="center" align="middle">
										<Col span={12}>
											<Form.Item
												labelAlign="left"
												name={`right${index}`}
												valuePropName="checked"
												// label={right.name}
												style={{ marginBottom: 0 }}
											>
												<Switch
													onChange={(checked) =>
														handleRightsSelection(right.id, checked)
													}
													defaultChecked={selectedAdmin?.rights?.find(
														(i) => i.id + '' === right.id + '',
													)}
												/>
												<span style={{ marginLeft: '1rem' }}>{right.name}</span>
											</Form.Item>
										</Col>
									</Row>
								))}
							</Form.Item> */}
							{/* OLD LAYOUT ENDS */}

							{/* Back and Submit buttons */}
							<div style={{ textAlign: 'right' }}>
								<Button style={{ marginRight: 10 }} onClick={handleBack}>
									Back
								</Button>
								<Button type="primary" htmlType="submit">
									Submit
								</Button>
							</div>
						</>
					)}
				</Form>
			</Modal>

			{/* Edit Role Modal */}
			<Modal
				// title={!input ? 'Add Coupon' : 'Edit'}
				title={'Edit Role'}
				visible={isEditRoleModalVisible}
				// onOk={form2.submit}
				onCancel={handleCloseEditRoleModal}
				width={600}
				footer={null}
			>
				{/* Creating */}
				{showEditRole2Alert && (
					<Alert message="Updated" type="success" showIcon />
				)}
				{loadingEditRoleMt && (
					<Alert message="Submitting..." type="info" showIcon />
				)}
				{errorEditRoleMt && (
					<Alert
						message={`Submission error! ${errorEditRoleMt.message}`}
						type="error"
						showIcon
					/>
				)}

				{/* Updating */}
				{/* {dataMtU && <Alert message="Updated" type="success" showIcon />}
				{loadingMtU && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtU && ( */}
				{/* <Alert
						message={`Submission error! `}
						type="error"
						showIcon
					/> */}
				{/* // )} */}

				{selectedRole?.name && (
					<Form
						form={form3}
						onFinish={onEditRoleFinish}
						// scrollToFirstError
						// initialValues={
						// 	!input
						// 		? {}
						// 		: {
						// 				...input,
						// 				startDate: moment(input.startDate),
						// 				endDate: moment(input.endDate),
						// 		  }
						// }
					>
						{/* Page 1 */}
						<>
							<Row justify="center">
								<Col span={12}>
									<Form.Item
										name="name"
										label={<span>Name</span>}
										rules={[
											{
												required: true,
												message: 'Please input name',
												whitespace: true,
											},
										]}
										style={{ textAlign: 'center', marginLeft: '-8rem' }}
									>
										<Input
											onChange={(e) =>
												setEditValues({ ...editValues, name: e.target.value })
											}
											// value={selectedRole.name}
										/>
									</Form.Item>
									{/* <Button onClick={() => settestRole('i changed' )}>change state</Button> */}
								</Col>
							</Row>

							<div style={{ textAlign: 'right' }}>
								<Button type="primary" htmlType="submit">
									Submit
								</Button>
							</div>
						</>
					</Form>
				)}
			</Modal>

			{/* Manage Rights Modal */}

			{/* OLD LAYOUT STARTS */}
			<Modal
				// title={!input ? 'Add Coupon' : 'Edit'}
				title={'Edit Rights'}
				visible={isManageRightsModalVisible}
				// onOk={form2.submit}
				onCancel={handleCloseManageRightsModal}
				width={800}
				footer={null}
			>
				{showEditRole2Alert && (
					<Alert message="Created" type="success" showIcon />
				)}
				{loadingEditRoleMt && (
					<Alert message="Submitting..." type="info" showIcon />
				)}
				{errorEditRoleMt && (
					<Alert
						message={`Submission error! ${errorEditRoleMt.message}`}
						type="error"
						showIcon
					/>
				)}

				{selectedRole?.name && (
					<Form form={form4} onFinish={onEditRoleFinish}>
						<>
							{/* NEW LAYOUT STARTS */}
							<Radio.Group
								className="gx-radio-group-link gx-radio-group-link-news"
								// defaultValue={news}
								onChange={handleMainPageRadio}
								defaultValue={
									Array.isArray(mainPagesRights) &&
									mainPagesRights.length &&
									mainPagesRights[0]?.id
								}
								
								value={selectedMainPageRight?.id}
							>
								{mainPagesRights?.map((x, i) => (
									<Radio.Button key={i} value={x.id} className="gx-mb-1">
										{x.name.charAt(0).toUpperCase() + x.name.slice(1)}
									</Radio.Button>
								))}
							</Radio.Group>

							<Row justify="center">
								<Col span={14}>
									<Form.Item
										// validateStatus={editRightsValidation ? 'error' : ''}
										// help={
										// 	editRightsValidation
										// 		? 'Please select at least one right'
										// 		: ''
										// }
										style={{ marginBottom: 0 }}
									>
										<br />
										{selectedMainPageRight &&
											!subPagesRightsOfSelectedMainPage.length && (
												<Row
													key={selectedMainPageRight?.name}
													justify="center"
													align="middle"
												>
													<Col span={18}>
														{/* <Form.Item
												name={`right1`}
												valuePropName="checked"
												label={
													<h4
														style={{ display: 'inline', marginRight: '1rem' }}
													>
														{selectedMainPageRight?.name} -
													</h4>
												}
												style={{ marginBottom: 0 }}
											> */}
														{/* <h6>{right.name} -</h6> */}
														<Form.Item
															labelAlign="right"
															name={`right${selectedMainPageRight.id}`}
															valuePropName="checked"
															label={
																<h4
																	style={{
																		display: 'inline',
																		marginRight: '0.5rem',
																		marginTop: '6px',
																	}}
																>
																	{selectedMainPageRight.name
																		.charAt(0)
																		.toUpperCase() +
																		selectedMainPageRight.name.slice(1)}
																</h4>
															}
															style={{ marginBottom: 0 }}
														>
															{/* <span style={{ marginLeft: '1rem' }}>
														{`${selectedMainPageRight.name}:      `}
													</span> */}
															{''}
															<Switch
																onChange={(checked) =>
																	handleEditRightsSelection(
																		selectedMainPageRight,
																		checked,
																		null,
																	)
																}
																checked={editRights.find(
																	(i) =>
																		i.id + '' == selectedMainPageRight?.id + '',
																)}
																style={{
																	transform: 'scale(0.8)',
																}}
															/>
														</Form.Item>
														{/* </Form.Item> */}
													</Col>
												</Row>
											)}

										{subPagesRightsOfSelectedMainPage.length != 0 &&
											subPagesRightsOfSelectedMainPage.map((right, index) => (
												<Row key={right.name} justify="center" align="middle">
													<Col span={20}>
														<Form.Item
															name={`right${index}`}
															valuePropName="checked"
															label={
																<h4
																	style={{
																		display: 'inline',
																		marginRight: '0.5rem',
																		marginTop: '6px',
																	}}
																>
																	{right.name.charAt(0).toUpperCase() +
																		right.name.slice(1)}
																</h4>
															}
															style={{ marginBottom: 0 }}
														>
															{/* <h6>{right.name} -</h6> */}

															{rightsOfPageOrSubpage?.map(
																(x, i) =>
																	x.parentId == right.id && (
																		<Form.Item
																			labelAlign="right"
																			name={`right${index}`}
																			valuePropName="checked"
																			// label={right.name}
																			style={{ marginBottom: 0 }}
																			key={i}
																		>
																			<Switch
																				onChange={(checked) =>
																					handleEditRightsSelection(
																						x,
																						checked,
																						right.id,
																					)
																				}
																				checked={editRights.find(
																					(i) => i.id + '' == x.id + '',
																				)}
																				style={{
																					transform: 'scale(0.8)',
																					marginLeft: '1rem',
																				}}
																			/>
																			<span style={{ marginLeft: '4px' }}>
																				{x.name.charAt(0).toUpperCase() +
																					x.name.slice(1)}
																			</span>
																		</Form.Item>
																	),
															)}

															{!rightsOfPageOrSubpage.find(
																(k) => k.parentId == right.id,
															) && (
																<Switch
																	onChange={(checked) =>
																		handleEditRightsSelection(
																			right,
																			checked,
																			selectedMainPageRight.id,
																		)
																	}
																	checked={editRights.find(
																		(i) => i.id + '' == right.id + '',
																	)}
																	style={{
																		transform: 'scale(0.8)',
																	}}
																/>
															)}
														</Form.Item>
													</Col>
												</Row>
											))}
									</Form.Item>
								</Col>
							</Row>
							{/* NEW LAYOUT ENDS */}

							{/* OLD LAYOUT STARTS */}
							{/* <Row justify="center">
								<Col span={12}>
									<Form.Item
										validateStatus={editRightsValidation ? 'error' : ''}
										help={
											editRightsValidation
												? 'Please select at least one right'
												: ''
										}
										style={{ marginBottom: 0 }}
									>
										{rightsData?.getRights?.map((right, index) => (
											<Row key={right.name} justify="center" align="middle">
												<Col span={12}>
													<Form.Item
														labelAlign="left"
														name={`right${index}`}
														valuePropName="checked"
														// label={right.name}
														style={{ marginBottom: 0 }}
													>
														<Switch
															onChange={(checked) =>
																handleEditRightsSelection(right, checked)
															}
															checked={editRights?.find(
																(i) => i.id + '' === right.id + '',
															)}
														/>
														<span style={{ marginLeft: '1rem' }}>
															{right.name}
														</span>
													</Form.Item>
												</Col>
											</Row>
										))}
									</Form.Item>
								</Col>
							</Row> */}
							{/* OLD LAYOUT ENDS */}

							<div style={{ textAlign: 'right' }}>
								<Button type="primary" htmlType="submit">
									Submit
								</Button>
							</div>
						</>
					</Form>
				)}
			</Modal>
			{/* OLD LAYOUT ENDS */}
		</Card>
	);
};
export default Roles;
