import React ,{useState}from 'react';
import { Card, Col, Row,Select,Space  } from 'antd';

import {
	Area,
	AreaChart,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
} from 'recharts';
import { increamentData, lineData } from './data';
import ChartCard from 'src/components/dashboard/ChartCard';
import Portfolio from 'src/components/dashboard/Portfolio';
import BalanceHistory from 'src/components/dashboard/BalanceHistory';
import SendMoney from 'src/components/dashboard/SendMoney';
import RewardCard from 'src/components/dashboard/RewardCard';
import CurrencyCalculator from 'src/components/dashboard/CurrencyCalculator';
import CryptoNews from 'src/components/dashboard/CryptoNews';
import DownloadMobileApps from 'src/components/dashboard/DownloadMobileApps';
import OrderHistory from 'src/components/dashboard/OrderHistory';
import Revenue from './revenue';
import { useParams } from 'react-router-dom';
import {
	CMSGetUser,
	CMSGetUserVariables,
} from 'src/lib/gql/generated/CMSGetUser';
import { useQuery } from '@apollo/client';
import { CMSQueryGetUser } from 'src/lib/gql/queries/users';
import Payout from './payout';

const Reports = () => {
	const Option = Select.Option;
	const [selectValue,setSelectValue]=useState("Revenue")

		const handleChange = (key) => {
			setSelectValue(key)
		};
		const options = [];
		// for (let i = 10; i < 36; i++) {
		//   options.push({
		// 	label: i.toString(36) + i,
		// 	value: i.toString(36) + i,
		//   });
		// }

		const { id } = useParams<{ id: string }>();
		const { loading, data: qryData } = useQuery<CMSGetUser, CMSGetUserVariables>(
			CMSQueryGetUser,
			{
				variables: {
					id: id || '',
				},
			},
		);


	return (
		<>
			{/* Crypto */}
			<Row>
			<Col xl={24} lg={12} md={12} sm={12} xs={24}>
			<Card style={{borderRadius:"1rem", backgroundColor:"#fbf6ed"}}>		
				
				
				<div style={{textAlign:"center"}}>
					<span style={{fontSize:"20px"}}>Choose Report</span>{" "}
				<Select placeholder="Revenue Generation By Location/Service Type" onChange={handleChange}>
					<Option value="Revenue">Revenue Generation By Location/Service Type</Option>
					{/* <Option value="Amount">Amount Paid Out To Service Partners</Option> */}
				</Select>
				</div>
			</Card>
			</Col>
			</Row>
			<br/>
			


			{selectValue ==="Revenue" ?(
			<>
			<Revenue />
			</>
			// 	<Row>

			// 	<Col xl={6} lg={12} md={12} sm={12} xs={24}>
			// 		<ChartCard
			// 			prize="₹9,626"
			// 			title="23"
			// 			icon="bitcoin"
			// 			styleName="up"
			// 			desc="Description"
			// 		>
			// 			<ResponsiveContainer width="100%" height={75}>
			// 				<AreaChart
			// 					data={increamentData}
			// 					margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
			// 				>
			// 					<Tooltip />
			// 					<defs>
			// 						<linearGradient id="color3" x1="0" y1="0" x2="1" y2="0">
			// 							<stop offset="5%" stopColor="#163469" stopOpacity={0.9} />
			// 							<stop offset="95%" stopColor="#FE9E15" stopOpacity={0.9} />
			// 						</linearGradient>
			// 					</defs>
			// 					<Area
			// 						dataKey="price"
			// 						strokeWidth={0}
			// 						stackId="2"
			// 						stroke="#4D95F3"
			// 						fill="url(#color3)"
			// 						fillOpacity={1}
			// 					/>
			// 				</AreaChart>
			// 			</ResponsiveContainer>
			// 		</ChartCard>
			// 	</Col>
			// 	<Col xl={6} lg={12} md={12} sm={12} xs={24}>
			// 		<ChartCard
			// 			prize="₹7,831"
			// 			title="07"
			// 			icon="etherium"
			// 			styleName="up"
			// 			desc="Description"
			// 		>
			// 			<ResponsiveContainer width="100%" height={75}>
			// 				<AreaChart
			// 					data={increamentData}
			// 					margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
			// 				>
			// 					<Tooltip />
			// 					<defs>
			// 						<linearGradient id="color4" x1="0" y1="0" x2="1" y2="0">
			// 							<stop offset="5%" stopColor="#4ECDE4" stopOpacity={0.9} />
			// 							<stop offset="95%" stopColor="#06BB8A" stopOpacity={0.9} />
			// 						</linearGradient>
			// 					</defs>
			// 					<Area
			// 						dataKey="price"
			// 						type="monotone"
			// 						strokeWidth={0}
			// 						stackId="2"
			// 						stroke="#4D95F3"
			// 						fill="url(#color4)"
			// 						fillOpacity={1}
			// 					/>
			// 				</AreaChart>
			// 			</ResponsiveContainer>
			// 		</ChartCard>
			// 	</Col>
			// 	<Col xl={6} lg={12} md={12} sm={12} xs={24}>
			// 		<ChartCard
			// 			prize="₹1,239"
			// 			title="08"
			// 			icon="ripple"
			// 			styleName="down"
			// 			desc="Description"
			// 		>
			// 			<ResponsiveContainer width="100%" height={75}>
			// 				<AreaChart
			// 					data={increamentData}
			// 					margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
			// 				>
			// 					<Tooltip />
			// 					<defs>
			// 						<linearGradient id="color5" x1="0" y1="0" x2="0" y2="1">
			// 							<stop offset="5%" stopColor="#e81a24" stopOpacity={0.8} />
			// 							<stop offset="95%" stopColor="#FEEADA" stopOpacity={0.8} />
			// 						</linearGradient>
			// 					</defs>
			// 					<Area
			// 						dataKey="price"
			// 						strokeWidth={0}
			// 						stackId="2"
			// 						stroke="#FEEADA"
			// 						fill="url(#color5)"
			// 						fillOpacity={1}
			// 					/>
			// 				</AreaChart>
			// 			</ResponsiveContainer>
			// 		</ChartCard>
			// 	</Col>
			// 	<Col xl={6} lg={12} md={12} sm={12} xs={24}>
			// 		<ChartCard
			// 			prize="₹849"
			// 			title="47"
			// 			icon="litcoin"
			// 			styleName="down"
			// 			desc="Description"
			// 		>
			// 			<ResponsiveContainer width="100%" height={75}>  
			// 				<LineChart
			// 					data={lineData}
			// 					margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
			// 				>
			// 					<Tooltip />
			// 					<Line
			// 						dataKey="price"
			// 						stroke="#038FDE"
			// 						dot={{ stroke: '#FEA931', strokeWidth: 2 }}
			// 					/>
			// 				</LineChart>
			// 			</ResponsiveContainer>
			// 		</ChartCard>
			// 	</Col>
			// 	<Col xl={12} lg={24} md={12} sm={24} xs={24}>
			// 		<Portfolio />
			// 	</Col>
			// 	<Col xl={12} lg={24} md={12} sm={24} xs={24}>
			// 		<BalanceHistory />
			// 	</Col>

			// 	<Col xl={9} lg={24} md={24} sm={24} xs={24}>
			// 		<SendMoney />
			// 	</Col>
			// 	<Col xl={6} lg={12} md={12} sm={24} xs={24}>
			// 		<RewardCard />
			// 	</Col>
			// 	<Col xl={9} lg={12} md={12} sm={24} xs={24}>
			// 		<CurrencyCalculator />
			// 	</Col>

			// 	<Col xl={15} lg={24} md={24} sm={24} xs={24}>
			// 		<CryptoNews />
			// 	</Col>
			// 	<Col xl={9} lg={24} md={24} sm={24} xs={24}>
			// 		<DownloadMobileApps />
			// 		<OrderHistory />
			// 	</Col>
			// </Row>
	):(
		<Payout/>
	)}
			
			</>
	);
};

export default Reports;
