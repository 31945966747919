import { getApps, initializeApp } from 'firebase/app';
import React from 'react';
import { RecoilRoot } from 'recoil';
import { firebaseConfig } from 'src/lib/firebase/fbConfig';
interface Props {
	children?: React.ReactNode;
}

const RecoilHOC = (props: Props) => {
	if (getApps().length === 0) initializeApp(firebaseConfig);
	return <RecoilRoot>{props.children}</RecoilRoot>;
};

export default RecoilHOC;
