import { useMutation } from '@apollo/client';
import { Alert, Button, Col, Form, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import {
	CMSCreatePartnerCompany,
	CMSCreatePartnerCompanyVariables,
} from 'src/lib/gql/generated/CMSCreatePartnerCompany';
import { CMSGetPartnerCompanies } from 'src/lib/gql/generated/CMSGetPartnerCompanies';
import { CREATE_PARTNER_COMPANY } from 'src/lib/gql/mutations/mutations';

interface Props {
	input?: CMSGetPartnerCompanies | null;
	onFinishAdd?: () => void;
}

const UpdatePartnerCompanies: React.FC<Props> = ({ input, onFinishAdd }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [search, setSearch] = useState('');

	// Create
	const [create, { data: dataMtU, loading: loadingMtU, error: errorMtU }] =
		useMutation<CMSCreatePartnerCompany, CMSCreatePartnerCompanyVariables>(
			CREATE_PARTNER_COMPANY,
		);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
		setIsModalVisible(false);
	};

	const [form] = Form.useForm();

	const onFinish = (values) => {
		create({
			variables: {
				name: values.name,
				address: values.address,
			},
		}).then(() => {
			form.resetFields();
			setTimeout(() => {
				if (typeof onFinishAdd === 'function') {
					onFinishAdd();
				}
				setIsModalVisible(false);
			}, 1500);
		});
	};

	return (
		<>
			<Button type="primary" onClick={showModal}>
				Add Partner Company
			</Button>
			<Modal
				title={'Add Partner Company '}
				visible={isModalVisible}
				onOk={handleOk}
				okText="Create"
				onCancel={handleCancel}
				width={window.screen.width * 0.5}
			>
				{/* Updating */}
				{dataMtU && <Alert message="Updated" type="success" showIcon />}
				{loadingMtU && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtU && (
					<Alert
						message={`Submission error! ${errorMtU.message}`}
						type="error"
						showIcon
					/>
				)}

				{/* <Input
					placeholder="Search by name or code"
					onChange={(e) => setSearch(e.target.value)}
				/> */}

				<Row>
					<Form
						form={form}
						onFinish={onFinish}
						scrollToFirstError
						style={{
							maxHeight: '60vh',
							width: '100%',
							overflowY: 'auto',
							overflowX: 'hidden',
						}}
					>
						<Col span={12}>
							<Form.Item
								name={`name`}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<Input placeholder="Company Name" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name={`address`}>
								<Input placeholder="Address" />
							</Form.Item>
						</Col>
					</Form>
				</Row>
			</Modal>
		</>
	);
};
export default UpdatePartnerCompanies;
