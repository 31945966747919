import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Card, Col, Modal, Row } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UpdateHomepageBanner from 'src/components/manage/UpdateHomepageBanner';
import {
	CMSDeleteBanner,
	CMSDeleteBannerVariables,
} from 'src/lib/gql/generated/CMSDeleteBanner';
import {
	CMSGetCmsBanners,
	CMSGetCmsBannersVariables,
} from 'src/lib/gql/generated/CMSGetCmsBanners';
import { DELETE_BANNER } from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetBanners } from 'src/lib/gql/queries/cms';
import {  CaretLeftOutlined } from '@ant-design/icons';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { userAtom } from 'src/lib/recoil/userCoil';
import { useRecoilValue } from 'recoil';

const HomepageBanners: React.FC = () => {
	const userAuth = useRecoilValue(userAtom);

	// Fetch queries
	// const {
	// 	loading,
	// 	data: qryData,
	// 	called,
	// 	error,
	// 	refetch: refetchQryData,
	// } = useQuery<CMSGetCmsBanners, CMSGetCmsBannersVariables>(
	// 	CMSQueryGetBanners,
	// 	{
	// 		variables: {
	// 			getAll: true,
	// 		},
	// 	},
	// );

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<
		string | null
	>(null);
	// Delete
	const [
		deleteBanner,
		{ data: dataMtD, loading: loadingMtD, error: errorMtD },
	] = useMutation<CMSDeleteBanner, CMSDeleteBannerVariables>(DELETE_BANNER);
	
	
	const onDelete = () => {
		if (!isDeleteModalVisible) return;

		deleteBanner({
			variables: {
				id: isDeleteModalVisible,
			},
		}).then(() => {
			// refetchQryData();

			setTimeout(() => {
				setIsDeleteModalVisible(null);
			}, 1500);
		});
	};

	const navigate = useNavigate();

	return (
		// <Card
		
		// title={
		// 	<div>
		// 	  <Button className="gx-mr-4" onClick={() => navigate(-1)} style={{margin:"0"}}>
		// 	  <CaretLeftOutlined />
		// 	  </Button>
		// 	  Manage homepage banners
			  
		// 	</div>
		//   }
		
		// >
		// 	<div className="gx-flex gx-flex-nowrap gx-mb-2">
		// 		{/* <div className="gx-card-profile">
		// 			<div className="ant-card-head">
		// 				<Button className="gx-mr-4" onClick={() => navigate(-1)}>
		// 					Back
		// 				</Button>
		// 			</div>

		// 			<div className="gx-pt-md-3"></div>
		// 		</div> */}

		// 		<Row>
		// 			<Col span={20} className="gx-mb-4">
		// 				{/* <h1>Manage homepage Top banners</h1> */}
		// 				{/* <hr /> */}
		// 			</Col>
		// 			{userAuth?.userType === UserTypeEnum.ADMIN ||
		// 							userAuth?.rights?.find(
		// 								(x) => x.type === 'sub2' && x.name === 'homepage-banners-add',
		// 							) ? (
		// 			<Col span={2}>
		// 				<UpdateHomepageBanner />
		// 			</Col>):("")}

		// 			{qryData?.getBanners?.map((banner) => {
		// 				return (
		// 					<Col span={12} key={banner.id}>

		// 								{userAuth?.userType === UserTypeEnum.ADMIN ||
		// 								userAuth?.rights?.find(
		// 									(x) => x.type === 'sub2' && x.name === 'homepage-banners-add',
		// 								) ? ( 
		// 						<Card
		// 							cover={
		// 								<img
		// 									alt="example"
		// 									src={`https://staging.api.zimkey.in/media-upload/${banner?.media?.url}`}
		// 								/>
		// 							}
		// 							actions={[
		// 								<Button
		// 									key="delete"
		// 									type="text"
		// 									onClick={() => setIsDeleteModalVisible(banner.id)}
		// 									danger
		// 								>
		// 									<DeleteOutlined />
		// 								</Button>,
		// 								<a
		// 									key="link"
		// 									href={banner?.url ?? ''}
		// 									target="_blank"
		// 									rel="noreferrer"
		// 								>
		// 									<LinkOutlined />
		// 								</a>,							
		// 							]}
		// 						>
		// 							<Meta title={banner.title} description={banner.description} />
		// 							<br />

		// 							<p>{banner?.url}</p>
		// 						</Card>

		// 								):(	
		// 								<Card
		// 									cover={
		// 										<img
		// 											alt="example"
		// 											src={`https://staging.api.zimkey.in/media-upload/${banner?.media?.url}`}
		// 										/>
		// 									}
		
											
										
		// 									actions={[
		// 										<Button
		// 										disabled
		// 											key="delete"
		// 											type="text"
		// 											// onClick={() => setIsDeleteModalVisible(banner.id)}
		// 											danger
		// 										>
		// 											<DeleteOutlined />
		// 										</Button>,
		// 										<a
		// 											key="link"
		// 											href={banner?.url ?? ''}
		// 											target="_blank"
		// 											rel="noreferrer"
		// 										>
		// 											<LinkOutlined />
		// 										</a>,							
		// 									]}
		// 								>
		// 									<Meta title={banner.title} description={banner.description} />
		// 									<br />
		
		// 									<p>{banner?.url}</p>
		// 								</Card>)}
		// 					</Col>
		// 				);
		// 			})}
		// 			{qryData?.getBanners?.length === 0 && (
		// 				<Col span={24}>
		// 					<Card>
		// 						<Meta
		// 							title="No Banners"
		// 							description="Please create new banners"
		// 						/>
		// 					</Card>
		// 				</Col>
		// 			)}

		// 			<Modal
		// 				title={'Delete'}
		// 				visible={isDeleteModalVisible !== null}
		// 				onOk={onDelete}
		// 				onCancel={() => setIsDeleteModalVisible(null)}
		// 				width={300}
		// 			>
		// 				Delete Banner?
		// 				{/* Delete */}
		// 				{dataMtD && <Alert message="Deleted" type="success" showIcon />}
		// 				{loadingMtD && <Alert message="Deleting..." type="info" showIcon />}
		// 				{errorMtD && (
		// 					<Alert
		// 						message={`Submission error! ${errorMtD.message}`}
		// 						type="error"
		// 						showIcon
		// 					/>
		// 				)}
		// 			</Modal>
		// 		</Row>
		// 	</div>
		// </Card><h1>
		<h1>Nothing</h1>
	);
};
export default HomepageBanners;
