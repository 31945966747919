import { getApp } from '@firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { messaging } from './firebase';
import { getToken } from "firebase/messaging";

export const getTokenOld = async (): Promise<string | null> => {
	const app = getApp();
	const auth = getAuth(app);
	if (auth) {
		const user = auth.currentUser;
		if (user) {
			try {
				const token = await user.getIdToken(false);
				return token;
			} catch {
				return null;
			}
		}
	}
	return null;
};

export const watchAuth = (updateFn) => {
	const app = getApp();
	const auth = getAuth(app);

	const usrDta = localStorage.getItem('zk_usr_dta')

	if (usrDta) {
		updateFn(usrDta);
	} else {
		updateFn(null);
	}

	// auth.onAuthStateChanged(async (user) => {
	// 	if (user) {
	// 		const token = await getToken();
	// 		updateFn(token);
	// 	} else {
	// 		updateFn(null);
	// 	}
	// });
};

export const watchToken = (updateFn) => {
	const app = getApp();
	const auth = getAuth(app);

	const acctkn = localStorage.getItem('zk_acc_tkn')

	if (acctkn) {
		updateFn(acctkn);
	} else {
		updateFn(null);
	}

	// if (auth)
	// 	auth.onIdTokenChanged(async (user) => {
	// 		if (user) {
	// 			const token = await getToken();
	// 			updateFn(token);
	// 		} else {
	// 			updateFn(null);
	// 		}
	// 	});
};

export const logoutUser = async (updateFn) => {
	const auth = getAuth();
	await auth.signOut();
	updateFn(null);
};

export const loginUser = async (
	credentials: {
		email: string;
		password: string;
	},
	updateFn,
	adminLogin: any,
) => {
	const auth = getAuth();
	const data = await signInWithEmailAndPassword(
		auth,
		credentials.email,
		credentials.password,
	);

	// console.log('this is data from fb fn',data)
	// console.log('get id token()',await data.user?.getIdToken())

	// const data2 = await adminLogin({
	// 	variables: {
	// 		data: {
	// 			email: credentials.email,
	// 			password: credentials.password,
	// 		},
	// 	},
	// });

	// console.log('mudata',data2)
	// console.log(
	// 	'data2.data.adminLogin.data.token: ',
	// 	data2.data.adminLogin.data.token,
	// );

	// localStorage.setItem('zk_acc_tkn', data2.data.adminLogin.data.token);

	// .then((data) => {
	// 	console.log('logged in');
	// 	console.log('adam--->', data.data.adminLogin.data.token);
	// 	updateFn(data.data.adminLogin.data.token)
	// 	// setUserToken({id: 'testid', name: 'testname'})

	// 	navigate('/');
	// }).catch((err) => {
	// 	console.log('adminLgingg errrror',err)
	// 	throw new Error()
	// })

	// const token = data2.data.adminLogin.data.token;
	// console.log('thankan', token);

	// localStorage.setItem('zk_acc_tkn', token);

	// updateFn(data2.data.adminLogin.data.token || null);

	updateFn((await data.user?.getIdToken()) || null);
};
