import { useQuery, useMutation } from '@apollo/client';
import { Col, Switch, Button, Modal, Badge, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import About from 'src/components/profile/about/index';
import Counts from 'src/components/profile/counts';
import Contact from 'src/components/profile/contact/index';
import Events from 'src/components/profile/events/index';
import ProfileHeader from 'src/components/profile/profileHeader/index';
import {
	CMSGetUser,
	CMSGetUserVariables,
} from 'src/lib/gql/generated/CMSGetUser';
import { CMSQueryGetUser } from 'src/lib/gql/queries/users';
import Widget from 'src/components/widget';
import { Radio } from 'antd';
import {
	allNews,
	bitCoinNews,
	lightCoinNews,
	rippleNews,
} from '../reports/data';
import CircularProgress from 'src/components/CircularProgress';
import CryptoNewsItem from 'src/components/dashboard/CryptoNewsItem';
import BookingDetails from './bookingDetails';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { UPDATE_DISABLE_CUSTOMER_ACCOUNT } from 'src/lib/gql/mutations/mutations';
import { useNavigate } from 'react-router-dom';
import { CaretLeftOutlined, SettingOutlined } from '@ant-design/icons';
import { MailOutlined, MobileOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'src/lib/recoil/userCoil';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';

export const friendList = [
	{
		id: 1,
		image: 'https://via.placeholder.com/150',
		name: 'Electrician',
		status: 'online',
	},
	{
		id: 2,
		image: 'https://via.placeholder.com/150',
		name: 'Plumber',
		status: 'offline',
	},
	{
		id: 3,
		image: 'https://via.placeholder.com/150',
		name: 'Cleaning',
		status: 'away',
	},
	{
		id: 4,
		image: 'https://via.placeholder.com/150',
		name: 'Cleaning',
		status: 'away',
	},
];

//Dummy data end

const UserDetail: React.FC = () => {

	const userAuth = useRecoilValue(userAtom);

	const { id } = useParams<{ id: string }>();
	const { loading, data: qryData } = useQuery(
		CMSQueryGetUser,
		{
			variables: {
				id: id || '',
			},
		},
	);

	const navigate = useNavigate();

	// Update
	const [
		updateDetails,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation(
		UPDATE_DISABLE_CUSTOMER_ACCOUNT,
	);


	const aboutList = [
		// {
		// 	id: 2,
		// 	title: 'Birthday',
		// 	icon: 'birthday-new',
		// 	userList: '',
		// 	desc: [`${qryData?.getUser?.dob ? qryData?.getUser?.dob : ''}`],
		// },

		{
			id: 4,
			title: 'Lives in',
			icon: 'home',
			userList: '',
			desc: [
				`${qryData?.getUser?.customerDetails?.defaultAddress?.area
					? qryData?.getUser?.customerDetails?.defaultAddress?.area
					: ''
				}`,
			],
		},
	];

	const formatPhoneNumber = (phoneNumber) => {
		phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
		if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
		  return `+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`;
		} else {
		  return phoneNumber;
		}
	  };
	const inputPhoneNumber = qryData?.getUser?.phone
	const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);


	const contactList = [
		{
			id: 2,
			title: 'name',
			icon: 'name',
			desc: [`${qryData?.getUser?.name}`],
		},
		{
			id: 1,
			title: 'Email',
			icon: 'email',
			desc: [
				<span className="gx-link" key={1}>
					{qryData?.getUser?.email}
				</span>,
			],
		},
		{
			id: 3,
			title: 'Phone',
			icon: 'phone',
			desc: [`${qryData?.getUser?.phone}`],
		},

	];

	const partnerStats = [
		{
			label: 'Loyalty Balance',
			value: qryData?.getUser?.customerDetails?.rewardPointBalance ?? '...',
		},
	];
	const [dateTime, setDateTime] = useState("")

	useEffect(() => {
		if (qryData?.getUser?.createDateTime) {
			const datetime = moment(+qryData?.getUser?.createDateTime).format('MMMM Do YYYY, h:mm:ss a');
			setDateTime(datetime)
		}
	}, [qryData?.getUser?.createDateTime]);

	const newsData = [allNews, bitCoinNews, lightCoinNews, rippleNews];

	const [news, setNews] = useState(0);
	const [loader, setLoader] = useState(false);

	const handleChange = (e) => {
		const value = e.target.value;
		setNews(e.target.value);
		setLoader(true);
		setTimeout(() => {
			setLoader(false);
		}, 1500);
	};

	const [result, setResult] = useState(false)
	const [proActivate, setProActivate] = useState(false)
	const [proDeactivate, setProDeactivate] = useState(false)
	const [value, setValue] = useState()
	const handleClick = (e) => {
		// setResult(true)
		setValue(e)
		setProDeactivate(true)

		setStatusCheck(e)

	}

	const [statusCheck, setStatusCheck] = useState<any>(false);

	useEffect(() => {
		setStatusCheck(qryData?.getUser?.customerDetails?.disableAccount)
	}, [qryData])

	const handleOk = () => {
		setStatusCheck(value)
		updateDetails({
			variables: {
				partnerId: qryData?.getUser?.id,
				status: statusCheck
			},
		})
		setResult(false);
		setProDeactivate(false)
	};

	const handleCancel = () => {
		setResult(false);
		setProDeactivate(false)
	};
	const editDetails = () => {
		setResult(true)
	}

	return (

		<>
			<div>
				<Row>
					<Col span={18} >

						<h2> <Button className="gx-mr-4" onClick={() => navigate(-1)} style={{ margin: "0" }}>
							<CaretLeftOutlined />
						</Button>
							Customer - {qryData?.getUser?.name}</h2>

					</Col>

					{userAuth?.userType === UserTypeEnum.ADMIN ||
						userAuth?.rights?.find(
							(x) => x.type === 'sub2' && x.name === 'settings',
						) ? (
						<Col span={6}>
							<div className="ant-card-head" style={{ textAlign: "right" }}>
								<Link to={""} onClick={editDetails}>
									<Button className="gx-mb-0 gx-mr-4" type="primary" style={{ borderRadius: "8px" }}>
										<SettingOutlined />
									</Button>
								</Link>
							</div>
						</Col>
					) : (
						<></>
					)}

				</Row>
				<Row>
					{qryData?.getUser?.email && (
						<>
							{/* <Col span={6} style={{marginRight:"-20px"}} > 	 */}
							<p style={{ paddingRight: "1rem", paddingLeft: "1rem" }}><MailOutlined />{" "}{qryData?.getUser?.email} </p>
							{/* </Col> */}|
						</>
					)}

					{qryData?.getUser?.phone && (
						<>
							{/* <Col span={5} style={{marginRight:"-50px"}} >  */}
							<p style={{ paddingRight: "1rem", paddingLeft: "1rem" }}><MobileOutlined />
							{/* {qryData?.getUser?.phone}  */}
							{formattedPhoneNumber}
							</p>
							{/* </Col> */}
							|</>
					)}
					{/* <Col span={6}  >  */}
					<p style={{ paddingLeft: "1rem" }}>Joined on {dateTime} </p>
					{/* </Col> */}
				</Row>
				<Widget>
					<div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-dash-search">

						<div className="gx-mx-sm-2">
							<Radio.Group
								className="gx-radio-group-link gx-radio-group-link-news"
								defaultValue={0}
								onChange={handleChange}
							>
								<Radio.Button value={0} className="gx-mb-1">
									Overview
								</Radio.Button>
								<Radio.Button value={1} className="gx-mb-1" >
									Bookings
								</Radio.Button>
							</Radio.Group>
						</div>
					</div>

					{loader && (
						<CircularProgress className="gx-loader-10" />
					)}
					{news === 0 ? (
						<Counts countInfo={qryData?.getUser} />
					) : (
						<BookingDetails bookingsData={qryData?.getUser?.bookings} />
					)}
				</Widget>

			</div>
			<br />
			{/* <div>
				<h2>Personal Details</h2>
			</div> */}
			{news === 0 && (
				<>
					{/* <Contact contactList={qryData?.getUser}   */}
					{/* // avatar={`https://staging.api.zimkey.in/media-upload/${qryData?.getUser?.partnerDetails?.photo?.url}`} */}
					{/* /> */}
					<br />
					<About aboutList={qryData?.getUser?.customerDetails} />
				</>
			)}
			{/* <ProfileHeader name={qryData?.getUser?.name} stats={partnerStats} />

			<div className="gx-profile-content">
				<Row>
					<Col xl={16} lg={14} md={14} sm={24} xs={24}>
						<About aboutList={aboutList} /> */}
			{/* <Biography/> */}


			{/* </Col> */}
			{/* <Row>
					<Col xl={24} lg={14} md={14} sm={24} xs={24}>
					<About aboutList={aboutList} />
					</Col>
					</Row> */}
			{/* 
					<Col xl={8} lg={10} md={10} sm={24} xs={24}>
						<Contact contactList={contactList} />
						<Row>
							<Col xl={24} lg={24} md={24} sm={12} xs={24}> */}
			{/* <Friends friendList={friendList} /> */}
			{/* </Col> */}
			{/* <Col xl={24} lg={24} md={24} sm={12} xs={24}>
                <Photos photoList={photoList}/>
              </Col> */}
			{/* </Row> */}
			{/* </Col> */}

			{/* <br/> */}
			{/* <Col xl={24} lg={14} md={14} sm={24} xs={24}  style={{paddingTop:"1rem"}}> */}
			{/* <Counts aboutList={aboutList} /> */}
			{/* <Biography/> */}
			{/* </Col> */}
			{/* 
					<Events
							eventList={qryData?.getUser?.bookings}
							isLoading={loading}
						/> */}

			{/* </Row>
				
			</div> */}
			{result &&
				<Modal
					title={"Settings"}
					visible={result}
					onOk={handleOk}
					onCancel={handleCancel}
					width={500}
				>
					{/* {proActivate && <Alert message="Profile Deactivated" type="success" showIcon />} */}
					{/* {proDeactivate && <Alert message="Updating...." type="success" showIcon />} */}
					{/* {errorMtU && (
					<Alert
						message={`Submission error! ${errorMtU.message}`}
						type="error"
						showIcon
					/>
				)} */}

					{/* <br/> */}
					<span style={{ paddingRight: "1rem" }}>Disable Customer</span>
					<Switch onChange={e => handleClick(e)} checked={statusCheck} />
					{/* defaultChecked={qryData?.getUser?.isActive} */}
				</Modal>
			}
		</>
	);
};
export default UserDetail;
