import React, { useState } from 'react';
import { Col, Row, Tabs, Badge, Card, Avatar } from 'antd';
import AboutItem from './aboutItem';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CMSQueryGetUserBookings } from 'src/lib/gql/queries/bookings';
import {
	CMSGetUserBookings,
	CMSGetUserBookingsVariables,
} from 'src/lib/gql/generated/CMSGetUserBookings';
import noData from "../../../../src/components/dashboard/noDataa.png"

const TabPane = Tabs.TabPane;
interface Props {
	aboutList: any;
}

const About = ({ aboutList }: Props) => {
	// const [pagination, setPagination] = useState({
	// 	pageNumber: 1,
	// 	pageSize: 5,
	// });
	// const { id } = useParams<{ id: string }>();

	// const { data: qryData, loading } = useQuery<
	// 	CMSGetUserBookings,
	// 	CMSGetUserBookingsVariables
	// >(CMSQueryGetUserBookings, {
	// 	variables: {
	// 		pagination: pagination,
	// 		useId:id !
	// 	},
	// });

	// console.log("aboutList",aboutList);

	const address = [
		{
			id: 2,
			title: 'name',
			icon: 'name',
			add: '120 253 31',
			city: 'kakkaand',
			place: 'kakkaand',
			pin: '985632',
		},
		{
			id: 1,
			title: 'Email',
			icon: 'email',
			add: '120 253 31',
			city: 'kakkaand',
			place: 'kakkaand',
			pin: '985632',
		},
		{
			id: 3,
			title: 'Phone',
			icon: 'phone',
			add: '120 253 31',
			city: 'kakkaand',
			place: 'kakkaand',
			pin: '985632',
		},
		{
			id: 4,
			title: 'Phone',
			icon: 'phone',
			add: '120 253 31',
			city: 'kakkaand',
			place: 'kakkaand',
			pin: '985632',
		},
		{
			id: 5,
			title: 'Phone',
			icon: 'phone',
			add: '120 253 31',
			city: 'kakkaand',
			place: 'kakkaand',
			pin: '985632',
		},
		{
			id: 6,
			title: 'Phone',
			icon: 'phone',
			add: '120 253 31',
			city: 'kakkaand',
			place: 'kakkaand',
			pin: '985632',
		},
	];



	return (
		<Card
			title="Address"
			className={`gx-card-widget gx-card-tabs gx-card-profile`}
		>
			{/* <Row>
				<Col xl={6} lg={12} md={12} sm={12} xs={24}>
					{aboutList?.defaultAddress && (
						<Badge.Ribbon
							text={aboutList?.defaultAddress?.addressType === "OTHER" ? aboutList?.defaultAddress?.otherText : aboutList?.defaultAddress?.addressType}
							color={'green'}
						>

							<Card
								style={{
									backgroundColor: '#f5f5f5',
									borderRadius: '8px',
									marginTop: '1rem',
								}}
							>
								<p style={{ textAlign: "center" }}><i>Default Address</i></p>
								<div style={{ paddingTop: '1rem' }}>
									<b>House/Flat No: </b>
									<span style={{float:"right"}}>{aboutList?.defaultAddress?.buildingName}</span><br />
									<b>Locality: </b>
									<span  style={{float:"right"}}>{aboutList?.defaultAddress?.locality}</span><br />
									{aboutList?.defaultAddress?.landmark && (
										<>
											<b>landmark: </b>
											<span style={{float:"right"}}>{aboutList?.defaultAddress?.landmark}</span><br />
										</>)}
									<b>City: </b>
									<span style={{float:"right"}}>{aboutList?.defaultAddress?.area?.city?.name}</span><br />
									<b>Area: </b>
									<span style={{float:"right"}}>{aboutList?.defaultAddress?.area?.name}</span><br />
									<b>Postal Code: </b>
									<span style={{float:"right"}}>{aboutList?.defaultAddress?.postalCode}</span><br />

								</div>
							</Card>
						</Badge.Ribbon>
					)}
				</Col>
			</Row> */}
			{/* <Tabs className="gx-tabs-right" defaultActiveKey="1"> */}
			{/* <TabPane  key="1"> */}

			<div
				className="gx-mb-2"
				style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
			>
				{
					aboutList?.addresses?.length > 0 ?
						(
							<Row>
								<>

									{aboutList?.addresses.map((about, index) => (
										<Col key={index} xl={6} lg={12} md={12} sm={12} xs={24}>
											<Badge.Ribbon
												text={about?.addressType === "OTHER" ? about?.otherText : about?.addressType}
												color={'green'}
											>  
												<Card
													style={{
														backgroundColor: '#f5f5f5',
														borderRadius: '8px',
														// marginTop: '1rem',
													}}
												>
													{about?.isDefault===true ?(
														<>
														<div style={{textAlign:"center" ,paddingRight:"0.6rem"}}><span ><i>Default Address</i></span></div>
														<div style={{paddingTop:"1rem"}} >
														{/* <div style={{ display: 'flex' }}> */ }
															<b>House/Flat No:</b>
															{/* <span style={{ paddingLeft: '0.5rem' }}> */}
																<span style={{ fontSize: '14px',float:"right" }}>{about.buildingName}</span>
															{/* </span> */}
														{/* </div> */}<br/>
															<b> Locality:</b>
															<span style={{ paddingLeft: '0.5rem',float:"right" }}>
																{about.locality}
															</span><br/>
														{about.landmark && (
															<>
																<b> Landmark:</b>
																<span style={{ paddingLeft: '0.5rem',float:"right" }}>
																	{about.landmark}
																</span><br/>
																</>
														)}
															<b> City:</b>
															<span style={{ paddingLeft: '0.5rem',float:"right" }}>
																{about.area?.city?.name}
															</span><br/>
															<b>Area:</b>
															<span style={{ paddingLeft: '0.5rem' ,float:"right"}}>
																{about.area?.name}
															</span><br/>
															<b> Postal Code:</b>
															<span style={{ paddingLeft: '0.5rem',float:"right" }}>
																{about.postalCode}
															</span><br/>
															{/* <b> PH:</b> */}
															<span style={{ paddingLeft: '0.5rem',float:"right" }}>
																{/* {about.addressPhone} */}
															</span>
													</div>
													</>
													) :(
														<>
														
														<div style={{paddingTop:"2.3rem"}} >
														{/* <div style={{ display: 'flex' }}> */}
															<b>House/Flat No:</b>
															{/* <span style={{ paddingLeft: '0.5rem' }}> */}
																<span style={{ fontSize: '14px',float:"right" }}>{about.buildingName}</span>
															{/* </span> */}
														{/* </div> */}<br/>
															<b> Locality:</b>
															<span style={{ paddingLeft: '0.5rem',float:"right" }}>
																{about.locality}
															</span><br/>
														{about.landmark && (
															<>
																<b> Landmark:</b>
																<span style={{ paddingLeft: '0.5rem',float:"right" }}>
																	{about.landmark}
																</span><br/>
																</>
														)}
															<b> City:</b>
															<span style={{ paddingLeft: '0.5rem',float:"right" }}>
																{about.area?.city?.name}
															</span><br/>
															<b>Area:</b>
															<span style={{ paddingLeft: '0.5rem' ,float:"right"}}>
																{about.area?.name}
															</span><br/>
															<b> Postal Code:</b>
															<span style={{ paddingLeft: '0.5rem',float:"right" }}>
																{about.postalCode}
															</span><br/>
															{/* <b> PH:</b> */}
															<span style={{ paddingLeft: '0.5rem',float:"right" }}>
																{/* {about.addressPhone} */}
															</span>
													</div>
													</>
													)}
											
												</Card>
											</Badge.Ribbon>
										</Col>
									))}
								</>
							</Row>
						) : (
							<Card
								style={{
									// backgroundColor: '#f5f5f5',
									borderRadius: '8px',
									marginTop: '1rem',
									width: "400px"
								}}>
								<div style={{ textAlign: "center", paddingTop: "1rem" }}>
									<div>
										<img src={noData} height={110} width={110} />
									</div><div ><span style={{ fontStyle: "italic" }}>No Data</span>
									</div></div>
							</Card>
						)
				}

			</div>
			{/* </TabPane> */}
			{/* <TabPane tab="Work" key="2">
            <div className="gx-mb-2">
              <Row>{aboutList.map((about, index) =>
                <Col key={index} xl={8} lg={12} md={12} sm={12} xs={24}>
                  <AboutItem data={about}/>
                </Col>
              )}
              </Row>
            </div>
          </TabPane>
          <TabPane tab="Education" key="3">
            <div className="gx-mb-2">
              <Row>
                {aboutList.map((about, index) =>

                  <Col key={index} xl={8} lg={12} md={12} sm={12} xs={24}>
                    <AboutItem data={about}/>
                  </Col>
                )}
              </Row>
            </div>
          </TabPane> */}
			{/* </Tabs> */}

		</Card>
	);
};

export default About;
