import { useQuery ,useMutation} from '@apollo/client';
import {
	Button,
	Card,
	Col,
	Input,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Table,message
} from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import {
	CMSGetUsers,
	CMSGetUsersVariables,
} from 'src/lib/gql/generated/CMSGetUsers';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { CMSQueryGetUsers } from 'src/lib/gql/queries/users';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'src/lib/recoil/userCoil';
import { BufferTimeTypeEnum,CutOffTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { UPDATE_CHKSETTINGS } from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetChkSettings } from 'src/lib/gql/queries/chk';
import { VariablesAreInputTypesRule } from 'graphql';

const { Option } = Select;

const ServiceSettings: React.FC = () => {
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [filterName, setFilterName] = useState('');
	const [filterPhone, setFilterPhone] = useState('');
	const [accountType, setAccountType] = useState<any>();
	const [accountTypeCut, setAccountTypeCut] = useState<any>();
	const [accountTypeBuffer, setAccountTypeBuffer] = useState<any>();
	const userAuth = useRecoilValue(userAtom);
	const [bufferTime, setBufferTime] = useState<any>();
	const [cutTime, setCutTime] = useState<any>();

	const { data: qryData, loading } = useQuery<
	CMSGetUsers,
	CMSGetUsersVariables
	>(CMSQueryGetUsers, {
		variables: {
			pagination: pagination,
			filters: {
				type: accountType,
				name: filterName,
				phone: filterPhone,
			},
		},
		fetchPolicy: 'network-only'
	});
	
	const {
		data: qryDataChk,
		refetch: refetchQryDataChk,
	} = useQuery(
		CMSQueryGetChkSettings,
		);
	// console.log("accountTypeBuffer",accountTypeBuffer);
	useEffect(()=>{
		setBufferTime(qryDataChk?.getChkSettings?.bufferTimeValue)
		setCutTime(qryDataChk?.getChkSettings?.cutOffTimeValue)
		setAccountTypeBuffer(qryDataChk?.getChkSettings?.bufferTimeType)
		setAccountTypeCut(qryDataChk?.getChkSettings?.cutOffTimeType)
	},[qryDataChk])

	const handlePhoneFilter = (value: string | undefined) => {
		setFilterPhone(value || '');
	};
	const handleInputChangeBuffer = (value) => {
		// console.log("value",value);
		// let value = e.target.value;
		// Allow only numbers
		const values = value.replace(/\D/g, '');
		setBufferTime(values);
	  };
	  const handleInputChangeTime = (value) => {
		// let value = e.target.value;
		// Allow only strings (no numbers)
		const values = value.replace(/\D/g, '');
		setCutTime(values);
	  };

	useEffect(() => {
		setPagination({
			...pagination,
			pageNumber: 1, // Reset pagination when filters change
		});
	}, [filterName, filterPhone, accountType]);

	const exportData = qryData?.getUsers?.data || []; // Extract the data to be exported
	// console.log("dataa==>",qryData?.getUsers?.data);

	const [
		updateChkSettings,
		{ data: dataEditMtU, loading: loadingEditMtU, error: errorEditMtU },
	] = useMutation(UPDATE_CHKSETTINGS);

	const handleSave=()=>{
		console.log('values', cutTime);
		console.log('accountTypeCut', accountTypeCut);
		if(accountTypeCut==="AM" && cutTime >12){
			message.error('Maximum cut off time is 12'); 
		}else if(accountTypeCut==="PM" && cutTime >11){
			message.error('Maximum cut off time is 11'); 
		}else{
		updateChkSettings({
			variables: {
				data: { 
					bufferTimeValue: bufferTime,
					bufferTimeType: accountTypeBuffer,
					cutOffTimeValue: cutTime,
					cutOffTimeType: accountTypeCut
				},
			},
		}).then((result) => {
			refetchQryDataChk();
			message.success("Service Settings Successfully ");
		  })
		  .catch((error) => {
			message.error('Error please try again'); 
		  });
		}
	}

	return (
		<Card title={<div>Service Settings</div>}>
			{/* {loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)} */}
			{/* {userAuth?.userType === UserTypeEnum.ADMIN ||
				userAuth?.rights?.find(
					(x) => x.type === 'sub2' && x.name === 'export-csv',
				) ? ( */}
				<Row justify="end" style={{
					marginBottom: 16, paddingTop: "1rem",
					backgroundColor: "#f6f6f6",
					paddingBottom: "5px"
				}}>
					<Col span={20} >
					Time Settings
					</Col>
					<Col span={4} >
						<Button onClick={handleSave}>Save</Button>
					</Col>
				</Row>
                <Row>
                    <Col span={24} >
					<div style={{display:"flex"}}>Buffer time : 
					<Input onChange={(e) => handleInputChangeBuffer(e.target.value)} 
					value={bufferTime} style={{width:"50px",marginLeft: "3rem"}}/> 	
                    <div style={{ paddingLeft: "0.5rem" }}>
							<Select
								value={accountTypeBuffer}
								style={{ width: 120 }}
								onChange={(value) => setAccountTypeBuffer(value)} getPopupContainer={(trigger) => trigger.parentElement}
							>
								<Option value={BufferTimeTypeEnum.HOURS}>{BufferTimeTypeEnum.HOURS}</Option>
								<Option value={BufferTimeTypeEnum.MINUTES}>{BufferTimeTypeEnum.MINUTES}</Option>
							</Select>
						</div></div> <br/>
                        <div style={{display:"flex"}}> Cut-off time :	
						 <Input onChange={(e) => handleInputChangeTime(e.target.value)} value={cutTime} style={{width:"50px",marginLeft: "2.6rem"}} /> 		 
                    <div style={{ paddingLeft: "0.5rem" }}> 
							<Select
								value={accountTypeCut}
								style={{ width: 120 }}
								onChange={(value) => setAccountTypeCut(value)} getPopupContainer={(trigger) => trigger.parentElement}
							>
								<Option value={CutOffTypeEnum.AM}>{CutOffTypeEnum.AM}</Option>
								<Option value={CutOffTypeEnum.PM}>{CutOffTypeEnum.PM}</Option>
							</Select>
						</div></div> 		
                    </Col>  
                </Row>
			{/* // ) : (
			// 	<></>
			// )} */}
		</Card>
	);
};
export default ServiceSettings;
