import { useMutation, useQuery } from '@apollo/client';
import {
	Alert,
	Avatar,
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Input,
	Modal,
	Row,
} from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { useState, useEffect } from 'react';
import { CMSGetPartner_getUser } from 'src/lib/gql/generated/CMSGetPartner';
import { CMSGetServiceAreas } from 'src/lib/gql/generated/CMSGetServiceAreas';
import {
	CMSGetServices,
	CMSGetServicesVariables,
} from 'src/lib/gql/generated/CMSGetServices';
import {
	CMSUpdatePartnerServices,
	CMSUpdatePartnerServicesVariables,
} from 'src/lib/gql/generated/CMSUpdatePartnerServices';
import { UPDATE_PARTNER_SERVICES } from 'src/lib/gql/mutations/mutations';
import {
	CMSQueryGetServiceAreas,
	CMSQueryGetServices,
} from 'src/lib/gql/queries/services';

interface Props {
	input?: CMSGetPartner_getUser | null;
	onFinishAdd?: () => void;
}

const UpdatePartnerServices: React.FC<Props> = ({ input, onFinishAdd }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [search, setSearch] = useState('');
	const [checkedValues, setCheckedValues] = useState<any>([]);
	const [allSelectedIds, setAllSelectedIds] = useState<string[] | undefined>([]);

	useEffect(() => {
		setCheckedValues(
			input?.partnerDetails?.services?.map((service) => service.id),
		);
		setAllSelectedIds(
			input?.partnerDetails?.services?.map((service) => service.id),
		);
	}, [input]);

	// Get all services
	const {
		loading,
		data: qryData,
		refetch: refetchQryData,
	} = useQuery<CMSGetServices, CMSGetServicesVariables>(CMSQueryGetServices, {
		variables: {
			showAll: true,
			search: '',
			onlyPublished: true,
		},
		errorPolicy: 'all',
	});

	// Update
	const [update, { data: dataMtU, loading: loadingMtU, error: errorMtU }] =
		useMutation<CMSUpdatePartnerServices, CMSUpdatePartnerServicesVariables>(
			UPDATE_PARTNER_SERVICES,
		);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
		setIsModalVisible(false);
	};

	const [form] = Form.useForm();

	const onFinish = (values) => {
		if (input) {
			update({
				variables: {
					partnerId: input?.id,
					services: allSelectedIds ? allSelectedIds : [],
				},
			}).then(() => {
				form.resetFields();
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
					}
					setIsModalVisible(false);
				}, 1500);
			});
		}
	};

	const onChange = (checkedValues) => {
		setCheckedValues(checkedValues);
	};


	const handleCheckboxChange = (e) => {
		const serviceId = e.target.value;
		if (e.target.checked) {
			// Add to the list of selected IDs
			setAllSelectedIds((prevIds: any) => [...prevIds, serviceId]);
		} else {
			// Remove from the list of selected IDs
			setAllSelectedIds((prevIds: any) =>
				prevIds.filter((id) => id !== serviceId),
			);
		}
	};

	return (
		<>
			<div style={{ paddingRight: '1rem' }}>
				<Button type="primary" onClick={showModal}>
					Edit
				</Button>
			</div>
			<Modal
				title={
					<>
						<Row>
							<Col span={6}>
								<Button
									type="primary"
									onClick={handleOk}
									style={{ margin: '0' }}
								>
									Save
								</Button>
							</Col>
							<Col span={12}>
								<p style={{ textAlign: 'center' }}>Edit Info</p>
							</Col>
						</Row>
					</>
				}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
				footer={null}
			>
				{/* Updating */}
				{dataMtU && <Alert message="Updated" type="success" showIcon />}
				{loadingMtU && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtU && (
					<Alert
						message={`Submission error! ${errorMtU.message}`}
						type="error"
						showIcon
					/>
				)}
				<Row>
					<Col span={12}>
						<Input
							placeholder="Search by name or code"
							onChange={(e) => setSearch(e.target.value)}
						/>
					</Col>
					<Col span={12} style={{ textAlign: 'end' }}>
						<p>Selected Services: {allSelectedIds?.length}</p>
					</Col>
				</Row>

				<Form
					form={form}
					onFinish={onFinish}
					scrollToFirstError
					initialValues={
						!input
							? {}
							: {
									...input,
									...input?.partnerDetails?.address,
							  }
					}
				>
					<Row>
						{/* <Checkbox.Group
							style={{ width: '100%' }}
							onChange={onChange}
							value={checkedValues}
						> */}
							
								{qryData?.getServices
									.filter(
										(el) =>
											el.name.toLowerCase().includes(search.toLowerCase()) ||
											el.code.toLowerCase().includes(search.toLowerCase()),
									)
									.map((el) => {
										const isSelected = allSelectedIds?.includes(el.id);
										return (
											<Col key={el.id} span={8}>
												<br />
												<Card>
													<Checkbox value={el.id} checked={isSelected} onChange={handleCheckboxChange}>
														<Meta
															avatar={
																<Avatar
																	src={`https://staging.api.zimkey.in/media-upload/${el?.icon?.url}`}
																/>
															}
															title={el.code}
															description={el.name}
														/>
													</Checkbox>
												</Card>
											</Col>
										);
									})}
						
						{/* </Checkbox.Group> */}
					</Row>
				</Form>
			</Modal>
		</>
	);
};
export default UpdatePartnerServices;
