import {
	Card,
	Col,
	Row,
	Select,
	Space,
	DatePicker,
	Spin,
	Pagination,
	Modal,
	Table,
	Button,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { CSVLink } from 'react-csv';
import { DownloadOutlined } from '@ant-design/icons';
import img1 from './img1.jpeg';
import img2 from './img2.jpeg';
import img3 from './img3.jpeg';
import { useParams } from 'react-router-dom';
import { CMSQueryGetUser } from 'src/lib/gql/queries/users';
import { useQuery } from '@apollo/client';
import {
	CMSGetUser,
	CMSGetUserVariables,
} from 'src/lib/gql/generated/CMSGetUser';
import { CMSGetServiceAreas } from 'src/lib/gql/generated/CMSGetServiceAreas';
import { CMSQueryGetServiceAreas } from 'src/lib/gql/queries/services';
import { CMSGetAllServiceCategories } from 'src/lib/gql/generated/CMSGetAllServiceCategories';
import { CMSQueryGetAllServiceCategories } from 'src/lib/gql/queries/services';
import {
	CMSGetServices,
	CMSGetServicesVariables,
} from 'src/lib/gql/generated/CMSGetServices';
import { CMSQueryGetServices } from 'src/lib/gql/queries/services';
import {
	CMSQueryRevenueGeneration,
	CMSQueryServicePartnerReport,
	CMSQueryExportServicePartnerReport
} from 'src/lib/gql/queries/reports';
import { DatePeriodEnums3 } from 'src/lib/gql/generated/globalTypes';
import moment from 'moment';
import { RouteList } from 'src/config/constants/routes';
import { Link } from 'react-router-dom';
import {
	CMSGetPartners,
	CMSGetPartnersVariables,
	CMSGetPartners_getUsers_data,
} from 'src/lib/gql/generated/CMSGetPartners';
import {
	PartnerPendingTaskEnum,
	UserTypeEnum,
} from 'src/lib/gql/generated/globalTypes';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userAtom, userTokenAtom } from 'src/lib/recoil/userCoil';

const ServicePartner = () => {
	const Option = Select.Option;
	const { RangePicker } = DatePicker;
	const [dateValue, SetDateValue] = useState<any>();
	const [isOpenDate, SetIsOpenDate] = useState(false);
	const [saveDate, setSaveDate] = useState('');
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [startDate, setStartDate] = useState<any>('');
	const [endDate, setEndDate] = useState<any>('');
	const [statusValue, SetStatusValue] = useState<any>();
	const [providerValue, SetProviderValue] = useState<any>();
	const [areaValue, SetAreaValue] = useState<any>();
	const [serviceValue, SetServiceValue] = useState<any>();
	const [serviceCateValue, SetServiceCateValue] = useState<any>();
	const [categoryService, SetCategoryService] = useState<any>();
	const { id } = useParams<{ id: string }>();
	const [accountType, setAccountType] = useState(UserTypeEnum.PENDING_PARTNER);
	const userAuth = useRecoilValue(userAtom);

	const { data: qryDatas, refetch: refetchQryData } = useQuery(
		CMSQueryServicePartnerReport,
		{
			variables: {
				pagination: pagination,
				filter: {
					registrationStatus: accountType,
					fromDate: startDate ? startDate : null,
					toDate: endDate ? endDate : null,
					datePeriod: dateValue ? dateValue : null,
				},
			},
			fetchPolicy: 'network-only'
		},
	);
	const { data: qryDatass, refetch: refetchQryDataa } = useQuery(
		CMSQueryExportServicePartnerReport,
		{
			variables: {
				filter: {
					registrationStatus: accountType,
					fromDate: startDate ? startDate : null,
					toDate: endDate ? endDate : null,
					datePeriod: dateValue ? dateValue : null,
				},
			},
		},
	);

	const { loading, data: qryData } = useQuery<CMSGetUser, CMSGetUserVariables>(
		CMSQueryGetUser,
		{
			variables: {
				id: id || '',
			},
		},
	);
	// console.log("12345600",qryData?.getUser);
	const { data: qryDataArea, refetch: refetchQryDatas } =
		useQuery<CMSGetServiceAreas>(CMSQueryGetServiceAreas, {
			errorPolicy: 'all',
		});
	const { data: qryDataServiceCate, refetch: refetchQryDataServiceCate } =
		useQuery<CMSGetAllServiceCategories>(CMSQueryGetAllServiceCategories);
	// console.log("service categroy",qryDataService);

	// Get all services
	const { data: qryDataService, refetch: refetchQryDataService } = useQuery<
		CMSGetServices,
		CMSGetServicesVariables
	>(CMSQueryGetServices, {
		variables: {
			showAll: true,
			search: '',
		},
		errorPolicy: 'all',
	});
	// console.log("service",qryDataService);
	useEffect(() => {
		// console.log("serviceCate Value",serviceCateValue);
		SetCategoryService(
			qryDataServiceCate?.getServiceCategories?.find(
				(value) => value.id === serviceCateValue,
			),
		);
		// console.log("daya",dataaa?.services);
	}, [serviceCateValue]);

	const data = [{ name: 'Page A', uv: 400, pv: 2400, amt: 2400 }];
	// useEffect(()=>{
	//     console.log("isOpenDate",isOpenDate);
	//     console.log("dateValue",dateValue);
	//     //    if(dateValue.[]==="custom"){
	//     //     SetIsOpenDate(true)
	//     // }
	// },[isOpenDate,dateValue])
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const convertTimestampToDateTime = (createDateTime) => {
		const date = new Date(parseInt(createDateTime));
		const day = String(date.getDate()).padStart(2, '0');
		const month = months[date.getMonth()];
		const year = String(date.getFullYear());
		const hours24 = date.getHours();
		const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
		const ampm = hours24 < 12 ? 'AM' : 'PM'; // Determine AM or PM

		const hours = String(hours12).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${day} ${month} ${year} ${hours}:${minutes}:${seconds} ${ampm}`;
	};

	const exportData =
	qryDatass?.exportServicePartnerReport?.map((item) => {
			return {
			'Provider Name': item.name,
			'email': item.email,
			'phone': item.phone,
			'createDateTime': convertTimestampToDateTime(item.createDateTime),
			'uploadDocuments':
				item.partnerDetails?.pendingTasks?.findIndex(
					(task) => task === PartnerPendingTaskEnum.UPLOAD_DOCUMENT,
				) > -1
					? 'No'
					: 'Yes',
			'Total Services': item.partnerDetails.services.length,
			'Total Areas': item.partnerDetails?.serviceAreas?.length,
			'Account Status': item.userType,
			}
		}) || []; // Extract the data to be exported

	useEffect(() => {
		refetchQryData();
	}, [accountType, pagination, refetchQryData]);

	const columns = [
		// ...(accountType
		// 	?
		// 	[
		// {
		// 	title: 'SI No',
		// 	dataIndex: '',
		// 	key: 'bookings',
		// 	align: 'center' as const,
		// },
		{
			title: 'Provider Name',
			dataIndex: 'Provider Name',
			key: 'Provider Name',
			align: 'center' as const,
			render: (name, record) => (
				<span className="gx-link">
					<Link to={RouteList.partners.path + '/' + record.id}>
						{record?.name}
					</Link>
				</span>
			),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Phone',
			dataIndex: 'phone',
			key: 'Phone',
			width: 150,
			align: 'center' as const,
			render: (phone) => <span>{phone} </span>,
		},
		{
			title: 'Registered on',
			dataIndex: 'createDateTime',
			key: 'Registered on',
			width: 150,
			align: 'center' as const,
			render: (createDateTime, record) => {
				const convertedDate = new Date(parseInt(createDateTime));
				const month = convertedDate.toLocaleString('default', {
					month: 'short',
				});
				const time = convertedDate.toLocaleTimeString();
				return (
					<span>
						{convertedDate.getDate()} {month}, {convertedDate.getFullYear()}
						<br />
						{time}
					</span>
				);
			},
		},
		{
			title: 'Documents Uploaded',
			dataIndex: 'uploadDocuments',
			key: 'Documents Uploaded',
			align: 'center' as const,
			render: (_, record: CMSGetPartners_getUsers_data) => (
				<div
					className="gx-ml-2 gx-d-none gx-d-sm-flex"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{record?.partnerDetails?.pendingTasks && (
						<span>
							{record?.partnerDetails?.pendingTasks?.findIndex(
								(task) => task === PartnerPendingTaskEnum.UPLOAD_DOCUMENT,
							) > -1
								? `🔴`
								: `🟢`}
						</span>
					)}
				</div>
			),
		},
		{
			title: 'Total Services',
			dataIndex: 'Total Services',
			key: 'Total Services',
			align: 'center' as const,
			render: (text: string, record: any) => {
				return <span>{record?.partnerDetails?.services?.length}</span>;
			},
		},
		{
			title: 'Total Areas',
			dataIndex: 'Total Areas',
			key: 'Total Areas',
			align: 'center' as const,
			render: (text: string, record: any) => {
				return <span>{record?.partnerDetails?.serviceAreas?.length}</span>;
			},
		},
		{
			title: 'Account Status',
			dataIndex: 'Account Status',
			key: 'Account Status',
			align: 'center' as const,
			render: (text: string, record: any) => {
				return <span>{record?.userType}</span>;
			},
		},
		// ]
		// 	: []),
	];
	const handleServiceCate = (value) => {
		SetServiceCateValue(value);
	};
	const handleArea = (value) => {
		SetAreaValue(value);
	};
	const handleService = (value) => {
		SetServiceValue(value);
	};
	
	const handleChanges = (value) => {
		SetDateValue(value);
		setStartDate(null);
		setEndDate(null);
		if (value === 'CUSTOM') {
			SetIsOpenDate(true);
		}
	};

	const handleOk = () => {
		SetIsOpenDate(false);
	};
	const handleCancel = () => {
		SetIsOpenDate(false);
	};
	const handleDate = (dates) => {
		setSaveDate(dates);
		if (dates && dates?.length === 2) {
			const startDates = moment(dates[0]?._d).toISOString();
			const endDates = moment(dates[1]?._d).toISOString();
			// setStartDate(moment(startDates).format('MMMM Do YYYY'));
			// setEndDate(moment(endDates).format('MMMM Do YYYY'));
			console.log('before setting dates',startDates,endDates)
			setStartDate(startDates);
			setEndDate(endDates);
		}
	};
	const ClearAll = () => {
		SetProviderValue(null);
		// SetStatusValue(null);
		SetDateValue(null);
		setStartDate(null);
		setEndDate(null);
	};

	const handlePaginationChange = (page: number, pageSize?: number) => {
		setPagination((prevPagination) => ({
			...prevPagination,
			pageNumber: page,
		}));
	};

	const handlePageSizeChange = (current: number, size: number) => {
		setPagination((prevPagination) => ({
			pageNumber: 1, // Reset to the first page when changing page size
			pageSize: size,
		}));
	};

	const currentDate = new Date();
	const formattedDate = currentDate.toISOString().split('T')[0];
	const formattedTime = currentDate
		.toTimeString()
		.split(' ')[0]
		.replace(/:/g, '');
	const hour = parseInt(formattedTime.substring(0, 2), 10);
	const minute = parseInt(formattedTime.substring(2, 4), 10);
	const second = parseInt(formattedTime.substring(4, 6), 10);

	const filename = `servicePartnerReport-${formattedDate}_${hour}:${minute}:${second}.csv`;

	return (
		<>
			<Card>
				<Row>
					<Col span={20}>
						<h3>Filters</h3>
					</Col>
					<Col span={4}>
						<Button type="primary" onClick={ClearAll}>
							Clear All
						</Button>
					</Col>
				</Row>{' '}
				<hr />
				
				<Row style={{justifyContent:"center"}}>
					<Col xl={5} lg={12} md={12} sm={12} xs={24}>
						<span>Date Range</span>

						<Select getPopupContainer={(trigger) => trigger.parentElement}
							style={{
								width: '100%',
							}}
							placeholder="select one date"
							defaultValue={saveDate ? saveDate : 'All Time'}
							onChange={handleChanges}
							optionLabelProp="label"
							value={dateValue}
						>
							<Option value={null} label="All Time">
								<Space>All Time</Space>
							</Option>
							<Option value="TODAY" label="TODAY">
								<Space>{DatePeriodEnums3.TODAY}</Space>
							</Option>
							<Option value="YESTERDAY" label="YESTERDAY">
								<Space>{DatePeriodEnums3.YESTERDAY}</Space>
							</Option>
							<Option value="LAST_3_DAYS" label="LAST_3_DAYS">
								<Space>{DatePeriodEnums3.LAST_3_DAYS}</Space>
							</Option>
							<Option value="THIS_WEEK" label="THIS_WEEK">
								<Space>{DatePeriodEnums3.THIS_WEEK}</Space>
							</Option>
							<Option value="THIS_MONTH" label="THIS_MONTH">
								<Space>{DatePeriodEnums3.THIS_MONTH}</Space>
							</Option>
							<Option value="THIS_YEAR" label="THIS_YEAR">
								<Space>{DatePeriodEnums3.THIS_YEAR}</Space>
							</Option>
							<Option value="CUSTOM" label="CUSTOM">
								<Space>{DatePeriodEnums3.CUSTOM}</Space>
							</Option>

							{/* <Option value="dateandtime" label="Custom">
                        {dateValue ==="custom" && 

                        <Space direction="vertical" size={12}>
                        <RangePicker />
                        </Space> }  
                        </Option> */}
						</Select>
					</Col>
					<Col xl={4} lg={12} md={12} sm={12} xs={24}>
						<span>Registration Status</span>

						<Select getPopupContainer={(trigger) => trigger.parentElement}
							style={{
								width: '100%',
							}}
							placeholder="select Status"
							defaultValue={['PENDING_PARTNER']}
							// onChange={(e) => handleStatus(e)}
							onChange={(value) => setAccountType(value)}
							value={statusValue}
							optionLabelProp="label"
						>
							<Option value={null} label="All">
								<Space>
									<span role="img" aria-label="All">
										All
									</span>
								</Space>
							</Option>
							<Option value="PENDING_PARTNER" label="Pending Partner">
								<Space>
									<span role="img" aria-label="Pending Partner">
										Pending Partner
									</span>
								</Space>
							</Option>
							<Option value="PARTNER" label="Partner">
								<Space>
									<span role="img" aria-label="Partner">
										Partner
									</span>
								</Space>
							</Option>
							<Option value="REJECTED_PARTNER" label="Rejected Partner">
								<Space>
									<span role="img" aria-label="Rejected Partner">
										Rejected Partner
									</span>
								</Space>
							</Option>
						</Select>
					</Col>
				</Row>
				<Row style={{ float: 'right' }}>
					<div style={{ float: 'right' }}>
						{startDate ? moment(startDate).format('lll') : ''}
						{startDate ? '-' : ''}{' '}
						{endDate ? moment(endDate).format('lll') : ''}
					</div>
				</Row>
				{isOpenDate && (
					<Modal
						title="Date"
						visible={isOpenDate}
						onOk={handleOk}
						onCancel={handleCancel}
						// width={800}
					>
						<div style={{ textAlign: 'center' }}>
							<Space direction="vertical" size={6}>
								<RangePicker onChange={handleDate} />
							</Space>
						</div>
					</Modal>
				)}
			</Card>
			<br />
			{/* 
                    <Row>
						<Col span={8} >
                        <Row style={{marginLeft:"0rem"}}>
						<Card style={{borderRadius:"10px",width:"50%"}}>
						<div className="gx-media-body" style={{textAlign:"center"}}>
						<h5>Net Profit</h5>
						<h1 className="gx-mb-1 gx-text-grey"><span style={{fontSize: "18px"}}>₹</span>{" "}123456</h1>
						</div>
						</Card>
                          
						<Card style={{borderRadius:"10px",width:"50%"}}>
						<div className="gx-media-body" style={{textAlign:"center"}}>
						<h5>Commission Earnings</h5>
						<h1 className="gx-mb-1 gx-text-grey"><span style={{fontSize: "18px"}}>₹</span>{" "}1</h1>
						</div>			
							</Card>
                        </Row>
						<Card style={{borderRadius:"10px",width: "105%",marginTop:"1rem"}}>
						<div className="gx-media-body" >
                        <Row>
                            <Col span={6}>
                                
                            <img src={img1} style={{width:"100px",height:"50px"}}/>

						
                        </Col>
                        <Col span={18} style={{float:"right"}}>
                        <span>Total Expense</span>
						<h1 className="gx-mb-1 gx-text-grey"><span style={{fontSize: "18px"}}>₹</span>{" "}1</h1>
                        </Col>
                        </Row></div>
                        <br/>
                        <Row>
                            <Col span={4}>
                            Campaign
                            </Col>
                            <Col span={10} style={{textAlign:"center"}}>
                            Normal Discount
                                </Col>
                                <Col span={10}>
                                Coupon  Discount
                                </Col>
                                <Col span={4} style={{textAlign:"center"}}>
                                  <span className="gx-mb-1 gx-text-grey" style={{fontSize: "15px"}}>₹{" "}10</span>
                                </Col>
                                 <Col span={10} style={{textAlign:"center"}}>
                                   <span className="gx-mb-1 gx-text-grey" style={{fontSize: "15px"}}>₹{" "}20</span>
                                </Col>
                                <Col span={10} style={{textAlign:"center"}}>
                                 <span className="gx-mb-1 gx-text-grey" style={{fontSize: "15px"}}>₹{" "}30</span>
                                </Col>
                        </Row>
                   
						</Card>
						</Col>
						<Col  span={6} >
					
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"1rem"}}>
                        <BarChart width={600} height={300} data={data}>
                        <XAxis dataKey="name" stroke="#8884d8" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <Bar dataKey="uv" fill="#8884d8" barSize={30} />
                        </BarChart>
						</div>
						</Col>
						</Row> */}
			<br />
			<Row justify="end" style={{ marginBottom: 16 }}>
			{userAuth?.userType === UserTypeEnum.ADMIN ||
					userAuth?.rights?.find(
						(x) => x.type === 'sub2' && x.name === 'service-partner-export-csv',
					) ? (
						<CSVLink
					data={exportData}
					filename={filename}
					className="ant-btn ant-btn-primary"
					target="_blank"
					headers={columns.map((column) => ({
						label: column.title,
						key: column.dataIndex,
					}))}
				>
					<DownloadOutlined /> Export CSV
				</CSVLink>
					) : (
						<></>
					)}
				
			</Row>
			<Row>
				<Col
					span={24}
					style={{
						marginBottom: 16,
						paddingTop: '1rem',
						backgroundColor: '#f6f6f6',
						paddingBottom: '5px',
					}}
				>
					<div style={{ textAlign: 'center' }}>
						<Pagination
							key="pagination"
							defaultCurrent={
								qryDatas?.getServicePartnerReport?.pageInfo.currentPage
							}
							current={qryDatas?.getServicePartnerReport?.pageInfo.currentPage}
							pageSize={pagination.pageSize}
							total={qryDatas?.getServicePartnerReport?.pageInfo.totalCount}
							showTotal={(total) => `Total ${total} items`}
							onChange={handlePaginationChange}
							showSizeChanger
							pageSizeOptions={['10', '20', '50', '100']}
							onShowSizeChange={handlePageSizeChange}
							selectComponentClass={Select}
							showLessItems
						/>
					</div>
				</Col>
			</Row>
			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}
			{!loading && (
				<Table
					className="gx-table-responsive"
					columns={columns}
					dataSource={qryDatas?.getServicePartnerReport?.data}
					pagination={false}
				/>
			)}
		</>
	);
};

export default ServicePartner;
