import { useMutation, useQuery } from '@apollo/client';
import {
	Card,
	Col,
	Input,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Table,
	Button,
	Modal,
	Switch,
} from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import {
	CMSGetUsers,
	CMSGetUsersVariables,
} from 'src/lib/gql/generated/CMSGetUsers';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import {
	CMSQueryGetPartners,
	CMSQueryGetUsers,
} from 'src/lib/gql/queries/users';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'src/lib/recoil/userCoil';
import {
	CMSQueryGetPersonnel,
	CMSQueryGetTeams,
} from 'src/lib/gql/queries/chk';
import {
	CREATE_TEAM,
	UPDATE_TEAM,
	DELETE_TEAM,
} from 'src/lib/gql/mutations/mutations';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { CMSQueryGetPayoutsHistory } from 'src/lib/gql/queries/payouts';

const { Option } = Select;

const PayoutsHistory: React.FC = () => {
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [payoutsHistoryPagination, setPayoutsHistoryPagination] = useState({
		pageNumber: 1,
		pageSize: 30,
	});
	const [filterName, setFilterName] = useState('');
	const [filterPhone, setFilterPhone] = useState('');
	const userAuth = useRecoilValue(userAtom);

	const [name, setName] = useState<any>();
	const [strength, setStrength] = useState<any>();

	// Fetching payouts history
	const {
		data: qryPayoutsHistoryData,
		loading: loadingPayoutsHistory,
		error: errorPayoutsHistory,
		refetch: refetchQryPayoutsHistoryData,
	} = useQuery(CMSQueryGetPayoutsHistory, {
		variables: {
			pagination: payoutsHistoryPagination,
			filters: {},
		},
	});

	const handlePhoneFilter = (value: string | undefined) => {
		setFilterPhone(value || '');
	};
	const handleInputChangeName = (e) => {
		console.log('e', e);
		let value = e.target.value;
		value = value.replace(/[0-9]/g, '');
		setFilterPhone(value);
	};
	const handleInputChange = (e) => {
		const value = e.target.value;
		// value = value.replace(/[0-9]/g, '');
		setFilterName(value);
	};

	const pageSizeOptions = ['10', '20', '50']; // Add '5' to the list of options

	const columns = [
		{
			title: 'Hatio Reference ID',
			dataIndex: 'payout_reference_id',
			key: 'payout_reference_id',
			// width: 150,
			render: (payout_reference_id: string, record: any) => (
				<span className="">{payout_reference_id}</span>
			),
		},
		{
			title: 'Partner',
			dataIndex: 'partner',
			key: 'partner',
			// width: 150,
			render: (partner: string, record: any) => (
				<span className="">
					<Link to={RouteList.partners.path + '/' + record?.user?.id}>
					{record?.user?.name}
					</Link>
				</span>
			),
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			align: 'center' as const,
			render: (amount: number) => <span>₹<b>{amount?.toLocaleString()}</b></span>
		},

        {
			title: 'Date',
			dataIndex: 'transactionDate',
			key: 'transactionDate',
			render: (transactionDate, record) => {
				const convertedDatel = new Date(parseInt(transactionDate));
				const month = convertedDatel.toLocaleString('default', { month: 'long' });
				const time = convertedDatel.toLocaleTimeString();
				const timeCrt = moment(time).format('LT');
				return (
					<span style={{ textAlign: 'center' }}>
						{/* {convertedDatel.getDate()} {month}, {convertedDatel.getFullYear()}
						<br /> */}
						{/* {timeCrt} */}
						{moment(convertedDatel).format('DD MMM yyyy, h:mm a')}
					</span>
				);
			},
		},

		{
			title: 'Status',
			dataIndex: 'status',
			align: 'center' as const,
			render: (status: string) => <span>{status}</span>,
		},
		

		{
			title: 'Source',
			dataIndex: 'source',
			align: 'center' as const,
			render: (source: string) => <span>{source}</span>,
		}

		

		// {
		// 	title: 'Action',
		// 	dataIndex: 'key',
		// 	key: 'id',
		// 	width: 'auto',
		// 	align: 'center' as const,
		// 	render: (text: string, record: any) => (
		// 		<>
		// 			<span
		// 				onClick={() => handleOpenModalEdit(record)}
		// 				style={{ cursor: 'pointer' }}
		// 			>
		// 				<EditOutlined style={{ marginRight: '20px', color: '#5143dc' }} />
		// 			</span>
		// 			<span
		// 				onClick={() => handleOpenModalDelete(record)}
		// 				style={{ cursor: 'pointer' }}
		// 			>
		// 				<DeleteOutlined style={{ color: 'red', marginRight: '20px' }} />
		// 			</span>
		// 		</>
		// 	),
		// },
	];

	const exportData = qryPayoutsHistoryData?.getPayoutsHistory?.data || []; // Extract the data to be exported

	return (
		<Card
			title={<div>Payouts History</div>}
		>
			<Row
				style={{
					marginBottom: '16px',
					paddingTop: '0.2rem',
					paddingBottom: '5px',
				}}
			>
				<Col span={24}>
					<div
						style={{
							justifyContent: 'center',
							display: 'flex',
						}}
					>
						<div>
							<Input
								type="string"
								style={{ width: '250px' }}
								placeholder="Search by reference ID"
								value={filterName}
								allowClear
								onChange={(e) => handleInputChange(e)}
							/>
						</div>

						{/* </Col> */}
						{/* <Col span={4}> */}
						<div style={{ paddingLeft: '0.5rem' }}>
							<Input
								style={{ width: '250px', paddingLeft: '1rem' }}
								placeholder="Search by Partner Name"
								value={filterPhone}
								allowClear
								onChange={(e) => handleInputChangeName(e)}
								// maxLength={10}
							/>
						</div>
						{/* </Col> */}
						{/* <Col> */}
					</div>
				</Col>
				{/* <Col span={12}>
				
				</Col> */}
			</Row>

			{loadingPayoutsHistory && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}

			{/* Data export button */}

			{userAuth?.userType === UserTypeEnum.ADMIN ||
			userAuth?.rights?.find(
				(x) => x.type === 'sub2' && x.name === 'export-csv',
			) ? (
				<Row
					justify="end"
					style={{
						marginBottom: 16,
						paddingTop: '1rem',
						backgroundColor: '#f6f6f6',
						paddingBottom: '5px',
					}}
				>
					<Col span={24} style={{ textAlign: 'center' }}>
						<Pagination
							key="pagination"
							defaultCurrent={qryPayoutsHistoryData?.getPayoutsHistory?.pageInfo.currentPage}
							current={qryPayoutsHistoryData?.getPayoutsHistory?.pageInfo.currentPage}
							pageSize={pagination.pageSize}
							total={qryPayoutsHistoryData?.getPayoutsHistory?.pageInfo.totalCount}
							showTotal={(total) => `Total ${total} items`}
							onChange={(page, pageSize) => {
								setPayoutsHistoryPagination({
									pageNumber: page,
									pageSize: pageSize ? +pageSize : pagination.pageSize,
								});
							}}
							showSizeChanger
							pageSizeOptions={pageSizeOptions} // Set the pageSizeOptions prop
						/>
					</Col>
				</Row>
			) : (
				<></>
			)}

			{/* Table */}
			<Table
				className="gx-table-responsive"
				columns={columns}
				dataSource={qryPayoutsHistoryData?.getPayoutsHistory?.data}
				pagination={false}
			/>

		</Card>
	);
};

export default PayoutsHistory;
