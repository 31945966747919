import React, { useState, useEffect } from 'react';
import {
	Card,
	Row,
	Col,
	Button,
	Input,
	Checkbox,
	Switch,
	Modal,
	Radio,
	Alert,
} from 'antd';
import Widget from 'src/components/widget/index';
import CircularProgress from 'src/components/CircularProgress';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RouteList } from 'src/config/constants/routes';
import { Link } from 'react-router-dom';
import Android from '../../assets/vendors/icons/android-icon.png';
import IOS from '../../assets/vendors/icons/ios-icon.jpg';
import { CMSQueryGetAppConfig } from 'src/lib/gql/queries/appConfig';
import { useQuery, useMutation } from '@apollo/client';
import {
	CMSGetAppConfig,
	CMSGetAppConfigVariables,
} from 'src/lib/gql/generated/CMSGetAppConfig';
import { UPDATE_APP_CONFIG } from 'src/lib/gql/mutations/mutations';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { userAtom } from 'src/lib/recoil/userCoil';
import { useRecoilValue } from 'recoil';


const menus = [
	// { title: 'Onboarding screens', link: RouteList.onboardingScreens.path },
	// { title: 'Popular services', link: RouteList.popularServices.path },
	{ title: 'App content', link: RouteList.appContent.path },
	{ title: 'Partner Companies', link: RouteList.managePartnerCompanies.path },
	{ title: 'Manage FAQs', link: RouteList.manageFaqs.path },
];

const homePageMenus = [
	{ title: 'Customer App', link: RouteList.homepageBanners.path },
	{ title: 'Partners App', link: RouteList.homepageServices.path },
];

const AppConfig: React.FC = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalOpened, setIsModalOpened] = useState(false);
	const [modalMaintenanceAndroid, setModalMaintenanceAndroid] = useState(false);
	const [modalMaintenanceIos, setModalMaintenanceIos] = useState(false);
	const [modalMaintenanceAndroid2, setModalMaintenanceAndroid2] =
		useState(false);
	const [modalMaintenanceIos2, setModalMaintenanceIos2] = useState(false);
	const userAuth = useRecoilValue(userAtom);

	const [check, setCheck] = useState(false);
	const [enable, setEnable] = useState(false);
	const [android, setAndroid] = useState<any>(false);
	const [ios, setIos] = useState<any>(false);
	const [android2, setAndroid2] = useState<any>(false);
	const [ios2, setIos2] = useState<any>(false);
	const [loader, setLoader] = useState(false);
	const [news, setNews] = useState(0);
	const [fieldEnabled, setFieldEnabled] = useState(false);
	const [isFieldEnabled, setIsFieldEnabled] = useState(false);

	const [dataInfo, setDataInfo] = useState<
		CMSGetAppConfigVariables | null | undefined
	>(null);
	// let qryData

	// useEffect(() => {

	const {
		loading,
		data: qryData,
		refetch: refetchQryData,
	} = useQuery<CMSGetAppConfig, CMSGetAppConfigVariables>(
		CMSQueryGetAppConfig,
		{
			variables: {
				appModule: news == 0 ? 'customer' : 'partner' || '',
			},
			errorPolicy: 'all',
			notifyOnNetworkStatusChange: true,
		},
	);
	// setDataInfo(qryData?.getAppConfig);

	// console.log("dataInfo",dataInfo);

	// }, [news]);

	const [custoAndrVer, setCustoAndrVer] = useState<any>(
		qryData?.getAppConfig?.androidVersion,
	);
	const [custoAndrPath, setCustoAndrPath] = useState<any>(
		qryData?.getAppConfig?.androidAppPath,
	);
	const [custoIosVer, setCustoIosVer] = useState<any>(
		qryData?.getAppConfig?.iosVersion,
	);
	const [custoIosPath, setCustoIosPath] = useState<any>(
		qryData?.getAppConfig?.iosAppPath,
	);

	useEffect(() => {
		// console.log("newssss",news);
		setCustoAndrVer(qryData?.getAppConfig?.androidVersion);
		setCustoAndrPath(qryData?.getAppConfig?.androidAppPath);
		setCustoIosVer(qryData?.getAppConfig?.iosVersion);
		setCustoIosPath(qryData?.getAppConfig?.iosAppPath);
		setAndroid(qryData?.getAppConfig?.androidMMode);
		setIos(qryData?.getAppConfig?.iosMMode);
		setAndroid2(qryData?.getAppConfig?.androidMMode);
		setIos2(qryData?.getAppConfig?.iosMMode);
	}, [qryData?.getAppConfig]);

	const handleChange = (e) => {
		// const value = e.target.value;
		setCheck(false)
		setEnable(false)
		setFieldEnabled(false)
		setIsFieldEnabled(false)
		setNews(e.target.value);
		setLoader(true);
		setTimeout(() => {
			setLoader(false);
		}, 1500);
	};

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		dataMtU(false);
	// 		loadingMtU(false);
	// 		loadingMtU(false);
	// 	}, 1500);
	// }, [qryData?.getAppConfig]);

	const showModal = () => {
		if (check === false) {
			setIsModalOpen(true);
		} else {
			setCheck(false);
			setFieldEnabled(false);
		}
	};
	const openModal = () => {
		if (enable === false) {
			setIsModalOpened(true);
		} else {
			setEnable(false);
			setIsFieldEnabled(false);
		}
	};
	const androidModal = () => {
		if (android === false) {
			setModalMaintenanceAndroid(true);
		} else {
			setAndroid(false);
		}
	};
	const iosModal = () => {
		if (ios === false) {
			setModalMaintenanceIos(true);
		} else {
			setIos(false);
		}
	};
	const androidModal2 = () => {
		if (android2 === false) {
			setModalMaintenanceAndroid2(true);
		} else {
			setAndroid2(false);
		}
	};
	const iosModal2 = () => {
		if (ios2 === false) {
			setModalMaintenanceIos2(true);
		} else {
			setIos2(false);
		}
	};

	const handleOk = () => {
		setCheck(true);
		setIsModalOpen(false);
		setFieldEnabled(true);
	};
	const androidOk = () => {
		setAndroid(true);
		setModalMaintenanceAndroid(false);
	};
	const iosOk = () => {
		setIos(true);
		setModalMaintenanceIos(false);
	};
	const androidOk2 = () => {
		setAndroid2(true);
		setModalMaintenanceAndroid2(false);
	};
	const iosOk2 = () => {
		setIos2(true);
		setModalMaintenanceIos2(false);
	};
	const ok = () => {
		setEnable(true);
		setIsModalOpened(false);
		setIsFieldEnabled(true);
	};

	const handleCancel = () => {
		setCheck(false);
		setIsModalOpen(false);
		setFieldEnabled(false);
	};
	const androidCancel = () => {
		setAndroid(false);
		setModalMaintenanceAndroid(false);
	};
	const iosCancel = () => {
		setIos(false);
		setModalMaintenanceIos(false);
	};
	const androidCancel2 = () => {
		setAndroid2(false);
		setModalMaintenanceAndroid2(false);
	};
	const iosCancel2 = () => {
		setIos2(false);
		setModalMaintenanceIos2(false);
	};
	const cancel = () => {
		setEnable(false);
		setIsModalOpened(false);
		setIsFieldEnabled(false);
	};
	const [updateAlert, setUpdateAlert] = useState(false)
	const [
		updateDetails,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation(UPDATE_APP_CONFIG, {
		onCompleted: () => {
			refetchQryData();
		},
	});


	const customerClick = () => {
		updateDetails({
			variables: {
				data: {
					androidVersion: custoAndrVer,
					androidAppPath: custoAndrPath,
					androidMMode: news == 0 ? android : android2,
					iosVersion: custoIosVer,
					iosAppPath: custoIosPath,
					iosMMode: news == 0 ? ios : ios2,
					appModule: news == 0 ? 'customer' : 'partner',
				},
			},
		});
		setUpdateAlert(true)
		setTimeout(() => {
		setUpdateAlert(false)
		}, 2000)
	};
	const partnerClick = () => {
		updateDetails({
			variables: {
				data: {
					androidVersion: custoAndrVer,
					androidAppPath: custoAndrPath,
					androidMMode: news == 0 ? android : android2,
					iosVersion: custoIosVer,
					iosAppPath: custoIosPath,
					iosMMode: news == 0 ? ios : ios2,
					appModule: news == 0 ? 'customer' : 'partner',
				},
			},
		});
		setUpdateAlert(true)
		setTimeout(() => {
		setUpdateAlert(false)
		}, 2000)
	};

	return (
		<>
			<Widget>
				<div className="gx-justify-content-between gx-mb-3 gx-dash-search">
					<h2 className="h4 gx-mb-3 gx-mb-sm-1 gx-mr-2">App Config</h2>
					<div className="gx-mx-sm-2 gx-mt-4 gx-mb-4">
						<Radio.Group
							className="gx-radio-group-link gx-radio-group-link-news"
							defaultValue={0}
							onChange={handleChange}
						>
							<Radio.Button value={0} className="gx-mb-1">
								Customer App
							</Radio.Button>
							<Radio.Button value={1} className="gx-mb-1">
								Partner App
							</Radio.Button>
						</Radio.Group>
					</div>
				</div>

				{loader ? (
					<CircularProgress className="gx-loader-100" />
				) : (
					<>
						{news == 0 ? (
							<Row>
								<Col span={24} className="gx-mb-4">

									{updateAlert && !loadingMtU && (
										<Alert message="Updated" type="success" showIcon />
									)}
									{loadingMtU && (
										<Alert message="Submitting..." type="info" showIcon />
									)}
									{errorMtU && (
										<Alert
											message={`Submission error! ${errorMtU.message}`}
											type="error"
											showIcon
										/>
									)}
			
									<Card className={`gx-card-widget`}>
										<Row style={{ marginBottom: '2rem' }}>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												{/* <h2 className="gx-mb-2 gx-mt-2">Customer App</h2> */}
											</Col>

											<Col
												xl={12}
												lg={12}
												md={12}
												sm={12}
												xs={24}
												className="gx-audi-col"
												style={{ textAlign: 'end' }}
											>
											{userAuth?.userType === UserTypeEnum.ADMIN ||
											userAuth?.rights?.find(
											(x) => x.type === 'sub2' && x.name === 'customer-app-edit',
											) ? (
												<div>
													{/* <Link to={el.link}> */}
													<Switch
														unCheckedChildren="Edit"
														onClick={showModal}
														checked={check}
													/>
													<Modal
														title="Confirmation"
														visible={isModalOpen}
														onOk={handleOk}
														onCancel={handleCancel}
													>
														<p>Are you sure want to edit?</p>
													</Modal>
													{/* </Link> */}
													<Button
														className="gx-ml-2 gx-mb-0"
														type="primary"
														disabled={!fieldEnabled}
														onClick={customerClick}
													>
														Save
													</Button>
												</div>
											):("")}

											</Col>
										</Row>
										<Row
											style={{
												marginBottom: '2rem',
											}}
										>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												<div
													style={{
														display: 'flex',
														textAlign: 'center',
														justifyContent: 'center',
													}}
												>
													<img
														src={Android}
														alt="Android-icon"
														style={{ width: '25px', height: '25px' }}
													/>
													<h2>Android</h2>
												</div>
											</Col>

											<Col
												xl={12}
												lg={12}
												md={12}
												sm={12}
												xs={24}
												className="gx-audi-col"
											>
												<div
													style={{
														display: 'flex',
														textAlign: 'center',
														justifyContent: 'center',
													}}
												>
													<img
														src={IOS}
														alt="Android-icon"
														style={{ width: '25px', height: '25px' }}
													/>
													<h2 className="gx-ml-1">IOS</h2>
												</div>
											</Col>
										</Row>
										<Row style={{ marginBottom: '1rem' }}>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												<h5>Version</h5>
												<Input
													placeholder="Version"
													disabled={!fieldEnabled}
													value={custoAndrVer}
													onChange={(e) => setCustoAndrVer(e.target.value)}
												/>
											</Col>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												<h5>Version</h5>

												<Input
													placeholder="Version"
													disabled={!fieldEnabled}
													value={custoIosVer}
													onChange={(e) => setCustoIosVer(e.target.value)}
												/>
											</Col>
										</Row>
										<Row style={{ marginBottom: '1rem' }}>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												<h5>App Path</h5>
												<Input
													placeholder="Path"
													disabled={!fieldEnabled}
													value={custoAndrPath}
													onChange={(e) => setCustoAndrPath(e.target.value)}
												/>
											</Col>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												<h5>App Path</h5>

												<Input
													placeholder="Path"
													disabled={!fieldEnabled}
													value={custoIosPath}
													onChange={(e) => setCustoIosPath(e.target.value)}
												/>
											</Col>
										</Row>
										<Row>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												<div style={{ display: 'flex' }}>
													<Checkbox
														style={{ fontWeight: 'bold' }}
														onClick={androidModal}
														checked={android}
														disabled={!fieldEnabled}
													>
														Maintenance Mode
													</Checkbox>
													<Modal
														title="Confirmation"
														visible={modalMaintenanceAndroid}
														onOk={androidOk}
														onCancel={androidCancel}
													>
														<p>
															Are you sure want to enable Maintenance Mode in
															android?
														</p>
													</Modal>
													{/* <h5>Maintenance Mode</h5> */}
												</div>
											</Col>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												<div style={{ display: 'flex' }}>
													<Checkbox
														style={{ fontWeight: 'bold' }}
														onClick={iosModal}
														checked={ios}
														disabled={!fieldEnabled}
													>
														Maintenance Mode
													</Checkbox>
													<Modal
														title="Confirmation"
														visible={modalMaintenanceIos}
														onOk={iosOk}
														onCancel={iosCancel}
													>
														<p>
															Are you sure want to enable Maintenance Mode in
															ios?
														</p>
													</Modal>
													{/* <h5>Maintenance Mode</h5> */}
												</div>
											</Col>
										</Row>
									</Card>
								</Col>
							</Row>
						) : (
							<Row>
								<Col span={24} className="gx-mb-4">
									{updateAlert && !loadingMtU && (
										<Alert message="Updated" type="success" showIcon />
									)}
									{loadingMtU && (
										<Alert message="Submitting..." type="info" showIcon />
									)}
									{errorMtU && (
										<Alert
											message={`Submission error! ${errorMtU.message}`}
											type="error"
											showIcon
										/>
									)}
									<Card className={`gx-card-widget`}>
										<Row style={{ marginBottom: '2rem' }}>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												{/* <h2 className="gx-mb-2 gx-mt-2">Partners App</h2> */}
											</Col>

											<Col
												xl={12}
												lg={12}
												md={12}
												sm={12}
												xs={24}
												className="gx-audi-col"
												style={{ textAlign: 'end' }}
											>
											{userAuth?.userType === UserTypeEnum.ADMIN ||
											userAuth?.rights?.find(
											(x) => x.type === 'sub2' && x.name === 'partner-app-edit',
											) ? (
												<div>
													{/* <Link to={el.link}> */}
													<Switch
														unCheckedChildren="Edit"
														onClick={openModal}
														checked={enable}
													/>
													<Modal
														title="Confirmation"
														visible={isModalOpened}
														onOk={ok}
														onCancel={cancel}
													>
														<p>Are you sure want to edit?</p>
													</Modal>
													{/* </Link> */}
													<Button
														className="gx-ml-2 gx-mb-0"
														type="primary"
														disabled={!isFieldEnabled}
														onClick={partnerClick}
													>
														Save
													</Button>
												</div>
											):("")}
											</Col>
										</Row>
										<Row
											style={{
												marginBottom: '2rem',
											}}
										>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												<div
													style={{
														display: 'flex',
														textAlign: 'center',
														justifyContent: 'center',
													}}
												>
													<img
														src={Android}
														alt="Android-icon"
														style={{ width: '25px', height: '25px' }}
													/>
													<h2>Android</h2>
												</div>
											</Col>

											<Col
												xl={12}
												lg={12}
												md={12}
												sm={12}
												xs={24}
												className="gx-audi-col"
											>
												<div
													style={{
														display: 'flex',
														textAlign: 'center',
														justifyContent: 'center',
													}}
												>
													<img
														src={IOS}
														alt="Android-icon"
														style={{ width: '25px', height: '25px' }}
													/>
													<h2 className="gx-ml-1">IOS</h2>
												</div>
											</Col>
										</Row>
										<Row style={{ marginBottom: '1rem' }}>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												<h5>Version</h5>
												<Input
													placeholder="Version"
													disabled={!isFieldEnabled}
													value={custoAndrVer}
													onChange={(e) => setCustoAndrVer(e.target.value)}
												/>
											</Col>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												<h5>Version</h5>

												<Input
													placeholder="Version"
													disabled={!isFieldEnabled}
													value={custoIosVer}
													onChange={(e) => setCustoIosVer(e.target.value)}
												/>
											</Col>
										</Row>
										<Row style={{ marginBottom: '1rem' }}>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												<h5>App Path</h5>
												<Input
													placeholder="Path"
													disabled={!isFieldEnabled}
													value={custoAndrPath}
													onChange={(e) => setCustoAndrPath(e.target.value)}
												/>
											</Col>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												<h5>App Path</h5>

												<Input
													placeholder="Path"
													disabled={!isFieldEnabled}
													value={custoIosPath}
													onChange={(e) => setCustoIosPath(e.target.value)}
												/>
											</Col>
										</Row>
										<Row>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												<div style={{ display: 'flex' }}>
													<Checkbox
														style={{ fontWeight: 'bold' }}
														onClick={androidModal2}
														checked={android2}
														disabled={!isFieldEnabled}
													>
														Maintenance Mode
													</Checkbox>
													<Modal
														title="Confirmation"
														visible={modalMaintenanceAndroid2}
														onOk={androidOk2}
														onCancel={androidCancel2}
													>
														<p>
															Are you sure want to enable Maintenance Mode in
															android?
														</p>
													</Modal>
													{/* <h5>Maintenance Mode</h5> */}
												</div>
											</Col>
											<Col xl={12} lg={12} md={12} sm={12} xs={24}>
												<div style={{ display: 'flex' }}>
													<Checkbox
														style={{ fontWeight: 'bold' }}
														onClick={iosModal2}
														checked={ios2}
														disabled={!isFieldEnabled}
													>
														Maintenance Mode
													</Checkbox>
													<Modal
														title="Confirmation"
														visible={modalMaintenanceIos2}
														onOk={iosOk2}
														onCancel={iosCancel2}
													>
														<p>
															Are you sure want to enable Maintenance Mode in
															ios?
														</p>
													</Modal>
													{/* <h5>Maintenance Mode</h5> */}
												</div>
											</Col>
										</Row>
									</Card>
								</Col>
							</Row>
						)}
					</>
				)}
			</Widget>
		</>
	);
};
export default AppConfig;
