import React ,{useState}from 'react';
import {
	Card,
	Row,
	Col,
	Button,
	Input,
	Table,
	Pagination,
	Space,
	Select,
	DatePicker,Modal,Rate
} from 'antd';
import moment from 'moment';
import { RouteList } from 'src/config/constants/routes';
import { Link } from 'react-router-dom';
import { DatePeriodEnum2 } from 'src/lib/gql/generated/globalTypes';
import { CMSQueryGetCustomerSupport } from 'src/lib/gql/queries';
import { useQuery } from '@apollo/client';

const Support = () => {
	const [code, setCode] = useState<any>();
	const [phone, setPhone] = useState<any>();
    const [pagination, setPagination] = useState({
        pageNumber: 1,
        pageSize: 10,
    });
	const [support, setSupport] = useState<any>();
    const[startDate,setStartDate]=useState<any>()
    const[endDate,setEndDate]=useState<any>()
    const [isOpenDate,SetIsOpenDate] =useState(false)
	const pageSizeOptions = ['10', '20', '50']; // Add '5' to the list of options

    const { Option } = Select;
    const { RangePicker } = DatePicker;
        const {
        loading,
        data: qryData,
        refetch: refetchQryData,
    } = useQuery(CMSQueryGetCustomerSupport, {
        variables: {
            showAll: true,
            pagination: pagination,
            filters: {
                name: "",
                code:code,
                phone:phone,
                datePeriod:support,
                fromDate: startDate ? startDate : null,
                toDate: endDate ? endDate : null,
            },
        },
    });
    const formatPhoneNumber = (phoneNumber) => {
		phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
		if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
		  return `+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`;
		} else {
		  return phoneNumber;
		}
	  };
	// const inputPhoneNumber = qryData?.getBookingServiceItem?.bookingService?.booking
		// ?.user?.phone;
		// const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);

    const columns = [
        {
			title: 'Code',
			dataIndex: 'code',
			key: 'code',
            align: 'center' as const,
			width: 120,
			// align: 'center' as const,
			// render: (
			// 	text,
			// 	record,  
			// ) => (
			// 	<>
					
			// 	</>
			// ),
		},
        {
			title: 'Customer',
			dataIndex: '',
			key: 'email',
            width: 150,
            align: 'center' as const,
            render: (
				text,
				record,  
			) =>  {
                const formatPhoneNumber = (phoneNumber) => {
                    phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
                    if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
                      return `+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`;
                    } else {
                      return phoneNumber;
                    }
                  };
                const inputPhoneNumber = record?.user?.phone
                    const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);
            
                return(
				<>
					<Link 
				to={RouteList.users.path + '/' + record.user?.id}
					>
					<span className="gx-link">
						{record?.user?.name} <br/>
					</span>
					</Link>
                        {formattedPhoneNumber} 
				</>
			);
        },
		},
        {
			title: 'Created On',
			dataIndex: '',
			key: 'createDateTime',
            width: 180,
            render: (text: string, record: any) => {
				return (
				<span>
               {moment(record?.createDateTime).fromNow()}
			</span>
				);
			},
		},
        {
			title: 'Subject',
			dataIndex: 'subject',
			key: 'subject',
		},
        {
			title: 'Feedback',
			dataIndex: 'message',
			key: 'message',
		},
	];
    const ClearAll=()=>{
        setCode("")
        setPhone("")
        setSupport(null)
        setStartDate(null)
		setEndDate(null)
    }
    const handleSupport=(value)=>{
        setStartDate(null)
        setEndDate(null)
        setSupport(value)
        if(value==="CUSTOM"){ 
            SetIsOpenDate(true)
        }
	}
    const handleOk = () => {
        SetIsOpenDate(false)
    }	
    const handleCancel = () => {
        SetIsOpenDate(false)  
    }
	const handleDate = (dates) => {
		if (dates && dates?.length === 2) {
			const startDates = moment(dates[0]?._d).toISOString();		
			const endDates = moment(dates[1]?._d).toISOString();	
			// setStartDate(moment(startDates).format('MMMM Do YYYY'));
			// setEndDate(moment(endDates).format('MMMM Do YYYY'));
			setStartDate(startDates)
			setEndDate(endDates)
		  }   
    }
    const handleInputChange = (e) => {
		console.log("e",e);
		let value = e.target.value;
		// Allow only numbers
		value = value.replace(/\D/g, '');
		setPhone(value);
	  };
	return (
        <Card
        title={"Support"}
        extra={[
            <div key={null}>
            <Button style={{borderRadius:"0.5rem"}} onClick={ClearAll}>Clear All</Button>
            </div>
		]}
        >
                  <Row
                    style={{
                        marginBottom: "16px",
                        paddingTop: "0.2rem",
                        paddingBottom: "5px",justifyContent:"center"
                    }}
                >
                    <Col span={6} style={{paddingTop:"1.5rem"}}>
                    {/* <div> */}
                     <Input
                         placeholder="Search by Code"
                        value={code}
                        // style={{ width: "250px" }}
                         allowClear
                          onChange={(e) => {
                            setCode(e.target.value);
                       }}
                      />
                     {/* </div> */}
                    </Col>
                    <Col span={6} style={{paddingTop:"1.5rem"}}>
                    {/* <div style={{paddingLeft:"1rem"}}> */}
                      <Input
                         placeholder="Search by Phone"
                          value={phone}
                        onChange={(e) => handleInputChange(e)}
								maxLength={10}
                          allowClear
                      />
                 {/* </div> */}
                    </Col>
                    <Col span={4}>
                    {/* <div style={{ paddingLeft: "0.5rem" }}> */}
                            <span style={{textAlign:"center",paddingLeft:"26px"}}>Created On</span>
							<Select getPopupContainer={(trigger) => trigger.parentElement}
								defaultValue={"All"}
								style={{ width: 120 }}
								onChange={handleSupport}
                                value={support}
							>
							<Option value={null} label={"All"} >
							<Space>
								<span role="img" aria-label={"All"}>
								All
								</span>								
							</Space>
							</Option>
                        <Option value="TODAY" label="TODAY">
						<Space>
						{DatePeriodEnum2.TODAY}
						</Space>
						</Option>
						<Option value="YESTERDAY" label="YESTERDAY">
						<Space>
						{DatePeriodEnum2.YESTERDAY}
						</Space>
						</Option>
						<Option value="THIS_WEEK" label="THIS_WEEK">
						<Space>
							{DatePeriodEnum2.THIS_WEEK}
						</Space>
						</Option>
                        <Option value="THIS_MONTH" label="THIS_MONTH">
						<Space>
							{DatePeriodEnum2.THIS_MONTH}
						</Space>
						</Option>
                        <Option value="CUSTOM" label="CUSTOM">
						<Space>
						{DatePeriodEnum2.CUSTOM}
						</Space>
						</Option>
							</Select>
						{/* </div> */}
                    </Col>
                </Row>
                 <Row>
                    <Col span={24}>
                    <div style={{float:"right"}}>
        { startDate ? moment(startDate).format("lll") :""} 
		{ startDate ? "-" :""} { endDate ?  moment(endDate).format("lll") :""}
                    </div>
                    </Col>
                </Row>
                <Row
                    style={{
                        marginBottom: 16,
                        paddingTop: "1rem",
                        backgroundColor: "#f6f6f6",
                    }}
                >
                    <Col span={24} style={{ textAlign: "center" }}>
                        <Pagination
                            key="pagination"
                            defaultCurrent={qryData?.getCustomerSupport?.pageInfo.currentPage}
							current={qryData?.getCustomerSupport?.pageInfo.currentPage}
							pageSize={pagination.pageSize}
                            total={qryData?.getCustomerSupport?.pageInfo?.totalCount}
                            showTotal={(total) => `Total ${total} items`}
                            onChange={(page, pageSize) => {
                                setPagination((prevState) => ({
                                    ...prevState,
                                    pageNumber: page,
                                    pageSize: pageSize || prevState.pageSize,
                                }));
                            }}
                            pageSizeOptions={pageSizeOptions}
                            showSizeChanger
                        />
                    </Col>
                </Row>
                {loading ? (
                    <div>Loading...</div>
               ) : ( 
                <div className="custom-table">
                    <Table
                        className="gx-table-responsive"
                        dataSource={qryData?.getCustomerSupport?.data}
                        columns={columns}
                        pagination={false}
                    /></div>
                 )} 
			{ isOpenDate && 
            <Modal
                title='Date'
                   visible={isOpenDate}
                  onOk={handleOk}
                  onCancel={handleCancel}
                 // width={800}
              >
			<div style={{textAlign:"center"}}> 
				<Space direction="vertical" size={6}>
				<RangePicker 									
				onChange={handleDate}							
				/>
				</Space> 
				</div>
            </Modal>
            }
        </Card>
	);
};

export default Support;
