import { useMutation, useQuery } from '@apollo/client';
import {
	Alert,
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Input,
	Row,
	Select,
	Space,
	Spin,
	Modal,
	Switch,
	DatePicker,
	InputNumber
} from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import UpdatePartnerCompanies from 'src/components/users/updatePartnerCompanies';
import { RouteList } from 'src/config/constants/routes';
import { CMSGetPartnerCompanies } from 'src/lib/gql/generated/CMSGetPartnerCompanies';
import { CMSGetServiceAreas } from 'src/lib/gql/generated/CMSGetServiceAreas';
import {
	CMSRegisterPartnerByAdmin,
	CMSRegisterPartnerByAdminVariables,
} from 'src/lib/gql/generated/CMSRegisterPartnerByAdmin';
import { ADD_SUB_ADMIN, REGISTER_PARTNER_BY_ADMIN, UPDATE_SUB_ADMIN } from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetRights, CMSQueryGetRoles } from 'src/lib/gql/queries/rights';
import { CMSQueryGetServiceAreas } from 'src/lib/gql/queries/services';
import { CMSQueryGetPartnerCompanies, CMSQueryGetSubAdmin } from 'src/lib/gql/queries/users';
import {  CaretLeftOutlined } from '@ant-design/icons';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 24,
			offset: 8,
		},
	},
};

const EditAdmin: React.FC = (props: any) => {
    const { id } = useParams<{ id: string }>();

    const { loading, data: qryData, } = useQuery(
		CMSQueryGetSubAdmin,
		{
			variables: {
				id: id || '',
			},
			fetchPolicy: 'network-only'
		},
	);

	const [form] = Form.useForm();
	const [form2] = Form.useForm();


	const navigate = useNavigate();
	const [checked, setChecked] = useState(false);

	// Get All Roles
	const {
	    data: rolesData,
    } = useQuery(CMSQueryGetRoles, {
		variables: {
			pagination: {
				pageNumber: 1,
				pageSize: 100
			},
			filters: {
				name: "",
			},
		},
		fetchPolicy: 'network-only',
	})

	


	

	// Get all Areas
	// const {
	// 	loading,
	// 	data: qryData,
	// 	refetch: refetchQryData,
	// } = useQuery<CMSGetServiceAreas>(CMSQueryGetServiceAreas, {
	// 	errorPolicy: 'all',
	// });

	// const PartnerCompanies = useQuery<CMSGetPartnerCompanies>(
	// 	CMSQueryGetPartnerCompanies,
	// );

    const AdminRoles = {
        data: {
            getAdminRoles: [{
                id: 1,
                roleName: 'Test role 1'
            }]
        }
    }

	// // Add Partner
	// const [
	// 	addServiceCategory,
	// 	{ data: dataMt, loading: loadingMt, error: errorMt },
	// ] = useMutation<
	// 	CMSRegisterPartnerByAdmin,
	// 	CMSRegisterPartnerByAdminVariables
	// >(REGISTER_PARTNER_BY_ADMIN);

    // Add Partner
	// const [
	// 	addServiceCategory,
	// 	{ data: dataMt, loading: loadingMt, error: errorMt },
	// ] = useMutation<
	// 	CMSRegisterPartnerByAdmin,
	// 	CMSRegisterPartnerByAdminVariables
	// >(REGISTER_PARTNER_BY_ADMIN);

	const [
		updateSubAdmin,
		{ data: dataMt, loading: loadingMt, error: errorMt },
	] = useMutation(UPDATE_SUB_ADMIN);

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select style={{ width: 150 }} defaultValue="+91" getPopupContainer={(trigger) => trigger.parentElement}>
				<Select.Option value="+91">+91</Select.Option>
				{/* <Select.Option value="971">+971</Select.Option> */}
			</Select>
		</Form.Item>
	);

	const [adminValues, setAdminValues] = useState({
		name: 'one',
		email: '',
		phone: '',
		// password: '',
		role: ''
	})

    useEffect(() => {
      if (qryData?.getSubAdmin) {
        setAdminValues({
            name: qryData?.getSubAdmin?.user?.name,
            email: qryData?.getSubAdmin?.user?.email,
            phone: qryData?.getSubAdmin?.user?.phone,
            // password: '',
            role: qryData?.getSubAdmin?.role?.id
        })

		const initialRights = qryData?.getSubAdmin?.rights?.map((r,k) => r.id)
		setRights(initialRights)
      }
    }, [qryData]) 


	const handleValuesChange = (name) => (event) => {
		setAdminValues({...adminValues, [name]: event?.target?.value})
	}
    

	const [rightsOfRole, setRightsOfRole] = useState<any[]>([])
	const [rights, setRights] = useState<string[]>([])

	const onDetailsSubmit = (values) => {
        // console.log('ffff>>>',values)
		// setAdminValues({
		// 	...adminValues,
		// 	name: values.name,
		// 	email: values.email,
		// 	phone: values.phone,
		// 	// password: values.password
		// })
		onCreateAdminFinish()
		// showModal()
		return
	};	

	const onCreateAdminFinish = () => {

		updateSubAdmin({
			variables: {
				id: id,
				data: {
					name: adminValues.name,
					email: adminValues.email,
					phone: adminValues.phone,
					role: adminValues.role,
					rights: rights
				},
			},
		}).then((res) => {
			// navigate('/partners/' + res?.data?.registerPartnerByAdmin?.id);
			navigate('/users/admins');

			form.resetFields();
		});
		
	}

    const validatePhoneNumber = (_, value) => {
        const phoneNumberRegex = /^\d{10}$/; // Regular expression for 10-digit phone number
        if (!phoneNumberRegex.test(value)) {
          return Promise.reject('Please enter a valid 10-digit phone number');
        }
        return Promise.resolve();
      };

      const passwordValidator = (_, value) => {
        if (value && value.length < 6) {
          return Promise.reject('Password must be at least 6 characters long');
        }
        return Promise.resolve();
      };
    
      const confirmPasswordValidator = (_, value) => {
        // return Promise.resolve(value === form.getFieldValue('password') ? undefined : 'Passwords do not match');
        if (value && value !== form.getFieldValue('password')) {
            return Promise.reject('Passwords do not match');
        }
          return Promise.resolve();
      };

	const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
		setIsModalVisible(true);
	};

	const onFinish = (values) => {
		console.log('oaisjfd');
	};

	const handleRightsSelection = (value, checked) => {
		if (checked) {
		  setRights([...rights, value]);
		} else {
		  const updatedArray = rights.filter((item) => item !== value);
		  setRights(updatedArray);
		}
	};

	const handleRightsOfRole = (selectedRoleId) => {
		const selectedRole = rolesData?.getRoles?.data?.find((x:any) => x.id === selectedRoleId)
		const rightsIds = selectedRole?.rights.map((right) => right.id);
		setAdminValues({...adminValues, role: selectedRoleId})
		setRights(rightsIds)
		setRightsOfRole(selectedRole?.rights)
	};

	return (
		// <Card title="Edit Admin">
<Card
				title={
					<div>
					  <Button className="gx-mr-4" onClick={() => navigate(-1)} style={{margin:"0"}}>
						<CaretLeftOutlined/>
					  </Button>
					 Edit Admin
					  
					</div>
				  }
				
			>

			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}
			<div className="gx-flex gx-flex-nowrap gx-mb-2">
				{/* <div className="gx-card-profile">
					<div className="ant-card-head " style={{ textAlign: 'center' }}>
						<Link to={RouteList.admins.path} className="gx-mr-4">
							[ Back ]
						</Link>
					</div>

					<div className="gx-pt-md-3"></div>
				</div> */}

				{!loading && (
					<Row>
						<Col span={24} className="gx-mb-4">
							{/* <h1>Add new Admin</h1> */}
							{/* <hr /> */}

							{dataMt && <Alert message="Created" type="success" showIcon />}
							{loadingMt && (
								<Alert message="Submitting..." type="info" showIcon />
							)}
							{errorMt && (
								<Alert
									message={`Submission error! ${errorMt.message}`}
									type="error"
									showIcon
								/>
							)}
						</Col>
						<Col span={24} className="gx-mb-4">
							{!loading && adminValues.phone !== '' && Array.isArray(rolesData?.getRoles?.data) && rolesData?.getRoles?.data?.length && (
								<Form
									{...formItemLayout}
									form={form}
									name="addAdmin1"
									onFinish={onCreateAdminFinish}
									// 
									
									scrollToFirstError
								>
									{/* <h3></h3> */}

									 <Form.Item
										name="name"
										label={<span>Name</span>}
										rules={[
											{
												required: true,
												message: 'Please input name',
												whitespace: true,
											},
										]}
										initialValue={adminValues.name}
									> 
										<Input value={adminValues.name} onChange={handleValuesChange('name')}/>
									</Form.Item>

									<Form.Item
										name={`email`}
										label={<span>Email</span>}
										rules={[
											{
												required: true,
												type: 'email',
											},
										]}
										initialValue={adminValues.email}
										
									>
										<Input value={adminValues.email} autoComplete="off" onChange={handleValuesChange('email')}/>
									</Form.Item>

									<Form.Item
										name="phone"
										label="Phone Number"
										rules={[
											{
												required: true,
												// message: 'Please input your phone number!',
                                                // type: 'number'
											},
                                            { validator: validatePhoneNumber },
										]}
										initialValue={adminValues.phone}
									>
										<Input
											addonBefore={prefixSelector}
											// defaultValue="+91"
											style={{ width: '100%' }}
                                            value={adminValues.phone}
											onChange={handleValuesChange('phone')}
										/>
									</Form.Item>

									{/* <Form.Item
										name="password"
										label={<span>Password</span>}
										rules={[
											{
												required: true,
												message: 'Please input password',
												whitespace: true,
											},
                                            {
                                                validator: passwordValidator
                                            }
										]}
									>
										<Input.Password />
									</Form.Item>

									<Form.Item
										name="confirmPassword"
										label={<span>Confirm Password</span>}
                                        dependencies={['password']}
										rules={[
											{
												required: true,
												message: 'Please input confi',
												whitespace: true,
											},
                                            { validator: confirmPasswordValidator },
										]}
									>
										<Input.Password />
									</Form.Item> */}

									<Form.Item
										name="role"
										label={<span>Roles</span>}
										rules={[
											{
												required: true,
												// message: 'Please input name',
												// whitespace: true,
											},
										]}
										initialValue={adminValues.role}
									>
										<Select getPopupContainer={(trigger) => trigger.parentElement}
											showSearch
											placeholder="Select Role"
											allowClear
											optionFilterProp="children"
											filterOption={(input, option: any) => {
												return (
													option?.children?.toLowerCase().includes(input) ??
													true
												);
											}}
											style={{ marginTop: '6px', marginBottom: '12px' }}
											onChange={handleRightsOfRole}
                                            value={adminValues?.role}
										>
											{rolesData?.getRoles?.data?.map((role, index) => (
													<Select.Option key={role.id} value={role.id}>
														{role.name}
													</Select.Option>
											))}
											
										</Select>
									</Form.Item>

									<Form.Item {...tailFormItemLayout} style={{textAlign: 'center'}}>
										<Button type="primary" htmlType="submit">
											Update
										</Button>
									</Form.Item>
								</Form>
							)}
						</Col>
					</Row>
				)}
			</div>

			<Modal
				// title={!input ? 'Add Coupon' : 'Edit'}
				title={'Rights'}
				visible={isModalVisible}
				onOk={onCreateAdminFinish}
				onCancel={() => setIsModalVisible(false)}
				width={600}
			>
				{/* Creating */}
				{dataMt && <Alert message="Created" type="success" showIcon />}
				{loadingMt && <Alert message="Submitting..." type="info" showIcon />}
				{errorMt && (
					<Alert
						message={`Submission error! ${errorMt.message}`}
						type="error"
						showIcon
					/>
				)}

				{/* Updating */}
				{/* {dataMtU && <Alert message="Updated" type="success" showIcon />}
				{loadingMtU && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtU && ( */}
					{/* <Alert
						message={`Submission error! `}
						type="error"
						showIcon
					/> */}
				{/* // )} */}

				<Form
					form={form2}
					name="adminRights"
					onFinish={onFinish}
					// scrollToFirstError
					// initialValues={
					// 	!input
					// 		? {}
					// 		: {
					// 				...input,
					// 				startDate: moment(input.startDate),
					// 				endDate: moment(input.endDate),
					// 		  }
					// }
				>
					<Row>
						
						{/* <Col span={12}>
							<Form.Item
								name={`maxUsePerCustomer`}
								label={<span>Max redemption per customer</span>}
								rules={[
									{
										type: 'number',
										required: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col> */}
						{rightsOfRole?.map((right, index) => (
							<React.Fragment key={right.name}>
							<Col span={3}></Col>
							<Col span={21}>
							  <Form.Item
								name={`right${index}`}
								valuePropName="checked"
								label={right.name}
							  >
								<Switch
								  onChange={(checked) => handleRightsSelection(right.id, checked)}
								  checked={rights.includes(right.id)}
								/>
							  </Form.Item>
							</Col>
						  </React.Fragment>
						))}
											
						
					</Row>
				</Form>
			</Modal>

		</Card>
	);
};
export default EditAdmin;
