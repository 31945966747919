import { useQuery } from '@apollo/client';
import {
    Avatar,
    Card,
    Col,
    DatePicker,
    Form,
    Button,
    Pagination,
    Row,
    Select,
    Space,
    Spin,
    Table, Radio, Modal,Input
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
	CMSGetBookingServiceItems,
	CMSGetBookingServiceItemsVariables,
	CMSGetBookingServiceItems_getBookingServiceItems_data,
} from 'src/lib/gql/generated/CMSGetBookingServiceItems';
import { RouteList } from 'src/config/constants/routes';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CMSQueryGetServiceAreas } from 'src/lib/gql/queries/services';
import { CMSGetServiceAreas } from 'src/lib/gql/generated/CMSGetServiceAreas';
import { CMSGetAllServiceCategories } from 'src/lib/gql/generated/CMSGetAllServiceCategories';
import { CMSQueryGetAllServiceCategories } from 'src/lib/gql/queries/services';
import { CMSGetServices, CMSGetServicesVariables } from 'src/lib/gql/generated/CMSGetServices';
import { CMSQueryGetServices } from 'src/lib/gql/queries/services';
import { UserTypeEnum, BookingServiceItemTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { CMSGetUsers, CMSGetUsersVariables } from 'src/lib/gql/generated/CMSGetUsers';
import { CMSQueryGetPartners } from 'src/lib/gql/queries/users';
import { DatePeriodEnum, BookingServiceItemStatusTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { CMSQueryGetBookingServiceItems } from 'src/lib/gql/queries/bookings';

const Rework: React.FC = () => {
    const [pagination, setPagination] = useState({
        pageNumber: 1,
        pageSize: 10,
    });
    const [radioValue, setRadioValue] = useState('createdOn');
    const { RangePicker } = DatePicker;
    const onRadioChange = (e) => {
        setRadioValue(e.target.value);
        localStorage.setItem('bookingSorting', e.target.value);
    };
    const [dateValue, SetDateValue] = useState<any>()
    const [isOpenDate, SetIsOpenDate] = useState(false)
    const [startDate, setStartDate] = useState<any>("")
    const [endDate, setEndDate] = useState<any>("")
    const [statusValue, SetStatusValue] = useState<any>()
    const [providerValue, SetProviderValue] = useState<any>()
    const [areaValue, SetAreaValue] = useState<any>()
    const [serviceValue, SetServiceValue] = useState<any>()
    const [serviceCateValue, SetServiceCateValue] = useState<any>()
    const [categoryService, SetCategoryService] = useState<any>()
    const [accountType, SetBookingType] = useState(UserTypeEnum.PARTNER);
    const [filterName, SetFilterName] = useState('');
    const { Option } = Select;
    const [searchName, setSearchName] = useState("");
	const [current, setCurrent] = useState(1);

    const {
        loading,
        data: qryData,
        called,
        error,
        refetch: refetchQryData,
    } = useQuery(
        CMSQueryGetBookingServiceItems,
        {
            variables: {
                pagination: pagination,
                filter: {
                    // ...filters,
                    useId: null,
                    scheduledDateFrom: null,
                    scheduledDateTo: null,
                    partnerAssigned: true,
                    serviceCategory: serviceCateValue,
                    service: serviceValue,
                    bookingStatus: statusValue,
                    servicePartner: providerValue,
                    area: areaValue,
                    datePeriod: dateValue ? dateValue : null,
                    sortBy: radioValue,
                    fromDate: startDate ? startDate : null,
                    toDate: endDate ? endDate : null,
                    bookingServiceItemType: BookingServiceItemTypeEnum.REWORK
                },
            },
        },
    );

    // Get all  area
    const {
        data: qryDataArea,
        refetch: refetchQryDatas,
    } = useQuery<CMSGetServiceAreas>(CMSQueryGetServiceAreas, {
        errorPolicy: 'all',
    });
    // Get all services category
    const {
        data: qryDataServiceCate,
        refetch: refetchQryDataServiceCate,
    } = useQuery<CMSGetAllServiceCategories>(CMSQueryGetAllServiceCategories);
    // Get all services
    const {
        data: qryDataService,
        refetch: refetchQryDataService,
    } = useQuery<CMSGetServices, CMSGetServicesVariables>(CMSQueryGetServices, {
        variables: {
            showAll: true,
            search: '',
        },
        errorPolicy: 'all',
    });
    // Get all partner
    const {
        data: qryDataPartner,
        refetch: refetchQryDataPartner,
    } = useQuery<CMSGetUsers, CMSGetUsersVariables>(CMSQueryGetPartners, {
        variables: {
            pagination: pagination,
            filters: {
                type: accountType,
                name: filterName,
                // phone: filterPhone,
            },
        },
    });
    useEffect(() => {
        SetCategoryService(qryDataServiceCate?.getServiceCategories?.find((value) => value.id === serviceCateValue))
    }, [serviceCateValue])


    const handleOk = () => {
        SetIsOpenDate(false)
    }
    const handleCancel = () => {
        SetIsOpenDate(false)
    }
    const handleDate = (dates) => {
        if (dates && dates?.length === 2) {
            const startDates = moment(dates[0]?._d).toISOString();
            const endDates = moment(dates[1]?._d).toISOString();
            setStartDate(startDates)
            setEndDate(endDates)
            // console.log("start", startDate);
            // console.log("end", endDates);
        }
    }
    const handleStatus = (value) => {
        SetStatusValue(value)
    }
    const handleProvider = (value) => {
        SetProviderValue(value)
    }
    const handleArea = (value) => {
        SetAreaValue(value)
    }
    const handleService = (value) => {
        SetServiceValue(value)
    }
    const handleServiceCate = (value) => {
        SetServiceCateValue(value)
    }
    const handleChanges = (value) => {
        setStartDate(null)
        setEndDate(null)
        SetDateValue(value)
        if (value === "CUSTOM") {
            SetIsOpenDate(true)
        }
    };

    const ClearAll = () => {
        SetServiceCateValue(null);
        SetServiceValue(null);
        SetAreaValue(null);
        SetProviderValue(null);
        SetStatusValue(null);
        SetDateValue(null)
        setStartDate(null)
        setEndDate(null)
        setRadioValue("scheduledOn")
    }
    const columns = [
        // {
        // 	title: 'Assign',
        // 	key: 'asign',
        // 	align: 'center' as const,
        // 	render: (text, record) => {
        // 		return <ReAssignPartner  input={record} onFinishAdd={refetchQryData} />;
        // 	},
        // },
        {
            title: 'Booking ID',
            dataIndex: 'userBookingNumber',
            key: 'userBookingNumber',
            width: 150,
            align: 'center' as const,
            render: (
                text,
                record:CMSGetBookingServiceItems_getBookingServiceItems_data,  
            ) => (
                <>
                    <Link 
                    to={RouteList.bookings.path + '/' + record?.id}
                    >

                        <span className="gx-link">
                            {record?.bookingService?.booking?.userBookingNumber}
                        </span>
                    </Link>

                </>
            ),
        },

        {
            title: 'Service',
            dataIndex: 'service',
            key: 'service',
            // width: 250,
            // align: 'center' as const,
            render: (
                text,
                record: CMSGetBookingServiceItems_getBookingServiceItems_data,
            ) => {
                return (
                    <div className="gx-ml-2 gx-d-none gx-d-sm-flex">
                        {/* <Avatar
							size="large"
							alt={'name'}
							src={`https://staging.api.zimkey.in/media-upload/${record?.bookingService?.service?.icon?.url}`}
							style={{ marginRight: '8px' }}
						/> */}
                        <span>
                            {/* <Link to="/users/users/1">
			  <span className="gx-link">View detail</span>
			  </Link> */}
                            {/* <span className="">{record?.bookingService?.service?.code}</span>
							<br /> */}
                            <span className="">{record?.bookingService?.service?.name}</span>
                        </span>
                    </div>
                );
            },
        },

        {
            title: 'Booked By',
            dataIndex: 'name',
            key: 'name',
            // width: 150,
            align: 'center' as const,
            render: (
                text,
                record: CMSGetBookingServiceItems_getBookingServiceItems_data,
                ) => {
			
                    const formatPhoneNumber = (phoneNumber) => {
                        phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
                        if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
                          return `+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`;
                        } else {
                          return phoneNumber;
                        }
                      };
                    const inputPhoneNumber = record?.bookingService?.booking?.user?.phone
                    const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);
                    return(
                    <Link
                        to={
                            RouteList.users.path +
                            '/' +
                            record?.bookingService?.booking?.user?.id
                        }
                    // target="_blank"
                    >
                        <span className="gx-link">
                            {record?.bookingService?.booking?.user?.name}
                        </span>
                        <br />
                        <span>{formattedPhoneNumber}</span>
                        <br />
                        <span>{record?.bookingService?.booking?.bookingAddress?.area?.name}</span>
    
                    </Link>
                );
                    },
            },
        {
            title: 'Total Amount',
            dataIndex: 'grandTotal',
            key: 'grandTotal',
            align: 'center' as const,
            render: (
                text,
                record: CMSGetBookingServiceItems_getBookingServiceItems_data,
            ) => (
                <>
                    <span className="">
                        ₹<b>{record?.bookingService?.booking?.bookingAmount?.grandTotal?.toLocaleString()}</b>
                    </span>

                </>
            ),
        },
        // {
        // 	title: 'Address',
        // 	dataIndex: 'areaName',
        // 	key: 'areaName',
        // children: [
        // {
        // 	title: 'Building Name',
        // 	dataIndex: 'buildingName',
        // 	key: 'buildingName',
        // },
        // {
        // 	title: 'Building Number',
        // 	dataIndex: 'buildingNumber',
        // 	key: 'buildingNumber',
        // },
        // {
        // 	title: 'Address',
        // 	dataIndex: 'address',
        // 	key: 'address',
        // },
        // {
        // 	title: 'Postal Code',
        // 	dataIndex: 'postalCode',
        // 	key: 'postalCode',
        // 	align: 'center' as const,
        // },
        // {
        // 	title: 'Address Type',
        // 	dataIndex: 'addressType',
        // 	key: 'addressType',
        // },
        // {
        // 	title: 'Landmark',
        // 	dataIndex: 'landmark',
        // 	key: 'landmark',
        // },
        // {
        // 	title: 'Area Name',
        // 	dataIndex: 'areaName',
        // 	key: 'areaName',
        // },
        // ],
        // },
        // {
        // 	title: 'Scheduled Time',
        // 	dataIndex: 'time',
        // 	key: 'time',
        // 	align: 'center' as const,
        // },
        {
            title: 'Scheduled On / Booked On',
            dataIndex: '',
            key: 'bookingDate',
            align: 'center' as const,
            render: (text: string, record: any) => {
                return (
                    <span>
                        {record?.bookingService?.unit === "HOUR" ? (
							<>
								{moment(record?.startDateTime).format('lll')} {record?.endDateTime && (` - ${moment(record?.endDateTime).format('LT')}`)} / <br />
								{moment(record?.bookingService?.booking?.createDateTime).format('lll')} 
								{/* {moment("2023-08-01T10:24:59.792Z").format('lll')} */}
							</>) : (
							<>
								{moment(record?.startDateTime).format('lll')} {record?.endDateTime && (` - ${moment(record?.endDateTime).format('lll')}`)} / <br />
								{moment(record?.bookingService?.booking?.createDateTime).format('lll')}
							</>
						)}
                    </span>
                );
            },
        },

        {
            title: 'Assigned to',
            dataIndex: 'partner',
            key: 'partner',
            align: 'center' as const,
            render: (
                text,
                record: CMSGetBookingServiceItems_getBookingServiceItems_data,
                ) =>  {
                    const formatPhoneNumber = (phoneNumber) => {
                        phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
                        if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
                          return `+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`;
                        } else {
                          return phoneNumber;
                        }
                      };
                    const inputPhoneNumber = record?.servicePartner?.phone
                    const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);
                    return(
                    <Link
                        to={RouteList.partners.path + '/' + record?.servicePartner?.id}
                    // target="_blank"
                    >
                        <span className="gx-link">{record?.servicePartner?.name}</span>
                        <br />
                        <span>{formattedPhoneNumber}</span>
                        <br />
                    </Link>
                );
                    },
            },
        {
            title: 'Status',
            dataIndex: 'areaName',
            key: 'areaName',
            align: 'center' as const,
            render: (
                text,
                record: CMSGetBookingServiceItems_getBookingServiceItems_data,
            ) => (
                <>
                    <span className="">
                        {record?.bookingServiceItemStatus}
                    </span>
                </>
            ),
        },
    ];
    const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Row>
            <Col span={24}>

                <Card title={"Rework"}
                    extra={[
                        <div key={null} style={{display:"flex"}}>
							<div key={null}>
							<Input
                                    placeholder="Search by ID"
                                    value={searchName}
                                    style={{ width: "200px" }}
                                    allowClear
                                    onChange={(e) => {
                                        setSearchName(e.target.value);
                                    }}
                                />
							</div>
                        <div style={{ float: "right",paddingLeft:"1rem"  }} key={null}>
                            Sort By {" "}
                            <Radio.Group onChange={onRadioChange} value={radioValue} >
                                <Radio value={"scheduledOn"}>Scheduled On</Radio>
                                <Radio value={"createdOn"}>Booked On</Radio>
                            </Radio.Group>
                            <Button type='primary' onClick={ClearAll}>Clear All</Button>
                        </div>    </div>
                    ]}
                >

                    <Row style={{ paddingTop: "1rem" }}>
                        <Col span={4}>
                            <span>Service Category</span>
                            <Select
                                // mode="multiple"
                                id='servicecateid'
                                style={{
                                    width: '100%',
                                }} getPopupContainer={(trigger) => trigger.parentElement}
                                placeholder="select Service Category"
                                defaultValue='All'
                                onChange={handleServiceCate}
                                onSearch={handleServiceCate}
                                optionLabelProp="label"
                                value={serviceCateValue}
                            // value={dpValue}
                            >
                                <Option value={null} label={"All"} >
                                    <Space>
                                        <span role="img" aria-label={"All"}>
                                            All
                                        </span>
                                    </Space>
                                </Option>
                                {qryDataServiceCate?.getServiceCategories.map((entry, index) => (
                                    <Option value={entry?.id} label={entry?.name} key={index}>
                                        <Space>
                                            <span role="img" aria-label={entry?.name}>
                                                {entry?.name}
                                            </span>
                                        </Space>
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={4}>
                            <span>Service</span>
                            <Select getPopupContainer={(trigger) => trigger.parentElement}
                                // mode="multiple"
								showSearch filterOption={filterOption}
                                style={{
                                    width: '100%',
                                }}
                                placeholder="select Service"
                                defaultValue={'All'}
                                onChange={handleService}
                                optionLabelProp="label"
                                value={serviceValue}

                            >
                                <Option value={null} label={"All"} >
                                    <Space>
                                        <span role="img" aria-label={"All"}>
                                            All
                                        </span>
                                    </Space>
                                </Option>

                                {serviceCateValue ? (
                                    <>
                                        {categoryService?.services?.map((entry, index) => (
                                            <Option value={entry?.id} label={entry?.name} key={index}>
                                                <Space>
                                                    <span role="img" aria-label={entry?.name}>
                                                        {entry?.name}
                                                    </span>
                                                </Space>
                                            </Option>
                                        ))}
                                        {/* {qryDataServiceCate?.filter((value) => value.id ===serviceCateValue)} */}

                                    </>
                                ) : (
                                    <>
                                        {qryDataService?.getServices.map((entry, index) => (
                                            <Option value={entry?.id} label={entry?.name} key={index}>
                                                <Space>
                                                    <span role="img" aria-label={entry?.name}>
                                                        {entry?.name}
                                                    </span>
                                                </Space>
                                            </Option>
                                        ))}
                                    </>
                                )}
                            </Select>
                        </Col>
                        <Col span={4}>
                            <span>Area</span>
                            <Select
                                // mode="multiple"
                                style={{
                                    width: '100%',
                                }} getPopupContainer={(trigger) => trigger.parentElement}
                                placeholder="select Area"
                                defaultValue={'All'}
                                onChange={handleArea}
                                optionLabelProp="label"
                                value={areaValue}
                            >
                                <Option value={null} label={"All"} >
                                    <Space>
                                        <span role="img" aria-label={"All"}>
                                            All
                                        </span>
                                    </Space>
                                </Option>

                                {qryDataArea?.getAreas.map((entry, index) => (
                                    <Option value={entry?.id} label={entry?.name} key={index}>
                                        <Space>
                                            <span role="img" aria-label={entry?.name}>
                                                {entry?.name}
                                            </span>

                                        </Space>
                                    </Option>
                                ))}

                            </Select>
                        </Col>
                        <Col span={4}>
                            <span>Provider</span>
                            <Select
                                // mode="multiple"
                                style={{
                                    width: '100%',
                                }} getPopupContainer={(trigger) => trigger.parentElement}
                                placeholder="select Provider"
                                defaultValue={'All'}
                                onChange={handleProvider}
                                optionLabelProp="label"
                                value={providerValue}
                            >
                                <Option value={null} label={"All"} >
                                    <Space>
                                        <span role="img" aria-label={"All"}>
                                            All
                                        </span>
                                    </Space>
                                </Option>
                                {qryDataPartner?.getUsers?.data.map((entry, index) => (
                                    <Option value={entry?.id} label={entry?.name} key={index}>
                                        <Space>
                                            {/* <span role="img" aria-label={entry?.name}> */}
                                            {entry?.name}
                                            {/* </span> */}

                                        </Space>
                                    </Option>
                                ))}

                            </Select>
                        </Col>

                        <Col span={4}>
                            <span>Booking Status</span>
                            <Select style={{
                                width: '100%',
                            }}
                                defaultValue={'ALL'}
                                onChange={(e) => handleStatus(e)}
                                getPopupContainer={(trigger) => trigger.parentElement}
                                value={statusValue}
                            >
                                <Select.Option value={null}>
                                    All
                                </Select.Option>
                                <Select.Option value="OPEN">
                                    {BookingServiceItemStatusTypeEnum.OPEN}
                                </Select.Option>
                                <Select.Option value="PARTNER_ASSIGNED">
                                    {BookingServiceItemStatusTypeEnum.PARTNER_ASSIGNED}
                                </Select.Option>
                                <Select.Option value="PARTNER_APPROVAL_PENDING">
                                    {BookingServiceItemStatusTypeEnum.PARTNER_APPROVAL_PENDING}
                                </Select.Option>
                                <Select.Option value="CUSTOMER_APPROVAL_PENDING">
                                    {BookingServiceItemStatusTypeEnum.CUSTOMER_APPROVAL_PENDING}
                                </Select.Option>
                                <Select.Option value="IN_PROGRESS">
                                    {BookingServiceItemStatusTypeEnum.IN_PROGRESS}
                                </Select.Option>
                                <Select.Option value="PAYMENT_PENDING">
                                    {BookingServiceItemStatusTypeEnum.PAYMENT_PENDING}
                                </Select.Option>
                                <Select.Option value="CLOSED">
                                    {BookingServiceItemStatusTypeEnum.CLOSED}
                                </Select.Option>
                                <Select.Option value="CANCELED">
                                    {BookingServiceItemStatusTypeEnum.CANCELED}
                                </Select.Option>
                            </Select>
                        </Col>
                        <Col span={4}>
                            <span>{radioValue === "scheduledOn" ? 'Scheduled On' : 'Booked On'}</span>
                            <Select
                                // mode="multiple"
                                style={{
                                    width: '100%',
                                }} getPopupContainer={(trigger) => trigger.parentElement}
                                placeholder="Select One Date"
                                defaultValue={'All'}
                                onChange={handleChanges}
                                optionLabelProp="label"
                                value={dateValue}
                            >
                                <Option value={null} label={"All"} >
                                    <Space>
                                        <span role="img" aria-label={"All"}>
                                            All
                                        </span>
                                    </Space>
                                </Option>
                                {/* <Option value="24_Hour" label={"24_Hour"} >
                                    <Space>
                                        <span role="img" aria-label={"24_Hour"}>
                                            24_Hour
                                        </span>
                                    </Space>
                                </Option> */}
                                <Option value="TODAY" label="TODAY">
                                    <Space>
                                        {/* <span role="img" aria-label="China">
                        🇨🇳
                        </span> */}
                                        {DatePeriodEnum.TODAY}
                                    </Space>
                                </Option>
                                <Option value="YESTERDAY" label="YESTERDAY">
                                    <Space>
                                        {/* <span role="img" aria-label="USA">
                        🇺🇸
                        </span> */}
                                        {DatePeriodEnum.YESTERDAY}
                                    </Space>
                                </Option>
                                {radioValue === "scheduledOn" && (
                                    <Option value="NEXT_3_DAYS" label="NEXT_3_DAYS">
                                        <Space>
                                            {/* <span role="img" aria-label="Japan">
                        🇯🇵
                        </span> */}
                                            {DatePeriodEnum.NEXT_3_DAYS}
                                        </Space>
                                    </Option>
                                )}
                                <Option value="THIS_WEEK" label="THIS_WEEK">
                                    <Space>
                                        {/* <span role="img" aria-label="Korea">
                        🇰🇷
                        </span> */}
                                        {DatePeriodEnum.THIS_WEEK}
                                    </Space>
                                </Option>
                                <Option value="THIS_MONTH" label="THIS_MONTH">
                                    <Space>
                                        {/* <span role="img" aria-label="Korea">
                        🇰🇷
                        </span> */}
                                        {DatePeriodEnum.THIS_MONTH}
                                    </Space>
                                </Option>

                                <Option value="CUSTOM" label="CUSTOM">
                                    <Space>

                                        {DatePeriodEnum.CUSTOM}
                                    </Space>
                                </Option>



                            </Select>
                            {startDate ? moment(startDate).format("lll") : ""}
                            {startDate ? "-" : ""} {endDate ? moment(endDate).format("lll") : ""}

                        </Col>

                    </Row>

                    <hr />
                    <Row>
                        <Col span={24} style={{ 
                            marginBottom: 16, paddingTop: "1rem",
                            backgroundColor: "#f6f6f6",
                            paddingBottom: "5px"
                        }}>
                            <div style={{ textAlign: "center" }}>
                                <Pagination current={current} 
                                    key="pagination"
                                    pageSize={pagination.pageSize}
                                    total={qryData?.getBookingServiceItems?.pageInfo.totalCount}
                                    showTotal={(total) => `Total ${total} items`}
                                    onChange={(page, pageSize) => {
							            setCurrent(page)
                                        setPagination({
                                            pageNumber: page,
                                            pageSize: pageSize ? +pageSize : pagination.pageSize,
                                        });
                                    }}
                                    showSizeChanger
                                /></div>
                        </Col>
                    </Row>

                    {isOpenDate &&
                        <Modal
                            title='Date'
                            visible={isOpenDate}
                            onOk={handleOk}
                            onCancel={handleCancel}
                        // width={800}
                        >

                            <div style={{ textAlign: "center" }}>
                                <Space direction="vertical" size={6}>
                                    <RangePicker
                                        onChange={handleDate}
                                    />
                                </Space>
                            </div>

                        </Modal>
                    }


                    {loading && (
                        <Row justify="center">
                            <Space size="middle">
                                <Spin size="large" />
                            </Space>
                        </Row>
                    )}
                    {!loading && (
                        <Table
                            className="gx-table-responsive"
                            columns={columns}
                            dataSource={qryData?.getBookingServiceItems?.data}
                            pagination={false}
                        />
                    )}
                </Card>
            </Col>
            <Col span={4}>
            </Col>
        </Row>

    );
};
export default Rework;
