import { useQuery } from '@apollo/client';
import {
	AutoComplete,
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Row,
	Select,
} from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CMSGetAllServiceCategories } from 'src/lib/gql/generated/CMSGetAllServiceCategories';
import { CMSQueryGetAllServiceCategories } from 'src/lib/gql/queries/services';

const SelectHomePageServices: React.FC = () => {
	const [form] = Form.useForm();

	const {
		loading,
		data: qryData,
		refetch: refetchQryData,
	} = useQuery<CMSGetAllServiceCategories>(CMSQueryGetAllServiceCategories);

	const onFinish = (values) => {
		console.log('Received values of form: ', values);
	};

	const [autoCompleteResult, setAutoCompleteResult] = useState([]);
	const navigate = useNavigate();

	return (
		<Card title="Homepage services">
			<div className="gx-flex gx-flex-nowrap gx-mb-2">
				<div className="gx-card-profile">
					<div className="ant-card-head ">
						<Button className="gx-mr-4" onClick={() => navigate(-1)}>
							Back
						</Button>

						{/* <span className="ant-card-head-title gx-mb-1">Bookings</span> */}
						{/* <p className="gx-text-grey gx-fs-sm gx-mb-0">Booking List</p> */}
					</div>

					<div className="gx-pt-md-3"></div>
				</div>

				<Form
					form={form}
					name="register"
					onFinish={onFinish}
					initialValues={{
						category: 'Home Services',
					}}
					scrollToFirstError
				>
					<Row>
						<Col span={24} className="gx-mb-4">
							<h1>Homepage services</h1>
							<hr />
						</Col>

						{qryData?.getServiceCategories.map((category) => {
							return (
								<>
									<Col span={24} key={category.name} className="gx-mb-4">
										<h3>{category.name}</h3>
										<Row>
											{category.services.map((item) => {
												return (
													<Col span={6} key={item.name}>
														<Form.Item name={item.name} valuePropName="checked">
															<Checkbox>{item.name}</Checkbox>
														</Form.Item>
													</Col>
												);
											})}
										</Row>
									</Col>
								</>
							);
						})}

						<Form.Item>
							<Button type="primary" htmlType="submit">
								Save
							</Button>
						</Form.Item>
					</Row>
				</Form>
			</div>
		</Card>
	);
};
export default SelectHomePageServices;
