import { gql } from '@apollo/client';

export const CMSQueryGetZPoints = gql`
	query CMSGetZPoints {
		getZPoints {
            id
            conversionRate
            minOrderValue
            maxRedemptionOnOrder
            minOrderValueForReward
            rewardValue
		}
	}
`;



export const UPDATE_ZPOINTS_MUTATION = gql`
mutation UpdateZPoints($data: ZPointsUpdateGqlInput!) {
      updateZPoints(data: $data) {
        id
        conversionRate
        minOrderValue
        maxRedemptionOnOrder
        minOrderValueForReward
        rewardValue
      }
    }
`;

