/* eslint-disable react/prop-types */
import React from 'react';

import ToDoItem from './ToDoItem';

const ToDoList = ({ toDos, onTodoSelect, onTodoChecked, onMarkAsStart }) => {
	return (
		<div className="gx-module-list">
			{toDos.map((todo, index) => (
				<ToDoItem
					key={index}
					index={index}
					todo={todo}
					onTodoSelect={onTodoSelect}
					onMarkAsStart={onMarkAsStart}
					onTodoChecked={onTodoChecked}
				/>
			))}
		</div>
	);
};

export default ToDoList;
