import { useQuery } from '@apollo/client';
import {
	Card,
	Col,
	Input,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Table,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import {
	CMSGetUsers,
	CMSGetUsersVariables,
} from 'src/lib/gql/generated/CMSGetUsers';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { CMSQueryGetPartners } from 'src/lib/gql/queries/users';
import { Button } from 'antd';
import moment from 'moment';
import { userAtom, userTokenAtom } from 'src/lib/recoil/userCoil';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CMSGetPartners_getUsers_data_partnerDetails } from 'src/lib/gql/generated/CMSGetPartners';
import PartnerDetail from './partnersDetail';

// Name, Bookings, Address, Account status, action

const columns = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		width: 150,
		render: (name, record) => (
			<span className="gx-link">
				<Link to={RouteList.partners.path + '/' + record.id}>{name}</Link>
			</span>
		),
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
	},
	{
		title: 'Phone',
		dataIndex: 'phone',
		key: 'phone',
		width: 150,
		render: (
			text,
			record,  
		) =>  {
			const formatPhoneNumber = (phoneNumber) => {
				phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
				if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
				  return `+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`;
				} else {
				  return phoneNumber;
				}
			  };
			const inputPhoneNumber = record?.phone
			const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);
			return(
			<>
			{formattedPhoneNumber} 
			</>
		);
	},
	},
	{
		title: 'Registered On',
		dataIndex: 'createDateTime',
		key: 'createDateTime',
		width: 180,
		align: 'center' as const,
		render: (createDateTime, record) => {
			const convertedDate = new Date(parseInt(createDateTime));
			const month = convertedDate.toLocaleString('default', { month: 'long' });
			const time = convertedDate.toLocaleTimeString();
			const timeCrt = moment(time).format('LT');
			return (
				<span>
					{/* {convertedDate.getDate()} {month}, {convertedDate.getFullYear()}
					<br />
					{timeCrt} */}
					{moment(convertedDate).fromNow()}
				</span>
			);
		},
	},
	{
		title: 'Total Bookings',
		dataIndex: 'totalBookings',
		key: 'totalBookings',
		align: 'center' as const,
		render: (bookings,record) => <span>
			{/* {bookings.length} */}
		{record?.partnerAnalytics?.totalBookings}
		</span>,
	},
	{
		title: 'Open Bookings',
		dataIndex: 'openBookings',
		key: 'openBookings',
		align: 'center' as const,
		render: (bookings,record) => (
			<span>
				{/* {bookings?.filter((b) => b.bookingStatus === 'OPEN').length} */}
			{record?.partnerAnalytics?.openBookings}
			</span>
		),
	},
	{
		title: 'Status',
		dataIndex: '',
		key: 'disableAccount',
		align: 'center' as const,
		render: (partnerDetails, record) => (
			<p>
				{record?.partnerDetails?.disableAccount === true ? (
					<p style={{ color: 'green' }}>🔴</p>
				) : (
					<p style={{ color: 'red' }}>🟢</p>
				)}
				{/* {record?.partnerDetails?.disableAccount} */}
				{/* {record?.PartnerDetail?.disableAccount===true ?<p style={{ color: 'green' }}>Activate</p> : <p style={{ color: 'red' }}>Deactivate</p>} */}
			</p>
		),
	},
	// {
	// 	title: 'Action',
	// 	dataIndex: 'key',
	// 	key: 'id',
	// 	width: 'auto',
	// 	align: 'center' as const,
	// 	render: (text, record) => (
	// 		<span>
	// 			<Link to={RouteList.partners.path + '/' + record.id} >
	// 				<span className="gx-link">
	// 					<i className="icon-view-o" style={{ marginRight: '20px' }} />
	// 				</span>
	// 			</Link>
	// 		</span>
	// 	),
	// },
];

const { Option } = Select;

const Partner: React.FC = () => {
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [filterName, setFilterName] = useState('');
	const [filterPhone, setFilterPhone] = useState('');
	const [accountType, setAccountType] = useState(UserTypeEnum.PARTNER);

	const {
		data: qryData,
		loading,
		error,
		refetch: refetchQryData,
	} = useQuery(CMSQueryGetPartners, {
		variables: {
			pagination: pagination,
			filters: {
				type: accountType,
				name: filterName,
				phone: filterPhone,
			},
		},
	});
	useEffect(() => {
		setPagination({
			...pagination,
			pageNumber: 1, // Reset pagination when filters change
		});
	}, [filterName, filterPhone, accountType]);
	useEffect(() => {
		refetchQryData();
	}, [pagination, filterName, filterPhone, accountType, refetchQryData]);

	const handlePaginationChange = (page: number, pageSize?: number) => {
		setPagination((prevPagination) => ({
			...prevPagination,
			pageNumber: page,
		}));
	};

	const handlePageSizeChange = (current: number, size: number) => {
		setPagination((prevPagination) => ({
			pageNumber: 1, // Reset to the first page when changing page size
			pageSize: 10,
		}));
	};
	const userAuth = useRecoilValue(userAtom);
	const handleInputChangeName = (e) => {
		let value = e.target.value;
		// Allow only strings (no numbers)
		value = value.replace(/[0-9]/g, '');
		setFilterName(value);
		refetchQryData();
	  };
	  const handleInputChange = (e) => {
		console.log("e",e);
		let value = e.target.value;
		// Allow only numbers
		value = value.replace(/\D/g, '');
		setFilterPhone(value);
		refetchQryData();
	  };
// console.log("aaaaaaa",qryData?.getUsers?.data);

	return (
		<Card title={<div>Partners</div>}
			extra={[
				<>
					{userAuth?.userType === UserTypeEnum.ADMIN ||
						userAuth?.rights?.find((x) => x.type === 'sub2' && x.name === 'add') ? (
						<Link to={RouteList.addPartner.path}>
							<Button type="primary" style={{ float: 'right' }}>
								Add New Partner
							</Button>
						</Link>
					) : (
						<></>
					)}
				</>,
			]}
		>
			<Row style={{
				marginBottom: "16px",
				paddingTop: "0.2rem",
				paddingBottom: "5px"
			}}>
				<Col span={24}>
					<div style={{
						justifyContent: "center",

						display: "flex"
					}}>
						<Input style={{ width: "250px" }}
							placeholder="Search by Name"
							value={filterName}
							allowClear
							onChange={(e) => handleInputChangeName(e)}
						/>
						{/* </Col> */}
						{/* <Col span={4}> */}
						<div style={{ paddingLeft: "0.5rem" }}>
							<Input style={{ width: "250px" }}
								placeholder="Search by Phone"
								value={filterPhone}
								allowClear
								onChange={(e) => handleInputChange(e)}
								maxLength={10}
							/>	</div></div>
				</Col>
				{/* 
				<Col span={16}>
				
					</Col> */}
			</Row>
			<Row>
				<Col span={24} style={{
					textAlign: "center", paddingTop: "1rem", marginBottom: 16, backgroundColor: "#f6f6f6",
					paddingBottom: "5px"
				}}>
					<Pagination
						key="pagination"
						defaultCurrent={qryData?.getUsers?.pageInfo.currentPage}
						current={qryData?.getUsers?.pageInfo.currentPage}
						pageSize={pagination.pageSize}
						total={qryData?.getUsers?.pageInfo.totalCount}
						showTotal={(total) => `Total ${total} items`}
						// onChange={handlePaginationChange}
						showSizeChanger
						pageSizeOptions={['10', '20', '50', '100']}
						onShowSizeChange={handlePageSizeChange}
						selectComponentClass={Select}
						showLessItems
						onChange={(page, pageSize) => {
							setPagination({
								pageNumber: page,
								pageSize: pageSize ? +pageSize : pagination.pageSize,
							});
						}}
					/>
				</Col>
			</Row>
			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}
			{qryData && (
				<Table
					className="gx-table-responsive"
					columns={columns}
					dataSource={qryData?.getUsers?.data}
					pagination={false}
				/>
			)}
		</Card>
	);
};

export default Partner;
