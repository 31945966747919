import { useQuery } from '@apollo/client';
import {
	Card,
	Col,
	Input,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Table,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import {
	CMSGetUsers,
	CMSGetUsersVariables,
} from 'src/lib/gql/generated/CMSGetUsers';
import { CMSGetUser,CMSGetUserVariables} from 'src/lib/gql/generated/CMSGetUser';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { CMSQueryGetUsers } from 'src/lib/gql/queries/users';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom';
import { CMSQueryGetUser } from 'src/lib/gql/queries/users';
import { CMSQueryGetUserBookings } from 'src/lib/gql/queries/bookings';
import { CMSGetUserBookings,CMSGetUserBookingsVariables } from 'src/lib/gql/generated/CMSGetUserBookings';
import moment from 'moment';
import { CMSQueryGetUserBookingServiceItems } from 'src/lib/gql/queries/bookings';

const { Option } = Select;
interface Props {
	bookingsData: any;
}

const BookingDetails = ({ bookingsData }: Props) => {

// console.log("bookingsData",bookingsData);

	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [filterName, setFilterName] = useState('');
	const [filterPhone, setFilterPhone] = useState('');
	const [accountType, setAccountType] = useState(UserTypeEnum.CUSTOMER);

	// const { data: qryData, loading } = useQuery<
	// 	CMSGetUsers,
	// 	CMSGetUsersVariables
	// >(CMSQueryGetUsers, {
	// 	variables: {
	// 		pagination: pagination,
	// 		filters: {
	// 			type: accountType,
	// 			name: filterName,
	// 			phone: filterPhone,
	// 		},
	// 	},
	// });
    const { id } = useParams<{ id: string }>();

	const { data: qryData, loading } = useQuery<
		CMSGetUserBookings,
		CMSGetUserBookingsVariables
	>(CMSQueryGetUserBookings, {
		variables: {
			pagination: pagination,
			useId:id !
		},
	});	

	const { data: qryDataBooking } = useQuery
	(CMSQueryGetUserBookingServiceItems, {
		variables: {
			pagination: pagination,
			userId: id,
		},
	});

    // const { id } = useParams<{ id: string }>();
	// const { data: qryData } = useQuery<CMSGetUser, CMSGetUserVariables>(
	// 	CMSQueryGetUser,
	// 	{
	// 		variables: {
	// 			id:id!
    //         }
	// 	},
	// 	);
		
	// 	console.log('lkl',qryData?.getUser)
   
	// const handlePhoneFilter = (value: string | undefined) => {
	// 	setFilterPhone(value || '');
	// };

	const columns = [
		{
			title: 'Booking ID',
			dataIndex: '',
			key: 'userBookingNumber',
			width: 'auto',
			align: 'center' as const,
			render: (text: string, record: any) => (
				<span>
					<Link to={RouteList.bookings.path + '/' + record.id} >
					{record?.bookingService?.booking?.userBookingNumber}
						{/* <i className="icon-view-o" style={{ marginRight: '20px' }} /> */}
					</Link>
				</span>
			),
		},
		{
			title: 'Provider Info',
			dataIndex: 'user',
			key: 'user',
			width: 'auto',
			align: 'center' as const,
			render: (text, record) => (
				<span>
					<Link to={RouteList.partners.path + '/' + record?.servicePartner?.id}  style={{cursor:"pointer"}}>
						<span className="gx-link">
							{record?.servicePartner?.name}
						</span>
					</Link> 
				</span>
			),
		},
		{
			title: 'Total Amount',
			dataIndex: '',
			key: 'bookingPayments',
			// align: 'center' as const,
			// render: (bookingPayments:any) => <span>{bookingPayments.amount}</span>,
			align: 'center' as const,
			render: (record: any) => <span>₹{record?.bookingService?.booking?.bookingAmount?.grandTotal}</span>,

			// render: (bookingPayments: any[]) => (
				// <span>{bookingPayments?.map((b) => b.amount )}</span>
			// ),
			
			// onFilter: (value: string | number | boolean, record: any) =>
			// 	record.phone.includes(value.toString()),
			// filterDropdown: () => (
			// 	<div style={{ padding: 8 }}>
			// 		<Input
			// 			placeholder="Search mobile number"
			// 			value={filterPhone}
			// 			onChange={(e) => handlePhoneFilter(e.target.value)}
			// 			onPressEnter={() => console.log('Enter pressed')}
			// 			style={{ width: 188, marginBottom: 8, display: 'block' }}
			// 		/>
			// 	</div>
			// ),
			// filterIcon: () => (
			// 	<SearchOutlined style={{ color: filterPhone ? '#1890ff' : undefined }} />
			// ),
		},
		{
			title: 'Service',
			dataIndex: '',
			key: 'service',
			render: (record: any) => <span>{record?.bookingService?.service?.name}</span>,
		},
		// {
		// 	title: 'Booking Status',
		// 	dataIndex: '',
		// 	key: 'bookingStatus',
		// 	render: (text: string, record: any) => (
		// 		<span>{record?.bookingService?.booking?.bookingPayments?.status}</span>
		// 	),
			// align: 'center' as const,
			// render: (bookings: any[]) => (
			// 	<span>{bookings?.filter((b) => b.bookingStatus === 'OPEN').length}</span>
			// ),
		// },
        // {
		// 	title: 'Payment Status',
		// 	dataIndex: '',
		// 	align: 'center' as const,
			// render: (text: string, record: any) => (
				// return (record?.bookingService?.booking?.bookingPayments[0]?.status === 'CUSTOMER' ? <span >🟢</span> : <span >🔴</span>)
				// <span>{record?.bookingService?.booking?.bookingPayments[0]?.status=== 'CUSTOMER' ? <span >🟢</span> : <span >🔴</span>}</span>
			// ),
			// render: (status: string) => {
			// 	return (record?.bookingService?.booking?.bookingPayments[0]?.status === 'CUSTOMER' ? <span >🟢</span> : <span >🔴</span>)

			// },
		// },
		{
			title: 'Scheduled On',
			dataIndex: 'bookingDate',
			key: 'bookings',
			align: 'center' as const,
			render: (text: string, record: any) => (
				<span>{moment(record?.bookingService?.booking?.bookingDate).format('lll')}</span>
			),
		},
		{
			title: 'Booked On',
			dataIndex: 'startDateTime',
			key: 'createDateTime',
			align: 'center' as const,
			render: (text: string, record: any) => (
				<span>{moment(record?.startDateTime).format('lll')}</span>
			),
		},

		{
			title: 'Status',
			dataIndex: '',
			key: 'bookingPayments',
			align: 'center' as const,
			render: (record: any) => <span>{record?.bookingServiceItemStatus}</span>,
		},
		// {
		// 	title: 'Action',
		// 	dataIndex: 'key',
		// 	key: 'id',
		// 	width: 'auto',
		// 	align: 'center' as const,
		// 	render: (text: string, record: any) => (
		// 		<span>
		// 			<Link to={RouteList.users.path + '/' + record.id} target="_blank">
		// 				<i className="icon-view-o" style={{ marginRight: '20px' }} />
		// 			</Link>
		// 		</span>
		// 	),
		// },
	];

	useEffect(() => {
		setPagination({
			...pagination,
			pageNumber: 1, // Reset pagination when filters change
		});
	}, [filterName, filterPhone, accountType]);

	// const exportData = qryData?.getUsers?.data || []; // Extract the data to be exported

	// console.log("Dataaa -2",qryData?.getUser?.bookings);  

	
	return (
		<Card >
			{/* <Row>
				<Col span={4}>
					<Input
						placeholder="Search by Name"
						value={filterName}
						allowClear
						onChange={(e) => setFilterName(e.target.value)}
					/>
				</Col>
				<Col span={4}>
					<Input
						placeholder="Search by Phone"
						value={filterPhone}
						allowClear
						onChange={(e) => setFilterPhone(e.target.value.trim())}
					/>
				</Col>
				<Col>
					<Select
						defaultValue={UserTypeEnum.CUSTOMER}
						style={{ width: 120 }}
						onChange={(value) => setAccountType(value)}
					>
						<Option value={UserTypeEnum.CUSTOMER}>{UserTypeEnum.CUSTOMER}</Option>
						<Option value={UserTypeEnum.PENDING}>{UserTypeEnum.PENDING}</Option>
					</Select>
				</Col>
				<Col span={12}>
					<Pagination
						key="pagination"
						defaultCurrent={qryData?.getUsers?.pageInfo.currentPage}
						current={qryData?.getUsers?.pageInfo.currentPage}
						pageSize={pagination.pageSize}
						total={qryData?.getUsers?.pageInfo.totalCount}
						showTotal={(total) => `Total ${total} items`}
						onChange={(page, pageSize) => {
							setPagination({
								pageNumber: page,
								pageSize: pageSize ? +pageSize : pagination.pageSize,
							});
						}}
						showSizeChanger
					/>
				</Col>
			</Row> */}

			{/* {loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)} */}

			{/* Data export button */}
			{/* <Row justify="end" style={{ marginBottom: 16 }}>
				<CSVLink
					data={exportData}
					filename="users.csv"
					className="ant-btn ant-btn-primary"
					target="_blank"
				>
					<DownloadOutlined /> Export CSV
				</CSVLink>
			</Row> */}

			{/* Table */}
			<Row justify="end" style={{ marginBottom: 16 ,paddingTop:"1rem",
										backgroundColor: "#f6f6f6",
										paddingBottom: "5px"}}>
				<Col span={24} style={{textAlign:"center"}}>
					<Pagination
						key="pagination"
						defaultCurrent={qryDataBooking?.getUserBookingServiceItems?.pageInfo.currentPage}
						current={qryDataBooking?.getUserBookingServiceItems?.pageInfo.currentPage}
						pageSize={pagination.pageSize}
						total={qryDataBooking?.getUserBookingServiceItems?.pageInfo.totalCount}
						showTotal={(total) => `Total ${total} items`}
						onChange={(page, pageSize) => {
							setPagination({
								pageNumber: page,
								pageSize: pageSize ? +pageSize : pagination.pageSize,
							});
						}}
						showSizeChanger
					/>
				</Col>						
			</Row>
			<Table
				className="gx-table-responsive"
				columns={columns}
				dataSource={qryDataBooking?.getUserBookingServiceItems?.data || undefined}
				pagination={false}
			/>
		</Card>
	);
};

export default BookingDetails;
