import { useMutation, useQuery } from '@apollo/client';
import {
	Card,
	Col,
	Input,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Table,
	Button,
	Modal,
	Switch,
} from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import {
	CMSGetUsers,
	CMSGetUsersVariables,
} from 'src/lib/gql/generated/CMSGetUsers';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { CMSQueryGetUsers } from 'src/lib/gql/queries/users';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'src/lib/recoil/userCoil';
import { CMSQueryGetPersonnel } from 'src/lib/gql/queries/chk';
import {
	CREATE_PERSONNEL,
	UPDATE_PERSONNEL,DELETE_PERSONNEL
} from 'src/lib/gql/mutations/mutations';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

const Personnel: React.FC = () => {
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [filterName, setFilterName] = useState('');
	const [filterPhone, setFilterPhone] = useState('');
	const [name, setName] = useState<any>();
	const [phone, setPhone] = useState<any>();
	const [rank, setRank] = useState<any>();

	const [accountType, setAccountType] = useState(UserTypeEnum.CUSTOMER);
	const userAuth = useRecoilValue(userAtom);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
	const [isModalVisibleDelete, setIsModalVisibleDelete] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [phoneError, setPhoneError] = useState(false);
	const [rankError, setRankError] = useState(false);

	const [filterUid, setFilterUid] = useState('');

	const {
		data: qryData,
		loading,
		refetch: refetchQryData,
	} = useQuery(CMSQueryGetPersonnel, {
		variables: {
			pagination: pagination,
			filters: {
				name: filterName,
				phone: filterPhone,
				uid: filterUid
			},
		},
		fetchPolicy: 'network-only',
	});
	const handlePhoneFilter = (value: string | undefined) => {
		setFilterPhone(value || '');
	};
	const handleInputChange = (e) => {
		// console.log("e",e);
		const value = e.target.value;
		// // Allow only numbers
		// value = value.replace(/\D/g, '');
		setFilterUid(value);
	};
	const handleInputChangeName = (e) => {
		const value = e.target.value;
		// Allow only strings (no numbers)
		setFilterName(value);
	};

	const pageSizeOptions = ['10', '20', '50']; // Add '5' to the list of options

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: 150,
			render: (name: string, record: any) => (
				<span className="">
					{/* <Link to={RouteList.users.path + '/' + record.id}> */}
						{name}
						{/* </Link> */}
				</span>
			),
		},
		{
			title: 'ID',
			dataIndex: 'uid',
			key: 'uid',
			width: 150,
			render: (uid: string, record: any) => (
				<span className="">
					{/* <Link to={RouteList.users.path + '/' + record.id}> */}
						{uid}
						{/* </Link> */}
				</span>
			),
		},
		{
			title: 'Rank',
			dataIndex: 'rank',
			key: 'rank',
			width: 100,
			render: (rank: string, record: any) => (
				<span className="">
					{/* <Link to={RouteList.users.path + '/' + record.id}> */}
						{rank}
						{/* </Link> */}
				</span>
			),
		},
		{
			title: 'Mobile Number',
			dataIndex: 'phone',
			key: 'phone',
			width: 150,
			render: (phone, record) => {
				const formatPhoneNumber = (phoneNumber) => {
					phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
					if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
						return `+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`;
					} else {
						return phoneNumber;
					}
				};
				const inputPhoneNumber = record?.phone;
				const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);
				return <>{formattedPhoneNumber}</>;
			},
		},
		{
			title: 'Registered On',
			dataIndex: 'createDateTime',
			key: 'createDateTime',
			render: (createDateTime, record) => {
				const convertedDate = new Date(createDateTime);
				const time = convertedDate.toLocaleTimeString();
				return (
					<span style={{ textAlign: 'center' }}>
						{/* {/* {convertedDate.getDate()} {month}, {convertedDate.getFullYear()}
                            <br /> */}
						{/* {timeCrt} */}
						{moment(convertedDate).fromNow()}
					</span>
				);
			},
		},
		{
			title: 'Total Bookings',
			dataIndex: 'totalBookings',
			key: 'totalBookings',
			align: 'center' as const,
			render: (totalBookings: number) => <span>{totalBookings}</span>,
		},
		{
			title: 'Open Bookings',
			dataIndex: 'openBookings',
			key: 'openBookings',
			align: 'center' as const,
			render: (openBookings: number) => <span>{openBookings}</span>,
		},

		{
			title: 'Status',
			dataIndex: 'isActive',
			align: 'center' as const,
			render: (isActive: boolean, record: any) => {
				return isActive ? <span>🟢</span> : <span>🔴</span>;
			},
		},
		{
			title: 'Action',
			dataIndex: 'key',
			key: 'id',
			width: 'auto',
			align: 'center' as const,
			render: (text: string, record: any) => (
				<>
				<span onClick={() => handleOpenModalEdit(record)} style={{cursor: 'pointer'}}>
					<EditOutlined style={{ marginRight: '20px', color: '#5143dc' }} />
				</span>
				<span onClick={() => handleOpenModalDelete(record)} style={{cursor: 'pointer'}}>
				<DeleteOutlined style={{ color: "red" ,marginRight: '20px'}} />
			</span>
			</>
			),
		},
	];

	const [
		createPersonnel,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation(CREATE_PERSONNEL);

	const [selectedPersonnel, setSelectedPersonnel] = useState<any>();
	const [deletedPersonnel, setdeletedPersonnel] = useState<any>();
	const [
		updatePersonnel,
		{ data: dataEditMtU, loading: loadingEditMtU, error: errorEditMtU },
	] = useMutation(UPDATE_PERSONNEL);

	const [
		deletePersonnel,
		{ data: dataEditMtUd, loading: loadingEditMtUd, error: errorEditMtUd },
	] = useMutation(DELETE_PERSONNEL);

	const handleOpenModalEdit = (e) => {
		const selectedPerson = qryData.getPersonnel.data.find(
			(x, i) => x.id === e.id,
		);
		setSelectedPersonnel(selectedPerson);
		setEditPersonnelStatus(selectedPerson?.isActive);

		setIsModalVisible(true);
		setIsModalVisibleEdit(true);
	};
	const handleOpenModalDelete = (e) => {
		const selectedPerson = qryData.getPersonnel.data.find(
			(x, i) => x.id === e.id,
		);
		setdeletedPersonnel(selectedPerson);
		setIsModalVisibleDelete(true)
	}

	const handleOpenModal = () => {
		setIsModalVisible(true);
		setIsModalVisibleEdit(false);
	};

	// handle edit changes
	const handleEditChanges = (field) => (e) => {
		setSelectedPersonnel({...selectedPersonnel, [field]: e.target.value});
	}


	const handleOk = () => {
		if (isModalVisibleEdit) {
			updatePersonnel({
				variables: {
					data: { 
						id: selectedPersonnel.id,
						name: selectedPersonnel.name, 
						phone: selectedPersonnel.phone, 
						rank: selectedPersonnel.rank,
						isActive: editPersonnelStatus
					},
				},
				onCompleted: () => {
					refetchQryData();
				},
			});

		} else {
			if( !name){
				setNameError(true)
			}else if(!phone){
				setPhoneError(true)
				}else if(!rank){
					setRankError(true)
			}else{
				setNameError(false)
				setPhoneError(false)
				setRankError(false)
				createPersonnel({
					variables: {
						data: { name, phone, rank },
					},
					onCompleted: () => {
						refetchQryData();
						setName("")
						setPhone("")
						setRank("")
						setIsModalVisibleEdit(false);
						setIsModalVisible(false);
					},
				});
			}
		}
	
	};
	const handleCancel = () => {
		setIsModalVisible(false);
		setIsModalVisibleEdit(false);
	};
	const handleCancelDelete = () => {
		setIsModalVisibleDelete(false);
	};
	const handleOkDelete = () => {
		deletePersonnel({
			variables: {
			id: deletedPersonnel.id,
			},
			onCompleted: () => {
				refetchQryData();
				setIsModalVisibleDelete(false);
			},
		});
		
	};
	useEffect(() => {
		setPagination({
			...pagination,
			pageNumber: 1, // Reset pagination when filters change
		});
	}, [filterName, filterPhone, accountType]);

	const [editPersonnelStatus, setEditPersonnelStatus] = useState(false);

	useEffect(() => {
		console.log(
			'🚀 ~ file: personnel.tsx:241 ~ useEffect ~ editPersonnelStatus:',
			editPersonnelStatus,
		);
	}, [editPersonnelStatus]);

	return (
		<Card
			title={<div>Personnel</div>}
			extra={[
				<>
					<Button
						onClick={handleOpenModal}
						type="primary"
						style={{ float: 'right' }}
					>
						Add New Personnel
					</Button>
				</>,
			]}
		>
			<Row
				style={{
					marginBottom: '16px',
					paddingTop: '0.2rem',
					paddingBottom: '5px',
				}}
			>
				<Col span={24}>
					<div
						style={{
							justifyContent: 'center',
							display: 'flex',
						}}
					>
						<div>
							<Input
								type="string"
								style={{ width: '250px' }}
								placeholder="Search by Name"
								value={filterName}
								allowClear
								onChange={(e) => handleInputChangeName(e)}
							/>
						</div>

						{/* </Col> */}
						{/* <Col span={4}> */}
						<div style={{ paddingLeft: '0.5rem' }}>
							<Input
								style={{ width: '250px', paddingLeft: '1rem' }}
								placeholder="Search by ID"
								value={filterUid}
								allowClear
								onChange={(e) => handleInputChange(e)}
								// maxLength={10}
							/>
						</div>
						{/* </Col> */}
						{/* <Col> */}
					</div>
				</Col>
				{/* <Col span={12}>
				
				</Col> */}
			</Row>

			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}

			{/* Data export button */}

			{userAuth?.userType === UserTypeEnum.ADMIN ||
			userAuth?.rights?.find(
				(x) => x.type === 'sub2' && x.name === 'export-csv',
			) ? (
				<Row
					justify="end"
					style={{
						marginBottom: 16,
						paddingTop: '1rem',
						backgroundColor: '#f6f6f6',
						paddingBottom: '5px',
					}}
				>
					<Col span={24} style={{ textAlign: 'center' }}>
						<Pagination
							key="pagination"
							defaultCurrent={qryData?.getTeams?.pageInfo.currentPage}
							current={qryData?.getTeams?.pageInfo.currentPage}
							pageSize={pagination.pageSize}
							total={qryData?.getTeams?.pageInfo.totalCount}
							showTotal={(total) => `Total ${total} items`}
							onChange={(page, pageSize) => {
								setPagination({
									pageNumber: page,
									pageSize: pageSize ? +pageSize : pagination.pageSize,
								});
							}}
							showSizeChanger
							pageSizeOptions={pageSizeOptions} // Set the pageSizeOptions prop
						/>
					</Col>
				</Row>
			) : (
				<></>
			)}

			{/* Table */}
			<Table
				className="gx-table-responsive"
				columns={columns}
				dataSource={qryData?.getPersonnel?.data}
				pagination={false}
			/>

			{/* modal edit */}
			<Modal
				title={isModalVisibleEdit ? 'Edit Personnel' : 'Add New Personnel'}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
				bodyStyle={{
					padding: '1rem 3rem',
				}}
			>
				<Row>
					<Col span={12} style={{ width: '100px' }}>
						<span>Name </span>
						<Input onChange={isModalVisibleEdit ? handleEditChanges('name') : (e) => setName(e.target.value)} value={isModalVisibleEdit ? selectedPersonnel?.name : name} />
						{nameError && <p style={{color:"red"}}>Requried</p>}
					</Col>
					<Col span={12} style={{ width: '100px' }}>
						<span>Mobile Number </span>
						<Input onChange={isModalVisibleEdit ? handleEditChanges('phone') : (e) => setPhone(e.target.value)} value={isModalVisibleEdit ? selectedPersonnel?.phone : phone} />
						{phoneError && <p style={{color:"red"}}>Requried</p>}
					</Col>
					<Col span={12} style={{ width: '100px', marginTop: '10px' }}>
						<span>Rank </span>
						<Input onChange={isModalVisibleEdit ? handleEditChanges('rank') : (e) => setRank(e.target.value)} value={isModalVisibleEdit ? selectedPersonnel?.rank : rank} />
					{rankError && <p style={{color:"red"}}>Requried</p>}
					</Col>
					{isModalVisibleEdit && (
						<Col
							span={12}
							style={{
								width: '100px',
								marginTop: '10px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<span style={{ marginRight: '1rem' }}>Status </span>
							<Switch
								onChange={(e) => setEditPersonnelStatus(e)}
								checked={editPersonnelStatus}
							/>
						</Col>
					)}
				</Row>
			</Modal>

				{/* modal delete */}
				<Modal
				title={'Delete Personnel'}
				visible={isModalVisibleDelete}
				onOk={handleOkDelete}
				onCancel={handleCancelDelete}
				width={500}
				bodyStyle={{
					padding: '1rem 3rem',
				}}
			>
			<p>Are you sure want to delete?</p>
			</Modal>
		</Card>
	);
};

export default Personnel;
