import { gql } from '@apollo/client';

export const CMSQueryGetPayoutsHistory = gql`
	query CMSGetPayoutsHistory(
		$pagination: PaginationGqlInput!
		$filters: PayoutsQueryGqlInput!
	) {
		getPayoutsHistory(pagination: $pagination, filters: $filters) {
			data {
                id
                user {
                    id 
                    name
                }
                payout_id
                payout_reference_id
                amount
				status
                source
                transactionDate
                walletRef
            }
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;

export const CMSQueryGetTeams = gql`
	query CMSGetTeams(
		$pagination: PaginationGqlInput!
		$filters: TeamQueryGqlInput!
	) {
		getTeams(pagination: $pagination, filters: $filters) {
			data {
				id
				uid
				name
				partnerId
				strength
				members {
					id
					name
					uid
					phone
					rank
					isActive
				}
				isActive
				partner {
					id
					name
					email
					phone
					dob
					gender
					uid
				}
			}
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;

export const CMSQueryGetPayoutSettings= gql`
	query CMSgetPayoutSettings{
        getPayoutSettings {
		payoutSchedule
	}
}
`;

export const CMSQueryGetPendingPayoutsPartner = gql`
	query CMSGetPendingPayoutsPartner(
		$filters: PendingPayoutsUserGqlInput!
	) {
		getPendingPayoutsPartner(filters: $filters) {
				id
				name
				email
				userType
				isActive
				uid
				phone
				partnerAnalytics {
					totalEarnings
				}
				bookings {
					id
					bookingStatus
				}

				createDateTime
				partnerDetails {
					id
					walletBalance
					approved
					disableAccount
					accountNumber
					pendingTasks
				}
		}
	}
`;

export const CMSQueryGetPendingPayoutsBooking = gql`
	query CMSGetPendingPayoutsBooking(
		$filters: PendingPayoutsBookingGqlInput!
	) {
		getPendingPayoutsBooking(filters: $filters) {
			id
			bookingServiceId
			startDateTime
			endDateTime
			units
			bookingServiceItemStatus
			bookingService {
				unit
				service {
					id
					code
					name
					icon {
						id
						url
					}
				}
				booking {
					id
					userBookingNumber
					createDateTime
					bookingAmount {
						totalCommission
                        partnerAmount
						grandTotal
					}
					bookingAddress {
						id
						buildingName
						postalCode
						area {
							name
						}
						addressType
						landmark
					}
					user {
						id
						name
						phone
					}
					bookingStatus
					bookingDate
				}
			}
			servicePartner {
				id
				name
				phone
			}
		}
	}
`;