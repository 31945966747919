import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Card, Radio, Rate, Badge,Alert, Steps, Modal,DatePicker,notification } from 'antd';
import ProfileHeader from 'src/components/profile/profileHeader/index';
import About from 'src/components/profile/about/index';
import Contact from 'src/components/profile/contact/index';
import CircularProgress from 'src/components/CircularProgress';
import { useQuery } from '@apollo/client';
import { CMSQueryGetBookingServiceItem } from 'src/lib/gql/queries/bookings';
import { useParams } from 'react-router-dom';
import { CMSGetBookingServiceItem } from 'src/lib/gql/generated/CMSGetBookingServiceItem';
import Widget from 'src/components/widget';
import { Button, Dropdown, message, Space, Tooltip } from 'antd';
import {
	MailOutlined,
	MobileOutlined,
	HomeOutlined,
	CopyOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ReAssignPartner from 'src/components/services/reAssignPartner';
import moment from 'moment';
import { CMSQueryGetBookingServiceItems } from 'src/lib/gql/queries/bookings';
import {
	CMSGetBookingServiceItems,
	CMSGetBookingServiceItemsVariables,
} from 'src/lib/gql/generated/CMSGetBookingServiceItems';
import { useNavigate } from 'react-router-dom';
import { CaretLeftOutlined } from '@ant-design/icons';
import noData from '../../../src/components/dashboard/noDataa.png';
import AssignPartner from 'src/components/services/assignPartner';
import { CMSQueryGetJobBoard,CMSQueryGetServiceBookingSlots } from 'src/lib/gql/queries/bookings';
import { RouteList } from 'src/config/constants/routes';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userAtom, userTokenAtom } from 'src/lib/recoil/userCoil';
import { BookingServiceItemStatusTypeEnum, BookingServiceItemTypeEnum, PartnerCalendarStatusTypeEnum, UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { RESCHEDULE_JOB } from 'src/lib/gql/mutations/mutations';
import { useMutation } from '@apollo/client';

export const friendList = [
	{
		id: 1,
		image: 'https://via.placeholder.com/150',
		name: 'Electrician',
		status: 'online',
	},
	{
		id: 2,
		image: 'https://via.placeholder.com/150',
		name: 'Plumber',
		status: 'offline',
	},
	{
		id: 3,
		image: 'https://via.placeholder.com/150',
		name: 'Cleaning',
		status: 'away',
	},
	{
		id: 4,
		image: 'https://via.placeholder.com/150',
		name: 'Cleaning',
		status: 'away',
	},
];

//Dummy data end

const columns = [
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
	},
	{
		title: 'Date',
		dataIndex: 'date',
		key: 'date',
	},
];

const data: any = [];

const BookingDetail: React.FC = () => {
	const navigate = useNavigate();

	const [loader, setLoader] = useState(false);
	const [news, setNews] = useState(0);
	const [bookingTab, setBookingTab] = useState(0);
	const [qty, setQty] = useState<any>();
	const [units, setUnits] = useState<any>();
	const [partnerPrice, setPartnerPrice] = useState<any>();
	const [platformPrice, setPlatformPrice] = useState<any>();
	const [partnerDiscount, setPartnerDiscount] = useState<any>();
	const [platformDiscount, setPlatformDiscount] = useState<any>();
	const [partnerAmount, setPartnerAmount] = useState<any>();
	const [platformAmount, setPlatformAmount] = useState<any>();
	const [partnerGstRate, setPartnerGstRate] = useState<any>();
	const [platformGstRate, setPlatformGstRate] = useState<any>();
	const [partnerCgst, setPartnerCgst] = useState<any>();
	const [platformCgst, setPlatformCgst] = useState<any>();
	const [partnerSgst, setPartnerSgst] = useState<any>();
	const [platformSgst, setPlatformSgst] = useState<any>();
	const [partnerTotal, setPartnerTotal] = useState<any>();
	const [platformTotal, setPlatformTotal] = useState<any>();
	const [subTotal, setSubTotal] = useState<any>();
	const [discount, setDiscount] = useState<any>();
	const [amount, setAmount] = useState<any>();
	const [sgstTotal, setSgstTotal] = useState<any>();
	const [cgstTotal, setCgstTotal] = useState<any>();
	const [grandTotal, setGrandTotal] = useState<any>();
	const [payStatus, setPayStatus] = useState<any>();
	const [filterBillingOption, setFilterBillingOption] = useState<any>();
	const [dateSlots, setDateSlots] = useState<any>();
	const [dateSlotsRadio, setDateSlotsRadio] = useState<any>();
	const [selectedDateRange, setSelectedDateRange] = useState({ start: null, end: null });
	const [updateAlert, setUpdateAlert] = useState(false)
	const [errorUpdate, setErrorUpdate] = useState(false);
	const [minUnitCal, setMinUnitCal] = useState<any>();
	const [phoneFormat, setPhoneFormat] = useState<any>();

	const baseRazorpayURL = 'https://dashboard.razorpay.com/app/payments/';

	const hangleBooking = (e) => {
		setBookingTab(e.target.value);
	};
	const clearError = () => {
		setErrorUpdate(true);
	  };
	const handleChange = (e) => {
		const value = e.target.value;
		setNews(e.target.value);
		setLoader(true);
		setTimeout(() => {
			setLoader(false);
		}, 1500);
	};

	const items = [
		{
			label: '1st menu item',
			key: '1',
		},
		{
			label: '2nd menu item',
			key: '2',
		},
		{
			label: '3rd menu item',
			key: '3',
		},
		{
			label: '4rd menu item',
			key: '4',
		},
	];
	const handleMenuClick = (e) => {
		message.info('Click on menu item.');
	};
	const menuProps = {
		items,
		onClick: handleMenuClick,
	};

	const { id } = useParams<{ id: string }>();
	const userContact: any = [];
	const partnerContact: any = [];

	const {
		loading,
		data: qryData,
		called,
		error,
		refetch: refetchQryDataa,
	} = useQuery(CMSQueryGetBookingServiceItem, {
		variables: {
			id: id,
		},
		fetchPolicy: 'network-only',
	});

	
	const commissionPrice = qryData?.getBookingServiceItem?.bookingService?.bookingAdditionalPayments?.itemPrice?.grandTotal;

	// Check if commissionPrice is not null or undefined before rendering
	const commissionDisplay = commissionPrice !== undefined ? `₹ ${commissionPrice.toLocaleString()}` : '';
  
	// getServiceBookingSlots
	const {
		data: qryDataSlots,
		refetch: refetchQryDataaSlots,
	} = useQuery(CMSQueryGetServiceBookingSlots, {
		variables: {
			billingOptionId:qryData?.getBookingServiceItem?.bookingService?.serviceBillingOptionId ?qryData?.getBookingServiceItem?.bookingService?.serviceBillingOptionId :"",
			date:dateSlots ?dateSlots :"",
			isReschedule: true,
			bookingServiceItemId: id
			// units:qryData?.getBookingServiceItem?.units
		},	
		fetchPolicy: 'network-only',
	});
	// reschedule job
	const [
		reschduleJob,
		{ data: dataMtUs, loading: loadingMtUs, error: errorMtUs },
	] = useMutation(RESCHEDULE_JOB,{
		onCompleted: () => {
			refetchQryDataa()
			
		},
	});

	const handleDateSlots=(date)=>{
	setDateSlots(date)
	}

	const formatPhoneNumber = (phoneNumber) => {
		phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
		if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
		  return `+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`;
		  setPhoneFormat(`+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`)
		} else {
		  return phoneNumber;
		  setPhoneFormat(phoneNumber)
		}
	  };

	//   useEffect(()=>{
		const inputPhoneNumber = qryData?.getBookingServiceItem?.bookingService?.booking
		?.user?.phone;
		const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);

		const inputPhoneNumberss=qryData?.getBookingServiceItem?.bookingService
		?.booking?.bookingAddress?.alternatePhoneNumber
		const formattedPhoneNumberss = formatPhoneNumber(inputPhoneNumberss);

	//   },[qryData?.getBookingServiceItem?.bookingService?.booking
	// 	?.user?.phone,qryData?.getBookingServiceItem?.bookingService
	// 	?.booking?.bookingAddress?.alternatePhoneNumber])

	const handleDateSlotsRadio = (start, end) => {
		// Update the selected date range in state
		
		setSelectedDateRange({ start, end });
	  };

	useEffect(() => {
		// let aaaa
		const aaa = qryData?.getBookingServiceItem?.statusHistory?.map(
			(status, index) => {
				// aaaa=status
				setPayStatus(status?.status);
			},
		);

		
	}, [qryData]);

	useEffect(() => {
		const sss =
			qryData?.getBookingServiceItem?.bookingService?.service?.billingOptions?.find(
				(value) =>
					value.id ===
					qryData?.getBookingServiceItem?.bookingService
						?.serviceBillingOptionId,
			);
		setFilterBillingOption(sss);
	}, [qryData]);

	useEffect(() => {
		setQty(qryData?.getBookingServiceItem?.bookingService?.qty);
		setUnits(qryData?.getBookingServiceItem?.units);


		setPartnerPrice(
			qryData?.getBookingServiceItem?.chargedPrice
				?.partnerRate,
		);
		setPlatformPrice(
			qryData?.getBookingServiceItem?.chargedPrice
				?.commissionRate,
		);
		setPartnerDiscount(
			qryData?.getBookingServiceItem?.chargedPrice
				?.partnerDiscount,
		);
		setPlatformDiscount(
			qryData?.getBookingServiceItem?.chargedPrice
				?.commissionDiscount,
		);
		setPartnerAmount(
			qryData?.getBookingServiceItem?.chargedPrice
				?.partnerAmount,
		);
		setPlatformAmount(
			qryData?.getBookingServiceItem?.chargedPrice
				?.commissionAmount,
		);
		setPartnerGstRate(
			qryData?.getBookingServiceItem?.chargedPrice
				?.partnerGSTPercentage,
		);
		setPlatformGstRate(
			qryData?.getBookingServiceItem?.chargedPrice
				?.commissionGSTPercentage,
		);
		setPartnerCgst(
			qryData?.getBookingServiceItem?.chargedPrice
				?.partnerGSTAmount / 2,
		);
		setPartnerSgst(
			qryData?.getBookingServiceItem?.chargedPrice
				?.partnerGSTAmount / 2,
		);
		setPlatformCgst(
			qryData?.getBookingServiceItem?.chargedPrice
				?.commissionGSTAmount / 2,
		);
		setPlatformSgst(
			qryData?.getBookingServiceItem?.chargedPrice
				?.commissionGSTAmount / 2,
		);
		setPartnerTotal(
			qryData?.getBookingServiceItem?.chargedPrice
				?.totalPartnerAmount,
		);
		setPlatformTotal(
			qryData?.getBookingServiceItem?.chargedPrice
				?.totalCommission,
		);
		setSubTotal(
			qryData?.getBookingServiceItem?.chargedPrice
				?.subTotal,
		);
		setDiscount(
			qryData?.getBookingServiceItem?.chargedPrice
				?.totalDiscount,
		);
		setAmount(
			qryData?.getBookingServiceItem?.chargedPrice
				?.totalAmount,
		);
		setCgstTotal(
			qryData?.getBookingServiceItem?.chargedPrice
				?.totalGSTAmount / 2,
		);
		setSgstTotal(
			qryData?.getBookingServiceItem?.chargedPrice
				?.totalGSTAmount / 2,
		);
		setGrandTotal(
			qryData?.getBookingServiceItem?.chargedPrice
				?.grandTotal,
		);



		// setPartnerPrice(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.partnerRate,
		// );
		// setPlatformPrice(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.commissionRate,
		// );
		// setPartnerDiscount(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.partnerDiscount,
		// );
		// setPlatformDiscount(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.commissionDiscount,
		// );
		// setPartnerAmount(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.partnerAmount,
		// );
		// setPlatformAmount(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.commissionAmount,
		// );
		// setPartnerGstRate(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.partnerGSTPercentage,
		// );
		// setPlatformGstRate(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.commissionGSTPercentage,
		// );
		// setPartnerCgst(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.partnerGSTAmount / 2,
		// );
		// setPartnerSgst(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.partnerGSTAmount / 2,
		// );
		// setPlatformCgst(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.commissionGSTAmount / 2,
		// );
		// setPlatformSgst(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.commissionGSTAmount / 2,
		// );
		// setPartnerTotal(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.totalPartnerAmount,
		// );
		// setPlatformTotal(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.totalCommission,
		// );
		// setSubTotal(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.subTotal,
		// );
		// setDiscount(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.totalDiscount,
		// );
		// setAmount(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.totalAmount,
		// );
		// setCgstTotal(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.totalGSTAmount / 2,
		// );
		// setSgstTotal(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.totalGSTAmount / 2,
		// );
		// setGrandTotal(
		// 	qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount
		// 		?.grandTotal,
		// );



		setMinUnitCal(qryData?.getBookingServiceItem?.units-qryData?.getBookingServiceItem?.bookingService
			?.serviceBillingOption.minUnit )
		//   ?.serviceBillingOption?.minUnit );

		// const pp = qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount?.partnerRate
		// const pd=qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount?.totalDiscount
		// const qt=qryData?.getBookingServiceItem?.bookingService?.qty
		// const platPrice=pp * 21.185
		// const ppp=platPrice/100

		// let pa=0
		// let cgst=0
		// let sgst=0
		// let platAmt=0
		// let pcgst=0
		// let psgst=0
		// if(qt&&pp&&typeof(pd) == 'number'){
		// const val=qt*pp
		// const discountedAmount = val - pd
		// pa=discountedAmount
		// setPartnerAmount(discountedAmount)
		// }
		// if(qt&&ppp&&typeof(pd) == 'number'){
		// const ass=qt* ppp
		// const as=ass-pd
		// platAmt=as
		// setPlatformAmount(as)
		// }
		// setPartnerGstRate("5%")
		// setPlatformGstRate("18%")
		// if(pa){
		// 	const dis=pa *5
		// 	const diid=dis/100
		// 	const ddd=diid/2
		// 	cgst=ddd
		// 	sgst=ddd
		// setPartnerCgst(ddd)
		// setPartnerSgst(ddd)
		// }
		// if(platAmt){
		// 	const vll=platAmt*18
		// 	const vaal=vll/100
		// 	const vaa=vaal/2
		// 	pcgst=vaa
		// 	psgst=vaa
		// setPlatformCgst(vaa)
		// setPlatformSgst(vaa)
		// }
		// if(pa&&cgst&&sgst){
		// setPartnerTotal(pa+sgst+cgst)
		// }
		// if(platAmt&&psgst&&pcgst){
		// setPlatformTotal((platAmt+pcgst+psgst))
		// }
	}, [qryData]);

	// useEffect(()=>{
	// 	const dt=qty*partnerPrice+platformAmount
	// 	setSubTotal(dt)
	// 	const dd=partnerDiscount+platformDiscount
	// 	setDiscount(dd?.toLocaleString())
	// 	const amt=dt-dd
	// 	setAmount(amt?.toLocaleString())
	// 	const ct=partnerCgst+platformCgst
	// 	setCgstTotal(ct)
	// 	const st=partnerSgst+platformSgst
	// 	setSgstTotal(st)
	// 	setGrandTotal(Math.round(amt+ct+st))
	// },[partnerAmount])

	// const {
	// 	data: qryDat,
	// 	refetch: refetchQryDatass,
	// } = useQuery(CMSQueryGetJobBoard, {
	// 	variables: {
	// 		pagination: "",
	// 		filter:{
	// 			areaIds: null ,
	// 			serviceIds: null,
	// 			autoAssign: true,
	// 			serviceCategoryIds: null,
	// 			bookingStatus: null,
	// 			sortBy:null ,
	// 			fromDate: null ,
	// 			toDate: null,
	// 			datePeriod: null,
	// 		}
	// 	},
	// });

	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [filters, setFilters] = useState<any>({
		useId: null,
		scheduledDateFrom: null,
		scheduledDateTo: null,
		partnerAssigned: true,
		// area: '',
	});

	const { data: qryDatas, refetch: refetchQryData } = useQuery<
		CMSGetBookingServiceItems,
		CMSGetBookingServiceItemsVariables
	>(CMSQueryGetBookingServiceItems, {
		variables: {
			pagination: pagination,
			filter: {
				...filters,
			},
			// useId: '',
			// partnerAssigned: true,
		},
	});

	const [dateTime, setDateTime] = useState('');
	const [rating, setRating] = useState<any>();
	const [status, setStatus] = useState('');
	const [billingHr, setBillingHr] = useState<any>();
	const [copiedId, setCopiedId] = useState<any>(false);
	const [copiedWcode, setCopiedWcode] = useState<any>(false);
	const [openReschedule,setOpenReschedule]= useState<any>();
	const handleReschedule=()=>{
		setOpenReschedule(true)
	}
	const handleOk=()=>{
	reschduleJob({	
		variables: {
			bookingServiceItemId:qryData?.getBookingServiceItem?.id,
			scheduleTime:selectedDateRange.start,
			scheduleEndDateTime:selectedDateRange.end,
			modificationReason:""
		}}).then(() => {
				setUpdateAlert(true)
				setTimeout(() => {
				setUpdateAlert(false)
				setOpenReschedule(false)
				setDateSlots(null)
				setSelectedDateRange({ start: null, end: null })
				}, 2000)
				refetchQryDataa()
			})	
			.catch((error) => {
				console.log("Reschedule error",error);
				setErrorUpdate(false);
				setTimeout(() => {
					setErrorUpdate(true);
				});
				<Alert
						message={`Submission error! ${error.message}`}
						type="error"
						showIcon
					/>
			});
		}

	const handleCancel=()=>{
		setOpenReschedule(false)	
	}

	useEffect(() => {
		setTimeout(() => {
			if (copiedId) {
				setCopiedId(false);
			}
		}, 2000);
	}, [copiedId]);
	useEffect(() => {
		setTimeout(() => {
			if (copiedWcode) {
				setCopiedWcode(false);
			}
		}, 2000);
	}, [copiedWcode]);

	useEffect(() => {
		const one =
			qryData?.getBookingServiceItem?.bookingService?.serviceBillingOptionId;
		const two =
			qryData?.getBookingServiceItem?.bookingService?.service?.billingOptions?.find(
				(b) =>
					b.id ===
					qryData?.getBookingServiceItem?.bookingService
						?.serviceBillingOptionId,
			);

		setBillingHr(two?.name);
	}, [qryData?.getBookingServiceItem?.bookingService]);

	useEffect(() => {
		setRating(qryData?.getBookingServiceItem?.review);
	}, [qryData]);

	useEffect(() => {
		if (
			qryData?.getBookingServiceItem?.bookingService?.booking?.bookingStatus
		) {
			const string =
				qryData?.getBookingServiceItem?.bookingService?.booking?.bookingStatus;
			const status =
				string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

			setStatus(status);
		}
	}, [qryData?.getBookingServiceItem?.bookingService?.booking?.bookingStatus]);

	useEffect(() => {
		if (qryData?.getBookingServiceItem?.startDateTime) {
			const datetime = moment(
				qryData?.getBookingServiceItem?.startDateTime,
			).format('lll');
			setDateTime(datetime);
		}
	}, [qryData?.getBookingServiceItem?.startDateTime]);

	let aboutList: Array<any> = [];

	if (!loading) {
		const date = new Date(
			qryData?.getBookingServiceItem?.bookingService.booking.bookingDate,
		);

		aboutList = [
			{
				id: 1,
				title: 'Service booked',
				icon: 'cards-list-view',
				userList: '',
				desc: qryData?.getBookingServiceItem?.bookingService?.service?.name,
			},
			{
				id: 3,
				title: 'Booked created on',
				icon: 'calendar',
				userList: '',
				desc: [``],
			},
			{
				id: 2,
				title: 'Booking date',
				icon: 'calendar',
				userList: '',
				desc: [`${date}`],
			},
			{
				id: 4,
				title: 'Status',
				icon: 'circle-o',
				userList: '',
				desc: [
					`${qryData?.getBookingServiceItem?.bookingService.booking.bookingStatus}`,
				],
			},
		];

		if (qryData?.getBookingServiceItem?.bookingService.booking.user) {
			userContact.push({
				id: 1,
				title: 'Name',
				icon: 'user',
				desc: [
					<span className="gx-link" key={1}>
						{qryData?.getBookingServiceItem?.bookingService.booking.user.name}
					</span>,
				],
			});
			userContact.push({
				id: 1,
				title: 'Phone',
				icon: 'phone',
				desc: [
					<span className="gx-link" key={1}>
						{qryData?.getBookingServiceItem?.bookingService.booking.user.phone}
					</span>,
				],
			});

			partnerContact.push({
				id: 1,
				title: 'Name',
				icon: 'user',
				desc: [
					<span className="gx-link" key={1}>
						{qryData?.getBookingServiceItem?.servicePartner?.name}
					</span>,
				],
			});

			partnerContact.push({
				id: 1,
				title: 'Phone',
				icon: 'phone',
				desc: [
					<span className="gx-link" key={1}>
						{qryData?.getBookingServiceItem?.servicePartner?.phone}
					</span>,
				],
			});
		}
	}
	const { Step } = Steps;
	const hRef = useRef<HTMLInputElement>(null);
	const copyId = () => {
		if (hRef.current) {
			const text = hRef?.current?.innerText;
			navigator.clipboard
				.writeText(text.trim())
				.then(() => {
					setCopiedId(true);
				})
				.catch((error) => {
					console.log();
				});
		}
		openNotification()
	};
	const hRefs = useRef<HTMLInputElement>(null);

	const copyWcode = () => {

		if (hRefs.current) {
			const texts = hRefs?.current?.innerText;
			navigator.clipboard
				.writeText(texts.trim())
				.then(() => {
					setCopiedWcode(true);
				})
				.catch((error) => {
					console.log();
					
				});
		}
		openNotification()
	};
	const openNotification = () => {
		notification.open({
		  message: 'Copied',
		  description:
			'Text copied successfully!',
		 
		});
	  };
	const editDetails = () => {
		return (
			<ReAssignPartner
				input={qryData?.getBookingServiceItem}
				onFinishAdd={refetchQryData}
			/>
		);
	};

const userAuth = useRecoilValue(userAtom);
	
	return (
		<>
			<Card>
				<Row style={{ paddingBottom: '0.5rem' }}>
					<Col span={20}>
						{/* {copiedId && (
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<Alert
									message="Copied"
									type="success"
									showIcon
									style={{ width: '300px' }}
								/>
							</div>
						)} */}
						<div style={{ display: 'flex' }}>
							<Button
								className="gx-mr-4"
								onClick={() => navigate(-1)}
								style={{ margin: '0' }}
							>
								<CaretLeftOutlined />
							</Button>
							<h2>Booking Details:</h2>
							<span
								ref={hRef}
								style={{ paddingLeft: '0.5rem', paddingTop: '3px' }}
							>
								{
									qryData?.getBookingServiceItem?.bookingService?.booking
										?.userBookingNumber
								}{' '}
								<CopyOutlined onClick={copyId} />
							</span>
						</div>
					</Col>
				</Row>
				<Widget>
					<div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-dash-search">
						<div className="gx-mx-sm-2">
							<Radio.Group
								className="gx-radio-group-link gx-radio-group-link-news"
								defaultValue={news}
								onChange={handleChange}
							>
								<Radio.Button value={0} className="gx-mb-1">
									Details
								</Radio.Button>
								<Radio.Button value={1} className="gx-mb-1">
									Status
								</Radio.Button>
								<Radio.Button value={2} className="gx-mb-1">
									Payments
								</Radio.Button>
								<Radio.Button value={3} className="gx-mb-1">
									Review
								</Radio.Button>
							</Radio.Group>
						</div>
					</div>
					{loader && <CircularProgress className="gx-loader-10" />}
					{news === 0 ? (
						<div>
							<Card>
								<Row>
									<Col span={12}>
										{/* {copiedWcode && (
											<div
												style={{ display: 'flex', justifyContent: 'center' }}
											>
												<Alert
													message="Copied"
													type="success"
													showIcon
													style={{ width: '300px' }}
												/>
												
											</div>
										)} */}

                                        {qryData?.getBookingServiceItem?.bookingServiceItemType === BookingServiceItemTypeEnum.REWORK && (<>
											<div style={{ display: 'flex' }}>
												<h5>Job Type:</h5>
												<span
													ref={hRefs}
													style={{
														paddingLeft: '0.5rem',
														marginTop: '-2px',
														color: '#f96d38',
													}}
												>
													<b>
													{qryData?.getBookingServiceItem?.bookingServiceItemType}
													</b>
												</span>
											</div>

											<div style={{ display: 'flex' }}>
												<h5>Primary Booking:</h5>
												<span
													ref={hRefs}
													style={{ 
														paddingLeft: '0.5rem', 
														marginTop: '-2px',
														color: '#f96d38',
														textDecoration: 'underline'
													 }}
												>
													<Link
													to={
														RouteList.bookings.path +
														'/' +
														qryData?.getBookingServiceItem?.refBookingServiceItem
															?.id
													}
														>
															{qryData?.getBookingServiceItem?.bookingService?.booking?.userBookingNumber}
															<br />
														</Link>
														
												</span>
											</div>

										</>)}
										{qryData?.getBookingServiceItem?.bookingServiceItemType === BookingServiceItemTypeEnum.ADDITIONAL && (<>
											<div style={{ display: 'flex' }}>
												<h5>Job Type:</h5>
												<span
													ref={hRefs}
													style={{
														paddingLeft: '0.5rem',
														marginTop: '-2px',
														color: '#f96d38',
													}}
												>
													<b>
													{qryData?.getBookingServiceItem?.bookingServiceItemType}
													</b>
												</span>
											</div>

											<div style={{ display: 'flex' }}>
												<h5>Primary Booking:</h5>
												<span
													ref={hRefs}
													style={{ 
														paddingLeft: '0.5rem', 
														marginTop: '-2px',
														color: '#f96d38',
														textDecoration: 'underline'
													 }}
												>
													<Link
															to={
																RouteList.bookings.path +
																'/' +
																qryData?.getBookingServiceItem?.refBookingServiceItem
																	?.id
															}
														>
															{qryData?.getBookingServiceItem?.bookingService?.booking?.userBookingNumber}
															<br />
														</Link>
														
												</span>
											</div>

										</>)}

										<div style={{ display: 'flex' }}>
											<h5>Work Code:</h5>
											<span
												ref={hRefs}
												style={{ paddingLeft: '0.5rem', marginTop: '-2px' }}
											>
												{qryData?.getBookingServiceItem?.workCode}{' '}
												<CopyOutlined onClick={copyWcode} />
											</span>
										</div>

										{/* <h6>Booking Placed:  {dateTime}</h6> */}
										<div style={{ display: 'flex' }}>
											<h5>Status:</h5>
											<span
												// ref={hRefs}
												style={{
													paddingLeft: '0.5rem',
													marginTop: '-2px',
													color: '#f96d38',
												}}
											>
												<b>
													{
														qryData?.getBookingServiceItem
														?.bookingServiceItemType === BookingServiceItemTypeEnum.REWORK && 

														qryData?.getBookingServiceItem?.activePartnerCalender === PartnerCalendarStatusTypeEnum.REWORK_REJECTED ?

														PartnerCalendarStatusTypeEnum.REWORK_REJECTED :

														qryData?.getBookingServiceItem
															?.bookingServiceItemStatus
													}   
												</b>
											</span>
										</div>
										<div style={{ display: 'flex' }}>
											{qryData?.getBookingServiceItem.actualEndDateTime&&(
										<>
										<h5>Cloesd On:</h5>		<span
												// ref={hRefs}
												style={{
													paddingLeft: '0.5rem',
													marginTop: '-2px',
													color: '#f96d38',
												}}
											> 
											 {moment(qryData?.getBookingServiceItem.actualEndDateTime).format('lll')}
											</span>
										</>
									)}</div>
									</Col>
									<Col span={12}>
										{qryData?.getBookingServiceItem.bookingServiceItemStatus==="CANCELED" || qryData?.getBookingServiceItem.bookingServiceItemStatus==="CLOSED" ?(
											<></>
										):(
										<div className="" style={{ textAlign: 'right' }}>
											{qryData?.getBookingServiceItem
												?.bookingServiceItemType === 'REWORK' ? (
												<></>
											) : qryData?.getBookingServiceItem?.servicePartnerId ? (
												<>
													{userAuth?.userType === UserTypeEnum.ADMIN ||
											userAuth?.rights?.find(
												(x) => x.type === 'sub2' && x.name === 'bookings-reassign',
											) ? (
												<ReAssignPartner
														input={qryData?.getBookingServiceItem}
														onFinishAdd={refetchQryDataa}
													/>
											) : (
												<></>
											)}
													
												</>
											) : (
												<>
												{userAuth?.userType === UserTypeEnum.ADMIN ||
											userAuth?.rights?.find(
												(x) => x.type === 'sub2' && x.name === 'unassigned-bookings-assign',
											) ? (
												<AssignPartner
													input={qryData?.getBookingServiceItem?.jobBoard}
													onFinishAdd={refetchQryDataa}
												/>
											) : (
												<></>
											)}</>
												
											)}

											<Button type="primary" onClick={handleReschedule}>Reschedule</Button>
										</div>
										) }
									</Col>
								</Row>
								<hr />
								<Row>
									<Col span={12}>
										<>
								{qryData?.getBookingServiceItem?.workNote ?(
									<>
										<h5>Work Note</h5>
										{/* <span style={{color:"red"}}>Cash / Online</span><br/> */}
										{/* Amount:  <span style={{fontSize: "18px"}}>₹</span>{" "} */}
										{
											qryData?.getBookingServiceItem?.workNote
												
										}
									{/* // </Col> */}
									</>
								):qryData?.getBookingServiceItem?.bookingService?.booking?.bookingNote ?(
									// <Col span={12}>
									<>
										<h5>Booking Note</h5>
										{/* <span style={{color:"red"}}>Cash / Online</span><br/> */}
										{/* Amount:  <span style={{fontSize: "18px"}}>₹</span>{" "} */}
										{
											qryData?.getBookingServiceItem?.bookingService?.booking
												?.bookingNote
										}
										</>
									):("")}
									</></Col>
								<br/>
								
								
									<Col span={12} >
										<div style={{ float: "right" }}>
											<span style={{ fontWeight: "500" }}>Booked On: </span> 
											
											 {/* {moment("2023-08-01T10:24:59.792Z").format('lll')} */}
											 {moment(qryData?.getBookingServiceItem?.bookingService?.booking?.bookingDate).format('lll')}
											<br />
											<span style={{ fontWeight: '500' }}>
												Scheduled On:
											</span>{' '}
											{qryData?.getBookingServiceItem?.bookingService?.unit ===
											'HOUR' ? (
												<>
													{dateTime}{' '}
													{qryData?.getBookingServiceItem?.endDateTime &&
														` - ${moment(
															qryData?.getBookingServiceItem?.endDateTime,
														).format('LT')}`}
												</>
											) : (
												<>
													{dateTime}{' '}
													{qryData?.getBookingServiceItem?.endDateTime &&
														` - ${moment(
															qryData?.getBookingServiceItem?.endDateTime,
														).format('LT')}`}
												</>
											)}
										</div>
									</Col>
								</Row>
								<Row>
								<Col span={12}>
										{ qryData?.getBookingServiceItem?.modificationReason && (
											<>
										<span style={{ fontWeight: '500' }}>
											Reschedule Note:
											</span>{' '}{qryData?.getBookingServiceItem?.modificationReason}
											</>
											)}
									</Col>	
								</Row>
								<hr />
								<Row>
									<Col span={8}>
										<Card
											style={{
												backgroundColor: '#eeeeff',
												borderRadius: '1rem',
												height: '100%',
											}}
										>
											<h5>Service Partner</h5>
											{qryData?.getBookingServiceItem?.servicePartner ? (
												<>
													<span className="gx-link">
														<Link
															to={
																RouteList.partners.path +
																'/' +
																qryData?.getBookingServiceItem?.servicePartner
																	?.id
															}
														>
															{
																qryData?.getBookingServiceItem?.servicePartner
																	?.name
															}
															<br />
														</Link>
													</span>
													<MobileOutlined />{' '}
													{
														qryData?.getBookingServiceItem?.servicePartner
															?.phone
													}
													<br />
												</>
											) : (
												<>
													<br />
													<p
														style={{
															textAlign: 'center',
															fontStyle: 'italic',
															paddingTop: 'inherit',
														}}
													>
														No data avilable
													</p>
												</>
											)}
										</Card>
									</Col>
									<Col span={8}>
										<Badge.Ribbon
											text={
												qryData?.getBookingServiceItem?.bookingService?.booking
													?.bookingAddress?.addressType
											}
											color={'green'}
										>
											<Card
												style={{
													backgroundColor: '#eeeeff',
													borderRadius: '1rem',
													height: '210px',
												}}
											>
												<Row>
													<Col span={16}>
														<h5>Booking Address</h5>{' '}
													</Col>
												</Row>
												<div style={{display: "flex",
    												gap: "21px",
    												justifyContent: "space-between"}}>
												<b>House/ Flat No:</b>
												<span style={{ float: 'right',overflow:"hidden" }}>
													{qryData?.getBookingServiceItem?.bookingService
														?.booking?.bookingAddress?.buildingName && (
														<>
															{
																qryData?.getBookingServiceItem?.bookingService
																	?.booking?.bookingAddress?.buildingName
															}
														
														</>
													)}
												</span>
												</div>
											<div style={{display: "flex",
    												gap: "21px",
    												justifyContent: "space-between"}}>
												<b>Locality:</b>{' '}
												<span style={{ float: 'right',overflow:"hidden"  }}>
													{qryData?.getBookingServiceItem?.bookingService
														?.booking?.bookingAddress?.locality && (
														<>
															{
																qryData?.getBookingServiceItem?.bookingService
																	?.booking?.bookingAddress?.locality
															}
														</>
													)}{' '}
												</span></div>
												
												<div style={{display: "flex",
    												gap: "21px",
    												justifyContent: "space-between"}}>
												{qryData?.getBookingServiceItem?.bookingService?.booking
													?.bookingAddress?.landmark && (
													<>
														<b>Landmark:</b>
														<span style={{ float: 'right' ,overflow:"hidden" }}>
															{
																qryData?.getBookingServiceItem?.bookingService
																	?.booking?.bookingAddress?.landmark
															}
														</span>{' '}
														
													</>
												)}</div>
												<div >
												<b>City:</b>
												<span style={{ float: 'right' ,overflow:"hidden" }}>
													{
														qryData?.getBookingServiceItem?.bookingService
															?.booking?.bookingAddress?.area?.city?.name
													}
												</span>{' '}
												</div>	<div >
												<b>Area:</b>
												<span style={{ float: 'right',overflow:"hidden"  }}>
													{
														qryData?.getBookingServiceItem?.bookingService
															?.booking?.bookingAddress?.area?.name
													}
												</span>{' '}
												</div><div >
												<b>Postal Code:</b>
												<span style={{ float: 'right',overflow:"hidden"  }}>
													{
														qryData?.getBookingServiceItem?.bookingService
															?.booking?.bookingAddress?.postalCode
													}
												</span>{' '}</div>
												<br />
												{/* <b>Ph:</b>{" "} */}
											</Card>
										</Badge.Ribbon>
									</Col>
									<Col span={8}>
										<Card
											style={{
												backgroundColor: '#eeeeff',
												borderRadius: '1rem',
												height: '100%',
											}}
										>
											<h5>Customer</h5>
											<span className="gx-link">
												<Link
													to={
														RouteList.users.path +
														'/' +
														qryData?.getBookingServiceItem?.bookingService
															?.booking?.user?.id
													}
												>
													{
														qryData?.getBookingServiceItem?.bookingService
															?.booking?.user?.name
													}
													<br />
												</Link>
											</span>

											{qryData?.getBookingServiceItem?.bookingService?.booking
												?.user?.email && (
												<>
													<MailOutlined />{' '}
													{
														qryData?.getBookingServiceItem?.bookingService
															?.booking?.user?.email
													}
													<br />
												</>
											)}
											{qryData?.getBookingServiceItem?.bookingService?.booking
												?.user?.phone && (
												<>
													<MobileOutlined />{' '}
													{
														// qryData?.getBookingServiceItem?.bookingService
															// ?.booking?.user?.phone 
															formattedPhoneNumber
													} (Primary) 
													<br />
												</>
											)}
												{qryData?.getBookingServiceItem?.bookingService?.booking
												?.bookingAddress?.alternatePhoneNumber&&qryData?.getBookingServiceItem?.bookingService?.booking
												?.bookingAddress?.alternatePhoneNumber !==
												qryData?.getBookingServiceItem?.bookingService?.booking
												?.user?.phone && (
												<>
													<MobileOutlined />{' '}
													{formattedPhoneNumberss} 
												</>
											)}
										</Card>
									</Col>
								</Row>
								<br />
								{qryData?.getBookingServiceItem?.bookingService
									?.serviceRequirements.length !== 0 ? (
									<>
										<hr />
										<h4>Service Requirements</h4>
										<Row key={1}>
											{qryData?.getBookingServiceItem?.bookingService?.serviceRequirements.map(
												(entry, index) => (
													<>
													{entry==="Other" ? (
													<>	</>
													):(
														<>
													<Col span={3} key={index}>
														<Card
															style={{
																backgroundColor: '#eeeeff',
																textAlign: 'center',
																marginTop: '1rem',
																borderRadius: '1rem',
																marginRight: '-27px',
															}}
															className="serviceReqirement"
														>
															{entry}
														</Card>
													</Col>
													</>
													)}
											
												</>
												),
											)}
													{qryData?.getBookingServiceItem?.bookingService?.otherRequirements && (
														<Col span={3} >
														<Card
															style={{
																backgroundColor: 'rgb(208 208 208)',
																textAlign: 'center',
																marginTop: '1rem',
																borderRadius: '1rem',
																marginRight: '-27px',
															}}
															className="serviceReqirement"
														>
															{qryData?.getBookingServiceItem?.bookingService?.otherRequirements }
														</Card>
													</Col>
													)}
										</Row>
										<hr />
									</>
								) : (
									''
								)}
								{/* <hr />
								{qryData?.getBookingServiceItem?.otherRequirements && (
									<>
										<h4>Other Requirements</h4>
										<Row>
											<Col span={24}>
												<Card
													style={{
														backgroundColor: '#eeeeff',
														textAlign: 'center',
														marginTop: '1rem',
														borderRadius: '1rem',
														marginRight: '-27px',
														width: 'fit-content',
														padding: '5px',
													}}
													className="serviceReqirement"
												>
													{qryData?.getBookingServiceItem?.otherRequirements}
												</Card>
											</Col>
										</Row>
										<hr />
									</>
								)} */}

								<div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-dash-search">
									<div className="gx-mx-sm-2">
										<Radio.Group
											className="gx-radio-group-link gx-radio-group-link-news"
											defaultValue={bookingTab}
											onChange={hangleBooking}
										>
											<Radio.Button value={0} className="gx-mb-1">
												Booking Summary
											</Radio.Button>
											{qryData?.getBookingServiceItem?.additionalWorks?.length > 0 && qryData?.getBookingServiceItem?.additionalWorks.find((i) => i.bookingAdditionalWorkStatus == 'PAID') && (
											<Radio.Button value={1} className="gx-mb-1">
												Additional Work
											</Radio.Button>
											)}
										</Radio.Group>
									</div>
								</div>
								{/* 0 */}
								{bookingTab === 0 ? (
									<>
										<div style={{textAlign:"end",fontWeight:"bold",color:"#ff9061"}}>
										Biling Option: {filterBillingOption?.name}
										</div>
									<div>
										<Card style={{ backgroundColor: '#eeeeff' }}>
											<Row>
												<Col span={8}>Service</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													Qty
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													Rate
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													Discount
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													Amount
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													GST
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													CGST
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													SGST
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													Total
												</Col>
											</Row>
										</Card>

										<Card>
											<Row>
												<Col span={8}>
													{
														qryData?.getBookingServiceItem?.bookingService
															?.service?.name
													}{' '}<br/>
													{/* <span style={{ fontSize: '13px' }}> */}
														(HSN/SAC:{' '}
														{
															qryData?.getBookingServiceItem?.bookingService
																?.service?.HSNcode
														}
														)
													{/* </span> */}
											
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}> 
												{  minUnitCal  
												=== 0 ? (
												<>
												{units} 
												</>):(
													<>
														Min: {qryData?.getBookingServiceItem?.bookingService?.serviceBillingOption.minUnit } <br/>
														Extra: {minUnitCal}
													</>
												)}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
												{  minUnitCal 
												=== 0 ? (
												<>
													₹{partnerPrice?.toLocaleString()}
													</>
													):(
													<>₹{partnerPrice?.toLocaleString()}<br/>
												(₹{qryData?.getBookingServiceItem?.itemPrice?.unitPrice?.toLocaleString()})<br/>
													</>
												)}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{partnerDiscount?.toLocaleString()}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{partnerAmount?.toLocaleString()}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													{qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount?.partnerGSTPercentage}%
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{partnerCgst}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{partnerSgst}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{partnerTotal?.toLocaleString()}
												</Col>
											</Row>{' '}
											<hr />
											<Row>
												<Col span={8}>
													{/* {qryData?.getBookingServiceItem?.bookingService?.service?.name} */}
													Platform Fee{' '}
													<span style={{ fontSize: '13px' }}>
														(HSN/SAC: 999799)
													</span>{' '}
													<br />[
													{
														qryData?.getBookingServiceItem?.bookingService
															?.booking?.bookingAmount?.commissionPercentage
													}
													%]
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													{qty}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{platformPrice?.toLocaleString()}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{platformDiscount}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{platformAmount?.toLocaleString()}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													18%
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{platformCgst?.toFixed(2)}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{platformSgst?.toFixed(2)}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{platformTotal?.toLocaleString()}
												</Col>
											</Row>
											{/* <Col span={3} > */}
											{/* {qryData?.getBookingServiceItem?.bookingService?.service?.name} */}
											{/* {billingHr} */}
											{/* </Col> */}
											{/* <Col span={3} >
						
					
						<Tooltip placement="bottomLeft" title={text} >
							
							<span style={{fontSize: "18px"}}>₹</span>{" "}{" "}
							</Tooltip>

						{qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount?.subTotal}
						</Col> */}
											{/* <Col span={3} >
						{qryData?.getBookingServiceItem?.bookingService?.qty ?qryData?.getBookingServiceItem?.bookingService?.qty :1}
						{" "}{qryData?.getBookingServiceItem?.bookingService?.unit}
						</Col> */}
											{/* <Col span={9} ><span style={{fontSize: "18px"}}>₹</span>{" "}
						{qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount?.totalDiscount?.toLocaleString()}
						<span style={{float:"right"}}><span style={{fontSize: "18px"}}>₹</span>{" "}{qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount?.subTotal?.toLocaleString()}</span>	
						</Col> */}
											<hr/>
											{/* <br /> */}
											<Row>
												<Col span={14}>
													<Card
														style={{
															backgroundColor: '#eeeeff',
															borderRadius: '1rem',
															marginTop: '15px',
															textAlign: 'center',
															width: '25%',
														}}
													>
														<h2 style={{ color: 'green' }}>
															{' '}
															{qryData?.getBookingServiceItem?.bookingService?.booking?.bookingPayments[0]?.status?.toUpperCase()}
														</h2>
													</Card>

													{/* </Col>
						<Col span={4} >
						</Col>
						<Col span={3} >
						</Col>	<Col span={3} > */}
												</Col>
												<Col span={9} style={{ marginLeft: '23px' }}>
													Subtotal
													<span style={{ float: 'right' }}>₹ {subTotal?.toLocaleString()}</span>
													<br />
													Discount
													<span style={{ float: 'right' }}>₹ {discount}</span>
													<br />
													Amount
													<span style={{ float: 'right' }}>₹ {amount?.toLocaleString()}</span>
													<br />
													SGST
													<span style={{ float: 'right' }}>
														₹ {sgstTotal?.toFixed(2)}
													</span>
													<br />
													CGST
													<span style={{ float: 'right' }}>
														₹ {cgstTotal?.toFixed(2)}
													</span>
													<br />
													<b>
														<hr />
														Grand Total
														<span style={{ float: 'right' }}>
															<span style={{ fontSize: '18px' }}>₹</span>{' '}
															{grandTotal?.toLocaleString()}
														</span>
														<br />
													</b>
													<hr />
												</Col>
											</Row>
											{/* Coupon Discount
						<span style={{float:"right"}}>0</span><br/>
						Campaign Discount
						
						<span style={{float:"right"}}>0</span><br/> */}
											{/* Total Commission */}
											{/* <span style={{float:"right"}}>₹{" "}{qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount?.totalCommission?.toLocaleString()}</span><br/> */}
											{/* Total Commission Tax */}
											{/* <span style={{float:"right"}}>₹{" "}{qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount?.totalCommissionTax?.toLocaleString()}</span><br/> */}
											{/* Total Partner Price
						<span style={{float:"right"}}>{qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount?.totalPartnerPrice}</span><br/> */}
											{/* Total Partner Tax */}
											{/* <span style={{float:"right"}}>{qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount?.totalPartnerTax}</span><br/> */}
											{/* Total Refundable */}
											{/* <span style={{float:"right"}}>₹{" "}{qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount?.totalRefundable}</span><br/> */}
											{/* Total Refunded */}
											{/* <span style={{float:"right"}}>₹{" "}{qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount?.totalRefunded}</span><br/> */}
											{/* Vat
						<span style={{float:"right"}}>0</span><br/> */}
											<Row>
												<Col span={24}>
													{/* <hr /> */}
													<div
														style={{ marginTop: '10px', textAlign: 'center' }}
													>
														{qryData?.getBookingServiceItem?.bookingService
															?.booking?.isCouponApplied && (
															<>
															<hr />
																<span style={{ color: '#ff6c37' }}>
																	Coupon Applied
																</span>
																<b style={{ paddingLeft: '1rem' }}>
																	{
																		qryData?.getBookingServiceItem
																			?.bookingService?.booking
																			?.appliedCouponDetails?.name
																	}
																	(
																	<span style={{ color: 'green' }}>
																		₹
																		{
																			qryData?.getBookingServiceItem
																				?.bookingService?.booking?.bookingAmount
																				?.commissionDiscount
																		}
																	</span>
																	saved on this booking)
																</b>{' '}
																<br />
															</>
														)}
														{qryData?.getBookingServiceItem?.bookingService
															?.booking?.isZpointsApplied && (
															<>
															<hr />		{
																		qryData?.getBookingServiceItem
																			?.bookingService?.booking?.bookingAmount
																			?.commissionDiscount
																	} {" "}
																<span style={{ color: '#ff6c37' }}>
																	ZPointes Applied
																</span>
																<b style={{ paddingLeft: '0.5rem' }}>
																	
															
																	(
																	<span style={{ color: 'green' }}>
																		₹
																		{
																			qryData?.getBookingServiceItem
																				?.bookingService?.booking?.bookingAmount
																				?.commissionDiscount
																		}
																	</span>{" "}
																	saved on this booking)
																</b>
															</>
														)}
													</div>
												</Col>
											</Row>
										</Card>
									</div>
									</>
								) :  (
									<div>
										{/* {qryData?.getBookingServiceItem?.bookingService
											?.bookingAdditionalPayments?.length > 1 ? (
											<> */}
												<Card style={{ backgroundColor: '#eeeeff' }}>
													<Row>
												<Col span={8}>Item</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													Qty
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													Rate
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													Discount
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													Amount
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													GST
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													CGST
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													SGST
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													Total
												</Col>
											</Row>
												</Card>

												{/* start additional work */}
											{qryData?.getBookingServiceItem?.additionalWorks?.length > 0 ?(
												<>
												
												{qryData?.getBookingServiceItem?.additionalWorks?.map((datas, index) => (
													<> 
													{datas?.bookingAdditionalWorkStatus !=="PAID" ?(
													<> </>
													):(
												<Card key={index}>
												<Row>
												<Col span={8}>
												Additonal Hours
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}> 
												{  datas?.additionalHoursUnits- datas?.minUnit  
												=== 0 ? (
												<>
												{ datas?.additionalHoursUnits} 
												</>):(
													<>
														Min: {datas?.minUnit } <br/>
														Extra:{datas?.additionalHoursUnits- datas?.minUnit}
													 </>
												)} 
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
												 {  datas?.additionalHoursUnits- datas?.minUnit  
												=== 0 ? (
												<>
													₹{datas?.additionalHoursAmount?.partnerRate}
													</>):( 
													<> 
													₹{datas?.additionalHoursAmount?.partnerRate?.toLocaleString()} <br/>
												(₹{datas?.additionalHoursItemPrice ?.unitPrice}) <br/>
													 </>
												)} 
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{datas?.additionalHoursAmount?.partnerDiscount?.toLocaleString()}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
												₹{datas?.additionalHoursAmount?.partnerAmount?.toLocaleString()}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
												{datas?.additionalHoursAmount?.partnerGSTPercentage}%
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
												₹{datas?.additionalHoursAmount?.partnerGSTAmount/2}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
												₹{datas?.additionalHoursAmount?.partnerGSTAmount/2}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{datas?.additionalHoursAmount?.totalPartnerAmount?.toLocaleString()}
												</Col>
											</Row>{' '}	<hr />
													<Row>
													<Col span={8}>
													{/* {qryData?.getBookingServiceItem?.bookingService?.service?.name} */}
													Platform Fee{' '}
													<span style={{ fontSize: '13px' }}>
														(HSN/SAC: 999799)
													</span>{' '}
													<br />[{datas?.additionalHoursAmount?.commissionPercentage}%]
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													1
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{datas?.additionalHoursAmount?.commissionRate?.toLocaleString()}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{datas?.additionalHoursAmount?.commissionDiscount}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{datas?.additionalHoursAmount?.commissionAmount?.toLocaleString()}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
												{datas?.additionalHoursAmount?.commissionGSTPercentage}%
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{datas?.additionalHoursAmount?.commissionGSTAmount/2}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{datas?.additionalHoursAmount?.commissionGSTAmount/2}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{datas?.additionalHoursAmount?.totalCommission?.toLocaleString()}
												</Col>
													</Row>	<hr />

													{/* addon start */}
													{datas?.bookingAddons?.length > 0 && ( 
														<> 
													{datas?.bookingAddons?.map((entry, index) => (
														<>
													<Row>
													<Col span={8}>
													{entry?.name}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
												{  entry?.units- entry?.minUnit  
												=== 0 ? (
												<>
												{ entry?.units} 
												</>):(
													<>
														Min: {entry?.minUnit } <br/>
														Extra:{entry?.units- entry?.minUnit}
													 </>
												)} 
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													{  entry?.units- entry?.minUnit  
												=== 0 ? (
												<>
													₹{entry?.amount?.partnerRate}
													</>):( 
													<> 
													₹{entry?.amount?.partnerRate?.toLocaleString()} <br/>
												(₹{entry?.itemPrice?.unitPrice}) <br/>
													 </>
												)} 
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{entry?.amount?.partnerDiscount}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{entry?.amount?.partnerAmount?.toLocaleString()}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
												{entry?.amount?.partnerGSTPercentage}%
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{entry?.amount?.partnerGSTAmount/2}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{entry?.amount?.partnerGSTAmount/2}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{entry?.amount?.totalPartnerAmount?.toLocaleString()}
												</Col>
													</Row>	<hr />
													<Row>
													<Col span={8}>
													Platform Fee{' '}
													<span style={{ fontSize: '13px' }}>
														(HSN/SAC: 999799)
													</span>{' '}
													<br />[{entry?.amount?.commissionPercentage}%]
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													1
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{entry?.amount?.commissionRate?.toLocaleString()}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{entry?.amount?.commissionPercentage?.toLocaleString()}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{entry?.amount?.commissionDiscount}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
												{entry?.amount?.commissionGSTPercentage}%
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{entry?.amount?.commissionGSTAmount/2}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{entry?.amount?.commissionGSTAmount/2}
												</Col>
												<Col span={2} style={{ textAlign: 'center' }}>
													₹{entry?.amount?.totalCommission?.toLocaleString()}
												</Col>
													</Row>	
													<hr />
													{/* end addons */}
													</>
														))}
													 </>
													 )} 

													<Row>
													
														<Col span={14}>
													<Card
														style={{
															backgroundColor: '#eeeeff',
															borderRadius: '1rem',
															textAlign: 'center',
															width: '25%',
															paddingTop:"1rem"
														}}
													>
														<h2 style={{ color: 'green' }}>
															{' '}
															{datas.bookingAdditionalWorkStatus?.toUpperCase()}
														</h2>
													</Card>
												</Col>
													<Col span={9} style={{ marginLeft: '23px' }}>
													Subtotal
													<span style={{ float: 'right' }}>₹{datas?.totalAdditionalWorkAmount?.subTotal?.toLocaleString()}</span>
													<br />
													Discount
													<span style={{ float: 'right' }}>₹{datas?.totalAdditionalWorkAmount?.totalDiscount}</span>
													<br />
													Amount
													<span style={{ float: 'right' }}>₹{datas?.totalAdditionalWorkAmount?.totalAmount?.toLocaleString()}</span>
													<br />
													SGST
													<span style={{ float: 'right' }}>
														₹{(datas?.totalAdditionalWorkAmount?.totalGSTAmount/2).toFixed(2)}
													</span>
													<br />
													CGST
													<span style={{ float: 'right' }}>
														₹{(datas?.totalAdditionalWorkAmount?.totalGSTAmount/2).toFixed(2)}
													</span>
													<br />
													<b>
														<hr />
														Grand Total
														<span style={{ float: 'right' }}>
															<span style={{ fontSize: '18px' }}>₹</span>{' '}
															{datas?.totalAdditionalWorkAmount?.grandTotal?.toLocaleString()}
														</span>
														<br />
													</b>
													<hr />
												</Col>
													</Row>
												</Card>
												)}</>
													))}
												</>
											):(
											<>
											<div style={{ textAlign: 'center' }}>
												<img src={noData} height={110} width={110} />
												<h2 style={{ color: '#f96d38' }}>No data avilable</h2>
											</div>
											</>
											)}

											{/* </>
										) : (
											<Card style={{ height: '180px' }}>
												<div style={{ textAlign: 'center' }}>
													<img src={noData} height={110} width={110} />
													<h4 style={{ color: '#f96d38' }}>No Scope of work</h4>
												</div>
											</Card>
										)} */}
									</div>
								)}
							</Card>
						</div>
					) : news === 1 ? (
						<>
							<Row>
								<Col span={6}>
									Booking Placed <br /> {dateTime}
								</Col>
								<Col span={6}>
									Booking Status <br /> {status}

								</Col>
								<Col span={6}>
									Payment Status <br />
									{payStatus ? payStatus : '-'}
								</Col>
								<Col span={6}>
									Booking Amount <br />{' '}
									<span style={{ fontSize: '18px' }}>₹</span>{' '}
									{qryData?.getBookingServiceItem?.bookingService?.booking?.bookingAmount?.grandTotal?.toLocaleString()}
								</Col>
							</Row>
							<hr />
							<Row>
								<Col span={16}>
									{qryData?.getBookingServiceItem?.statusHistory &&
										qryData?.getBookingServiceItem?.statusHistory?.map(
											(status, index) => (
												<Steps current={1} direction="vertical" key={index}>
													<Step
														style={{ color: 'green' }}
														title={status?.status === BookingServiceItemStatusTypeEnum.PAYMENT_PENDING && qryData?.getBookingServiceItem?.statusHistory?.find((st, i) => (st.status === BookingServiceItemStatusTypeEnum.OPEN)) ? 'PAYMENT COMPLETED' : status?.status }
														description={`${moment(
															status?.statusChangedAt,
														).format('lll')} By ${status?.changedByUser?.name}`}
													/>
												</Steps>
											),
										)}
								</Col>
								<Col span={8}>
									<Card
										style={{
											float: 'right',
											borderRadius: '1rem',
											marginTop: '1rem',
											backgroundColor: '#ff8f65',
											width: '60%',
										}}
									>
										Lead Service Information
										<br />
										{
											qryData?.getBookingServiceItem?.bookingService?.booking
												?.user?.name
										}
										<br />
										{
											qryData?.getBookingServiceItem?.bookingService?.booking
												?.user?.email
										}
										<br />
										{
											qryData?.getBookingServiceItem?.bookingService?.booking
												?.user?.phone
										}
									</Card>
									{qryData?.getBookingServiceItem?.servicePartner && (
										<Card
											style={{
												float: 'right',
												marginTop: '1rem',
												borderRadius: '1rem',
												backgroundColor: '#ff8f65',
												width: '60%',
											}}
										>
											Provider Information
											<br />
											{qryData?.getBookingServiceItem?.servicePartner?.name}
											<br />
											{qryData?.getBookingServiceItem?.servicePartner?.phone}
											<br />
										</Card>
									)}
								</Col>
							</Row>
						</>
					) : news === 2 ? (
						<>
							{qryData?.getBookingServiceItem?.bookingService?.booking
								?.bookingPayments?.length === 1 ? (
								<>
									{/* <Row > */}
									<br />
									<Card style={{ backgroundColor: '#eeeeff' }}>
										<Row key={1}>
											<Col span={4}>Invoice No</Col>
											<Col span={4}>Razorpay ID</Col>
											<Col span={4}>Amount Paid</Col>
											<Col span={4}>Amount Due</Col>
											<Col span={4}>Attempts</Col>
											<Col span={4}>Status</Col>
										</Row>
									</Card>
									{qryData?.getBookingServiceItem?.bookingService?.booking?.bookingPayments.map(
										(entry, index) => (
											<Card key={index}>
												<Row key={1}>
													<Col span={4}>{entry?.invoiceNumber}</Col>
													<Col span={4} style={{ paddingLeft: '1px' }}>
														{/* <Link to="https://dashboard.razorpay.com/app/payments/pay_MFdwRec4osBXow" target="_blank" rel="noopener noreferrer">ID</Link> */}
														<a
															href={`${baseRazorpayURL}${entry?.paymentId}?init_point=payments-table&init_page=Transactions.Payments`}
															target="_blank"
															rel="noopener noreferrer"
														>
															{entry?.paymentId}
														</a>
														{/* <Link to={`${baseRazorpayURL}${entry?.paymentId}?init_point=payments-table&init_page=Transactions.Payments`}  target="_blank">
													{entry?.paymentId}
													</Link> */}
													</Col>
													<Col span={4} style={{ paddingLeft: '39px' }}>
														₹{entry?.amountPaid}
													</Col>
													<Col span={4} style={{ paddingLeft: '39px' }}>
														₹{entry?.amountDue}
													</Col>
													<Col span={4} style={{ paddingLeft: '39px' }}>
														{entry?.attempts}
													</Col>
													<Col span={4} style={{ paddingLeft: '20px' }}>
														{entry?.status?.toUpperCase()}
													</Col>
												</Row>{' '}
											</Card>
											// <Col  span={5} key={index}>
											// <Card style={{borderRadius:"1rem",backgroundColor:"#fff4f0"}}>
											// Amount: {entry.amount}<br/>
											// Amount Paid: {entry.amountPaid}<br/>
											// Amount Due: {entry.amountDue}<br/>
											// Attempts: {entry.attempts}<br/>
											// Currency: {entry.currency}<br/>
											// Invoice Number: {entry.invoiceNumber}<br/>
											// </Card>
											// </Col>
										),
									)}
									{/* </Row>	 */}
								</>
							) : (
								// <div>
								// 	No data avilable
								// </div>
								<Row>
									<Col span={24}>
										<Card style={{ borderRadius: '1rem', height: '100%' }}>
											<div style={{ textAlign: 'center' }}>
												<img src={noData} height={110} width={110} />
												<h2 style={{ color: '#f96d38' }}>No data avilable</h2>
											</div>
										</Card>
									</Col>
								</Row>
							)}
						</>
					) : (
						<div>
							<br />
							<Row>
								{rating ? (
									<>
										<Col span={12}>
											<Card style={{ borderRadius: '1rem', height: '100%' }}>
												<div
													style={{
														textAlign: 'center',
														paddingTop: '50px',
														paddingBottom: '50px',
													}}
												>
													<h1>
														<span
															style={{ fontSize: '2rem', color: '#f96d38' }}
														>
															{qryData?.getBookingServiceItem?.review?.rating}
														</span>
														/5
													</h1>
													{/* <StarTwoTone /> <StarTwoTone /> <StarTwoTone /> <StarTwoTone /> <StarTwoTone />  */}
													<Rate
														disabled
														defaultValue={
															qryData?.getBookingServiceItem?.review?.rating
														}
														style={{ color: '#f96d38' }}
													/>
													<br />
													Ratings
												</div>
											</Card>
										</Col>
										<Col span={12}>
											<Card
												style={{
													borderRadius: '1rem',
													height: '100%',
													textAlign: 'center',
												}}
											>
												{/* <div style={{ textAlign: "center" }}> */}
												<h1
													style={{ paddingTop: '50px', paddingBottom: '50px' }}
												>
													{qryData?.getBookingServiceItem?.review?.review}
												</h1>
												{/* </div> */}
											</Card>
										</Col>
									</>
								) : (
									<Col span={24}>
										<Card style={{ borderRadius: '1rem', height: '100%' }}>
											<div style={{ textAlign: 'center' }}>
												<img src={noData} height={110} width={110} />
												<h2 style={{ color: '#f96d38' }}>No Rating</h2>
												{/* <StarTwoTone /> <StarTwoTone /> <StarTwoTone /> <StarTwoTone /> <StarTwoTone />  */}
											</div>
										</Card>
									</Col>
								)}
							</Row>
						</div>
					)}
				</Widget>
			</Card>

			
			<Modal
				title={'Reschedule'}
				visible={openReschedule}
				onOk={handleOk}
				onCancel={handleCancel}
				width={600}
			>
				{updateAlert &&  !loadingMtUs  && <Alert message="Booking Rescheduled Successfully" type="success" showIcon />}
				{loadingMtUs && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtUs && !errorUpdate && (
						<Alert
							message={`Submission error! ${errorMtUs.message}`}
							type="error"
							showIcon
							 onClose={clearError}
						/>
					)}
			<div style={{textAlign:"center"}}>
			<DatePicker onChange={handleDateSlots} value={dateSlots}/><br/><br/>
			{qryDataSlots &&qryDataSlots?.getServiceBookingSlots.map((entry, index) => (
			<>
			<div style={{display:"inline-block",paddingLeft:"5px"}}>
				
			<Card
			style={{
			backgroundColor: '#eeeeff',
			textAlign: 'center',
			marginTop: '1rem',
			borderRadius: '1rem',
			width: 'fit-content',
			padding: '5px',
			}}
			className="serviceReqirement"
			>
			<Radio  value={index}
            onChange={() => handleDateSlotsRadio(entry.start, entry.end)}
			checked={
			  selectedDateRange.start === entry.start &&
			  selectedDateRange.end === entry.end
			}>
	
			{moment(entry?.start).format('LT')} -{ moment(entry?.end).format('LT')}
			</Radio></Card></div>
			</>))}
			</div>
			</Modal>

			{/* <ProfileHeader
				name={`OrderID: ${qryData?.getBookingServiceItem?.id}`}
				avatar={`https://staging.api.zimkey.in/media-upload/${qryData?.getBookingServiceItem?.bookingService.service?.icon?.url}`}
			/> */}
			{/* <div className="gx-profile-content"> */}
			{/* <Row> */}
			{/* <Col xl={16} lg={14} md={14} sm={24} xs={24}> */}
			{/* {aboutList.length > 0 && <About aboutList={aboutList} />} */}
			{/* <Biography/> */}
			{/* <Events eventList={eventList} /> */}
			{/* <Card title="Updates">
							<Table
								className="gx-table-responsive"
								columns={columns}
								dataSource={data}
							/>
						</Card>
					</Col>

					<Col xl={8} lg={10} md={10} sm={24} xs={24}>
						{userContact && userContact.length > 0}
						<Contact title={'Customer contact'} contactList={userContact} />
						<Contact title={'Partner contact'} contactList={partnerContact} />
						<Row> */}
			{/* <Col xl={24} lg={24} md={24} sm={12} xs={24}> */}
			{/* <Friends friendList={friendList} /> */}
			{/* </Col> */}
			{/* <Col xl={24} lg={24} md={24} sm={12} xs={24}>
                <Photos photoList={photoList}/>
              </Col> */}
			{/* </Row>
					</Col>
				</Row>
			</div> */}
		</>
	);
};
export default BookingDetail;
