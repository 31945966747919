import React,{useEffect,useState} from 'react';
import noData from "./noDataa.png"

import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip,RadialBar,Legend } from 'recharts';

import Widget from 'src/components/widget';
interface Props {
	TotalBooking: any;
	Booking:any
}


const COLORS = ['#5797fc', '#FA8C16', '#f5222d', 'grey','green'];

const TaskByStatus = ({TotalBooking,Booking}:Props) => {
	// console.log("TotalBooking",TotalBooking);
	const[openCount,setOpenCount]=useState("")
    const[createCount,setCreateCount]=useState("")
    const[pendingCount,setPendingCount]=useState("")
    const[pendingFailedCount,setPendingFailedCount]=useState("")
    const[closeCount,setCloseCount]=useState("")


    useEffect(()=>{
		const open =Booking?.filter(value => value.bookingStatus ==="OPEN");
		const create =Booking?.filter(value => value.bookingStatus ==="CREATED");
		const pending =Booking?.filter(value => value.bookingStatus ==="PAYMENT_PENDING");
		const closed =Booking?.filter(value => value.bookingStatus ==="CLOSED");
		const failed =Booking?.filter(value => value.bookingStatus ==="PAYMENT_FAILED");

		setOpenCount(open?.length)
		setCreateCount(create?.length)
		setPendingCount(pending?.length)
		setPendingFailedCount(failed?.length)
		setCloseCount(closed?.length)

		},[Booking])
	
	
		const data = [
			{ name: 'Open', value: openCount },
			{ name: 'Created', value: createCount },
			{ name: 'Payment Pending', value: pendingCount },
			{ name: 'Payment Failed', value: pendingFailedCount },
			{ name: 'Closed', value: closeCount },

		];
	
	return (
		<Widget
			// title={
			// 	<h2 className="h4 gx-text-capitalize gx-mb-0">Total Bookings - {Booking?.length}</h2>
			// }
			styleName="gx-text-center"
		>
			{Booking?.length >1 ? (
			<div className="gx-py-3">
				<ResponsiveContainer width="100%" height={290}>
					<PieChart>
						<Tooltip />
						{/* <div>
						<text
							x="50%"
							className="h1"
							y="50%"
							textAnchor="middle"
							dominantBaseline="middle"
							// style={{paddingRight:"2px"}}
						>
							{Booking?.length}
						</text>
						</div> */}
				     <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            label
          >
							{data.map((entry, index) => (
								<Cell key={index} fill={COLORS[index % COLORS.length]} />
							))}
						</Pie>
	
						<RadialBar label={{ fill: '#666', position: 'right' }}  dataKey='uv'  /> 
  						<Legend iconSize={20}  layout='vertical' verticalAlign='middle' align="right"    /> 
					</PieChart>
				</ResponsiveContainer>
			</div>
			):(
				<div style={{textAlign:"center"}}>
				<div>
				<img src={noData} height={110} width={110}/>
				</div><div >
				<span style={{fontStyle:"italic"}}>No Bookings</span>
				</div></div>
			)}


		</Widget>
	);
};
export default TaskByStatus;
