import { gql } from '@apollo/client';

export const CMSQueryGetDashboardData = gql`
	query CMSGetDashboard  {
		getDashboardData {
			generalDashboard{
				counts{
					unassignedBookings
					pendingPartners
					last7DaysBookings
					next7DaysBookings
					last7DaysCustomers
				}
				top5BookingsByTrade
				ratingByService
			}
			directorsDashboard{
				totalRevenue
				retentionRate
				totalCustomers
				serviceOfferingsByCity
				serviceOfferingsByArea {
					areaName
					bookings
				}
				serviceOfferingsByTrade {
					
						serviceName
						bookings
					
				}
			}
		}
	}
`;

export const CMSQueryGetDashboardCounts = gql`
	query CMSGetDashboardCounts  {
		getDashboardCounts {
			unassignedBookings
			pendingPartners
			pendingReworks
			workingInProgress
		}
	}
`;

export const CMSQueryGetDashboardFilterCounts = gql`
	query CMSGetDashboardFilterCounts(
		$filters: DashboardCountFiltersGqlInput!
	)  {
		getDashboardFilterCounts(filters: $filters) {
			totalBookings
			upcomingBookings
			newCustomers
			newPartners
		}
	}
`;

export const CMSQueryGetTopBookings = gql`
	query CMSGetTopBookings(
		$filters: DashboardTopBookingsFilterGqlInput!
	)  {
		getTopBookings(filters: $filters) {
			name
			value
		}
	}
`;

export const CMSQueryGetTopRatings = gql`
	query CMSGetTopRatings(
		$filters: DashboardTopRatingsFilterGqlInput!
	)  {
		getTopRatings(filters: $filters) {
			name
			value
		}
	}
`;

export const CMSQueryGetDirectorsDashboardCounts = gql`
	query CMSGetDirectorsDashboardCounts  {
		getDirectorsDashboardCounts {
			totalCustomers
			totalPartners
			totalServices
		}
	}
`;
export const CMSQueryGetDirectorsDashboardRevenue = gql`
	query CMSGetDirectorsDashboardRevenue(
		$filters: DashboardRevenueFiltersGqlInput!
	)  {
		getDirectorsDashboardRevenue(filters: $filters) {
			revenueGraph{
				name
				revenue
			}
			totalRevenue
		}
	}
`;

export const CMSQueryGetCustomerRetentionRateGraph= gql`
	query CMSGetCustomerRetentionRateGraph(
		$filters: DashboardRevenueFiltersGqlInput!
	)  {
		getCustomerRetentionRateGraph(filters: $filters) {
			customerRetentionGraph{
				name
				value
			}
		}
	}
`;