import { useMutation } from '@apollo/client';
import {
	Alert,
	Button,
	Col,
	Form,
	Input,
	Modal,
	Row,
	Select,
	Switch,
} from 'antd';
import React, { useState,useEffect } from 'react';
import { CMSGetFaqs_getFaqs } from 'src/lib/gql/generated/CMSGetFaqs';
import {
	CMSUpdateFaq,
	CMSUpdateFaqVariables,
} from 'src/lib/gql/generated/CMSUpdateFaq';
import { AppTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { UPDATE_FAQ } from 'src/lib/gql/mutations/mutations';
import {EditOutlined } from '@ant-design/icons';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { userAtom } from 'src/lib/recoil/userCoil';
import { useRecoilValue } from 'recoil';
interface Props {
	input?: CMSGetFaqs_getFaqs;
	onFinishAdd?: () => void;
}

const EditFaq: React.FC<Props> = ({ input, onFinishAdd }) => {
	console.log("innnn",input);
	
	const [isModalVisible, setIsModalVisible] = useState(false);
	const userAuth = useRecoilValue(userAtom);
	const [updateAlert, setUpdateAlert] = useState(false)

	// Create
	const [UpdateFaq, { data: dataMt, loading: loadingMt, error: errorMt }] =
		useMutation<CMSUpdateFaq, CMSUpdateFaqVariables>(UPDATE_FAQ,
			{
				onCompleted: () => {
					onFinishAdd
					form.resetFields();
				},
});

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
		setIsModalVisible(false);
	};
	useEffect(() => {
		form.setFieldsValue({
			question:input?.question,
			answer :input?.answer,
			category:input?.category,
			sortOrder:input?.sortOrder,
			appType:input?.appType,
		});
	}, [input]);

	const [form] = Form.useForm();

	const onFinish = (values) => {
		if (input) {
			UpdateFaq({
				variables: {
					data: {
						question: values.question,
						answer: values.answer,
						appType: values.appType,
						sortOrder: +values.sortOrder,
						category: values.category,
					},
					id: input?.id,
				},
			})
				.then(() => {
					form.resetFields();
					setTimeout(() => {
						if (typeof onFinishAdd === 'function') {
							onFinishAdd();
						}
						setIsModalVisible(false);
					}, 1500);
					onFinishAdd
				setUpdateAlert(true)
				setTimeout(() => {
				setUpdateAlert(false)
				}, 2000)
				})
				.catch((e) => console.log(e));
		}
	};
	return (
		<>
			{/* <Button type="primary" onClick={showModal}>
				{!input ? 'Add' :  }
			</Button> */}
			{ !input ?(
					<Button type="primary" onClick={showModal}>
					Add
				</Button>
			):(
				<>
				{userAuth?.userType === UserTypeEnum.ADMIN ||
					userAuth?.rights?.find(
						(x) => x.type === 'sub2' && x.name === 'faq-customer-edit'|| x.type === 'sub2' && x.name === 'faq-partner-edit',
					) ? (
						<>
			<EditOutlined onClick={showModal} style={{color:"#5143dc"}}/>
			</>
					):( 		<>
			<EditOutlined style={{color:"#5143dc"}}/>
			</>
					)}
					

			</>
				)}

			<Modal
				title={!input ? 'Add ' : 'Edit'}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
			>
				{/* Creating */}
				{updateAlert &&  !loadingMt  && <Alert message="Created" type="success" showIcon />}
				{loadingMt && <Alert message="Submitting..." type="info" showIcon />}
				{errorMt && (
					<Alert
						message={`Submission error! ${errorMt.message}`}
						type="error"
						showIcon
					/>
				)}

				<Form
					form={form}
					name="updateFaq"
					onFinish={onFinish}
					scrollToFirstError
					initialValues={
						!input
							? {}
							: {
									...input,
							  }
					}
				>
					<Row>
						<Col span={12}>
							<Form.Item name={'question'} label="Question">
								<Input.TextArea />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name={'answer'} label="Answer">
								<Input.TextArea />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name={'category'} label="Category">
								<Input.TextArea />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name={'sortOrder'} label="Sort Order">
								<Input.TextArea />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="appType"
								label="App type"
								rules={[{ required: true }]}
							>
								<Select placeholder="Select a option and change input text above" getPopupContainer={(trigger) => trigger.parentElement}>
									<Select.Option value={AppTypeEnum.CUSTOMER}>
										{AppTypeEnum.CUSTOMER}
									</Select.Option>
									<Select.Option value={AppTypeEnum.PARTNER}>
										{AppTypeEnum.PARTNER}
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>

						<Col span={2}></Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};
export default EditFaq;
