import { useMutation, useQuery } from '@apollo/client';
import {
	Alert,
	Button,
	Card,
	Col,
	Form,
	Image,
	Input,
	Modal,
	Row,
	Select,
	Table,
	Radio,
	Spin,
	Space,
} from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useState, useEffect } from 'react';
import CKEditor from 'react-ckeditor-component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AddAdditionalInput from 'src/components/services/addAdditionalInput';
import AddBillingOption from 'src/components/services/addBillingOption';
import AddServiceRequirement from 'src/components/services/addRequirement';
import AddServiceInput from 'src/components/services/addServiceInput';
import {
	UploadPublicImage,
	UploadPublicImage2,
} from 'src/components/shared/UploadPublicImage';
import {
	CMSCreateService,
	CMSCreateServiceVariables,
} from 'src/lib/gql/generated/CMSCreateService';
import { CMSCreateServiceAddon } from 'src/lib/gql/generated/CMSCreateServiceAddon';
import { CMSCreateServiceBillingOption_createServiceBillingOption } from 'src/lib/gql/generated/CMSCreateServiceBillingOption';
import {
	CMSDeleteService,
	CMSDeleteServiceVariables,
} from 'src/lib/gql/generated/CMSDeleteService';
import {
	CMSGetService,
	CMSGetServiceVariables,
	CMSGetService_getService_inputs,
	CMSGetService_getService_requirements,
	CMSGetService_getService_billingOptions_additionalUnitPrice,
} from 'src/lib/gql/generated/CMSGetService';
import {
	CMSUpdateService,
	CMSUpdateServiceVariables,
} from 'src/lib/gql/generated/CMSUpdateService';
import { ServiceShortListEnum } from 'src/lib/gql/generated/globalTypes';
import {
	CREATE_SERVICE,
	DELETE_SERVICE,
	UPDATE_SERVICE,
} from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetService } from 'src/lib/gql/queries/services';
import {
	LoadingOutlined,
	CaretLeftOutlined,
	EditOutlined,
	DeleteOutlined,
	FileAddOutlined,
} from '@ant-design/icons';
import { ServiceStatusTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { CMSQueryGetAllServiceCategories } from 'src/lib/gql/queries/services';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { userAtom, userTokenAtom } from 'src/lib/recoil/userCoil';
import { useRecoilState, useRecoilValue } from 'recoil';
import AddPropertyArea from 'src/components/services/addPropertyArea';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
};

const AddService: React.FC = () => {
	const isEditMode = useQueryParam().get('mode');
	const categoryID = useQueryParam().get('category');
	const { id } = useParams<{ id: string }>();
	const [content, setcontent] = useState<any>();
	const [zimkeyService, setZimkeyService] = useState<boolean>(false);
	const [sstatus, setSstatus] = useState<any>();
	const [errorAlert, setErrorAlert] = useState(false)
	const [errorUpdate, setErrorUpdate] = useState(false);
	const [updateAlert, setUpdateAlert] = useState(false);
	const [createAlert, setCreateAlert] = useState(false);

	// Create
	const [addService, { data: dataMt, loading: loadingMt, error: errorMt }] =
		useMutation<CMSCreateService, CMSCreateServiceVariables>(CREATE_SERVICE, {
			onCompleted: () => {
				navigate(-1);
				refetchQryDataaaaa();
				// setTimeout(() => {
				// 	dataMt(false)
				// }, 1500);
			},
		});

	// Update
	const [
		updateService,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation(UPDATE_SERVICE, {
		onCompleted: () => {
			refetchQryData();
			setTimeout(() => {
				// dataMtU(false)
			}, 1500);
		},
	});

	const { data: qryDataaaa, refetch: refetchQryDataaaaa } = useQuery(
		CMSQueryGetAllServiceCategories,
	);
	useEffect(()=>{
		// console.log("111111111111111",qryDataaaa);
		const opensss =	qryDataaaa?.getServiceCategories?.map(
			(value) => value?.services
		)
		// const clooos =	opensss?.map(
		// 	(value) => value?.serviceShortList
		// )
		// console.log("111111111111111 clooos",clooos);
		// let daaaaaya
		// const shortlistData = opensss?.map(item => (
		// 	daaaaaya=opensss.map(
		// 		(value) => value.id
		// 	)
		// ));
		// console.log("daaaaaya",daaaaaya);
		// console.log("shortlistData",shortlistData);
		// const open =qryDataaaa?.getServiceCategories?.filter(value => value.services?.serviceShortList ==="SHOW_IN_HOME");
		// console.log("111111111111111 open",open);
		// const daaa =opensss?.filter(value => value.serviceShortList ==="SHOW_IN_HOME");
		// console.log("111111111111111 daaa",daaa);
		// const isValueInArray = qryDataaaa?.getServiceCategories.includes("SHOW_IN_HOME");
		// console.log("isValueInArray",isValueInArray);
	},[qryDataaaa])

	function useQueryParam() {
		const { search } = useLocation();
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const deletConfirm = () => {
		setIsDeleteModalVisible(true);
	};

	// Delete
	const [
		deleteServiceCategory,
		{ data: dataMtD, loading: loadingMtD, error: errorMtD },
	] = useMutation<CMSDeleteService, CMSDeleteServiceVariables>(DELETE_SERVICE);
	const onDelete = (id) => {
		if (!id) return;
		deleteServiceCategory({
			variables: {
				id: id,
			},
		}).then(() => {
			setTimeout(() => {
				setIsDeleteModalVisible(false);
				navigate('/services');
				refetchQryDataaaaa();
				refetchQryData()
			}, 1500);
		});
	};

	// qryData?.getService?.description ?? '',

	const {
		loading,
		data: qryData,
		refetch: refetchQryData,
	} = useQuery(CMSQueryGetService, {
		variables: {
			id: id || '',
		},
		// onCompleted: () => {
		// 	setcontent(qryData?.getService?.description ?? '');
		// },
		skip: !isEditMode,
	});

	useEffect(() => {
		// console.log("1233",statusVal);
		setStatusVal(qryData?.getService?.serviceStatus);
		setcontent(qryData?.getService?.description ?? '');
		setZimkeyService(qryData?.getService?.isZimkeyService);
		setSstatus(qryData?.getService.serviceStatus);
		const mediaIds = qryData?.getService?.medias?.map((i, k) => i.id);
		setMediaId(mediaIds);

		form.setFieldsValue({
			name: qryData?.getService.name,
			code: qryData?.getService.code,
			HSNcode: qryData?.getService.HSNcode,
			isZimkeyService: qryData?.getService.isZimkeyService,
			description: qryData?.getService.description,
			enabled: qryData?.getService.enabled.toString(),
			reworkGracePeriod: qryData?.getService.reworkGracePeriod ? qryData?.getService.reworkGracePeriod : 0,
			serviceStatus: qryData?.getService.serviceStatus, 
			serviceShortList: qryData?.getService.serviceShortList,
		});

	}, [qryData]);

	const [form] = Form.useForm();
	const onFinish = (values) => {
		console.log('values',values);

		if (!isEditMode) {
			addService({
				variables: {
					data: {
						enabled: true,
						name: values.name,
						description: content,
						code: values.code,
						HSNcode: values.SACcode,
						isZimkeyService: zimkeyService ? true : false,
						categoryIds: categoryID ? [categoryID] : [],
						// reworkGracePeriod: parseFloat(values.reworkGracePeriod),
						reworkGracePeriod: values.reworkGracePeriod
						? parseFloat(values.reworkGracePeriod)
						: 0,
						iconId: iconId,
						thumbnailId:thumbnailId,
						serviceShortList: values.serviceShortList,	
						mediaIds: medaId ? [medaId] : [],
					},
				},
			}).then(() => {
				form.resetFields();
			});
			setCreateAlert(true);
			setTimeout(() => {
				setCreateAlert(false);
			}, 4000);
		} else {
			if (!qryData?.getService) return;
			// console.log('values',values);
			
			updateService({
				variables: {
					data: {
						code: values.code,
						name: values.name,
						HSNcode: values.SACcode,
						isZimkeyService: zimkeyService,
						reworkGracePeriod: values.reworkGracePeriod
							? parseFloat(values.reworkGracePeriod)
							: 0,
						description: content,
						enabled: values.enabled === 'true',
						iconId: iconId,
						thumbnailId:thumbnailId,
						mediaIds:
							medaId && Array.isArray(medaId) ? medaId : medaId ? [medaId] : [],
						// serviceShortList: serviceList,
						serviceShortList: values.serviceShortList,
					},
					id: qryData?.getService.id,
				},
			})
				.then(() => {
					form.resetFields();
					refetchQryData();
					setUpdateAlert(true);
					setTimeout(() => {
						setUpdateAlert(false);
					}, 4000);
				})
				.catch(() => {
					setErrorUpdate(true);
					setTimeout(() => {
						setErrorUpdate(false);
					}, 5000);
				});
		}
	};

	const { Option } = Select;

	const [statusVal, setStatusVal] = useState<any>();
	const [serviceList, setServiceList] = useState<any>([]);

	const [iconId, setIconId] = useState(null);
	const [medaId, setMediaId] = useState(null);
	const [thumbnailId, setThumbnailId] = useState(null);
	const [activeTabValue, setActiveTabValue] = useState(0);
	const navigate = useNavigate();
	const [statusModalOpen, setStatusModalOpen] = useState(false);

	const handleServiceOptionsTabs = (e) => {
		setActiveTabValue(e?.target?.value);
		// setLoader(true);
		// setTimeout(() => {
		// 	setLoader(false);
		// }, 1500);
	};
	const statusChange = (e) => {
		// console.log("eee",e);
		setStatusVal(e);
		setStatusModalOpen(true);
	};

	const serviceShortListChange = (e) => {
		// console.log("eee",e);
		setServiceList(e);
	};
	const [startingSubmit, setStartingSubmit] = useState(false);
	const [endingSubmit, setEndingSubmit] = useState(false);

	const handleZimkeyService = (value) => {
		setZimkeyService(value?.target?.value);
	};

	const updateStatus = () => {
		updateService({
			variables: {
				data: {
					serviceStatus: statusVal,
					// description: content,
				},
				id: qryData?.getService?.id,
			},
		}).then(() => {
			// form.resetFields();
			refetchQryData();
		}).catch((error) => {
			setErrorAlert(true);
			setStatusVal(ServiceStatusTypeEnum.DRAFT)
			setTimeout(() => {
				setErrorAlert(false);
				setStatusModalOpen(false);
			}, 7000);
		});
	};

	const isOk = () => {
		// setStatusModalOpen(false)
		// setStartingSubmit(true)
		updateStatus();
		// setEndingSubmit(true);
		setTimeout(() => {
			// setStartingSubmit(false)
			setEndingSubmit(false);
			setStatusModalOpen(false);
		}, 1500);
	};
	const isCancel = () => {
		setStatusModalOpen(false);
	};

	const editorConfig = {
		// Customize options here
		toolbar: [
			//   { name: 'document', items: ['Source', '-', 'NewPage', 'Preview', '-', 'Templates'] },
			//   { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
			//   { name: 'editing', items: ['Find', 'Replace', '-', 'SelectAll'] },
			'/',
			{
				name: 'basicstyles',
				items: [
					'Bold',
					'Italic',
					'Underline',
					'Strike',
					'Subscript',
					'Superscript',
					'-',
					'RemoveFormat',
				],
			},
			{
				name: 'paragraph',
				items: [
					'NumberedList',
					'BulletedList',
					'-',
					'CreateDiv',
					'-',
					'JustifyLeft',
					'JustifyCenter',
					'JustifyRight',
					'JustifyBlock',
				],
			},
			//   { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
			//   { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar'] },
			'/',
			//   { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
			//   { name: 'colors', items: ['TextColor', 'BGColor'] },
			//   { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
			{ name: 'others', items: ['-'] },
		],
		// ...
	};

	const userAuth = useRecoilValue(userAtom);

	const validateReworkGracePeriod = (_, value) => {
		 // Check if the value is not a valid numeric string
		 if (!/^\d+$/.test(value)) {
			return Promise.reject('Please enter a valid number for the rework grace period.');
		}
	
		// Check if the numeric value is less than 0
		if (Number(value) < 0) {
			return Promise.reject('Please enter a non-negative number for the rework grace period.');
		}
		return Promise.resolve();
	  };

	return (
		<Card title="Services">
			<div className="gx-flex gx-flex-nowrap gx-mb-2">
				{/* <div className="gx-card-profile">
					<div className="ant-card-head ">
						<Button className="gx-mr-4" onClick={() => navigate(-1)}>
							Back
						</Button>

					</div>

					<div className="gx-pt-md-3"></div>
				</div> */}

				{loading ? (
					<Row justify="center">
						<Space size="middle">
							<Spin size="large" />
						</Space>
					</Row>
				) : (
					<>
						<Row>
							<Col span={24}>
								{dataMt && <Alert message="Created" type="success" showIcon />}
								{loadingMt && (
									<Alert message="Submitting..." type="info" showIcon />
								)}
								{errorMt && (
									<Alert
										message={`Submission error! ${errorMt.message}`}
										type="error"
										showIcon
									/>
								)}
							</Col>
							<Col span={10} className="gx-mb-4">
								<h1>
									<Button
										className="gx-mr-4"
										onClick={() => navigate(-1)}
										style={{ margin: '0' }}
									>
										<CaretLeftOutlined />
									</Button>
									{isEditMode
										? `Edit - ${qryData?.getService.name}`
										: 'Add New Service'}
								</h1>
							</Col>
							<Col span={12}>
								{isEditMode && (
									<div style={{ width: '500px', float: 'right' }}>
										{userAuth?.userType === UserTypeEnum.ADMIN ||
										userAuth?.rights?.find(
											(x) =>
												x.type === 'sub2' && x.name === 'change-service-status',
										) ? (
											<Form.Item
												label="Service Status"
												// name="serviceStatus"
											>
												<Select  getPopupContainer={(trigger) => trigger.parentElement}
													onChange={(e) => statusChange(e)}
													value={statusVal}
												>
													<Select.Option value="DRAFT">
														{ServiceStatusTypeEnum.DRAFT}
													</Select.Option>
													<Select.Option value="PUBLISHED">
														{ServiceStatusTypeEnum.PUBLISHED}
													</Select.Option>
												</Select>
											</Form.Item>
										) : (
											<></>
										)}
									</div>
								)}
							</Col>
							<Col span={2} className="gx-mb-4">
								<div style={{ float: 'right', paddingLeft: '2rem' }}>
									{isEditMode && (
										<>
											{userAuth?.userType === UserTypeEnum.ADMIN ||
											userAuth?.rights?.find(
												(x) => x.type === 'sub2' && x.name === 'service-delete',
											) ? (
												<Button type="text" onClick={deletConfirm} danger>
													<DeleteOutlined />
												</Button>
											) : (
												<></>
											)}
										</>
									)}
								</div>
							</Col>
							{/* </Col> */}
							{/* <Col span={12}> */}

							{/* <hr/> */}
							{/* <div
									style={{
										display: 'flex', float:"right",
										marginBottom: '1rem',
									}}
								>
									<Form.Item {...tailFormItemLayout} style={{width:"80px "}}>
										<Button type="primary" htmlType="submit"  >
											{isEditMode ? 'Save' : 'Add'}
										</Button>
									</Form.Item>

									 { isEditMode&& (
										<Button
										type="text"
										onClick={ deletConfirm}
										danger
									>
										<DeleteOutlined/>
									</Button>
									 )}

								</div> */}
							{/* </Col> */}

							{/* </Col> */}
						</Row>
						{/* <hr/> */}
						<Row style={{ marginTop: '-2rem' }}>
							<Col span={24}>
								<hr />
							</Col>
						</Row>
						<Row>
							<Col span={24} className="gx-mb-4">
								{!loading && (
									<Form
										{...formItemLayout}
										form={form}
										name="addService"
										onFinish={onFinish}
										initialValues={
											!qryData
												? {
														enabled: 'false',
												  }
												: {
														name: qryData?.getService.name,
														code: qryData?.getService.code,
														SACcode: qryData?.getService.HSNcode,
														isZimkeyService:
															qryData?.getService.isZimkeyService,
														description: qryData?.getService.description,
														enabled: qryData?.getService.enabled.toString(),
														reworkGracePeriod:
															qryData?.getService.reworkGracePeriod ? qryData?.getService.reworkGracePeriod : 0,
														serviceStatus: qryData?.getService.serviceStatus,
														serviceShortList:
															qryData?.getService.serviceShortList,
												  }
										}
										scrollToFirstError
									>
										{/* Updating */}
										{updateAlert && !loadingMtU && (
											<Alert message="Updated" type="success" showIcon />
										)}
										{loadingMtU && (
											<Alert message="Submitting..." type="info" showIcon />
										)}
										{errorMtU && errorUpdate && (
											<Alert
												message={`Submission error! ${errorMtU.message}`}
												type="error"
												showIcon
											/>
										)}
										<Row>
											<Col span={10}>
												<h3>Basic Details</h3>
											</Col>

											{/* {isEditMode ? (
												<Col span={10}>
													<Form.Item
														label="Service Status"
														name="serviceStatus"
													>
														<Select
															onChange={(e) => statusChange(e)}
															value={statusVal}
														>
															<Select.Option value="DRAFT">
																{ServiceStatusTypeEnum.DRAFT}
															</Select.Option>
															<Select.Option value="PUBLISHED">
																{ServiceStatusTypeEnum.PUBLISHED}
															</Select.Option>
														</Select>
													</Form.Item>
												</Col>
											) : (
												<Col span={10}> </Col>
											)} */}

											<Col span={12}>
												<div
													style={{
														display: 'flex',
														//  float:"right",
														marginBottom: '1rem',
														// textAlign:"end"
														float: 'right',
													}}
												>
													<Form.Item
														{...tailFormItemLayout}
														style={{ width: '100px ' }}
													>
														{userAuth?.userType === UserTypeEnum.ADMIN ||
														userAuth?.rights?.find(
															(x) =>
																x.type === 'sub2' &&
																x.name === 'service-details-edit',
														) ? (
															<Button type="primary" htmlType="submit">
																{isEditMode ? 'Save' : 'Add'}
															</Button>
														) : (
															<></>
														)}
													</Form.Item>
												</div>
											</Col>
										</Row>

										{statusModalOpen && (
											<Modal
												title="Confirmation"
												visible={statusModalOpen}
												// onOk={isOk}
												// onCancel={isCancel}
												footer={errorAlert ? null : (
													<div>
													  {/* Add your footer content here */}
													  <Button onClick={isOk}>Ok</Button>
													  <Button onClick={() => setStatusModalOpen(false)}>Cancel</Button>
													</div>
												  )}
											>
												{endingSubmit && (
													<Alert message="Submitted" type="success" showIcon />
												)}
												{startingSubmit && (
													<Alert message="Submitting..." type="info" showIcon />
												)}
												{errorMtU &&   errorAlert&&(
													<Alert
														message={`Submission error! ${errorMtU.message}`}
														type="error"
														showIcon
													/>
												)}
												<p>
													Are you sure want to change the status to{' '}
													{statusVal === 'draft' ? 'Draft' : 'Publish'}?
												</p>
											</Modal>
										)}

										{/* <Form.Item
									name="categoryName"
									label={<span>Category Name</span>}
								>
									<Input readOnly />
								</Form.Item> */}

										<Row>
											<Col span={14}>
												<Form.Item
													name="name"
													label={<span>Name</span>}
													rules={[
														{
															required: true,
															message: 'Please input service category name',
															whitespace: true,
														},
													]}
												>
													<Input />
												</Form.Item>

												<Form.Item name="code" label={<span>Code</span>}>
													<Input />
												</Form.Item>

												<Form.Item
													name="SACcode"
													label={<span>SAC Code</span>}
													rules={[
														{
															required: true,
															whitespace: true,
														},
													]}
												>
													<Input />
												</Form.Item>

												{/* <Form.Item label="Service Status" name="enabled">
											<Select>
												<Select.Option value={'false'}>Draft</Select.Option>
												<Select.Option value={'true'}>Published</Select.Option>
											</Select>
										</Form.Item> */}

												{/* Rework */}
												<Form.Item
  												label="Rework Grace Period In Days"
  												name="reworkGracePeriod"
  												rules={[
  												  {
  												    validator: validateReworkGracePeriod,
   												 },
  												]}
												>
  												<Input placeholder="0" type="number" />
												</Form.Item>

												<Form.Item label="Description">
													<CKEditor
														activeClass="p10"
														content={content}
														events={{
															change: (evt) => setcontent(evt.editor.getData()),
														}}
														config={editorConfig}
													/>
												</Form.Item>

												<Form.Item
													label="Service Shortlist"
													name="serviceShortList"
												>
													<Select getPopupContainer={(trigger) => trigger.parentElement}
														mode="multiple"
														style={{ width: '100%' }}
														placeholder="Select Property"
														optionLabelProp="label"
														onChange={(e) => serviceShortListChange(e)}
													>
														<Option
															value={ServiceShortListEnum.POPULAR}
															label={ServiceShortListEnum.POPULAR}
														>
															<div className="demo-option-label-item">
																{ServiceShortListEnum.POPULAR}
															</div>
														</Option>

														<Option
															value={ServiceShortListEnum.SHOW_IN_HOME}
															label={ServiceShortListEnum.SHOW_IN_HOME}
														>
															<div className="demo-option-label-item">
																{ServiceShortListEnum.SHOW_IN_HOME}
															</div>
														</Option>

													</Select>
												</Form.Item>
												<Form.Item
													name="isZimkeyService"
													label={<span>Is This Zimkey Service</span>}
												>
													<Radio.Group
														defaultValue={false}
														onChange={handleZimkeyService}
													>
														<Radio value={false}>No</Radio>
														<Radio value={true}>Yes</Radio>
													</Radio.Group>
												</Form.Item>
											</Col>

											<Col span={10}>
												{/* <Row> */}
												<Row>
													<Col span={8}></Col>
													<Col span={16}>
														<Form.Item
															label={<span>Icon</span>}
															style={{
																display: 'flex',
																flexDirection: 'column',
															}}
														>
															{/* <Form.Item
																name="icon"
																valuePropName="icon"
																noStyle
															> */}
															{qryData?.getService?.icon?.url ? (
																<div>
																	{/* <Image
																	width={150}
																	height={150}
																	src={`https://staging.api.zimkey.in/media-upload/${qryData?.getService?.icon?.url}`}
																	
																	fallback={"https://iconicentertainment.in/wp-content/uploads/2013/11/dummy-image-square.jpg"}
																/> */}
																	{/* <button
																	style={{
																		borderRadius: '50%',
																		border: 'none',
																		backgroundColor: '#f96d38',
																		color: 'white',
																		position: 'absolute',
																		bottom: '-10px', // Adjust this value to move the button up or down
																		left: '50%',
																		transform: 'translateX(-50%)',
																	}}
																>
																	<EditOutlined />
																</button> */}
																	<UploadPublicImage2
																		serviceIconUrl={`https://staging.api.zimkey.in/media-upload/${qryData?.getService?.icon?.url}`}
																		onFinishAdd={(info) =>
																			setIconId(info?.file.response.data.id)
																		}
																	/>
																</div>
															) : (
																<UploadPublicImage
																	onFinishAdd={(info) =>
																		setIconId(info?.file.response.data.id)
																	}
																/>
															)}
															{/* </Form.Item> */}
														</Form.Item>
													</Col>
												</Row>

												<Row>
													<Col span={8}></Col>
													<Col span={16}>
														<Form.Item
															label={
																<span style={{ textAlign: 'center' }}>
																	Cover Image
																</span>
															}
															style={{
																display: 'flex',
																flexDirection: 'column',
															}}
															rules={[
																{
																	required: true,
																	message: 'Please upload icon',
																	whitespace: true,
																},
															]}
														>
															<Form.Item
																name="icon"
																valuePropName="icon"
																noStyle
															>
																{qryData?.getService?.medias[0] ? (
																	// <div style={{ position: 'absolute' }}>
																	// 	<Image
																	// 		width={200}
																	// 		height={200}
																	// 		src={`https://staging.api.zimkey.in/media-upload/${qryData?.getService?.medias[0]?.url}`}
																	// 		fallback={"https://iconicentertainment.in/wp-content/uploads/2013/11/dummy-image-square.jpg"}
																	// 	/>
																	// 	<button
																	// 		style={{
																	// 			borderRadius: '50%',
																	// 			border: 'none',
																	// 			backgroundColor: '#f96d38',
																	// 			color: 'white',
																	// 			position: 'absolute',
																	// 			bottom: '-10px', // Adjust this value to move the button up or down
																	// 			left: '50%',
																	// 			transform: 'translateX(-50%)',
																	// 		}}
																	// 	>
																	// 		<EditOutlined />
																	// 	</button>
																	// </div>

																	<div>
																		<UploadPublicImage2
																			serviceIconUrl={`https://staging.api.zimkey.in/media-upload/${qryData?.getService?.medias[0]?.url}`}
																			onFinishAdd={(info) =>
																				setMediaId(info?.file.response.data.id)
																			}
																		/>
																	</div>
																) : (
																	<UploadPublicImage
																		onFinishAdd={(info) =>
																			setMediaId(info?.file.response.data.id)
																		}
																	/>
																)}
															</Form.Item>
														</Form.Item>
													</Col>
												</Row>
												
												<Row>
													<Col span={8}></Col>
													<Col span={16}>
														<Form.Item
															label={
																<span style={{ textAlign: 'center' }}>
																	Thumbnail  
																</span>
															}
															style={{
																display: 'flex',
																flexDirection: 'column',
															}}
															rules={[
																{
																	required: true,
																	message: 'Please upload thumbnail',
																	whitespace: true,
																},
															]}
														>
															<Form.Item
																name="thumbnail"
																valuePropName="thumbnail"
																noStyle
															>
																{qryData?.getService?.thumbnail?.url  ? (
																	<div>
																		<UploadPublicImage2
																			serviceIconUrl={`https://staging.api.zimkey.in/media-upload/${qryData?.getService?.thumbnail?.url}`}
																			onFinishAdd={(info) =>
																				setThumbnailId(info?.file.response.data.id)
																			}
																		/>
																	</div>
																) : (
																	<UploadPublicImage
																		onFinishAdd={(info) =>
																			setThumbnailId(info?.file.response.data.id)
																		}
																	/>
																)}
															</Form.Item>
														</Form.Item>
													</Col>
												</Row>
												{/* </Row> */}
											</Col>
										</Row>

										{/* Creating */}
										{/* {dataMt && <Alert message="Created" type="success" showIcon />}
								{loadingMt && (
									<Alert message="Submitting..." type="info" showIcon />
								)}
								{errorMt && (
									<Alert
										message={`Submission error! ${errorMt.message}`}
										type="error"
										showIcon
									/>
								)} */}

										<hr />

										{/* <div
									style={{
										display: 'flex',
										justifyContent: 'center',
										marginBottom: '2rem',
									}}
								>
									<Form.Item {...tailFormItemLayout}>
										<Button type="primary" htmlType="submit">
											{isEditMode ? 'Save' : 'Add'}
										</Button>
									</Form.Item>

									<Button
										type="text"
										onClick={() => setIsDeleteModalVisible(true)}
										danger
									>
										{'Delete'}
									</Button>
								</div> */}

										<Modal
											title={'Delete'}
											visible={isDeleteModalVisible}
											onOk={() => onDelete(qryData?.getService.id)}
											onCancel={() => setIsDeleteModalVisible(false)}
											width={300}
										>
											Delete Service?
											{/* Delete */}
											{dataMtD && (
												<Alert message="Deleted" type="success" showIcon />
											)}
											{loadingMtD && (
												<Alert message="Deleting..." type="info" showIcon />
											)}
											{errorMtD && (
												<Alert
													message={`Submission error! ${errorMtD.message}`}
													type="error"
													showIcon
												/>
											)}
										</Modal>

										{isEditMode && qryData && (
											<div className="gx-mb-4">
												<h2>Service Options</h2>
												<hr />

												<Radio.Group
													className="gx-radio-group-link gx-radio-group-link-news"
													defaultValue={0}
													onChange={handleServiceOptionsTabs}
													style={{ marginBottom: '2rem' }}
												>
													<Radio.Button value={0} className="gx-mb-1">
														Billing Options
													</Radio.Button>
													{/* <Radio.Button value={1} className="gx-mb-1">
														Additional Inputs
													</Radio.Button> */}
													<Radio.Button value={2} className="gx-mb-1">
														Service Requirements
													</Radio.Button>
													<Radio.Button value={3} className="gx-mb-1">
														Service Addons
													</Radio.Button>
													{qryData?.getService?.isTeamService &&
													<Radio.Button value={4} className="gx-mb-1">
														Service Property Area
													</Radio.Button>
													}
												</Radio.Group>

												{/* <br/> */}

												{activeTabValue === 0 ? (
													// <Card
													// 	type="inner"
													// 	headStyle={{ padding: '0px 24px' }}
													// 	title="Billing Options"
													// 	extra={
													// 		<AddBillingOption
													// 			serviceID={qryData?.getService.id}
													// 			onFinishAdd={refetchQryData}
													// 		/>
													// 	}
													// >

													// Billing Options
													<div>
														<div
															style={{ display: 'flex', justifyContent: 'end' }}
														>
															<AddBillingOption
																serviceID={qryData?.getService.id}
																onFinishAdd={refetchQryData}
																getService={qryData?.getService}
															/>
															{/* {qryData?.getService.billingOptions.length === 0 && (
														<p>No Options</p>
													)} */}
														</div>

														<Table
															dataSource={qryData?.getService.billingOptions}
															pagination={false}
														>
															{/* <Column title="ID" dataIndex="id" key="id" width={120} /> */}
															<Column
																title="Name"
																dataIndex="name"
																key="name"
																align="center"
															/>
															<Column
																title="Description"
																dataIndex="description"
																key="description"
																align="center"
															/>
															<Column
																title="Total Price"
																dataIndex="grandTotal"
																key="grandTotal"
																align="center"
																render={(text, record: any) => (
																	<span>
																		₹
																		{record.unitPrice?.grandTotal?.toLocaleString()}
																	</span>
																)}
															/>
															<Column
																title="Unit Type"
																dataIndex="unit"
																key="unit"
																align="center"
															/>
															{/* <Column
														title="Recurring"
														dataIndex="recurring"
														key="recurring"
														render={(recurring) => (
															<>{recurring.toString().toUpperCase()}</>
														)}
													/> */}
															{/* <Column
														title="Recurring period"
														dataIndex="recurringPeriod"
														key="recurringPeriod"
													/> */}

															{/* <Column
														title="Unit Range"
														dataIndex="minUnit"
														key="minUnit"
													/> */}

															<Column
																title="Min Unit"
																dataIndex="minUnit"
																key="minUnit"
																align="center"
															/>
															<Column
																title="Max Unit"
																dataIndex="maxUnit"
																key="maxUnit"
																align="center"
															/>

															{/* <Column
																title="Job Board"
																dataIndex="maxUnit"
																key="autoAssignPartner"
																align="center"
																render={(autoAssignPartner: boolean) => {
																	return autoAssignPartner === true ? (
																		<span>🟢</span>
																	) : (
																		<span>🔴</span>
																	);
																}}
															/> */}

															<Column
																title="Action"
																dataIndex="id"
																key="id"
																width={200}
																align="center"
																render={(
																	id,
																	record: CMSCreateServiceBillingOption_createServiceBillingOption,
																) => (
																	<>
																		<AddBillingOption
																			serviceID={qryData?.getService.id}
																			input={record}
																			onFinishAdd={refetchQryData}
																			getService={qryData?.getService}
																		/>
																	</>
																)}
															/>
														</Table>
													</div>
												) : // </Card>

												activeTabValue === 1 ? (
													// Service Inputs
													<div>
														<div
															style={{ display: 'flex', justifyContent: 'end' }}
														>
															<AddServiceInput
																serviceID={qryData?.getService.id}
																onFinishAdd={refetchQryData}
															/>
														</div>

														{!qryData?.getService?.inputs && <p>No inputs</p>}

														{qryData?.getService?.inputs && (
															<Table
																dataSource={qryData?.getService?.inputs}
																pagination={false}
															>
																{/* <Column title="ID" dataIndex="id" key="id" /> */}

																<Column title="Key" dataIndex="key" key="id" />

																<Column
																	title="Name"
																	dataIndex="name"
																	key="name"
																	align="center"
																/>
																<Column
																	title="Description"
																	dataIndex="description"
																	key="description"
																	align="center"
																/>
																<Column
																	title="Required"
																	dataIndex="required"
																	key="required"
																	render={(required) => (
																		<>{required.toString().toUpperCase()}</>
																	)}
																	align="center"
																/>
																<Column
																	title="Type"
																	dataIndex="type"
																	key="type"
																	render={(type) => (
																		<>{type.toString().toUpperCase()}</>
																	)}
																	align="center"
																/>

																<Column
																	title="Action"
																	dataIndex="id"
																	key="id"
																	render={(
																		id,
																		record: CMSGetService_getService_inputs,
																	) => (
																		<>
																			<AddServiceInput
																				serviceID={qryData?.getService.id}
																				input={record}
																				onFinishAdd={refetchQryData}
																			/>
																		</>
																	)}
																	align="center"
																/>
															</Table>
														)}
													</div>
												) : activeTabValue === 2 ? (
													// Service Requirements
													<div>
														<div
															style={{ display: 'flex', justifyContent: 'end' }}
														>
															<AddServiceRequirement
																serviceID={qryData?.getService.id}
																onFinishAdd={refetchQryData}
															/>
														</div>

														{!qryData?.getService?.requirements && (
															<p>No requirements</p>
														)}

														{qryData?.getService?.requirements && (
															<Table
																dataSource={qryData?.getService?.requirements}
																pagination={false}
															>
																{/* <Column title="ID" dataIndex="id" key="id" /> */}
																<Column
																	title="Title"
																	dataIndex="title"
																	key="title"
																	align="center"
																/>
																<Column
																	title="Description"
																	dataIndex="description"
																	key="description"
																	align="center"
																/>

																<Column
																	title="Action"
																	dataIndex="id"
																	key="id"
																	render={(
																		id,
																		record: CMSGetService_getService_requirements,
																	) => (
																		<>
																			<AddServiceRequirement
																				serviceID={qryData?.getService.id}
																				input={record}
																				onFinishAdd={refetchQryData}
																			/>
																		</>
																	)}
																	align="center"
																/>
															</Table>
														)}
													</div>
												) : activeTabValue === 3 ? (
													// Additional Inputs
													<div>
														<div
															style={{ display: 'flex', justifyContent: 'end' }}
														>
															<AddAdditionalInput
																serviceID={qryData?.getService.id}
																onFinishAdd={refetchQryData}
															/>
														</div>
														{!qryData?.getService?.inputs && <p>No inputs</p>}

														{qryData?.getService?.addons && (
															<Table
																dataSource={qryData?.getService?.addons}
																pagination={false}
															>
																{/* <Column title="ID" dataIndex="id" key="id" /> */}
																<Column
																	title="Name"
																	dataIndex="name"
																	key="name"
																	align="center"
																/>
																<Column
																	title="Description"
																	dataIndex="description"
																	key="description"
																	align="center"
																/>
																<Column
																	title="Unit Type"
																	dataIndex="unit"
																	key="unit"
																	align="center"
																/>
																<Column
																	title="Total Price"
																	dataIndex="grandTotal"
																	key="grandTotal"
																	align="center"
																	render={(text, record: any) => (
																		<span>
																			₹
																			{record.unitPrice?.grandTotal?.toLocaleString()}
																		</span>
																	)}
																/>
																<Column
																	title="Min Unit"
																	dataIndex="minUnit"
																	key="minUnit"
																	align="center"
																/>
																<Column
																	title="Max Unit"
																	dataIndex="maxUnit"
																	key="maxUnit"
																	align="center"
																/>

																<Column
																	title="Action"
																	dataIndex="id"
																	key="id"
																	render={(
																		id,
																		record: CMSCreateServiceAddon,
																	) => (
																		<>
																			<AddAdditionalInput
																				serviceID={qryData?.getService.id}
																				input={record}
																				onFinishAdd={refetchQryData}
																			/>
																		</>
																	)}
																	align="center"
																/>
															</Table>
														)}
													</div>
												) : activeTabValue === 4 ? (
													// Additional Inputs
													<div>
														<div
															style={{ display: 'flex', justifyContent: 'end' }}
														>
															<AddPropertyArea
																serviceID={qryData?.getService.id}
																onFinishAdd={refetchQryData}
															/>
														</div>
														{!qryData?.getService?.inputs && <p>No inputs</p>}

														{/* {qryData?.getService?.propertyAreas && ( */}
															<Table
																dataSource={qryData?.getService?.propertyAreas}
																pagination={false}
															>
																{/* <Column title="ID" dataIndex="id" key="id" /> */}
																{/* <Column
																	title="id"
																	dataIndex="id"
																	key="id"
																	align="center"
																/> */}
																<Column
																	title="title"
																	dataIndex="title"
																	key="title"
																	align="center"
																/>
																<Column
																	title="hours"
																	dataIndex="hours"
																	key="hours"
																	align="center"
																/>
																
																<Column
																	title="description"
																	dataIndex="description"
																	key="description"
																	align="center"
																/>
																

																<Column
																	title="Action"
																	dataIndex="id"
																	key="id"
																	render={(
																		id,
																		record: CMSCreateServiceAddon,
																	) => (
																		<>
																			<AddPropertyArea
																				serviceID={qryData?.getService.id}
																				input={record}
																				onFinishAdd={refetchQryData}
																			/>
																		</>
																	)}
																	align="center"
																/>
															</Table>
														{/* )} */}
													</div>
												):(
													<></>
												)}
											</div>
										)}
									</Form>
								)}
							</Col>
						</Row>
					</>
				)}
			</div>
		</Card>
	);
};
export default AddService;
