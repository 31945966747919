import { DeleteOutlined, EditOutlined, LinkOutlined, SearchOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Switch, Table,Image, Tabs, Upload, Select, Alert,Pagination, Button, Card, Col, Modal, Row, Dropdown, message, Space, Tooltip } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { useEffect, useState } from 'react';
import {
    CMSGetCmsBanners,
    CMSGetCmsBannersVariables,
} from 'src/lib/gql/generated/CMSGetCmsBanners';
import { CMSQueryGetBanners } from 'src/lib/gql/queries/cms';
import { Input, RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { CMSGetServices,CMSGetServicesVariables } from 'src/lib/gql/generated/CMSGetServices';
import { CMSQueryGetServices } from 'src/lib/gql/queries/services';
import { CMSGetAllServiceCategories } from 'src/lib/gql/generated/CMSGetAllServiceCategories';
import { CMSQueryGetAllServiceCategories } from 'src/lib/gql/queries/services';
import { CMSCreateBanner,CMSCreateBannerVariables } from 'src/lib/gql/generated/CMSCreateBanner';
import { CREATE_BANNER } from 'src/lib/gql/mutations/mutations';
import { EnumBannerResourceType } from 'src/lib/gql/generated/globalTypes';
import { UploadPublicImage, UploadPublicImage2,UploadPublicImage3 } from 'src/components/shared/UploadPublicImage';
import { CMSDeleteBanner,CMSDeleteBannerVariables } from 'src/lib/gql/generated/CMSDeleteBanner';
import { DELETE_BANNER ,UPDATE_BANNER} from 'src/lib/gql/mutations/mutations';
import { CMSUpdateBanner,CMSUpdateBannerVariables } from 'src/lib/gql/generated/CMSUpdateBanner';
import { useNavigate } from 'react-router-dom';
import {  CaretLeftOutlined } from '@ant-design/icons';
import CKEditor from 'react-ckeditor-component';
import { CMSQueryGetHomeContent } from 'src/lib/gql/queries';
import { UPDATE_HOME_CONTENT } from 'src/lib/gql/mutations/mutations';

const HomeContent: React.FC = () => {
	const { Option } = Select;
    const { Dragger } = Upload;
    const { TabPane } = Tabs;
    const [value, setValue] = useState<any>();
    const [link,setLink]=useState<any>()
    const [resourceData,setResourceData]=useState<any>()
	const [picId, setPicId] = useState('');
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [deletData,setDeletData]= useState<any>();
    const [editInfo,setEditInfo]=useState<any>();
    const [title,setTitle]=useState<any>()
    const [statusCheck,setStatusCheck]=useState<any>()
    const [hideReset, setHideReset] = useState(false);
	const [content, setcontent] = useState<any>();
    // const [proActivate,setProActivate]=useState(false)
	// const [proDeactivate,setProDeactivate]=useState(false)
	const [result,setResult]=useState(false)

	const navigate = useNavigate();
    
	const { data: qryData, refetch: refetchQryData } = useQuery(CMSQueryGetHomeContent);
	const handleClick =(e)=>{
		// setResult(true)
		console.log("e",e);
		
		setResult(e)
	}
		useEffect(()=>{
		setResult(qryData?.getHomeContent?.status)
		setcontent(qryData?.getHomeContent?.description)
		setPicId(qryData?.getHomeContent?.image)
		},[qryData])

	const [createAlert, setCreateAlert] = useState(false)
   
    // create
    const [
		createDetails,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation(UPDATE_HOME_CONTENT,
        {
            onCompleted: () => {
                refetchQryData();
                // setTimeout(() => {
                //     dataMtU(false)
                // }, 1500);
            },
         } );

    const props: UploadProps = {
        name: 'file',
        multiple: true,
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    // console.log("filteredData",filteredData);
    const [errorMsg,setErrorMsg]=useState(false)
    const [errorMsgDesc,setErrorMsgDesc]=useState(false)

    useEffect(()=>{
        setTimeout(() => {
            setErrorMsg(false)
            }, 3000)
    },[errorMsg])

    const bannerSave=(event)=>{
        // if(!editInfo){
			console.log("content in",content);
			// console.log("result in",result);
			event.preventDefault();
            if (content!=="<p>&nbsp;</p>\n\n<p>&nbsp;</p>\n"){
                setErrorMsg(false)
                setErrorMsgDesc(false)
                createDetails({
                    variables: {
                        data: {
							// id: qryData?.getHomeContent?.id,
                            image: picId,
                            status: result,
                            description:content
                        },
                    }
                })
                setCreateAlert(true)
                setTimeout(() => {
                setCreateAlert(false)
                }, 2000)
            }else{
                setErrorMsgDesc(true)
            }

    }


    const editorConfig = {
		// extraPlugins: 'font',
		// Customize options here
		toolbar: [
			'/',
			{
				name: 'basicstyles',
				items: [
					'Bold',
					'Italic',
					'Underline',
					'Strike',
					'Subscript',
					'Superscript',
					'-',
					'RemoveFormat',
				],
			},
			{
				name: 'paragraph',
				items: [
					'NumberedList',
					'BulletedList',
					'-',
					'CreateDiv',
					'-',
					'JustifyLeft',
					'JustifyCenter',
					'JustifyRight',
					'JustifyBlock',
					'Bidi'
				],
			},
			{ name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
			{ name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },

			'/',
			{ name: 'others', items: ['-'] },
		],
		// ...
	};


	return (
		<>
		<Card>
		<Row>
		   <Col span={20}>
		   <h1><Button className="gx-mr-4" onClick={() => navigate(-1)} style={{margin:"0"}}>
			<CaretLeftOutlined />
			</Button>Home Content</h1>
		   </Col>
					<Col span={4} style={{display:"flex"}} >
					{/* <Space wrap> */}				
						<Button type="primary" onClick={bannerSave}> Save</Button>
					{/* </Space> */}
				</Col>
			</Row>
			<hr/>
			<Row>
			<Col span={20} >
			{createAlert && !loadingMtU && <Alert message="Submitted" type="success" showIcon />}
			{loadingMtU && <Alert message="Submiting..." type="info" showIcon />}
			{errorMtU && (
			<Alert
			message={`Submission error! ${errorMtU.message}`}
			type="error"
			showIcon
			/>)}
            </Col>
			</Row>
			<Row >
				
			
			<Col span={12}>
			<Card style={{borderRadius:"1rem"}}>
				<div>
			<span>Status</span><div style={{float:"right"}}><Switch  onChange={e => handleClick(e)} checked={result}/></div>
			</div><br/><br/>
			<div>
				<span>Banner Image</span>  
			   <div style={{float:"right"}}>
				{picId ? (
						<UploadPublicImage3
									onFinishAdd={(info) =>
										setPicId(info?.file.response.data.url)
									}	
							// serviceIconUrl={editInfo?.media?.url}
							serviceIconUrl={
								`https://staging.api.zimkey.in/media-upload/${qryData?.getHomeContent?.image}`
							}						
								/>
				):(
					<UploadPublicImage
					onFinishAdd={(info) =>
						setPicId(info?.file.response.data.url)
					}
					/>
				)}
				</div></div><br/><br/>
				<div style={{paddingTop: "60px"}}>
				<span>Description</span>  <div >
                <CKEditor 
				required
			    activeClass="p10"
				content={content}
				events={{
					change: (evt) => setcontent(evt.editor.getData()),
				}}
			    config={editorConfig}
		    />
			{errorMsgDesc && <p style={{color:"red"}}>Description required</p>}
					</div>
				</div>
			   </Card>
			   </Col>
			</Row>
		
		</Card>
	</>
	);
};
export default HomeContent;
