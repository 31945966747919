import { useMutation, useQuery } from '@apollo/client';
import { Input, message, AutoComplete, Button, Card, Col, Form, Row, Select } from 'antd';
import React, { useEffect, useState, createRef } from 'react';
import { useParams } from 'react-router-dom';
import CKEditor from 'react-ckeditor-component';
import { useNavigate } from 'react-router-dom';
import { CMSGetCmsContent } from 'src/lib/gql/generated/CMSGetCmsContent';
import {
	CMSUpdateCmsContent,
	CMSUpdateCmsContentVariables,
} from 'src/lib/gql/generated/CMSUpdateCmsContent';
import { UPDATE_CMS_CONTENT } from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetCmsContent } from 'src/lib/gql/queries/cms';
import Widget from 'src/components/widget';
import { Radio } from 'antd';
import CircularProgress from 'src/components/CircularProgress';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CaretLeftOutlined } from '@ant-design/icons';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { userAtom } from 'src/lib/recoil/userCoil';
import { useRecoilValue } from 'recoil';


const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

const AppContent: React.FC = () => {
	const userAuth = useRecoilValue(userAtom);

	const {
		loading,
		data: qryData,
		called,
		error,
		refetch: refetchQryData,
	} = useQuery(CMSQueryGetCmsContent);
	return loading ? (
		<p>Loading...</p>
	) : (
		<AppContentForm data={qryData} onFinishAdd={refetchQryData} />
	);
};

interface Props {
	data?: CMSGetCmsContent | undefined;
	onFinishAdd?: () => void;
}

const AppContentForm: React.FC<Props> = ({ data, onFinishAdd }) => {

	const [isSaved, setIsSaved] = useState(false);

	const [loader, setLoader] = useState(false);
	const navigate = useNavigate();
	const userAuth = useRecoilValue(userAtom);

	const {
		loading,
		data: qryData,
		refetch: refetchQryData,
	} = useQuery(CMSQueryGetCmsContent, {
		variables: {

		},

	});

	const [updateContent, { data: dataMt, loading: loadingMt, error: errorMt }] =
		useMutation(
			UPDATE_CMS_CONTENT,
			{
				onCompleted: () => {
					refetchQryData();
				},
			},
		);

	const inputRef = createRef();
	useEffect(() => {
		setAboutUs(qryData?.getCmsContent?.aboutUs)
		setReferPolicy(qryData?.getCmsContent?.referPolicy)
		setTermsConditionsCustomer(qryData?.getCmsContent?.termsConditionsCustomer)
		setTermsConditionsPartner(qryData?.getCmsContent?.termsConditionsPartner)
		setPrivacyPolicy(qryData?.getCmsContent?.privacyPolicy)
		setSafetyPolicy(qryData?.getCmsContent?.safetyPolicy)
		setcancellationPolicyPartner(qryData?.getCmsContent?.cancellationPolicyPartner)
		setcancellationPolicyCustomer(qryData?.getCmsContent?.cancellationPolicyCustomer)
	}, [qryData])

	const [aboutUs, setAboutUs] = useState<any>();
	const [referPolicy, setReferPolicy] = useState<any>();
	const [termsConditionsCustomer, setTermsConditionsCustomer] = useState<any>();
	const [termsConditionsPartner, setTermsConditionsPartner] = useState<any>();
	const [privacyPolicy, setPrivacyPolicy] = useState<any>();
	const [safetyPolicy, setSafetyPolicy] = useState<any>();
	const [cancellationPolicyPartner, setcancellationPolicyPartner] = useState<any>();
	const [cancellationPolicyCustomer, setcancellationPolicyCustomer] = useState<any>();


	const [content, setContent] = useState({}); // State to store content values
	const [selectedRadio, setSelectedRadio] = useState<any>(0); // State to track selected radio button

	// const [form] = Form.useForm();
	const dataaaa = (evt, editor) => {
		setReferPolicy(data)
	}


	const handleRadioChange = (e) => {
		setSelectedRadio(e.target.value); // Update the selected radio button value
	};

	const onFinish = (values) => {
		// console.log('Received values of form: ', aboutUs);
		updateContent({
			variables: {
				data: {
					aboutUs: aboutUs,
					referPolicy: referPolicy,
					termsConditionsCustomer: termsConditionsCustomer,
					termsConditionsPartner: termsConditionsPartner,
					privacyPolicy: privacyPolicy,
					safetyPolicy: safetyPolicy,
					cancellationPolicyPartner: cancellationPolicyPartner,
					cancellationPolicyCustomer: cancellationPolicyCustomer
				},
			},
		})
		setIsSaved(true);
		setTimeout(() => {
			setIsSaved(false);
		}, 2000);

	};
	const editorConfig = {
		// Customize options here
		toolbar: [
			//   { name: 'document', items: ['Source', '-', 'NewPage', 'Preview', '-', 'Templates'] },
			//   { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
			//   { name: 'editing', items: ['Find', 'Replace', '-', 'SelectAll'] },
			'/',
			{
				name: 'basicstyles',
				items: [
					'Bold',
					'Italic',
					'Underline',
					'Strike',
					'Subscript',
					'Superscript',
					'-',
					'RemoveFormat',
				],
			},
			{
				name: 'paragraph',
				items: [
					'NumberedList',
					'BulletedList',
					'-',
					'CreateDiv',
					'-',
					'JustifyLeft',
					'JustifyCenter',
					'JustifyRight',
					'JustifyBlock',
				],
			},
			//   { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
			//   { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar'] },
			'/',
			//   { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
			//   { name: 'colors', items: ['TextColor', 'BGColor'] },
			//   { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
			{ name: 'others', items: ['-'] },
		],
		// ...
	};

	return (
		<>
			<Card
				title={
					<div>
						<Button className="gx-mr-4" onClick={() => navigate(-1)} style={{ margin: "0" }}>
							<CaretLeftOutlined />
						</Button>
						Manage App Content

					</div>
				}
			>
				{/* <div>
				<h2></h2>
			</div><br/>
			<div className="ant-card-head ">
						<Button className="gx-mr-4" onClick={() => navigate(-1)}>
							Back
						</Button> */}

				{/* <span className="ant-card-head-title gx-mb-1">Bookings</span> */}
				{/* <p className="gx-text-grey gx-fs-sm gx-mb-0">Booking List</p> */}
				{/* </div> */}
				<Widget>
					<div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-dash-search">
						<div className="gx-mx-sm-2">
							<Radio.Group
								className="gx-radio-group-link gx-radio-group-link-news"
								value={selectedRadio}
								onChange={handleRadioChange}
							>
								<Radio.Button value={0} className="gx-mb-1">
									About us
								</Radio.Button>
								<Radio.Button value={1} className="gx-mb-1">
									Refer & Earn
								</Radio.Button>
								<Radio.Button value={2} className="gx-mb-1">
									T & C Customer
								</Radio.Button>
								<Radio.Button value={6} className="gx-mb-1">
									T & C Partner
								</Radio.Button>
								<Radio.Button value={3} className="gx-mb-1">
									Privacy Policy
								</Radio.Button>
								<Radio.Button value={4} className="gx-mb-1">
									Safety Policy
								</Radio.Button>
								<Radio.Button value={5} className="gx-mb-1">
									Cancellation Policy Partner
								</Radio.Button>
								<Radio.Button value={7} className="gx-mb-1">
									Cancellation Policy Customer
								</Radio.Button>
							</Radio.Group>
						</div>
					</div>

					{
						selectedRadio == 0 && (
							<>
								<Row>
									{userAuth?.userType === UserTypeEnum.ADMIN ||
										userAuth?.rights?.find(
											(x) => x.type === 'sub2' && x.name === 'about-us-edit',
										) ? (
										<>
											<Col span={24}>
												<Button type="primary" onClick={onFinish} style={{ float: "right" }}>
													Save
												</Button><br /><br />
											</Col>
											<Col span={24}>
												<CKEditor
													// value={data?.getCmsContent?.aboutUs}
													// data={data?.getCmsContent?.aboutUs}
													// content={data?.getCmsContent?.aboutUs}
													activeClass="p10"
													content={aboutUs}
													events={{
														change: (evt) => setAboutUs(evt.editor.getData()),
													}}
													config={editorConfig}
												/></Col></>
									) : (
										<Col span={24}>
											<CKEditor
												// value={data?.getCmsContent?.aboutUs}
												// data={data?.getCmsContent?.aboutUs}
												// content={data?.getCmsContent?.aboutUs}
												activeClass="p10"
												content={aboutUs}
												readOnly={true}
											// events={{
											// 	change: (evt) => setAboutUs(evt.editor.getData()),
											// }}			
											config={editorConfig}

											/></Col>
									)}

								</Row>
							</>

						)}
					{/* // ) : */}

					{selectedRadio == 1 && (
						<>
							<Row>
								{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub2' && x.name === 'refer-earn-edit',
									) ? (
									<>
										<Col span={24}>
											<Button type="primary" onClick={onFinish} style={{ float: "right" }}>
												Save
											</Button><br /><br />
										</Col>
										<Col span={24}>
											<CKEditor ref={inputRef}
												activeClass="p10"
												content={referPolicy}
												events={{
													change: (evt) => setReferPolicy(evt.editor.getData()),
												}}
												config={editorConfig}

											/>
										</Col></>
								) : (
									<>
										<Col span={24}>
											<CKEditor ref={inputRef}
												readOnly={true}
												activeClass="p10"
												content={referPolicy}
											// onChange={dataaaa}
											config={editorConfig}

											/>
										</Col></>
								)}

							</Row>
						</>
					)}
					{selectedRadio == 2 && (
						<>
							<Row>
								{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub2' && x.name === 'terms-customer-edit',
									) ? (
									<>
										<Col span={24}>
											<Button type="primary" onClick={onFinish} style={{ float: "right" }}>
												Save
											</Button><br /><br />
										</Col>			<Col span={24}>
											<CKEditor
												activeClass="p10"
												content={termsConditionsCustomer}
												events={{
													change: (evt) => setTermsConditionsCustomer(evt.editor.getData()),
												}}
												config={editorConfig}
											/>
										</Col></>
								) : (
									<>
										<Col span={24}>
											<CKEditor
												readOnly={true}
												activeClass="p10"
												content={termsConditionsCustomer}
											// events={{
											// 	change: (evt) => setTermsConditionsCustomer(evt.editor.getData()),
											// }}
											config={editorConfig}
											/></Col>
									</>
								)}

							</Row>
						</>)}
					{selectedRadio == 6 && (
						<>
							<Row>
								{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub2' && x.name === 'terms-partner-edit',
									) ? (
									<>
										<Col span={24}>
											<Button type="primary" onClick={onFinish} style={{ float: "right" }}>
												Save
											</Button><br /><br />
										</Col>			<Col span={24}>
											<CKEditor
												activeClass="p10"
												content={termsConditionsPartner}
												events={{
													change: (evt) => setTermsConditionsPartner(evt.editor.getData()),
												}}
												config={editorConfig}
											/>
										</Col></>
								) : (
									<>
										<Col span={24}>
											<CKEditor
												readOnly={true}
												activeClass="p10"
												content={termsConditionsPartner}
											// events={{
											// 	change: (evt) => setTermsConditionsCustomer(evt.editor.getData()),
											// }}
											config={editorConfig}
											/></Col>
									</>
								)}

							</Row>
						</>)}

					{selectedRadio == 3 && (
						<>
							<Row>
								{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub2' && x.name === 'privacy-policy-edit',
									) ? (
									<>
										<Col span={24}>
											<Button type="primary" onClick={onFinish} style={{ float: "right" }}>
												Save
											</Button><br /><br />
										</Col>
										<Col span={24}>

											<CKEditor
												activeClass="p10"
												content={privacyPolicy}
												events={{
													change: (evt) => setPrivacyPolicy(evt.editor.getData()),
												}}
												config={editorConfig}
											/></Col>
									</>
								) : (
									<>
										<Col span={24}>
											<CKEditor
												readOnly={true}
												activeClass="p10"
												content={privacyPolicy}
											// events={{
											// 	change: (evt) => setPrivacyPolicy(evt.editor.getData()),
											// }}
											config={editorConfig}
											/></Col>
									</>
								)}
							</Row>
						</>
					)}

					{selectedRadio == 4 && (
						<>
							<Row>
								{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub2' && x.name === 'safety-policy-edit',
									) ? (
									<>
										<Col span={24}>
											<Button type="primary" onClick={onFinish} style={{ float: "right" }}>
												Save
											</Button><br /><br />
										</Col>
										<Col span={24}>
											<>
												<CKEditor
													activeClass="p10"
													content={safetyPolicy}
													events={{
														change: (evt) => setSafetyPolicy(evt.editor.getData()),
													}}
													config={editorConfig}
												/></></Col>
									</>
								) : (
									<>
										<Col span={24}>
											<CKEditor
												activeClass="p10"
												readOnly={true}
												content={safetyPolicy}
											// events={{
											// 	change: (evt) => setSafetyPolicy(evt.editor.getData()),
											// }}
											config={editorConfig}
											/></Col>
									</>
								)}
							</Row>

						</>
					)}


					{selectedRadio == 5 && (
						<>
							<Row>
								{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub2' && x.name === 'cancellation-policy-partner-edit',
									) ? (
									<>
										<Col span={24}>
											<Button type="primary" onClick={onFinish} style={{ float: "right" }}>
												Save
											</Button><br /><br />
										</Col>
										<Col span={24}>
											<CKEditor
												activeClass="p10"
												content={cancellationPolicyPartner}
												events={{
													change: (evt) => setcancellationPolicyPartner(evt.editor.getData()),
												}}
												config={editorConfig}
											/></Col></>
								) : (
									<>
										<Col span={24}>
											<CKEditor
												activeClass="p10"
												content={cancellationPolicyPartner}
												config={editorConfig}
												disabled={true}
											/></Col></>
								)}
							</Row>
						</>
					)}

					{selectedRadio == 7 && (
						<>
							<Row>
								{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub2' && x.name === 'cancellation-policy-customer-edit',
									) ? (
									<>
										<Col span={24}>
											<Button type="primary" onClick={onFinish} style={{ float: "right" }}>
												Save
											</Button><br /><br />
										</Col>
										<Col span={24}>
											<CKEditor
												activeClass="p10"
												content={cancellationPolicyCustomer}
												events={{
													change: (evt) => setcancellationPolicyCustomer(evt.editor.getData()),
												}}
												config={editorConfig}
											/></Col></>
								) : (
									<>
										<Col span={24}>
											<CKEditor
												activeClass="p10"
												content={cancellationPolicyCustomer}
												config={editorConfig}
												disabled={true}
											/></Col></>
								)}
							</Row>
						</>
					)}

				</Widget>
			</Card>

			{isSaved && (
				<div className="success-message">
					Save successfully!
				</div>
			)}
		</>

	);
};

export default AppContent;
