import { gql } from '@apollo/client';

export const CMSQueryGetFaqs = gql`
	query CMSGetFaqs($appType: [AppTypeEnum!]!) {
		getFaqs(appType: $appType) {
			id
			question
			answer
			appType
			sortOrder
			category
		}
	}
`;
