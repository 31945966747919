import { useMutation } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { loginUser } from 'src/lib/firebase/fbClient';
import { ADMIN_LOGIN } from 'src/lib/gql/mutations/mutations';
import { userTokenAtom } from 'src/lib/recoil/userCoil';

interface Props {}

const Signin: React.FC = (props: Props) => {
	const [userToken, setUserToken] = useRecoilState(userTokenAtom);
	const navigate = useNavigate();

	useEffect(() => {
		console.log('userToken', userToken);
		if (userToken !== null) {
			// window.location.href = '/';
		}
	}, [userToken, history]);

	const [adminLogin, { data: dataMp, loading: loadingMp, error: errorMp }] =
	useMutation(ADMIN_LOGIN);
	const fnLogin = async (data: { email: string; password: string }) => {
		try {
			const data2 = await adminLogin({
				variables: {
					data
				},
			});
			localStorage.setItem('zk_acc_tkn', data2.data.adminLogin.data.token);
			setUserToken(data2.data.adminLogin.data.token)
			navigate('/')

			// await loginUser(data, setUserToken, adminLogin);
		} catch (error) {
			alert('Username or Password is wrong');
		}
	};
	

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const onFinish = (values) => {
		fnLogin({ email: values.email, password: values.password });
	};

	return (
		<div className="gx-app-login-wrap">
			<div className="gx-app-login-container">
				<div className="gx-app-login-main-content">
					<div className="gx-app-logo-content">
						<div className="gx-app-logo-content-bg"></div>
						<div className="gx-app-logo-wid">
							<h1>Sign in</h1>
						</div>
						<div className="gx-app-logo">
							<img
								alt="Taskey logo"
								src="/assets/images/taskey-logo-white.svg"
							/>
						</div>
					</div>
					<div className="gx-app-login-content">
						<Form
							initialValues={{ remember: true }}
							name="basic"
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							className="gx-signin-form gx-form-row0"
						>
							<Form.Item
								rules={[
									{ required: true, message: 'The input is not valid E-mail!' },
								]}
								name="email"
							>
								<Input placeholder="Email" />
							</Form.Item>
							<Form.Item
								rules={[
									{ required: true, message: 'Please input your Password!' },
								]}
								name="password"
							>
								<Input type="password" placeholder="Password" />
							</Form.Item>
							<Form.Item>
								<Button type="primary" className="gx-mb-0" htmlType="submit">
									Login
								</Button>
							</Form.Item>
						</Form>
					</div>

					<div className="gx-loader-view" style={{ display: 'none' }}>
						Loading
					</div>
				</div>
			</div>
		</div>
	);
};

export default Signin;
