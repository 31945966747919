import React,{useEffect,useState} from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip,Legend, RadialBar} from 'recharts';
import noData from "./noDataa.png"
import Widget from 'src/components/widget';
import { Row,Col } from 'antd';
interface Props {
	TotalBooking: any;
	Booking:any
}


const COLORS = ['#5797fc', '#FA8C16', '#f5222d', 'grey','green'];

const TaskByStatusPartners = ({TotalBooking,Booking}:Props) => {
	// console.log("TotalBooking",TotalBooking);
    const[openCount,setOpenCount]=useState<number>()
    const[createCount,setCreateCount]=useState<number>()
    const[pendingCount,setPendingCount]=useState<number>()
    const[pendingFailedCount,setPendingFailedCount]=useState<number>()
    const[closeCount,setCloseCount]=useState<number>()

    useEffect(()=>{
    const open =Booking?.filter(value => value.bookingStatus ==="OPEN");
    const create =Booking?.filter(value => value.bookingStatus ==="CREATED");
    const pending =Booking?.filter(value => value.bookingStatus ==="PAYMENT_PENDING");
	const closed =Booking?.filter(value => value.bookingStatus ==="CLOSED");
	const failed =Booking?.filter(value => value.bookingStatus ==="PAYMENT_FAILED");
	setOpenCount(open?.length)
    setCreateCount(create?.length)
    setPendingCount(pending?.length)
	setPendingFailedCount(failed?.length)
	setCloseCount(closed?.length)
    },[Booking])


	const data = [
        { name: 'Open', value: openCount },
        { name: 'Created', value: createCount },
        { name: 'Payment Pending', value: pendingCount },
		{ name: 'Payment Failed', value: pendingFailedCount },
		{ name: 'Closed', value: closeCount },

    ];
	
	return (
		<Widget
			// title={
			// 	<h2 className="h4 gx-text-capitalize gx-mb-0">Total Bookings - {Booking?.length}</h2>
			// }
			styleName="gx-text-center"
		>
			{Booking?.length > 1 ? (
			<div className="gx-py-3">
				<ResponsiveContainer width="100%" height={290}>
					<PieChart>
						<Tooltip />
						{/* <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie> */}
			<Pie
							data={data}
							dataKey="value"
							cx="50%"
							cy="50%"
							innerRadius={47}
							outerRadius={57}
							fill="#8884d8"
						>
							{data.map((entry, index) => (
								<Cell key={index} fill={COLORS[index % COLORS.length]} />
							))}
						</Pie>
						{/* <text
							x="50%"
							className="h1"
							y="50%"
							textAnchor="middle"
							dominantBaseline="middle"
						>
                            {Booking?.length}
						</text> */}
						{/* <Pie
							data={data}
							dataKey="value"
							cx="50%"
							cy="50%"
							innerRadius={47}
							outerRadius={57}
							fill="#8884d8"
						>
							{data.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={COLORS[index ]} />
							))}
						</Pie> */}
						{/* <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%"
						 innerRadius={60} outerRadius={80} fill="#82ca9d" label >
							{data.map((entry, index) => (
								<Cell key={index} fill={COLORS[index % COLORS.length]} />
							))}
						</Pie> */}

						

						<RadialBar label={{ fill: '#666', position: 'right' }}  dataKey='uv'  /> 
  						<Legend iconSize={20}  layout='vertical' verticalAlign='middle' align="right"    /> 
					</PieChart>
					
				</ResponsiveContainer>
				{/* <Row>
					<Col span={5}>
					{ openCount  !==0  ? (
					<>
					<p>Open {openCount}</p></>
					):("")}
					</Col>
					<Col span={5}>
					{ createCount !==0  ? (
					<>
					<p>Created {createCount}</p></>
					):("")}
					</Col>
					<Col span={5}>
					{ pendingCount  !==0  ?  (
					<>
					<p>Payment Pending {pendingCount}</p></>
					):("")}
					</Col>
					<Col span={5}>
					{ pendingFailedCount  !==0  ?  (
					<>
					<p>Payment Failed {pendingFailedCount}</p></>
					):("")}
					</Col>
					<Col span={4}>
					{ closeCount!==0  ?  (
					<>
					<p>Closed {closeCount}</p></>
					):("")}
					</Col>
				</Row> */}

			</div>
			):(
				<div style={{textAlign:"center"}}>
				<div>
				<img src={noData} height={110} width={110}/>
				</div><div >
				<span style={{fontStyle:"italic"}}>No Bookings</span>
				</div></div>
			)}
		</Widget>
	);
};
export default TaskByStatusPartners;
