import React, { useState ,useEffect} from 'react';
import { Modal, Button, Row, Col, Alert } from 'antd';
import { Form, Input, Select } from 'antd';
import { useMutation } from '@apollo/client';
import {
	CREATE_SERVICE_ADDON,
	DELETE_SERVICE_ADDON,
	UPDATE_SERVICE_ADDON,
} from 'src/lib/gql/mutations/mutations';
import {
	ServiceUnitEnum,
	AddonTypeEnum,
} from 'src/lib/gql/generated/globalTypes';
import {
	CMSCreateServiceAddon,
	CMSCreateServiceAddonVariables,
} from 'src/lib/gql/generated/CMSCreateServiceAddon';
import {
	CMSUpdateServiceAddon,
	CMSUpdateServiceAddonVariables,
} from 'src/lib/gql/generated/CMSUpdateServiceAddon';
import {
	CMSDeleteServiceAddon,
	CMSDeleteServiceAddonVariables,
} from 'src/lib/gql/generated/CMSDeleteServiceAddon';
import { EditOutlined,DeleteOutlined } from '@ant-design/icons';

interface Props {
	serviceID: string;
	input?: any;
	onFinishAdd?: () => void;
}

const AddAdditionalInput = ({ serviceID, input, onFinishAdd }: Props) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [partnerPrice, setPartnerPrice] = useState<any>(0);
	const [partnerTax, setPartnerTax] = useState<any>(0);
	const [partnerTotal, setPartnerTotal] = useState<any>();
	const [commissionPrice, setCommissionPrice] = useState<any>();
	const [commissionTax, setCommissionTax] = useState<any>(18);
	const [commissionTotal, setCommissionTotal] = useState<any>();
	const [commissionAmount, setCommissionAmount] = useState<any>();
	const [grandTotal, setGrandTotal] = useState<any>(0);
	const [unitPrice, setUnitPrice] = useState<any>();
	const [updateAlert, setUpdateAlert] = useState(false)
	const [createAlert, setCreateAlert] = useState(false)

	useEffect(()=>{
		setPartnerPrice(input?.unitPrice?.partnerPrice)
		setPartnerTax(input?.unitPrice?.partnerTax)
		setCommissionPrice(input?.unitPrice?.commission)
		// setCommissionTax(input?.unitPrice?.commissionTax)
		setUnitPrice(input?.unitPrice?.unitPrice)
		
		setPartnerTotal(input?.unitPrice?.partnerTotal)
		setCommissionTotal(input?.unitPrice?.commissionTotal)
		setCommissionAmount(input?.unitPrice?.commissionAmount)
		setGrandTotal(input?.unitPrice?.grandTotal)

		// Update the form fields with the new data
		if(input){
		form.setFieldsValue({
			serviceId: serviceID,
			name: input?.name,
			description: input?.description,
			type: input?.type?.toUpperCase(),
			unit: input?.unit,
			unitPrice: +input?.unitPrice,
			minUnit: +input?.minUnit,
			maxUnit: +input?.maxUnit,
			partnerPrice: input?.unitPrice?.partnerPrice,
			commission: input?.unitPrice?.commission,
			// commissionTax: input.unitPrice.commissionTax,
			partnerTax: input?.unitPrice?.partnerTax,
			unitPrices:input?.unitPrice?.unitPrice,


			// name: newValue,
			// description: newValue,
			
		});
	}
	},[input])

	// useEffect(()=>{
	// 	if (partnerPrice&&partnerTax){
	// 		setPartnerTotal(Math.round(parseInt(partnerPrice)+(parseInt(partnerPrice)*parseInt(partnerTax))/100))
	// 	}	
	// 	setCommissionAmount((partnerPrice*commissionPrice)/100)
	// 	setCommissionTotal(Math.round(((parseFloat(commissionAmount)*parseFloat(commissionTax))/100) +commissionAmount))
	// 	if(partnerTotal&& !commissionTotal){
	// 		setGrandTotal(Math.round(parseFloat(partnerTotal)))
	// 	} else{
	// 		setGrandTotal(Math.round(parseFloat(partnerTotal)+parseFloat(commissionTotal)))
	// 	}
	// },[partnerPrice,partnerTax,commissionPrice,commissionTax,commissionAmount,partnerTotal,commissionTotal])

	useEffect(()=>{
		if (partnerPrice&&partnerTax){
			setPartnerTotal((parseInt(partnerPrice)+(parseInt(partnerPrice)*parseInt(partnerTax))/100))
		}	
		if (partnerPrice&&commissionPrice){
			const val=((partnerPrice*commissionPrice)/100).toFixed(2)
			setCommissionAmount(parseFloat(val))
		}	
	if (commissionAmount){
		const daVal=(parseFloat(commissionAmount) * parseFloat(commissionTax) / 100) + parseFloat(commissionAmount);
		setCommissionTotal(parseFloat(daVal.toFixed(2)));
		}	
		if(partnerTotal&& !commissionTotal){
			const val=Math.round(parseFloat(partnerTotal)).toFixed(2)
			setGrandTotal(parseFloat(val))
			// setGrandTotal(Math.round(parseFloat(partnerTotal)).toFixed(2))
		} else if(partnerTotal&& commissionTotal){
			const val=Math.round(parseFloat(partnerTotal)+parseFloat(commissionTotal)).toFixed(2)
			setGrandTotal(parseFloat(val))
			// setGrandTotal(Math.round(parseFloat(partnerTotal)+parseFloat(commissionTotal)).toFixed(2))
		}else{
			setGrandTotal(0)
		}
	},[partnerPrice,partnerTax,commissionPrice,commissionTax,commissionAmount,partnerTotal,commissionTotal])

	// Create
	const [
		createServiceInputOption,
		{ data: dataMt, loading: loadingMt, error: errorMt },
	] = useMutation(
		CREATE_SERVICE_ADDON,{
			onCompleted: () => {
				setIsModalVisible(false);
				onFinishAdd
			form.resetFields();
			if(partnerTotal){

			setPartnerTotal(null)
			}
			// setCommissionTotal(0)
			// setCommissionAmount(0)
			// setGrandTotal(0)
			},
		}
	);

	// Update
	const [
		updateServiceInputOption,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation(
		UPDATE_SERVICE_ADDON,{
			onCompleted: () => {
				setIsModalVisible(false);
				onFinishAdd
			},
		}
	);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
		form.resetFields()
		setIsModalVisible(false);
	};

	const [form] = Form.useForm();

	const onFinish = (values) => {

		if (!values?.unitPrices) {
			alert('Unit Price is required')
			return
		}
		
		if (!input) {
			createServiceInputOption({
				variables: {
					data: {
						serviceId: serviceID,
						name: values.name,
						description: values.description,
						// type: values?.type?.toUpperCase(),
						type:AddonTypeEnum.PARTNER,
						unit: values.unit,
						unitPrice: {
							partnerPrice: +values.partnerPrice,
							commission: +values.commission,
							commissionTax:commissionTax,
							partnerTax: +values.partnerTax,
							commissionAmount: commissionAmount,
							commissionTotal: commissionTotal,
							partnerTotal: partnerTotal,
							grandTotal: grandTotal,
							unitPrice: parseFloat(values?.unitPrices)
						},
						minUnit: +values.minUnit,
						maxUnit: +values.maxUnit,
						commissionPercentage: Math.round(commissionAmount),
					},
				},
			}).then(() => {
				form.resetFields();
				setCreateAlert(true)
				setTimeout(() => {
				setCreateAlert(false)
				}, 2000)
			setPartnerTotal(0)
			setCommissionTotal(0)
			setCommissionAmount(0)
			setGrandTotal(0)
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
					}
					setIsModalVisible(false);
				}, 1500);
			});
		} else {
			updateServiceInputOption({
				variables: {
					id: input.id,
					data: {
						serviceId: serviceID,
						name: values.name,
						description: values.description,
						type: AddonTypeEnum.PARTNER,
						unit: values.unit,
						unitPrice: {
							partnerPrice: +values.partnerPrice,
							commission: +values.commission,
							commissionTax: commissionTax,
							partnerTax: +values.partnerTax,
							commissionAmount: commissionAmount,
							commissionTotal: commissionTotal,
							partnerTotal: partnerTotal,
							grandTotal: grandTotal,
							unitPrice:parseFloat(values?.unitPrices)
						},
						minUnit: +values.minUnit,
						maxUnit: +values.maxUnit,
						commissionPercentage: Math.round(commissionAmount),
					},
				},
			}).then(() => {
				form.resetFields();
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
					}
					setIsModalVisible(false);
				}, 1500);
			});
			setUpdateAlert(true)
            setTimeout(() => {
            setUpdateAlert(false)
            }, 2000)
		}
	};

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	// Delete
	const [
		deleteServiceInputOption,
		{ data: dataMtD, loading: loadingMtD, error: errorMtD },
	] = useMutation<CMSDeleteServiceAddon, CMSDeleteServiceAddonVariables>(
		DELETE_SERVICE_ADDON,
	);
	const onDelete = () => {
		deleteServiceInputOption({
			variables: {
				id: input.id,
			},
		}).then(() => {
			setTimeout(() => {
				if (typeof onFinishAdd === 'function') {
					onFinishAdd();
				}
				setIsDeleteModalVisible(false);
			}, 1500);
		});
	};
	const validateReworkGracePeriod = (_, value) => {
		if (isNaN(value) || Number(value) < 0) {
		  return Promise.reject('Please enter a non-negative number.');
		}
		return Promise.resolve();
	  };

	return (
		<>
			{/* <Button type="primary" onClick={showModal}>
				{!input ? 'Add Service Add-ons' : <EditOutlined/>}
			</Button> */}
			<Button
				type="primary"
				onClick={showModal}
				style={
					input
						? {
								background: 'transparent',
								color: '#5a20ff',
								border: 'none',
								boxShadow: 'none',
						  }
						: {}
				}
			>{!input ? 'Add Service Add-ons' : <EditOutlined />}
			</Button>
			<Modal
				title={!input ? 'Add Service Add-ons' : 'Edit'}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
			>
				{/* Creating */}
				{createAlert && !loadingMt  && <Alert message="Created" type="success" showIcon />}
				{loadingMt && <Alert message="Submitting..." type="info" showIcon />}
				{errorMt && (
					<Alert
						message={`Submission error! ${errorMt.message}`}
						type="error"
						showIcon
					/>
				)}

				{/* Updating */}
				{updateAlert &&  !loadingMtU && <Alert message="Updated" type="success" showIcon />}
				{loadingMtU && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtU && (
					<Alert
						message={`Submission error! ${errorMtU.message}`}
						type="error"
						showIcon
					/>
				)}
				<Form
					form={form}
					name="addServiceInput"
					onFinish={onFinish}
					scrollToFirstError
					initialValues={
						!input
							? {}
							: {
									serviceId: serviceID,
									name: input.name,
									description: input.description,
									type: input.type.toUpperCase(),
									unit: input.unit,
									unitPrice: +input.unitPrice,
									minUnit: +input.minUnit,
									maxUnit: +input.maxUnit,
									partnerPrice: input.unitPrice.partnerPrice,
									commission: input.unitPrice.commission,
									// commissionTax: input.unitPrice.commissionTax,
									partnerTax: input.unitPrice.partnerTax,
									unitPrices:input?.unitPrice?.unitPrice,
							 
								}
					}
				>
					<Row>
						<Col span={24}>
							<label
								style={{
									fontSize: '14px',
									fontWeight: 'bold',
									display: 'block',
								}}
							>
								Basic Info
							</label>
							<hr/>
						</Col>
						<Col span={12}>
							<Form.Item
								name={`name`}
								label={<span>Name</span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
							<Col span={12}>
							<Form.Item label="Unit" name="unit">
								<Select getPopupContainer={(trigger) => trigger.parentElement}>
									<Select.Option value={ServiceUnitEnum.COUNT}>
										{ServiceUnitEnum.COUNT}
									</Select.Option>
									<Select.Option value={ServiceUnitEnum.HOUR}>
										{ServiceUnitEnum.HOUR}
									</Select.Option>
									<Select.Option value={ServiceUnitEnum.DAY}>
										{ServiceUnitEnum.DAY}
									</Select.Option>
									<Select.Option value={ServiceUnitEnum.WEEK}>
										{ServiceUnitEnum.WEEK}
									</Select.Option>
									<Select.Option value={ServiceUnitEnum.MONTH}>
										{ServiceUnitEnum.MONTH}
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>

						<Col span={24}>
							<Form.Item
								name={`description`}
								label={<span>Description</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input.TextArea />
							</Form.Item>
						</Col>

						<Col span={24}>
							<label
								style={{
									fontSize: '14px',
									fontWeight: 'bold',
									display: 'block',
								}}
							>
								Unit
							</label>
							<hr/>
						</Col>

						<Col span={12}>
							<Form.Item label="Type" name="type">
								<Select defaultValue={AddonTypeEnum.PARTNER} getPopupContainer={(trigger) => trigger.parentElement}>
									{/* <Select.Option value={AddonTypeEnum.ALL}>
										{AddonTypeEnum.ALL}
									</Select.Option>
									<Select.Option value={AddonTypeEnum.CUSTOMER}>
										{AddonTypeEnum.CUSTOMER}
									</Select.Option> */}
									<Select.Option value={AddonTypeEnum.PARTNER}>
										{AddonTypeEnum.PARTNER}
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}></Col>

						{/* <Col span={12}>
							<Form.Item
								name={`unitPrice`}
								label={<span>Unit Price</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col> */}

						<Col span={12}>
							<Form.Item
								name={`minUnit`}
								label={<span>Min unit</span>}
								rules={[
									{
										required: true,
										validator: validateReworkGracePeriod,
									}, 
								]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								name={`maxUnit`}
								label={<span>Max unit</span>}
								rules={[
									{
										required: true, 
										validator: validateReworkGracePeriod,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={24}>
							<label
								style={{
									fontSize: '14px',
									fontWeight: 'bold',
									display: 'block',
								}}
							>
								Price Info
							</label>
							<hr/>
						</Col>
						<Col span={8}>
							<Form.Item
								name={`partnerPrice`}
								label={<span>Partner Price</span>}
								rules={[
									{
										required: true,
										validator: validateReworkGracePeriod,
									},
								]}
							>
								<Input onChange={(e)=>setPartnerPrice(e.target.value)} value={partnerPrice}/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name={`partnerTax`}
								label={<span>Partner Tax(%)</span>}
								rules={[
									{
										required: true,
										validator: validateReworkGracePeriod,
									},
								]}
							>
								<Input onChange={(e)=>setPartnerTax(e.target.value)} value={partnerTax} />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								// name={`partnerTax`}
								label={<span>Partner Total</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input disabled value={partnerTotal? partnerTotal:0}/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name={`commission`}
								label={<span>Commission %</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input onChange={(e)=>setCommissionPrice(e.target.value)} value={commissionPrice}/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item
								// name={`commissionTax`}
								label={<span>Commission Tax(%)</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input value={18} disabled />
							</Form.Item>
							
						</Col>
						<Col span={8}>
							<Form.Item
								// name={`partnerTax`}
								label={<span>Commission Total</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input disabled  value={commissionTotal?commissionTotal:0}/>
								<hr/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								// name={`commissionPercentage`}
								label={<span>Commission Amount</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input disabled value={commissionAmount?commissionAmount:0} />
							</Form.Item>
						</Col>
						<Col span={8}>
						<Form.Item
								name={`unitPrices`}
								label={<span>Unit Price</span>}
								rules={[
									{
										validator: validateReworkGracePeriod,
										// required: true,
										// whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={8}>
							
							<Form.Item
								// name={`commissionPercentage`}
								label={<span>Grand Total</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input disabled value={grandTotal?grandTotal:0} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			{input && (
				<Button
					type="text"
					onClick={() => setIsDeleteModalVisible(true)}
					danger
				>
					<DeleteOutlined/>
				</Button>
			)}
			<Modal
				title={'Delete'}
				visible={isDeleteModalVisible}
				onOk={onDelete}
				onCancel={() => setIsDeleteModalVisible(false)}
				width={300}
			>
				Delete Addon?
				{/* Delete */}
				{dataMtD && <Alert message="Deleted" type="success" showIcon />}
				{loadingMtD && <Alert message="Deleting..." type="info" showIcon />}
				{errorMtD && (
					<Alert
						message={`Submission error! ${errorMtD.message}`}
						type="error"
						showIcon
					/>
				)}
			</Modal>
		</>
	);
};
export default AddAdditionalInput;
