import { DeleteOutlined, EditOutlined, LinkOutlined, SearchOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Switch, Table,Image, Tabs, Upload, Select, Alert,Pagination, Button, Card, Col, Modal, Row, Dropdown, message, Space, Tooltip } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { useEffect, useState,useRef } from 'react';
import {
    CMSGetCmsBanners,
    CMSGetCmsBannersVariables,
} from 'src/lib/gql/generated/CMSGetCmsBanners';
import { CMSQueryGetBanners } from 'src/lib/gql/queries/cms';
import { Input, RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { CMSGetServices,CMSGetServicesVariables } from 'src/lib/gql/generated/CMSGetServices';
import { CMSQueryGetServices } from 'src/lib/gql/queries/services';
import { CMSGetAllServiceCategories } from 'src/lib/gql/generated/CMSGetAllServiceCategories';
import { CMSQueryGetAllServiceCategories } from 'src/lib/gql/queries/services';
import { CMSCreateBanner,CMSCreateBannerVariables } from 'src/lib/gql/generated/CMSCreateBanner';
import { CREATE_BANNER } from 'src/lib/gql/mutations/mutations';
import { EnumBannerResourceType } from 'src/lib/gql/generated/globalTypes';
// import { UploadPublicImage, UploadPublicImage2 } from 'src/components/shared/UploadPublicImage';
import { CMSDeleteBanner,CMSDeleteBannerVariables } from 'src/lib/gql/generated/CMSDeleteBanner';
import { DELETE_BANNER ,UPDATE_BANNER} from 'src/lib/gql/mutations/mutations';
import { CMSUpdateBanner,CMSUpdateBannerVariables } from 'src/lib/gql/generated/CMSUpdateBanner';
import { useNavigate } from 'react-router-dom';
import {  CaretLeftOutlined } from '@ant-design/icons';
import { UploadPublicImageBanner,UploadPublicImageBanner2 } from './UploadPublicImagesBanner';

const DupehomeBanners: React.FC = () => {

	const { Option } = Select;
    const { Dragger } = Upload;
    const { TabPane } = Tabs;
    const [value, setValue] = useState<any>();
    const [link,setLink]=useState<any>()
    const [resourceData,setResourceData]=useState<any>()
	const [picId, setPicId] = useState<any>('');
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [tab,setTab] = useState<any>();
    const [deletData,setDeletData]= useState<any>();
    const [editInfo,setEditInfo]=useState<any>();
    const [title,setTitle]=useState<any>()
    const [statusCheck,setStatusCheck]=useState<any>()
    const [hideReset, setHideReset] = useState(false);
    const[resetImg,setResetImg] = useState(false);
	const [updateAlert, setUpdateAlert] = useState(false)
	const [createAlert, setCreateAlert] = useState(false)
	const [deleteAlert, setDeleteAlert] = useState(false)
	const navigate = useNavigate();
    const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
    const [resourceValue,setResourceValue]=useState<any>()
    useEffect(()=>{
        setTitle(editInfo?.title)
        setValue(editInfo?.resourceType)
        if(editInfo?.resourceType==="SERVICE_WISE"){
            const val=qryDataService?.getServices?.find(
                (x) => x.id === editInfo?.resourceData) 
                setResourceData(val?.name)
         console.log("SERVICE_WISE",val?.name);

        }
        if(editInfo?.resourceType==="CATEGORY_WISE"){
     const val=qryDataServiceCate?.getServiceCategories?.find(
        (x) => x.id === editInfo?.resourceData) 
         setResourceData(val?.name)
         console.log("cate",val?.name);
     }
         console.log("editInfo",editInfo);
         setPicId(editInfo?.media?.id)
         setLink(editInfo?.url)
    },[editInfo])
 
    useEffect(()=>{
        if (title){
            setHideReset(true)
        }
    },[title])

    const statusChange=(e)=>{
        // if(e.enabled){
            setStatusCheck(!e.enabled)
            updateDetails({
                variables: {
                    id:e.id,
                    data: {
                        mediaId: picId,
                        title:title ,
                        url: link,
                        description:"",
                        resourceType:value ? value : null,
                        resourceData:resourceData,
                        enabled: !e.enabled,
                    },}
            })
            .then((result) => {
                // Handle the result if needed
                message.success('Status Updated');
                dataClear()
                 // Display success message
              })
            // setUpdateAlert(true)
            // setTimeout(() => {
            // setUpdateAlert(false)
    }

    const dataClear =()=>{
        setTitle(""),
        setValue(""),
        setResourceData("")
        setPicId(null)
        setEditInfo(null)
        setResetImg(true)
    }

    const deleteConfirm =(record)=>{
        setOpenModalDelete(true) 
        setDeletData(record)
    }
    const textboxRef = useRef(null);

    const editdata =(record)=>{
    setEditInfo(record)
    const textbox = document.getElementById("textbox");
    textbox?.focus();
    }


    const { Search } = Input;
	const handleChanges = (value) => {
        // setResourceData(value)
        setResourceValue(value)
    }
    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
        setResourceData(null)
        
    };


    	// delete
	const [
		deleteBanner,
		{ data: dataMtD, loading: loadingMtD, error: errorMtD },
	] = useMutation<CMSDeleteBanner, CMSDeleteBannerVariables>(DELETE_BANNER,
        {
            onCompleted: () => {
                refetchQryData();
                setLink("")
                setTitle("")
                setResourceData(null)
                setPicId(null)                
                setEditInfo(null)
            },
         });
	

    // create
    const [
		createDetails,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation(CREATE_BANNER,
        {
            onCompleted: () => {
                refetchQryData();
                setLink("")
                setTitle("")
                setResourceData(null)
                setPicId(null)                
                setEditInfo(null)
                // setTimeout(() => {
                //     dataMtU(false)
                // }, 1500);
            },
         } );

         
    // Update
    const [
		updateDetails,
		{ data: dataMtUs, loading: loadingMtUs, error: errorMtUss },
	] = useMutation(UPDATE_BANNER,
        {
            onCompleted: () => {
                refetchQryData();
                // setTimeout(() => {
                //     dataMtUs(false)
                // }, 1500);
             
                setLink("")
                setTitle("")
                setResourceData(null)
                setPicId(null)                
                setEditInfo(null)
            },
         } );

    // Fetch queries
    const {
        loading,
        data: qryData,
        called,
        error,
        refetch: refetchQryData,
    } = useQuery(
        CMSQueryGetBanners,
        {
            variables: {
                getAll: true,
                resourceType:tab ,
                search: searchText,
                pagination: pagination,
            },
        },
    );
    // Get all services
	const {	
		data: qryDataService,
		refetch: refetchQryDataService,
	} = useQuery<CMSGetServices, CMSGetServicesVariables>(CMSQueryGetServices, {
		variables: {
			showAll: true,
			search: '',
		}, 
		errorPolicy: 'all',
	});

		// Get all services category
    const {
		data: qryDataServiceCate,
		refetch: refetchQryDataServiceCate,
	} = useQuery<CMSGetAllServiceCategories>(CMSQueryGetAllServiceCategories);


    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Type',
            dataIndex: 'resourceType',
            key: 'Type',
        },
        {
            title: 'Status',
            dataIndex: 'switch',
            key: 'switch',
            render: (_, record) => (
                <Switch  onChange={() => statusChange(record)} checked={record?.enabled}/>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'key',
            key: 'id',
            width: 'auto',
            render: (text, record) => (
                <div>
                    <Button style={{color:"#5143dc"}} type="link" onClick={()=>editdata(record)} icon={<EditOutlined  id="scrollButton"/>} />
                    <Button type="link" onClick={()=>deleteConfirm(record)} icon={<DeleteOutlined />} />
                </div>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            Title: 'Laptop Repair Service',
            Type: 'Category',
            // switch: true,
        },
        {
            key: '2',
            Title: 'House cleaning Service',
            Type: 'service',
            switch: false,
        },
        // Add more data rows as needed
    ];


    const props: UploadProps = {
        name: 'file',
        multiple: true,
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };


    // const filteredData = qryData?.getBanners.filter((item) =>
    //     item.Title.toLowerCase().includes(searchText.toLowerCase())
    // );
    // console.log("filteredData",filteredData);
    const [errorMsg,setErrorMsg]=useState(false)
    useEffect(()=>{
        setTimeout(() => {
            setErrorMsg(false)
            }, 3000)
    },[errorMsg])

    const bannerSave=()=>{
        console.log("resourceData",resourceData);
        
        let resourceDataVal
        if(value==="SERVICE_WISE"){
            console.log("1");
            
            const val=qryDataService?.getServices?.find(
                (x) => x.name === resourceData) 
                setResourceData(val?.id)
            console.log("val",val);

              resourceDataVal=val?.id
        }
        if(value==="CATEGORY_WISE"){
            console.log("2");

     const val=qryDataServiceCate?.getServiceCategories?.find(
        (x) => x.name === resourceData) 
         setResourceData(val?.id)
           resourceDataVal=val?.id
     }
     console.log("resourceDataVal",resourceDataVal);

     console.log("picId",picId);
     console.log("title",title);
     console.log("link",link);
        
        if(!editInfo){
            if (picId&&title&&link ||resourceDataVal ){
                setErrorMsg(false)
                createDetails({
                    variables: {
                        data: {
                            mediaId: picId,
                            title:title ,
                            url: link,
                            description:"",
                            resourceType:value,
                            resourceData:resourceData,
                        },
                    }
                })
               
                setCreateAlert(true)
                setTimeout(() => {
                setCreateAlert(false)
                }, 2000)
            }else{
                setErrorMsg(true)
            }
        }else{
            updateDetails({
                variables: {
                    id:editInfo?.id,
                    data: {
                        mediaId: picId,
                        title:title ,
                        url: link,
                        description:"",
                        resourceType:value,
                        resourceData:resourceValue ? resourceValue :resourceDataVal,
                        enabled:statusCheck
                    },
                }
            })
            .then((result) => {
                // Handle the result if needed
                message.success('Banner Updated');
                 // Display success message
              })
            setUpdateAlert(true)
            setTimeout(() => {
            setUpdateAlert(false)
            }, 2000)
        }
    }

    const handleOk = () => {
        deleteBanner({
			variables: {
				id: deletData?.id,
			},
		}).then(() => {
			// refetchQryData();
            setDeleteAlert(true)
			setTimeout(() => {
				setOpenModalDelete(false);
            setDeleteAlert(false)
			}, 1500);
		});
	};

	const handleCancel = () => {
        setOpenModalDelete(false)
	};

    const handleTypeTabChange = (e) => {
        setTab(e == 'null' ? null : e)
        // refetchQryData()
    }

    useEffect(() => {
        refetchQryData()
    },[tab])

    return (
        <>
            <Card>
            <Row>
               <Col span={20}>
               <h1><Button className="gx-mr-4" onClick={() => navigate(-1)} style={{margin:"0"}}>
				<CaretLeftOutlined />
				</Button>Promotional Banners</h1>
               </Col>
                        <Col span={4} style={{display:"flex"}} >
                        {/* <Space wrap> */}
                        { hideReset ?(
                            <Button type="primary" onClick={dataClear} >
                                Reset
                            </Button>
                        ):(
                            <Button type="primary" disabled >
                                Reset
                            </Button>
                        )} 
                            <Button type="primary" onClick={bannerSave}>{editInfo ? "Save" :"Create"}</Button>
                        {/* </Space> */}
                    </Col>
                </Row>
                <hr/>
                <Row>
                <Col span={20} >
                {createAlert && !loadingMtU && <Alert message="Created" type="success" showIcon />}
			    {loadingMtU && <Alert message="Submiting..." type="info" showIcon />}
			    {errorMtU && (
			    <Alert
			    message={`Submission error! ${errorMtU.message}`}
			    type="error"
			    showIcon
			    />)}
                {/* Update */}
                {/* {updateAlert &&  !loadingMtUs && <Alert message="Saved" type="success" showIcon />} */}
			    {loadingMtUs && <Alert message="Submiting..." type="info" showIcon />}
			    {errorMtUss && (
			    <Alert
			    message={`Submission error! ${errorMtUss.message}`}
			    type="error"
			    showIcon
			    />)}
                 {errorMsg && <Alert message="Please fill the details" type="error" showIcon />}
                </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={14}>
                        <div style={{ width: 600, marginBottom: '20px' }}>
                            <h5>Banner Title</h5>
                            <Input placeholder="Title*"  id="textbox" 
							onChange={(e) => setTitle(e.target.value)}
                            value={title?title:""}
                            />
                        </div>
                        <h5>Resource Type</h5>
                        <div>
                            <Radio.Group onChange={onChange} value={value} >
                                <Radio value={EnumBannerResourceType.CATEGORY_WISE}>Category</Radio>
                                <Radio value={EnumBannerResourceType.SERVICE_WISE}>Service</Radio>
                                <Radio value={EnumBannerResourceType.REDIRECT_LINK}>Redirect Link</Radio>
                            </Radio.Group>
                        </div>
                        <br></br>

                        {value === "CATEGORY_WISE" && (
                        <Select getPopupContainer={(trigger) => trigger.parentElement}
						style={{
						width: '100%',
						}}
						placeholder="Select Service Category"
						defaultValue={resourceData?resourceData:"Select Service Category"}
						onChange={handleChanges}
						optionLabelProp="label"
					>
					{qryDataServiceCate?.getServiceCategories.map((entry, index) => (						
							<Option value={entry?.id} label={entry?.name} key={index}>
							<Space>
								<span role="img" aria-label={entry?.name}>
								{entry?.name}
								</span>								
							</Space>
							</Option>		
							))}
					</Select>  
                     )}
                        {value === "SERVICE_WISE" && (
                        <Select getPopupContainer={(trigger) => trigger.parentElement}
						style={{
						width: '100%',
						}}
						placeholder="Select Service"
						defaultValue={resourceData? resourceData:"Select Service"}
						onChange={handleChanges}
						optionLabelProp="label"
					>
					{qryDataService?.getServices.map((entry, index) => (						
							<Option value={entry?.id} label={entry?.name} key={index}>
							<Space>
								<span role="img" aria-label={entry?.name}>
								{entry?.name}
								</span>								
							</Space>
							</Option>		
							))}
					</Select>  
                     )}
                        { value ==="REDIRECT_LINK" && (
                            <Input placeholder="Link*" 
							onChange={(e) => setLink(e.target.value)} value={link?link:""} />
                        )}
                    </Col>
                    <Col span={8} style={{display:"flex"}}>
                        {/* <Row> */}
                <Col span={12}>
                {/* {editInfo &&(
                <Image
                width={150}
                height={100}
                src={`https://staging.api.zimkey.in/media-upload/${editInfo?.media?.url}`}
                />
                )} */}
                </Col>

            <Col span={12}>
                   <div>
                    
                    {/* && picId !== null  */}
                    {editInfo &&editInfo!==null ?(
                            <UploadPublicImageBanner2
										onFinishAdd={(info) =>
											setPicId(info?.file.response.data.id)
										}
                                // serviceIconUrl={editInfo?.media?.url}
                                serviceIconUrl={
                                    `https://staging.api.zimkey.in/media-upload/${editInfo?.media?.url}`
                                }						
							/>
                    ) :editInfo===null ?(
                        <UploadPublicImageBanner2
                        onFinishAdd={(info) =>
                            setPicId(info?.file.response.data.id)
                        }
                // serviceIconUrl={editInfo?.media?.url}
                serviceIconUrl={
                    `https://staging.api.zimkey.in/media-upload/${editInfo?.media?.url}`
                }						
            />
                        
                    )  :(
                        <UploadPublicImageBanner
						onFinishAdd={(info) =>
                            setPicId(info?.file.response.data.id)
						}
						/>
                    )}
                    </div>
                   </Col>
                        {/* </Row> */}
                    </Col>
                    {/* <Col span={1} >
                        <Space wrap>
                            <Button type="primary" disabled>
                                Reset
                            </Button>
                            <Button type="primary">Submit</Button>
                        </Space>
                    </Col> */}
                </Row>
                <br/>
            </Card>
          

            <Tabs defaultActiveKey="1" onChange={handleTypeTabChange}>
                <TabPane tab="All" key={null}>
                    <Card>
                        <Space style={{ marginBottom: 16 }}>
                            <Search
                                placeholder="Search name"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                allowClear
                            />
                        </Space>
                        <Table columns={columns}pagination={false} dataSource={qryData?.getBanners?.data} />
                        {/* <div style={{ textAlign: 'right', marginTop: 16 }}>
                            Total Names: {filteredData.length}
                        </div> */}
                        <div style={{textAlign:"end",paddingTop:"1rem"}}>
                        		<Pagination
								key="pagination"
								defaultCurrent={qryData?.getBanners?.pageInfo.currentPage}
								current={qryData?.getBanners?.pageInfo.currentPage}
								pageSize={pagination.pageSize}
								total={qryData?.getBanners?.pageInfo.totalCount}
								showTotal={(total) => `Total ${total} items`}
								onChange={(page, pageSize) => {
									console.log('onChange');
									setPagination({
										pageNumber: page,
										pageSize: pageSize ? +pageSize : pagination.pageSize,
									});
								}}
								showSizeChanger
							/></div>
                    </Card>
                </TabPane>
                <TabPane tab="Category Wise" key={EnumBannerResourceType.CATEGORY_WISE}>
                    <Card>
                        <Space style={{ marginBottom: 16 }}>
                            <Search
                                placeholder="Search name"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                allowClear
                                // enterButton={<Button type="primary" icon={<SearchOutlined />} />}
                            />
                            {/* <Button type="primary">Add</Button> */}
                        </Space>
                        <Table columns={columns} pagination={false} dataSource={qryData?.getBanners?.data} />
                        {/* <div style={{ textAlign: 'right', marginTop: 16 }}>
                            Total Names: {filteredData.length}
                        </div> */}
                                <div style={{textAlign:"end",paddingTop:"1rem"}}>
                        		<Pagination
								key="pagination"
								defaultCurrent={qryData?.getBanners?.pageInfo.currentPage}
								current={qryData?.getBanners?.pageInfo.currentPage}
								pageSize={pagination.pageSize}
								total={qryData?.getBanners?.pageInfo.totalCount}
								showTotal={(total) => `Total ${total} items`}
								onChange={(page, pageSize) => {
									console.log('onChange');
									setPagination({
										pageNumber: page,
										pageSize: pageSize ? +pageSize : pagination.pageSize,
									});
								}}
								showSizeChanger
							/></div>
                    </Card>
                </TabPane>
                <TabPane tab="Service Wise" key={EnumBannerResourceType.SERVICE_WISE}>
                    <Card>

                        <Space style={{ marginBottom: 16 }}>
                            <Search
                                placeholder="Search name"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                allowClear
                                // enterButton={<Button type="primary" icon={<SearchOutlined />} />}
                            />
                            {/* <Button type="primary">Add</Button> */}
                        </Space>
                         <Table columns={columns} pagination={false} dataSource={qryData?.getBanners?.data} />
                        {/* <div style={{ textAlign: 'right', marginTop: 16 }}>
                            Total Names: {filteredData.length}
                        </div> */} 
                                <div style={{textAlign:"end",paddingTop:"1rem"}}>
                        		<Pagination
								key="pagination"
								defaultCurrent={qryData?.getBanners?.pageInfo.currentPage}
								current={qryData?.getBanners?.pageInfo.currentPage}
								pageSize={pagination.pageSize}
								total={qryData?.getBanners?.pageInfo.totalCount}
								showTotal={(total) => `Total ${total} items`}
								onChange={(page, pageSize) => {
									console.log('onChange');
									setPagination({
										pageNumber: page,
										pageSize: pageSize ? +pageSize : pagination.pageSize,
									});
								}}
								showSizeChanger
							/></div>
                    </Card>
                </TabPane>
                <TabPane tab="Redirect Link" key={EnumBannerResourceType.REDIRECT_LINK}>
                    <Card>
                        <Space style={{ marginBottom: 16 }}>
                            <Search
                                placeholder="Search name"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                allowClear
                                // enterButton={<Button type="primary" icon={<SearchOutlined />} />}
                            />
                            {/* <Button type="primary">Add</Button> */}
                        </Space>
                         <Table columns={columns} dataSource={qryData?.getBanners?.data} pagination={false}/>
                        {/* <div style={{ textAlign: 'right', marginTop: 16 }}>
                            Total Names: {filteredData.length}
                        </div> */} 
                                <div style={{textAlign:"end",paddingTop:"1rem"}}>
                        		<Pagination
								key="pagination"
								defaultCurrent={qryData?.getBanners?.pageInfo.currentPage}
								current={qryData?.getBanners?.pageInfo.currentPage}
								pageSize={pagination.pageSize}
								total={qryData?.getBanners?.pageInfo.totalCount}
								showTotal={(total) => `Total ${total} items`}
								onChange={(page, pageSize) => {
									console.log('onChange');
									setPagination({
										pageNumber: page,
										pageSize: pageSize ? +pageSize : pagination.pageSize,
									});
								}}
								showSizeChanger
							/></div>
                    </Card>
                </TabPane>
            </Tabs>


            { openModalDelete && (
                		<Modal
                        title={'Delete'}
                        visible={openModalDelete}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        width={500}
                        >
                            Delete Banner?
                        {dataMtD && deleteAlert&&<Alert message="Deleted" type="success" showIcon />}
					    {loadingMtD && <Alert message="Deleting..." type="info" showIcon />}
				        {errorMtD  && (
							<Alert
								message={`Submission error! ${errorMtD.message}`}
								type="error"
								showIcon
							/>
						)}
                         {/* {proActivate && <Alert message="Profile updated successfully" type="success" showIcon />} */}
                  
                        </Modal>
            )}

        </>
    );
};
export default DupehomeBanners;



