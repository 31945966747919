import React, { useState, useEffect } from 'react';
import {
	Card,
	Row,
	Col,
	Button,
	Input,
	Table,
	Pagination,
	Space,
	Select,
	DatePicker,Modal,Rate
} from 'antd';
import { RouteList } from 'src/config/constants/routes';
import { Link } from 'react-router-dom';
import { DatePeriodEnum2,ReviewTypeEnum } from 'src/lib/gql/generated/globalTypes';
import moment from 'moment';
import { CMSQueryReviews } from 'src/lib/gql/queries';
import { useQuery } from '@apollo/client';

const FirstBookingExp: React.FC = () => {
   const [pagination, setPagination] = useState({
        pageNumber: 1,
        pageSize: 10,
    });
    const { Option } = Select;
	const [rating, setRating] = useState<any>();
	const [review, setReview] = useState<any>();
	const [reviewType, setReviewType] = useState<any>();

	const [name, setName] = useState<any>();
	const [phone, setPhone] = useState<any>();
    const[startDate,setStartDate]=useState<any>()
    const[endDate,setEndDate]=useState<any>()
    const [isOpenDate,SetIsOpenDate] =useState(false)
    const {
        loading,
        data: qryData,
        refetch: refetchQryData,
    } = useQuery(CMSQueryReviews, {
        variables: {
            showAll: true,
            pagination: pagination,
            filters: {
                name: name,
                phone:phone,
                datePeriod:review,
                rating: rating,
                type: reviewType,
                fromDate: startDate ? startDate : null,
                toDate: endDate ? endDate : null,
            },
        },
    });
    const { RangePicker } = DatePicker;
    const handleRating=(value)=>{
        setRating(value)
	}
    const handleType=(value)=>{
        setReviewType(value)
	}
    const handleReview=(value)=>{
        setStartDate(null)
        setEndDate(null)
        setReview(value)
       
        if(value==="CUSTOM"){ 
            SetIsOpenDate(true)
        }
	}
    const handleOk = () => {
        SetIsOpenDate(false)
    }	
    const handleCancel = () => {
        SetIsOpenDate(false)  
    }
	const handleDate = (dates) => {
		if (dates && dates?.length === 2) {
			const startDates = moment(dates[0]?._d).toISOString();		
			const endDates = moment(dates[1]?._d).toISOString();	
			// setStartDate(moment(startDates).format('MMMM Do YYYY'));
			// setEndDate(moment(endDates).format('MMMM Do YYYY'));
			setStartDate(startDates)
			setEndDate(endDates)
		  }   
    }
	const pageSizeOptions = ['10', '20', '50'];

    const ClearAll=()=>{
        setName("")
        setPhone("")
        setRating(null)
        setReview(null)
        setReviewType(null)
        setStartDate(null)
		setEndDate(null)
    }

    const getRowClassName = record => {
        if (record.type == "BOOKING") {
          return 'highlight-booking'; 
        } else if (record.type == "FIRST_BOOKING") {
          return 'highlight-firstBooking'; 
        }
        return ''; 
      };

    const columns = [
        {
			title: 'Customer',
			dataIndex: '',
			key: 'email',
            width: 150,
            render: (
				text,
				record,  
			) => 
            {
                const formatPhoneNumber = (phoneNumber) => {
                    phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
                    if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
                      return `+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`;
                    } else {
                      return phoneNumber;
                    }
                  };
                const inputPhoneNumber = record?.user?.phone
                const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);
                return(
				<>
					<Link 
					// to={RouteList.bookings.path + '/' + record?.id} 
				to={RouteList.users.path + '/' + record.user?.id}
					>
					<span className="gx-link">
						{record?.user?.name} <br/>
					</span>
					</Link>
                        {formattedPhoneNumber}
				</>
			);
            },
		},
		
		{
			title: 'Booking ID',
			dataIndex: 'userBookingNumber',
			key: 'userBookingNumber',
			width: 120,
			align: 'center' as const,
			render: (
				text,
				record,  
			) => (
				<>
					<Link 
				    to={RouteList.bookings.path + '/' + record?.bookingServiceItem?.id}
					>
					<span className="gx-link">
						{record?.bookingServiceItem?.bookingService?.booking?.userBookingNumber}
					</span>
					</Link>
				</>
			),
		},
  
        {
			title: 'Reviewed On',
			dataIndex: '',
			key: 'createDateTime',
            width: 180,
            render: (text: string, record: any) => {
				return (
				<span>
             {moment(record?.createDateTime).fromNow()}
			</span>
				);
			},
		},
        {
			title: 'Rating',
			dataIndex: 'rating',
			key: 'rating',
            width: 200,
            render: (text: string, record: any) => {
            return (
            <span>
		    <Rate disabled defaultValue={record?.rating } style={{color:"#f96d38"}}/>
            </span>
            );
        },
		},
        {
			title: 'Review',
			dataIndex: 'review',
			key: 'review',
		},
	];
    const handleInputChangeName = (e) => {
		let value = e.target.value;
		// Allow only strings (no numbers)
		value = value.replace(/[0-9]/g, '');
		setName(value);
	  };
      const handleInputChange = (e) => {
		let value = e.target.value;
		// Allow only numbers
		value = value.replace(/\D/g, '');
		setPhone(value); 
	  };

	return (
        <Card
        title={"Reviews"}
        extra={[
            <div key={null}>
            <Button style={{borderRadius:"0.5rem"}} onClick={ClearAll}>Clear All</Button>
            </div>
		]}
        >
                  <Row
                    style={{
                        marginBottom: "16px",
                        paddingTop: "0.2rem",
                        paddingBottom: "5px",
                    }}
                >
                    <Col span={6} style={{paddingTop:"1.5rem"}}>
                    {/* <div> */}
                     <Input
                         placeholder="Search by Name"
                        value={name}
                         allowClear
					onChange={(e) => handleInputChangeName(e)}
                      />
                     {/* </div> */}
                    </Col>
                    <Col span={6} style={{paddingTop:"1.5rem"}}>
                    {/* <div style={{paddingLeft:"1rem"}}> */}
                      <Input
                         placeholder="Search by Phone"
                          value={phone}
                          allowClear
                          onChange={(e) => handleInputChange(e)}
                          maxLength={10}
                      />
                 {/* </div> */}
                    </Col>
                    <Col span={4}>
                    {/* <div style={{ paddingLeft: "0.5rem" }}> */}
                        <span style={{textAlign:"center",paddingLeft:"26px"}}>Rating{" "}{" "}{" "}</span>
					<Select getPopupContainer={(trigger) => trigger.parentElement}
						defaultValue={"All"}
						style={{ width: 120 }}
						onChange={handleRating}
                         value={rating}
					>
						<Option value={null}>All</Option>
						<Option value={5}>5 Star</Option>
                         <Option value={4}>4 Star</Option>
						<Option value={3}>3 Star</Option>
                          <Option value={2}>2 Star</Option>
						<Option value={1}>1 Star</Option>
					</Select>
				{/* </div> */}
                    </Col>
                    <Col span={4}>
                    {/* <div style={{ paddingLeft: "0.5rem" }}> */}
                            <span style={{textAlign:"center",paddingLeft:"26px"}}>Reviewd On</span>
							<Select getPopupContainer={(trigger) => trigger.parentElement}
								defaultValue={"All"}
								style={{ width: 120 }}
								onChange={handleReview}
                                value={review}
							>
							<Option value={null} label={"All"} >
							<Space>
								<span role="img" aria-label={"All"}>
								All
								</span>								
							</Space>
							</Option>
                        <Option value="TODAY" label="TODAY">
						<Space>
						{DatePeriodEnum2.TODAY}
						</Space>
						</Option>
						<Option value="YESTERDAY" label="YESTERDAY">
						<Space>
						{DatePeriodEnum2.YESTERDAY}
						</Space>
						</Option>
						<Option value="THIS_WEEK" label="THIS_WEEK">
						<Space>
							{DatePeriodEnum2.THIS_WEEK}
						</Space>
						</Option>
                        <Option value="THIS_MONTH" label="THIS_MONTH">
						<Space>
							{DatePeriodEnum2.THIS_MONTH}
						</Space>
						</Option>
                        <Option value="CUSTOM" label="CUSTOM">
						<Space>
						{DatePeriodEnum2.CUSTOM}
						</Space>
						</Option>
							</Select>

						{/* </div> */}
        
                    </Col>
                    <Col span={4}>
                    <span style={{textAlign:"center",paddingLeft:"26px"}}>Type</span>
                    <Select getPopupContainer={(trigger) => trigger.parentElement}
						defaultValue={"All"}
						style={{ width: 140 }}
						onChange={(e) => handleType(e)}
                        value={reviewType}
					>
						<Option value={null}>All</Option>
						  <Option value="FIRST_BOOKING" label="FIRST_BOOKING">
						<Space>
							{ReviewTypeEnum.FIRST_BOOKING}
						</Space>
						</Option>
                        <Option value="BOOKING" label="BOOKING">
						<Space>
						{ReviewTypeEnum.BOOKING}
						</Space></Option>
					</Select>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                    <div style={{float:"right"}}>
        { startDate ? moment(startDate).format("lll") :""} 
                        
		{ startDate ? "-" :""} { endDate ?  moment(endDate).format("lll") :""}
                    </div>
                    </Col>
                </Row>
                <Row
                    style={{
                        marginBottom: 16,
                        paddingTop: "1rem",
                        backgroundColor: "#f6f6f6",
                    }}
                >
                    <Col span={24} style={{ textAlign: "center" }}>
                        <Pagination
                            key="pagination"
                            defaultCurrent={qryData?.getReviews?.pageInfo.currentPage}
							current={qryData?.getReviews?.pageInfo.currentPage}
							pageSize={pagination.pageSize}
                            total={qryData?.getReviews?.pageInfo?.totalCount}
                            showTotal={(total) => `Total ${total} items`}
                            onChange={(page, pageSize) => {
                                setPagination((prevState) => ({
                                    ...prevState,
                                    pageNumber: page,
                                    pageSize: pageSize || prevState.pageSize,
                                }));
                            }}
                            pageSizeOptions={pageSizeOptions}
                            showSizeChanger
                        />
                    </Col>
                </Row>

                {loading ? (
                    <div>Loading...</div>
            // ) : qryData?.getReviews?.data?.length === 0 ? ( 
            //         <div>No data in applied filter.</div>
               ) : ( 
                <div className="custom-table">
                    <Table
                        className="gx-table-responsive"
                        dataSource={qryData?.getReviews?.data}
                        columns={columns}
                        pagination={false}
                        rowClassName={getRowClassName}
                    /></div>
                 )} 
                
			{ isOpenDate && 
            <Modal
                title='Date'
                   visible={isOpenDate}
                  onOk={handleOk}
                  onCancel={handleCancel}
                 // width={800}
              >
			<div style={{textAlign:"center"}}> 
				<Space direction="vertical" size={6}>
				<RangePicker 									
				onChange={handleDate}							
				/>
				</Space> 
				</div>

            </Modal>
            }
        </Card>
	);
};
export default FirstBookingExp;
