interface Route {
	name: string;
	path: string;
}

interface RouteList {
	[key: string]: Route;
}

export const RouteList: RouteList = {
	login: { name: 'Login', path: '/login' },
	dashboard: { name: 'Dashboard', path: '/' },
	users: { name: 'Users', path: '/users' },
	userDetail: { name: 'User Detail', path: '/users/:id' },
	partners: { name: 'Partners', path: '/partners' },
	partnersPending: { name: 'Partners Pending', path: '/partners-pending' },
	partnerDetail: { name: 'User Detail', path: '/partners/:id' },
	addPartner: { name: 'Add Partner', path: '/partner-new' },
	jobBoard: { name: 'Job Board', path: '/job-board' },
	bookings: { name: 'Bookings', path: '/bookings' },
	rework: { name: 'Rework', path: '/rework' },
	subscriptions: { name: 'Subscriptions', path: '/subscriptions' },
	bookingDetail: { name: 'Bookings', path: '/bookings/:id' },
	services: { name: 'Services', path: '/services' },
	addService: { name: 'Add Service', path: '/services/add' },
	editService: { name: 'Edit Service', path: '/services/add/:id' },
	changeOrder: { name: 'Change Order', path: '/services/changeOrder' },
	addServiceCategory: { name: 'Add Category', path: '/services/category/add' },
	editServiceCategory: {
		name: 'Add Category',
		path: '/services/category/add/:id',
	},
	addServiceTimeSlots: {
		name: 'Manage Timeslots',
		path: '/services/time-slot',
	},
	manageApp: { name: 'Manage App', path: '/manage-app' },
	onboardingScreens: {
		name: 'Onboarding Screens',
		path: '/manage-app/onboarding',
	},
	appContent: {
		name: 'App Content',
		path: '/manage-app/app-content',
	},
	homepageBanners: {
		name: 'Homepage Banners',
		path: '/manage-app/homepage-banners',
	},
	DupehomeBanners: {
		name: 'DupehomeBanners',
		path: '/manage-app/DupehomeBanners',
	},
	homepageServices: {
		name: 'Homepage Services',
		path: '/manage-app/homepage-services',
	},
	popularServices: {
		name: 'Popular Services',
		path: '/manage-app/popular-services',
	},
	manageCities: {
		name: 'Manage Cities',
		path: '/manage-app/manage-cities',
	},
	manageCoupon: {
		name: 'Coupon',
		path: '/manage-app/coupon',
	},
	dupmanageCoupon: {
		name: 'dupCoupon',
		path: '/manage-app/dupcoupon',
	},
	managePartnerCompanies: {
		name: 'Coupon',
		path: '/manage-app/partner-companies',
	},
	manageFaqs: {
		name: 'Manage sFAQs',
		path: '/manage-app/faqs',
	},
	customerSupport: {
		name: 'Customer Support',
		path: '/customer-support',
	},
	loyalty: {
		name: 'Loyalty',
		path: '/loyalty',
	},
	zpoints: {
		name: 'ZPoints',
		path: '/promotions/zpoints',
	},
	payouts: {
		name: 'Payouts',
		path: '/payouts/pending-payouts',
	},
	admins: {
		name: 'Admins',
		path: '/users/admins',
	},
	manageAppConfig: {
		name: 'Manage App Config',
		path: '/manage-app-config',
	},
	reports: { name: 'Reports', path: '/reports' },
	servicePartners: { name: 'Service Partners', path: '/reports/servicePartner' },
	jobs: { name: 'Jobs', path: '/reports/jobs' },
	addAdmin: {
		name: 'Add Admin',
		path: '/admin-new'
	},
	editAdmin: {
		name: 'Edit Admin',
		path: '/users/admins/:id'
	},
	adminRoles: {
		name: 'Roles',
		path: '/users/admins/roles',
	},
	firstBookingExp: {
		name: 'FirstBookingExp',
		path: '/firstBookingExp',
	},
	homeContent: {
		name: 'HomeContent',
		path: '/homeContent',
	},
	support: {
		name: 'Support',
		path: '/support',
	},
	personnel: {
		name: 'Personnel',
		path: '/teamManagement/personnel',
	},
	teams: {
		name: 'Teams',
		path: '/teamManagement/teams',
	},
	serviceSettings: {
		name: 'ServiceSettings',
		path: '/teamManagement/serviceSettings',
	},
	payoutsHistory: {
		name: 'Payouts',
		path: '/payouts/history',
	},
	payoutSettings: {
		name: 'PayoutSettings',
		path: '/payouts/settings',
	},
	notifications: {
		name: 'Notifications',
		path: '/notifications',
	}
};
