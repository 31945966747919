import React ,{useEffect,useState}from 'react';
import { Col, Row, Tabs, Card ,Button} from 'antd';
import TaskByStatusPartners from 'src/components/dashboard/TaskByStatusPartners';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { CMSGetUsers,CMSGetUsersVariables } from 'src/lib/gql/generated/CMSGetUsers';
import { CMSQueryGetPartners, CMSQueryGetUser } from 'src/lib/gql/queries/users';
import { exitCode } from 'process';

const TabPane = Tabs.TabPane;

const serviceCounts = ({data}) => {

	const {
		data: qryData,
		loading,
		called,
		error,
		refetch: refetchQryData,
	} = useQuery<CMSGetUsers, CMSGetUsersVariables>(CMSQueryGetUser, {
	
	});

	// console.log("helo",qryData);
	// const open =data?.bookings?.filter(value => value.bookingStatus ==="OPEN");

	// console.log("open",open.length);
	

	return (
		<Card
			
			className={`gx-card-widget gx-card-profile`} 
		>
			<Tabs className="gx-tabs-right" defaultActiveKey="1">
				<TabPane key="1">
					<div className="gx-mb-2">
						<Row>
                        {/* <Col xl={6} lg={12} md={8} sm={12} xs={24} > */}
						{/* <Card style={{
							height:" 100%",
							display: "grid",
							alignItems: "center",borderRadius:"10px"
							
						}}>
							<div className="gx-media-body" style={{textAlign:"center",paddingTop:"1rem"}}>
					<h3>Collect Cash From provider</h3>
                    <h5>0.002</h5>
                    <div className="ant-card-head" >
						<Link to={""} >
							<Button className="gx-mb-0 gx-mr-4" type="primary" style={{borderRadius: "8px"}}>
								Collect Cash
							</Button>
						</Link>
					</div>
						</div>
						</Card> */}
						
						{/* </Col> */}
						<Col xl={7} lg={12} md={12} sm={12} xs={24}>
						<Card style={{backgroundColor:"#d4d4ff",borderRadius:"10px",marginBottom:"1rem",height:"150px"}}>
						<Row>
						
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"3rem"}}>
						<h5>Total Bookings</h5>
						<h1 className="gx-mb-1 gx-text-grey">{data?.partnerAnalytics?.totalBookings}</h1>
						</div>
					
						</Row>	</Card>
						<Card style={{backgroundColor:"#d5dfd5",borderRadius:"10px",height:"150px"}}>
						<Row>
					
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"3rem"}}>
						<h5>Open Bookings</h5>
						<h1 className="gx-mb-1 gx-text-grey">{data?.partnerAnalytics?.openBookings}</h1>
						</div>	
				
						</Row>		</Card>
						</Col>
						<Col xl={7} lg={12} md={12} sm={12} xs={24} >
						<Card style={{backgroundColor:"#ffb882",borderRadius:"10px",marginBottom:"1rem",height:"150px"}}>
						<Row>
						
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"3rem"}}>
						<h5>Cash On Hand</h5>
						<h1 className="gx-mb-1 gx-text-grey">{data?.partnerAnalytics?.withdrawableAmount}</h1>
						</div>
				
						</Row>		</Card>
						<Card style={{backgroundColor:"#faf5b8",borderRadius:"10px",height:"150px"}}>
						<Row >
					
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"3rem"}}>
						<h5>Total Earning</h5>
						<h1 className="gx-mb-1 gx-text-grey">{data?.partnerAnalytics?.totalEarnings}</h1>
						</div>
					
						</Row>	</Card>
						
						</Col>
						
						<Col xl={10} lg={12} md={8} sm={12} xs={24} >
						{/* <Card style={{
							height:" 100%",
							display: "grid",
							alignItems: "center",borderRadius:"10px"
						}}> */}
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"1rem"}}>
					
						<TaskByStatusPartners TotalBooking={data?.partnerAnalytics} Booking={data?.bookings}/>
						</div>
						{/* </Card> */}
						
						</Col>
						</Row>
						
						
                        {/* <Row style={{marginTop:"1rem"}}>
						<Col xl={6} lg={12} md={12} sm={12} xs={24}>
						<Card style={{
							height:" 100%",
							display: "grid",
							alignItems: "center",borderRadius:"10px",backgroundColor:"#ffd4e5"
						}}>
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"1rem"}}>
						<h5>Open Orders</h5>
						<h1 className="gx-mb-1 gx-text-grey">15</h1>
						</div>	</Card>
						</Col>
						<Col xl={6} lg={12} md={12} sm={12} xs={24}>
						<Card style={{
							height:" 100%",
							display: "grid",
							alignItems: "center",borderRadius:"10px",backgroundColor:"#c4fcee"
						}}>
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"1rem"}}>
						<h5>Total Orders</h5>
						<h1 className="gx-mb-1 gx-text-grey">15</h1>
						</div></Card>
						</Col>
						<Col xl={6} lg={12} md={12} sm={12} xs={24}>
						<Card style={{
							height:" 100%",
							display: "grid",
							alignItems: "center",borderRadius:"10px",backgroundColor:"#e7bcb5"
						}}>
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"1rem"}}>
						<h5>Pending Payments Counts</h5>
						<h1 className="gx-mb-1 gx-text-grey">15</h1>
						</div></Card>
						</Col>
						</Row> */}
					</div>
				</TabPane>
				{/* <TabPane tab="Work" key="2">
            <div className="gx-mb-2">
              <Row>{aboutList.map((about, index) =>
                <Col key={index} xl={8} lg={12} md={12} sm={12} xs={24}>
                  <AboutItem data={about}/>
                </Col>
              )}
              </Row>
            </div>
          </TabPane>
          <TabPane tab="Education" key="3">
            <div className="gx-mb-2">
              <Row>
                {aboutList.map((about, index) =>

                  <Col key={index} xl={8} lg={12} md={12} sm={12} xs={24}>
                    <AboutItem data={about}/>
                  </Col>
                )}
              </Row>
            </div>
          </TabPane> */}
			</Tabs>
		</Card>
	);
};

export default serviceCounts;
