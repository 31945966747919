import { gql } from '@apollo/client';

export const CMSQueryRevenueGeneration = gql`
	query CMSGetRevenueGeneration(
		$pagination: PaginationGqlInput!
		$filter: GetFinanceReportFilterGqlInput!
	) {
		getRevenueGeneration(pagination: $pagination, filter: $filter) {
			data {
				id
				workCode
				bookingServiceId
				startDateTime
				endDateTime
				jobRating
				servicePartnerId
				units
				otherRequirements
				bookingServiceItemStatus
				bookingService {
					serviceBillingOptionId
					qty
					unit
					service {
						id
						code
						name
						HSNcode
					}
					bookingAdditionalPayments {
						id
						itemPrice {
							partnerPrice
							commission
							commissionTax
							partnerTax
							totalTax
							total
						}
						name
						description
						refundable
						refundAmount
						mandatory
						bookingServiceId
					}
					booking {
						bookingNote
						id
						userBookingNumber
						appliedCoupons
						bookingAmount {
							totalRefundable
							totalRefunded
							subTotal
							grandTotal
							partnerRate
							partnerDiscount
							partnerAmount
							partnerGSTPercentage
							partnerGSTAmount
							totalPartnerAmount
							commissionPercentage
							commissionRate
							commissionDiscount
							commissionAmount
							commissionGSTPercentage
							commissionGSTAmount
							totalCommission
							subTotal
							totalDiscount
							totalAmount
							totalGSTAmount
							grandTotal
						}
						bookingPayments {
							id
							orderId
							paymentId
							amount
							amountPaid
							amountDue
							currency
							status
							attempts
							invoiceNumber
							bookingId
						}
						user {
							id
							name
							email
							phone
						}
						bookingStatus
						bookingDate
					}
				}
				servicePartner {
					id
					name
					phone
				}
			}
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;

export const CMSQueryExportRevenueGeneration = gql`
	query CMSExportRevenueGeneration(
		$filter: GetFinanceReportFilterGqlInput!
	) {
		exportRevenueGeneration(filter: $filter) {
				id
				workCode
				bookingServiceId
				startDateTime
				endDateTime
				jobRating
				servicePartnerId
				units
				otherRequirements
				bookingServiceItemStatus
				review {
					id
					userId
					user {
						id
						name
					}
					rating
					review
					createDateTime
				}
				statusHistory {
					status
					statusChangedAt
					changedByUser {
						name
						id
					}
				}
				bookingAddons {
					name
					itemPrice {
						partnerPrice
						commission
						commissionTax
						partnerTax
						totalTax
						total
					}
					units
					unit
					addedBy
					addonId
					bookingServiceItemId
				}
				bookingService {
					serviceRequirements
					serviceBillingOptionId
					qty
					unit
					service {
						id
						code
						name
						HSNcode
						billingOptions {
							id
							name
							maxUnit
							sortOrder
						}
						icon {
							id
							url
						}
					}
					bookingServiceInputs {
						id
						bookingServiceId
						value
						name
						key
						type
					}
					bookingAdditionalPayments {
						id
						itemPrice {
							partnerPrice
							commission
							commissionTax
							partnerTax
							totalTax
							total
						}
						name
						description
						refundable
						refundAmount
						mandatory
						bookingServiceId
					}
					booking {
						bookingNote
						id
						userBookingNumber
						appliedCoupons
						bookingAmount {
							totalRefundable
							totalRefunded
							subTotal
							grandTotal
							partnerRate
							partnerDiscount
							partnerAmount
							partnerGSTPercentage
							partnerGSTAmount
							totalPartnerAmount
							commissionPercentage
							commissionRate
							commissionDiscount
							commissionAmount
							commissionGSTPercentage
							commissionGSTAmount
							totalCommission
							subTotal
							totalDiscount
							totalAmount
							totalGSTAmount
							grandTotal
						}
						bookingPayments {
							id
							orderId
							paymentId
							amount
							amountPaid
							amountDue
							currency
							status
							attempts
							invoiceNumber
							bookingId
						}
						bookingAddress {
							id
							buildingName
							postalCode
							area {
								name
								city {
									id
									name
								}
							}
							addressType
							landmark
							locality
						}
						user {
							id
							name
							email
							phone
						}
						bookingStatus
						bookingDate
					}
				}
				servicePartner {
					id
					name
					phone
				}
		}
	}
`;

export const CMSQueryServicePartnerReport = gql`
	query CMSGetServicePartnerReport(
		$pagination: PaginationGqlInput!
		$filter: GetServicePartnerReportFilterGqlInput!
	) {
		getServicePartnerReport(pagination: $pagination, filter: $filter) {
			data {
				id
				name
				email
				phone
				createDateTime
				partnerDetails {
					pendingTasks
					documents {
						documentType
					}
					services {
						name
					}
					serviceAreas {
						name
					}
				}
				userType
			}
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;
export const CMSQueryExportServicePartnerReport = gql`
	query CMSExportServicePartnerReport(
		$filter: GetServicePartnerReportFilterGqlInput!
	) {
		exportServicePartnerReport(filter: $filter) {
				id
				name
				email
				phone
				createDateTime
				partnerDetails {
					pendingTasks
					documents {
						documentType
					}
					services {
						name
					}
					serviceAreas {
						name
					}
				}
				userType
		}
	}
`;

export const CMSQueryJobReport = gql`
	query CMSGetJobReport(
		$pagination: PaginationGqlInput!
		$filter: GetJobReportFilterGqlInput!
	) {
		getJobReport(pagination: $pagination, filter: $filter) {
			data {
				workCode
				bookingService {
					servicePartners{
						id
						name
					}
					booking {
						user {
							id
							name
						}
						createDateTime
					}
					service {
						name
					}
				}
				servicePartner {
					id
					name
					email
					phone
				  }
				startDateTime
				endDateTime
				actualStartDateTime
				actualEndDateTime
				allocatedHours
				totalTimeTaken
				timeDifference
			}
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;
export const CMSQueryExportJobReport = gql`
	query CMSExportJobReport(
		$filter: GetJobReportFilterGqlInput!
	) {
		exportJobReport(filter: $filter) {
				workCode
				bookingService {
					servicePartners{
						id
						name
					}
					booking {
						user {
							id
							name
						}
						createDateTime
					}
					service {
						name
					}
				}
				servicePartner {
					id
					name
					email
					phone
				  }
				startDateTime
				endDateTime
				actualStartDateTime
				actualEndDateTime
				allocatedHours
				totalTimeTaken
				timeDifference
				bookingServiceItemStatus
		}
	}
`;
