import { useQuery } from '@apollo/client';
import {
	Card,
	Col,
	Input,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Table,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import {
	CMSGetPartners,
	CMSGetPartnersVariables,
	CMSGetPartners_getUsers_data,
} from 'src/lib/gql/generated/CMSGetPartners';
import { useNavigate } from 'react-router-dom';
import {
	PartnerPendingTaskEnum,
	UserTypeEnum,
} from 'src/lib/gql/generated/globalTypes';
import { CMSQueryGetPartners } from 'src/lib/gql/queries/users';

// Name, Bookings, Address, Account status, action


const { Option } = Select;

const PartnerPending: React.FC = () => {
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [filterName, setFilterName] = useState('');
	const [filterPhone, setFilterPhone] = useState('');
	const [accountType, setAccountType] = useState(UserTypeEnum.PENDING_PARTNER);
	const [selectedPageSize, setSelectedPageSize] = useState(
		pagination.pageSize.toString()
	);
	const navigate = useNavigate();
	// const [RaccountType, RsetAccountType] = useState(UserTypeEnum.REJECTED_PARTNER);
	const {
		data: qryData,
		loading,
		called,
		error,
		refetch: refetchQryData,
	} = useQuery<CMSGetPartners, CMSGetPartnersVariables>(CMSQueryGetPartners, {
		variables: {
			pagination: pagination,
			filters: {
				type: accountType,
				name: filterName,
				phone: filterPhone,
			},
		},
	});

	const columns = [
		// ...(accountType === UserTypeEnum.PENDING_PARTNER
		// 	? 
		// 	[
			{

				title: 'Name',
				dataIndex: 'name',
				key: 'name',
				width: 150,
				render: (name, record) => <Link to={RouteList.partners.path + '/' + record.id}><span className="gx-link">{name}</span></Link>,
			},
			// ]
			// : []),
		// {
		// 	title: 'Name',
		// 	dataIndex: 'name',
		// 	key: 'name',
		// 	width: 150,
		// 	render: (name, record) => <Link to={RouteList.partnersPending.path + '/' + record.id}><span className="gx-link">{name}</span></Link>
		// },
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Phone',
			dataIndex: 'phone',
			key: 'phone',
			width: 150,
			align: 'center' as const,
			render: (
				text,
				record,  
			) =>  {
				const formatPhoneNumber = (phoneNumber) => {
					phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
					if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
					  return `+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`;
					} else {
					  return phoneNumber;
					}
				  };
				const inputPhoneNumber = record?.phone
				const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);
				return(
				<>
				{formattedPhoneNumber} 
				</>
			);
		},
		},
		{
			title: 'Registered on',
			dataIndex: 'createDateTime',
			key: 'createDateTime',
			width: 150,
			align: 'center' as const,
			render: (createDateTime, record) => {
				const convertedDate = new Date(parseInt(createDateTime));
				const month = convertedDate.toLocaleString('default', { month: 'long' });
				const time = convertedDate.toLocaleTimeString();
				return (
					<span>
						{convertedDate.getDate()} {month}, {convertedDate.getFullYear()}
						<br />
						{time}
					</span>
				);
			},
		},
		{
			title: 'Documents Uploaded',
			dataIndex: 'rework',
			key: 'rework',
			align: 'center' as const,
			render: (_, record: CMSGetPartners_getUsers_data) => (
				<div className="gx-ml-2 gx-d-none gx-d-sm-flex" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					{record?.partnerDetails?.pendingTasks && (
						<span>
							{record?.partnerDetails?.pendingTasks?.findIndex(
								(task) => task === PartnerPendingTaskEnum.UPLOAD_DOCUMENT,
							) > -1
								? `🔴`
								: `🟢`}
						</span>
					)}
				</div>
			),
		},
		{
			title: 'Services selected',
			dataIndex: 'rework',
			key: 'rework',
			align: 'center' as const,
			render: (_, record: CMSGetPartners_getUsers_data) => (
				<div className="gx-ml-2 gx-d-none gx-d-sm-flex" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					{record?.partnerDetails?.pendingTasks && (
						<span>
							{record?.partnerDetails?.pendingTasks?.findIndex(
								(task) => task === PartnerPendingTaskEnum.SELECT_SERVICE,
							) > -1
								? `🔴`
								: `🟢`}
						</span>
					)}
				</div>
			),
		},
		{
			title: 'Services Areas selected',
			dataIndex: 'rework',
			key: 'rework',
			align: 'center' as const,
			render: (_, record: CMSGetPartners_getUsers_data) => (
				<div className="gx-ml-2 gx-d-none gx-d-sm-flex" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
					{record?.partnerDetails?.pendingTasks && (
						<span>
							{record?.partnerDetails?.pendingTasks?.findIndex(
								(task) => task === PartnerPendingTaskEnum.SELECT_AREA,
							) > -1
								? `🔴`
								: `🟢`}
						</span>
					)}
				</div>
			),
		},
		{
			title: 'Account Status',
			dataIndex: 'userType',
			key: 'userType',
			align: 'center' as const,
		},

		// {
		// 	title: 'Action',
		// 	dataIndex: 'key',
		// 	key: 'id',
		// 	width: 'auto',
		// 	align: 'center' as const,
		// 	render: (_text, record) => (
		// 		<span>
		// 			<Link to={RouteList.partners.path + '/' + record.id} >
		// 				<span className="gx-link"><i className="icon-view-o" style={{ marginRight: '20px' }} /></span>
		// 			</Link>
		// 		</span>
		// 	),
		// },
	];

	const handleNameSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		let value = e.target.value;
		value = value.replace(/[0-9]/g, '');
		setFilterName(value);
		setPagination({ ...pagination, pageNumber: 1 }); // Reset pagination when searching by name
	};

	const handlePhoneSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		let value = e.target.value;
		value = value.replace(/\D/g, '');
		setFilterPhone(value);
	};

	const handlePaginationChange = (page: number, pageSize?: number) => {
		setPagination({
			pageNumber: page,
			pageSize: pageSize ? +pageSize : pagination.pageSize || 5,
		});
		setSelectedPageSize((pageSize || pagination.pageSize).toString());
	};

	useEffect(() => {
		refetchQryData();
	}, [filterName, filterPhone, accountType, pagination, refetchQryData]);

	return (
		<Card title="Pending Partners">
			<Row style={{
				marginBottom: "16px",
				paddingTop: "0.2rem",
				paddingBottom: "5px"
			}}>
				<Col span={24}>
					<div style={{
						justifyContent: "center",
						display: "flex"
					}}>
						<Input style={{ width: "250px" }}
							placeholder="Search by Name"
							value={filterName} allowClear
							onChange={handleNameSearch}
						/>
						{/* </Col> */}
						{/* <Col span={4}> */}
						<div style={{ paddingLeft: "0.5rem" }}>
							<Input style={{ width: "250px" }}
								placeholder="Search by Phone"
								value={filterPhone}
								allowClear maxLength={10}
								onChange={handlePhoneSearch}
							/>
						</div>
						<div style={{ paddingLeft: "0.5rem" }}>
							<Select
								defaultValue={UserTypeEnum.PENDING_PARTNER}
								style={{ width: 120 }}
								onChange={(value) => setAccountType(value)} getPopupContainer={(trigger) => trigger.parentElement}
							> 
								<Option value={UserTypeEnum.PENDING_PARTNER}>PENDING</Option>
								<Option value={UserTypeEnum.REJECTED_PARTNER}>REJECTED</Option>
							</Select>
						</div>

					</div>
				</Col>
			</Row>
			<Row style={{
				marginBottom: 16, paddingTop: "1rem",
				backgroundColor: "#f6f6f6",
				paddingBottom: "5px"
			}}>
				<Col span={24} style={{ textAlign: "center" }}>

					<Pagination
						key="pagination"
						defaultCurrent={qryData?.getUsers?.pageInfo.currentPage}
						current={
							qryData?.getUsers?.pageInfo.currentPage}
						pageSize={pagination.pageSize}
						total={qryData?.getUsers?.pageInfo.totalCount}
						showTotal={(total) => `Total ${total} items`}
						onChange={handlePaginationChange}
						showSizeChanger
						pageSizeOptions={['10', '20', '50', '100']}
						onShowSizeChange={handlePaginationChange}
						selectComponentClass={Select}
						showQuickJumper
						showLessItems
					/>
				</Col>
			</Row>
			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}

			{qryData && (
				<Table
					className="gx-table-responsive"
					columns={columns}
					dataSource={qryData?.getUsers?.data}
					pagination={false}
				/>
			)}
		</Card>
	);
};

export default PartnerPending;