import {
	ApolloClient,
	ApolloProvider,
	createHttpLink,
	InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { getTokenOld, watchAuth, watchToken } from 'src/lib/firebase/fbClient';
import { userTokenAtom } from 'src/lib/recoil/userCoil';

interface Props {
	children?: React.ReactNode;
}

const ApolloHOC = (props: Props) => {
	const [userToken, setUserToken] = useRecoilState(userTokenAtom);
	useEffect(() => {
		watchAuth(setUserToken);
		watchToken(setUserToken);
	}, []);
	const httpLink = createHttpLink({
		uri: process.env.REACT_APP_GQL_ENDPOINT,
	});

	const authLink = setContext(async (_, { headers }) => {
		let finalToken = userToken;
		finalToken = await getTokenOld();
		finalToken = localStorage.getItem('zk_acc_tkn')
		return {
			headers: {
				...headers,
				'x-source-platform': 'ADMIN_APP',
				...(finalToken && {
					Authorization: finalToken,
				}),
			},
		};
	});

	const gqlClient = new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache(),
		defaultOptions: {
			query: {
				errorPolicy: 'all',
			},
		},
	});
	return <ApolloProvider client={gqlClient}>{props.children}</ApolloProvider>;
};

export default ApolloHOC;
