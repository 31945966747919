import { useMutation, useQuery } from '@apollo/client';
import {
	Card,
	Col,
	Input,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Table,Button,Modal, Switch,
	Alert
} from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import {
	CMSGetUsers,
	CMSGetUsersVariables,
} from 'src/lib/gql/generated/CMSGetUsers';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { CMSQueryGetPartners, CMSQueryGetUsers } from 'src/lib/gql/queries/users';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'src/lib/recoil/userCoil';
import { CMSQueryGetPersonnel, CMSQueryGetTeams } from 'src/lib/gql/queries/chk';
import { CREATE_TEAM, UPDATE_TEAM,DELETE_TEAM } from 'src/lib/gql/mutations/mutations';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

const Teams: React.FC = () => {
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [partnersPagination, setPartnersPagination] = useState({
		pageNumber: 1,
		pageSize: 300,
	});
	const [personnelPagination, setPersonnelPagination] = useState({
		pageNumber: 1,
		pageSize: 300,
	});
	const [filterName, setFilterName] = useState('');
	const [filterPhone, setFilterPhone] = useState('');
	const [accountType, setAccountType] = useState(UserTypeEnum.PARTNER);
	const userAuth = useRecoilValue(userAtom);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [supervisor, setSupervisor] = useState<any>()
	const [members, setMembers] = useState<any>()
	const [selectedTeam, setSelectedTeam] = useState<any>()
	const [editTeamStatus, setEditTeamStatus] = useState<any>()
	const [isModalVisibleEdit, setIsModalVisibleEdit] = useState<any>()
	const [editSupervisor, setEditSupervisor] = useState()
    const [editMembers, setEditMembers] = useState<any>()
	const [isModalVisibleDelete, setIsModalVisibleDelete] = useState(false);
	const [deletedTeams, setdeletedTeams] = useState<any>();
	const [filterTeamId, setFilterTeamId] = useState('');
	const [submitError, setSubmitError] = useState('');
	const [editSubmitError, setEditSubmitError] = useState('');


	const [name, setName] = useState<any>()
	const [strength, setStrength] = useState<any>()

	const { data: qryData, loading, refetch: refetchQryData } = useQuery(CMSQueryGetTeams, {
		variables: {
			pagination: pagination,
			filters: {
				name: filterName,
				teamId: filterTeamId
			},
		},
		fetchPolicy: 'network-only'
	}
	);

	const {
		data: qryPartnersData,
		loading: loadingPartners,
		error,
		refetch: refetchQryPartnersData,
	} = useQuery(CMSQueryGetPartners, {
		variables: {
			pagination: partnersPagination,
			filters: {
				type: accountType,
				phone: filterPhone,
			},
		},
	});

	const {
		data: qryPersonnelData,
		loading: loadingPersonnel,
		error: errorPersonnel,
		refetch: refetchQryPersonnelData,
	} = useQuery(CMSQueryGetPersonnel, {
		variables: {
			pagination: personnelPagination,
			filters: {},
		},
	});

	const [
		deleteTeams,
		{ data: dataEditMtUd, loading: loadingEditMtUd, error: errorEditMtUd },
	] = useMutation(DELETE_TEAM);


	const handlePhoneFilter = (value: string | undefined) => {
		setFilterPhone(value || '');
	};
	const handleInputChangeName = (e) => {
		const value = e?.target?.value;
		setFilterName(value);
	};

	  const handleInputChangeId = (e) => {
		const value = e.target.value;
		setFilterTeamId(value)
	  };

	  const handleOpenModalEdit = (e) => {
		const selectedTeam = qryData.getTeams.data.find(
			(x, i) => x.id === e.id,
		);
		const membersIds = selectedTeam?.members?.map((x) => x.id)
		setEditMembers(membersIds)
		setSelectedTeam(selectedTeam);
		setEditTeamStatus(selectedTeam?.isActive);
		setIsModalVisibleEdit(true);
	};
	const handleOpenModalDelete = (e) => {
		const selectedTeam = qryData.getTeams.data.find(
			(x, i) => x.id === e.id,
		);
		setdeletedTeams(selectedTeam);
		setIsModalVisibleDelete(true)
	}

	const pageSizeOptions = ['10', '20', '50']; // Add '5' to the list of options

	const columns = [
        {
            title: 'ID',
            dataIndex: 'uid',
            key: 'uid',
            // width: 150,
            render: (uid: string, record: any) => (
                <span className="">
                    {uid}
                </span>
            ),
        },
		{
				title: 'Supervisor',
				dataIndex: 'partner',
				key: 'partner',
				// width: 150,
				render: (partner: string, record: any) => (
					<span className="">
						{/* <Link to={RouteList.users.path + '/' + record?.partner?.id}> */}
							{record?.partner?.name}
							{/* </Link> */}
					</span>
				),
			},
            {
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
				align: 'center' as const,
				render: (name: string) => (
					<span>{name}</span>
				),

			},
			{
				title: 'Open Bookings',
				dataIndex: 'openBookings',
				key: 'openBookings',
				align: 'center' as const,
				render: (openBookings: any[]) => (
					<span>{0}</span>
				),

			},
		
			{
				title: 'Account Status',
				dataIndex: 'isActive',
				align: 'center' as const,
				render: (isActive: string, record: any) => {
					return (isActive ? <span >🟢</span> : <span >🔴</span>)
				}
			},

			
				// {
				// 	title: 'Last Tried On',
				// 	dataIndex: 'lastChangedDateTime',
				// 	key: 'lastChangedDateTime',
				// 	render: (lastChangedDateTime, record) => {
				// 		const convertedDatel = new Date(parseInt(lastChangedDateTime));
				// 		const month = convertedDatel.toLocaleString('default', { month: 'long' });
				// 		const time = convertedDatel.toLocaleTimeString();
				// 		const timeCrt = moment(time).format('LT');
				// 		return (
				// 			<span style={{ textAlign: 'center' }}>
				// 				{/* {convertedDatel.getDate()} {month}, {convertedDatel.getFullYear()}
				// 				<br /> */}
				// 				{/* {timeCrt} */}
				// 				{moment(convertedDatel).fromNow()}
				// 			</span>
				// 		);
				// 	},
				// },
				
		{
			title: 'Action',
			dataIndex: 'key',
			key: 'id',
			width: 'auto',
			align: 'center' as const,
			render: (text: string, record: any) => (
				<>
				<span onClick={() => handleOpenModalEdit(record)} style={{cursor: 'pointer'}}>
					<EditOutlined style={{ marginRight: '20px', color: '#5143dc' }} />
				</span>
				<span onClick={() => handleOpenModalDelete(record)} style={{cursor: 'pointer'}}>
				<DeleteOutlined style={{ color: "red" ,marginRight: '20px'}} />
			</span>
				</>
		    ),
		},
	];

	const [
		createTeam,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation(CREATE_TEAM);

	const [
		updateTeam,
		{ data: dataEditMtU, loading: loadingEditMtU, error: errorEditMtU },
	] = useMutation(UPDATE_TEAM);


	const handleOpenModal = () => {
		setIsModalVisible(true);
	};
	const handleOk = () => {
		if (!name || !strength || !supervisor || !Array.isArray(members) || !members.length) {
			setSubmitError('Please fill all fields')
			return
		}
		setSubmitError('')
		createTeam({
			variables: {
				data: { 
					name: name, 
					strength: strength,
					partnerId: supervisor,
					members
				},
			},
			onCompleted: () => {
				refetchQryData();
				setIsModalVisible(false);
				setName('')
				setStrength('')
				setSupervisor('')
				setMembers([])
			},
		});
		
	};

	const handleEditOk = () => {
		console.log("🚀 ~ handleEditOk ~ Array.isArray(editMembers):", Array.isArray(editMembers))
		console.log("🚀 ~ handleEditOk ~ !editMembers.length:", editMembers)
		console.log("🚀 ~ handleEditOk ~ selectedTeam?.supervisor:", selectedTeam?.partner)
		console.log("🚀 ~ handleEditOk ~ selectedTeam?.strength:", selectedTeam?.strength)
		console.log("🚀 ~ handleEditOk ~ selectedTeam?.name:", selectedTeam?.name)
		if (!selectedTeam?.name || !selectedTeam?.strength || !selectedTeam?.partner || !Array.isArray(editMembers) || !editMembers.length) {
			setEditSubmitError('Please fill all fields')
			return
		}
		setEditSubmitError('')
		updateTeam({
			variables: {
				data: { 
					id: selectedTeam?.id,
					name: selectedTeam?.name,
					strength: selectedTeam?.strength,
					partnerId: selectedTeam?.partnerId,
					members: editMembers,
					isActive: editTeamStatus
				},
			},
			onCompleted: () => {
				refetchQryData();
				setIsModalVisibleEdit(false);
			},
		});
		
	};

	const handleCancel = () => {
		setName('')
		setStrength('')
		setSupervisor('')
		setMembers([])
		setSubmitError('')
		setEditSubmitError('')
		setIsModalVisible(false);
		setIsModalVisibleEdit(false);
	};

	// handle edit changes
	const handleEditChanges = (field) => (e) => {
		if (field === 'partnerId' || field === 'members') {
			setSelectedTeam({...selectedTeam, [field]: e});
		} else {
			setSelectedTeam({...selectedTeam, [field]: e.target.value});
		}
	}

	useEffect(() => {
		setPagination({
			...pagination,
			pageNumber: 1, // Reset pagination when filters change
		});
	}, [filterName, filterPhone]);

	const exportData = qryData?.getUsers?.data || []; // Extract the data to be exported

	// console.log("dataa==>",qryData?.getUsers?.data);
	const handleCancelDelete = () => {
		setIsModalVisibleDelete(false);
	};

	const handleOkDelete = () => {
		deleteTeams({
			variables: {
			id: deletedTeams.id,
			},
			onCompleted: () => {
				refetchQryData();
				setIsModalVisibleDelete(false);
			},
		});
		
	};

	return (
		<Card title={<div>Teams</div>}
        extra={[
            <>
                    {/* <Link to={RouteList.addPartner.path}> */}
                        <Button onClick={handleOpenModal} type="primary" style={{ float: 'right' }}>
                            Add New Teams
                        </Button>
                    {/* </Link> */}
             
            </>,
        ]}
		>
			<Row style={{
				marginBottom: "16px",
				paddingTop: "0.2rem",
				paddingBottom: "5px"
			}}>
				<Col span={24} >
					<div style={{
						justifyContent: "center",
						display: "flex"
					}}>
						<div>
							<Input type='string'
								style={{ width: "250px" }}
								placeholder="Search by ID"
								value={filterTeamId}
								allowClear
								onChange={(e) => handleInputChangeId(e)}
							/>
						</div>

						{/* </Col> */}
						{/* <Col span={4}> */}
						<div style={{ paddingLeft: "0.5rem" }}>
							<Input style={{ width: "250px", paddingLeft: "1rem" }}
								placeholder="Search by Supervisor Name"
								value={filterName}
								allowClear 
								onChange={(e) => handleInputChangeName(e)}
								// maxLength={10}
							/>
						</div>
						{/* </Col> */}
						{/* <Col> */}
						
					</div>
				</Col>
				{/* <Col span={12}>
				
				</Col> */}
			</Row>

			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}

			{/* Data export button */}

			{userAuth?.userType === UserTypeEnum.ADMIN ||
				userAuth?.rights?.find(
					(x) => x.type === 'sub2' && x.name === 'export-csv',
				) ? (
				<Row justify="end" style={{
					marginBottom: 16, paddingTop: "1rem",
					backgroundColor: "#f6f6f6",
					paddingBottom: "5px"
				}}>
					<Col span={24} style={{ textAlign: "center" }}>
						<Pagination
							key="pagination"
							defaultCurrent={qryData?.getTeams?.pageInfo.currentPage}
							current={qryData?.getTeams?.pageInfo.currentPage}
							pageSize={pagination.pageSize}
							total={qryData?.getTeams?.pageInfo.totalCount}
							showTotal={(total) => `Total ${total} items`}
							onChange={(page, pageSize) => {
								setPagination({
									pageNumber: page,
									pageSize: pageSize ? +pageSize : pagination.pageSize,
								});
							}}
							showSizeChanger
							pageSizeOptions={pageSizeOptions} // Set the pageSizeOptions prop
						/>
					</Col>

				</Row>
			) : (
				<></>
			)}

			{/* Table */}
			<Table
				className="gx-table-responsive"
				columns={columns}
				dataSource={qryData?.getTeams?.data}
				pagination={false}
			/>

				{/* modal  */}
				<Modal
				title="Add Team"
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
				bodyStyle={{
					padding: '1rem 3rem'
				}}
			>
				{submitError && 
						<Alert
						message={`${submitError}`}
						type="error"
						showIcon
					/>
				}
					<Row>
					<Col span={12} style={{width:"100px"}}>
						<span>Name </span>
						<Input value={name} onChange={(e) => setName(e.target.value)}/>
					</Col>
					<Col span={12} style={{width:"100px"}}>
						<span>Strength </span>
						<Input value={strength} onChange={(e) => setStrength(e.target.value)}/>
					</Col>
					<Col span={12} style={{width:"100px", marginTop: '1rem'}}>
						<span>Supervisor </span>
						<Select getPopupContainer={(trigger) => trigger.parentElement}
											showSearch
											placeholder="Select a supervisor"
											allowClear
											optionFilterProp="children"
											filterOption={(input, option: any) => {
												return (
													option?.children?.toLowerCase().includes(input) ??
													true
												);
											}}
											style={{ marginBottom: '12px', display: 'flex' }}
											onChange={(e) => setSupervisor(e)}
											value={supervisor}
										>
											{qryPartnersData?.getUsers?.data?.map((partner, index) => (
												<Select.Option key={partner.id} value={partner.id}>
													{partner.name}
												</Select.Option>
											))}
										</Select>
					</Col>

					<Col span={12} style={{marginTop: '1rem'}}>
						<span>Members </span>
						<Select getPopupContainer={(trigger) => trigger.parentElement}
											showSearch
											mode="multiple"
											placeholder="Select members"
											allowClear
											optionFilterProp="children"
											filterOption={(input, option: any) => {
												return (
													option?.children?.toLowerCase().includes(input) ??
													true
												);
											}}
											style={{ marginBottom: '12px',  width: '100%', display: 'block' }}
											onChange={(e) => setMembers(e)}
											value={members?.map((x,i) => x)}
										>
											{qryPersonnelData?.getPersonnel?.data?.map((member, index) => (
												<Select.Option key={member.id} value={member.id} >
													{member.name}
												</Select.Option>
											))}
										</Select>
					</Col>
					</Row>

				</Modal>

			{/* Edit Modal edit  */}
			<Modal
				title="Edit Team"
				visible={isModalVisibleEdit}
				onOk={handleEditOk}
				onCancel={handleCancel}
				width={1000}
				bodyStyle={{
					padding: '1rem 3rem'
				}}
			>
				{editSubmitError && 
						<Alert
						message={`${editSubmitError}`}
						type="error"
						showIcon
					/>
				}
					<Row>
					<Col span={12} style={{width:"100px"}}>
						<span>Name </span>
						<Input onChange={handleEditChanges('name')} value={selectedTeam?.name}/>
					</Col>
					<Col span={12} style={{width:"100px"}}>
						<span>Strength </span>
						<Input onChange={handleEditChanges('strength')} value={selectedTeam?.strength}/>
					</Col>
					<Col span={12} style={{width:"100px", marginTop: '1rem'}}>
						<span>Supervisor </span>
						<Select getPopupContainer={(trigger) => trigger.parentElement}
											showSearch
											placeholder="Select a supervisor"
											allowClear
											optionFilterProp="children"
											filterOption={(input, option: any) => {
												return (
													option?.children?.toLowerCase().includes(input) ??
													true
												);
											}}
											style={{ marginBottom: '12px', display: 'flex' }}
											value={selectedTeam?.partnerId}
											onChange={handleEditChanges('partnerId')}
										>
											{qryPartnersData?.getUsers?.data?.map((partner, index) => (
												<Select.Option key={partner.id} value={partner.id}>
													{partner.name}
												</Select.Option>
											))}
										</Select>
					</Col>

					<Col span={12} style={{width:"100px", marginTop: '1rem'}}>
						<span>Members </span>
						<Select getPopupContainer={(trigger) => trigger.parentElement}
											showSearch
											mode="multiple"
											placeholder="Select members"
											allowClear
											optionFilterProp="children"
											filterOption={(input, option: any) => {
												return (
													option?.children?.toLowerCase().includes(input) ??
													true
												);
											}}
											style={{ marginBottom: '12px', display: 'block', width:"100%", }}
											onChange={(e) => setEditMembers(e)}
											value={editMembers}
										>
											{qryPersonnelData?.getPersonnel?.data?.map((member, index) => (
												<Select.Option key={member.id} value={member.id}>
													{member.name}
												</Select.Option>
											))}
										</Select>
					</Col>
					<Col
							span={12}
							style={{
								width: '100px',
								marginTop: '10px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<span style={{ marginRight: '1rem' }}>Status </span>
							<Switch
								onChange={(e) => setEditTeamStatus(e)}
								checked={editTeamStatus}
							/>
						</Col>
					</Row>
				</Modal>

	{/* modal delete */}
			<Modal
				title={'Delete Team'}
				visible={isModalVisibleDelete}
				onOk={handleOkDelete}
				onCancel={handleCancelDelete}
				width={500}
				bodyStyle={{
					padding: '1rem 3rem',
				}}
			>
			<p>Are you sure want to delete?</p>
			</Modal>
		</Card>
	);
};

export default Teams;
