import { gql } from '@apollo/client';

export const CMSQueryGetAllServiceCategories = gql`
	query CMSGetAllServiceCategories {
		getServiceCategories {
			id
			name
			code
			description
			sortOrder
			enabled
			services {
				id
				name
				serviceShortList
				icon {
					url
				}
			}
		}
	}
`;

export const CMSQueryGetServiceCategory = gql`
	query CMSGetServiceCategory($id: String!) {
		getServiceCategory(id: $id) {
			id
			name
			code
			services {
				id
				name
				icon {
					url
				}
			}
		}
	}
`;

export const CMSQueryGetService = gql`
	query CMSGetService($id: String!) {
		getService(id: $id) {
			id
			name
			code
			description
			serviceStatus
			serviceShortList
			HSNcode
			isZimkeyService
			isTeamService
			propertyAreas{
				id
				title
				hours
				description
			}
			thumbnail {
				url
			}
			medias {
				id
				type
				url
			}
			icon {
				url
			}
			billingOptions {
				id
				code
				name
				description
				recurring
				recurringPeriod
				autoAssignPartner
				unit
				minUnit
				maxUnit
				additionalMinUnit
				additionalMaxUnit
				unitPrice {
					partnerPrice
					commission
					commissionTax
					partnerTax
					unitPrice
					grandTotal
				}
				additionalUnitPrice {
					partnerPrice
					commission
					commissionTax
					partnerTax
					unitPrice
					grandTotal
				}
			}
			inputs {
				id
				name
				description
				required
				key
				type
			}
			requirements {
				id
				title
				description
			}
			addons {
				id
				name
				description
				type
				unit
				minUnit
				maxUnit
				serviceId
				unitPrice {
					partnerPrice
					commission
					commissionTax
					partnerTax
					unitPrice
					grandTotal
				}
			}
			requirements {
				id
				title
				description
			}
			enabled
			reworkGracePeriod
		}
	}
`;

export const CMSQueryGetServiceAreas = gql`
	query CMSGetServiceAreas {
		getAreas {
			id
			name
			code
			pinCodes {
				id
				pinCode
				areaId
			}
			city {
				id
				name
				code
			}
		}
	}
`;

export const CMSQueryGetServices = gql`
	query CMSGetServices($showAll: Boolean, $search: String, $onlyPublished: Boolean) {
		getServices(showAll: $showAll, search: $search, onlyPublished: $onlyPublished) {
			id
			name
			code
			icon {
				id
				url
			}
			serviceShortList
			showInHomeSortOrder
			popularSortOrder
			serviceStatus
		}
	}
`;
