import React, { useEffect, useState } from 'react';
import { Menu, Card } from 'antd';
import { Link } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { userAtom, userTokenAtom } from 'src/lib/recoil/userCoil';
import { useRecoilState, useRecoilValue } from 'recoil';
import { logoutUser } from 'src/lib/firebase/fbClient';
import adminProfile from './adminPic.png';
import { CMSGetMe } from 'src/lib/gql/generated/CMSGetMe';
import { useQuery } from '@apollo/client';
import { CMSQueryGetMe } from 'src/lib/gql/queries/users';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
// import { messaging } from './firebase';
import { getToken } from 'firebase/messaging';
import { messaging } from 'src/lib/firebase/firebase';
import { UNREGISTER_FCM_TOKEN } from 'src/lib/gql/mutations/mutations';
import { useMutation } from '@apollo/client';
import { MailOutlined, MobileOutlined,NotificationOutlined } from '@ant-design/icons';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
export const SidebarContent: React.FC = () => {
	const navigate = useNavigate();
	const [, setUserToken] = useRecoilState(userTokenAtom);
	const [, setAuthUser] = useRecoilState(userAtom);
	const { data: qryData } = useQuery<CMSGetMe>(CMSQueryGetMe);

	const userAuth = useRecoilValue(userAtom);

	// console.log("qryData",qryData?.me?.email);

	const [selectedMenuKey, setSelectedMenuKey] = useState<any>();
	const [openSubMenuKeys, setOpenSubMenuKeys] = useState<any>();
	const path = window.location.pathname;
	useEffect(() => {
		// Set the selected menu key based on the current path
		if (path === RouteList.dashboard.path) {
			setSelectedMenuKey('main/dashboard/crypto');
			setOpenSubMenuKeys(null);
		} else if (path === RouteList.users.path) {
			setSelectedMenuKey('users');
			setOpenSubMenuKeys(['user']);
		} else if (path === RouteList.partners.path) {
			setSelectedMenuKey('partners');
			setOpenSubMenuKeys(['user']);
		} else if (path === RouteList.partnersPending.path) {
			setSelectedMenuKey(RouteList.partnersPending.path);
			setOpenSubMenuKeys(['user']);
		} else if (path === RouteList.admins.path) {
			setSelectedMenuKey(RouteList.admins.path);
			setOpenSubMenuKeys(['user']);
		} else if (path === RouteList.manageApp.path) {
			setSelectedMenuKey(RouteList.manageApp.path);
			setOpenSubMenuKeys(['manage']);
		} else if (path === RouteList.manageAppConfig.path) {
			setSelectedMenuKey(RouteList.manageAppConfig.path);
			setOpenSubMenuKeys(['manage']);
		} else if (path === RouteList.dupmanageCoupon.path) {
			setSelectedMenuKey(RouteList.dupmanageCoupon.path);
			setOpenSubMenuKeys(['promotions']);
		} else if (path === RouteList.zpoints.path) {
			setSelectedMenuKey(RouteList.zpoints.path);
			setOpenSubMenuKeys(['promotions']);
		} else if (path === RouteList.reports.path) {
			setSelectedMenuKey(RouteList.reports.path);
			setOpenSubMenuKeys(['reports']);
		} else if (path === RouteList.servicePartners.path) {
			setSelectedMenuKey(RouteList.servicePartners.path);
			setOpenSubMenuKeys(['reports']);
		} else if (path === RouteList.jobs.path) {
			setSelectedMenuKey(RouteList.jobs.path);
			setOpenSubMenuKeys(['reports']);
		} else if (path === RouteList.bookings.path) {
			setSelectedMenuKey(RouteList.bookings.path);
			setOpenSubMenuKeys(['bookings']);
		} else if (path === RouteList.jobBoard.path) {
			setSelectedMenuKey(RouteList.jobBoard.path);
			setOpenSubMenuKeys(['bookings']);
		} else if (path === RouteList.rework.path) {
			setSelectedMenuKey(RouteList.rework.path);
			setOpenSubMenuKeys(['bookings']);
		} else if (path === RouteList.firstBookingExp.path) {
			setSelectedMenuKey(RouteList.firstBookingExp.path);
			setOpenSubMenuKeys(['firstBookingExp']);
		} else if (path === RouteList.support.path) {
			setSelectedMenuKey(RouteList.support.path);
			setOpenSubMenuKeys(['support']);
		} else if (path === RouteList.services.path) {
			setSelectedMenuKey(RouteList.services.path);
			setOpenSubMenuKeys(null);
		} else if (path === RouteList.personnel.path) {
			setSelectedMenuKey(RouteList.personnel.path);
			setOpenSubMenuKeys(['TeamManagement']);
		} else if (path === RouteList.teams.path) {
			setSelectedMenuKey(RouteList.teams.path);
			setOpenSubMenuKeys(['TeamManagement']);
		} else if (path === RouteList.serviceSettings.path) {
			setSelectedMenuKey(RouteList.serviceSettings.path);
			setOpenSubMenuKeys(['TeamManagement']);
		} else if (path === RouteList.payouts.path) {
			setSelectedMenuKey(RouteList.payouts.path);
			setOpenSubMenuKeys(['payouts']);
		} else if (path === RouteList.payoutsHistory.path) {
			setSelectedMenuKey(RouteList.payoutsHistory.path);
			setOpenSubMenuKeys(['payouts']);
		} else if (path === RouteList.payoutSettings.path) {
			setSelectedMenuKey(RouteList.payoutSettings.path);
			setOpenSubMenuKeys(['payouts']);
		} else if (path === RouteList.notifications.path) {
		setSelectedMenuKey(RouteList.notifications.path);
		setOpenSubMenuKeys(['notifications']);
	}
	}, [path]);

	const [
		unregsiterFcmToken,
		{ data: dataMtUs, loading: loadingMtUs, error: errorMtUs },
	] = useMutation(UNREGISTER_FCM_TOKEN);
	const logout = async (updateFn) => {
		const token = await getToken(messaging, {
			vapidKey:
				'BN8kdMP5Gnq04uAa2KgtSmG06mxd3n4dmyPU8-d_A57x6lh_XTXiGi1fMbIQWr0nQYKlZ_VjoYPnfxa8H72StRY',
		});
		// console.log("1");
		const auth = getAuth();
		await auth.signOut();

		// logoutUser(setUserToken);
		setUserToken(null);
		setAuthUser(null);

		await unregsiterFcmToken({
			variables: {
				token: token,
			},
		});

		localStorage.removeItem('zk_acc_tkn');
		localStorage.removeItem('zk_usr_dta');

		// updateFn(null);
		if (updateFn === null) {
			navigate('/login');
		}
	};

	// const [selectedItem, setSelectedItem] = useState('');

	// useEffect(() => {
	// 	// Set the default selected menu item based on available rights
	// 	const availableRights = userAuth?.rights || [];
	// 	const firstMenuItem = availableRights.find((x) => x.type === 'main');
	// 	if (firstMenuItem?.length) {
	// 	  setSelectedItem(firstMenuItem[0].name);
	// 	}
	//   }, [userAuth?.rights]);

	return (
		<>
			<div className="gx-layout-sider-header">
				<Link to="/" className="gx-site-logo">
					<img alt="logo2" height="40px" src="/assets/images/taskey-logo.svg" />
				</Link>
			</div>
			<div className="gx-sidebar-content">
				{/* <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <AppsNavigation />
        </div> */}
				<Card
					style={{
						width: '90%',
						backgroundColor: 'rgb(9 40 70)',
						borderRadius: '5px',
						position: 'relative',
						overflow: 'hidden',
						boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
						alignItems: 'center',
						margin: '0 10px 0 10px',
						color: '#f4f4f4',
						border: 'none',
						height: '70px',
					}}
				>
					<div className="card_content" style={{ display: 'flex' }}>
						<img
							src={adminProfile}
							alt="admin-profile"
							style={{ width: '30px', height: '30px', borderRadius: '100%' }}
						/>
						<div style={{ paddingLeft: '5px', marginTop: '-10px' }}>
							<p style={{ color: '#f4f4f4', fontWeight: 'bold' }}>
								{qryData?.me?.email}
							</p>
							<p style={{ fontSize: '12px', marginTop: '-10px' }}>
								{qryData?.me?.name}
							</p>
						</div>
					</div>
				</Card>
				<div className="gx-layout-sider-scrollbar">
					<Menu
						theme={'dark'}
						mode="inline"
						selectedKeys={[selectedMenuKey]}
						defaultOpenKeys={openSubMenuKeys}
					>
						<MenuItemGroup key="main" className="test" title={''}>
							{/* Dashboard */}

							{userAuth?.userType === UserTypeEnum.ADMIN ||
							userAuth?.rights?.find(
								(x) => x.type === 'main' && x.name === 'dashboard',
							) ? (
								<Menu.Item key="main/dashboard/crypto">
									<Link to={RouteList.dashboard.path}>
										<i className="icon icon-dasbhoard" />
										<span>Dashboard</span>
									</Link>
								</Menu.Item>
							) : (
								<></>
							)}

							{/* Users */}
							{userAuth?.userType === UserTypeEnum.ADMIN ||
							userAuth?.rights?.find(
								(x) => x.type === 'main' && x.name === 'users',
							) ? (
								<SubMenu
									key="user"
									title={
										<span>
											{' '}
											<i className="icon icon-user-o" />
											<span>Users</span>
										</span>
									}
								>
									{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub' && x.name === 'customers',
									) ? (
										<Menu.Item key="users">
											<Link to="/users">
												<i
													className="icon-family"
													style={{ marginRight: '12px' }}
												/>
												<span>Customers</span>
											</Link>
										</Menu.Item>
									) : (
										<></>
									)}

									{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub' && x.name === 'service-partners',
									) ? (
										<Menu.Item key="partners">
											<Link to="/partners">
												<i
													className="icon-team"
													style={{ marginRight: '12px' }}
												/>
												<span>Service Partners</span>
											</Link>
										</Menu.Item>
									) : (
										<></>
									)}

									{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub' && x.name === 'pending-partners',
									) ? (
										<Menu.Item key={RouteList.partnersPending.path}>
											<Link to={RouteList.partnersPending.path}>
												<i
													className="icon-error"
													style={{ marginRight: '12px' }}
												/>
												<span>Pending Partners</span>
											</Link>
										</Menu.Item>
									) : (
										<></>
									)}

									{/* <Menu.Item key={RouteList.addPartner.path}>
                  <Link to={RouteList.addPartner.path}>
                    <i
                      className="icon-circle-o"
                      style={{ marginRight: '12px' }}
                    />
                    <span>Add New Partner</span>
                  </Link>
                </Menu.Item> */}

									{userAuth?.userType === UserTypeEnum.ADMIN && (
										<Menu.Item key={RouteList.admins.path}>
											<Link to={RouteList.admins.path}>
												<i
													className="icon-wall"
													style={{ marginRight: '12px' }}
												/>
												<span>Admins</span>
											</Link>
										</Menu.Item>
									)}
								</SubMenu>
							) : (
								<></>
							)}

							{/* Bookings */}
							{userAuth?.userType === UserTypeEnum.ADMIN ||
							userAuth?.rights?.find(
								(x) => x.type === 'main' && x.name === 'bookings',
							) ? (
								<SubMenu
									key="bookings"
									title={
										<span>
											<i className="icon icon-orders" />
											<span>Bookings</span>
										</span>
									}
								>
									<Menu.Item key={RouteList.jobBoard.path}>
										<Link to={RouteList.jobBoard.path}>
											<i
												className="icon-sweet-alert"
												style={{ marginRight: '12px' }}
											/>
											<span>Unassigned</span>
										</Link>
									</Menu.Item>

									<Menu.Item key={RouteList.bookings.path}>
										<Link to={RouteList.bookings.path}>
											<i
												className="icon-datepicker"
												style={{ marginRight: '12px' }}
											/>
											<span>Bookings</span>
										</Link>
									</Menu.Item>
									{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub' && x.name === 'rework',
									) ? (
										<Menu.Item key={RouteList.rework.path}>
											<Link to={RouteList.rework.path}>
												<i
													className="icon-table"
													style={{ marginRight: '12px' }}
												/>
												<span>Rework</span>
											</Link>
										</Menu.Item>
									) : (
										<></>
									)}
								</SubMenu>
							) : (
								<></>
							)}
							{/* Services */}
							<Menu.Item key={RouteList.services.path}>
								<Link to={RouteList.services.path}>
									<i
										className="icon-product-list"
										style={{ marginRight: '12px' }}
									/>
									<span>Services</span>
								</Link>
							</Menu.Item>

							{/* Team management  */}
							<SubMenu
								key="TeamManagement"
								title={
									<span>
										<i className="icon icon-extra-components" />
										<span>Team Management</span>
									</span>
								}
							>
								<Menu.Item key={RouteList.personnel.path}>
									<Link to={RouteList.personnel.path}>
										<i
											className="icon-ckeditor"
											style={{ marginRight: '12px' }}
										/>
										<span>Personnel</span>
									</Link>
								</Menu.Item>

								<Menu.Item key={RouteList.teams.path}>
									<Link to={RouteList.teams.path}>
										<i
											className="icon-apps-new"
											style={{ marginRight: '12px' }}
										/>
										<span>Teams</span>
									</Link>
								</Menu.Item>
								<Menu.Item key={RouteList.serviceSettings.path}>
									<Link to={RouteList.serviceSettings.path}>
										<i
											className="icon-apps-new"
											style={{ marginRight: '12px' }}
										/>
										<span>Service Settings</span>
									</Link>
								</Menu.Item>
							</SubMenu>

							{/* Payouts */}
							<SubMenu
								key="Payouts"
								title={
									<span>
										<i className="icon icon-extra-components" />
										<span>Payouts</span>
									</span>
								}
							>
								<Menu.Item key={RouteList.payouts.path}>
									<Link to={RouteList.payouts.path}>
										<i
											className="icon-transfer"
											style={{ marginRight: '12px' }}
										/>
										<span>Pending Payouts</span>
									</Link>
								</Menu.Item>

								<Menu.Item key={RouteList.payoutsHistory.path}>
									<Link to={RouteList.payoutsHistory.path}>
										<i
											className="icon-apps-new"
											style={{ marginRight: '12px' }}
										/>
										<span>Payouts History</span>
									</Link>
								</Menu.Item>
								<Menu.Item key={RouteList.payoutSettings.path}>
									<Link to={RouteList.payoutSettings.path}>
										<i
											className="icon-apps-new"
											style={{ marginRight: '12px' }}
										/>
										<span>Payout Settings</span>
									</Link>
								</Menu.Item>
							</SubMenu>

							{/* Manage */}
							{userAuth?.userType === UserTypeEnum.ADMIN ||
							userAuth?.rights?.find(
								(x) => x.type === 'main' && x.name === 'manage',
							) ? (
								<SubMenu
									key="manage"
									title={
										<span>
											<i className="icon icon-extra-components" />
											<span>Manage</span>
										</span>
									}
								>
									{/* Manage Content */}
									{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub' && x.name === 'app-content',
									) ? (
										<Menu.Item key={RouteList.manageApp.path}>
											<Link to={RouteList.manageApp.path}>
												<i
													className="icon-ckeditor"
													style={{ marginRight: '12px' }}
												/>
												<span>App Content</span>
											</Link>
										</Menu.Item>
									) : (
										<></>
									)}

									{/* Manage App Config */}
									{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub' && x.name === 'app-config',
									) ? (
										<Menu.Item key={RouteList.manageAppConfig.path}>
											<Link to={RouteList.manageAppConfig.path}>
												<i
													className="icon-apps-new"
													style={{ marginRight: '12px' }}
												/>
												<span>App Config</span>
											</Link>
										</Menu.Item>
									) : (
										<></>
									)}
								</SubMenu>
							) : (
								<></>
							)}

							{/* Customer Support */}
							{/* <SubMenu
								key="customer-support"
								title={
									<span>
										<i className="icon icon-user" />
										<span>Customer Support</span>
									</span>
								}
							>
								<Menu.Item key={RouteList.customerSupport.path}>
									<Link to={RouteList.customerSupport.path}>
										<i
											className="icon-user-o"
											style={{ marginRight: '12px' }}
										/>
										<span>Customer Support</span>
									</Link>
								</Menu.Item>
							</SubMenu> */}

							{/* Customer Support */}
							{/* <SubMenu
								key="loyalty-points"
								title={
									<span>
										<i className="icon icon-listing-dbrd" />
										<span>Loyalty</span>
									</span>
								}
							>
								<Menu.Item key={RouteList.loyalty.path}>
									<Link to={RouteList.loyalty.path}>
										<i
											className="icon-listing-dbrd"
											style={{ marginRight: '12px' }}
										/>
										<span>Loyalty</span>
									</Link>
								</Menu.Item>
							</SubMenu> */}

							{/* Promotions */}
							{userAuth?.userType === UserTypeEnum.ADMIN ||
							userAuth?.rights?.find(
								(x) => x.type === 'main' && x.name === 'promotions',
							) ? (
								<SubMenu
									key="promotions"
									title={
										<span>
											<i className="icon icon-timeline" />
											<span>Promotions</span>
										</span>
									}
								>
									{/* Coupon Codes */}
									{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub' && x.name === 'coupon-codes',
									) ? (
										<Menu.Item key={RouteList.dupmanageCoupon.path}>
											<Link to={RouteList.dupmanageCoupon.path}>
												<i
													className="icon-tickets"
													style={{ marginRight: '12px' }}
												/>
												<span>Coupon Codes</span>
											</Link>
										</Menu.Item>
									) : (
										<></>
									)}

									{/* Z Points */}
									{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub' && x.name === 'z-points',
									) ? (
										<Menu.Item key={RouteList.zpoints.path}>
											<Link to={RouteList.zpoints.path}>
												<i
													className="icon-ticket-new"
													style={{ marginRight: '12px' }}
												/>
												<span>Z Points</span>
											</Link>
										</Menu.Item>
									) : (
										<></>
									)}
								</SubMenu>
							) : (
								<></>
							)}

							{/* Reports */}
							{userAuth?.userType === UserTypeEnum.ADMIN ||
							userAuth?.rights?.find(
								(x) => x.type === 'main' && x.name === 'reports',
							) ? (
								<SubMenu
									key="reports"
									title={
										<span>
											<i className="icon icon-chart" />
											<span>Reports</span>
										</span>
									}
								>
									{/* Finance */}
									<Menu.Item key={RouteList.reports.path}>
										<Link to={RouteList.reports.path}>
											<i
												className="icon-chart-line"
												style={{ marginRight: '12px' }}
											/>
											<span>Finance</span>
										</Link>
									</Menu.Item>

									{/* Service Partner */}
									<Menu.Item key={RouteList.servicePartners.path}>
										<Link to={RouteList.servicePartners.path}>
											<i
												className="icon-map-street-view"
												style={{ marginRight: '12px' }}
											/>
											<span>Service Partner</span>
										</Link>
									</Menu.Item>

									{/* Jobs */}
									<Menu.Item key={RouteList.jobs.path}>
										<Link to={RouteList.jobs.path}>
											<i
												className="icon-cards-list-view"
												style={{ marginRight: '12px' }}
											/>
											<span>Jobs</span>
										</Link>
									</Menu.Item>
								</SubMenu>
							) : (
								<></>
							)}
							{userAuth?.userType === UserTypeEnum.ADMIN ||
							userAuth?.rights?.find(
								(x) => x.type === 'main' && x.name === 'reviews',
							) ? (
								<Menu.Item key={RouteList.firstBookingExp.path}>
									<Link to={RouteList.firstBookingExp.path}>
										<i className="icon-star" style={{ marginRight: '12px' }} />
										<span>Reviews</span>
									</Link>
								</Menu.Item>
							) : (
								<></>
							)}
							{/* {userAuth?.userType === UserTypeEnum.ADMIN && (
								
							)} */}
							<Menu.Item key={RouteList.support.path}>
								<Link to={RouteList.support.path}>
									<MobileOutlined style={{ marginRight: '2px' }} />
									<span>Support</span>
								</Link>
							</Menu.Item>
							<Menu.Item key={RouteList.notifications.path}>
								<Link to={RouteList.notifications.path}>
									<NotificationOutlined style={{ marginRight: '2px' }} />
									<span>Notifications</span>
								</Link>
							</Menu.Item>

							<Menu.Item onClick={logout}>
								<Link to={'#'}>
									<i
										className="icon-product-list"
										style={{ marginRight: '12px' }}
									/>
									<span>Logout</span>
								</Link>
							</Menu.Item>
						</MenuItemGroup>
					</Menu>
				</div>
			</div>
		</>
	);
};

SidebarContent.propTypes = {};
export default SidebarContent;
