import React, { useState } from 'react';

import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { userTokenAtom } from 'src/lib/recoil/userCoil';
import { EditOutlined,DeleteOutlined ,FileAddOutlined} from '@ant-design/icons';

function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeUpload(file) {
	console.log(file.type);
	const isJpgOrPng =
		file.type === 'image/jpeg' ||
		file.type === 'image/png' ||
		file.type === 'image/svg+xml';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG/SVG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJpgOrPng && isLt2M;
}

interface Props {
	onFinishAdd?: (info: any) => void;
}

// src={`https://staging.api.zimkey.in/media-upload/${qryData?.getService?.icon?.url}`}


interface Props2 {
	serviceIconUrl?: string;
	onFinishAdd?: (info: any) => void;
}

export const UploadPublicImage = ({ onFinishAdd }: Props) => {
	const userToken = useRecoilValue(userTokenAtom);

	const [loading, setLoading] = useState<boolean>(false);
	const [imageUrl, setImageUrl] = useState<any>();
	const uploadButton = (
		<div>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	const handleChange = (info) => {
		if (info.file.status === 'uploading') {
			setLoading(true);
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, (imageUrl) => {
				setLoading(false);
				setImageUrl(imageUrl);
				console.log(info);
				if (typeof onFinishAdd === 'function') {
					onFinishAdd(info);
				}
			});
		}
	};

	return (
		<>
			<Upload
				listType="picture-card"
				className="avatar-uploader"
				showUploadList={false}
				action="https://staging.api.zimkey.in/media-upload/upload-cms"
				beforeUpload={beforeUpload}
				onChange={handleChange}
				headers={{ authorization: userToken ? userToken : '' }}
				name="media"
			>
				{imageUrl ? (
					<img
						src={imageUrl}
						alt="avatar"
						style={{ width: '100%', height: '100%', objectFit: 'contain' }}
					/>
				) : (
					uploadButton
				)}
			</Upload>
		</>
	);
};

export const UploadPublicImage2 = ({ serviceIconUrl, onFinishAdd }: Props2) => {
	const userToken = useRecoilValue(userTokenAtom);

	const [loading, setLoading] = useState<boolean>(false);
	const [imageUrl, setImageUrl] = useState<any>();
	const uploadButton = (
		// <div>
		// 	{loading ? <LoadingOutlined /> : <PlusOutlined />}
		// 	<div style={{ marginTop: 8 }}>Upload</div>
		// </div>

		<button
																	style={{
																		borderRadius: '50%',
																		border: 'none',
																		backgroundColor: '#f96d38',
																		color: 'white',
																		position: 'absolute',
																		bottom: '20px', // Adjust this value to move the button up or down
																		left: '50%',
																		transform: 'translateX(-164%)',
																		cursor: 'pointer'
																	}}
																>
																	<EditOutlined />
																</button>
	);

	const handleChange = (info) => {
		if (info.file.status === 'uploading') {
			setLoading(true);
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, (imageUrl) => {
				setLoading(false);
				setImageUrl(imageUrl);
				console.log(info);
				if (typeof onFinishAdd === 'function') {
					onFinishAdd(info);
				}
			});
		}
	};

	return (
		<>
			<Upload
				listType="picture-card"
				className="avatar-uploader"
				showUploadList={false}
				action="https://staging.api.zimkey.in/media-upload/upload-cms"
				beforeUpload={beforeUpload}
				onChange={handleChange}
				headers={{ authorization: userToken ? userToken : '' }}
				name="media"
			>
				{imageUrl ? (
					<>
					<img
						src={imageUrl}
						alt="avatar"
						style={{ width: '100%', height: '100%', objectFit: 'contain' }}
					/>
					<>
					{uploadButton}
					</>
					</>
				) : 
				serviceIconUrl ? (
					<>
					<img
						src={serviceIconUrl}
						alt="avatar"
						style={{ width: '100%', height: '100%', objectFit: 'contain' }}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src="https://iconicentertainment.in/wp-content/uploads/2013/11/dummy-image-square.jpg";
						  }}
					/>
					<>
					{uploadButton}
					</>
					</>
				) : (
					uploadButton
				)}
			</Upload>
		</>
	);
};

export const UploadPublicImage3 = ({ serviceIconUrl, onFinishAdd }: Props2) => {
	const userToken = useRecoilValue(userTokenAtom);

	const [loading, setLoading] = useState<boolean>(false);
	const [imageUrl, setImageUrl] = useState<any>();
	const uploadButton = (
		// <div>
		// 	{loading ? <LoadingOutlined /> : <PlusOutlined />}
		// 	<div style={{ marginTop: 8 }}>Upload</div>
		// </div>

	<button
		style={{
			borderRadius: '50%',
			border: 'none',
			backgroundColor: '#f96d38',
			color: 'white',
			position: 'absolute',
			bottom: '380px',
			left: '90%',
			transform: 'translateX(-164%)',
			cursor: 'pointer'
		}}
	>
		<EditOutlined />
	</button>
	);

	const handleChange = (info) => {
		if (info.file.status === 'uploading') {
			setLoading(true);
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, (imageUrl) => {
				setLoading(false);
				setImageUrl(imageUrl);
				console.log(info);
				if (typeof onFinishAdd === 'function') {
					onFinishAdd(info);
				}
			});
		}
	};

	return (
		<>
			<Upload
				listType="picture-card"
				className="avatar-uploader"
				showUploadList={false}
				action="https://staging.api.zimkey.in/media-upload/upload-cms"
				beforeUpload={beforeUpload}
				onChange={handleChange}
				headers={{ authorization: userToken ? userToken : '' }}
				name="media"
			>
				{imageUrl ? (
					<>
					<img
						src={imageUrl}
						alt="avatar"
						style={{ width: '100%', height: '100%', objectFit: 'contain' }}
					/>
					<>
					{uploadButton}
					</>
					</>
				) : 
				serviceIconUrl ? (
					<>
					<img
						src={serviceIconUrl}
						alt="avatar"
						style={{ width: '100%', height: '100%', objectFit: 'contain' }}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src="https://iconicentertainment.in/wp-content/uploads/2013/11/dummy-image-square.jpg";
						  }}
					/>
					<>
					{uploadButton}
					</>
					</>
				) : (
					uploadButton
				)}
			</Upload>
		</>
	);
};
