import { Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userTokenAtom } from 'src/lib/recoil/userCoil';

interface Props {
	url: string;
}

const ImageAuth: React.FC<Props> = ({ url}) => {
	const [blob, setBlob] = useState<string>('');
	const [blob2, setBlob2] = useState<string>('');
	const [imageIn, setImageIn] = useState<any>(false);

	const userToken = useRecoilValue(userTokenAtom);

	useEffect(() => {
		const src = url;
		const options = {
			headers: {
				authorization: userToken ? userToken : '',
			},
		};

		fetch(src, options)
			.then((res) => res.blob())
			.then((blob) => {
				setBlob(URL.createObjectURL(blob));
			});

			
	}, []);

	// if (!blob) {
	// 	return <p>Loading...</p>;
	// }

	return (
		<>
		<Image.PreviewGroup>
		<Image width={50} height={50} src={blob} style={{borderRadius:"25px"}}/> 
		</Image.PreviewGroup>
		</>
	);
	
	
};

export default ImageAuth;
