import { useMutation, useQuery } from '@apollo/client';
import {
  Card,
  Col,
  Input,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Button,
  Checkbox,
  message 
} from 'antd';
import { log } from 'console';
import React, { useState, useEffect } from 'react';
import { CMSQueryGetZPoints, UPDATE_ZPOINTS_MUTATION } from 'src/lib/gql/queries/promotions';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { userAtom } from 'src/lib/recoil/userCoil';
import { useRecoilValue } from 'recoil';

// Name, Bookings, Address, Account status, action

const Zpoints: React.FC = () => {
  const [check1, setCheck1] = useState(true);
  const [check2, setCheck2] = useState(true);
  const [isDisabled1, setIsDisabled1] = useState(false);
  const [isDisabled2, setIsDisabled2] = useState(false);
  const [conversionRate, setConversionRate] = useState<any>();
  const [minOrderValue, setMinOrderValue] = useState<any>();
  const [maxRedemption, setMaxRedemption] = useState<any>();
	const userAuth = useRecoilValue(userAtom);
  const [ReworkMinimum, setReworkMInimum] = useState<any>();
  const [RewardedAmt, setRewardedAmt] = useState<any>();
  const [errorNegative, setErrorNegative] = useState(false);
  const [errorNegativeReward, setErrorNegativeReward] = useState(false);


  const validateRedemption  = ( value) => {
    console.log("value",value);
    if (isNaN(value) || Number(value) < 0) {
      setErrorNegative(true);
    } else {
      setErrorNegative(false);
      setMaxRedemption(value);
    }
  }
    
  const validateRewareded = ( value) => {
    console.log("value",value);
    if (isNaN(value) || Number(value) < 0) {
      setErrorNegativeReward(true);
    } else {
      setErrorNegativeReward(false);
      setRewardedAmt(value);
    }
  }
  // get data
  const {
    data: qryData,
    loading,
    called,
    error,
    refetch: refetchQryData,
  } = useQuery(CMSQueryGetZPoints);

  // mutation update data

  const [
	ZPointsUpdate,
	{ data: dataMtU, loading: loadingMtU, error: errorMtU },
] = useMutation(
	UPDATE_ZPOINTS_MUTATION,
  {
		onCompleted: () => {
			refetchQryData();
		},
	}
);

  useEffect(() => {
    if (qryData?.getZPoints?.conversionRate !== undefined) {
      setConversionRate(qryData.getZPoints.conversionRate);
    }
    if (qryData?.getZPoints?.minOrderValue == 'No limit') {
      setMinOrderValue('No limit');
      setCheck1(true);
	    setIsDisabled1(true);
    }else{
		setMinOrderValue(qryData?.getZPoints?.minOrderValue);
		setCheck1(false);
		setIsDisabled1(false);
	}
  //   if (qryData?.getZPoints?.maxRedemptionOnOrder == 'No limit') {
	// 	setMaxRedemption('No limit');
	// 	setCheck2(true);
	// 	setIsDisabled2(true);
	// }else{
		setMaxRedemption(qryData?.getZPoints?.maxRedemptionOnOrder);
	// 	setCheck2(false);
	// 	setIsDisabled2(false);
	// }
  setRewardedAmt(qryData?.getZPoints?.rewardValue)
      if (qryData?.getZPoints?.minOrderValueForReward == 'No limit') {
        setReworkMInimum('No limit');
		setCheck2(true);
		setIsDisabled2(true);
	}else{
		setReworkMInimum(qryData?.getZPoints?.minOrderValueForReward);
		setCheck2(false);
		setIsDisabled2(false);
	}
  }, [qryData]);

  const handleCheck = (name: number) => () => {
    name === 1 ? setCheck1(!check1) : setCheck2(!check2);
    name === 1 ? setIsDisabled1(!isDisabled1) : setIsDisabled2(!isDisabled2);
  };

  const handleSave = () => {
	
	let updatedMinOrderValue = minOrderValue;
	let updatedmaxRedemptionOnOrder = ReworkMinimum;
	if (check1) {
	  updatedMinOrderValue = "No limit";
	}
	if (check2) {
		updatedmaxRedemptionOnOrder = "No limit";
	  }
	// console.log('myyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy',qryData?.getZPoints?.id,conversionRate,minOrderValue,maxRedemption)
	// console.log("aa",updatedMinOrderValue,updatedmaxRedemptionOnOrder)
    ZPointsUpdate({
      variables: {
        data: {
			// id:qryData?.getZPoints?.id,
          conversionRate: parseInt(conversionRate) ,
          minOrderValue: updatedMinOrderValue,
          maxRedemptionOnOrder:maxRedemption,
          minOrderValueForReward:updatedmaxRedemptionOnOrder,
          rewardValue:RewardedAmt
        },
      },
    })
	
	.then((result) => {
        // Handle the result if needed
        message.success('Update successful'); // Display success message
      })
      .catch((error) => {
        // Handle errors if any
		message.error('error'); // Display success message
      });
  };

  return (
    <Card title="Zpoints" extra={
      <>
      {userAuth?.userType === UserTypeEnum.ADMIN ||
					userAuth?.rights?.find(
						(x) => x.type === 'sub2' && x.name === 'edit-zpoints',
					) ? (
      <Row style={{ textAlign: 'end' }}>
        <Col
          xl={24}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className="gx-audi-col"
          style={{ textAlign: 'end' }}
        >
          <Button className="gx-ml-2 gx-mb-0" type="primary" onClick={handleSave}>
        Save
      </Button>
      </Col>
    </Row>
          ):("")}
      </>
    }>

      

  {loading && (
    <Row justify="center">
      <Space size="middle">
        <Spin size="large" />
      </Space>
    </Row>
  )}

  <Row className="gx-mt-4">
    <Col span={24} className="gx-mb-4">
      <Card className={`gx-card-widget`}>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <span>Zpoints conversion rate</span>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <div style={{ display: 'flex' }}>
              <Input
                type="number"
                style={{ width: '100px', marginRight: '1rem' }}
                value={conversionRate}
                onChange={(e) => setConversionRate((e.target.value))}
              />
              <span>Paisa</span>
            </div>
          </Col>
        </Row><br/>
        <h2>Redemption Settings</h2>
          <hr/>
        <Row className="gx-mt-3 gx-mb-3">
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <span>Minimum order value for Zpoints redemption</span>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <div style={{ display: 'flex' }}>
              <Checkbox checked={check1} onClick={handleCheck(1)}>
                No Limit
              </Checkbox>
              <Input
                disabled={isDisabled1}
                type="number"
                style={{ width: '100px', marginRight: '1rem', marginLeft: '1rem' }}
                value={minOrderValue}
                onChange={(e) => setMinOrderValue((e.target.value))}
              />
              <span>Rupees</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <span>Maximum redemption of Zpoints in an order</span>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <div style={{ display: 'flex' }}>
              {/* <Checkbox checked={check2} onClick={handleCheck(2)}>
                No Limit
              </Checkbox> */}
              <Input
                // disabled={isDisabled2}
                // type="number"
                value={maxRedemption}
                onChange={(e) => validateRedemption((e.target.value))}
                style={{ width: '100px', marginRight: '1rem', marginLeft: '1rem' }}
              />
              <span>% of partner price</span>
            </div>
            {errorNegative && <span style={{color:"red"}}>Please enter a non-negative number.
                </span>}
          </Col>
        </Row><br/>
        <h2>Reward Settings</h2>
          <hr/>
          <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <span>Minimum order value for Zpoints reward</span>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <div style={{ display: 'flex' }}>
              <Checkbox checked={check2} onClick={handleCheck(2)}>
                No Limit
              </Checkbox>
              <Input
                disabled={isDisabled2}
                type="number"
                value={ReworkMinimum}
                onChange={(e) => setReworkMInimum((e.target.value))}
                style={{ width: '100px', marginRight: '1rem', marginLeft: '1rem' }}
              />
              <span>Rupees</span>
            </div>
          </Col>
        </Row><br/>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <span>How much Zponits will rewarded</span>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <div style={{ display: 'flex' }}>
              {/* <Checkbox checked={check2} onClick={handleCheck(2)}>
                No Limit
              </Checkbox> */}
              <Input
                // disabled={isDisabled2}
                // type="number"
                value={RewardedAmt}
                onChange={(e) => validateRewareded((e.target.value))}
                style={{ width: '100px', marginRight: '1rem', marginLeft: '1rem' }}
              />
              <span>% of partner price</span>
            </div>
            {errorNegativeReward && <span style={{color:"red"}}>Please enter a non-negative number.
                </span>}
          </Col>
        </Row>
      </Card>
    </Col>
  </Row>

</Card>

);
};
export default Zpoints;