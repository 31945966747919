import { gql } from '@apollo/client';

export const CMSQueryGetAppConfig = gql`
	query CMSGetAppConfig($appModule: String!) {
		getAppConfig(appModule: $appModule) {
			androidVersion
            androidAppPath
            androidMMode
            iosVersion
            iosAppPath
            iosMMode
            appModule
            iosMMode
            androidMMode
		}
	}
`;
