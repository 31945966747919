import {
	InboxOutlined,
	MinusCircleOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import {
	AutoComplete,
	Button,
	Card,
	Col,
	Form,
	Input,
	Row,
	Select,
	Upload,
} from 'antd';
import React, { useState } from 'react';
import CKEditor from 'react-ckeditor-component';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 4 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
};

const normFile = (e) => {

	if (Array.isArray(e)) {
		return e;
	}

	return e && e.fileList;
};
const formItemLayoutWithOutLabel = {
	wrapperCol: {
		xs: { span: 24, offset: 0 },
		sm: { span: 20, offset: 4 },
	},
};

const OnboardingScreens: React.FC = () => {
	const [form] = Form.useForm();

	const onFinish = (values) => {
		console.log('Received values of form: ', values);
	};

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select style={{ width: 70 }} getPopupContainer={(trigger) => trigger.parentElement}>
				<Option value="86">+86</Option>
				<Option value="87">+87</Option>
			</Select>
		</Form.Item>
	);

	const [autoCompleteResult, setAutoCompleteResult] = useState([]);
	const [content, setcontent] = useState(
		'<h2>Awesome Rich Content</h2>\n' +
			'<p>Suspendisse id sollicitudin dui. <strong>Vestibulum eu sapien pharetra,</strong> bibendum ligula id, ullamcorper ligula.</p>\n' +
			'\n' +
			'<ul>\n' +
			'        <li>ullamcorper ligula</li>\n' +
			'        <li>Duis vel neque</li>\n' +
			'</ul>\n' +
			'\n' +
			'<p><em>Sed feugiat hendrerit risus, quis efficitur massa facilisis vitae. Aliquam erat volutpat. </em></p>\n',
	);

	const [enableHelper, setEnableHelper] = useState(false);

	const websiteOptions = autoCompleteResult.map((website) => ({
		label: website,
		value: website,
	}));

	const navigate = useNavigate();

	return (
		<Card title="Manage onboarding screens">
			<div className="gx-flex gx-flex-nowrap gx-mb-2">
				<div className="gx-card-profile">
					<div className="ant-card-head ">
						<Button className="gx-mr-4" onClick={() => navigate(-1)}>
							Back
						</Button>

						{/* <span className="ant-card-head-title gx-mb-1">Bookings</span> */}
						{/* <p className="gx-text-grey gx-fs-sm gx-mb-0">Booking List</p> */}
					</div>

					<div className="gx-pt-md-3"></div>
				</div>

				<Row>
					<Col span={24} className="gx-mb-4">
						<h1>Manage onboarding screens</h1>
						<hr />
					</Col>
					<Col span={24} className="gx-mb-4">
						<Form
							{...formItemLayout}
							form={form}
							name="register"
							onFinish={onFinish}
							initialValues={{
								category: 'Home Services',
							}}
							scrollToFirstError
						>
							{/* Fields */}
							<h3>Add Slides</h3>
							<Form.List name="names">
								{(fields, { add, remove }) => {
									return (
										<div>
											{fields.map((field, index) => (
												<Form.Item
													{...formItemLayout}
													label={`Slide: ${index + 1}`}
													required={false}
													key={field.key}
												>
													<Form.Item
														name="title"
														label={''}
														rules={[
															{
																required: true,
																message: 'Please enter title!',
																whitespace: true,
															},
														]}
													>
														<Input placeholder="Title" />
													</Form.Item>

													<Form.Item label="">
														<Form.Item
															name="dragger"
															valuePropName="fileList"
															getValueFromEvent={normFile}
															noStyle
														>
															<Upload.Dragger name="files" action="/upload.do">
																<p className="ant-upload-drag-icon">
																	<InboxOutlined />
																</p>
																<p className="ant-upload-text">
																	Click or drag icon to this area to upload
																</p>
															</Upload.Dragger>
														</Form.Item>
													</Form.Item>

													{fields.length > 1 ? (
														<MinusCircleOutlined
															className="dynamic-delete-button"
															style={{ margin: '0 8px' }}
															onClick={() => {
																remove(field.name);
															}}
														/>
													) : null}

													<h3>Description: </h3>
													<CKEditor
														activeClass="p10"
														content={content}
														events={{
															blur: (evt) =>
																console.log(
																	'onBlur event called with event info: ',
																	evt,
																),
															afterPaste: (evt) =>
																console.log(
																	'afterPaste event called with event info: ',
																	evt,
																),
															change: (evt) => setcontent(evt.editor.getData()),
														}}
													/>
												</Form.Item>
											))}
											<Form.Item>
												<Button
													type="dashed"
													onClick={() => {
														add();
													}}
													style={{ width: '100%' }}
												>
													<PlusOutlined /> Add Slide
												</Button>
											</Form.Item>
										</div>
									);
								}}
							</Form.List>

							<hr />
							<Form.Item {...tailFormItemLayout}>
								<Button type="primary" htmlType="submit">
									Add
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</div>
		</Card>
	);
};
export default OnboardingScreens;
