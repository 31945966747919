import React, { useState ,useEffect} from 'react';
import { Modal, Button, Row, Col, Alert } from 'antd';
import { Form, Input, InputNumber  } from 'antd';
import { useMutation } from '@apollo/client';
import { CREATE_SERVICE_PROPERTY_AREA,UPDATE_SERVICE__PROPERTY_AREA,DELETE_SERVICE__PROPERTY_AREA } from 'src/lib/gql/mutations/mutations';
import { EditOutlined,DeleteOutlined } from '@ant-design/icons';

interface Props {
	serviceID: string;
	input?: any;
	onFinishAdd?: () => void;
}

const AddPropertyArea = ({ serviceID, input, onFinishAdd }: Props) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [updateAlert, setUpdateAlert] = useState(false)
	const [createAlert, setCreateAlert] = useState(false)
	const [hours, setHours] = useState(0)

	useEffect(()=>{
		// Update the form fields with the new data
        console.log("input",input);
        
		if(input){
		form.setFieldsValue({
			serviceId: serviceID,
			titile: input?.titile,
			description: input?.description,
		});
        setHours(input?.hours)
	}
	},[input])

	// Create
	const [
		createServiceInputOption,
		{ data: dataMt, loading: loadingMt, error: errorMt },
	] = useMutation(
		CREATE_SERVICE_PROPERTY_AREA,{
			onCompleted: () => {
				setIsModalVisible(false);
				onFinishAdd
			form.resetFields();
            setHours(0)
			},
		}
	);

	// Update
	const [
		updateServiceInputOption,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation(
		UPDATE_SERVICE__PROPERTY_AREA,{
			onCompleted: () => {
				setIsModalVisible(false);
				onFinishAdd
			},
		}
	);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
		form.resetFields()
		setIsModalVisible(false);
	};

	const [form] = Form.useForm();

	const onFinish = (values) => {

		if (!input) {
			createServiceInputOption({
				variables: {
					data: {
						serviceId: serviceID,
						title: values.title,
                        hours:hours,
						description: values.description,
					
					},
				},
			}).then(() => {
				form.resetFields();
				setCreateAlert(true)
                setHours(0)
				setTimeout(() => {
				setCreateAlert(false)
				}, 2000)
			
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
					}
					setIsModalVisible(false);
				}, 1500);
			});
		} else {
			updateServiceInputOption({
				variables: {
                    data: {
                        id: input.id,
						title: values.title,
                        hours:hours,
						description: values.description,
                        serviceId: serviceID,
					},
				},
			}).then(() => {
				form.resetFields();
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
					}
					setIsModalVisible(false);
				}, 1500);
                setUpdateAlert(true)
                setTimeout(() => {
                setUpdateAlert(false)
                }, 2000)
			});
		}
	};

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	// Delete
	const [
		deleteServiceInputOption,
		{ data: dataMtD, loading: loadingMtD, error: errorMtD },
	] = useMutation(
		DELETE_SERVICE__PROPERTY_AREA,
	);
	const onDelete = () => {
		deleteServiceInputOption({
			variables: {
				id: input.id,
			},
		}).then(() => {
			setTimeout(() => {
				if (typeof onFinishAdd === 'function') {
					onFinishAdd();
				}
				setIsDeleteModalVisible(false);
			}, 1500);
		});
	};
	const validateReworkGracePeriod = (_, value) => {
		if (isNaN(value) || Number(value) < 0) {
		  return Promise.reject('Please enter a non-negative number.');
		}
		return Promise.resolve();
	  };

      const handleHours=(value)=>{
        // console.log("1",value);
        setHours(value)
      }

	return (
		<>
			{/* <Button type="primary" onClick={showModal}>
				{!input ? 'Add Service Add-ons' : <EditOutlined/>}
			</Button> */}
			<Button
				type="primary"
				onClick={showModal}
				style={
					input
						? {
								background: 'transparent',
								color: '#5a20ff',
								border: 'none',
								boxShadow: 'none',
						  }
						: {}
				}
			>{!input ? 'Add Property Area' : <EditOutlined />}
			</Button>
			<Modal
				title={!input ? 'Add Property Area' : 'Edit Property Area'}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
			>
				{/* Creating */}
				{createAlert && !loadingMt  && <Alert message="Created" type="success" showIcon />}
				{loadingMt && <Alert message="Submitting..." type="info" showIcon />}
				{errorMt && (
					<Alert
						message={`Submission error! ${errorMt.message}`}
						type="error"
						showIcon
					/>
				)}

				{/* Updating */}
				{updateAlert &&  !loadingMtU && <Alert message="Updated" type="success" showIcon />}
				{loadingMtU && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtU && (
					<Alert
						message={`Submission error! ${errorMtU.message}`}
						type="error"
						showIcon
					/>
				)}
				<Form
					form={form}
					name="addServiceInput"
					onFinish={onFinish}
					scrollToFirstError
					initialValues={
						!input
							? {}
							: {
									serviceId: serviceID,
									title: input.title,
									description: input.description,
								}
					}
				>
					<Row>
						<Col span={24}>
						<Form.Item
								name={`title`}
								label={<span>Title</span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
                            <Form.Item
								// name={`hours`}
								label={<span>Hours</span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<InputNumber min={1} max={24} value={hours} onChange={handleHours} />
							</Form.Item>
                            <Form.Item
								name={`description`}
								label={<span>Description</span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			{input && (
				<Button
					type="text"
					onClick={() => setIsDeleteModalVisible(true)}
					danger
				>
					<DeleteOutlined/>
				</Button>
			)}
			<Modal
				title={'Delete'}
				visible={isDeleteModalVisible}
				onOk={onDelete}
				onCancel={() => setIsDeleteModalVisible(false)}
				width={300}
			>
				Are you want to delete Service Property Area?
				{/* Delete */}
				{dataMtD && <Alert message="Deleted" type="success" showIcon />}
				{loadingMtD && <Alert message="Deleting..." type="info" showIcon />}
				{errorMtD && (
					<Alert
						message={`Submission error! ${errorMtD.message}`}
						type="error"
						showIcon
					/>
				)}
			</Modal>
		</>
	);
};
export default AddPropertyArea;
