import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './assets/vendors/style.ts';
import ApolloHOC from './hoc/apolloClient';
import RecoilHOC from './hoc/recoil';
import './index.less';
import reportWebVitals from './reportWebVitals';
import Root from './root';
import * as Sentry from "@sentry/react";

// Sentry.init({
// 	dsn: "https://319ea94a320e4207b4beeb0709c9abcc@o472685.ingest.sentry.io/4505187817947136",
// 	integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
// 	// Performance Monitoring
// 	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
// 	// Session Replay
// 	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });

ReactDOM.render(
	<React.StrictMode>
		<RecoilHOC>
			<ApolloHOC>
				<BrowserRouter>
					<Root />
				</BrowserRouter>
			</ApolloHOC>
		</RecoilHOC>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


