import React, { useState,useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
	Alert,
	Button,
	Col,
	Form,
	Input,
	InputNumber,
	Modal,
	Row,
	Switch,Select,Space
} from 'antd';
import { DatePicker } from 'antd';
import moment from 'moment';
import {
	CMSCreateCoupon,
	CMSCreateCouponVariables,
} from 'src/lib/gql/generated/CMSCreateCoupon';
import { CMSGetCoupons_getCoupons } from 'src/lib/gql/generated/CMSGetCoupons';
import { CREATE_COUPON, UPDATE_COUPON } from 'src/lib/gql/mutations/mutations';
import {
	CMSUpdateCoupon,
	CMSUpdateCouponVariables,
} from 'src/lib/gql/generated/CMSUpdateCoupon';
import {EditOutlined } from '@ant-design/icons';
import { DiscountTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { useQuery } from '@apollo/client';
import { CMSGetServiceAreas } from 'src/lib/gql/generated/CMSGetServiceAreas';
import { CMSQueryGetServiceAreas } from 'src/lib/gql/queries/services';
import { CMSQueryGetAllServiceCategories } from 'src/lib/gql/queries/services';
import { CMSGetAllServiceCategories } from 'src/lib/gql/generated/CMSGetAllServiceCategories';
import { CMSGetServices, CMSGetServicesVariables } from 'src/lib/gql/generated/CMSGetServices';
import { CMSQueryGetServices } from 'src/lib/gql/queries/services';
import { CMSQueryGetCities } from 'src/lib/gql/queries';
import CKEditor from 'react-ckeditor-component';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import isEqual from 'lodash.isequal';


interface Props {
	input?: CMSGetCoupons_getCoupons;
	onFinishAdd?: () => void;
}

const AddCouponCode: React.FC<Props> = ({ input, onFinishAdd }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [updateAlert, setUpdateAlert] = useState(false)
	const [createAlert, setCreateAlert] = useState(false)
	const [statusValue, SetStatusValue] = useState<any>()
	const [providerValue, SetProviderValue] = useState<any>()
	const [areaValue, SetAreaValue] = useState<any>([])
	const [citiesValue, SetCitiesValue] = useState<any>()
	const [serviceValue, SetServiceValue] = useState<any>()
	const [serviceCateValue, SetServiceCateValue] = useState<any>()
	const [categoryService, SetCategoryService] = useState<any>()
	const { Option } = Select;
	const [contentTerms, setcontentTerms] = useState<any>()
	const [contentDec, setcontentDec] = useState<any>()
	dayjs.extend(customParseFormat);
	const { RangePicker } = DatePicker;
	const [startDate, setStartDate] = useState<any>("")
	const [endDate, setEndDate] = useState<any>("")
	const [enabled, setEnabled] = useState<any>("")
	const [serachVal, setSerachVal] = useState<any>("")
	const [dateVal, setDateVal] = useState<any>([])
	const [termsValidation,setTermsValidation] = useState<any>(false)



	// Get all  area
	const {
		data: qryDataArea,
		refetch: refetchQryDatas,
	} = useQuery<CMSGetServiceAreas>(CMSQueryGetServiceAreas, {
		errorPolicy: 'all',
	});
	// Get all services category
	const {
		data: qryDataServiceCate,
		refetch: refetchQryDataServiceCate,
	} = useQuery<CMSGetAllServiceCategories>(CMSQueryGetAllServiceCategories);
	// Get all services
	const {
		data: qryDataService,
		refetch: refetchQryDataService,
	} = useQuery<CMSGetServices, CMSGetServicesVariables>(CMSQueryGetServices, {
		variables: {
			showAll: true,
			search: '',
		},
		errorPolicy: 'all',
	});
	// Get all cities
	const {
		data: qryDataCities,
		refetch: refetchQryDataCities,
	} = useQuery(CMSQueryGetCities);

	const handleArea = (value) => {
		SetAreaValue(value)
	}
	const handleCities = (value) => {
		// console.log("123",value);
		SetCitiesValue(value)
	}
	const handleService = (value) => {
		SetServiceValue(value)
		if(value?.length===0){
			form.setFieldsValue({
				name: input?.name,
				description: input?.description,
				terms: input?.terms,
				code: input?.code,
				value: input?.value,
				maxUsePerCustomer: input?.maxUsePerCustomer,
				minOrder: input?.minOrder,         
				discountType: input?.discountType,        
				usageLimit: input?.usageLimit, 
				// areas:area,
				// cities:city,
				serviceCategories:[],
				// services:service
		});
		}
	}
	const handleServiceCate = (value) => {
		// console.log("123",value);
		SetServiceCateValue(value)
	}
	const validateMinOrder = (_, value) => {
		if (!value) {
		  return Promise.reject('Minimum order value is required');
		}
		const numericValue = Number(value);
		if (isNaN(numericValue) || numericValue < 0) {
		  return Promise.reject('Minimum order value must be a non-negative number');
		}
		return Promise.resolve();
	  };
	useEffect(() => {
		const daaaa=qryDataServiceCate?.getServiceCategories?.filter((value) => value.id ===serviceCateValue?.find((value) => value))
				SetCategoryService(daaaa)
			// SetCategoryService([])
		if(serviceCateValue?.length<1){
			SetServiceValue([])
		}
		},
	[serviceCateValue])

	const editorConfig = {
		// Customize options here
		toolbar: [
			'/',
			{
				name: 'basicstyles',
				items: [
					'Bold',
					'Italic',
					'Underline',
					'Strike',
					'Subscript',
					'Superscript',
					'-',
					'RemoveFormat',
				],
			},
			{
				name: 'paragraph',
				items: [
					'NumberedList',
					'BulletedList',
					'-',
					'CreateDiv',
					'-',
					'JustifyLeft',
					'JustifyCenter',
					'JustifyRight',
					'JustifyBlock',
				],
			},
			'/',
			{ name: 'others', items: ['-'] },
		],
		// ...
	};

	useEffect(()=>{
		const area: Array<any> = [];
		if(input?.areas ){
			input?.areas.forEach((item, index) => {
				area.push(item?.id);
				//  da.push(item?.id);
			});
				SetAreaValue(area);
		}
	
		// setDateVal([input?.startDate,input?.endDate])
		setEnabled(input?.enabled) 
		setcontentDec(input?.description)
		setcontentTerms(input?.terms)
		setStartDate(input?.startDate)
		setEndDate(input?.endDate)

		const city: Array<any> = [];
		if(input?.cities){
			input?.cities.forEach((item, index) => {	
				city.push(item?.id);
			});
				SetCitiesValue(city);
		}
		const service: Array<any> = [];
		if(input?.services){
			input?.services.forEach((item, index) => {	
				service.push(item?.id);
			});
				SetServiceValue(service);
		}
		const serviceCategory: Array<any> = [];
		if(input?.serviceCategories){
			input?.serviceCategories.forEach((item, index) => {	
				serviceCategory.push(item?.id);
			});
				SetServiceCateValue(serviceCategory);
		}
	
		form.setFieldsValue({
			name: input?.name,
			description: input?.description,
			terms: input?.terms,
			code: input?.code,
			value: input?.value,
			maxUsePerCustomer: input?.maxUsePerCustomer,
			minOrder: input?.minOrder,         
			discountType: input?.discountType,        
			usageLimit: input?.usageLimit, 
			areas:area,
			cities:city,
			serviceCategories:serviceCategory,
			services:service
	});

	},[input]) 

	const handleClick =(e)=>{
		setEnabled(e)
	}
	// Create
	const [createCoupon, { loading,data: dataMt, loading: loadingMt, error: errorMt }] =
		useMutation<CMSCreateCoupon, CMSCreateCouponVariables>(CREATE_COUPON
			,{
				onCompleted: () => {
					onFinishAdd
					form.resetFields();
				},
			});

	// Update
	const [
		updateCoupon,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation<CMSUpdateCoupon, CMSUpdateCouponVariables>(UPDATE_COUPON
		,{
			onCompleted: () => {
				onFinishAdd
			},
		}
		);

	const showModal = () => {
		setIsModalVisible(true);
		setStartDate(null)
		setEndDate(null)
		SetAreaValue([])
		SetCitiesValue([])
		SetServiceValue([])
		SetServiceCateValue([])
	};

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
		// form.resetFields()
		setIsModalVisible(false);
		const area: Array<any> = [];
		if(input?.areas ){
			input?.areas.forEach((item, index) => {
				area.push(item?.id);
				//  da.push(item?.id);
			});
				SetAreaValue(area);
		}
	
		// setDateVal([input?.startDate,input?.endDate])
		setEnabled(input?.enabled) 
		setcontentDec(input?.description)
		setcontentTerms(input?.terms)
		setStartDate(input?.startDate)
		setEndDate(input?.endDate)

		const city: Array<any> = [];
		if(input?.cities){
			input?.cities.forEach((item, index) => {	
				city.push(item?.id);
			});
				SetCitiesValue(city);
		}
		const service: Array<any> = [];
		if(input?.services){
			input?.services.forEach((item, index) => {	
				service.push(item?.id);
			});
				SetServiceValue(service);
		}
		const serviceCategory: Array<any> = [];
		if(input?.serviceCategories){
			input?.serviceCategories.forEach((item, index) => {	
				serviceCategory.push(item?.id);
			});
				SetServiceCateValue(serviceCategory);
		}
	
		form.setFieldsValue({
			name: input?.name,
			description: input?.description,
			terms: input?.terms,
			code: input?.code,
			value: input?.value,
			maxUsePerCustomer: input?.maxUsePerCustomer,
			minOrder: input?.minOrder,         
			discountType: input?.discountType,        
			usageLimit: input?.usageLimit, 
			areas:area,
			cities:city,
			serviceCategories:serviceCategory,
			services:service
	});
	};
	const onSearch = (value) => {
		setSerachVal(value)
	  };

	const [form] = Form.useForm();

	const onFinish = (values) => {
		console.log("stat");
		
		if (!contentTerms){
			console.log("in");
			
			setTermsValidation(true)
			return 
		}
		console.log("end");

		if (!input) {
			createCoupon({
				variables: {
					data: {
						name: values.name,
						description:contentDec ? contentDec:"",
						terms: contentTerms,
						code: values.code,
						startDate:  startDate ?startDate :"" ,
						endDate: endDate ? endDate:"",
						value: parseFloat(values.value),
						maxUsePerCustomer: parseFloat(values.maxUsePerCustomer),
						minOrder: parseFloat(values.minOrder),
						serviceBillingOptions: [],
						customers: [],
						// enabled:  enabled,
						discountType: values.discountType,
						usageLimit: values.usageLimit,
						areaIds:areaValue ?areaValue :values.areas,
						cityIds:citiesValue?citiesValue :values.cities,
						serviceIds:serviceValue?serviceValue :values.services,
						serviceCategoryIds:serviceCateValue?serviceCateValue :values.serviceCategories,
					},
				},
				
			})
				.then(() => {
					form.resetFields();
					setTermsValidation(false)
					onFinishAdd
					setTimeout(() => {
						if (typeof onFinishAdd === 'function') {
							onFinishAdd();
						}
						setIsModalVisible(false);
					}, 1500);
					setCreateAlert(true)
					setTimeout(() => {
					setCreateAlert(false)
					setStartDate(null)
					setEndDate(null)
					}, 2000)
				})
				.catch((e) => console.log(e));
		} else {
			updateCoupon({
				variables: {
					id: input.id,
					data: {
						name: values.name,
						description: contentDec,
						terms:contentTerms,
						code: values.code,
						startDate: startDate,
						endDate: endDate,
						value: parseFloat(values.value),
						maxUsePerCustomer: parseFloat(values.maxUsePerCustomer),
						minOrder: parseFloat(values.minOrder),
						serviceBillingOptions: [],
						customers: [],
						enabled: enabled,
						discountType: values.discountType,
						usageLimit: parseFloat(values.usageLimit),
						areaIds:values.areas,
						cityIds:citiesValue?.length ? citiesValue :values.cities,
						serviceIds:serviceValue?.length ? serviceValue :values.services,
						serviceCategoryIds:serviceCateValue?.length ? serviceCateValue :values.serviceCategories,
					},
				},
			}).then(() => {
				// form.resetFields();
				setTermsValidation(false)
				onFinishAdd
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
					}
					setIsModalVisible(false);
				}, 1500);
				setUpdateAlert(true)
				setTimeout(() => {
				setUpdateAlert(false)
				}, 2000)
			});
		}
	};
	const handleDate = (dates) => {
		// console.log("datedate00.+++ ",dates);
		if (dates && dates?.length === 2) {
			const startDates = moment(dates[0]?._d).toISOString();
			const endDates = moment(dates[1]?._d).toISOString();
			// setStartDate(moment(startDates).format('MMMM Do YYYY'));
			// setEndDate(moment(endDates).format('MMMM Do YYYY'));
			setStartDate(startDates)
			setEndDate(endDates)
            setDateVal([dates[0].startOf("day"),dates[1].endOf("day")])
		}
		// setSaveDate(date)    
	}
	const range = (start, end) => {
		const result: Array<any> = [];
		for (let i = start; i < end; i++) {
		  result.push(i);
		}
		return result;
	  };

	  const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current < dayjs().endOf('day');
	  }
	  const disabledDateTime = () => ({
		disabledHours: () => range(0, 24).splice(4, 20),
		disabledMinutes: () => range(30, 60),
		disabledSeconds: () => [55, 56],
	  });
	  const disabledRangeTime = (_, type) => {
		if (type === 'start') {
		  return {
			disabledHours: () => range(0, 60).splice(4, 20),
			disabledMinutes: () => range(30, 60),
			disabledSeconds: () => [55, 56],
		  };
		}
		return {
		  disabledHours: () => range(0, 60).splice(20, 4),
		  disabledMinutes: () => range(0, 31),
		  disabledSeconds: () => [55, 56],
		};
	  };

	return (
		<>
		
		{!input ? (
			<Button type="primary" onClick={showModal}>Add Coupon
			</Button>
		 ) : (
		<EditOutlined onClick={showModal} style={{color:"#5143dc"}}/>
		 )}	
			<Modal
				title={!input ? 'Add Coupon' : 'Edit'}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
				bodyStyle={{
					padding: '1rem 3rem'
				}}
			>
				{/* Creating */}
				{createAlert &&  !loadingMt  && <Alert message="Created" type="success" showIcon />}
				{loadingMt && <Alert message="Submitting..." type="info" showIcon />}
				{errorMt && (
					<Alert
						message={`Submission error! ${errorMt.message}`}
						type="error"
						showIcon
					/>
				)}
				{/* Updating */}
				{updateAlert &&  !loadingMtU  && <Alert message="Updated" type="success" showIcon />}
				{loadingMtU && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtU && (
					<Alert
						message={`Submission error! ${errorMtU.message}`}
						type="error"
						showIcon
					/>
				)}
				<Form
					form={form}
					name="addCoupon"
					onFinish={onFinish}
					scrollToFirstError
					initialValues={
						!input
							? {}
							: {
								...input,
								startDate: moment(input.startDate),
								endDate: moment(input.endDate),
							}
					}
				>
					<Row>
					<Col span={24}>
							<label
								style={{
									fontSize: '14px',
									fontWeight: 'bold',
									display: 'block',
								}}
							>
								Basic Info
							</label>
							<hr/>
						</Col>
					<Col span={8} style={{width:"100px"}}>
							<Form.Item
								name={`code`}
								label={<span>Code </span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name={`name`}
								label={<span>Name</span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
							<Input />
							</Form.Item>
						</Col>
						{!input ? ( 
							""
						):(

							<Col span={8}>
								<Form.Item  label="Is Enabled">
									<Switch  onChange={e => handleClick(e)} checked={enabled}/>
								</Form.Item>
							</Col>
						)}
						{/* </Row><Row> */}
						<Col span={24}>
						{/* <div style={{height:"100px"}}> */}
							<Form.Item
								// name={`description`}
								label={<span>Description</span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<div style={{ maxHeight: '150px', overflow: 'auto' }}>
								    {/* <CKEditor style={{height:"120px"}}
			 						activeClass="p10" 
									content={contentDec}
									events={{
										change: (evt) => setcontentDec(evt.editor.getData()),
									}}
			   					 	config={editorConfig}
		   						 	/>  */}
									<textarea value={contentDec} onChange={(e)=>setcontentDec(e.target.value)} style={{width:"100%"}}/>
									</div> 
							</Form.Item>
							{/* </div> */}
						</Col>
						
						<Col span={24}>
							<Form.Item
								// name={`terms`}
								label={<span><span style={{color:"red"}}>*</span> Terms & Conditions </span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<div style={{ maxHeight: '150px', overflow: 'auto' }}>
									     <CKEditor
			 						   activeClass="p10" 
									content={contentTerms}
									onInit={(editor) => {
										editor.editing.view.change((writer) => {
										writer.setStyle(
											"height",
											"100px",
											editor.editing.view.document.getRoot()
										);
										});
									}}
									events={{
										change: (evt) => setcontentTerms(evt.editor.getData()),
									}}
			   					 config={editorConfig}
		   						 /></div>
								{termsValidation &&  <p style={{color:"red"}}>Terms is requried</p>}
							</Form.Item>
						</Col>
					
						</Row>
						<Row>
						<Col span={24}>
							<label
								style={{
									fontSize: '14px',
									fontWeight: 'bold',
									display: 'block',
								}}
							>
								Benefit
							</label>
							<hr/>
						</Col>
						<Col span={12}>
							<Form.Item
								name={`discountType`}
								label={<span>Discount Type</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
									<Select getPopupContainer={(trigger) => trigger.parentElement}>
									<Select.Option value={DiscountTypeEnum.FIXED_AMOUNT}>
										{DiscountTypeEnum.FIXED_AMOUNT}
									</Select.Option>
									<Select.Option value={DiscountTypeEnum.PERCENTAGE}>
										{DiscountTypeEnum.PERCENTAGE}
									</Select.Option>
									
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name={`value`}
								label={<span>Discount Value</span>}
								rules={[
									{
										type: 'number',
										required: true,
									},
								]}
							>
								<InputNumber min={0} />
							</Form.Item>
						</Col>
						</Row>
						<Row>
						<Col span={24}>
							<label
								style={{
									fontSize: '14px',
									fontWeight: 'bold',
									display: 'block',
								}}
							>
								Promotion Period
							</label>
							<hr/>
						</Col>
						<Col span={24}>
						<div style={{textAlign:"center"}}>
						{startDate ? moment(startDate).format("lll") :  moment(input?.startDate).format("lll") }
						{startDate ? "-" : "-"} {endDate ? moment(endDate).format("lll") :  moment(input?.endDate).format("lll") }
						<br/>
						{/* { input?.startDate ? moment(input?.startDate).format("lll") :""}{input?.startDate &&"-"}{ input?.endDate ? moment(input?.endDate).format("lll"):"" }<br/> */}
						<RangePicker onChange={handleDate} allowClear />
						</div>
							{/* <Form.Item
								name={`startDate`}
								label={<span>Start Date</span>}
								rules={[
									{
										type: 'object' as const,
										required: true,
										message: 'Please select Start Date!',
									},
								]}
							>
								<DatePicker />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name={`endDate`}
								label={<span>End Date</span>}
								rules={[
									{
										type: 'object' as const,
										required: true,
										message: 'Please select End Date!',
									},
								]}
							>
								<DatePicker />
							</Form.Item>
						 */}
						</Col>
						</Row>
						<Row>
						<Col span={24}>
							<label
								style={{
									fontSize: '14px',
									fontWeight: 'bold',
									display: 'block',
								}}
							>
								Conditions
							</label>
							<hr/>
						</Col>
						<Col span={12}>
							<Form.Item name={`minOrder`}
							 label={<span>Min Order Value</span>}
							 rules={[
								{
									// type: 'number',
									required: true,
									validator: validateMinOrder,
								},
							]}>
								<Input type="number" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name={'usageLimit'}
								label={<span >Usage Limit</span>}
								rules={[
									{
										// type: 'number',
										required: true,
										validator: validateMinOrder,
									},
								]}
							>
								<Input type='number'/>
							</Form.Item>
						</Col>
					
						<Col span={24}>
							<Form.Item
								name={'maxUsePerCustomer'}
								label={<span style={{fontSize: '9.8px'}}>Max Redemption per Customer</span>}
								rules={[
									{
										// type: 'number',
										required: true,
										validator: validateMinOrder,
									},
								]}
							>
								<Input type='number'/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name={'cities'}
								label={<span >Cities</span>}
								rules={[
									{
										required: false,
									},
								]}
							>
								<Select getPopupContainer={(trigger) => trigger.parentElement}
								mode="multiple"
								style={{
									width: '100%',
								}}
								placeholder="Select Cities"
								// defaultValue={'All'}
								onChange={handleCities}
								optionLabelProp="children"
								// value={citiesValue}

								filterOption={(input, option: any) => {
									const children = option?.label;
									try {
									  if (typeof children === 'string') {
										return children.toLowerCase().includes(input);
									  }
									} catch (error) {
									  console.error("Error filtering option:", error);
									}
									
									return true;
								  }}
								allowClear 
								 onSearch={onSearch}
							>
								{/* <Option value={null} label={"All"} >
									<Space>
										<span role="img" aria-label={"All"}>
											All
										</span>
									</Space>
								</Option> */}

								{ qryDataCities?.getCities.map((entry, index) => (
									<Option value={entry?.id} label={entry?.name} key={index}>
										<Space>
											<span role="img" aria-label={entry?.name}>
												{entry?.name}
											</span>

										</Space>
									</Option>
								))}

							</Select>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name={'areas'}
								label={<span >Areas</span>}
								rules={[
									{
										required: false,
									},
								]}
							>
								{/* {areaValue.length} */}
								<Select getPopupContainer={(trigger) => trigger.parentElement}
								mode="multiple"
								style={{
									width: '100%',
								}}
								placeholder="Select Area"
								showSearch
								onChange={handleArea}
								optionLabelProp="children"
								// value={areaValue}
								// defaultValue={areaValue}
								filterOption={(input, option: any) => {
									const children = option?.label;
									try {
									  if (typeof children === 'string') {
										return children.toLowerCase().includes(input);
									  }
									} catch (error) {
									  console.error("Error filtering option:", error);
									}
									
									return true;
								  }}
								allowClear 
								 onSearch={onSearch}
							>
								{/* <Option value={null} label={"All"} >
									<Space>
										<span role="img" aria-label={"All"}>
											All
										</span>
									</Space>
								</Option> */}	

								{qryDataArea?.getAreas
									// .filter(
									// 	(el) =>
									// 		el.name.toLowerCase().includes(serachVal?.toLowerCase()?? true) 
									// )
								.map((entry, index) => (
									<Option value={entry?.id} label={entry?.name} key={index}>
										<Space>
											<span role="img" aria-label={entry?.name}>
												{entry?.name}
											</span>
										</Space>
									</Option>
								))}
							</Select>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name={'serviceCategories'}
								label={<span>Service Categories</span>}
								rules={[
									{
										required: false,
									},
								]}
							>
								<Select showSearch getPopupContainer={(trigger) => trigger.parentElement}
								mode="multiple"
								id='servicecateid'
								style={{
									width: '100%',
								}}
								placeholder="Select Service Category"
								// defaultValue='All'
								onChange={handleServiceCate}
								// onSearch={handleServiceCate}
								optionLabelProp="label"
								value={serviceCateValue}
							// value={dpValue}
							filterOption={(input, option: any) => {
								const children = option?.label;
								try {
								  if (typeof children === 'string') {
									return children.toLowerCase().includes(input);
								  }
								} catch (error) {
								  console.error("Error filtering option:", error);
								}
								
								return true;
							  }}
							allowClear 
							 onSearch={onSearch}
							>
								{/* <Option value={null} label={"All"} >
									<Space>
										<span role="img" aria-label={"All"}>
											All
										</span>
									</Space>
								</Option> */}
								{qryDataServiceCate?.getServiceCategories.map((entry, index) => (
									<Option value={entry?.id} label={entry?.name} key={index}>
										<Space>
											<span role="img" aria-label={entry?.name}>
												{entry?.name}
											</span>
										</Space>
									</Option>
								))}
							</Select>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name={'services'}
								label={<span >Services</span>}
								rules={[
									{
										required: false,
									},
								]}
							>
								<Select getPopupContainer={(trigger) => trigger.parentElement}
								mode="multiple"
								style={{
									width: '100%',
								}}
								placeholder="Select Service"
								// defaultValue={'All'}
								onChange={handleService}
								optionLabelProp="label"
								// value={serviceValue}
								filterOption={(input, option: any) => {
									const children = option?.label;
									try {
									  if (typeof children === 'string') {
										return children.toLowerCase().includes(input);
									  }
									} catch (error) {
									  console.error("Error filtering option:", error);
									}
									
									return true;
								  }}
								allowClear 
								 onSearch={onSearch}
							>
								{/* <Option value={null} label={"All"} >
									<Space>
										<span role="img" aria-label={"All"}>
											All
										</span>
									</Space>
								</Option> */}

								{serviceCateValue?.length>=1 ? (
									<>
								{categoryService?.map((entry, index) => (
										<>
										{entry?.services?.map((entry, index) => (
											<Option value={entry?.id} label={entry?.name} key={index}>
												<Space>
													<span role="img" aria-label={entry?.name}>
														{entry?.name}
													</span>
												</Space>
											</Option>
										))}</>	
										))}
									</>
								) : (
									<>
										{qryDataService?.getServices.map((entry, index) => (
											<Option value={entry?.id} label={entry?.name} key={index}>
												<Space>
													<span role="img" aria-label={entry?.name}>
														{entry?.name}
													</span>
												</Space>
											</Option>
										))}
									</>
								 )} 
							</Select>
							</Form.Item>
						</Col>
						{/* <Col span={12}>
							<Form.Item
								name={'serviceBillingOption'}
								label={<span >Service Billing Option</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input/>
							</Form.Item>
						</Col> */}
					
						{/* <Col span={2}></Col> */}
					
					</Row>
				</Form>
			</Modal>
		</>
		
	);
};
export default AddCouponCode;
