import { useMutation, useQuery } from '@apollo/client';
import {
	Alert,
	Button,
	Card,
	Col,
	Input,
	Modal,
	Pagination,
	Row,
	Space,
	Spin,
	Table,Rate
} from 'antd';
import React, { useEffect, useState } from 'react';
import { RouteList } from 'src/config/constants/routes';
import { Link } from 'react-router-dom';
import {
	CMSBookingReAssignPartner,
	CMSBookingReAssignPartnerVariables,
} from 'src/lib/gql/generated/CMSBookingReAssignPartner';
import {
	CMSGetUsers,
	CMSGetUsersVariables,
} from 'src/lib/gql/generated/CMSGetUsers';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { BOOKING_RE_ASSIGN_PARTNER } from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetPartners } from 'src/lib/gql/queries/users';
import { CMSQueryGetAssignPartners } from 'src/lib/gql/queries/bookings';
interface Props {
	input?: any;
	onFinishAdd?: () => void;
}

const ReAssignPartner = ({ input, onFinishAdd }: Props) => {
	console.log("onFinishAdd",onFinishAdd);
	
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isAssignPartnersLoading, setIsAssignPartnersLoading] = useState(false);

	const [
		reassignPartner,
		{ data: dataMt, loading: loadingMt, error: errorMt },
	] = useMutation<
		CMSBookingReAssignPartner,
		CMSBookingReAssignPartnerVariables
	>(BOOKING_RE_ASSIGN_PARTNER
		,{
			onCompleted: () => {
				setTimeout(() => {
					setIsModalVisible(false);
				}, 2000);
				if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
			},
		});

	const showModal = async () => {
		setIsAssignPartnersLoading(true)
		setIsModalVisible(true);
		await refetchQryData()
		setIsAssignPartnersLoading(false)
	};

	const handleCancel = () => {
		if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
		setIsModalVisible(false);
	};
	const handleOk = () => {
		setIsModalVisible(false);
		onFinishAdd
	};

	const onCommit = (partner) => {
		reassignPartner({
			variables: {
				partnerId: partner.id,
				// Booking Service Item
				bookingServiceItemId: input?.id,
			},
		}).then(() => {
			console.log('done');
			onFinishAdd
		});
	};

	//Pull Partner data
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [filterName, SetFilterName] = useState('');
	const [filterPhone, SetFilterPhone] = useState('');

	const {
		data: qryData,
		loading: isLoading,
		called,
		error,
		refetch: refetchQryData,
		
	} = useQuery(CMSQueryGetAssignPartners, {
		variables: {
			pagination: pagination,
			filters: {
				type: UserTypeEnum.PARTNER,
				name: filterName,
				phone:filterPhone
			},
		    bookingServiceItemId: input?.id
		},
		fetchPolicy: 'network-only'
	});

	useEffect(() => {
		console.log("🚀 ~ ReAssignPartner ~ loading:", isLoading)
	}, [isLoading])
	
		

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: 150,
			render: (name, record) => <span className="gx-link">
			<Link to={RouteList.partners.path + '/' + record.id}>{name}</Link>
		</span>,
		},
		{
			title: 'Phone',
			dataIndex: 'phone',
			key: 'phone',
			width: 150,
			render: (phone, record) => <span>{phone} </span>,
		},
		{
			title: 'Total Bookings',
			dataIndex: 'bookings',
			key: 'bookings',
			align: 'center' as const,
			render: (bookings) => <span>{bookings.length}</span>,
		},
		{
			title: 'Open Bookings',
			dataIndex: 'bookings',
			key: 'bookings',
			align: 'center' as const,
			render: (bookings) => (
				<span>{bookings.filter((b) => b.bookingStatus === 'OPEN').length}</span>
			),
		},
		// {
		// 	title: 'Rework status',
		// 	dataIndex: 'rework',
		// 	key: 'rework',
		// },
		// {
		// 	title: 'No. of Cancellation',
		// 	dataIndex: 'cancelations',
		// 	key: 'cancelations',
		// 	align: 'center' as const,
		// },
		{
			title: 'Ratings',
			dataIndex: 'totalratings',
			key: 'totalratings',
			render: (text, record) => (
				<span>
					{/* <span>⭐⭐⭐⭐</span> */}
					<Rate
					disabled
					defaultValue={record?.rating}
				style={{ color: 'green' }}
				/>
				</span>
			),
		},
		{
			title: 'Action',
			dataIndex: 'key',
			key: 'id',
			width: 'auto',
			render: (text, record) => (
				<Button type="primary" onClick={() => onCommit(record)}>
					Re Assign
				</Button>
			),
		},
	];

	const handleNameSearch = (e) => {
		
		SetFilterName(e.target.value);

		setTimeout(() => {
			refetchQryData();
		}, 1000);
	}

	return (
		<>
			<Button type="primary" onClick={showModal}>
				Re Assign
			</Button>
			<Modal
				title={'Re Assign'}
				visible={isModalVisible}
				onCancel={handleCancel}
				onOk={handleOk}
				width={1000}
				footer={null}
			>
				{/* Commiting */}
				{dataMt && <Alert message="Created" type="success" showIcon />}
				{loadingMt && <Alert message="Submitting..." type="info" showIcon />}
				{errorMt && (
					<Alert
						message={`Submission error! ${errorMt.message}`}
						type="error"
						showIcon
					/>
				)}

				<Card>
					<Row style={{
                    marginBottom: "16px",
                    paddingTop: "0.2rem",
                    paddingBottom: "5px"
                }}>
						<Col span={24}>
						<div style={{
                            justifyContent: "center",
                            display: "flex"
                        }}>
                            <div>
							<Input
								placeholder="Search by Name"
								value={filterName}
								onChange={handleNameSearch}
							/>
							</div>
						{/* </Col>
						<Col span={4}> */}
                            <div style={{ paddingLeft: "0.5rem" }}>
							<Input
								placeholder="Search by Phone"
								value={filterPhone}
								allowClear
								onChange={(e) => SetFilterPhone(e.target.value)}
							/>     </div> </div>
						</Col>
						</Row>
						<Row style={{
                    marginBottom: 16, paddingTop: "1rem",
                    backgroundColor: "#f6f6f6",
          		      }}> 
						<Col span={24}  style={{ textAlign: "center" }}>
							<Pagination
								key="pagination"
								defaultCurrent={qryData?.getAssignPartners?.pageInfo.currentPage}
								current={qryData?.getAssignPartners?.pageInfo.currentPage}
								pageSize={pagination.pageSize}
								total={qryData?.getAssignPartners?.pageInfo.totalCount}
								showTotal={(total) => `Total ${total} items`}
								onChange={(page, pageSize) => {
									console.log('onChange');
									setPagination({
										pageNumber: page,
										pageSize: pageSize ? +pageSize : pagination.pageSize,
									});
								}}
								showSizeChanger
							/>
						</Col>
					</Row>

					{isLoading && (
						<Row justify="center">
							<Space size="middle">
								<Spin size="large" />
							</Space>
						</Row>
					)}

					{qryData && (
						<Table
							className="gx-table-responsive"
							columns={columns}
							dataSource={qryData?.getAssignPartners?.data}
							pagination={false}
							loading={isAssignPartnersLoading}
						/>
					)}
				</Card>
			</Modal>
		</>
	);
};
export default ReAssignPartner;
