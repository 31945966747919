import { useMutation, useQuery } from '@apollo/client';
import {
	Alert,
	Button,
	Col,
	Form,
	Input,
	Modal,
	Row,
	Select,
	Image,
} from 'antd';
import React, { useState } from 'react';
import { CMSGetPartner_getUser } from 'src/lib/gql/generated/CMSGetPartner';
import { CMSGetServiceAreas } from 'src/lib/gql/generated/CMSGetServiceAreas';
import {
	CMSUpdatePartnerDetails,
	CMSUpdatePartnerDetailsVariables,
} from 'src/lib/gql/generated/CMSUpdatePartnerDetails';
import { UPDATE_PARTNER_DETAILS } from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetServiceAreas } from 'src/lib/gql/queries/services';
import { UploadPublicImage } from 'src/components/shared/UploadPublicImage';
import {EditOutlined } from '@ant-design/icons';
import { log } from 'console';


interface Props {
	input?: CMSGetPartner_getUser | null;
	onFinishAdd?: () => void;
}

const UpdatePartnerDetails: React.FC<Props> = ({ input, onFinishAdd }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [picId, setPicId] = useState(null);

	// Get all Areas
	const { loading, data: qryData } = useQuery<CMSGetServiceAreas>(
		CMSQueryGetServiceAreas,
		{
			errorPolicy: 'all',
		},
	);

	// Update
	const [
		updateDetails,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation<CMSUpdatePartnerDetails, CMSUpdatePartnerDetailsVariables>(
		UPDATE_PARTNER_DETAILS,
	);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
		setIsModalVisible(false);
	};

	const [form] = Form.useForm();

	const onFinish = (values) => {
		if (input) {
			updateDetails({
				variables: {
					partnerId: input?.id,
					data: {
						name: values.name,
						email: values.email,
						photoId: picId,
						isBackgroundVerification: null,
						backgroundVerificationNote: null,
						isZimkeyPartner: null,
						address: {
							buildingName: values.buildingName,
							address: values.address,
							landmark: values.landmark,
							postalCode: values.postalCode,
							area: values.area,
							// TODO
							city: '',
							locality:values.locality,
						},
					},
				},
			}).then(() => {
				// form.resetFields();
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
					}
					setIsModalVisible(false);
				}, 1500);
			});
		}
	};
	console.log('img',input?.partnerDetails?.photo?.url);
	
	return (
		<>
			<Button type="primary" onClick={showModal}>
			<EditOutlined />
			</Button>
			<Modal
				title={'Edit Info'}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
			>
				{/* Updating */}
				{dataMtU && <Alert message="Updated" type="success" showIcon />}
				{loadingMtU && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtU && (
					<Alert
						message={`Submission error! ${errorMtU.message}`}
						type="error"
						showIcon
					/>
				)}

				<Form
					form={form}
					onFinish={onFinish}
					scrollToFirstError
					initialValues={
						!input
							? {}
							: {
									...input,
									...input?.partnerDetails?.address,
							  }
					}
				>
					<Row>
						<Col span={12}>
							<Form.Item
								name={`name`}
								label={<span>Name</span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								name={`email`}
								label={<span>Email</span>}
								rules={[
									{
										required: true,
										type: 'email',
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item label="Upload Photo">
								<Form.Item name="icon" valuePropName="icon" noStyle>
									{input?.partnerDetails?.photo?.url && (
										<Image
											width={200}
											height={200}
											src={`https://staging.api.zimkey.in/media-upload/${input?.partnerDetails?.photo?.url}`}
										/>
									)
									}
									<UploadPublicImage
										onFinishAdd={(info) =>
											setPicId(info?.file.response.data.id)
										}
									/>
									*jpeg/png
									(320*320)
								</Form.Item>
							</Form.Item>
						</Col>

						<Col span={24}>
							<p>Address Details:</p>
						</Col>

						<Col span={12}>
							<Form.Item
								name={`buildingName`}
								label={<span>Building Name</span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name={`address`}
								label={<span>Address</span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name={`locality`}
								label={<span>Locality</span>}
								rules={[
									{
										whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name={`landmark`}
								label={<span>Landmark</span>}
								rules={[
									{
										whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name={`postalCode`}
								label={<span>Postal Code</span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name={"area"}
								label={<span>Area</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Select getPopupContainer={(trigger) => trigger.parentElement}
								// value={area} 
									showSearch
									placeholder="Select Area"
									optionFilterProp="children"
									filterOption={(input, option: any) => {
										return (
											option?.children?.toLowerCase().includes(input) ?? true
										);
									}}
								>
									{qryData?.getAreas?.map((area) => (
										<Select.Option key={area.id} value={area.id}>
											{area.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};
export default UpdatePartnerDetails;
