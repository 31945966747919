export default [
	{
		id: 1,
		handle: 'frontend',
		title: 'Feedback',
		color: 'teal',
	},
	{
		id: 2,
		handle: 'backend',
		title: 'Complain',
		color: 'indigo',
	},
	{
		id: 3,
		handle: 'api',
		title: 'App Feedback',
		color: 'green',
	},
	{
		id: 4,
		handle: 'issue',
		title: 'Other',
		color: 'info',
	},
];
