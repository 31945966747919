import { gql } from '@apollo/client';

export const CMSQueryGetRoles = gql`
	query CMSGetRoles(
		$pagination: PaginationGqlInput!
		$filters: UsersQueryGqlInput!
	) {
		getRoles(pagination: $pagination, filters: $filters) {
			data {
				id
				slNo
				name
				rights {
					id
					name
				}
			}
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;

export const CMSQueryGetRights = gql`
	query CMSGetRights {
		getRights {
			id
			name
			type
			parentId
		}
	}
`;
