import { useMutation, useQuery } from '@apollo/client';
import {
	Alert,
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Input,
	Row,
	Select,
	Space,
	Spin,
} from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UpdatePartnerCompanies from 'src/components/users/updatePartnerCompanies';
import { RouteList } from 'src/config/constants/routes';
import { CMSGetPartnerCompanies } from 'src/lib/gql/generated/CMSGetPartnerCompanies';
import { CMSGetServiceAreas } from 'src/lib/gql/generated/CMSGetServiceAreas';
import {
	CMSRegisterPartnerByAdmin,
	CMSRegisterPartnerByAdminVariables,
} from 'src/lib/gql/generated/CMSRegisterPartnerByAdmin';
import { REGISTER_PARTNER_BY_ADMIN } from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetServiceAreas } from 'src/lib/gql/queries/services';
import { CMSQueryGetPartnerCompanies } from 'src/lib/gql/queries/users';
import { CaretLeftOutlined } from '@ant-design/icons';
import { UploadPublicImage } from 'src/components/shared/UploadPublicImage';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 24,
			offset: 8,
		},
	},
};

const AddServicePartner: React.FC = (props: any) => {
	const [form] = Form.useForm();
	const [picId, setPicId] = useState(null);

	const navigate = useNavigate();
	const [checked, setChecked] = useState(false);
	const [checkedZimkeyPartner, setCheckedZimkeyPartner] = useState(false);
	const [checkedCompany, setCheckedCompany] = useState(false);

	// Get all Areas
	const {
		loading,
		data: qryData,
		refetch: refetchQryData,
	} = useQuery<CMSGetServiceAreas>(CMSQueryGetServiceAreas, {
		errorPolicy: 'all',
	});
	const {  error, data, refetch: refetchPartnerCompanies } = useQuery(CMSQueryGetPartnerCompanies, {
		variables: {
		  pagination: {
			pageNumber: 1,
			pageSize: 100,
		  },
		  filters: {
			// Your filter criteria here
		  },
		},
		fetchPolicy: 'network-only'
	  });
	  const PartnerCompanies = data?.getPartnerCompanies?.data || [];

	// const PartnerCompanies = useQuery<CMSGetPartnerCompanies>(
	// 	CMSQueryGetPartnerCompanies,{
	// 		variables: {
	// 			pagination: {
	// 				pageNumber: 1,
	// 				pageSize: 100
	// 			},
	// 			filters: {}
	// 		}	
	// 	}
	// );
	// console.log("PartnerCompanies",PartnerCompanies?.data?.getPartnerCompanies	);
		

	// Add Partner
	const [
		addServiceCategory,
		{ data: dataMt, loading: loadingMt, error: errorMt },
	] = useMutation<
		CMSRegisterPartnerByAdmin,
		CMSRegisterPartnerByAdminVariables
	>(REGISTER_PARTNER_BY_ADMIN);

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select style={{ width: 150 }} getPopupContainer={(trigger) => trigger.parentElement}>
				<Select.Option value="+91">+91</Select.Option>
				{/* <Select.Option value="971">+971</Select.Option> */}
			</Select>
		</Form.Item>
	);

	const onFinish = (values) => {
		console.log('vall',values);
		console.log('checkedCompany',checkedCompany);
		console.log('checked',checked);

		
		let value = values.phone;
		// Allow only numbers
		value = value.replace(/\D/g, '');
		addServiceCategory({
			variables: {
				phone: values.prefix + value,
				data: {
					name: values.name,
					address: {
						buildingName: values.buildingName,
						address: values.address,
						locality: values.locality,
						landmark: values.landmark,
						postalCode: values.postalCode,
						area: values.areaId,
						// TODO
						city: values.city,
					},
					companyId: checked ? checkedCompany : null,
					accountNumber: values.accnum,
					email: values.email,
					ifscCode: values.ifsc,
					isZimkeyPartner:checkedZimkeyPartner,
					// TODO
					photoId:picId,
					// isBackgroundVerification: null,
					// backgroundVerificationNote: null,
				},
			},
		}).then((res) => {
			navigate('/partners/' + res?.data?.registerPartnerByAdmin?.id);
			form.resetFields();
		});
	};
	const handleCompany=(values:any)=>{
		setCheckedCompany(values)
	}

	return (
		<Card >
				<h2 style={{ paddingBottom: '2px' }}>
				<Button
					className="gx-mr-4"
					onClick={() => navigate(-1)}
					style={{ margin: '0' }}
				>
					<CaretLeftOutlined />
				</Button>
				Add New Partner
			</h2>
			<hr/>
			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}
			<div className="gx-flex gx-flex-nowrap gx-mb-2">
				{/* <div className="gx-card-profile">
					<div className="ant-card-head " style={{textAlign:'center'}}>
						<Link to={RouteList.partners.path} className="gx-mr-4">
							[ Back ]
						</Link>
					</div>

					<div className="gx-pt-md-3"></div>
				</div> */}

				{!loading && (
					<Row>
						<Col span={24} className="gx-mb-4">
							{/* <h1>Add new Partner</h1> */}
							{/* <hr /> */}

							{dataMt && <Alert message="Created" type="success" showIcon />}
							{loadingMt && (
								<Alert message="Submitting..." type="info" showIcon />
							)}
							{errorMt && (
								<Alert
									message={`Submission error! ${errorMt.message}`}
									type="error"
									showIcon
								/>
							)}
						</Col>
						<Col span={24} className="gx-mb-4">
							{!loading && (
								<Form
									{...formItemLayout}
									form={form}
									name="register"
									onFinish={onFinish}
									initialValues={{
										partOfCompany: false,
										prefix: '+91',
										city:"Cochin",
									}}
									scrollToFirstError
								>
									<h3>Basic Info</h3>

									<Form.Item
										name="phone"
										label="Phone Number"
										rules={[
											{
												required: true,
												message: 'Please input your phone number!',
											},
											{
												pattern: /^[0-9]{10}$/, 
												message: 'Please enter a valid 10-digit phone number!',
											  },
										]}
									>
										<Input
											addonBefore={prefixSelector}
											style={{ width: '100%' }}
										/>
									</Form.Item>

									<Form.Item
										name="name"
										label={<span>Name</span>}
										rules={[
											{
												
												required: true,
												message: 'Please input name',
												whitespace: true,
											},
											{
												pattern: /^[a-zA-Z\s.]+$/,
												message: 'Please enter a valid name with only letters and spaces !',
											  },
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										name={`email`}
										label={<span>Email</span>}
										rules={[
											{
												required: true,
												type: 'email',
												message: 'Please input your Email!',
											},
										]}
									>
										<Input />
									</Form.Item>

									<Form.Item
										name="companyId"
										label={<span>Company</span>}
										// rules={[
										// 	{
										// 		required: checked,
										// 	},
										// ]}
									>
										<Checkbox
											checked={checked}
											onChange={(e) => setChecked(e.target.checked)}
											// value={checked}
											style={{ marginTop: '6px', marginBottom: '12px' }}
										>
											Is Part of Company?
										</Checkbox>

										{checked && (
											<>
												<Select
												 getPopupContainer={(trigger) => trigger.parentElement}
													showSearch
													placeholder="Select Company"
													allowClear
													onChange={handleCompany}
													optionFilterProp="children"
													filterOption={(input, option: any) => {
														return (
															option?.children?.toLowerCase().includes(input) ??
															true
														);
													}}
													style={{ marginTop: '6px', marginBottom: '12px' }}
												>
													{/* {PartnerCompanies?.data?.getPartnerCompanies&& Array?.isArray(PartnerCompanies?.data.getPartnerCompanies) ? ( */}
 														 {PartnerCompanies ? (
														  PartnerCompanies?.map((company) => (
   													 <Select.Option key={company.id} value={company.id}>
   													   {company.companyName}
   													 </Select.Option>
 													 )) 
													 ) : (
 													 // Handle the case where getPartnerCompanies is not an array
 													 <p>No partner companies available</p>
													)} 
												</Select>
												<UpdatePartnerCompanies
													onFinishAdd={refetchPartnerCompanies}
												/>
											</>
										)}
									</Form.Item>
									<Form.Item
										name="zimkeyPartner"
										label={<span>Is This Zimkey Partner</span>}
										// rules={[
										// 	{
										// 		required: checked,
										// 	},
										// ]}
									>
										<Checkbox
											checked={checkedZimkeyPartner}
											onChange={(e) => setCheckedZimkeyPartner(e.target.checked)}
											value={checkedZimkeyPartner}
											style={{ marginTop: '6px', marginBottom: '12px' }}
										>
										</Checkbox>
										</Form.Item>
										
							<Form.Item label="Upload Photo">
								<Form.Item name="icon" valuePropName="icon" noStyle>
								
									<UploadPublicImage
										onFinishAdd={(info) =>
											setPicId(info?.file.response.data.id)
										}
									/>
									*jpeg/png
									(320*320)
								</Form.Item>
							</Form.Item>
					
									<h3>Address Details</h3>

									<Form.Item
										name="address"
										label={<span>Address</span>}
										rules={[
											{
												required: true,
												message: 'Please input your Address!',
											},
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										name="buildingName"
										label={<span>Building Name</span>}
										rules={[
											{
												required: true,
												message: 'Please input your Building Name!',

											},
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item name="locality" label={<span>Locality</span>}>
										<Input />
									</Form.Item>
									<Form.Item name="landmark" label={<span>Landmark</span>}>
										<Input />
									</Form.Item>
									<Form.Item name="city" label={<span>City</span>} >
										<Input disabled/>
									</Form.Item>
									<Form.Item
										name="postalCode"
										label={<span>Postal Code</span>}
										rules={[
											{
												required: true,
												message: 'Please input your Postal Code!',

											},
											{
												pattern: /^[0-9]+$/, 
												message: 'Please enter numbers only!',
											  },
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										name="areaId"
										label={<span>Area</span>}
										rules={[
											{
												required: true,
												message: 'Please input your Area!',

											},
										]}
									>
										<Select getPopupContainer={(trigger) => trigger.parentElement}
											showSearch
											placeholder="Select Area"
											optionFilterProp="children"
											filterOption={(input, option: any) => {
												return (
													option?.children?.toLowerCase().includes(input) ??
													true
												);
											}}
										>
											{qryData?.getAreas?.map((area) => (
												<Select.Option key={area.id} value={area.id}>
													{area.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>

									<h3>Bank Info</h3>

									<Form.Item
										name="accnum"
										label={<span>Account Number</span>}
										rules={[
											{
												required: true,
												message: 'Please input your Account Number!',

											},
											{
												pattern: /^[0-9]+$/,
												message: 'Please enter numbers only!',
											  },
										]}
									>
										<Input />
									</Form.Item>

									<Form.Item
										name="ifsc"
										label={<span>IFSC Code</span>}
										rules={[
											{
												required: true,
												message: 'Please input your IFSC Code!',

											},
										]}
									>
										<Input />
									</Form.Item>

									<Form.Item {...tailFormItemLayout}>
										<div style={{textAlign:"center"}}>
										<Button type="primary" htmlType="submit">
											Add Partner
										</Button>
										</div>
									</Form.Item>
								</Form>
							)}
						</Col>
					</Row>
				)}
			</div>
		</Card>
	);
};
export default AddServicePartner;
