import { gql } from '@apollo/client';

export const CMSQueryGetUserBooking = gql`
	query CMSGetUserBooking($id: String!) {
		getUserBooking(id: $id) {
			id
			bookingStatus
			bookingDate
			user {
				name
				email
				phone
				about
			}

			bookingAddress {
				id
				addressType
				landmark
				area {
					name
				}
			}
			bookingPayments {
				bookingId
				orderId
				amount
				amountPaid
				amountDue
				currency
				status
				attempts
				invoiceNumber
			}
		}
	}
`;

export const CMSQueryGetUserBookings = gql`
	query CMSGetUserBookings($pagination: PaginationGqlInput!, $useId: String) {
		getUserBookings(pagination: $pagination, useId: $useId) {
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
			data {
				id
				bookingStatus
				userBookingNumber
				bookingService{
					bookingServiceItems {
					  servicePartner {
						id
						name
						email
						phone
					  }
					}
				  }
				bookingPayments {
					id
					orderId
					amount
					amountPaid
					currency
					status
					attempts
					invoiceNumber
					bookingId
				}
				pendingAmount{
					amount
					
				}
				bookingDate
				createDateTime
				servicePartners{
					id
					name
				}
				userId
				bookingNote
				appliedCoupons
				user {
					id
					name
					email
					phone
					userType
				}
				bookingAddress {
					id
					postalCode
					areaId
					area {
						name
						code
						pinCodes {
							id
							pinCode
						}
						city {
							id
							name
						}
					}
				}
			}
		}
	}
`;
export const CMSQueryGetBookingServiceItems = gql`
	query CMSGetBookingServiceItems(
		$pagination: PaginationGqlInput!
		$filter: GetBookingServiceItemsFilterGqlInput
	) {
		getBookingServiceItems(pagination: $pagination, filter: $filter) {
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
			data {
				id
				bookingServiceId
				startDateTime
				endDateTime
				units
				bookingServiceItemStatus
				chargedPrice{
					grandTotal
					totalAmount
					unitPrice
				}
				bookingService {
					unit
					service {
						id
						code
						name
						icon {
							id
							url
						}
					}
					booking {
						id
						userBookingNumber
						createDateTime
						bookingAmount{
							grandTotal
						}
						bookingAddress {
							id
							buildingName
							postalCode
							area {
								name
							}
							addressType
							landmark
						}
						user {
							id
							name
							phone
						}
						bookingStatus
						bookingDate
					}
				}
				servicePartner {
					id
					name
					phone
				}
			}
		}
	}
`;

export const CMSQueryGetBookingServiceItem = gql`
	query CMSGetBookingServiceItem($id: String!) {
		getBookingServiceItem(id: $id) {
			id
			workCode
			workNote
			bookingServiceId
			startDateTime
			endDateTime
			modificationReason
			jobRating
			servicePartnerId
			units
			actualEndDateTime
			bookingServiceItemType
			refBookingServiceItem{
				id
			}
			chargedPrice{
				unitPrice
				totalRefundable
				totalRefunded
				subTotal
				grandTotal
				partnerRate
				partnerDiscount
				partnerAmount
				partnerGSTPercentage
				partnerGSTAmount
				totalPartnerAmount
				commissionPercentage
				commissionRate
				commissionDiscount
				commissionAmount
				commissionGSTPercentage
				commissionGSTAmount
				totalCommission
				subTotal
				totalDiscount
				totalAmount
				totalGSTAmount
				grandTotal
			}
			jobBoard{
				id
				bookingServiceItemId
			}
			
			bookingServiceItemStatus
			review{
				id
				userId
				user{
					id
					name
				}
				rating
				review
				createDateTime
			}
			statusHistory{
				status
				statusChangedAt
				changedByUser{
					name
					id
				}
			}
			bookingAddons {
				name
				itemPrice{
					partnerPrice
					commission
					commissionTax
					partnerTax
					totalTax
					total
				}
				units
				unit
				addonId
				bookingServiceItemId
				}
			bookingService {
				serviceRequirements
				otherRequirements
				serviceBillingOptionId
				qty
			
				unit
				serviceBillingOption {
					minUnit
					maxUnit
				}
				service {
					id
					code
					name
					HSNcode
					billingOptions{
						id
						name
						maxUnit
						minUnit
						sortOrder
					  }
					icon {
						id
						url
					}
				}
				bookingServiceInputs{
					id
					bookingServiceId
					value
					name
					key
					type
				}
				bookingAdditionalPayments {
					id
					itemPrice{
						partnerPrice
						commission
						commissionTax
						partnerTax
						totalTax
						total
						}
					name
					description
					refundable
					refundAmount
					mandatory
					bookingServiceId
				}
				booking {
					bookingNote
					id
					userBookingNumber
					redeemedZpoints
					isZpointsApplied
					appliedCoupons
					appliedCouponDetails{
						id
						name
						minOrder
						discountType
						value
						startDate
						endDate
					}
					bookingAmount {
						unitPrice
						totalRefundable
						totalRefunded
						subTotal
						grandTotal
						partnerRate
						partnerDiscount
						partnerAmount
						partnerGSTPercentage
						partnerGSTAmount
						totalPartnerAmount
						commissionPercentage
						commissionRate
						commissionDiscount
						commissionAmount
						commissionGSTPercentage
						commissionGSTAmount
						totalCommission
						subTotal
						totalDiscount
						totalAmount
						totalGSTAmount
						grandTotal
					}
					bookingPayments {
						id
						orderId
						paymentId
						amount
						amountPaid
						amountDue
						currency
						status
						attempts
						invoiceNumber
						bookingId
					}
					
					bookingAddress {
						id
						buildingName
						postalCode
						alternatePhoneNumber
						area {
							name
							city{
								id
								name
							}
						}
						addressType
						landmark
						locality
					}
				
					user {
						id
						name
						email
						phone
					}
					bookingStatus
					bookingDate
				}
			}
			servicePartner {
				id
				name
				phone
			}
			itemPrice{
				unitPrice
			  }
			additionalWorks {
				bookingAdditionalWorkStatus
				additionalHoursUnits
				minUnit
				additionalHoursItemPrice {
					unitPrice
				  }
				bookingAddons {
				  name
				  units
				  minUnit
				  itemPrice{
					unitPrice
				  }
				  amount {
					unitPrice
					partnerRate
					partnerDiscount
					partnerAmount
					partnerGSTPercentage
					partnerGSTAmount
					totalPartnerAmount
					commissionPercentage
					commissionRate
					commissionDiscount
					commissionAmount
					commissionGSTPercentage
					commissionGSTAmount
					totalCommission
					subTotal
					totalDiscount
					totalAmount
					totalGSTAmount
					grandTotal
				  }
				}
				additionalHoursAmount {
				  unitPrice
				  partnerRate
				  partnerDiscount
				  partnerAmount
				  partnerGSTPercentage
				  partnerGSTAmount
				  totalPartnerAmount
				  commissionPercentage
				  commissionRate
				  commissionDiscount
				  commissionAmount
				  commissionGSTPercentage
				  commissionGSTAmount
				  totalCommission
				  subTotal
				  totalDiscount
				  totalAmount
				  totalGSTAmount
				  grandTotal
				}
				totalAdditionalWorkAmount {
				  subTotal
				  totalDiscount
				  totalAmount
				  totalGSTAmount
				  grandTotal
				}
			  }
			  activePartnerCalender {
				id
				partnerCalendarStatus
			  }
		}
	}
`;

export const CMSQueryGetAllBookings = gql`
	query CMSGetAllBookings(
		$pagination: PaginationGqlInput!
		$filter: GetBookingsFilterGqlInput
	) {
		getBookings(pagination: $pagination, filter: $filter) {
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
			data {
				id
				bookingStatus
				bookingPayments {
					id
					orderId
					amount
					amountPaid
					currency
					status
					attempts
					invoiceNumber
					bookingId
				}
				bookingDate
				userId
				bookingNote
				appliedCoupons
				user {
					id
					name
					email
					phone
					userType
				}
				bookingAddress {
					id
					postalCode
					areaId
					area {
						name
						code
						pinCodes {
							id
							pinCode
						}
						city {
							id
							name
						}
					}
				}
			}
		}
	}
`;

export const CMSQueryGetJobBoard = gql`
	query CMSGetJobBoard($pagination: PaginationGqlInput! ,$filter: JobBoardFilterGqlInput) {
		getJobBoard(pagination: $pagination, filter: $filter) {
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
			data {
				id
				jobDate
				addedDate
				jobAreaId
				jobEndDate
				bookingServiceItemId
				bookingServiceItem{
					bookingServiceItemStatus
				}
				jobArea {
					id
					name
					code
				}
				jobServiceId
				jobService {
					id
					code
					name
					icon {
						url
					}
				}
				bookingService{
					unit
				}
				autoAssign
				bookingId
				booking {
					bookingDate
					id
					bookingStatus
					userBookingNumber
					userId
					isZpointsApplied
					redeemedZpoints
					isCouponApplied
					isZpointsApplied
					redeemedZpoints
					isCouponApplied
					appliedCouponDetails{
						id
						name
						minOrder
						discountType
						value
						startDate
						endDate
					}
					user {
						id
						name
						phone
					}
					bookingAmount {
						totalRefundable
						totalRefunded
						subTotal
						grandTotal
						partnerRate
						partnerDiscount
						partnerAmount
						partnerGSTPercentage
						partnerGSTAmount
						totalPartnerAmount
						commissionPercentage
						commissionRate
						commissionDiscount
						commissionAmount
						commissionGSTPercentage
						commissionGSTAmount
						totalCommission
						subTotal
						totalDiscount
						totalAmount
						totalGSTAmount
						grandTotal
						}
					bookingAddress {
						id
						buildingName
						postalCode
						addressType
						landmark
						area {
							id
							name
							code
							city {
								id
								name
							}
						}
					}
				}
				jobPriority
			}
		}
	}
`;

export const CMSQueryGetUserBookingServiceItems = gql`
query CMSGetUserBookingServiceItems($pagination: PaginationGqlInput!, $userId: String!
	) {
	getUserBookingServiceItems (pagination: $pagination, userId: $userId) {
		pageInfo {
			hasNextPage
			currentPage
			currentCount
			nextPage
			totalPage
			totalCount
		}
		data {
			id
			bookingServiceId
			startDateTime
			endDateTime
			units
			bookingServiceItemStatus
			bookingService {
				service {
					id
					code
					name
					icon {
						id
						url
					}
				}
				booking {
					id
					userBookingNumber
					createDateTime
					bookingAmount{
						grandTotal
					}
					bookingAddress {
						id
						postalCode
						area {
							name
						}
						addressType
						landmark
					}
					user {
						id
						name
						phone
					}
					bookingStatus
					bookingDate
				}
			}
			servicePartner {
				id
				name
				phone
			}
		}
	}
}
`;


export const CMSQueryGetServiceBookingSlots = gql`
	query CMSGetServiceBookingSlots($billingOptionId: String!,$date: DateTime!,$areaId: String,$partnerId: String,$units: Float, $isReschedule: Boolean, $bookingServiceItemId: String) {
		getServiceBookingSlots(billingOptionId: $billingOptionId,date: $date,areaId: $areaId,partnerId: $partnerId,units: $units, isReschedule: $isReschedule,bookingServiceItemId: $bookingServiceItemId) {
			start
			available
			end
		}
}`;

export const CMSQueryGetAssignPartners = gql`
	query CMSGetAssignPartners(
		$pagination: PaginationGqlInput!
		$filters: UsersQueryGqlInput!
		$bookingServiceItemId: String
	) {
		getAssignPartners(pagination: $pagination, filters: $filters, bookingServiceItemId: $bookingServiceItemId) {
			data {
				id
				name
				email
				userType
				isActive
				uid
				phone
				rating
				bookings {
					id
					bookingStatus
				}

				createDateTime
				partnerDetails {
					id
					approved
					disableAccount
					accountNumber
					pendingTasks
					services {
						id
					}
					serviceAreas {
						id
					}
					documents {
						id
						documentType
						medias {
							id
							url
						}
					}
				}
				partnerAnalytics {
					totalBookings
					openBookings
				}
			}
			pageInfo {
				totalPage
				totalCount
				currentPage
				currentCount
				nextPage
				hasNextPage
			}
		}
	}
`;