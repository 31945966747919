import { LoadingOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
	Button,
	Card,
	Col,
	Form,
	Input,
	Radio,
	RadioChangeEvent,
	Row,
	Select,
	Space,
	Table,Alert
} from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditFaq from 'src/components/manage/editFaq';
import { CMSAddFaq, CMSAddFaqVariables } from 'src/lib/gql/generated/CMSAddFaq';
import {
	CMSDeleteFaq,
	CMSDeleteFaqVariables,
} from 'src/lib/gql/generated/CMSDeleteFaq';
import {
	CMSGetFaqs,
	CMSGetFaqsVariables,
} from 'src/lib/gql/generated/CMSGetFaqs';
import { AppTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { ADD_FAQ, DELETE_FAQ } from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetFaqs } from 'src/lib/gql/queries/faqs';
import {DeleteOutlined ,EditOutlined} from '@ant-design/icons';
import {  CaretLeftOutlined } from '@ant-design/icons';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { userAtom } from 'src/lib/recoil/userCoil';
import { useRecoilValue } from 'recoil';

const { Column } = Table;

const ManageFAQs: React.FC = () => {
	const [app, setApp] = useState<AppTypeEnum>(AppTypeEnum.CUSTOMER);
	const userAuth = useRecoilValue(userAtom);
	const [form] = Form.useForm();
	const [deleteAlert, setDeleteAlert] = useState(false)
	const [createAlert, setCreateAlert] = useState(false)

	const onChange = (e: RadioChangeEvent) => {
		setApp(e.target.value);
	};
	const {
		loading,
		data: qryData,
		called,
		error,
		refetch: refetchQryData,
	} = useQuery<CMSGetFaqs, CMSGetFaqsVariables>(CMSQueryGetFaqs, {
		variables: {
			appType: [app],
		},
	});

	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 16 },
	};

	/* eslint-disable no-template-curly-in-string */
	const validateMessages = {
		required: '${label} is required!',
		types: {
			email: '${label} is not a valid email!',
			number: '${label} is not a valid number!',
		},
		number: {
			range: '${label} must be between ${min} and ${max}',
		},
	};
	/* eslint-enable no-template-curly-in-string */
	// Create
	const [AddFaq, { data: dataMt, loading: loadingMt, error: errorMt }] =
		useMutation<CMSAddFaq, CMSAddFaqVariables>(ADD_FAQ,
			{
				onCompleted: () => {
					form.resetFields();
					refetchQryData();
				},
		});

	const onFinish = (values: any) => {
		AddFaq({
			variables: {
				data: {
					...values,
				},
			},
		}).then(() => {
			form.resetFields();
			refetchQryData();
			setCreateAlert(true)
			setTimeout(() => {
			setCreateAlert(false)
			}, 2000)
		});
	};

	// Create
	const [DeleteFaq] = useMutation<CMSDeleteFaq, CMSDeleteFaqVariables>(
		DELETE_FAQ,
		{
			onCompleted: () => {
				refetchQryData();
				setDeleteAlert(true)
				setTimeout(() => {
				setDeleteAlert(false)
				}, 4000)
			},
		},
	);

	const navigate = useNavigate();

	return (
		<Card 
		title={
			<div>
			  <Button className="gx-mr-4" onClick={() => navigate(-1)} style={{margin:"0"}}>
				<CaretLeftOutlined/>
			  </Button>
			  Manage FAQs
			  
			</div>
		  }
		>
			<div className="gx-flex gx-flex-nowrap gx-mb-2">
				{/* <div className="gx-card-profile">
					<div className="ant-card-head ">
						<Button className="gx-mr-4" onClick={() => navigate(-1)}>
							Back
						</Button>

				
					</div>

					<div className="gx-pt-md-3"></div>
				</div> */}

				<Row>
				{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub2' && x.name === 'faq-add',
									) ? (
					<Col span={24} className="gx-mb-4">
							{createAlert &&  !loadingMt  && <Alert message="Created" type="success" showIcon />}

						<Form form={form}
							{...layout}
							name="nest-messages"
							onFinish={onFinish}
							validateMessages={validateMessages}
						>
							<Form.Item name={'question'} label="Question" rules={[{ required: true }]}>
								<Input.TextArea />
							</Form.Item>
							<Form.Item name={'answer'} label="Answer" rules={[{ required: true }]}>
								<Input.TextArea />
							</Form.Item>
							<Form.Item name={'category'} label="Category" rules={[{ required: true }]}>
								<Input.TextArea />
							</Form.Item>
							<Form.Item
								name="appType"
								label="App type"
								rules={[{ required: true }]}
							>
								<Select placeholder="Select a option and change input text above" getPopupContainer={(trigger) => trigger.parentElement}>
									<Select.Option value={AppTypeEnum.CUSTOMER}>
										{AppTypeEnum.CUSTOMER}
									</Select.Option>
									<Select.Option value={AppTypeEnum.PARTNER}>
										{AppTypeEnum.PARTNER}
									</Select.Option>
								</Select>
							</Form.Item>
							<Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
								<Button type="primary" htmlType="submit">
									Add
								</Button>
							</Form.Item>
						</Form>
					</Col>
									):("")}

					<Col span={24} className="gx-mb-4">
						{/* <h1>Manage FAQs</h1> */}
						<hr />
					</Col>
					<Col span={24} className="gx-mb-4">
						<Radio.Group
							value={app}
							onChange={onChange}
							style={{ marginBottom: 16 }}
						>
							<Radio.Button value={AppTypeEnum.CUSTOMER}>
								Customer Faqs
							</Radio.Button>
							\
							<Radio.Button value={AppTypeEnum.PARTNER}>
								Partner Faqs
							</Radio.Button>
							\
						</Radio.Group>
					</Col>
					{loading ? (
						<LoadingOutlined />
					) : (
						<Col span={24} className="gx-mb-4">
							{deleteAlert &&<Alert message="Deleted" type="success" showIcon />}
							
							<Table dataSource={qryData?.getFaqs}>
								<Column title="Question" dataIndex="question" key="question" />
								<Column title="Answer" dataIndex="answer" key="answer" />

								<Column
									title="Action"
									key="action"
									render={(_: any, record: any) => (
										<Space size="middle">
											<EditFaq input={record} onFinishAdd={refetchQryData}/>

											{userAuth?.userType === UserTypeEnum.ADMIN ||
					userAuth?.rights?.find(
						(x) => x.type === 'sub2' && x.name === 'faq-customer-delete'|| x.type === 'sub2' && x.name === 'faq-partner-delete',
					) ? (
											<a
												onClick={() => {
													DeleteFaq({
														variables: {
															id: record.id,
														},
													});
												}}
											>
												<DeleteOutlined />
											</a>
								):(
								<a
							// onClick={() => {
							// 	DeleteFaq({
							// 		variables: {
							// 			id: record.id,
							// 		},
							// 	});
							// }}
						>
							<DeleteOutlined />
						</a>
								)}


										</Space>
									)}
								/>

							</Table>
						</Col>
					)}
				</Row>
			</div>
		</Card>
	);
};

export default ManageFAQs;
