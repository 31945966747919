import { Drawer, Layout } from 'antd';
import React, { useState } from 'react';
import {
	NAV_STYLE_DRAWER,
	NAV_STYLE_FIXED,
	NAV_STYLE_MINI_SIDEBAR,
	NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
	NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
	TAB_SIZE,
	THEME_TYPE_LITE,
} from 'src/config/constants/themeSetting';
import SidebarContent from './sidebarContent';

const { Sider } = Layout;

const Sidebar: React.FC = () => {
	const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

	let navStyle = '';
	navStyle = NAV_STYLE_MINI_SIDEBAR;
	const themeType = THEME_TYPE_LITE;
	const width = window.innerWidth;

	const onToggleCollapsedNav = () => {
		console.log('onToggleCollapsedNav');
	};

	let drawerStyle = 'gx-collapsed-sidebar';

	if (navStyle === NAV_STYLE_FIXED) {
		drawerStyle = '';
	} else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
		drawerStyle = 'gx-mini-sidebar gx-mini-custom-sidebar';
	} else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
		drawerStyle = 'gx-custom-sidebar';
	} else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
		drawerStyle = 'gx-mini-sidebar';
	} else if (navStyle === NAV_STYLE_DRAWER) {
		drawerStyle = 'gx-collapsed-sidebar';
	}
	if (
		(navStyle === NAV_STYLE_FIXED ||
			navStyle === NAV_STYLE_MINI_SIDEBAR ||
			navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) &&
		width < TAB_SIZE
	) {
		drawerStyle = 'gx-collapsed-sidebar';
	}
	return (
		<Sider
			className={`gx-app-sidebar gx-layout-sider-dark`}
			trigger={null}
			collapsed={
				width < TAB_SIZE
					? false
					: sidebarCollapsed || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR
			}
			theme={'dark'}
			collapsible
			style={{ overflowY: 'auto' }}
		>
			{navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ? (
				<Drawer
					className={`gx-drawer-sidebar gx-drawer-sidebar-dark`}
					placement="left"
					closable={false}
					onClose={onToggleCollapsedNav}
					visible={true}
				>
					<SidebarContent />
				</Drawer>
			) : (
				<SidebarContent />
			)}
		</Sider>
	);
};
export default Sidebar;
