import {
	Card,
	Col,
	Row,
	Select,
	Space,
	DatePicker,
	Spin,
	Pagination,
	Modal,
	Table,
	Button,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { CSVLink } from 'react-csv';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import img1 from './img1.jpeg';
import img2 from './img2.jpeg';
import img3 from './img3.jpeg';
import { useParams } from 'react-router-dom';
import { CMSQueryGetUser } from 'src/lib/gql/queries/users';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
	CMSGetUser,
	CMSGetUserVariables,
} from 'src/lib/gql/generated/CMSGetUser';
import { CMSGetServiceAreas } from 'src/lib/gql/generated/CMSGetServiceAreas';
import { CMSQueryGetServiceAreas } from 'src/lib/gql/queries/services';
import { CMSGetAllServiceCategories } from 'src/lib/gql/generated/CMSGetAllServiceCategories';
import { CMSQueryGetAllServiceCategories } from 'src/lib/gql/queries/services';
import {
	CMSGetServices,
	CMSGetServicesVariables,
} from 'src/lib/gql/generated/CMSGetServices';
import { CMSQueryGetServices } from 'src/lib/gql/queries/services';
import {
	CMSQueryRevenueGeneration,
	CMSQueryServicePartnerReport,
	CMSQueryJobReport,
	CMSQueryExportJobReport,
} from 'src/lib/gql/queries/reports';
import { DatePeriodEnums3 } from 'src/lib/gql/generated/globalTypes';
import moment from 'moment';
import { RouteList } from 'src/config/constants/routes';
import { Link } from 'react-router-dom';
import {
	CMSGetPartners,
	CMSGetPartnersVariables,
	CMSGetPartners_getUsers_data,
} from 'src/lib/gql/generated/CMSGetPartners';
import {
	PartnerPendingTaskEnum,
	UserTypeEnum,
} from 'src/lib/gql/generated/globalTypes';
import {
	CMSGetUsers,
	CMSGetUsersVariables,
} from 'src/lib/gql/generated/CMSGetUsers';
import { CMSQueryGetUsers } from 'src/lib/gql/queries/users';
import { CMSQueryGetPartners } from 'src/lib/gql/queries/users';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userAtom, userTokenAtom } from 'src/lib/recoil/userCoil';

const Jobs = () => {
	const Option = Select.Option;
	const { RangePicker } = DatePicker;
	const [dateValue, SetDateValue] = useState<any>();
	const [isOpenDate, SetIsOpenDate] = useState(false);
	const [saveDate, setSaveDate] = useState('');
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [startDate, setStartDate] = useState<any>('');
	const [endDate, setEndDate] = useState<any>('');
	const [statusValue, SetStatusValue] = useState<any>();
	const [providerValue, SetProviderValue] = useState<any>();
	const [areaValue, SetAreaValue] = useState<any>();
	const [serviceValue, SetServiceValue] = useState<any>();
	const [serviceCateValue, SetServiceCateValue] = useState<any>();
	const [categoryService, SetCategoryService] = useState<any>();
	const { id } = useParams<{ id: string }>();
	const [accountType, setAccountType] = useState(UserTypeEnum.PARTNER);
	const [filterName, SetFilterName] = useState('');
	const [csvLoading, setCsvLoading] = useState(false);
	const [csvData, setCSVData] = useState('');
	const [filename, setFilename] = useState('');

	const userAuth = useRecoilValue(userAtom);

	const { data: qryDatas, refetch: refetchQryData, loading: tableLoading } = useQuery(
		CMSQueryJobReport,
		{
			variables: {
				pagination: pagination,
				filter: {
					areaId: areaValue,
					serviceCategoryId: serviceCateValue,
					serviceId: serviceValue,
					providerId: providerValue,
					fromDate: startDate ? startDate : null,
					toDate: endDate ? endDate : null,
					datePeriod: dateValue ? dateValue : null,
				},
			},
			fetchPolicy: 'network-only'
		},
	);

	const [getExportData, { data: qryDatass }] = useLazyQuery(
		CMSQueryExportJobReport,
	);

	const { data: qryDataPartner, refetch: refetchQryDataPartner } = useQuery<
		CMSGetUsers,
		CMSGetUsersVariables
	>(CMSQueryGetPartners, {
		variables: {
			pagination: pagination,
			filters: {
				type: accountType,
				name: filterName,
				// phone: filterPhone,
			},
		},
	});

	const { loading, data: qryData } = useQuery<CMSGetUser, CMSGetUserVariables>(
		CMSQueryGetUser,
		{
			variables: {
				id: id || '',
			},
		},
	);

	const fetchAndExportCSV = async () => {
		setCsvLoading(true);

		const resp = await getExportData({
			variables: {
				filter: {
					areaId: areaValue,
					serviceCategoryId: serviceCateValue,
					serviceId: serviceValue,
					providerId: providerValue,
					fromDate: startDate ? startDate : null,
					toDate: endDate ? endDate : null,
					datePeriod: dateValue ? dateValue : null,
				},
			},
		});

		const currentDate = new Date();
		const formattedDate = currentDate.toISOString().split('T')[0];
		const formattedTime = currentDate
			.toTimeString()
			.split(' ')[0]
			.replace(/:/g, '');
		const hour = parseInt(formattedTime.substring(0, 2), 10);
		const minute = parseInt(formattedTime.substring(2, 4), 10);
		const second = parseInt(formattedTime.substring(4, 6), 10);

		const data =
			resp?.data?.exportJobReport?.map((record) => {
				// Map the data source to match the structure of the CSV
				const roundedTotalTime = parseFloat(record.totalTimeTaken).toFixed(2);
				const roundedtimeDifference = parseFloat(record.timeDifference).toFixed(
					2,
				);
				return {
					'Job ID': record.workCode,
					Customer: record.bookingService.booking.user?.name,
					'Service Name': record.bookingService.service?.name,
					Provider: record.servicePartner?.name,
					'Created On': record.bookingService.booking.createDateTime
						? new Date(
								record.bookingService.booking.createDateTime,
						  ).toLocaleString('en-US', {
								year: 'numeric',
								month: 'short',
								day: 'numeric',
								hour: 'numeric',
								minute: 'numeric',
								second: 'numeric',
								hour12: true,
						  })
						: '',
					'Allocated Hours': record.allocatedHours
						? `${record.allocatedHours} Hours`
						: '',
					'No: of Reschedules': record.Reschedules,
					'Start Date Time': record.startDateTime
						? new Date(record.startDateTime).toLocaleString('en-US', {
								year: 'numeric',
								month: 'short',
								day: 'numeric',
								hour: 'numeric',
								minute: 'numeric',
								second: 'numeric',
								hour12: true,
						  })
						: '',
					'End Date Time': record.endDateTime
						? new Date(record.endDateTime).toLocaleString('en-US', {
								year: 'numeric',
								month: 'short',
								day: 'numeric',
								hour: 'numeric',
								minute: 'numeric',
								second: 'numeric',
								hour12: true,
						  })
						: '',
					'Started On': record.actualStartDateTime
						? new Date(record.actualStartDateTime).toLocaleString('en-US', {
								year: 'numeric',
								month: 'short',
								day: 'numeric',
								hour: 'numeric',
								minute: 'numeric',
								second: 'numeric',
								hour12: true,
						  })
						: '',
					'Completed On': record.actualEndDateTime
						? new Date(record.actualEndDateTime).toLocaleString('en-US', {
								year: 'numeric',
								month: 'short',
								day: 'numeric',
								hour: 'numeric',
								minute: 'numeric',
								second: 'numeric',
								hour12: true,
						  })
						: '',
					'Total Time Taken': record.totalTimeTaken
						? `${roundedTotalTime} Hours`
						: '',
					'Planned vs Actual Time Difference': record.timeDifference
						? `${roundedtimeDifference} Hours`
						: '',
					'Job Status': record.bookingServiceItemStatus,
				};
			}) || [];

		setCSVData(data);

		setFilename(`JobReport-${formattedDate}_${hour}:${minute}:${second}.csv`);

		setCsvLoading(false);

		document?.getElementById('csv-link')?.click();
	};

	// console.log("12345600",qryData?.getUser);
	const { data: qryDataArea, refetch: refetchQryDatas } =
		useQuery<CMSGetServiceAreas>(CMSQueryGetServiceAreas, {
			errorPolicy: 'all',
		});
	const { data: qryDataServiceCate, refetch: refetchQryDataServiceCate } =
		useQuery<CMSGetAllServiceCategories>(CMSQueryGetAllServiceCategories);
	// console.log("service categroy",qryDataService);

	// Get all services
	const { data: qryDataService, refetch: refetchQryDataService } = useQuery<
		CMSGetServices,
		CMSGetServicesVariables
	>(CMSQueryGetServices, {
		variables: {
			showAll: true,
			search: '',
		},
		errorPolicy: 'all',
	});
	// console.log("service",qryDataService);
	useEffect(() => {
		const daaaa = qryDataServiceCate?.getServiceCategories?.filter(
			(value) => value.id === serviceCateValue?.find((value) => value),
		);
		SetCategoryService(daaaa);
		if (serviceCateValue?.length < 1) {
			SetServiceValue([]);
		}
	}, [serviceCateValue]);

	const data = [{ name: 'Page A', uv: 400, pv: 2400, amt: 2400 }];
	// useEffect(()=>{
	//     console.log("isOpenDate",isOpenDate);
	//     console.log("dateValue",dateValue);
	//     //    if(dateValue.[]==="custom"){
	//     //     SetIsOpenDate(true)
	//     // }
	// },[isOpenDate,dateValue])

	// Extract the data to be exported

	const onSearch = (value) => {
		console.log('search:', value);
		// setSerachVal(value)
	};

	useEffect(() => {
		refetchQryData();
	}, [pagination, refetchQryData]);

	const columns = [
		{
			title: 'Job ID',
			dataIndex: 'workCode',
			key: 'workCode',
			align: 'center' as const,
			width: 100,
			render: (text: string, record: any) => {
				return <span>{record?.workCode}</span>;
			},
		},
		{
			title: 'Customer',
			dataIndex: 'customer',
			key: 'customer',
			align: 'center' as const,
			width: 100,
			render: (name, record) => (
				<span className="gx-link">
					<Link
						to={
							RouteList.users.path +
							'/' +
							record?.bookingService?.booking?.user?.id
						}
					>
						<span className="gx-link">
							{record?.bookingService?.booking?.user?.name}
						</span>
					</Link>
				</span>
			),
		},
		{
			title: 'Service Name',
			dataIndex: 'bookingService.service.name',
			key: 'bookingService.service.name',
			align: 'center' as const,
			width: 100,
			render: (text: string, record: any) => {
				return <span>{record?.bookingService?.service?.name}</span>;
			},
		},
		{
			title: 'Provider',
			dataIndex: 'servicePartner.name',
			key: 'servicePartner.name',
			align: 'center' as const,
			width: 100,
			render: (name, record) => (
				<span className="gx-link">
					<Link to={RouteList.partners.path + '/' + record?.servicePartner?.id}>
						<span className="gx-link">{record?.servicePartner?.name}</span>
					</Link>
				</span>
			),
		},
		{
			title: 'Planned',
			// dataIndex: 'phone',
			// key: 'phone',
			// width: 150,
			// align: 'center' as const,
			// render: (phone) => <span>{phone} </span>,
			children: [
				{
					title: 'Created On',
					dataIndex: 'bookingService.booking.createDateTime',
					key: 'bookingService.booking.createDateTime',
					align: 'center' as const,
					render: (text: string, record: any) => {
						return (
							<span>
								<>
									{moment(
										record?.bookingService?.booking?.createDateTime,
									).format('lll')}
									{/* {moment("2023-08-01T10:24:59.792Z").format('lll')} */}
								</>
							</span>
						);
					},
				},
				{
					title: 'Allocated Hours',
					dataIndex: 'allocatedHours',
					key: 'allocatedHours',
					width: 100,
					render: (text: string, record: any) => {
						return (
							<span>
								{record?.allocatedHours ? (
									<>{record?.allocatedHours} Hours</>
								) : (
									''
								)}
							</span>
						);
					},
				},
				{
					title: 'No: of Reschedules',
					dataIndex: 'number',
					key: 'number',
					width: 100,
				},
				{
					title: 'Start Date Time',
					dataIndex: 'startDateTime',
					key: 'startDateTime',
					align: 'center' as const,
					render: (text: string, record: any) => {
						return (
							<span>
								{record?.startDateTime ? (
									<>
										{moment(record?.startDateTime).format('lll')}
										{/* {moment("2023-08-01T10:24:59.792Z").format('lll')} */}
									</>
								) : (
									''
								)}
							</span>
						);
					},
				},
				{
					title: 'End Date Time',
					dataIndex: 'endDateTime',
					key: 'endDateTime',
					align: 'center' as const,
					render: (text: string, record: any) => {
						return (
							<span>
								{record?.endDateTime ? (
									<>
										{moment(record?.endDateTime).format('lll')}
										{/* {moment("2023-08-01T10:24:59.792Z").format('lll')} */}
									</>
								) : (
									''
								)}
							</span>
						);
					},
				},
			],
		},
		{
			title: 'Actual',
			// dataIndex: 'phone',
			// key: 'phone',
			// width: 150,
			// align: 'center' as const,
			// render: (phone) => <span>{phone} </span>,
			children: [
				{
					title: 'Started On',
					dataIndex: 'actualStartDateTime',
					key: 'actualStartDateTime',
					align: 'center' as const,
					render: (text: string, record: any) => {
						return (
							<span>
								{record?.actualStartDateTime ? (
									<>
										{moment(record?.actualStartDateTime).format('lll')}
										{/* {moment("2023-08-01T10:24:59.792Z").format('lll')} */}
									</>
								) : (
									''
								)}
							</span>
						);
					},
				},
				{
					title: 'Completed On',
					dataIndex: 'actualEndDateTime',
					key: 'actualEndDateTime',
					align: 'center' as const,
					render: (text: string, record: any) => {
						return (
							<span>
								{record?.actualEndDateTime ? (
									<>
										{moment(record?.actualEndDateTime).format('lll')}
										{/* {moment("2023-08-01T10:24:59.792Z").format('lll')} */}
									</>
								) : (
									''
								)}
							</span>
						);
					},
				},
				{
					title: 'Total Time Taken',
					dataIndex: 'totalTimeTaken',
					key: 'totalTimeTaken',
					width: 100,
					render: (text: string, record: any) => {
						const roundedTotalTime = parseFloat(record.totalTimeTaken).toFixed(
							2,
						);
						return (
							<span>
								{record?.totalTimeTaken ? <>{roundedTotalTime} Hours</> : ''}
							</span>
						);
					},
				},
			],
		},
		{
			title: 'Planned vs Actual Time Difference ',
			dataIndex: 'timeDifference',
			key: 'timeDifference',
			align: 'center' as const,
			render: (text: string, record: any) => {
				const roundedtimeDifference = parseFloat(record.timeDifference).toFixed(
					2,
				);
				return (
					<span>
						{record?.timeDifference ? <>{roundedtimeDifference} Hours</> : ''}
					</span>
				);
			},
		},
	];

	// const roundedNumber  = (originalNumber ) => {
	// 	const roundedNumber = originalNumber.toFixed(2)
	// 	return roundedNumber;
	//   };

	const handleServiceCate = (value) => {
		SetServiceCateValue(value);
	};
	const handleArea = (value) => {
		SetAreaValue(value);
	};
	const handleService = (value) => {
		SetServiceValue(value);
	};
	const handleProvider = (value) => {
		SetProviderValue(value);
	};
	const handleChanges = (value) => {
		SetDateValue(value);
		setStartDate(null);
		setEndDate(null);
		if (value === 'CUSTOM') {
			SetIsOpenDate(true);
		}
	};

	const handleOk = () => {
		SetIsOpenDate(false);
	};
	const handleCancel = () => {
		SetIsOpenDate(false);
	};
	const handleDate = (dates) => {
		setSaveDate(dates);
		if (dates && dates?.length === 2) {
			const startDates = moment(dates[0]?._d).toISOString();
			const endDates = moment(dates[1]?._d).toISOString();
			// setStartDate(moment(startDates).format('MMMM Do YYYY'));
			// setEndDate(moment(endDates).format('MMMM Do YYYY'));
			setStartDate(startDates);
			setEndDate(endDates);
		}
	};
	const ClearAll = () => {
		SetServiceCateValue([]);
		SetServiceValue([]);
		SetAreaValue([]);
		SetProviderValue([]);
		SetDateValue(null);
		setStartDate(null);
		setEndDate(null);
	};

	const handlePaginationChange = (page: number, pageSize?: number) => {
		setPagination((prevPagination) => ({
			...prevPagination,
			pageNumber: page,
		}));
	};

	const handlePageSizeChange = (current: number, size: number) => {
		setPagination((prevPagination) => ({
			pageNumber: 1, // Reset to the first page when changing page size
			pageSize: size,
		}));
	};

	const currentDate = new Date();
	const formattedDate = currentDate.toISOString().split('T')[0];
	const formattedTime = currentDate
		.toTimeString()
		.split(' ')[0]
		.replace(/:/g, '');
	const hour = parseInt(formattedTime.substring(0, 2), 10);
	const minute = parseInt(formattedTime.substring(2, 4), 10);
	const second = parseInt(formattedTime.substring(4, 6), 10);

	return (
		<>
			<Card>
				<Row>
					<Col span={20}>
						<h3>Filters</h3>
					</Col>
					<Col span={4}>
						<Button type="primary" onClick={ClearAll}>
							Clear All
						</Button>
					</Col>
				</Row>{' '}
				<hr />
				<Row>
					<Col xl={4} lg={12} md={12} sm={12} xs={24}>
						<span>Area</span>
						<Select
							getPopupContainer={(trigger) => trigger.parentElement}
							allowClear
							mode="multiple"
							style={{
								width: '100%',
							}}
							placeholder="Select Area"
							// defaultValue={['All']}
							onChange={handleArea}
							// onSearch={handleArea}
							value={areaValue}
							optionLabelProp="label"
							// options={options}
							filterOption={(input, option: any) => {
								const children = option?.label;
								try {
									if (typeof children === 'string') {
										return children.toLowerCase().includes(input);
									}
								} catch (error) {
									console.error('Error filtering option:', error);
								}

								return true;
							}}
							onSearch={onSearch}
						>
							{/* <Option value="one" label={"one"} >
						<Space>
							<span role="img" aria-label={"one"}>
							one
							</span>
						</Space>
						</Option>
						<Option value="two" label={"two"} >
						<Space>
							<span role="img" aria-label={"two"}>
							two
							</span>
						</Space>
						</Option>
						<Option value="sixx" label={"sixx"} >
						<Space>
							<span role="img" aria-label={"sixx"}>
							sixx
							</span>
						</Space>
						</Option>
						<Option value="month" label={"month"} >
						<Space>
							<span role="img" aria-label={"month"}>
							month
							</span>
						</Space>
						</Option> */}
							{qryDataArea?.getAreas.map((entry, index) => (
								<Option value={entry?.id} label={entry?.name} key={index}>
									<Space>
										<span role="img" aria-label={entry?.name}>
											{entry?.name}
										</span>
									</Space>
								</Option>
							))}
						</Select>
					</Col>
					<Col xl={5} lg={12} md={12} sm={12} xs={24}>
						<span>Service Category</span>

						<Select
							getPopupContainer={(trigger) => trigger.parentElement}
							allowClear
							showSearch
							mode="multiple"
							style={{
								width: '100%',
							}}
							placeholder="Select Service Category"
							// defaultValue={['All']}
							onChange={handleServiceCate}
							// onSearch={handleServiceCate}
							optionLabelProp="label"
							value={serviceCateValue}
							filterOption={(input, option: any) => {
								const children = option?.label;
								try {
									if (typeof children === 'string') {
										return children.toLowerCase().includes(input);
									}
								} catch (error) {
									console.error('Error filtering option:', error);
								}

								return true;
							}}
							onSearch={onSearch}
						>
							{/* <Option value={null} label={"All"} >
									<Space>
										<span role="img" aria-label={"All"}>
											All
										</span>
									</Space>
								</Option> */}
							{qryDataServiceCate?.getServiceCategories.map((entry, index) => (
								<Option value={entry?.id} label={entry?.name} key={index}>
									<Space>
										<span role="img" aria-label={entry?.name}>
											{entry?.name}
										</span>
									</Space>
								</Option>
							))}
						</Select>
					</Col>
					<Col xl={5} lg={12} md={12} sm={12} xs={24}>
						<span>Services</span>
						<Select
							getPopupContainer={(trigger) => trigger.parentElement}
							mode="multiple"
							allowClear
							showSearch
							// maxTagCount="responsive"
							style={{
								width: '100%',
							}}
							placeholder="Select Services"
							// defaultValue={['All']}
							onChange={handleService}
							// onSearch={handleService}
							value={serviceValue}
							filterOption={(input, option: any) => {
								const children = option?.label;
								try {
									if (typeof children === 'string') {
										return children.toLowerCase().includes(input);
									}
								} catch (error) {
									console.error('Error filtering option:', error);
								}
								return true;
							}}
							onSearch={onSearch}
							optionLabelProp="label"
						>
							{/* <Option value={null} label={"All"} >
									<Space>
										<span role="img" aria-label={"All"}>
											All
										</span>
									</Space>
								</Option> */}

							{serviceCateValue && serviceCateValue?.length !== 0 ? (
								<>
									{categoryService?.map((entry, index) => (
										<>
											{entry?.services?.map((entry, index) => (
												<Option
													value={entry?.id}
													label={entry?.name}
													key={index}
												>
													<Space>
														<span role="img" aria-label={entry?.name}>
															{entry?.name}
														</span>
													</Space>
												</Option>
											))}
											{/* {qryDataServiceCate?.filter((value) => value.id ===serviceCateValue)} */}
										</>
									))}
								</>
							) : (
								<>
									{qryDataService?.getServices.map((entry, index) => (
										<Option value={entry?.id} label={entry?.name} key={index}>
											<Space>
												<span role="img" aria-label={entry?.name}>
													{entry?.name}
												</span>
											</Space>
										</Option>
									))}
								</>
							)}
						</Select>
					</Col>
					<Col xl={5} lg={12} md={12} sm={12} xs={24}>
						<span>Provider</span>
						<Select
							getPopupContainer={(trigger) => trigger.parentElement}
							allowClear
							mode="multiple"
							style={{
								width: '100%',
							}}
							placeholder="Select Provider"
							// defaultValue={['All']}
							onChange={handleProvider}
							// onSearch={handleArea}
							value={providerValue}
							optionLabelProp="label"
							// options={options}
							filterOption={(input, option: any) => {
								const children = option?.label;
								try {
									if (typeof children === 'string') {
										return children.toLowerCase().includes(input);
									}
								} catch (error) {
									console.error('Error filtering option:', error);
								}

								return true;
							}}
							onSearch={onSearch}
						>
							{qryDataPartner?.getUsers?.data.map((entry, index) => (
								<Option value={entry?.id} label={entry?.name} key={index}>
									<Space>
										{/* <span role="img" aria-label={entry?.name}> */}
										{entry?.name}
										{/* </span> */}
									</Space>
								</Option>
							))}
						</Select>
					</Col>
					<Col xl={5} lg={12} md={12} sm={12} xs={24}>
						<span>Date Range</span>

						<Select
							getPopupContainer={(trigger) => trigger.parentElement}
							style={{
								width: '100%',
							}}
							placeholder="select one date"
							defaultValue={saveDate ? saveDate : 'All Time'}
							onChange={handleChanges}
							optionLabelProp="label"
							value={dateValue}
						>
							<Option value={null} label="All Time">
								<Space>All Time</Space>
							</Option>
							<Option value="TODAY" label="TODAY">
								<Space>{DatePeriodEnums3.TODAY}</Space>
							</Option>
							<Option value="YESTERDAY" label="YESTERDAY">
								<Space>{DatePeriodEnums3.YESTERDAY}</Space>
							</Option>
							<Option value="LAST_3_DAYS" label="LAST_3_DAYS">
								<Space>{DatePeriodEnums3.LAST_3_DAYS}</Space>
							</Option>
							<Option value="THIS_WEEK" label="THIS_WEEK">
								<Space>{DatePeriodEnums3.THIS_WEEK}</Space>
							</Option>
							<Option value="THIS_MONTH" label="THIS_MONTH">
								<Space>{DatePeriodEnums3.THIS_MONTH}</Space>
							</Option>
							<Option value="THIS_YEAR" label="THIS_YEAR">
								<Space>{DatePeriodEnums3.THIS_YEAR}</Space>
							</Option>
							<Option value="CUSTOM" label="CUSTOM">
								<Space>{DatePeriodEnums3.CUSTOM}</Space>
							</Option>

							{/* <Option value="dateandtime" label="Custom">
                        {dateValue ==="custom" && 

                        <Space direction="vertical" size={12}>
                        <RangePicker />
                        </Space> }  
                        </Option> */}
						</Select>
					</Col>
				</Row>
				<Row style={{ float: 'right' }}>
					<div style={{ float: 'right' }}>
						{startDate ? moment(startDate).format('lll') : ''}
						{startDate ? '-' : ''}{' '}
						{endDate ? moment(endDate).format('lll') : ''}
					</div>
				</Row>
				{isOpenDate && (
					<Modal
						title="Date"
						visible={isOpenDate}
						onOk={handleOk}
						onCancel={handleCancel}
						// width={800}
					>
						<div style={{ textAlign: 'center' }}>
							<Space direction="vertical" size={6}>
								<RangePicker onChange={handleDate} />
							</Space>
						</div>
					</Modal>
				)}
			</Card>
			<br />
			{/* 
                    <Row>
						<Col span={8} >
                        <Row style={{marginLeft:"0rem"}}>
						<Card style={{borderRadius:"10px",width:"50%"}}>
						<div className="gx-media-body" style={{textAlign:"center"}}>
						<h5>Net Profit</h5>
						<h1 className="gx-mb-1 gx-text-grey"><span style={{fontSize: "18px"}}>₹</span>{" "}123456</h1>
						</div>
						</Card>
                          
						<Card style={{borderRadius:"10px",width:"50%"}}>
						<div className="gx-media-body" style={{textAlign:"center"}}>
						<h5>Commission Earnings</h5>
						<h1 className="gx-mb-1 gx-text-grey"><span style={{fontSize: "18px"}}>₹</span>{" "}1</h1>
						</div>			
							</Card>
                        </Row>
						<Card style={{borderRadius:"10px",width: "105%",marginTop:"1rem"}}>
						<div className="gx-media-body" >
                        <Row>
                            <Col span={6}>
                                
                            <img src={img1} style={{width:"100px",height:"50px"}}/>

						
                        </Col>
                        <Col span={18} style={{float:"right"}}>
                        <span>Total Expense</span>
						<h1 className="gx-mb-1 gx-text-grey"><span style={{fontSize: "18px"}}>₹</span>{" "}1</h1>
                        </Col>
                        </Row></div>
                        <br/>
                        <Row>
                            <Col span={4}>
                            Campaign
                            </Col>
                            <Col span={10} style={{textAlign:"center"}}>
                            Normal Discount
                                </Col>
                                <Col span={10}>
                                Coupon  Discount
                                </Col>
                                <Col span={4} style={{textAlign:"center"}}>
                                  <span className="gx-mb-1 gx-text-grey" style={{fontSize: "15px"}}>₹{" "}10</span>
                                </Col>
                                 <Col span={10} style={{textAlign:"center"}}>
                                   <span className="gx-mb-1 gx-text-grey" style={{fontSize: "15px"}}>₹{" "}20</span>
                                </Col>
                                <Col span={10} style={{textAlign:"center"}}>
                                 <span className="gx-mb-1 gx-text-grey" style={{fontSize: "15px"}}>₹{" "}30</span>
                                </Col>
                        </Row>
                   
						</Card>
						</Col>
						<Col  span={6} >
					
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"1rem"}}>
                        <BarChart width={600} height={300} data={data}>
                        <XAxis dataKey="name" stroke="#8884d8" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <Bar dataKey="uv" fill="#8884d8" barSize={30} />
                        </BarChart>
						</div>
						</Col>
						</Row> */}
			<br />
			<Row justify="end" style={{ marginBottom: 16 }}>
				{userAuth?.userType === UserTypeEnum.ADMIN ||
				userAuth?.rights?.find(
					(x) => x.type === 'sub2' && x.name === 'jobs-export-csv',
				) ? (
					<>
						<CSVLink
							id="csv-link"
							data={csvData}
							filename={filename}
							className="ant-btn ant-btn-primary"
							target="_blank"
							style={{ display: 'none' }}
						>
							<DownloadOutlined /> Export CSV
						</CSVLink>

						<Button
							type="primary"
							onClick={fetchAndExportCSV}
							style={{ width: '7.3rem' }}
						>
							{csvLoading ? (
								<Spin
									indicator={
										<LoadingOutlined
											style={{ fontSize: 22, color: 'white' }}
											spin
										/>
									}
								/>
							) : (
								<>
									<DownloadOutlined /> Export CSV
								</>
							)}
						</Button>
					</>
				) : (
					<></>
				)}
			</Row>
			<Row>
				<Col
					span={24}
					style={{
						marginBottom: 16,
						paddingTop: '1rem',
						backgroundColor: '#f6f6f6',
						paddingBottom: '5px',
					}}
				>
					<div style={{ textAlign: 'center' }}>
						<Pagination
							key="pagination"
							defaultCurrent={qryDatas?.getJobReport?.pageInfo.currentPage}
							current={qryDatas?.getJobReport?.pageInfo.currentPage}
							pageSize={pagination.pageSize}
							total={qryDatas?.getJobReport?.pageInfo.totalCount}
							showTotal={(total) => `Total ${total} items`}
							onChange={handlePaginationChange}
							showSizeChanger
							pageSizeOptions={['10', '20', '50', '100']}
							onShowSizeChange={handlePageSizeChange}
							selectComponentClass={Select}
							showLessItems
						/>
					</div>
				</Col>
			</Row>
			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}
			{!loading && (
				<Table
					className="gx-table-responsive"
					columns={columns}
					dataSource={qryDatas?.getJobReport?.data}
					pagination={false}
					bordered
					size="middle"
					scroll={{ x: 'calc(700px + 50%)', y: 240 }}
					loading={tableLoading}
				/>
			)}
		</>
	);
};

export default Jobs;
