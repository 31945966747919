import { useMutation, useQuery } from '@apollo/client';
import {
	Alert,
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Input,
	Modal,
	Row,
	Typography,
} from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { useState,useEffect } from 'react';
import { CMSGetPartner_getUser } from 'src/lib/gql/generated/CMSGetPartner';
import { CMSGetServiceAreas } from 'src/lib/gql/generated/CMSGetServiceAreas';
import {
	CMSUpdatePartnerServiceAreas,
	CMSUpdatePartnerServiceAreasVariables,
} from 'src/lib/gql/generated/CMSUpdatePartnerServiceAreas';
import { UPDATE_PARTNER_SERVICE_AREAS } from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetServiceAreas } from 'src/lib/gql/queries/services';

interface Props {
	input?: CMSGetPartner_getUser | null;
	onFinishAdd?: () => void;
}

const UpdatePartnerServiceAreas: React.FC<Props> = ({ input, onFinishAdd }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [search, setSearch] = useState('');
	const [checkedValues, setCheckedValues] = useState(
		input?.partnerDetails?.serviceAreas?.map((area) => area.id),
	);
	const [allSelectedIds, setAllSelectedIds] = useState<string[] | undefined>([]);

	// Get all Areas
	const {
		loading,
		data: qryData,
		refetch: refetchQryData,
	} = useQuery<CMSGetServiceAreas>(CMSQueryGetServiceAreas, {
		errorPolicy: 'all',
	});

	// Update
	const [update, { data: dataMtU, loading: loadingMtU, error: errorMtU }] =
		useMutation<
			CMSUpdatePartnerServiceAreas,
			CMSUpdatePartnerServiceAreasVariables
		>(UPDATE_PARTNER_SERVICE_AREAS);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
		setIsModalVisible(false);
	};
	useEffect(() => {
		setCheckedValues(
			input?.partnerDetails?.serviceAreas?.map((service) => service.id),
		);
		setAllSelectedIds(
			input?.partnerDetails?.serviceAreas?.map((service) => service.id),
		);
	}, [input]);
	const [form] = Form.useForm();

	const onFinish = (values) => {
		if (input) {
			update({
				variables: {
					partnerId: input?.id,
					areas: allSelectedIds ? allSelectedIds : [],
				},
			}).then(() => {
				form.resetFields();
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
					}
					setIsModalVisible(false);
				}, 1500);
			});
		}
	};
	const handleCheckboxChange = (e) => {
		const serviceId = e.target.value;
		if (e.target.checked) {
			// Add to the list of selected IDs
			setAllSelectedIds((prevIds: any) => [...prevIds, serviceId]);
		} else {
			// Remove from the list of selected IDs
			setAllSelectedIds((prevIds: any) =>
				prevIds.filter((id) => id !== serviceId),
			);
		}
	};

	const onChange = (checkedValues) => {
		setCheckedValues(checkedValues);
	};

	const onSelectAll = () => {
		setAllSelectedIds(qryData?.getAreas.map((area) => area.id));
	};

	const onUnselectAll = () => {
		setAllSelectedIds([]);
	};

	return (
		<>
		<div style={{paddingRight:"1rem"}}>
			<Button type="primary" onClick={showModal}>
				Edit
			</Button></div>
			<Modal
				title={
					<>
					<Row>
					<Col span={6}>
					<Button type="primary" onClick={handleOk} style={{margin:"0"}}>
					Save
					</Button>
					</Col>
					<Col span={12}>
					<p style={{textAlign:"center"}}>Edit Info</p>
					</Col>
					</Row>					
					</>
				}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={window.screen.width * 0.8}
				footer={null}
			>
				{/* Updating */}
				{dataMtU && <Alert message="Updated" type="success" showIcon />}
				{loadingMtU && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtU && (
					<Alert
						message={`Submission error! ${errorMtU.message}`}
						type="error"
						showIcon
					/>
				)}

				<Row>
					<Col span={12}>
					<Input
					placeholder="Search by name or code"
					onChange={(e) => setSearch(e.target.value)}
				/>
					</Col >
					<Col span={6}>
				
					</Col >
					<Col span={6} style={{textAlign:"end"}}>
					<Button type="primary" onClick={onSelectAll}>
					Select All
				</Button>
					<Button type="default" onClick={onUnselectAll}>
					Unselect All
				</Button>
				</Col>
				</Row>

				<Form
					form={form}
					onFinish={onFinish}
					scrollToFirstError
					initialValues={
						!input
							? {}
							: {
									...input,
									...input?.partnerDetails?.address,
							  }
					}
					style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }}
				>
					{/* <Checkbox.Group
						style={{ width: '100%' }}
						onChange={onChange}
						value={checkedValues}
					> */}
						<Row>
							{qryData?.getAreas
								.filter(
									(area) =>
										area.name.toLowerCase().includes(search.toLowerCase()) ||
										area.code.toLowerCase().includes(search.toLowerCase()),
								)
								.map((area) => {
									const isSelected = allSelectedIds?.includes(area.id);
									return (
										<Col key={area.id} span={8}>
											<br />
											<Card type="inner">
												<Checkbox value={area.id} checked={isSelected} onChange={handleCheckboxChange}>
													<Typography.Title level={4} style={{ margin: 0 }}>
														{area.name}
													</Typography.Title>
													<br />
													<Meta
														title={'Code: ' + area.code}
														description={'City: ' + area.city?.name}
													/>
												</Checkbox>
											</Card>
										</Col>
									);
								})}
						</Row>
					{/* </Checkbox.Group> */}
				</Form>
			</Modal>
		</>
	);
};
export default UpdatePartnerServiceAreas;
