import React from 'react';
import { Col, Row } from 'antd';

//Components Import
import SiteVisit from 'src/components/dashboard/SiteVisit';
import Overview from 'src/components/dashboard/Overview';
import SiteAudience from 'src/components/dashboard/SiteAudience';
import TotalRevenueCard from 'src/components/dashboard/TotalRevenueCard';
// import NewCustomers from 'src/components/dashboard/NewCustomers';
import GrowthCard from 'src/components/dashboard/GrowthCard';
import { trafficData } from 'src/pages/reports/data';

interface Props {}

const Loyalty: React.FC = (props: Props) => {
	return (
		<Row>
			<Col span={24}>
				<div className="gx-card">
					<div className="gx-card-body">
						<Row>
							<Col
								xl={6}
								lg={12}
								md={12}
								sm={12}
								xs={24}
								className="gx-audi-col"
							>
								<SiteAudience title="Users" />
							</Col>

							<Col
								xl={12}
								lg={24}
								md={24}
								sm={24}
								xs={24}
								className="gx-visit-col"
							>
								<SiteVisit title="Points Earned" />
							</Col>
						</Row>
					</div>
				</div>
			</Col>
			<Col xl={8} lg={24} md={8} sm={24} xs={24}>
				<TotalRevenueCard
					title="TOTAL Points Earned"
					value="₹ 200,167"
					width={'100%'}
				/>
			</Col>

			<Col xl={8} lg={12} md={8} sm={24} xs={24}>
				<GrowthCard trafficData={trafficData} title="Growth" percentage="35%" />
			</Col>

			<Col xl={8} lg={24} md={8} sm={24} xs={24}>
				<TotalRevenueCard
					title="Total number of customers"
					value="232"
					width={'100%'}
				/>
			</Col>
			<Col xl={24} lg={24} md={24} sm={24} xs={24}>
				<Overview
					title="Top Customers"
					total=""
					disableMap
					cities={{
						total: 100,
						values: [
							{ name: 'Arun', value: 30154 },
							{ name: 'Luxmi', value: 20147 },
						],
					}}
				/>
			</Col>
			<Col xl={24} lg={24} md={24} sm={24} xs={24}>
				<Overview
					title="Points Earned by City"
					total="₹ 200,167"
					cities={{
						total: 100,
						values: [
							{ name: 'Cochin', value: 60 },
							{ name: 'Others', value: 30 },
						],
					}}
				/>
			</Col>

			<Col xl={24} lg={24} md={24} sm={24} xs={24}>
				<Overview
					title="Total Points Earned by Service"
					total="32 Services"
					disableMap
					cities={{
						total: 200167,
						values: [
							{ name: 'Electric (Booked 52 times)', value: 70 },
							{ name: 'Handyman (Booked 33 times)', value: 40 },
						],
					}}
				/>
			</Col>

			<Col xl={16} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-1">
				<Row>
					{/* <Col xl={6} lg={6} md={6} sm={12} xs={12}>
						<IconWithTextCard
							cardColor="cyan"
							icon="diamond"
							title="09"
							subTitle="Projects"
						/>
					</Col>
					<Col xl={6} lg={6} md={6} sm={12} xs={12}>
						<IconWithTextCard
							cardColor="orange"
							icon="tasks"
							title="687"
							subTitle="Tasks"
						/>
					</Col>
					<Col xl={6} lg={6} md={6} sm={12} xs={12}>
						<IconWithTextCard
							cardColor="teal"
							icon="team"
							title="04"
							subTitle="Teams"
						/>
					</Col>
					<Col xl={6} lg={6} md={6} sm={12} xs={12}>
						<IconWithTextCard
							cardColor="red"
							icon="files"
							title="09"
							subTitle="Files"
						/>
					</Col> */}
				</Row>
			</Col>
		</Row>
	);
};

export default Loyalty;
