import { useQuery } from '@apollo/client';
import {
	Avatar,
	Card,
	Col,
	DatePicker,
	Radio,
	Button,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Table,
	Modal,
	Input,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AssignPartner from 'src/components/services/assignPartner';
import { RouteList } from 'src/config/constants/routes';
import {
	CMSGetJobBoard,
	CMSGetJobBoardVariables,
	CMSGetJobBoard_getJobBoard_data,
} from 'src/lib/gql/generated/CMSGetJobBoard';
import {
	BookingStatusTypeEnum,
	DatePeriodEnum,
} from 'src/lib/gql/generated/globalTypes';
import { CMSQueryGetJobBoard } from 'src/lib/gql/queries/bookings';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import moment from 'moment';
import { CMSQueryGetServiceAreas } from 'src/lib/gql/queries/services';
import { CMSGetServiceAreas } from 'src/lib/gql/generated/CMSGetServiceAreas';
import { CMSGetAllServiceCategories } from 'src/lib/gql/generated/CMSGetAllServiceCategories';
import { CMSQueryGetAllServiceCategories } from 'src/lib/gql/queries/services';
import {
	CMSGetServices,
	CMSGetServicesVariables,
} from 'src/lib/gql/generated/CMSGetServices';
import { CMSQueryGetServices } from 'src/lib/gql/queries/services';
import { useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userAtom, userTokenAtom } from 'src/lib/recoil/userCoil';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';

dayjs.extend(relativeTime);

const JobBoard: React.FC = () => {
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	// const [radioValue, setRadioValue] = useState("scheduledOn");
	const [radioValue, setRadioValue] = useState(
		localStorage.getItem('bookingSorts') || 'scheduledOn',
	);
	const [dateValue, SetDateValue] = useState<any>();
	const [isOpenDate, SetIsOpenDate] = useState(false);
	const [startDate, setStartDate] = useState<any>('');
	const [endDate, setEndDate] = useState<any>('');
	const { RangePicker } = DatePicker;
	const [statusValue, SetStatusValue] = useState<any>();
	const [areaValue, SetAreaValue] = useState<any>();
	const [serviceValue, SetServiceValue] = useState<any>();
	const [serviceCateValue, SetServiceCateValue] = useState<any>();
	const selectRef = useRef(null);
	const [categoryService, SetCategoryService] = useState<any>();
	const [searchName, setSearchName] = useState<any>();
	const [searchById, setSearchById] = useState<any>();
	const userAuth = useRecoilValue(userAtom);
	const [totalCount, setTotalCount] = useState(1)

	const {
		loading,
		data: qryData,
		called,
		error,
		refetch: refetchQryData,
	} = useQuery<CMSGetJobBoard, CMSGetJobBoardVariables>(CMSQueryGetJobBoard, {
		variables: {
			pagination: pagination,
			filter: {
				areaIds: areaValue,
				serviceIds: serviceValue,
				// autoAssign: true,
				bookingId: searchById ? searchById : null,
				serviceCategoryIds: serviceCateValue,
				bookingStatus: statusValue,
				sortBy: radioValue,
				fromDate: startDate ? startDate : null,
				toDate: endDate ? endDate : null,
				datePeriod: dateValue ? dateValue : null,
			},
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
	  if (qryData?.getJobBoard?.pageInfo?.totalCount) {
		setTotalCount(qryData?.getJobBoard?.pageInfo?.totalCount)
	  }
	}, [qryData])
	

	// Get all  area
	const { data: qryDataArea, refetch: refetchQryDatas } =
		useQuery<CMSGetServiceAreas>(CMSQueryGetServiceAreas, {
			errorPolicy: 'all',
		});
	// Get all services category
	const { data: qryDataServiceCate, refetch: refetchQryDataServiceCate } =
		useQuery<CMSGetAllServiceCategories>(CMSQueryGetAllServiceCategories);
	// Get all services
	const { data: qryDataService, refetch: refetchQryDataService } = useQuery<
		CMSGetServices,
		CMSGetServicesVariables
	>(CMSQueryGetServices, {
		variables: {
			showAll: true,
			search: '',
		},
		errorPolicy: 'all',
	});

	useEffect(() => {
		console.log("serviceCate Value",serviceCateValue);
		SetServiceValue(null)
			SetCategoryService(
			qryDataServiceCate?.getServiceCategories?.find(
				(value) => value.id === serviceCateValue,
			),
		);
		console.log("categoryService",qryDataServiceCate?.getServiceCategories?.find(
			(value) => value.id === serviceCateValue,
		),);
	}, [serviceCateValue]);

	useEffect(() => {
		if (searchName && searchName?.length >= 10) {
			setSearchById(searchName);
		} else {
			setSearchById(null);
		}
	}, [searchName]);

	const onRadioChange = (e) => {
		setRadioValue(e.target.value);
		localStorage.setItem('bookingSorts', e.target.value);
	};

	const columns = [
		{
			title: 'Status',
			key: 'asign',
			align: 'center' as const,
			render: (text, record: CMSGetJobBoard_getJobBoard_data) => {
				let color = 'white';
				const date1 = dayjs(record?.jobDate);
				const difference = date1.diff(dayjs(), 'hour', true);

				if (difference <= 24) {
					color = 'red';
				} else if (difference >= 24 && difference <= 48) {
					color = 'yellow';
				} else {
					color = 'rgba(0,0,0,0.05)';
				}

				return (
					<div
						style={{
							height: '24px',
							width: '24px',
							background: color,
							borderRadius: '50%',
							border: '1px solid rgba(0,0,0,0.05)',
						}}
					></div>
				);
			},
		},
		{
			title: 'Assign',
			key: 'asign',
			align: 'center' as const,
			render: (text, record) => {
				return <AssignPartner input={record} onFinishAdd={refetchQryData} />;
			},
		},
		// {
		// 	title: 'ID',
		// 	dataIndex: 'bookingId',
		// 	key: 'bookingId',
		// },
		{
			title: 'Booking ID',
			dataIndex: 'userBookingNumber',
			key: 'userBookingNumber',
			width: 120,
			align: 'center' as const,
			render: (text, record: CMSGetJobBoard_getJobBoard_data) => (
				<>
					<Link
						// to={RouteList.bookings.path + '/' + record?.id}
						to={RouteList.bookings.path + '/' + record.bookingServiceItemId}
					>
						<span className="gx-link">
							{record?.booking?.userBookingNumber}
						</span>
					</Link>
				</>
			),
		},
		{
			title: 'Service',
			dataIndex: 'service',
			key: 'service',
			align: 'center' as const,
			width: 200,
			render: (text, record) => {
				return (
					<div
						className="gx-ml-2 gx-d-none gx-d-sm-flex"
						style={{ textAlign: 'left' }}
					>
						<Avatar
							size="large"
							alt={'name'}
							src={`https://staging.api.zimkey.in/media-upload/${record.jobService?.icon?.url}`}
							// style={{ marginRight: '8px' }}
						/>
						<span>
							{/* <Link to="/users/users/1">
			  <span className="gx-link">View detail</span>
			  </Link> */}
							<span >{record.jobService?.code}</span>
							<br />
							<span className="">{record.jobService?.name}</span>
						</span>
					</div>
				);
			},
		},
		{
			title: 'Booked By',
			dataIndex: 'name',
			key: 'name',
			align: 'center' as const,
			render: (text, record) => 
			{
				const formatPhoneNumber = (phoneNumber) => {
					phoneNumber = phoneNumber?.replace(/[+\s]/g, '');
					if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
					  return `+91 ${phoneNumber?.slice(2, 7)} ${phoneNumber?.slice(7)}`;
					} else {
					  return phoneNumber;
					}
				  };
				const inputPhoneNumber =record.booking.user.phone
				const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);
				return(
				<Link
					to={RouteList.users.path + '/' + record.booking.user.id}
					// target="_blank"
				>
					<span className="gx-link">{record.booking.user.name}</span>
					<br />
					<span>{formattedPhoneNumber}</span>
					<br />
				</Link>
			);
				},
		},
		{
			title: 'Total Amount',
			dataIndex: 'grandTotal',
			key: 'grandTotal',
			align: 'center' as const,
			render: (text, record: CMSGetJobBoard_getJobBoard_data) => (
				<>
					<span className="">
						₹
						<b>
							{record?.booking?.bookingAmount?.grandTotal?.toLocaleString()}
						</b>
					</span>
				</>
			),
		},
		{
			title: 'Address',
			children: [
				// {
				// 	title: 'Building Name',
				// 	dataIndex: 'buildingName',
				// 	key: 'buildingName',
				// },
				// {
				// 	title: 'Building Number',
				// 	dataIndex: 'buildingNumber',
				// 	key: 'buildingNumber',
				// },
				// {
				// 	title: 'Address',
				// 	dataIndex: 'address',
				// 	key: 'address',
				// },
				{
					title: 'Postal Code',
					dataIndex: 'postalCode',
					key: 'postalCode',
					align: 'center' as const,
				},
				// {
				// 	title: 'Address Type',
				// 	dataIndex: 'addressType',
				// 	key: 'addressType',
				// },
				// {
				// 	title: 'Landmark',
				// 	dataIndex: 'landmark',
				// 	key: 'landmark',
				// },
				{
					title: 'Area Name',
					dataIndex: 'areaName',
					key: 'areaName',
					align: 'center' as const,
				},
				{
					title: 'City',
					dataIndex: 'city',
					key: 'city',
					align: 'center' as const,
				},
			],
		},
		// {
		// 	title: 'Scheduled Time',
		// 	dataIndex: 'addedDate',
		// 	key: 'time',
		// 	align: 'center' as const,
		// },
		{
			title: 'Scheduled On ',
			dataIndex: '',
			key: 'addedDate',
			align: 'center' as const,
			render: (text: string, record: any) => (
				<span>
					{record.bookingService?.unit === 'HOUR' ? (
						<>
							{/* {moment(record?.jobDate).format('lll')}  {record?.jobEndDate && (moment(record?.jobEndDate).format('LT'))}/ <br />
							{moment(record?.addedDate).format('lll')}</> */}
							{moment(record?.jobDate).format('lll')}{' '}
							{record?.jobEndDate &&
								` - ${moment(record?.jobEndDate).format('LT')}`}{' '}
							{/* / <br />
							{moment(record?.booking?.bookingDate).format('lll')} */}
						</>
					) : (
						<>
							{moment(record?.jobDate).format('lll')}{' '}
							{record?.jobEndDate &&
								` - ${moment(record?.jobEndDate).format('lll')}`}
							{/* / <br />
							{moment(record?.booking?.bookingDate).format('lll')} */}
						</>
					)}
				</span>
			),
		},
		{
			title: 'Booked On',
			dataIndex: '',
			key: 'addedDate',
			align: 'center' as const,
			render: (text: string, record: any) => (
				<span>
					{record.bookingService?.unit === 'HOUR' ? (
						<>
							{/* {moment(record?.jobDate).format('lll')}  {record?.jobEndDate && (moment(record?.jobEndDate).format('LT'))}/ <br />
							{moment(record?.addedDate).format('lll')}</> */}
							{/* {moment(record?.jobDate).format('lll')}{' '}
							{record?.jobEndDate &&
								` - ${moment(record?.jobEndDate).format('LT')}`}{' '}
							/ <br /> */}
							{moment(record?.booking?.bookingDate).format('lll')}
						</>
					) : (
						<>
							{/* {moment(record?.jobDate).format('lll')}{' '}
							{record?.jobEndDate &&
								` - ${moment(record?.jobEndDate).format('lll')}`}
							/ <br /> */}
							{moment(record?.booking?.bookingDate).format('lll')}
						</>
					)}
				</span>
			),
		},
		// {
		// 	title: 'Action',
		// 	dataIndex: 'key',
		// 	key: 'action',
		// 	width: 'auto',
		// 	align: 'center' as const,
		// 	render: (text, record) => (
		// 		<span>
		// 			<Link
		// 				to={RouteList.bookings.path + '/' + record.bookingServiceItemId}
		// 			>
		// 				<i className="icon-view-o" style={{ marginRight: '20px' }} />
		// 			</Link>
		// 		</span>
		// 	),
		// },
	];

	const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const data: any = [];

	qryData?.getJobBoard?.data.forEach((booking) => {
		const date = new Date(booking.jobDate);
		data.push({
			...booking,
			key: booking.id,
			service: '',
			date: date.toUTCString(),
			time: date.toLocaleTimeString(),
			responseData: booking,
			status: booking.booking.bookingStatus,
			buildingName: booking.booking.bookingAddress.buildingName,
			buildingNumber: booking.booking.bookingAddress.buildingNumber,
			address: booking.booking.bookingAddress.address,
			postalCode: booking.booking.bookingAddress.postalCode,
			addressType: booking.booking.bookingAddress.addressType,
			landmark: booking.booking.bookingAddress.landmark,
			areaName: booking?.booking?.bookingAddress?.area?.name,
			city: booking?.booking?.bookingAddress?.area?.city?.name,
		});
	});

	const [filterName, SetFilterName] = useState('');
	const [accountType, SetBookingType] = useState(BookingStatusTypeEnum.CREATED);
	const { Option } = Select;
	const handleStatus = (value) => {
		SetStatusValue(value);
	};
	const handleArea = (value) => {
		SetAreaValue(value);
	};
	const handleService = (value) => {
		SetServiceValue(value);
	};
	const handleServiceCate = (value) => {
		SetServiceCateValue(value);
	};
	const handleChanges = (value) => {
		setStartDate(null);
		setEndDate(null);
		SetDateValue(value);
		if (value === 'CUSTOM') {
			SetIsOpenDate(true);
		}
	};
	const handleOk = () => {
		SetIsOpenDate(false);
	};
	const handleCancel = () => {
		SetIsOpenDate(false);
	};
	const handleDate = (dates) => {
		if (dates && dates?.length === 2) {
			const startDates = moment(dates[0]?._d).toISOString();
			const endDates = moment(dates[1]?._d).toISOString();
			// setStartDate(moment(startDates).format('MMMM Do YYYY'));
			// setEndDate(moment(endDates).format('MMMM Do YYYY'));
			setStartDate(startDates);
			setEndDate(endDates);
		}

		//     setSaveDate(date)
	};
	const ClearAll = () => {
		SetServiceCateValue(null);
		SetServiceValue(null);
		SetAreaValue(null);
		SetStatusValue(null);
		SetDateValue(null);
		setStartDate(null);
		setEndDate(null);
		setRadioValue('scheduledOn');
		setSearchName('');
		setSearchById(null);
	};

	const filteredColumns = userAuth?.userType === UserTypeEnum.ADMIN || userAuth?.rights?.find((x) => x.type === 'sub2' && x.name === 'unassigned-bookings-assign') ? columns : columns.filter(column => column.title !== 'Assign');

	const onShowSizeChange = (current, pageSize) => {
		console.log(".....",current, pageSize);
		setPagination({
			pageNumber: current,
			pageSize: pageSize
			// pageSize: pageSize ? +pageSize : pagination.pageSize,
		});
	  };


	return (
		<Card
			title={'Unassigned Bookings'}
			extra={[
				<div key={null} style={{ display: 'flex' }}>
					<div key={null}>
						<Input
							placeholder="Search by ID"
							value={searchName}
							style={{ width: '200px' }}
							allowClear
							onChange={(e) => {
								setSearchName(e.target.value);
							}}
						/>
					</div>
					<div style={{ float: 'right', paddingLeft: '1rem' }} key={null}>
						Sort By{' '}
						<Radio.Group onChange={onRadioChange} value={radioValue}>
							<Radio value={'scheduledOn'}>Scheduled On</Radio>
							<Radio value={'createdOn'}>Booked On</Radio>
						</Radio.Group>
						<Button type="primary" onClick={ClearAll}>
							Clear All
						</Button>
					</div>
				</div>,
			]}
		>
			{/* <Row>
				<Col span={24} >  */}
			{/* <div style={{float:"right"}}>
				Sort By {" "}
				<Radio.Group onChange={onRadioChange} value={radioValue}>
      			 <Radio value={1}>Scheduled Date</Radio>
     			 <Radio value={2}>Created On</Radio>
   			 </Radio.Group>
			 </div> */}
			{/* </Col>
			</Row> */}
			<Row style={{ paddingTop: '1rem' }}>
				<Col span={6}>
					<span>Service Category</span>
					<Select
						ref={selectRef}
						// mode="multiple"
						style={{
							width: '100%',
						}}  getPopupContainer={(trigger) => trigger.parentElement}
						placeholder="Select Service Category"
						defaultValue={'All'}
						onChange={handleServiceCate}
						optionLabelProp="label"
						value={serviceCateValue}
					>
						<Option value={null} label={'All'}>
							<Space>
								<span role="img" aria-label={'All'}>
									All
								</span>
							</Space>
						</Option>
						{qryDataServiceCate?.getServiceCategories.map((entry, index) => (
							<Option value={entry?.id} label={entry?.name} key={index}>
								<Space>
									<span role="img" aria-label={entry?.name}>
										{entry?.name}
									</span>
								</Space>
							</Option>
						))}
					</Select>
				</Col>
				<Col span={6}>
					<span>Service</span>
					<Select
						// mode="multiple"
						showSearch filterOption={filterOption}
						style={{
							width: '100%',
						}} getPopupContainer={(trigger) => trigger.parentElement}
						placeholder="Select Service"
						defaultValue={'All'}
						onChange={handleService}
						optionLabelProp="label"
						value={serviceValue}
					>
						<Option value={null} label={'All'}>
							<Space>
								<span role="img" aria-label={'All'}>
									All
								</span>
							</Space>
						</Option>
						{serviceCateValue ? (
							<>
								{categoryService?.services?.map((entry, index) => (
									<Option value={entry?.id} label={entry?.name} key={index}>
										<Space>
											<span role="img" aria-label={entry?.name}>
												{entry?.name}
											</span>
										</Space>
									</Option>
								))}
								{/* {qryDataServiceCate?.filter((value) => value.id ===serviceCateValue)} */}
							</>
						) : (
							<>
								{qryDataService?.getServices.map((entry, index) => (
									<Option value={entry?.id} label={entry?.name} key={index}>
										<Space>
											<span role="img" aria-label={entry?.name}>
												{entry?.name}
											</span>
										</Space>
									</Option>
								))}
							</>
						)}
					</Select>
				</Col>
				<Col span={6}>
					<span>Area</span>
					<Select
						// mode="multiple"
						style={{
							width: '100%',
						}} getPopupContainer={(trigger) => trigger.parentElement}
						placeholder="Select Area"
						defaultValue={'All'}
						onChange={handleArea}
						optionLabelProp="label"
						value={areaValue}
					>
						<Option value={null} label={'All'}>
							<Space>
								<span role="img" aria-label={'All'}>
									All
								</span>
							</Space>
						</Option>
						{qryDataArea?.getAreas.map((entry, index) => (
							<Option value={entry?.id} label={entry?.name} key={index}>
								<Space>
									<span role="img" aria-label={entry?.name}>
										{entry?.name}
									</span>
								</Space>
							</Option>
						))}
					</Select>
				</Col>
				{/* <Col span={4}>
			<span>Booking Status</span>
			<Select
						style={{
						width: '100%',
						}}
						placeholder="select Status"
						defaultValue={'All'}
						onChange={handleStatus}
						optionLabelProp="label"
					>
						<Option value="CREATED" label="Created">
						<Space>
							<span role="img" aria-label="CREATED">
							Created
							</span>
						
						</Space>
						</Option>
						<Option value="PAYMENT_PENDING" label="Payment Pending">
						<Space>
							<span role="img" aria-label="Payment Pending">
							Payment Pending
							</span>
					
						</Space>
						</Option>
						<Option value="PAYMENT_FAILED" label="Payment Failed">
						<Space>
							<span role="img" aria-label="Payment Failed">
							Payment Failed
							</span>
					
						</Space>
						</Option>
						<Option value="OPEN" label="Open">
						<Space>
							<span role="img" aria-label="Open">
							Open
							</span>
						
						</Space>
						</Option>
						<Option value="CLOSED" label="Closed">
						<Space>
							<span role="img" aria-label="Closed">
							Closed
							</span>
						</Space>
						</Option>
					</Select>
				</Col> */}
				<Col span={6}>
					<span>
						{radioValue === 'scheduledOn' ? 'Scheduled On' : 'Booked On'}
					</span>
					<Select
						// mode="multiple"
						style={{
							width: '100%',
						}} getPopupContainer={(trigger) => trigger.parentElement}
						placeholder="Select One Date"
						defaultValue={'All'}
						onChange={handleChanges}
						optionLabelProp="label"
						value={dateValue}
					>
						<Option value={null} label={'All'}>
							<Space>
								<span role="img" aria-label={'All'}>
									All
								</span>
							</Space>
						</Option>
						<Option value="24_Hour" label={'24_Hour'}>
							<Space>
								<span role="img" aria-label={'24_Hour'}>
									24_Hour
								</span>
							</Space>
						</Option>
						<Option value="TODAY" label="TODAY">
							<Space>
								{/* <span role="img" aria-label="China">
							🇨🇳
							</span> */}
								{DatePeriodEnum.TODAY}
							</Space>
						</Option>
						<Option value="YESTERDAY" label="YESTERDAY">
							<Space>
								{/* <span role="img" aria-label="USA">
							🇺🇸
							</span> */}
								{DatePeriodEnum.YESTERDAY}
							</Space>
						</Option>
						{radioValue === 'scheduledOn' && (
							<Option value="NEXT_3_DAYS" label="NEXT_3_DAYS">
								<Space>
									{/* <span role="img" aria-label="Japan">
							🇯🇵
							</span> */}
									{DatePeriodEnum.NEXT_3_DAYS}
								</Space>
							</Option>
						)}

						<Option value="THIS_WEEK" label="THIS_WEEK">
							<Space>
								{/* <span role="img" aria-label="Korea">
							🇰🇷
							</span> */}
								{DatePeriodEnum.THIS_WEEK}
							</Space>
						</Option>
						<Option value="THIS_MONTH" label="THIS_MONTH">
							<Space>
								{/* <span role="img" aria-label="Korea">
							🇰🇷
							</span> */}
								{DatePeriodEnum.THIS_MONTH}
							</Space>
						</Option>

						<Option value="CUSTOM" label="CUSTOM">
							<Space>
								{/* <span role="img" aria-label="Korea">
							🇰🇷
							</span> */}
								{DatePeriodEnum.CUSTOM}
							</Space>
						</Option>

						{/* <Option value="dateandtime" label="Custom">
                        {dateValue ==="custom" && 

                        <Space direction="vertical" size={12}>
                        <RangePicker />
                        </Space> }  
                        </Option> */}
					</Select>
					{startDate ? moment(startDate).format('lll') : ''}
					{startDate ? '-' : ''} {endDate ? moment(endDate).format('lll') : ''}
				</Col>
			</Row>
			{/* <Row style={{marginBottom: "16px",
   							 paddingTop: "0.2rem",
   							 paddingBottom: "5px"}}>
				<Col span={24}>
				<div style={{justifyContent:"center",
					display:"flex"}}>
					<Input 
						style={{width:"250px"}}
						placeholder="Search by Name" />
			
				<div style={{paddingLeft:"0.5rem"}}>
					<Input.Group compact>
						<DatePicker.RangePicker
							placeholder={['From', 'To']}
							style={{ width: '70%' }}
						/>
					</Input.Group>
					</div></div>
				</Col>
			</Row> */}
			<hr />
			<Row
				style={{
					marginBottom: 16,
					paddingTop: '1rem',
					backgroundColor: '#f6f6f6',
					paddingBottom: '5px',
				}}
			>
				<Col span={24} style={{ textAlign: 'center' }}>
					{/* <div style={{textAlign:"center"}}> */}
					<Pagination 
						key="pagination"
						pageSize={pagination.pageSize}
						total={totalCount}
						showTotal={(total) => `Total ${total} items`}
						onChange={(page, pageSize) => {
							// console.log("11111111111111111",page,pageSize);
							
                            if (page != pagination.pageNumber) {
							setPagination({
								pageNumber: page,
								pageSize:  pageSize ? +pageSize :pagination.pageSize
								// pageSize: pageSize ? +pageSize : pagination.pageSize,
							});
						}
						}}
						showSizeChanger
						onShowSizeChange={onShowSizeChange}
					/>
					{/* <Pagination
      				showSizeChanger
     			 	onShowSizeChange={onShowSizeChange}
     				 defaultCurrent={3}
     				total={500}
   				 /> */}
					{/* </div> */}
				</Col>
			</Row>

			{isOpenDate && (
				<Modal
					title="Date"
					visible={isOpenDate}
					onOk={handleOk}
					onCancel={handleCancel}
					// width={800}
				>
					<div style={{ textAlign: 'center' }}>
						<Space direction="vertical" size={6}>
							<RangePicker onChange={handleDate} />
						</Space>
					</div>
				</Modal>
			)}

			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}
			{!loading && (
				<Table
					className="gx-table-responsive"
					columns={filteredColumns}
					dataSource={data}
					pagination={false}
					bordered
				/>
			)}
		</Card>
	);
};
export default JobBoard;
