import { useQuery } from '@apollo/client';
import {
	Avatar,
	Card,
	Col,
	DatePicker,
	Form,
	Input,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Table,
} from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import {
	CMSGetAllBookings,
	CMSGetAllBookingsVariables,
} from 'src/lib/gql/generated/CMSGetAllBookings';
import { CMSGetBookingServiceItems_getBookingServiceItems_data } from 'src/lib/gql/generated/CMSGetBookingServiceItems';
import { BookingStatusTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { CMSQueryGetAllBookings } from 'src/lib/gql/queries/bookings';

const Subscriptions: React.FC = () => {
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 5,
	});

	const {
		loading,
		data: qryData,
		called,
		error,
		refetch: refetchQryData,
	} = useQuery<CMSGetAllBookings, CMSGetAllBookingsVariables>(
		CMSQueryGetAllBookings,
		{
			variables: {
				pagination: pagination,
				// filter: {
				// 	isSubscription: 'true',
				// },
			},
		},
	);

	const columns = [
		{
			title: 'Booking ID',
			dataIndex: 'id',
			key: 'id',
			width: 150,
		},

		{
			title: 'Service',
			dataIndex: 'service',
			key: 'service',
			width: 250,
			render: (
				text,
				record: CMSGetBookingServiceItems_getBookingServiceItems_data,
			) => {
				return (
					<div className="gx-ml-2 gx-d-none gx-d-sm-flex">
						<Avatar
							size="large"
							alt={'name'}
							src={`https://staging.api.zimkey.in/media-upload/${record?.bookingService?.service?.icon?.url}`}
							style={{ marginRight: '8px' }}
						/>
						<span>
							{/* <Link to="/users/users/1">
			  <span className="gx-link">View detail</span>
			  </Link> */}
							<span className="">{record?.bookingService?.service?.code}</span>
							<br />
							<span className="">{record?.bookingService?.service?.name}</span>
						</span>
					</div>
				);
			},
		},
		{
			title: 'Booked By',
			dataIndex: 'name',
			key: 'name',
			width: 150,
			render: (
				text,
				record: CMSGetBookingServiceItems_getBookingServiceItems_data,
			) => (
				<Link
					to={
						RouteList.users.path +
						'/' +
						record?.bookingService?.booking?.user?.id
					}
					target="_blank"
				>
					<span className="gx-link">
						{record?.bookingService?.booking?.user?.name}
					</span>
					<br />
					<span>{record?.bookingService?.booking?.user?.phone}</span>
					<br />
				</Link>
			),
		},
		{
			title: 'Amount Paid',
			dataIndex: 'ammount',
			key: 'ammount',
			width: 150,
			render: (
				text,
				record: CMSGetBookingServiceItems_getBookingServiceItems_data,
			) => <p></p>,
		},
		{
			title: 'Address',
			children: [
				{
					title: 'Building Name',
					dataIndex: 'buildingName',
					key: 'buildingName',
				},
				{
					title: 'Building Number',
					dataIndex: 'buildingNumber',
					key: 'buildingNumber',
				},
				{
					title: 'Address',
					dataIndex: 'address',
					key: 'address',
				},
				{
					title: 'Postal Code',
					dataIndex: 'postalCode',
					key: 'postalCode',
				},
				{
					title: 'Address Type',
					dataIndex: 'addressType',
					key: 'addressType',
				},
				{
					title: 'Landmark',
					dataIndex: 'landmark',
					key: 'landmark',
				},
				{
					title: 'Area Name',
					dataIndex: 'areaName',
					key: 'areaName',
				},
			],
		},
		{
			title: 'Scheduled Time',
			dataIndex: 'time',
			key: 'time',
		},
		{
			title: 'Scheduled Date',
			dataIndex: 'date',
			key: 'date',
			render: (date) => {
				return (
					<span>
						{new Date(date).toLocaleDateString('en-us', {
							weekday: 'long',
							year: 'numeric',
							month: 'long',
							day: 'numeric',
						})}
					</span>
				);
			},
		},

		{
			title: 'Assigned to',
			dataIndex: 'partner',
			key: 'partner',
			render: (
				text,
				record: CMSGetBookingServiceItems_getBookingServiceItems_data,
			) => (
				<Link
					to={RouteList.users.path + '/' + record?.servicePartner?.id}
					target="_blank"
				>
					<span className="gx-link">{record?.servicePartner?.name}</span>
					<br />
					<span>{record?.servicePartner?.phone}</span>
					<br />
				</Link>
			),
		},
		{
			title: 'Action',
			dataIndex: 'bookingID',
			key: 'bookingID',
			width: 'auto',
			render: (
				text,
				record: CMSGetBookingServiceItems_getBookingServiceItems_data,
			) => (
				<span>
					<Link to={RouteList.bookings.path + '/' + record?.id} target="_blank">
						<span className="gx-link">View detail</span>
					</Link>
				</span>
			),
		},
	];

	const data: any = [];
	// for (let i = 1; i <= 50; i++) {
	// 	data.push({
	// 		key: i,
	// 		name: 'John Brown',
	// 		service: 'Home Services',
	// 		sub_service: 'Electrician',
	// 		age: `${Math.floor(Math.random() * 1000)}₹`,
	// 		address: `Street ${i},Cochin, Kerala, India`,
	// 		date: '2:00PM - 3:00PM, 1 Dec, 2020',
	// 	});
	// }

	qryData?.getBookings?.data.forEach((booking) => {
		const date = new Date(booking.bookingDate);
		data.push({
			...booking,
			key: booking.id,
			bookingID: booking?.id,
			buildingName: booking?.bookingAddress.buildingName,
			buildingNumber: booking?.bookingAddress.buildingNumber,
			address: booking?.bookingAddress.address,
			postalCode: booking?.bookingAddress.postalCode,
			areaName: booking?.bookingAddress?.area?.name,
			date: date.toUTCString(),
			time: date.toLocaleTimeString(),
			// responseData: booking,
			// status: booking.bookingStatus,
		});
	});

	const [filterName, SetFilterName] = useState('');
	const [accountType, SetBookingType] = useState(BookingStatusTypeEnum.CREATED);
	const { Option } = Select;
	return (
		<Card title="Subscriptions">
			<Row>
				<Col span={6}>
					<Input placeholder="Search by Name" />
				</Col>

				<Col span={12}>
					<Pagination
						key="pagination"
						pageSize={pagination.pageSize}
						total={qryData?.getBookings?.pageInfo.totalCount}
						showTotal={(total) => `Total ${total} items`}
						onChange={(page, pageSize) => {
							setPagination({
								pageNumber: page,
								pageSize: pageSize ? +pageSize : pagination.pageSize,
							});
						}}
						showSizeChanger
					/>
				</Col>
				<Col span={12}>
					<Input.Group compact>
						<Form.Item label="Filter by:"></Form.Item>
						<DatePicker.RangePicker
							placeholder={['From', 'To']}
							style={{ width: '70%' }}
						/>
					</Input.Group>
				</Col>
			</Row>

			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}
			{!loading && (
				<Table
					className="gx-table-responsive"
					columns={columns}
					dataSource={data}
					pagination={false}
				/>
			)}
		</Card>
	);
};
export default Subscriptions;
