import React, { useEffect, useState,useRef } from 'react';
import { Modal, Button, Row, Col, Alert } from 'antd';
import { Form, Input, Select, notification } from 'antd';
import { useMutation } from '@apollo/client';

import {
	CREATE_SERVICE_BILLING_OPTION,
	DELETE_SERVICE_BILLING_OPTION,
	UPDATE_SERVICE_BILLING_OPTION,
} from 'src/lib/gql/mutations/mutations';
import {
	CopyOutlined,
} from '@ant-design/icons';
import { CMSGetService_getService } from 'src/lib/gql/generated/CMSGetService';
import {
	CMSCreateServiceBillingOption,
	CMSCreateServiceBillingOptionVariables,
	CMSCreateServiceBillingOption_createServiceBillingOption,
} from 'src/lib/gql/generated/CMSCreateServiceBillingOption';
import {
	RecurringPeriodEnum,
	ServiceUnitEnum,
} from 'src/lib/gql/generated/globalTypes';
import {
	CMSDeleteServiceBillingOption,
	CMSDeleteServiceBillingOptionVariables,
} from 'src/lib/gql/generated/CMSDeleteServiceBillingOption';
import {
	CMSUpdateServiceBillingOption,
	CMSUpdateServiceBillingOptionVariables,
} from 'src/lib/gql/generated/CMSUpdateServiceBillingOption';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

interface Props {
	serviceID: string;
	input?: CMSCreateServiceBillingOption_createServiceBillingOption;
	onFinishAdd?: () => void;
	getService: CMSGetService_getService;
}

const AddBillingOption = ({ serviceID, input, onFinishAdd,getService }: Props) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isRecurring, setisRecurring] = useState(false);
	const [partnerPrice, setPartnerPrice] = useState<any>(0);
	const [partnerTax, setPartnerTax] = useState<any>(0);
	const [partnerTotal, setPartnerTotal] = useState<any>(0);
	const [commissionPrice, setCommissionPrice] = useState<any>();
	const [commissionTax, setCommissionTax] = useState<any>(18);
	const [commissionTotal, setCommissionTotal] = useState<any>(0);
	const [commissionAmount, setCommissionAmount] = useState<any>(0);
	const [grandTotal, setGrandTotal] = useState<any>(0);
	const [addPartnerPrice, setAddPartnerPrice] = useState<any>();
	const [addPartnerTax, setAddPartnerTax] = useState<any>();
	const [addPartnerTotal, setAddPartnerTotal] = useState<any>();
	const [addCommissionPrice, setAddCommissionPrice] = useState<any>();
	const [addCommissionTax, setAddCommissionTax] = useState<any>(18);
	const [addCommissionTotal, setAddCommissionTotal] = useState<any>();
	const [addCommissionAmount, setAddCommissionAmount] = useState<any>();
	const [addGrandTotal, setAddGrandTotal] = useState<any>(0);
	const [unitPrices, setUnitPrice] = useState<any>();
	const [addUnitPrice, setAddUnitPrice] = useState<any>();
	const [updateAlert, setUpdateAlert] = useState(false)
	const [createAlert, setCreateAlert] = useState(false)
	const [errorAlert, setErrorAlert] = useState(false)
	const [copiedId, setCopiedId] = useState<any>(false);
	const [isZimkeyServiceTrue, setIsZimkeyServiceTrue] = useState<any>(false);

	useEffect(()=>{
		// console.log("input+++ 000",input);
		setPartnerPrice(input?.unitPrice?.partnerPrice)
		setPartnerTax(input?.unitPrice?.partnerTax)
		setCommissionPrice(input?.unitPrice?.commission)
		// setCommissionTax(input?.unitPrice?.commissionTax)
		setAddPartnerPrice(input?.additionalUnitPrice?.partnerPrice)
		setAddPartnerTax(input?.additionalUnitPrice?.partnerTax)
		setAddCommissionPrice(input?.additionalUnitPrice?.commission)
		// setAddCommissionTax(input?.additionalUnitPrice?.commissionTax)
		// setUnitPrice(input?.unitPrice?.unitPrice)
		// setAddUnitPrice(input?.additionalUnitPrice?.unitPrice)
		setPartnerTotal(input?.unitPrice?.partnerTotal)
		setCommissionTotal(input?.unitPrice?.commissionTotal)
		setCommissionAmount(input?.unitPrice?.commissionAmount)
		setGrandTotal(input?.unitPrice?.grandTotal)
		setAddPartnerTotal(input?.additionalUnitPrice?.partnerTotal)
		setAddCommissionTotal(input?.additionalUnitPrice?.commissionTotal)
		setAddCommissionAmount(input?.additionalUnitPrice?.commissionAmount)
		setAddGrandTotal(input?.additionalUnitPrice?.grandTotal)
	},[input])

	useEffect(()=>{
		if(getService?.isZimkeyService===true){
			setCommissionPrice(0)
			setAddCommissionPrice(0)
			setIsZimkeyServiceTrue(true)
		}	
	},[getService])

	useEffect(()=>{
		if (partnerPrice&&partnerTax){
			console.log('AAFTER',partnerPrice,partnerTax)
			setPartnerTotal((parseInt(partnerPrice)+(parseInt(partnerPrice)*parseInt(partnerTax))/100))
		}	else{
			setPartnerTotal(0)
		}
		// console.log("commi",commissionPrice);
		// console.log("commi partnerPrice",partnerPrice);
		
		if (partnerPrice&&commissionPrice){
		const val=((partnerPrice*commissionPrice)/100).toFixed(2)
		// console.log("commi val",val);

		setCommissionAmount(parseFloat(val))
		}	else{
			setCommissionAmount(0)
		}
	if (commissionAmount){
		const daVal=(parseFloat(commissionAmount) * (commissionTax/ 100)) + parseFloat(commissionAmount);
		setCommissionTotal(parseFloat(daVal.toFixed(2)));
		}	
		else{
			setCommissionTotal(0);
		}
		if(partnerTotal&& !commissionTotal){
			const val=Math.round(parseFloat(partnerTotal)).toFixed(2)
			setGrandTotal(parseFloat(val))
		} else if(partnerTotal&& commissionTotal){
			const val=Math.round(parseFloat(partnerTotal)+parseFloat(commissionTotal)).toFixed(2)
			setGrandTotal(parseFloat(val))
		}else{
			setGrandTotal(0)
		}
	},[partnerPrice,partnerTax,commissionPrice,commissionTax,commissionAmount,partnerTotal,commissionTotal])


	useEffect(()=>{
		if (addPartnerPrice&&addPartnerTax){
			setAddPartnerTotal((parseInt(addPartnerPrice)+(parseInt(addPartnerPrice)*parseInt(addPartnerTax))/100))
		}else	{
			setAddPartnerTotal(0)
		}
		if (addPartnerPrice&&addCommissionPrice){
			const val=((addPartnerPrice*addCommissionPrice)/100).toFixed(2) 
			setAddCommissionAmount( parseFloat(val))
	}	else{
		setAddCommissionAmount(0)
	}

		if(addPartnerTotal&& !addCommissionTotal){
			const val=Math.round(parseFloat(addPartnerTotal)).toFixed(2)
			setAddGrandTotal(parseFloat(val))
		} else if(addPartnerTotal&& addCommissionTotal){
			const val=Math.round(parseFloat(addPartnerTotal)+parseFloat(addCommissionTotal)).toFixed(2)
			setAddGrandTotal(parseFloat(val))
		}else{
			setAddGrandTotal(0)
		}

		if (addCommissionAmount){
			const daVal=parseFloat(addCommissionAmount) * addCommissionTax
			const daaa= daVal/ 100 + parseFloat(addCommissionAmount);
			setAddCommissionTotal(parseFloat(daaa.toFixed(2)));
			}	else{
				setAddCommissionTotal(0);
			}
	// 	if (addPartnerPrice&&addPartnerTax){
	// 		setAddPartnerTotal(Math.round(parseInt(addPartnerPrice)+(parseInt(addPartnerPrice)*parseInt(addPartnerTax))/100))
	// 	}	
	// 	setAddCommissionAmount((addPartnerPrice*addCommissionPrice)/100)
	// 	setAddCommissionTotal(Math.round(((parseFloat(addCommissionAmount)*parseFloat(addCommissionTax))/100) +addCommissionAmount))
	// 		if(addPartnerPrice&& !addCommissionTotal){
	// 			setAddGrandTotal(Math.round(parseFloat(addPartnerPrice)))
	// 	} else{
	// 	setAddGrandTotal(Math.round(parseFloat(addPartnerTotal)+parseFloat(addCommissionTotal)))
	// }
	},[addPartnerPrice,addPartnerTax,addCommissionPrice,addCommissionTax,addCommissionAmount,addPartnerTotal,addCommissionTotal])

	// Create
	const [
		createServiceBillingOption,
		{ data: dataMt, loading: loadingMt, error: errorMt },
	] = useMutation(CREATE_SERVICE_BILLING_OPTION,{
		onCompleted: () => {
			setIsModalVisible(false);
			form.resetFields();
			onFinishAdd
			setPartnerTotal("")
			setCommissionTotal("")
			setCommissionAmount("")
			setGrandTotal("")
			setAddPartnerTotal("")
			setAddCommissionTotal("")
			setAddCommissionAmount("")
			setAddGrandTotal("")
		},
	});

	// Update
	const [
		updateServiceInputOption,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation(UPDATE_SERVICE_BILLING_OPTION,{
		onCompleted: () => {
			setIsModalVisible(false);
			onFinishAdd
		},
	});

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		form.submit();
	};

	useEffect(() => {
		setTimeout(() => {
			if (copiedId) {
				setCopiedId(false);
			}
		}, 2000);
	}, [copiedId]);
	const openNotification = () => {
		notification.open({
		  message: 'Copied',
		  description:
			'Text copied successfully!',
		});
	  };
	const hRef = useRef<HTMLInputElement>(null);

	const copyId = () => {
		if (hRef.current) {
			const text = hRef?.current?.innerText;
			navigator.clipboard
				.writeText(text.trim())
				.then(() => {
					setCopiedId(true);
				})
				.catch((error) => {
					console.error('Failed to copy text:', error);
				});
		}
		openNotification()
	};

	
	const [form] = Form.useForm();
	
	const handleCancel = () => {
		// onFinishAdd
		if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
		form.resetFields()
		setIsModalVisible(false);
	};

	const onFinish = (values) => {
		if (!input) {
			const data = {
				serviceId: serviceID,
				name: values.name,
				description: values.description,
				code: values.code,
				recurring: values.recurring === true,
				recurringPeriod:
					values.recurring === true
						? values.recurringPeriod.toUpperCase()
						: null,
				autoAssignPartner: values.autoAssignPartner === true,
				unitPrice: {
					partnerPrice: +values.partnerPrice,
					commission: +values.commission,
					commissionTax: commissionTax,
					partnerTax: +values.partnerTax,
					commissionAmount: commissionAmount,
					commissionTotal: commissionTotal,
					partnerTotal: partnerTotal,
					grandTotal: grandTotal,
					unitPrice:+values.unitPrice
				},
				additionalUnitPrice: {
					partnerPrice: +values.additionalPartnerPrice,
					commission: +values.additionalCommission,
					commissionTax: addCommissionTax,
					partnerTax: +values.additionalPartnerTax,
					commissionAmount: addCommissionAmount,
					commissionTotal: addCommissionTotal,
					partnerTotal: addPartnerTotal,
					grandTotal: addGrandTotal,
					unitPrice:+values.additionalunitPrice,
				
				},
				unit: values.unit.toUpperCase(),
				minUnit: +values.minUnit,
				maxUnit: +values.maxUnit,
				commissionPercentage: Math.round(commissionAmount),
				additionalMinUnit:+values.additionalMinUnit,
				additionalMaxUnit:+values.additionalMaxUnit,
			};
			// console.log(data);
			// TODO: Comission percentge
			// return;
			createServiceBillingOption({
				variables: {
					data: data,
				},
			}).then(() => {
				form.resetFields();
				// location.reload();
				setPartnerPrice(0)
				setPartnerTotal("")
				setCommissionTotal("")
				setCommissionAmount("")
				setGrandTotal("")
				setAddPartnerPrice(0)
				setAddPartnerTotal(0)
				setAddCommissionTotal("")
				setAddCommissionAmount("")
				setAddGrandTotal("")
				setCreateAlert(true)
				setTimeout(() => {
				setCreateAlert(false)
				}, 2000)
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
					}
					setIsModalVisible(false);
				}, 1500);
			});
		
		} else {
			updateServiceInputOption({
				variables: {
					id: input.id,
					data: {
						serviceId: serviceID,
						name: values.name,
						description: values.description,
						code: values.code,
						recurring: values.recurring === true,
						recurringPeriod:
							values.recurring === true
								? values.recurringPeriod.toUpperCase()
								: null,
						autoAssignPartner: values.autoAssignPartner === true,
						unitPrice: {
							partnerPrice: +values.partnerPrice,
							commission: +values.commission,
							commissionTax: 18,
							partnerTax: +values.partnerTax,
							commissionAmount:commissionAmount,
							commissionTotal:commissionTotal,
							partnerTotal:partnerTotal,
							grandTotal:grandTotal,
							unitPrice:+values.unitPrice
						},
						additionalUnitPrice: {
							partnerPrice: +values.additionalPartnerPrice,
							commission: +values.additionalCommission,
							commissionTax: 18,
							partnerTax: +values.additionalPartnerTax,
							commissionAmount:addCommissionAmount,
							commissionTotal:addCommissionTotal,
							partnerTotal:addPartnerTotal,
							grandTotal:addGrandTotal,
							unitPrice:+values.additionalunitPrice,
						
						},
						unit: values.unit.toUpperCase(),
						minUnit: +values.minUnit,
						maxUnit: +values.maxUnit,
						additionalMinUnit:+values.additionalMinUnit,
						additionalMaxUnit:+values.additionalMaxUnit,
						sortOrder: +values.sortOrder,
					},
				},
			}).then(() => {
				// form.resetFields();
				setTimeout(() => {
					if (typeof onFinishAdd === 'function') {
						onFinishAdd();
					}
					setIsModalVisible(false);
				}, 1500);
			});
			setUpdateAlert(true)
            setTimeout(() => {
            setUpdateAlert(false)
            }, 2000)
		}
	};

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	// Delete
	const [
		deleteServiceBillingOption,
		{ data: dataMtD, loading: loadingMtD, error: errorMtD },
	] = useMutation<
		CMSDeleteServiceBillingOption,
		CMSDeleteServiceBillingOptionVariables
	>(DELETE_SERVICE_BILLING_OPTION);

	const onDelete = () => {
		if (!input) return;
		deleteServiceBillingOption({
			variables: {
				id: input.id,
			},
		}).then(() => {
			setTimeout(() => {
				if (typeof onFinishAdd === 'function') {
					onFinishAdd();
				}
				setIsDeleteModalVisible(false);
			}, 1500);
		}).catch((error) => {
			setErrorAlert(true);
			setTimeout(() => {
				setErrorAlert(false);
				setIsDeleteModalVisible(false);
			}, 3000);
		});
	};

	const onChange = (values) => {
		if (values.target.id === 'addBilling_recurring') {
			setisRecurring(values.target.checked);
		}
	};

	const validateReworkGracePeriod = (_, value) => {
		if (isNaN(value) || Number(value) < 0) {
		  return Promise.reject('Please enter a non-negative number.');
		}
		return Promise.resolve();
	  };

	return (
		<>
			<Button
				type="primary"
				onClick={showModal}
				style={
					input
						? {
								background: 'transparent',
								color: '#5a20ff',
								border: 'none',
								boxShadow: 'none',
						  }
						: {}
				}
			>
				{!input ? 'Add Billing Option' : <EditOutlined />}
			</Button>
			<Modal
				title={
					<div style={{display:"flex"}}>
						{!input ? 'Add Billing Option' : 'Update Billing Option'}
						{input &&
						<span style={{textAlign:"center",paddingLeft:"5rem"}}>
							Billing Option Id : <span ref={hRef}>{input?.id}</span>  <CopyOutlined onClick={copyId} />
						</span>
						}
					</div>
				} 
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
				bodyStyle={{
					padding: '1rem 3rem'
				}}
			>
				{/* Creating */}
				{createAlert && !loadingMt && <Alert message="Created" type="success" showIcon />}
				{loadingMt && <Alert message="Submitting..." type="info" showIcon />}
				{errorMt && (
					<Alert
						message={`Submission error! ${errorMt.message}`}
						type="error"
						showIcon
					/>
				)}

				{/* Updating */}
				{updateAlert &&  !loadingMtU && <Alert message="Updated" type="success" showIcon />}
				{loadingMtU && <Alert message="Submitting..." type="info" showIcon />}
				{errorMtU && (
					<Alert
						message={`Submission error! ${errorMtU.message}`}
						type="error"
						showIcon
					/>
				)}

				<Form
					form={form}
					name="addBilling"
					onFinish={onFinish}
					onChange={onChange}
					scrollToFirstError
					initialValues={
						!input
							? {
								commission: 0,
								additionalCommission:0,
							}
							: {
									serviceId: serviceID,
									code: input.code,
									name: input.name,
									description: input.description,
									recurring: input.recurring,
									recurringPeriod: input.recurringPeriod,
									autoAssignPartner: input.autoAssignPartner,
									unit: input.unit,
									minUnit: input.minUnit,
									maxUnit: input.maxUnit,
									partnerPrice: input.unitPrice.partnerPrice,
									commission: input.unitPrice.commission,
									// commissionTax: input.unitPrice.commissionTax,
									partnerTax: input.unitPrice.partnerTax,
									unitPrice:input.unitPrice.unitPrice,
									// partnerTotalNew:partnerTotal,
									// commissionTotal:commissionTotal,
									// commissionAmount:commissionAmount,
									// grandTotal:grandTotal,
									additionalPartnerPrice:
										input.additionalUnitPrice.partnerPrice,
									additionalCommission: input.additionalUnitPrice.commission,
									// additionalCommissionTax:
										// input.additionalUnitPrice.commissionTax,
									additionalPartnerTax: input.additionalUnitPrice.partnerTax,
									additionalunitPrice:input.additionalUnitPrice.unitPrice,
									additionalMinUnit:input.additionalMinUnit,
									additionalMaxUnit:input.additionalMaxUnit,
									// addpartnerTotal:addPartnerTotal,
									// addcommissionTotal:addCommissionTotal,
									// addcommissionAmount:addCommissionAmount,
									// addgrandTotal:grandTotal,
								}
					}
				>
					<Row>
						<Col span={24}>
							<label
								style={{
									fontSize: '14px',
									fontWeight: 'bold',
									display: 'block',
								}}
							>
								Basic Info
							</label>
							<hr/>
						</Col>
						<Col span={8}>
							<Form.Item
								name={`code`}
								label={<span>Code</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item
								name={`name`}
								label={<span>Name</span>}
								rules={[
									{
										required: true,
										whitespace: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>

						{/* <Col span={8} style={{textAlign: 'center'}}>
							<Form.Item name="autoAssignPartner" valuePropName="checked">
								<Checkbox>Show On Job Board</Checkbox>
							</Form.Item>
						</Col> */}

						<Col span={23}>
							<Form.Item
								name={`description`}
								label={<span>Description</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input.TextArea />
							</Form.Item>
						</Col>

						{/* <Col span={4}>
							<Form.Item name="recurring" valuePropName="checked">
								<Checkbox>Recurring</Checkbox>
							</Form.Item>
						</Col> */}

						{/* <Col span={8}>
							<Form.Item label="Recurring Period" name="recurringPeriod">
								<Select disabled={!(isRecurring || input?.recurring)}>
									<Select.Option value={RecurringPeriodEnum.DAILY}>
										{RecurringPeriodEnum.DAILY}
									</Select.Option>
									<Select.Option value={RecurringPeriodEnum.BIWEEKLY}>
										{RecurringPeriodEnum.BIWEEKLY}
									</Select.Option>
									<Select.Option value={RecurringPeriodEnum.WEEKLY}>
										{RecurringPeriodEnum.WEEKLY}
									</Select.Option>
									<Select.Option value={RecurringPeriodEnum.MONTHLY}>
										{RecurringPeriodEnum.MONTHLY}
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={2}></Col> */}

                        

                        {/* UNITS STARTS */}
						{/* <Col span={24}>
							<label
								style={{
									fontSize: '14px',
									fontWeight: 'bold',
									display: 'block',
								}}
							>
								Units
							</label>
							<hr/>
						</Col> */}

                        {/* UNITS ENDS */}

						{/* PRICE INFO STARTS */}

						<Col span={24}>
							<label
								style={{
									fontSize: '14px',
									fontWeight: 'bold',
									display: 'block',
								}}
							>
								Price Info
							</label>
							<hr/>
						</Col>

						<Col span={8}>
							<Form.Item
								label="Unit Type"
								name="unit"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Select getPopupContainer={(trigger) => trigger.parentElement}>
									<Select.Option value={ServiceUnitEnum.COUNT}>
										{ServiceUnitEnum.COUNT}
									</Select.Option>
									<Select.Option value={ServiceUnitEnum.HOUR}>
										{ServiceUnitEnum.HOUR}
									</Select.Option>
									<Select.Option value={ServiceUnitEnum.DAY}>
										{ServiceUnitEnum.DAY}
									</Select.Option>
									<Select.Option value={ServiceUnitEnum.WEEK}>
										{ServiceUnitEnum.WEEK}
									</Select.Option>
									<Select.Option value={ServiceUnitEnum.MONTH}>
										{ServiceUnitEnum.MONTH}
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item
								name={`minUnit`}
								label={<span>Min Unit</span>}
								rules={[
									{
										required: true,
										validator: validateReworkGracePeriod,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={7}>
							<Form.Item
								name={`maxUnit`}
								label={<span>Max Unit</span>}
								rules={[
									{
										required: true,
										validator: validateReworkGracePeriod,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						{/* <Col span={24} style={{marginTop: '1rem'}}>
							<label
								style={{
									fontSize: '14px',
									// fontWeight: 'bold',
									display: 'block',
								}}
							>
								Partner Price :
							</label>
						</Col> */}

						<Col span={8}>
							<Form.Item
								name={`partnerPrice`}
								label={<span>Partner Price</span>}
								rules={[
									{
										required: true,
										validator: validateReworkGracePeriod,
									},
								]}
							>
								<Input onChange={(e)=>setPartnerPrice(e.target.value)} value={partnerPrice} />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name={`partnerTax`}
								label={<span>Partner Tax(%)</span>}
								rules={[
									{
										required: true,
										validator: validateReworkGracePeriod,
									},
								]}
							>
								<Input onChange={(e)=>setPartnerTax(e.target.value)} value={partnerTax}/>
							</Form.Item>
						</Col>
						<Col span={7}>
							<Form.Item
								// name={`partnerTotalNew`}
								label={<span>Partner Total</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input value={partnerTotal? partnerTotal?.toFixed(2):0} disabled/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item
								name={`commission`}
								label={<span>Commission %</span>}
								rules={[
									{
										required: true, 
									},
								]}
							>
								{ isZimkeyServiceTrue ?(
									<Input  value={0} disabled/>
								):(
									<Input onChange={(e)=>setCommissionPrice(e.target.value)} value={commissionPrice}/>
								)}
							</Form.Item>
							<Form.Item
								// name={`commissionAmountNew`}
								label={<span>Commission Amount</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input value={commissionAmount?commissionAmount:0} disabled/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item
								// name={`commissionTax`}
								label={<span>Commission Tax(%)</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input disabled value={18}/>
							</Form.Item>
							<Form.Item
								name={`unitPrice`}
								label={<span>Unit Price</span>}
								rules={[
									{
										required: true, 
										validator: validateReworkGracePeriod,
									},
								]}
							>
								<Input onChange={(e)=>setUnitPrice(e.target.value)} value={unitPrices}/>
							</Form.Item>
						</Col>
						

						<Col span={7}>
							<Form.Item
							    // name={`commissionTotalNew`}
								label={<span>Commission Total</span>}
							>
								<Input disabled value={commissionTotal?commissionTotal:0}/>
							</Form.Item>
							<hr/>
							<Form.Item
								// name={`grandTotalNew`}
								label={<span>Grand Total</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input disabled value={grandTotal?grandTotal:0}/>
							</Form.Item>
						</Col>

                        {/* PRICE INFO ENDS */}
						{/* <br /> */}

						<br />
						<br />

						{/* {input && (
							<Col span={12}>
								<Form.Item
									name={`sortOrder`}
									label={<span>Sort order</span>}
									rules={[
										{
											required: true,
										},
									]}
								>
									<Input />
								</Form.Item>
							</Col>
						)} */}
						<br />

						<Col span={24}>
							{/* <hr/> */}
							<label
								style={{
									fontSize: '14px',
									fontWeight: 'bold',
									display: 'block',
								}}
							>
								Additional Price
							</label>
							<hr/>
						</Col>
						<Col span={8}>
							<Form.Item
								label="Unit Type"
								name="unit"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Select disabled >
									<Select.Option value={ServiceUnitEnum.COUNT}>
										{ServiceUnitEnum.COUNT}
									</Select.Option>
									<Select.Option value={ServiceUnitEnum.HOUR}>
										{ServiceUnitEnum.HOUR}
									</Select.Option>
									<Select.Option value={ServiceUnitEnum.DAY}>
										{ServiceUnitEnum.DAY}
									</Select.Option>
									<Select.Option value={ServiceUnitEnum.WEEK}>
										{ServiceUnitEnum.WEEK}
									</Select.Option>
									<Select.Option value={ServiceUnitEnum.MONTH}>
										{ServiceUnitEnum.MONTH}
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item
								name={`additionalMinUnit`}
								label={<span>Min Unit</span>}
								rules={[
									{
										required: true, 
										validator: validateReworkGracePeriod,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={7}>
							<Form.Item
								name={`additionalMaxUnit`}
								label={<span>Max Unit</span>}
								rules={[
									{
										required: true,
										validator: validateReworkGracePeriod,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item
								name={`additionalPartnerPrice`}
								label={<span>Partner Price</span>}
								rules={[
									{
										required: true,
										validator: validateReworkGracePeriod,
									},
								]}
							>
								<Input onChange={(e)=>setAddPartnerPrice(e.target.value)} value={addPartnerPrice}/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name={`additionalPartnerTax`}
								label={<span>Partner Tax(%)</span>}
								rules={[
									{
										required: true,
										validator: validateReworkGracePeriod,
									},
								]}
							>
								<Input onChange={(e)=>setAddPartnerTax(e.target.value)} value={addPartnerTax} />
							</Form.Item>
						</Col>
						<Col span={7}>
							<Form.Item
							// name={`partnerTotalAddtionalNew`}
								label={<span>Partner Total</span>}
								// rules={[
								// 	{
								// 		required: true,
								// 	},
								// ]}
							>
								<Input disabled value={addPartnerTotal?addPartnerTotal:0}/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item
								name={`additionalCommission`}
								label={<span>Commission %</span>}
								rules={!isZimkeyServiceTrue ? [
									{
										required: true, 
										validator: validateReworkGracePeriod,
									},
								] : []}
							>
							{ isZimkeyServiceTrue ?(
								<Input disabled value={0}/>
								):(
								<Input onChange={(e)=>setAddCommissionPrice(e.target.value)} value={addCommissionPrice}/>
							)}
								</Form.Item>
							<Form.Item
								// name={`commissionAmountAddtionalNew`}
								label={<span>Commission Amount</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input disabled  value={addCommissionAmount ?addCommissionAmount:0}/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item
								// name={`additionalCommissionTax`}
								label={<span>Commission Tax(%)</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input disabled value={18}/>
							</Form.Item>
							<Form.Item
								name={`additionalunitPrice`}
								label={<span>Unit Price</span>}
								rules={[
									{
										required: true,
										validator: validateReworkGracePeriod,
									},
								]}
							>
								<Input onChange={(e)=>setAddUnitPrice(e.target.value)} value={addUnitPrice}/>
							</Form.Item>
						</Col>

						<Col span={7}>
							<Form.Item
								// name={`commissionTotalAddtionalNew`}
								label={<span>Commission Total</span>}
								// rules={[
								// 	{
								// 		required: true,
								// 	},
								// ]}
							>
								<Input disabled value={addCommissionTotal?addCommissionTotal:0}/>
							</Form.Item>
							<hr/>
							<Form.Item
								// name={`grandTotalAddtionalNew`}
								label={<span>Grand Total</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input disabled value={addGrandTotal?addGrandTotal:0}/>
							</Form.Item>
						</Col>
						

						{/* <Col span={12}>
							<Form.Item
								name={`commissionPercentage`}
								label={<span>Commission Percentage</span>}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input max={100} />
							</Form.Item>
						</Col> */}
					</Row>
				</Form>
			</Modal>

			{input && (
				<Button
					type="text"
					onClick={() => setIsDeleteModalVisible(true)}
					danger
				>
					<DeleteOutlined />
				</Button>
			)}
			<Modal
				title={'Delete'}
				visible={isDeleteModalVisible}
				// onOk={onDelete}
				onCancel={() => setIsDeleteModalVisible(false)}
				width={300}
				footer={errorAlert ? null : (
					<div>
					  {/* Add your footer content here */}
					  <Button onClick={onDelete}>Ok</Button>
					  <Button onClick={() => setIsDeleteModalVisible(false)}>Cancel</Button>
					</div>
				  )}
			>
				Delete Billing Option?
				{/* Delete */}
				{dataMtD && <Alert message="Deleted" type="success" showIcon />}
				{loadingMtD && <Alert message="Deleting..." type="info" showIcon />}
				{errorMtD &&  errorAlert &&(
					<Alert
						message={`Submission error! ${errorMtD.message}`}
						type="error"
						showIcon
					/>
				)}
			</Modal>
		</>
	);
};
export default AddBillingOption;
