/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AddonTypeEnum {
  ALL = "ALL",
  CUSTOMER = "CUSTOMER",
  PARTNER = "PARTNER",
}
export enum BookingServiceItemTypeEnum {
  PRIMARY = "PRIMARY",
  REWORK = "REWORK",
  ADDITIONAL = "ADDITIONAL",
}
export enum PartnerStatusTypeEnum {
  ACTION = "ACTION",
  REJECT = "REJECT",
}
export enum DiscountTypeEnum  {
  FIXED_AMOUNT = "FIXED_AMOUNT",
  PERCENTAGE = "PERCENTAGE",
}
export enum ServiceStatusTypeEnum {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

export enum AppTypeEnum {
  CUSTOMER = "CUSTOMER",
  PARTNER = "PARTNER",
}

export enum BookingStatusTypeEnum {
  ALL = "ALL",
  CLOSED = "CLOSED",
  CREATED = "CREATED",
  OPEN = "OPEN",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_PENDING = "PAYMENT_PENDING",
}
export enum BookingServiceItemStatusTypeEnum {
  OPEN = "OPEN",
  PARTNER_ASSIGNED = "PARTNER_ASSIGNED",
  PARTNER_APPROVAL_PENDING = "PARTNER_APPROVAL_PENDING",
  CUSTOMER_APPROVAL_PENDING = "CUSTOMER_APPROVAL_PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  CLOSED = "CLOSED",
  CANCELED = "CANCELED",
}
export enum RevenueDatePeriodEnum  {
  THIS_YEAR = "THIS_YEAR",
  THIS_MONTH = "THIS_MONTH",
  THIS_WEEK = "THIS_WEEK",
  LAST_3_MONTHS = "LAST_3_MONTHS",
  LAST_YEAR = "LAST_YEAR",
  LAST_MONTH = "LAST_MONTH",
  LAST_WEEK = "LAST_WEEK",
}
export enum DocumentTypeEnum {
  AADHAR = "AADHAR",
  BUSINESS_LICENSE = "BUSINESS_LICENSE",
  DRIVING_LICENSE = "DRIVING_LICENSE",
  OTHER = "OTHER",
  PAN = "PAN",
  RATION_ID = "RATION_ID",
  VACCINE_PASSPORT = "VACCINE_PASSPORT",
  VOTER_ID = "VOTER_ID",
}

export enum EnumAddressType {
  HOME = "HOME",
  OFFICE = "OFFICE",
  OTHER = "OTHER",
}

export enum JobPriorityEnum {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
}

export enum MediaTypeEnum {
  EMBEDDED = "EMBEDDED",
  GIF = "GIF",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum PartnerPendingTaskEnum {
  SELECT_AREA = "SELECT_AREA",
  SELECT_SERVICE = "SELECT_SERVICE",
  UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT",
  UPLOAD_PROFILE_PICTURE = "UPLOAD_PROFILE_PICTURE",
}

export enum RecurringPeriodEnum {
  BIWEEKLY = "BIWEEKLY",
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}

export enum ServiceInputTypeEnum {
  DATE = "DATE",
  LOCATION = "LOCATION",
  TEXT = "TEXT",
}
export enum DatePeriodEnum {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  NEXT_3_DAYS = "NEXT_3_DAYS",
  THIS_WEEK = "THIS_WEEK",
  THIS_MONTH = "THIS_MONTH",
  CUSTOM = "CUSTOM",
}
export enum DatePeriodEnums {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  NEXT_3_DAYS = "NEXT_3_DAYS",
  THIS_WEEK = "THIS_WEEK",
  THIS_MONTH = "THIS_MONTH",
  CUSTOM = "CUSTOM",
}
export enum ReviewTypeEnum {
  FIRST_BOOKING = "FIRST_BOOKING",
  BOOKING="BOOKING"
}
export enum DatePeriodEnum2 {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  THIS_WEEK = "THIS_WEEK",
  THIS_MONTH = "THIS_MONTH",
  CUSTOM = "CUSTOM",
}
export enum FilterByEnum  {
  TRADE = "TRADE",
  PARTNER = "PARTNER",
}
export enum DatePeriodEnums3 {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  LAST_3_DAYS = "LAST_3_DAYS",
  THIS_WEEK = "THIS_WEEK",
  THIS_MONTH = "THIS_MONTH",
  THIS_YEAR = "THIS_YEAR",
  CUSTOM = "CUSTOM",
}
export enum ServiceShortListEnum {
  POPULAR = "POPULAR",
  SHOW_IN_HOME = "SHOW_IN_HOME",
 
}
export enum EnumBannerResourceType {
  CATEGORY_WISE = "CATEGORY_WISE",
  SERVICE_WISE = "SERVICE_WISE",
  REDIRECT_LINK = "REDIRECT_LINK",
}

export enum ServiceUnitEnum {
  COUNT = "COUNT",
  DAY = "DAY",
  HOUR = "HOUR",
  KILOGRAM = "KILOGRAM",
  LITER = "LITER",
  METER = "METER",
  MONTH = "MONTH",
  WEEK = "WEEK",
}

export enum UserTypeEnum {
  ADMIN = "ADMIN",
  SUB_ADMIN = "SUB_ADMIN",
  CUSTOMER = "CUSTOMER",
  PARTNER = "PARTNER",
  PENDING = "PENDING",
  PENDING_PARTNER = "PENDING_PARTNER",
  REJECTED_PARTNER = "REJECTED_PARTNER"
}
export enum BufferTimeTypeEnum  {
  HOURS = "HOURS",
  MINUTES = "MINUTES",
}
export enum CutOffTypeEnum  {
  AM = "AM",
  PM = "PM",
}
export enum PayoutIntervalEnum  {
  ON_JOB_FINISH = "ON_JOB_FINISH",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY"
}
export enum PartnerCalendarStatusTypeEnum {
  OPEN = "OPEN",
  RESCHEDULED_PARTNER = "RESCHEDULED_PARTNER",
  RESCHEDULED_CUSTOMER = "RESCHEDULED_CUSTOMER",
  CANCELED_PARTNER="CANCELED_PARTNER",
  CANCELED_CUSTOMER="CANCELED_CUSTOMER",
  ADMIN_ASSIGNED="ADMIN_ASSIGNED",
  ADMIN_REASSIGNED="ADMIN_REASSIGNED",
  REWORK_PENDING="REWORK_PENDING",
  REWORK_ACCEPTED="REWORK_ACCEPTED",
  REWORK_REJECTED="REWORK_REJECTED",
  DONE="DONE"
}

export interface BannerCreateGqlInput {
  title: string;
  url: string;
  description: string;
  mediaId: string;
}

export interface BannerUpdateGqlInput {
  title?: string | null;
  url?: string | null;
  description?: string | null;
  mediaId?: string | null;
  enabled?: boolean | null;
}

export interface CmsContentUpdateGqlInput {
  aboutUs?: string | null;
  referPolicy?: string | null;
  termsConditionsPartner?: string | null;
  termsConditionsCustomer?: string | null;
  privacyPolicy?: string | null;
  safetyPolicy?: string | null;
  cancellationPolicyCustomer?: string | null;
  cancellationPolicyPartner?: string | null;
}

export interface CouponCreateGqlInput {
  name: string;
  description: any;
  terms: any;
  code: string;
  startDate: any| null;
  endDate: any| null;
  value: number;
  maxUsePerCustomer: number;
  minOrder?: number | null;
  serviceBillingOptions?: string[] | null;
  customers?: string[] | null;
  discountType?:DiscountTypeEnum;
  usageLimit?: any | null;
	areaIds?: string[] | null;
	cityIds?: string[] | null;
	serviceIds?: string[] | null;
	serviceCategoryIds?: string[] | null;
  // enabled?: boolean ;
}

export interface CouponUpdateGqlInput {
  name?: string | null;
  description?: any | null;
  terms?: any | null;
  code?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  value?: number | null;
  maxUsePerCustomer?: number | null;
  enabled?: boolean ;
  minOrder?: number | null;
  serviceBillingOptions?: string[] | null;
  customers?: string[] | null;
  discountType?:DiscountTypeEnum;
  usageLimit?: any | null;
  areaIds?: string[] | null;
	cityIds?: string[] | null;
	serviceIds?: string[] | null;
	serviceCategoryIds?: string[] | null;
}

export interface FaqCreateGqlInput {
  question: string;
  answer: string;
  appType: AppTypeEnum;
  category: string;
  sortOrder?: number | null;
}

export interface GetBookingServiceItemsFilterGqlInput {
  useId?: string | null;
  partnerAssigned?: boolean | null;
  scheduledDateFrom?: any | null;
  scheduledDateTo?: any | null;
  area?: string | null;
  serviceCategory: any | null;
  service: any | null;
  bookingStatus: any | null;
  servicePartner: any | null;
  sortBy: any | null;
  fromDate: any | null;
  toDate: any | null;
  datePeriod: DatePeriodEnum | null;
  bookingId:any | null;
}

export interface JobBoardFilterGqlInput {
  areaIds?: string | null;
  serviceIds?: string | null;
  autoAssign?: boolean | null;
  serviceCategoryIds?: string | null;
  bookingStatus?: BookingServiceItemStatusTypeEnum | null;
  sortBy: any | null;
  fromDate: any | null;
  toDate: any | null;
  datePeriod: DatePeriodEnum | null;
  bookingId:any | null;
}


export interface GetBookingsFilterGqlInput {
  isSubscription?: boolean | null;
}

export interface PaginationGqlInput {
  pageNumber?: number | null;
  pageSize?: number | null;
}

export interface PartnerDocumentGqlInput {
  type: DocumentTypeEnum;
  medias: string[];
  documentUniqueValue?: string | null;
}

export interface PartnerRegisterAddressGqlInput {
  buildingName: string;
  address: string;
  locality?: string | null;
  landmark?: string | null;
  city: string;
  postalCode: string;
  area: string;
  isDefault?: boolean | null;
  // isBackgroundVerification: boolean | null;
  // backgroundVerificationNote: string | null; 
}

export interface PartnerRegisterGqlInput {
  name: string;
  address: PartnerRegisterAddressGqlInput;
  accountNumber?: string | null;
  email: string;
  ifscCode?: string | null;
  companyId?: any | null;
  photoId?: string | null;
  isZimkeyPartner?: boolean | null;
  // isBackgroundVerification: boolean | null;
  // backgroundVerificationNote: string | null;

}

export interface PartnerUpdateGqlInput {
  name?: string | null;
  address?: PartnerRegisterAddressGqlInput | null;
  email?: string | null;
  photoId?: string | null;
  companyId?: string | null;
  isBackgroundVerification: boolean | null;
  backgroundVerificationNote: string | null;
  isZimkeyPartner: boolean | null;
}

export interface PartnerUpdatePayoutGqlInput {
  accountNumber: string;
  ifscCode: string;
}

export interface ServiceAddonCreateGqlInput {
  name: string;
  description: string;
  unit: ServiceUnitEnum;
  type: AddonTypeEnum;
  commissionPercentage: any;
  unitPrice: ServiceBillingOptionPriceGqlInput;
  minUnit: number;
  maxUnit: number;
  serviceId: string;
}

export interface ServicePropertyAreaInsertGqlInput {
  titile: string;
  description: string ;
  hours:number;
  serviceId: string;
}

export interface ServiceAddonUpdateGqlInput {
  name?: string | null;
  description?: string | null;
  unit?: ServiceUnitEnum | null;
  type?: AddonTypeEnum | null;
  unitPrice?: ServiceBillingOptionPriceGqlInput | null;
  commissionPercentage: any;
  minUnit?: number | null;
  maxUnit?: number | null;
  serviceId?: string | null;
}

export interface ServiceBillingOptionCreateGqlInput {
  name: string;
  description: string;
  code: string;
  sortOrder?: number | null;
  recurring: boolean;
  recurringPeriod?: RecurringPeriodEnum | null;
  autoAssignPartner?: boolean | null;
  unitPrice: ServiceBillingOptionPriceGqlInput;
  additionalUnitPrice: ServiceBillingOptionPriceGqlInput;
  unit: ServiceUnitEnum;
  minUnit?: number | null;
  maxUnit?: number | null;
  commissionPercentage: any;
  serviceId: string;
  additionalMinUnit: number;
  additionalMaxUnit: number;
}

export interface ServiceBillingOptionPriceGqlInput {
  partnerPrice: number;
  commission: number;
  commissionTax: number;
  partnerTax: number;
  // commissionAmount: number;
  // commissionTotal: number;
  // partnerTotal: number;
  // grandTotal: number;
}

export interface ServiceBillingOptionUpdateGqlInput {
  name?: string | null;
  description?: string | null;
  code?: string | null;
  sortOrder?: number | null;
  recurring?: boolean | null;
  recurringPeriod?: RecurringPeriodEnum | null;
  autoAssignPartner?: boolean | null;
  unitPrice?: ServiceBillingOptionPriceGqlInput | null;
  additionalUnitPrice?: ServiceBillingOptionPriceGqlInput | null;
  unit?: ServiceUnitEnum | null;
  minUnit?: number | null;
  maxUnit?: number | null;
  serviceId?: string | null;
  commissionPercentage?: any | null;
  additionalMinUnit: number;
  additionalMaxUnit: number;
}

export interface ServiceCategoryCreateGqlInput {
  name: string;
  description: string;
  code: string;
  sortOrder?: number | null;
  parentId?: string | null;
}

export interface ServiceCategoryUpdateGqlInput {
  name?: string | null;
  description?: string | null;
  code?: string | null;
  parentId?: string | null;
  order?: number | null;
  enabled: boolean;
}

export interface ServiceCreateGqlInput {
  name: string;
  description: string;
  code: string;
  enabled: boolean;
  sortOrder?: number | null;
  serviceShortList?: ServiceShortListEnum[] | null;
  categoryIds: string[];
  reworkGracePeriod?: number | null;
  iconId?: string | null;
  thumbnailId?: string | null;
  mediaIds?: string[] | null;
  isZimkeyService: Boolean | null;
  HSNcode: string | null;
}

export interface ServiceInputCreateGqlInput {
  name: string;
  description: string;
  key: string;
  required: boolean;
  type: ServiceInputTypeEnum;
  serviceId: string;
}

export interface ServiceInputUpdateGqlInput {
  name?: string | null;
  description?: string | null;
  key?: string | null;
  required?: boolean | null;
  type?: ServiceInputTypeEnum | null;
  serviceId?: string | null;
}

export interface ServiceRequirementInsertGqlInput {
  title: string;
  description?: string | null;
  serviceId: string;
}

export interface ServiceRequirementUpdateGqlInput {
  title?: string | null;
  description?: string | null;
}

export interface ServiceUpdateGqlInput {
  name?: string | null;
  enabled?: boolean | null;
  description?: string | null;
  code?: string | null;
  sortOrder?: number | null;
  serviceShortList?: ServiceShortListEnum[] | null;
  categoryIds?: string[] | null;
  mediaIds?: string[] | null;
  reworkGracePeriod?: number | null;
  iconId?: string | null;
}

export interface UsersQueryGqlInput {
  type?: UserTypeEnum | null;
  name?: string | null;
  phone?: string | null;
}


export interface PartnerCompaniesQueryGqlInput {
  companyName?: string | null;
}

export interface CouponsQueryGqlInput {
  name?: string | null;
  fromDate?: any | null;
  toDate?: any | null;
}
export interface ReviewsQueryGqlInput {
  name?: string | null;
  fromDate?: any | null;
  toDate?: any | null;
  phone?: string | null;
}
export interface DashboardCountFiltersGqlInput {
  city?: string | null;
  fromDate?: any | null;
  toDate?: any | null;
  datePeriod?: DatePeriodEnum2;
}
export interface DashboardTopBookingsFilterGqlInput {
  filterBy?:FilterByEnum;
  city?: any | null;
  toDate?: any | null;
  fromDate?: any | null;
  datePeriod?: DatePeriodEnum2;
  serviceId?: string | null;
}
export interface DashboardTopRatingsFilterGqlInput {
  filterBy?:FilterByEnum;
  city?: any | null;
  toDate?: any | null;
  fromDate?: any | null;
  datePeriod?: DatePeriodEnum2;
  serviceId?: string | null;
}
export interface DashboardRevenueFiltersGqlInput   {
  datePeriod?:RevenueDatePeriodEnum;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
