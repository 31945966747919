import { gql } from '@apollo/client';

export const CMSQueryGetCoupons = gql`
	query CMSGetCoupons(
		$showAll: Boolean
		$pagination: PaginationGqlInput!
		$filters: CouponsQueryGqlInput
	) {
		getCoupons(showAll: $showAll, pagination: $pagination, filters: $filters) {
			data {
				id
				name
				description
				terms
				code
				enabled
				startDate
				endDate
				value
				usageLimit
				discountType
				maxUsePerCustomer
				minOrder
				forAllServices
				forAllUsers
				services {
					id
					name
				}
				serviceCategories {
					id
					name
				}
				areas {
					id
					name
				}
				cities {
					id
					name
				}
			}
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;

export const CMSQueryExportCoupons = gql`
	query CMSExportCoupons($filters: CouponsQueryGqlInput) {
		exportCoupons(filters: $filters) {
			id
			name
			description
			terms
			code
			enabled
			startDate
			endDate
			value
			usageLimit
			discountType
			maxUsePerCustomer
			minOrder
			forAllServices
			forAllUsers
			services {
				id
				name
			}
			serviceCategories {
				id
				name
			}
			areas {
				id
				name
			}
			cities {
				id
				name
			}
		}
	}
`;

export const CMSQueryGetAreas = gql`
	query CMSGetAreas {
		name
		city {
			name
		}
	}
`;

export const CMSQueryReviews = gql`
	query CMSGetReviews(
		$pagination: PaginationGqlInput!
		$filters: ReviewsQueryGqlInput!
	) {
		getReviews(pagination: $pagination, filters: $filters) {
			data {
				id
				userId
				user {
					id
					name
					phone
				}
				type
				rating
				review
				createDateTime
				bookingServiceItem {
					bookingServiceId
					id
					bookingService {
						booking {
							userBookingNumber
						}
					}
				}
			}
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;

export const CMSQueryGetHomeContent = gql`
	query getHomeContent {
		getHomeContent {
			id
			status
			image
			description
		}
	}
`;

export const CMSQueryGetCities = gql`
	query getCities {
		getCities {
			id
			name
			code
			areas {
				id
				name
				code
			}
		}
	}
`;

export const CMSQueryGetCustomerSupport = gql`
	query CustomerSupport(
		$pagination: PaginationGqlInput!
		$filters: CustomerSupportQueryGqlInput!
	) {
		getCustomerSupport(pagination: $pagination, filters: $filters) {
			data {
				id
				userId
				code
				createDateTime
				user {
					id
					name
					phone
					email
				}
				subject
				message
			}
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;
export const CMSQueryGetPushNotifications = gql`
	query getPushNotifications($pagination: PaginationGqlInput!) {
		getPushNotifications(pagination: $pagination) {
			data {
				id
				title
				description
				userType
				status
				scheduleDate
			}
			pageInfo {
				hasNextPage
				currentPage
				currentCount
				nextPage
				totalPage
				totalCount
			}
		}
	}
`;
