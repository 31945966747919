import React from 'react';

interface Props {
	title;
	width;
	value;
	color;
	maximum
}
const LineIndicatorDash = ({ title, width, value, color,maximum }: Props) => {
    console.log("maximum",maximum);
    
	return (
		<>
			<p>{title}</p>
			<div className="gx-line-indi-info">
				<div
					className={`gx-line-indi gx-bg-${color}`}
					style={{
						width: `${(Number.parseInt(width, 10) * 420) / maximum}px`,
						// maxWidth:"102px"
					}}
				/>

				<span className="gx-line-indi-count gx-ml-2">{value}</span>
			</div>
		</>
	);
};

export default LineIndicatorDash;
