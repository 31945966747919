import { atom } from 'recoil';
import { User } from '../interface';

export const userTokenAtom = atom<string | null | undefined>({
	key: 'userIdToken',
	default: undefined,
});

export const userAtom = atom<User | null>({
	key: 'authUser',
	default: null,
});
