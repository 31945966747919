import React from 'react';
import { Card, Row, Col, Button } from 'antd';
import { RouteList } from 'src/config/constants/routes';
import { Link } from 'react-router-dom';
import homePage from "./homePage.jpg";
import contentImg from "./content.jpeg";
import companyImg from "./company.jpeg";
import faxImg from "./faq.jpeg"
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { userAtom } from 'src/lib/recoil/userCoil';
import { useRecoilValue } from 'recoil';
import serviceImg from "./img.png"
import homeContentImg from "./homeContent.jpg"
{
	/* 
		onboarding
	popular services
	app content
								*/
}

const homePageMenus = [
	{ title: 'Banners', link: RouteList.DupehomeBanners.path },
	// { title: 'Homepage Services', link: RouteList.homepageServices.path },
];

const ManageApp: React.FC = () => {
	const userAuth = useRecoilValue(userAtom);

	const	 menus = [
	
			{ title: 'App Content', link: RouteList.appContent.path ,src:contentImg},
			{ title: 'Partner Companies', link: RouteList.managePartnerCompanies.path,src:companyImg },
			{ title: 'FAQs', link: RouteList.manageFaqs.path,src:faxImg },
		];
	

	return (
		<>
			<Card title="Manage Home Page" style={{borderRadius:"1rem",textAlign:"center",marginTop:"0rem"}}>
				<div className="gx-flex gx-flex-nowrap gx-mb-2 ">
					<Row>
						{homePageMenus.map((el) => {
							return (
								<Col span={8} className="gx-mb-4 " key={el.title}>


									{userAuth?.userType === UserTypeEnum.ADMIN ||
									userAuth?.rights?.find(
										(x) => x.type === 'sub2' && x.name === 'homepage-banners',
									) ? (

									<Link to={el.link}>
									<Card className={`gx-card-widget hover-card`}  style={{borderRadius:"1rem",display:"inline-block" ,boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}} bodyStyle={{minWidth: '11.5rem', display: 'flex', justifyContent: 'center'}}>
										<div className="gx-media gx-featured-item " style={{textAlign:"center"}}>
											{/* <div className="gx-featured-thumb" style={{ width: '0%' }}>
									<img className="gx-rounded-lg" src={'data.image'} alt="..." />
								</div> */}
											<div className="gx-media-body gx-featured-content card-diplay" >
												<div className="gx-featured-content-left">
													<h3 className="gx-mb-2 gx-mt-2" >{el.title}</h3>
													<br />

													
														{/* <Button className="gx-mb-0" type="primary">
															Edit
														</Button> */}
														<img src={homePage} height={80} style={{borderRadius:"1rem",marginBottom:"1rem"}}/>
											
												</div>
											</div>
										</div>
									</Card>		
									</Link>
									):(
	
									// <Link to={""} >
									<Card className={`gx-card-widget hover-card`}  style={{borderRadius:"1rem",display:"inline-block" ,boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
										<div className="gx-media gx-featured-item " style={{textAlign:"center"}}>
											{/* <div className="gx-featured-thumb" style={{ width: '0%' }}>
									<img className="gx-rounded-lg" src={'data.image'} alt="..." />
									</div> */}
											<div className="gx-media-body gx-featured-content card-diplay" >
												<div className="gx-featured-content-left">
													<h3 className="gx-mb-2 gx-mt-2" >{el.title}</h3>
													<br />

													
														{/* <Button className="gx-mb-0" type="primary">
															Edit
														</Button> */}
														<img src={homePage} height={80} style={{borderRadius:"1rem",marginBottom:"1rem"}}/>
											
												</div>
											</div>
										</div>
									</Card>		
									// </Link>
									)}
								</Col>
							);
						})}

						<Col span={8}>
						{userAuth?.userType === UserTypeEnum.ADMIN ||
											userAuth?.rights?.find(
												(x) => x.type === 'sub2' && x.name === 'Homepage-service-order',
											) ? (
												<Link to="/services/changeOrder?mode=serviceShowons">
									<Card className={`gx-card-widget hover-card`}  style={{borderRadius:"1rem",display:"inline-block" ,boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}} bodyStyle={{minWidth: '11.5rem', display: 'flex', justifyContent: 'center'}}>
										<div className="gx-media gx-featured-item " style={{textAlign:"center"}}>
											{/* <div className="gx-featured-thumb" style={{ width: '0%' }}>
										<img className="gx-rounded-lg" src={'data.image'} alt="..." />
											</div> */}
											<div className="gx-media-body gx-featured-content card-diplay" >
												<div className="gx-featured-content-left">
													<h3 className="gx-mb-2 gx-mt-2" >Service Order</h3>
													<br />												
														{/* <Button className="gx-mb-0" type="primary">
															Edit
														</Button> */}
														<img src={serviceImg} height={80} style={{borderRadius:"1rem",marginBottom:"1rem"}}/>
												</div>
											</div>
										</div>
									</Card>		
							</Link>
											) : (
												<></>
											)}
						
						</Col>

						<Col span={8}>
								{userAuth?.userType === UserTypeEnum.ADMIN ||
											userAuth?.rights?.find(
												(x) => x.type === 'sub2' && x.name === 'Homepage-content',
											) ? (
												<Link  to={RouteList.homeContent.path}>
									<Card className={`gx-card-widget hover-card`}  style={{borderRadius:"1rem",display:"inline-block" ,boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}} bodyStyle={{minWidth: '11.5rem', display: 'flex', justifyContent: 'center'}}>
										<div className="gx-media gx-featured-item " style={{textAlign:"center"}}>
											{/* <div className="gx-featured-thumb" style={{ width: '0%' }}>
										<img className="gx-rounded-lg" src={'data.image'} alt="..." />
											</div> */}
											<div className="gx-media-body gx-featured-content card-diplay" >
												<div className="gx-featured-content-left">
													<h3 className="gx-mb-2 gx-mt-2" >Home Content</h3>
													<br />												
														{/* <Button className="gx-mb-0" type="primary">
															Edit
														</Button> */}
														<img src={homeContentImg} height={80} style={{borderRadius:"1rem",marginBottom:"1rem"}}/>
												</div>
											</div>
										</div>
									</Card>		
							</Link>
											) : (
												<></>
											)}
						
						</Col>
					</Row>
				</div>
			</Card>

			<Card title="Manage App Content" style={{borderRadius:"1rem",textAlign:"center",marginTop:"1rem"}}>
				<div className="gx-flex gx-flex-nowrap gx-mb-2">
					<Row>
											{userAuth?.userType === UserTypeEnum.ADMIN ||
											userAuth?.rights?.find(
											(x) => x.type === 'sub2' && x.name === 'manage-app-content',
											) ? (
								<Col span={8} className="gx-mb-4" >
									<Link to={RouteList.appContent.path}>
									<Card className={`gx-card-widget hover-card-content`}  style={{borderRadius:"1rem",display:"inline-block",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
										<div className="gx-media gx-featured-item" style={{textAlign:"center"}}>
											<div className="gx-media-body gx-featured-content card-diplay">
												<div className="gx-featured-content-left">
													<h3 className="gx-mb-2 gx-mt-2">App Content</h3>
													<br />
													<img src={contentImg} height={80} style={{borderRadius:"1rem",marginBottom:"1rem"}}/>
												</div>
											</div>
										</div>
									</Card>	</Link>
								</Col>
											):(
									<Col span={8} className="gx-mb-4" >
								
									<Card className={`gx-card-widget hover-card-content`}  style={{borderRadius:"1rem",display:"inline-block",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
										<div className="gx-media gx-featured-item" style={{textAlign:"center"}}>
											<div className="gx-media-body gx-featured-content card-diplay">
												<div className="gx-featured-content-left">
													<h3 className="gx-mb-2 gx-mt-2">App Content</h3>
													<br />
													<img src={contentImg} height={80} style={{borderRadius:"1rem",marginBottom:"1rem"}}/>
												</div>
											</div>
										</div>
									</Card>	
								</Col>)}

								{userAuth?.userType === UserTypeEnum.ADMIN ||
											userAuth?.rights?.find(
											(x) => x.type === 'sub2' && x.name === 'partner-companies',
											) ? (
								<Col span={8} className="gx-mb-4" >
								<Link to={RouteList.managePartnerCompanies.path}>
								<Card className={`gx-card-widget hover-card-content`}  style={{borderRadius:"1rem",display:"inline-block",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
								<div className="gx-media gx-featured-item" style={{textAlign:"center"}}>
								<div className="gx-media-body gx-featured-content card-diplay">
								<div className="gx-featured-content-left">
								<h3 className="gx-mb-2 gx-mt-2">Partner Companies</h3>
								<br />
								<img src={companyImg} height={80} style={{borderRadius:"1rem",marginBottom:"1rem"}}/>			
								</div>
								</div>
								</div>
								</Card>	</Link>
								</Col>
											):(
												<Col span={8} className="gx-mb-4" >
								<Card className={`gx-card-widget hover-card-content`}  style={{borderRadius:"1rem",display:"inline-block",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
								<div className="gx-media gx-featured-item" style={{textAlign:"center"}}>
								<div className="gx-media-body gx-featured-content card-diplay">
								<div className="gx-featured-content-left">
								<h3 className="gx-mb-2 gx-mt-2">Partner Companies</h3>
								<br />
								<img src={companyImg} height={80} style={{borderRadius:"1rem",marginBottom:"1rem"}}/>			
								</div>
								</div>
								</div>
								</Card>	
								</Col>)}

								{userAuth?.userType === UserTypeEnum.ADMIN ||
											userAuth?.rights?.find(
											(x) => x.type === 'sub2' && x.name === 'manage-faq',
											) ? (
								<Col span={8} className="gx-mb-4" >
								<Link to={RouteList.manageFaqs.path}>
								<Card className={`gx-card-widget hover-card-content`}  style={{borderRadius:"1rem",display:"inline-block",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
								<div className="gx-media gx-featured-item" style={{textAlign:"center"}}>
								<div className="gx-media-body gx-featured-content card-diplay">
								<div className="gx-featured-content-left">
								<h3 className="gx-mb-2 gx-mt-2">Manage FAQs</h3>
								<br />
								<img src={faxImg} height={80} style={{borderRadius:"1rem",marginBottom:"1rem"}}/>			
								</div>
								</div>
								</div>
								</Card>	</Link>
								</Col>
											):(
												<Col span={8} className="gx-mb-4" >
												<Card className={`gx-card-widget hover-card-content`}  style={{borderRadius:"1rem",display:"inline-block",boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"}}>
												<div className="gx-media gx-featured-item" style={{textAlign:"center"}}>
												<div className="gx-media-body gx-featured-content card-diplay">
												<div className="gx-featured-content-left">
												<h3 className="gx-mb-2 gx-mt-2">Manage FAQs</h3>
												<br />
												<img src={faxImg} height={80} style={{borderRadius:"1rem",marginBottom:"1rem"}}/>			
												</div>
												</div>
												</div>
												</Card>
												</Col>
											)}
				
					</Row>
				</div>
			</Card>

	

		</>
	);
};
export default ManageApp;
