import { useQuery } from '@apollo/client';
import { Layout, Spin,Button } from 'antd';
import React, { useEffect ,useState,useRef} from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import Sidebar from 'src/components/layouts/sidebar/sidebar';
import { RouteList } from 'src/config/constants/routes';
import { CMSGetMe } from 'src/lib/gql/generated/CMSGetMe';
import { logoutUser } from './lib/firebase/fbClient';
import { UserTypeEnum } from './lib/gql/generated/globalTypes';
import { CMSQueryGetMe } from './lib/gql/queries/users';
import { userAtom, userTokenAtom } from './lib/recoil/userCoil';
import Signin from './pages/auth/signin/signin';
import BookingDetail from './pages/bookings/bookingDetail';
import Bookings from './pages/bookings/bookings';
import JobBoard from './pages/bookings/jobBoard';
import Subscriptions from './pages/bookings/subscriptions';
import ManageCouponCodes from './pages/coupon/coupon';
import DupManageCouponCodes from './pages/coupon/dupcoupon';
import CustomerSupport from './pages/customer-support';
import Dashboard from './pages/dashboard/dashboard';
import Loyalty from './pages/loyalty/loyalty';
import ManageApp from './pages/manage';
import AppConfig from './pages/manage/appConfig';
import AppContent from './pages/manage/appContent';
// import AppContent from './pages/manage/orginal';
import ManageFAQs from './pages/manage/faqs';
import HomepageBanners from './pages/manage/homepageBanners';
import DupehomeBanners from './pages/manage/duphomebanner';
import SelectHomePageServices from './pages/manage/homePageServices';
import ManageCities from './pages/manage/manageCities';
import OnboardingScreens from './pages/manage/onboardingScreens';
import ManagePartnerCompanies from './pages/manage/partnerCompanies';
import PopularServices from './pages/manage/popularServices';
import AddServicePartner from './pages/partners/addServicePartner';
import Partner from './pages/partners/partners';
import PartnerDetail from './pages/partners/partnersDetail';
import PartnerPending from './pages/partners/partnersPending';
import Reports from './pages/reports';
import AddService from './pages/services/addService';
import AddServiceCategory from './pages/services/addServiceCategory';
import ChangeOrder from './pages/services/changeOrder';
import AddServiceTimeSlots from './pages/services/addServiceTimeSlots';
import Services from './pages/services/services';
import Users from './pages/users/users';
import UserDetail from './pages/users/usersDetail';
import Zpoints from './pages/promotions/zpoints';
import Payouts from './pages/payouts/payouts';
import Admins from './pages/admins/admins';
import AddAdmin from './pages/admins/addAdmin';
import ServicePartners from './pages/reports/servicePartner';
import Jobs from './pages/reports/jobs';
import EditAdmin from './pages/admins/editAdmin';
import Roles from './pages/admins/roles';
import Rework from './pages/bookings/rework';
import FirstBookingExp from './pages/firstBookingExp/firstBookingExp';
import HomeContent from './pages/homeContent/homeContent';
import { firebaseConfig, messaging, onMessageListener } from './lib/firebase/firebase';
import { getToken } from "firebase/messaging";
import { notification } from 'antd';
import { app } from './lib/firebase/firebase';
import { REGISTER_FCM_TOKEN } from './lib/gql/mutations/mutations';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import Support from './pages/support/support';
import Personnel from './pages/TeamManagment/personnel';
import Teams from './pages/TeamManagment/teams';
import ServiceSettings from './pages/TeamManagment/serviceSettings';
import PayoutSettings from './pages/payouts/payoutSettings';
import PayoutsHistory from './pages/payouts/payoutsHistory';
import Notifications from './pages/notifications/notifications';

const { Content, Footer } = Layout;

export const Root: React.FC = () => {
	return <CheckAuth />;
};

export const CheckAuth: React.FC = () => {
	const userToken = useRecoilValue(userTokenAtom);
	const authUser = useRecoilValue(userAtom);

	if (userToken === undefined) {
		return (
			<div
				className="loading"
				style={{
					position: 'absolute',
					left: '50%',
					top: '50%',
					right: 'auto',
					bottom: 'auto',
				}}
			>
				<Spin />
			</div>
		);
	}
	if (typeof userToken === 'string' && authUser == null) {
		return <GetUser />;
	}
	return <MainComp />;
};

const GetUser = () => {
	const [, setUserToken] = useRecoilState(userTokenAtom);
	const [, setAuthUser] = useRecoilState(userAtom);
	const { data: qryData } = useQuery<CMSGetMe>(CMSQueryGetMe);
	useEffect(() => {
		if (qryData !== undefined) {
			if (qryData?.me === null) logoutUser(setUserToken);
			if (qryData.me !== null) {
				if (
					qryData.me.userType !== UserTypeEnum.ADMIN &&
					qryData.me.userType !== UserTypeEnum.SUB_ADMIN
				) {
					logoutUser(setUserToken);
				} else {
					setAuthUser({
						id: qryData?.me?.id,
						name: qryData?.me?.name || '',
						rights: qryData?.me?.rights || [],
						userType: qryData?.me?.userType,
					});
					localStorage.setItem(
						'zk_usr_dta',
						JSON.stringify({
							id: qryData?.me?.id,
							name: qryData?.me?.name || '',
						}),
					);
				}
			}
		}
	}, [qryData?.me, setAuthUser, setUserToken]);

	return (
		<div
			className="loading"
			style={{
				position: 'absolute',
				left: '50%',
				top: '50%',
				right: 'auto',
				bottom: 'auto',
			}}
		>
			<Spin />
		</div>
	);
};

const MainComp = () => {
	const [tokenVal,setTokenVal]=useState<any>()
	const { data: qryData } = useQuery<CMSGetMe>(CMSQueryGetMe);
	const [
		regsiterFcmToken,
		{ data: dataMtU, loading: loadingMtU, error: errorMtU },
	] = useMutation(REGISTER_FCM_TOKEN);

	// regsiterFcmToken({
	// 	variables: {
	// 		data: {
	// 			deviceId:"5",
	// 			token:tokenVal,
	// 			device:"WEB",
	// 			app:"ADMIN"
	// 		}}})

	const authUser = useRecoilValue(userAtom);
	const navigate = useNavigate();
	// const audioPlayer = useRef<any>(null);
	// function playAudio() {
	// 	audioPlayer.current.play();
	//   }
	useEffect(() => {
		if (authUser === null) {
			navigate(RouteList.login.path);
		}
	}, [history, authUser]);
	const userAuth = useRecoilValue(userAtom);

	useEffect(() => {
		async function requestAndRegisterToken() {
		  try {
			const permission = await Notification.requestPermission();
			if (permission === "granted") {
			  let token = "";
			  if (qryData !== undefined) {
				token = await getToken(messaging, {
				  vapidKey:
					"BN8kdMP5Gnq04uAa2KgtSmG06mxd3n4dmyPU8-d_A57x6lh_XTXiGi1fMbIQWr0nQYKlZ_VjoYPnfxa8H72StRY",
				});
				setTokenVal(token);
				console.log('Got permission and going to register')
				regsiterFcmToken({
				  variables: {
					data: {
					  token: token,
					  device: "WEB",
					  app: "ADMIN",
					},
				  },
				}).catch((error) => {
					console.log('tre-',error)
				});
			  }
			} else if (permission === "denied") {
			  alert("You denied for the notification");
			}
		  } catch (error) {
			console.error("Error while requesting and registering FCM token:", error);
		  }
		}

		const messageListenerCleanup: () => void = () => {
			onMessageListener().then((payload:any) => {
			if (!show) {
			  setShow(true);
			  setNotifications({
				title: payload?.notification?.title ?? '',
				body: payload?.notification?.body ?? ''
			  });
			  openNotification({
				title: payload?.notification?.title ?? '',
				body: payload?.notification?.body ?? '',
				id: payload?.data?.bookingServiceItemId ?? ''
			  });
			  console.log("pay", payload);
			  audio.play();
			  playAudio();
			}
		  }).catch(err => console.log('failed: ', err));
	  
		  return () => {
			// Clean up the message listener when the component unmounts
			messageListenerCleanup();
		  };
		}
		requestAndRegisterToken();
	  }, [qryData?.me, regsiterFcmToken]);

	  const [show, setShow] = useState(false);
	  const [notifications, setNotifications] = useState({title: '', body: ''});
	  const [audio] = useState(new Audio('/assets/music/notification.mp3'));
	  const audioPlayer = useRef<HTMLAudioElement | null>(null);

	//   useEffect(() => {
	// 	const audio = new Audio("/assets/music/horse.mp3");
	// 	audio.play()
	//   }, [notifications]);

		const  playAudio=()=> {
			// console.log("audio",audioPlayer);
			// audio.play()
		if (audio) {
			audio.muted = false;
		  audio.play().catch(error => {
			console.error("Audio playback error:", error);
		  });
		}
	  }

	  onMessageListener().then((payload: any) => {
		setShow(true);
		setNotifications({title: payload?.notification?.title ?? '', body: payload?.notification?.body ?? ''})
		openNotification({title: payload?.notification?.title ?? '', body: payload?.notification?.body ?? '',id: payload?.data?.bookingServiceItemId ?? ''})
		console.log("pay",payload);
		audio.play(); 
		playAudio()
		// <audio ref={audioPlayer} src="/assets/music/horse.mp3" />
	  }).catch(err => console.log('failed: ', err));
	 

	const openNotification = (notificationData) => {
		notification.open({
		  message: (
			<span className="message"><b>{notificationData?.title}</b></span>
		  ),
		  description: (
			<span className="description">{notificationData?.body}</span>
		  ),
		  duration:10,
		  className: "notificationMessage",
		  onClick: () => {
			navigate(`bookings/${notificationData?.id}`); // Replace '/your-target-page' with the actual route you want to navigate to
		  },
		  onClose: () => setShow(false) 
		});
		// setShow(false)
	  };

	// function playAudios() {
	// 	const audio = new Audio("/assets/music/horse.mp3");
	// 	audio.addEventListener('/assets/music/horse.mp3', (event) => {
	// 	// the audio is now playable; play it if permissions allow
	// 	audio.play();
	//   });
	// }

	return (
		<Layout className="gx-app-layout">
			{authUser === null ? (
				<Routes>
					<Route element={<Signin />} path={RouteList.login.path} />
				</Routes>
			) : (
				<>
					<Sidebar />
					<Layout> 
					{/* <Button type="primary" onClick={playAudios}>
    				Open the notification box
  					</Button> */}
					 { show && 
					 <audio ref={audioPlayer} src="/assets/music/notification.mp3" muted={true} />
					}
					 {/* <button onClick={playAudio}>Play Audio</button> */}
						<Content className={`gx-layout-content`}>
							<div className="gx-main-content-wrapper">
								<Routes>
									<Route
										path={RouteList.dashboard.path}
										element={<Dashboard />}
									/>
									{userAuth?.userType === UserTypeEnum.ADMIN || userAuth?.rights?.find(
											(x) => x.type === 'sub' && x.name === 'customers'
										) ? (
											<Route element={<Users />} path={RouteList.users.path} />
										) : <></>}
									{userAuth?.userType === UserTypeEnum.ADMIN ||
										userAuth?.rights?.find(
											(x) => x.type === 'sub' && x.name === 'customers',
										) ? (
											<Route
												element={<UserDetail />}
												path={RouteList.userDetail.path}
											/>
										) : <></>}
									{userAuth?.userType === UserTypeEnum.ADMIN ||
										userAuth?.rights?.find(
											(x) => x.type === 'sub' && x.name === 'service-partners',
										) ? (
											<>
												<Route
													element={<Partner />}
													path={RouteList.partners.path}
												/>
												<Route
													element={<PartnerDetail />}
													path={RouteList.partnerDetail.path}
												/>
											</>
										) : <></>}
									{userAuth?.userType === UserTypeEnum.ADMIN ||
										userAuth?.rights?.find(
											(x) => x.type === 'sub' && x.name === 'pending-partners',
										) ? (
											<Route
												element={<PartnerPending />}
												path={RouteList.partnersPending.path}
											/>
										) : <></>}
									{userAuth?.userType === UserTypeEnum.ADMIN ||
										userAuth?.rights?.find(
											(x) => x.type === 'sub2' && x.name === 'add',
										) ? (
											<Route
												element={<AddServicePartner />}
												path={RouteList.addPartner.path}
											/>
										) : <></>}
									<Route
										element={<Subscriptions />}
										path={RouteList.subscriptions.path}
									/>
									<Route
										element={<JobBoard />}
										path={RouteList.jobBoard.path}
									/>
									<Route
										element={<Bookings />}
										path={RouteList.bookings.path}
									/>
										<Route
										element={<Rework />}
										path={RouteList.rework.path}
									/>
									<Route
										element={<BookingDetail />}
										path={RouteList.bookingDetail.path}
									/>
									<Route
										element={<Services />}
										path={RouteList.services.path}
									/>
									<Route
										element={<AddService />}
										path={RouteList.addService.path}
									/>
									<Route
										element={<AddService />}
										path={RouteList.editService.path}
									/>
									{userAuth?.userType === UserTypeEnum.ADMIN ||
										userAuth?.rights?.find(
											(x) => x.type === 'sub2' && x.name === 'service-category-add',
										) ? (
											<Route
											element={<AddServiceCategory />}
											path={RouteList.addServiceCategory.path}
										/>
										) : <></>}
									
									<Route
										element={<ChangeOrder />}
										path={RouteList.changeOrder.path}
									/>
									<Route
										element={<AddServiceTimeSlots />}
										path={RouteList.addServiceTimeSlots.path}
									/>

									{userAuth?.userType === UserTypeEnum.ADMIN ||
										userAuth?.rights?.find(
											(x) => x.type === 'sub' && x.name === 'app-content',
										) ? (
									<Route
										element={<ManageApp />}
										path={RouteList.manageApp.path}
									/>
										) : <></>}

									{userAuth?.userType === UserTypeEnum.ADMIN ||
										userAuth?.rights?.find(
											(x) => x.type === 'sub' && x.name === 'app-config',
										) ? (
									<Route
										element={<AppConfig />}
										path={RouteList.manageAppConfig.path}
									/>
										) : <></>}

									<Route
										element={<ManagePartnerCompanies />}
										path={RouteList.managePartnerCompanies.path}
									/>
									<Route
										element={<OnboardingScreens />}
										path={RouteList.onboardingScreens.path}
									/>
									<Route
										element={<AppContent />}
										path={RouteList.appContent.path}
									/>
									<Route
										element={<HomepageBanners />}
										path={RouteList.homepageBanners.path}
									/>
									<Route
										element={<DupehomeBanners />}
										path={RouteList.DupehomeBanners.path}
									/>
									<Route
										element={<SelectHomePageServices />}
										path={RouteList.homepageServices.path}
									/>
									<Route
										element={<ManageCities />}
										path={RouteList.manageCities.path}
									/>
									<Route
										element={<PopularServices />}
										path={RouteList.popularServices.path}
									/>
									<Route
										element={<ManageCouponCodes />}
										path={RouteList.manageCoupon.path}
									/>
									{userAuth?.userType === UserTypeEnum.ADMIN ||
										userAuth?.rights?.find(
											(x) => x.type === 'sub' && x.name === 'coupon-codes',
										) ? (
									<Route
										element={<DupManageCouponCodes />}
										path={RouteList.dupmanageCoupon.path}
									/>
										) : <></>}
									<Route
										element={<ManageFAQs />}
										path={RouteList.manageFaqs.path}
									/>
									<Route
										element={<CustomerSupport />}
										path={RouteList.customerSupport.path}
									/>
									<Route element={<Loyalty />} path={RouteList.loyalty.path} />
									<Route element={<Reports />} path={RouteList.reports.path} />
									{userAuth?.userType === UserTypeEnum.ADMIN ||
										userAuth?.rights?.find(
											(x) => x.type === 'sub' && x.name === 'z-points',
										) ? (
									<Route element={<Zpoints />} path={RouteList.zpoints.path} />
										) : <></>}
									<Route element={<Payouts />} path={RouteList.payouts.path} />

									{userAuth?.userType === UserTypeEnum.ADMIN ? (
										<>
									<Route element={<Admins />} path={RouteList.admins.path} />
									<Route
										element={<AddAdmin />}
										path={RouteList.addAdmin.path}
									/>
									<Route
										element={<EditAdmin />}
										path={RouteList.editAdmin.path}
									/>

									<Route element={<Roles />} path={RouteList.adminRoles.path} />
									</>
									) : <></>}

									<Route
										element={<ServicePartners />}
										path={RouteList.servicePartners.path}
									/>
									<Route element={<Jobs />} path={RouteList.jobs.path} />
									<Route
										element={<FirstBookingExp />}
										path={RouteList.firstBookingExp.path}
									/>
										<Route
										element={<Support />}
										path={RouteList.support.path}
									/>
									<Route
										element={<HomeContent />}
										path={RouteList.homeContent.path}
									/>
									<Route
										element={<Personnel />}
										path={RouteList.personnel.path}
									/>
									<Route
										element={<Teams />}
										path={RouteList.teams.path}
									/>
									<Route
										element={<ServiceSettings />}
										path={RouteList.serviceSettings.path}
									/>
									<Route
										element={<PayoutsHistory />}
										path={RouteList.payoutsHistory.path}
									/>
									<Route
										element={<PayoutSettings />}
										path={RouteList.payoutSettings.path}
									/>
										<Route
										element={<Notifications />}
										path={RouteList.notifications.path}
									/>
								</Routes>
							</div>
							<Footer>
								<div
									className="gx-layout-footer-content"
									style={{ textAlign: 'right' }}
								>
									Copyright Zimkey © {new Date().getFullYear()}
								</div>
							</Footer>
						</Content>
					</Layout>
				</>
			)}
		</Layout>
	);
};

export default Root;
