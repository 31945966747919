import { useMutation, useQuery } from '@apollo/client';
import {
	Alert,
	Button,
	Card,
	Col,
	Input,
	Modal,
	Pagination,
	Row,
	Space,
	Spin,
	Table,Rate
} from 'antd';
import { RouteList } from 'src/config/constants/routes';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
	CMSCommitJob,
	CMSCommitJobVariables,
} from 'src/lib/gql/generated/CMSCommitJob';
import {
	CMSGetUsers,
	CMSGetUsersVariables,
} from 'src/lib/gql/generated/CMSGetUsers';
import { UserTypeEnum } from 'src/lib/gql/generated/globalTypes';
import { BOOKING_COMMITJOB } from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetPartners } from 'src/lib/gql/queries/users';
import { CMSQueryGetAssignPartners } from 'src/lib/gql/queries/bookings';


interface Props {
	input?: any;
	onFinishAdd?: () => void;
}

const AssignPartner = ({ input, onFinishAdd }: Props) => {
	console.log("input",input);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [
		createServiceInputOption,
		{ data: dataMt, loading: loadingMt, error: errorMt },
	] = useMutation<CMSCommitJob, CMSCommitJobVariables>(BOOKING_COMMITJOB
		,{
		onCompleted: () => { 
			if (typeof onFinishAdd === 'function') {
				onFinishAdd();
			}
			setTimeout(() => {
				setIsModalVisible(false);
			}, 2000);
		},
		fetchPolicy: 'network-only',
	});

	const showModal = () => {
		setIsModalVisible(true);
		// refetchQryDataAssign();
		setIsQueryCalled(true);

		callQuery();
	};

	const handleCancel = () => {
		console.log("insideee");
		
		if (typeof onFinishAdd === 'function') {
			onFinishAdd();
		}
		setIsModalVisible(false);
		setIsQueryCalled(false);	

	};
	const handleOk = () => {
		setIsModalVisible(false);
		setIsQueryCalled(false);
	};

	const onCommit = (partner) => {
		createServiceInputOption({
			variables: {
				partnerId: partner?.id,
				jobBoardId: input?.id,
			},
		}).then(() => {
			console.log('done');
			// onFinishAdd
		});
	};

	//Pull Partner data
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
	});
	const [filterName, SetFilterName] = useState('');
	const [filterPhone, SetFilterPhone] = useState('');
	const [isQueryCalled, setIsQueryCalled] = useState(false);

	// const {
	// 	data: qryData,
	// 	loading,
	// 	called,
	// 	error,
	// 	refetch: refetchQryData,
	// } = useQuery<CMSGetUsers, CMSGetUsersVariables>(CMSQueryGetPartners, {
	// 	variables: {
	// 		pagination: pagination,
	// 		filters: {
	// 			type: UserTypeEnum.PARTNER,
	// 			name: filterName,
	// 		},
	// 	},
	// });
// console.log("input+",input);

// useEffect(() => {
// }, []);
// const [qryDataAssign,setQryDataAssign]=useState<any>()
// const [loading,setLoading]=useState<any>()


  const callQuery = () => {
    setIsQueryCalled(true);
  };

	const {
		data: qryDataAssign,
		loading,
		called,
		error,
		refetch: refetchQryDataAssign,
	} = useQuery(CMSQueryGetAssignPartners, {
		variables: isQueryCalled ? {
			pagination: pagination,
			filters: {
				type: UserTypeEnum.PARTNER,
				name: filterName,
				phone:filterPhone
			},
			bookingServiceItemId: 	input?.bookingServiceItemId
		} 
		: {

			pagination: pagination,
			filters: {
				type: null,
				name: null,
				phone:null
			},
		},
		fetchPolicy: 'network-only'
	})


	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: 150,
			render: (name, record) => <span className="gx-link">
			<Link to={RouteList.partners.path + '/' + record.id}>{name}</Link>
		</span>,
		},
		{
			title: 'Phone',
			dataIndex: 'phone',
			key: 'phone',
			width: 150,
			render: (phone, record) => <span>{phone} </span>,
		},
		{
			title: 'Total Bookings',
			dataIndex: 'bookings',
			key: 'bookings',
			align: 'center' as const,
			render: (partnerAnalytics, record) => <span>{record?.partnerAnalytics?.totalBookings}</span>,
		},
		{
			title: 'Open Bookings',
			dataIndex: 'bookings',
			key: 'bookings',
			align: 'center' as const,
			render: (partnerAnalytics, record) => (
				<span>{record?.partnerAnalytics?.openBookings}</span>
			),
		},
		// {
		// 	title: 'Rework status',
		// 	dataIndex: 'rework',
		// 	key: 'rework',
		// },
		// {
		// 	title: 'No. of Cancellation',
		// 	dataIndex: 'cancelations',
		// 	key: 'cancelations',
		// 	align: 'center' as const,
		// },
		{
			title: 'Ratings',
			dataIndex: 'totalratings',
			key: 'totalratings',
			render: (text, record) => (
				<span>
					{/* <span>⭐⭐⭐⭐</span> */}
					<Rate
					disabled
					defaultValue={record?.rating}
				style={{ color: 'green' }}
				/>
				</span>
			),
		},
		{
			title: 'Action',
			dataIndex: 'key',
			key: 'id',
			width: 'auto',
			render: (text, record) => (
				<Button type="primary" onClick={() => onCommit(record)}>
					Assign
				</Button>
			),
		},
	];

	return (
		<>
			<Button type="primary" onClick={showModal}>
				Assign
			</Button>
			<Modal
				title={'Assign'}
				visible={isModalVisible}
				onCancel={handleCancel}
				onOk={handleOk}
				footer={null}
				width={1000}
			>
				{/* Commiting */}
				{dataMt && <Alert message="Created" type="success" showIcon />}
				{loadingMt && <Alert message="Submitting..." type="info" showIcon />}
				{errorMt && (
					<Alert
						message={`Submission error! ${errorMt.message}`}
						type="error"
						showIcon
					/>
				)}

				<Card >
					<Row style={{marginBottom: "16px",
   							 paddingTop: "0.2rem",
   							 paddingBottom: "5px"}}>
						<Col span={24}>
						<div style={{justifyContent:"center",
					display:"flex"}}>
					<div>
							<Input
								placeholder="Search by Name"
								value={filterName}
								onChange={(e) => {
									SetFilterName(e.target.value);
									// refetchQryDataAssign();
								}}
							/>
						</div>
				<div style={{paddingLeft:"0.5rem"}}>

						{/* </Col> */}
						{/* <Col span={4}> */}
							<Input
								placeholder="Search by Phone"
								value={filterPhone}
								allowClear
								onChange={(e) => SetFilterPhone(e.target.value)}
							/></div></div>
						</Col>
						</Row>
						<Row justify="end" style={{ marginBottom: 16 ,paddingTop:"1rem",
						backgroundColor: "#f6f6f6",
						paddingBottom: "5px"}}>
						<Col span={24} style={{textAlign:"center"}}>
							<Pagination
								key="pagination"
								defaultCurrent={qryDataAssign?.getAssignPartners?.pageInfo.currentPage}
								current={qryDataAssign?.getAssignPartners?.pageInfo.currentPage}
								pageSize={pagination.pageSize}
								total={qryDataAssign?.getAssignPartners?.pageInfo.totalCount}
								showTotal={(total) => `Total ${total} items`}
								onChange={(page, pageSize) => {
									console.log('onChange');
									setPagination({
										pageNumber: page,
										pageSize: pageSize ? +pageSize : pagination.pageSize,
									});
								}}
								showSizeChanger
							/>
						</Col>
					</Row>

					{loading && (
						<Row justify="center">
							<Space size="middle">
								<Spin size="large" />
							</Space>
						</Row>
					)}

					{qryDataAssign && (
						<Table
							className="gx-table-responsive"
							columns={columns}
							dataSource={qryDataAssign?.getAssignPartners?.data}
							pagination={false}
						/>
					)}
				</Card>
			</Modal>
		</>
	);
};
export default AssignPartner;
