import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Card, Col, Form, Input, Row, Space, Spin } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, useLocation, useParams } from 'react-router-dom';
import { RouteList } from 'src/config/constants/routes';
import {
	CMSCreateServiceCategory,
	CMSCreateServiceCategoryVariables,
} from 'src/lib/gql/generated/CMSCreateServiceCategory';
import {
	CMSGetServiceCategory,
	CMSGetServiceCategoryVariables,
} from 'src/lib/gql/generated/CMSGetServiceCategory';
import { CREATE_SERVICE_CATEGORY } from 'src/lib/gql/mutations/mutations';
import { CMSQueryGetServiceCategory } from 'src/lib/gql/queries/services';
import { CaretLeftOutlined } from '@ant-design/icons';
import { CMSGetAllServiceCategories_getServiceCategories } from 'src/lib/gql/generated/CMSGetAllServiceCategories';
import { CMSGetAllServiceCategories } from 'src/lib/gql/generated/CMSGetAllServiceCategories';
import { CMSQueryGetAllServiceCategories } from 'src/lib/gql/queries/services';

function useQueryParam() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
};

const AddServiceCategory= () => {
// const aaa=	 CMSGetAllServiceCategories_getServiceCategories;
	
	const [form] = Form.useForm();

	const isEditMode = useQueryParam().get('mode');
	const { id } = useParams<{ id: string }>();



	const { loading, data: qryData,refetch: refetchQryData, } = useQuery<
		CMSGetServiceCategory,
		CMSGetServiceCategoryVariables
	>(CMSQueryGetServiceCategory, {
		variables: {
			id: id || '',
		},
		skip: !isEditMode,
	});

		const {
		data: qryDataaaa,
		refetch: refetchQryDataaaa,
	} = useQuery<CMSGetAllServiceCategories>(CMSQueryGetAllServiceCategories);

	const [
		addServiceCategory,
		{ data: dataMt, loading: loadingMt, error: errorMt },
	] = useMutation<CMSCreateServiceCategory, CMSCreateServiceCategoryVariables>(
		CREATE_SERVICE_CATEGORY,
		{
			onCompleted: () => {
				refetchQryDataaaa();
			},
		}
	);
	const onFinish = (values) => {
		addServiceCategory({
			variables: {
				data: {
					name: values.name,
					description: values.description,
					code: values.code,
				},
			},
		}).then(() => {
			navigate('/services');
			form.resetFields();
		});
	};

	const navigate = useNavigate();

	return (
		<Card title="Services">
			{loading && (
				<Row justify="center">
					<Space size="middle">
						<Spin size="large" />
					</Space>
				</Row>
			)}
			<div className="gx-flex gx-flex-nowrap gx-mb-2">
				{/* <div className="gx-card-profile">
					<div className="ant-card-head ">
						<Link to={RouteList.addService.path}>
							<Button className="gx-mb-0" type="primary">
								Add new service
							</Button>
						</Link>
					</div>

					<div className="gx-pt-md-3"></div>
				</div> */}

				{!loading && (
					<Row>
						<Col span={24} className="gx-mb-4">
							<div style={{ display: 'flex' }}>
								<Button
									className="gx-mr-4"
									onClick={() => navigate(-1)}
									style={{ margin: '0' }}
								>
									<CaretLeftOutlined />
								</Button>
								<h1> {isEditMode ? 'Edit' : 'Add Service Category'}</h1>
							</div>
							<hr />

							{dataMt && <Alert message="Created" type="success" showIcon />}
							{loadingMt && (
								<Alert message="Submitting..." type="info" showIcon />
							)}
							{errorMt && (
								<Alert
									message={`Submission error! ${errorMt.message}`}
									type="error"
									showIcon
								/>
							)}
						</Col>
						<Col span={24} className="gx-mb-4">
							{!loading && (
								<Form
									{...formItemLayout}
									form={form}
									name="register"
									onFinish={onFinish}
									initialValues={{
										name: qryData?.getServiceCategory.name,
									}}
									scrollToFirstError
								>
									{/* <h3>Fill options</h3> */}

									<Col span={16}>
										<Form.Item
											name="name"
											label={<span>Name</span>}
											rules={[
												{
													required: true,
													message: 'Please input service category name',
													whitespace: true,
												},
											]}
										>
											<Input autoComplete="off" />
										</Form.Item>

										<Form.Item
											name="code"
											label={<span>Code</span>}
											rules={[
												{
													required: true,
													message: 'Please category code',
													whitespace: true,
												},
											]}
										>
											<Input autoComplete="off" />
										</Form.Item>

										<Form.Item
											name="description"
											label={<span>Description</span>}
											rules={[
												{
													required: true,
													message: 'Please input category description',
													whitespace: true,
												},
											]}
										>
											<Input.TextArea />
										</Form.Item>
									</Col>

									{isEditMode && (
										<Col span={24} className="gx-mb-4">
											<h1>Services</h1>
											<hr />
										</Col>
									)}

									<Form.Item
										{...tailFormItemLayout}
										style={{ float: 'right', marginTop: '20px' }}
									>
										<Button type="primary" htmlType="submit">
											{isEditMode ? 'Save' : 'Add'}
										</Button>
									</Form.Item>
								</Form>
							)}
						</Col>
					</Row>
				)}
			</div>
		</Card>
	);
};
export default AddServiceCategory;
