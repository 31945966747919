export default [
	{
		title: 'All',
	},
	{
		title: 'None',
	},
	{
		title: 'Starred',
	},
	{
		title: 'Unstarred',
	},
	{
		title: 'Important',
	},
	{
		title: 'Unimportant',
	},
];
