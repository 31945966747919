import React from 'react';
import { Col, Row, Tabs, Card } from 'antd';
import AboutItem from '../about/aboutItem';
import graphStatus from './graph';
import TaskByStatus from 'src/components/dashboard/TaskByStatus';

const TabPane = Tabs.TabPane;
interface Props {
	countInfo: any;
}

const Counts = ({countInfo}: Props) => {
	
	return (
		<Card
			
			className={`gx-card-widget gx-card-profile`} 
		>
			<Tabs className="gx-tabs-right" defaultActiveKey="1">
				<TabPane key="1">
					<div className="gx-mb-2">
						<Row>
						<Col xl={5} lg={12} md={12} sm={12} xs={24}>
						<Card style={{backgroundColor:"#d4d4ff",borderRadius:"10px",marginBottom:"1rem",height:"150px"}}>
						<Row>
						
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"3rem"}}>
						<h5>Total Bookings</h5>
						<h1 className="gx-mb-1 gx-text-grey">{countInfo?.bookings?.length}</h1>
						</div>
					
						</Row>	</Card>
						<Card style={{backgroundColor:"#d5dfd5",borderRadius:"10px",height:"150px"}}>
						<Row>
					
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"3rem"}}>
						<h5>Points Earned</h5>
						<h1 className="gx-mb-1 gx-text-grey">{countInfo?.analytics?.totalPointsEarned}</h1>
						</div>	
				
						</Row>		</Card>
						</Col>
						<Col xl={5} lg={12} md={12} sm={12} xs={24} >
						<Card style={{backgroundColor:"#ffb882",borderRadius:"10px",marginBottom:"1rem",height:"150px"}}>
						<Row>
						
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"3rem"}}>
						<h5>Completed Bookings</h5>
						<h1 className="gx-mb-1 gx-text-grey">{countInfo?.analytics?.completedBookings}</h1>
						</div>
				
						</Row>		</Card>
						<Card style={{backgroundColor:"#faf5b8",borderRadius:"10px",height:"150px"}}>
						<Row >
					
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"3rem"}}>
						<h5>Points Redeemed</h5>
						<h1 className="gx-mb-1 gx-text-grey">{countInfo?.analytics?.totalPointsRedeemed}</h1>
						</div>
					
						</Row>	</Card>
						
						</Col>
						
						<Col xl={14} lg={12} md={8} sm={12} xs={24} >
						<Card style={{
							height:" 100%",
							display: "grid",
							alignItems: "center",borderRadius:"10px"
							
						}}>
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"1rem"}}>
					
						<TaskByStatus TotalBooking={countInfo?.analytics} Booking={countInfo?.bookings} />
						</div>
						</Card>
						
						</Col>
						</Row>
						
						
					<Row style={{marginTop:"1rem"}}>
						<Col xl={6} lg={12} md={12} sm={12} xs={24}>
						<Card style={{
							height:" 100%",
							display: "grid",
							alignItems: "center",borderRadius:"10px",backgroundColor:"#c4fcee"
						}}>
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"1rem"}}>
						<h5>Open Bookings</h5>
						<h1 className="gx-mb-1 gx-text-grey">{countInfo?.analytics?.openBookings}</h1>
						</div>	</Card>
						</Col>
						<Col xl={6} lg={12} md={12} sm={12} xs={24}>
						<Card style={{
							height:" 100%",
							display: "grid",
							alignItems: "center",borderRadius:"10px",backgroundColor:"#ffd4e5" 
						}}>
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"1rem"}}>
						<h5>Canceled Bookings</h5>
						<h1 className="gx-mb-1 gx-text-grey">{countInfo?.analytics?.canceledBookings}</h1>
						</div></Card>
						</Col>
						<Col xl={6} lg={12} md={12} sm={12} xs={24}>
						<Card style={{
							height:" 100%",
							display: "grid",
							alignItems: "center",borderRadius:"10px",backgroundColor:"#e7bcb5"
						}}>
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"1rem"}}>
						<h5>Total Spend</h5>
						<h1 className="gx-mb-1 gx-text-grey">{countInfo?.analytics?.totalSpend}</h1>
						</div></Card>
						</Col>
						<Col xl={6} lg={12} md={12} sm={12} xs={24}>
						<Card style={{
							height:" 100%",
							display: "grid",
							alignItems: "center",borderRadius:"10px",backgroundColor:"#cba1d5"
						}}>
						<div className="gx-media-body" style={{textAlign:"center",paddingTop:"1rem"}}>
						<h5>Favourite Service</h5>
						<h1 className="gx-mb-1 gx-text-grey">{countInfo?.customerDetails?.favoriteServices?.length}</h1>
						</div></Card>
						</Col>
						</Row>
					</div>
				</TabPane>
				{/* <TabPane tab="Work" key="2">
            <div className="gx-mb-2">
              <Row>{aboutList.map((about, index) =>
                <Col key={index} xl={8} lg={12} md={12} sm={12} xs={24}>
                  <AboutItem data={about}/>
                </Col>
              )}
              </Row>
            </div>
          </TabPane>
          <TabPane tab="Education" key="3">
            <div className="gx-mb-2">
              <Row>
                {aboutList.map((about, index) =>

                  <Col key={index} xl={8} lg={12} md={12} sm={12} xs={24}>
                    <AboutItem data={about}/>
                  </Col>
                )}
              </Row>
            </div>
          </TabPane> */}
			</Tabs>
		</Card>
	);
};

export default Counts;
