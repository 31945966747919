import { AutoComplete, Button, Card, Col, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 2 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 0,
		},
	},
};

const normFile = (e) => {

	if (Array.isArray(e)) {
		return e;
	}

	return e && e.fileList;
};
const formItemLayoutWithOutLabel = {
	wrapperCol: {
		xs: { span: 24, offset: 0 },
		sm: { span: 20, offset: 4 },
	},
};

const ManageCities: React.FC = () => {
	const navigate = useNavigate();

	function handleChange(value) {
		console.log(`Selected: ${value}`);
	}

	const raw = '{\n    "country": "nigeria"\n}';

	const requestOptions: RequestInit = {
		method: 'POST',
		body: raw,
		redirect: 'follow',
	};

	const [data, setData] = useState<any>({ cities: [] });

	useEffect(() => {
		const fetchData = async () => {
			const result = fetch('https://countriesnow.space/api/v0.1/countries')
				.then((response) => response.text())
				.then((result: any) => {
					// console.log('result?.data');
					// console.log(
					// 	JSON.parse(result)?.data?.filter((obj) => obj.country === 'India'),
					// );
					setData(
						JSON.parse(result)?.data?.filter(
							(obj) => obj.country === 'India',
						)[0],
					);
				})
				.catch((error) => console.log('error', error));

			// const result = await fetch(
			// 	'https://countriesnow.space/api/v0.1/countries/cities',
			// 	requestOptions,
			// );
		};

		fetchData();
	}, []);

	const children: any = [];
	if (data.cities) {
		data.cities.map((city, i) => {
			children.push(
				<Option key={city} value={city}>
					{city}
				</Option>,
			);
		});
	}

	// for (let i = 10; i < 36; i++) {
	// 	children.push(
	// 		<Option key={i.toString(36) + i} value={i.toString(36) + i}>
	// 			{i.toString(36) + i}
	// 		</Option>,
	// 	);
	// }


	return (
		<Card title="Manage cities">
			<div className="gx-flex gx-flex-nowrap gx-mb-2">
				<div className="gx-card-profile">
					<div className="ant-card-head ">
						<Button className="gx-mr-4" onClick={() => navigate(-1)}>
							Back
						</Button>

						{/* <span className="ant-card-head-title gx-mb-1">Bookings</span> */}
						{/* <p className="gx-text-grey gx-fs-sm gx-mb-0">Booking List</p> */}
					</div>

					<div className="gx-pt-md-3"></div>
				</div>

				<Row>
					<Col span={24} className="gx-mb-4">
						<h1>Manage cities</h1>
						<hr />
					</Col>
					<Col span={24} className="gx-mb-4">
						{data.cities.length > 0 ? (
							<Select getPopupContainer={(trigger) => trigger.parentElement}
								mode="multiple"
								placeholder="Please select"
								defaultValue={['kochi']}
								onChange={handleChange}
								style={{ width: '100%' }}
							>
								{children}
							</Select>
						) : (
							'Loading'
						)}
					</Col>
				</Row>
			</div>
		</Card>
	);
};
export default ManageCities;
